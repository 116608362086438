import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterModule } from '@angular/router';
import { AutoDestroy } from '@finxone-platform/shared/base-types';
import { NavBarMobile, baseCssConfigStyle, defaultNavBar } from '@finxone-platform/shared/sys-config-types';
import { Select, Store } from '@ngxs/store';
import { KeycloakAngularModule } from 'keycloak-angular';
import { SharedModule } from 'primeng/api';
import { Observable, Subject, fromEvent, map, take, takeUntil, timer } from 'rxjs';
import { RemoveAllProgressBarStack } from '../../../lib/actions/progress-bar.action';
import { ConfigService } from '../../../lib/services/config-service/config-service.service';
import { RoleState, RoleStateModel } from '../../../lib/state/role.state';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-footer-navbar',
  templateUrl: './footer-navbar.component.html',
  styleUrls: ['./footer-navbar.component.scss'],
  standalone: true,
  imports: [SharedModule, CommonModule, RouterModule, KeycloakAngularModule, MatIconModule],
})
export class FooterNavbarComponent implements OnInit, OnChanges {
  @AutoDestroy destroy$: Subject<void> = new Subject<void>();
  @Input() footerStyles: baseCssConfigStyle;
  @Input() currentUrl = '';
  hideChart = false;
  hideProfile = false;
  @Select(RoleState.getRole)
  role!: Observable<RoleStateModel>;
  roleInfo: RoleStateModel;
  navBarMobileConfig: NavBarMobile;
  getMobileNavBarItems$: Observable<NavBarMobile>;
  public containerStyles: baseCssConfigStyle;
  public bottomPadding = '0px';

  constructor(private router: Router, private configService: ConfigService, private store: Store) {
    this.updateSafeAreaPadding();

    this.configService.getConfig().subscribe(() => {
      this.updateSafeAreaPadding();
    });

    this.role.subscribe((role) => {
      this.roleInfo = role;
      this.getNavBarUIFromSystemConfig();
    });

    const source$ = fromEvent<StorageEvent>(window, 'storage');

    source$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (data.key === 'APP_PREFERENCES') {
        this.updateSafeAreaPadding();
      }
    });
  }

  private updateSafeAreaPadding() {
    // This is the in app padding needed for the safe area on mobile devices
    this.configService
      .getSafePadding()
      .pipe(take(1))
      .subscribe((padding) => {
        if (padding.isIOS) {
          this.bottomPadding = `${padding.bottomPadding}px`;
        } else {
          this.bottomPadding = '0px';
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateSafeAreaPadding();
  }

  ngOnInit() {
    if (this.currentUrl === '') {
      this.router.events
        .pipe(
          takeUntil(this.destroy$),
          map(() => {
            this.currentUrl = this.router.url;
          }),
        )
        .subscribe();
    }
    this.getNavBarUIFromSystemConfig();

    // just periodically check if padding should be updated every 500ms
    timer(0, 500)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.updateSafeAreaPadding();
      });
  }

  routeContains(route: string) {
    return this.router.url.includes(route);
  }

  navigateToZone(zoneUrl: string): void {
    this.store.dispatch(new RemoveAllProgressBarStack());
    this.router.navigateByUrl(zoneUrl);
  }

  getNavBarUIFromSystemConfig() {
    this.configService
      .getWidgetsConfig()
      .pipe(takeUntil(this.destroy$))
      .subscribe((config) => {
        const navBarConfig = config?.navbar[this.roleInfo.role]?.navBarMobile;

        if (navBarConfig) {
          this.navBarMobileConfig = navBarConfig;
        } else {
          this.navBarMobileConfig = defaultNavBar.navBarMobile;
        }
        this.footerStyles = {
          ...this.footerStyles,
          'background-color': this.navBarMobileConfig.navBarColor,
          'border-width': this.navBarMobileConfig.borderWidth.toString() + 'px',
          'border-color': this.navBarMobileConfig.borderColor,
          'border-style': 'solid',
          'border-bottom-left-radius':
            this.navBarMobileConfig.borderRadiusBottomLeft.toString() +
            this.navBarMobileConfig.borderRadiusUnit,
          'border-bottom-right-radius':
            this.navBarMobileConfig.borderRadiusBottomRight.toString() +
            this.navBarMobileConfig.borderRadiusUnit,
          'border-top-left-radius':
            this.navBarMobileConfig.borderRadiusTopLeft.toString() + this.navBarMobileConfig.borderRadiusUnit,
          'border-top-right-radius':
            this.navBarMobileConfig.borderRadiusTopRight.toString() +
            this.navBarMobileConfig.borderRadiusUnit,
        };
        /**
         * added nullable, in some case padding data does not come.
         */
        this.containerStyles = {
          ...this.containerStyles,
          'padding-left':
            this.navBarMobileConfig.paddingLeft?.toString() + this.navBarMobileConfig?.paddingUnit,
          'padding-right':
            this.navBarMobileConfig.paddingRight?.toString() + this.navBarMobileConfig?.paddingUnit,
          'padding-top': this.navBarMobileConfig.paddingTop.toString() + this.navBarMobileConfig?.paddingUnit,
          'padding-bottom':
            this.navBarMobileConfig.paddingBottom?.toString() + this.navBarMobileConfig?.paddingUnit,
          'margin-top': this.navBarMobileConfig.marginTop?.toString() + this.navBarMobileConfig?.marginUnit,
          'margin-bottom':
            this.navBarMobileConfig.marginBottom?.toString() + this.navBarMobileConfig?.marginUnit,
          'margin-left': this.navBarMobileConfig.marginLeft?.toString() + this.navBarMobileConfig?.marginUnit,
          'margin-right':
            this.navBarMobileConfig.marginRight?.toString() + this.navBarMobileConfig?.marginUnit,
        };
      });
  }
}
