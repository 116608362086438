import {
  Constants,
  Page,
  WidgetNames,
  WidgetTitles,
  WorkflowWidgetConfig,
} from '@finxone-platform/shared/sys-config-types';

export const accountNumberWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: WidgetNames.ACCOUNT_NUMBER,
  name: WidgetNames.ACCOUNT_NUMBER,
  widgetConfiguration: {
    widgetDisplayName: 'Account Number',
    widgetConfigDisplayName: WidgetTitles.ACCOUNT_NUMBER,
    isRemovable: true,
    attributes: {
      widgetProperties: { textContent: '' },
      elementsConfig: [],
      name: Page.TEXT_ITEM_WIDGET,
      type: 'account',
      slug: 'account_number',
      label: 'Account Number',
      css_config: Constants.defaultBaseCssConfig,
    },
  },
  gridPosition: {
    x: 0,
    y: 0,
    minItemCols: 15,
    minItemRows: 1,
    rows: 4,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const sortCodeWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: WidgetNames.SORT_CODE,
  name: WidgetNames.SORT_CODE,
  widgetConfiguration: {
    widgetDisplayName: 'Sort Code',
    widgetConfigDisplayName: WidgetTitles.SORT_CODE,
    attributes: {
      widgetProperties: { textContent: '' },
      elementsConfig: [],
      name: Page.TEXT_ITEM_WIDGET,
      type: 'account',
      slug: 'sort_code',
      label: 'Sort Code',
      css_config: Constants.defaultBaseCssConfig,
    },
    isRemovable: true,
  },
  gridPosition: {
    x: 5,
    y: 5,
    minItemCols: 15,
    minItemRows: 2,
    rows: 4,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const ibanNumberWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: WidgetNames.IBAN_NUMBER,
  name: WidgetNames.IBAN_NUMBER,
  widgetConfiguration: {
    widgetConfigDisplayName: WidgetTitles.IBAN_NUMBER,
    widgetDisplayName: 'IBAN Number',
    attributes: {
      widgetProperties: { textContent: '' },
      elementsConfig: [],
      name: Page.TEXT_ITEM_WIDGET,
      type: 'account',
      slug: 'iban_number',
      label: 'IBAN Number',
      css_config: Constants.defaultBaseCssConfig,
    },
    isRemovable: true,
  },
  gridPosition: {
    x: 5,
    y: 5,
    minItemCols: 1,
    minItemRows: 1,
    rows: 4,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;
