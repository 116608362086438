import { Router } from '@angular/router';
import { APP_ZONES } from '@finxone-platform/shared/sys-config-types';
import { ConfigService } from '../../../services/config-service/config-service.service';

/**
 * Navigates to a confirmation code page for displaying an ePIN.
 *
 * This utility function checks if a modal redirection is required for the
 * CARD_MANAGEMENT_ZONE using the provided ConfigService. If a target URL is
 * determined, it navigates the Router to the 'confirmation-code' route with
 * specific query parameters for ePIN retrieval.
 *
 * @param router - The Angular Router instance used for navigation.
 * @param configService - The ConfigService instance used to determine the
 *                        target URL for redirection.
 */
export const showEPINUtil = (router: Router, configService: ConfigService) => {
  const targetUrl = configService.reRouteIfModal(APP_ZONES.CARD_MANAGEMENT_ZONE, 'show-pin');
  if (targetUrl) {
    router.navigate(['confirmation-code'], {
      queryParams: {
        claimType: 'getEpin',
        confirmationType: 'PIN',
        returnUrl: targetUrl,
      },
    });
  }
};
