import {
  WorkflowPageConfig,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import { PageConfig } from '../../../../../zones/mobile/default-zone-page-setups';
import { SectionWidgetBuilder } from '../../../../widgets/example-builder';
import { sumsubWidgetConfig } from '../../../../widgets/verification/verification.type';
import { defaultPageConfigurations } from '../../../../widgets/workflow-ui-constants.v2.type';
import { desktopEditAddressInformationWidgets } from '../widget-sections/edit-company-address-widgets';
import { desktopEditCompanyInformationWidgets } from '../widget-sections/edit-company-information-widgets';
import { desktopEditIncorporationDateWidgets } from '../widget-sections/edit-information-incorporation-date';
import { desktopInformationConfirmationWidgets } from '../widget-sections/information-confirmation-widget';
import { desktopReviewInformationWidgets } from '../widget-sections/review-information-page-widgets';

export function buildDesktopVerificationOrgZonePagesConfig(): WorkflowPageConfig[] {
  const zonePages: WorkflowPageConfig[] = [];
  const initialPages: PageConfig[] = [
    {
      name: 'review-information',
      title: 'Review Information',
      tags: [],
      url: 'review-information',
      widgets: desktopReviewInformationWidgets(),
    },
    {
      name: 'edit-information-company-name',
      title: 'Edit Information - Company Name',
      tags: [],
      url: 'edit-information-company-name',
      widgets: desktopEditCompanyInformationWidgets(),
    },
    {
      name: 'edit-information-incorporation-date',
      title: 'Edit Information - Incorporation Date',
      tags: [],
      url: 'edit-information-incorporation-date',
      widgets: desktopEditIncorporationDateWidgets(),
    },
    {
      name: 'edit-information-company-address',
      title: 'Edit Information - Company Address',
      tags: [],
      url: 'edit-information-company-address',
      widgets: desktopEditAddressInformationWidgets(),
    },
    {
      name: 'information-confirmation',
      title: 'Information Confirmation',
      tags: [],
      url: 'information-confirmation',
      widgets: desktopInformationConfirmationWidgets(),
    },
    {
      name: 'sumsub-workflow',
      title: 'Sumsub Workflow',
      tags: [],
      url: 'sumsub-workflow',
      widgets: [
        new SectionWidgetBuilder()
          .attributes({
            outerCss: `left: 30%; position: relative; top: 100px; width: 45%; height:700px;`,
          } as unknown as WorkflowWidgetConfigurationAttributes)
          .childComponents([sumsubWidgetConfig()])
          .build(),
      ],
    },
  ];

  initialPages.forEach((page: PageConfig) => {
    const pageObj: WorkflowPageConfig = {
      name: page.name,
      title: page.title,
      widgets: page.widgets ?? [],
      pageConfiguration: {
        ...defaultPageConfigurations,
        ...page.pageConfiguration,
        isUsingHtmlStructure: true,
        backgroundColor: 'var(--color-background)',
        url: page.url ?? page.name,
        pageType: 'dynamicGrid',
        isRemovable: false,
        mainComponentPageName: 'ParentContainerComponent',
      },
    };
    zonePages.push(pageObj);
  });
  return zonePages;
}
