import { Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BeneficiariesSuccessCodes, BeneficiaryErrorCodes } from '@app/translations';
import { FormActionTypeEnum, OtpParam } from '@finxone-platform/form-action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { ACCOUNTTYPE, APP_ZONES, BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { catchError, take, tap } from 'rxjs';
import { UpdateBeneficiary } from '../../../actions/beneficiary.action';
import { ClearBulkPaymentList, GetBulkPaymentList } from '../../../actions/bulk-payment.action';
import { AccountService } from '../../../services/account-service/account-service.service';
import {
  AddBeneficiaryRequest,
  BeneficiaryIdentifierAddress,
  UpdateBeneficiaryRequest,
} from '../../../services/account-service/account.type';
import { BackOverrideService } from '../../../services/back-override-service/back-override.service';
import { FormActionState } from '../../../state/form-submision.state';
import { ProjectSettingsState } from '../../../state/project-settings.state';
import { RoleState } from '../../../state/role.state';
import { findAndShowAlertFromCode } from '../../alert-code-utils/alert-code.utils';
import { formatNameAsUrl } from '../../zone-url.utils';
import { redirectToPage } from '../cta-button-actions.utils';

/**
 * Maps OTP parameters to their corresponding form action types for beneficiary operations.
 *
 * This mapping is used to associate specific OTP parameters with form state identifiers
 * that represent different actions related to beneficiaries, such as adding or editing
 * a beneficiary.
 *
 * @type {Object.<string, {otpParam: OtpParam, formStateId: FormActionTypeEnum}>}
 */
const beneficiaryFlowMap: {
  [key: string]: { otpParam: OtpParam; formStateId: FormActionTypeEnum };
} = {
  [OtpParam.AddBeneficiary]: {
    otpParam: OtpParam.AddBeneficiary,
    formStateId: FormActionTypeEnum.ADD_BENEFICIARY,
  },
  [OtpParam.EditBeneficiary]: {
    otpParam: OtpParam.EditBeneficiary,
    formStateId: FormActionTypeEnum.EDIT_BENEFICIARY,
  },
};

/**
 * Handles the addition or editing of a beneficiary based on the provided flow type.
 *
 * This function retrieves necessary services and state data using the provided injector.
 * It determines the beneficiary flow type from query parameters and executes the appropriate
 * action based on the flow type and project settings. If SMS authentication is enabled and
 * the current role is not excluded, it redirects to an OTP page; otherwise, it initiates
 * an API call directly.
 *
 * @param injector - The Angular Injector used to retrieve services and state.
 * @param widgetProperties - The properties of the widget, used for navigation and configuration.
 */
export function addEditBeneficiary(injector: Injector, widgetProperties: BaseWidgetProperties) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  const route = injector.get(ActivatedRoute);
  const projectSettingsData = store.selectSnapshot(ProjectSettingsState.getProjectSettings);
  const roleData = store.selectSnapshot(RoleState.getRole);
  const params = route.snapshot.queryParams;
  const beneficiaryFlowType = beneficiaryFlowMap[params['flow']];
  const parsedWidgetProperties = JSON.parse(JSON.stringify(widgetProperties));

  if (beneficiaryFlowType.otpParam === OtpParam.BulkPayment) {
    callContinueAnywayAPI(injector);
    return;
  }
  const formActionStateData = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(beneficiaryFlowType.formStateId),
  );
  const formData: AddBeneficiaryRequest | UpdateBeneficiaryRequest =
    beneficiaryFlowType.otpParam === OtpParam.AddBeneficiary
      ? (formActionStateData?.formData as AddBeneficiaryRequest)
      : (formActionStateData?.formData?.payload as UpdateBeneficiaryRequest);

  if (projectSettingsData?.smsAuthenticationEnabled) {
    if (projectSettingsData.smsAuthenticationExclusion?.['addBeneficiary']?.includes(roleData?.role)) {
      // previous scenario
      initiateAPICall(injector, store, formData);
    } else {
      // new OTP page redirection
      parsedWidgetProperties['zoneToNavigate'] = APP_ZONES.PAYMENT;
      parsedWidgetProperties['urlToNavigate'] = `authentication-sms?otp=${beneficiaryFlowType.otpParam}`;
      redirectToPage(router, parsedWidgetProperties, injector);
    }
  } else {
    // previous scenario
    initiateAPICall(injector, store, formData);
  }
}

/**
 * Initiates an API call to either update an existing beneficiary or add a new one.
 *
 * @param injector - The Angular injector used to retrieve services.
 * @param store - The NGXS store for dispatching actions.
 * @param formData - The data for the beneficiary, which can be for adding or updating.
 *
 * If the formData contains an 'id', it dispatches an UpdateBeneficiary action.
 * Otherwise, it calls the API to add a new beneficiary.
 */
function initiateAPICall(
  injector: Injector,
  store: Store,
  formData: AddBeneficiaryRequest | UpdateBeneficiaryRequest,
) {
  const formValues = mapFormDataToAddBeneficiaryRequest(formData);

  (formData as UpdateBeneficiaryRequest)?.id
    ? store.dispatch(new UpdateBeneficiary(formValues, (formData as UpdateBeneficiaryRequest).id ?? ''))
    : callAddBeneficiaryAPI(injector, formData);
}

/**
 * Calls the add beneficiary API using the provided form data and handles the response.
 *
 * @param injector - The Angular Injector used to retrieve service instances.
 * @param formData - The form data containing beneficiary details to be added.
 *
 * This function retrieves necessary services using the injector, maps the form data,
 * and calls the AccountService to add a beneficiary. On success, it navigates to the
 * select beneficiary page and shows a success alert. On failure, it shows an error alert
 * and redirects to the add beneficiary page with appropriate parameters.
 */
function callAddBeneficiaryAPI(injector: Injector, formData: AddBeneficiaryRequest) {
  const accountService = injector.get(AccountService);
  const router = injector.get(Router);
  const alertHandlerService = injector.get(AlertHandlerService);
  const backService = injector.get(BackOverrideService);
  const store = injector.get(Store);
  const formValues = mapFormDataToAddBeneficiaryRequest(formData);
  const mockWidgetProperties: BaseWidgetProperties = {
    textContent: '',
    zoneToNavigate: APP_ZONES.PAYMENT,
    urlToNavigate: '',
  };
  accountService
    .addBeneficiary(formValues)
    .pipe(
      take(1),
      tap(() => {
        mockWidgetProperties['urlToNavigate'] = `select-beneficiary`;
        const nextRoute = `/zones/${formatNameAsUrl(mockWidgetProperties['zoneToNavigate'])}/${
          mockWidgetProperties['urlToNavigate']
        }`;
        redirectToPage(router, mockWidgetProperties, injector);
        backService.setBackOverride('/zones/landing/home', nextRoute, FormActionTypeEnum.ADD_BENEFICIARY);
        findAndShowAlertFromCode(store, router, alertHandlerService, [
          BeneficiariesSuccessCodes.ADD_BENEFICIARY,
        ]);
      }),
      catchError((err) => {
        let endRoute = '';
        endRoute =
          formData.accountType === ACCOUNTTYPE.PERSONAL
            ? `add-beneficiary#personal`
            : `add-beneficiary#business`;

        findAndShowAlertFromCode(store, router, alertHandlerService, [
          BeneficiaryErrorCodes.FAILED_TO_CREATE_BENEFICIARY,
        ]);
        mockWidgetProperties['zoneToNavigate'] = APP_ZONES.PAYMENT;
        mockWidgetProperties['urlToNavigate'] = `add-beneficiary#${endRoute}`;
        redirectToPage(router, mockWidgetProperties, injector);
        return err;
      }),
    )
    .subscribe();
}

const callContinueAnywayAPI = (injector: Injector) => {
  const accountService = injector.get(AccountService);
  const router = injector.get(Router);
  const alertHandlerService = injector.get(AlertHandlerService);
  const backService = injector.get(BackOverrideService);
  const store = injector.get(Store);
  const formActionData = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.BULK_PAYMENTS),
  );
  const masterFileId = formActionData?.formData.masterFileId;
  const transactionId = formActionData?.formData.transactionId;
  const mockWidgetProperties: BaseWidgetProperties = {
    textContent: '',
    zoneToNavigate: APP_ZONES.PAYMENT,
    urlToNavigate: '',
  };

  // passing suggestion: false as we call the continue anyway function here
  accountService
    .bulkPaymentContinueAnywayAndWithSuggestion(masterFileId, transactionId, { suggestion: false })
    .pipe(
      take(1),
      tap(() => {
        store.dispatch(new ClearBulkPaymentList());
        store.dispatch(new GetBulkPaymentList(1, 10, masterFileId));
        mockWidgetProperties['urlToNavigate'] = `bulk-payment-beneficiary-review`;
        redirectToPage(router, mockWidgetProperties, injector);
        const nextRoute = `/zones/${formatNameAsUrl(mockWidgetProperties['zoneToNavigate'])}/${
          mockWidgetProperties['urlToNavigate']
        }`;
        backService.setBackOverride('/zones/landing/home', nextRoute, FormActionTypeEnum.ADD_BENEFICIARY);
        alertHandlerService.showAlertFn('success', 'Updated Successfully.');
      }),
      catchError((error) => {
        return error;
      }),
    )
    .subscribe();
};

export function mapFormDataToAddBeneficiaryRequest(form: any): AddBeneficiaryRequest {
  const formaData: any = {
    name: form?.name ?? '',
    accountType: form?.accountType,
    accountId: form?.accountId,
    destinationIdentifier: form?.destinationIdentifier,
  };
  if (form?.bankId) formaData.bankId = form?.bankId;
  if (form?.id) formaData.id = form?.id;

  // Initialize address only if address has values
  const address = {} as BeneficiaryIdentifierAddress;
  if (form?.addressStreet) address.addressLine1 = form.addressStreet;
  if (form?.addressLine1) address.addressLine1 = form.addressLine1;
  if (form?.addressLine2) address.addressLine2 = form.addressLine2;
  if (form?.addressLine3) address.addressLine3 = form.addressLine3;
  if (form?.addressLine4) address.addressLine4 = form.addressLine4;
  if (form?.addressCity) address.city = form.addressCity;
  if (form?.addressCountry) address.country = form.addressCountry;
  if (form?.addressPostCode) address.postCode = form.addressPostCode;
  if (form?.addressState) address.state = form.addressState;

  // Add address if it's not empty
  if (Object.keys(address).length > 0) {
    formaData.address = address;
  }

  return formaData;
}

export function getCountryCodeFromBeneficiaryAccountDetail(type: 'iban' | 'bic', value: string): string {
  const splittedValue = value.split('');
  if (type === 'iban') {
    return splittedValue[0] + splittedValue[1];
  }
  if (type === 'bic') {
    return splittedValue[4] + splittedValue[5];
  }
  return '';
}
