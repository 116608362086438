import {
  APP_ZONES,
  ListingTypeEnum,
  PaymentFlowTypes,
  PaymentProvider,
  PermissionConfig,
  WidgetNames,
  WorkflowConfig,
  WorkflowPageConfig,
} from '@finxone-platform/shared/sys-config-types';
import {
  defaultPageConfigurations,
  defaultTableCheckBoxCssConfig,
} from '../../defaults/widgets/workflow-ui-constants.v2.type';
import { paymentRequestorPendingPageWidgets } from '../../defaults/zones/payments/payment-requestor-approval-widget/payment-requestor-approval-widget';
import { PageConfig } from '../../zones/mobile/default-zone-page-setups';

type UpdatedPageConfig = PageConfig;

// This migration is responsible for adding a new page "Pending and Approved Payment Requests" in the Payment Zone, immediately after the "Request Rejected" page in the Business User tab. This new page will be accessible with permission Requestor or approver
//User story: 6334 Pending And Approved Payment Requests

const addPaymentRequestPage: UpdatedPageConfig = {
  name: 'pending-and-approved-payment-requests',
  title: 'Pending And Approved Payment Requests',
  url: 'pending-and-approved-payment-requests',
  widgets: paymentRequestorPendingPageWidgets(),
  tags: [
    PaymentProvider.MODULR,
    PaymentProvider.INTEGRATED_FINANCE,
    PaymentFlowTypes.REQUESTOR,
    PaymentFlowTypes.APPROVER,
  ],
  pageConfiguration: {
    url: 'pending-and-approved-payment-requests',
    pageType: 'dynamicGrid',
    isRemovable: false,
    mainComponentPageName: 'ParentContainerComponent',
    ...defaultPageConfigurations,
    isUsingHtmlStructure: true,
  },
};

export const getV1_0_138ToV1_0_139 = (workflowConfig: WorkflowConfig) => {
  const oneToOneKeys = Object.keys(workflowConfig);
  const mappingObject = {};
  // Assign mappings to not modify this version upgrade
  oneToOneKeys.forEach((key) => {
    Object.assign(mappingObject, {
      [key]: key,
    });
  });
  return {
    ...mappingObject,
    // add new version number
    version: {
      key: 'version',
      transform: () => {
        return 'v1.0.139';
      },
    },
    permissionSections: {
      key: 'permissionSections',
      transform: (permissionSection: PermissionConfig): PermissionConfig => {
        const { roles, subscriptions } = permissionSection;
        roles.forEach((item) => {
          const zones = item.zones;
          const paymentZone = zones?.[APP_ZONES.PAYMENT];
          if (paymentZone) {
            const mobilePages = paymentZone?.canvases?.subscription?.['default']?.mobile?.pages as
              | UpdatedPageConfig[]
              | undefined;
            const desktopPages = paymentZone?.canvases?.subscription?.['default']?.desktop?.pages as
              | UpdatedPageConfig[]
              | undefined;
            const tabletPages = paymentZone?.canvases?.subscription?.['default']?.tablet?.pages as
              | UpdatedPageConfig[]
              | undefined;
            if (mobilePages?.length) {
              addNewPages(mobilePages);
            }
            if (desktopPages?.length) {
              addNewPages(desktopPages);
            }
            if (tabletPages?.length) {
              addNewPages(tabletPages);
            }
          }

          const feeManagementZone = zones?.[APP_ZONES.FEE_MANAGEMENT];
          if (feeManagementZone) {
            const desktopPages = feeManagementZone?.canvases?.subscription?.['default']?.desktop?.pages as
              | WorkflowPageConfig[]
              | undefined;

            if (desktopPages) {
              addWidgetConfig(desktopPages);
            }
          }
        });
        return <PermissionConfig>{
          zones: permissionSection.zones,
          subscriptions,
          roles,
        };
      },
    },
  };
};

function addNewPages(pagesArray: UpdatedPageConfig[]): UpdatedPageConfig[] {
  const findRequestPendingPageIndex = pagesArray.findIndex(
    (page) => page.title === 'Request Rejected' || page.name === 'request-rejected',
  );
  const paymentRequestPagePresent = pagesArray.some((page) => page.name === addPaymentRequestPage.name);
  if (findRequestPendingPageIndex !== -1 && !paymentRequestPagePresent) {
    pagesArray.splice(findRequestPendingPageIndex + 1, 0, addPaymentRequestPage);
  }
  return pagesArray;
}

const addWidgetConfig = (pages: WorkflowPageConfig[]) => {
  const page = pages.find((page) => page.name === 'clients-fee-history');

  page?.widgets?.find((widget) => {
    if (
      widget.name === WidgetNames.FEE_CLIENT_LISTING_WIDGET &&
      widget.widgetConfiguration.attributes.widgetProperties['listingType'] ===
        ListingTypeEnum.CLIENT_FEE_CHARGED_HISTORY
    ) {
      if (!widget.widgetConfiguration.attributes['tableCheckboxSettings']) {
        widget.widgetConfiguration.attributes = {
          ...widget.widgetConfiguration.attributes,
          tableCheckboxSettings: {
            enableCheckbox: true,
            inputFieldSettings: { ...defaultTableCheckBoxCssConfig },
          },
        };
      }
    }
  });

  return page;
};
