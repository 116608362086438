import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonActionType,
  GridPosition,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import { inputSettings } from '../../../widgets';
import {
  DesktopBulkPaymentListingWidgetBuilder,
  FormSubmissionButtonWidgetBuilder,
  LineWidgetBuilder,
  TextWidgetBuilder,
} from '../../../widgets/example-builder';
import {
  bulkPaymentFooter,
  generateTextWidget,
  getQuickLinkWidget,
  refreshBeneficiaryButton,
} from '../common-widgets-utils';
import { reviewBulkPayments } from '../utils/bulk-payment-util';
import { bulkPaymentElaboratedViewtListingColumns } from './bulk-payment-elaborated-view-widgets';

export const bulkPaymentElaboratedViewTplWidgets = [
  generateTextWidget({
    text: 'Bulk Payment Summary',
    position: { x: 0, y: 0, rows: 2, cols: 15 } as GridPosition,
    displayName: 'Header Text',
    styles: {
      fontSize: 24,
      isTextBold: true,
      verticalAlignment: 'start',
      textAlignment: 'left',
      noAlignment: true,
      paddingLeft: 33,
      paddingTop: 45,
    },
    isRemovable: true,
  }),

  generateTextWidget({
    text: "<p>This is an elaborative view of the bulk payments made on {{ datePipe bulkPaymentSummary.executedAt format='d MMM y' }} for {{ bulkPaymentSummary.totalAmount }}. Please review the payment details.</p>",
    position: { x: 0, y: 2, rows: 1, cols: 35 } as GridPosition,
    displayName: 'Information Content',
    styles: {
      verticalAlignment: 'start',
      textAlignment: 'left',
      fontSize: 20,
      paddingLeft: 33,
      paddingTop: 5,
      lineHeight: '24',
      textFont: 'var(--font-family)',
      color: 'var(--typography-color-1)',
    },
    isRemovable: true,
  }),

  getQuickLinkWidget({
    text: 'History',
    position: { x: 38, y: 1, rows: 1, cols: 5 } as GridPosition,
    pageUrl: 'bulk-payment-landing',
    displayName: 'Quick Link: History',
    styles: {
      color: 'var(--palette-color-3)',
      fontSize: '16',
      backGroundColor: 'transparent',
      textAlignment: 'center',
      justifyContent: 'center',
      borderColor: 'var(--palette-color-3)',
      borderStyle: 'solid',
      borderWidth: 1,
      borderRadiusTopLeft: 6,
      borderRadiusBottomLeft: 6,
      borderRadiusTopRight: 6,
      borderRadiusBottomRight: 6,
    },
    zone: APP_ZONES.PAYMENT,
    isRemovable: true,
  }),
  new TextWidgetBuilder()
    .text(reviewBulkPayments)
    .configDisplayName('Text Widget: Counters')
    .gridPosition({ x: 1, y: 3, rows: 3, cols: 32 } as GridPosition)
    .cssConfig({
      fontSize: 20,
      color: 'var(--palette-color-1)',
      textAlignment: 'center',
      verticalAlignment: 'center',
      backGroundColor: '#F2F4F7',
      paddingLeft: 10,
      paddingRight: 10,
    })
    .build(),

  generateTextWidget({
    text: 'Any missing data? Try refreshing using the “Refresh Data” button',
    position: { x: 33, y: 3, rows: 2, cols: 10 } as GridPosition,
    displayName: 'Text Widget: Info text  ',
    styles: {
      verticalAlignment: 'start',
      textAlignment: 'right',
      fontSize: '14',
      paddingLeft: 33,
      paddingTop: 5,
      lineHeight: '24',
      textFont: 'var(--font-family)',
      color: 'var(--typography-color-0)',
    },
    isRemovable: true,
  }),

  new FormSubmissionButtonWidgetBuilder()
    .gridPosition({ x: 16, y: 20, rows: 1, cols: 6 } as GridPosition)
    .cssConfig({
      fontSize: 14,
      verticalAlignment: 'center',
      textAlignment: 'center',
      disabledBgColor: 'var(--palette-color-3)',
      disabledTextColor: 'var(--palette-color-3)',

      color: 'var(--palette-color-3)',
      backGroundColor: 'transparent',
      justifyContent: 'center',
      borderColor: 'var(--palette-color-3)',
      borderStyle: 'solid',
      borderWidth: 1,
      borderRadiusTopLeft: 6,
      borderRadiusBottomLeft: 6,
      borderRadiusTopRight: 6,
      borderRadiusBottomRight: 6,
    })
    .attributes({
      widgetProperties: {
        textContent: 'Decline',
        buttonActionType: ButtonActionType.BULK_PAYMENT_REJECT,
        zoneToNavigate: APP_ZONES.PAYMENT,
        urlToNavigate: 'bulk-payment-authentication-sms',
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .build(),

  new FormSubmissionButtonWidgetBuilder()
    .gridPosition({ x: 23, y: 20, rows: 1, cols: 6 } as GridPosition)
    .cssConfig({
      fontSize: 14,
      verticalAlignment: 'center',
      textAlignment: 'center',
      disabledBgColor: 'var(--palette-color-3)',
      disabledTextColor: 'var(--palette-color-6)',
    })
    .attributes({
      widgetProperties: {
        textContent: 'Approve',
        buttonActionType: ButtonActionType.BULK_PAYMENT_APPROVER,
        zoneToNavigate: APP_ZONES.PAYMENT,
        urlToNavigate: 'bulk-payment-authentication-sms',
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .build(),

  refreshBeneficiaryButton,

  new DesktopBulkPaymentListingWidgetBuilder()
    .text('Bulk Payment Listing')
    .configDisplayName(`Bulk Payment Listing`)
    .gridPosition({ x: 1, y: 7, rows: 11, cols: 43 } as GridPosition)
    .cssConfig({
      color: 'var(--palette-color-1)',
      textAlignment: 'left',
      justifyContent: 'left',
      fontSize: '16',
      borderWidth: 1,
      borderColor: 'var(--palette-color-2)',
      backGroundColor: 'var(--palette-color-6)',
      borderStyle: 'solid',
      borderRadiusUnit: 'px',
      borderRadiusTopLeft: 5,
      borderRadiusBottomLeft: 5,
      borderRadiusTopRight: 5,
      borderRadiusBottomRight: 5,
    })
    .attributes({
      widgetProperties: {
        icon: 'search',
        textContent: ``,
        showEntity: false,
        entity: 'bulk_payment',
        memuOptions: [{ label: 'Retry', value: 'retry', isIdNotExist: 'transactionId' }],
      } as BaseWidgetProperties,
      tableGridSetting: {
        showGridLines: false,
        gridLinesColor: 'var(--border-color-1)',
      },
      rowSettings: {
        rowBgColor: 'var(--palette-color-6)',
        showErrorDuplicationRowBgColor: false,
      },
      columns: bulkPaymentElaboratedViewtListingColumns,
      viewListOption: 'none',
    } as unknown as WorkflowWidgetConfigurationAttributes)
    .inputSetting({
      ...inputSettings,
      css_config: {
        ...inputSettings.css_config,
        inputWidth: 110,
      },
      label: 'Search',
      isIconAllowed: true,
    })
    .isRemovable(false)
    .build(),

  new LineWidgetBuilder()
    .configDisplayName('Line Widget')
    .gridPosition({ x: 1, y: 22, rows: 1, cols: 45 } as GridPosition)
    .attributes({
      widgetProperties: {
        color: 'var(--border-color-1)',
        'border-bottom': 'var(--border-color-1)',
        lineThickness: 1,
        lineWidth: '100',
        textContent: '',
        show: true,
        contentAlignment: 'center',
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .build(),

  ...bulkPaymentFooter(23),
];
