import {
  APP_ZONES,
  ButtonSizeOptions,
  ButtonTypes,
  GridPosition,
} from '@finxone-platform/shared/sys-config-types';
import {
  MultiIconQuickLinkWidgetBuilder,
  QuickLinkWidgetBuilder,
  TextWidgetBuilder,
} from '../../../widgets/example-builder';
import {
  backbuttonCssConfig,
  multiIconWidgetCssConfig,
  textwidgetCssConfig,
} from '../../../widgets/profile/profile.type';

export const faqsWidgets = [
  new TextWidgetBuilder()
    .text('<div class="w-100"><h1><b>Frequently asked questions</b></h1></div>')
    .configDisplayName('Text Widget: FAQ')
    .gridPosition({ y: 0, rows: 3 } as GridPosition)
    .cssConfig(textwidgetCssConfig)
    .isRemovable(false)
    .build(),
  new TextWidgetBuilder()
    .text(
      '<div class="w-100"><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In elementum venenatis nisl a faucibus. Phasellus sollicitudin velit odio, quis semper elit venenatis a. Vivamus gravida commodo ante, a congue ipsum lobortis ac. Nullam condimentum eu turpis sit amet pellentesque. Phasellus molestie eget est quis malesuada. Nullam purus turpis, scelerisque vitae eros ut, dictum accumsan augue. Mauris nec ligula vitae lorem sagittis volutpat. Duis sit amet lectus ac felis molestie varius. Donec vel tellus sed sem luctus accumsan. Nunc eget libero turpis. Donec nec tortor a massa fermentum commodo. Suspendisse ut porta purus.</p></div>',
    )
    .configDisplayName('Text Widget: Lorem ipsum dolor sit amet')
    .gridPosition({ y: 3, rows: 3 } as GridPosition)
    .cssConfig(textwidgetCssConfig)
    .isRemovable(true)
    .build(),
  new MultiIconQuickLinkWidgetBuilder()
    .icons({
      left: 'question-circle',
      right: 'arrow-right',
    })
    .template('<p class="multi-icon-header">How do i create a Money</p>')
    .zone(APP_ZONES.PROFILE)
    .role()
    .navigateToPageUrl('faqs-how-do-i-create-an-account')
    .navigationOption('use-as-navigation')
    .configDisplayName('Multi Icon Quick Link: FAQs: How do i create a MoneyFin account')
    .cssConfig(multiIconWidgetCssConfig)
    .isRemovable(true)
    .gridPosition({ y: 7 } as GridPosition)
    .setGlobalStylingClass(true, `${ButtonTypes.MENU} ${ButtonSizeOptions.FULL_WIDTH}`)
    .build(),

  new MultiIconQuickLinkWidgetBuilder()
    .icons({
      left: 'question-circle',
      right: 'arrow-right',
    })
    .template('<p class="multi-icon-header">Customizing notification</p>')
    .zone(APP_ZONES.PROFILE)
    .role()
    .navigateToPageUrl('faqs-notification-settings')
    .navigationOption('use-as-navigation')
    .configDisplayName('Multi Icon Quick Link: FAQs: Customizing notifications and alerts in my profile')
    .cssConfig(multiIconWidgetCssConfig)
    .isRemovable(true)
    .gridPosition({ y: 11 } as GridPosition)
    .setGlobalStylingClass(true, `${ButtonTypes.MENU} ${ButtonSizeOptions.FULL_WIDTH}`)
    .build(),
  new TextWidgetBuilder()
    .text(
      '<div class="w-100"><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In elementum venenatis nisl a faucibus. Phasellus sollicitudin velit odio, quis semper elit venenatis a. Vivamus gravida commodo ante, a congue ipsum lobortis ac. Nullam condimentum eu turpis sit amet pellentesque. Phasellus molestie eget est quis malesuada. Nullam purus turpis, scelerisque vitae eros ut, dictum accumsan augue. Mauris nec ligula vitae lorem sagittis volutpat. Duis sit amet lectus ac felis molestie varius. Donec vel tellus sed sem luctus accumsan. Nunc eget libero turpis. Donec nec tortor a massa fermentum commodo. Suspendisse ut porta purus.</p></div>',
    )
    .configDisplayName('Text Widget: Lorem ipsum dolor sit amet')
    .gridPosition({ y: 23, rows: 5 } as GridPosition)
    .cssConfig(textwidgetCssConfig)
    .isRemovable(false)
    .build(),
  new QuickLinkWidgetBuilder()
    .zone(APP_ZONES.PROFILE)
    .navigateToPageUrl('profile')
    .navigationOption('use-as-navigation')
    .role()
    .text('Back')
    .configDisplayName('Quick Link: Back')
    .cssConfig(backbuttonCssConfig)
    .gridPosition({ y: 29, rows: 2 } as GridPosition)
    .setGlobalStylingClass(true, `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`)
    .build(),
];

export const faqsnotification = [
  new TextWidgetBuilder()
    .text(
      '<div class="w-100"><h1><b>FAQ: Notification Settings</b></h1>' +
        '<p><b>Q1: How can I customize my notification preferences for balance updates and transaction alerts?</b></p>' +
        '<p>A1: To personalize your notification experience, head to the Notification Settings in the app. Under "In-App," "Email," and "SMS" sections, youll find toggles for balance updates and transaction alerts. Simply toggle the options based on your preferences.</p>' +
        '<p><b>Q2: What do each notification type mean?</b></p>' +
        '<p>*A2: - In-App Notifications: Receive updates within the app.</p>' +
        '<p>Email Notifications: Get notified via email.</p>' +
        '<p>SMS Notifications: Receive text alerts on your mobile phone.*</p>' +
        '<p><b>Q3: Can I select specific notification types for different updates?</b></p>' +
        '<p>A3: Absolutely! Our customization options allow you to choose which notification types you prefer for both balance updates and transaction alerts. Select the options that suit your needs.</p>' +
        '<p><b>Q4: How do I turn off notifications for a specific type?</b></p>' +
        '<p>A4: To disable a notification type, simply toggle off the corresponding option under "In-App," "Email," or "SMS" in Notification Settings.</p>' +
        '<p><b>Q5: Will I still receive important updates if I turn off all notification types?</b></p>' +
        '<p>A5: While you can customize your preferences, we recommend keeping at least one notification type active to ensure you receive important updates about your balance and transaction alerts.</p>' +
        '<p><b>Q6: Are these settings synchronized across devices?</b></p>' +
        '<p>A6: Yes, your notification preferences are synced across all devices where you have the app installed, providing a consistent experience.</p>' +
        '<p><b>Q7: Im not receiving notifications. What should I check?</b></p>' +
        '<p>A7: Ensure that your device settings allow notifications for the app. Additionally, verify your notification preferences in the app under Notification Settings.</p></div>',
    )
    .configDisplayName('Text Widget: Notification Settings')
    .gridPosition({ y: 1, rows: 44 } as GridPosition)
    .cssConfig({
      ...textwidgetCssConfig,
      fontSize: '14',
    })
    .isRemovable(false)
    .build(),
  new QuickLinkWidgetBuilder()
    .zone(APP_ZONES.PROFILE)
    .navigateToPageUrl('profile')
    .navigationOption('use-as-navigation')
    .role()
    .text('Back')
    .configDisplayName('Quick Link: Back')
    .cssConfig(backbuttonCssConfig)
    .gridPosition({ y: 48, rows: 2 } as GridPosition)
    .setGlobalStylingClass(true, `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`)
    .build(),
];

export const faqscreatemoneyfin = [
  new TextWidgetBuilder()
    .text('<div class="w-100">' + '<h1><b>FAQs: How do I make money.</b></h1></div>')
    .configDisplayName('Text Widget: FAQs: How do I make money.')
    .gridPosition({ y: 1, rows: 3 } as GridPosition)
    .cssConfig(textwidgetCssConfig)
    .isRemovable(false)
    .build(),
  new TextWidgetBuilder()
    .text(
      '<div class="w-100 list-style-disc">' +
        '<h3>Information We Collect</h3> ' +
        '<ul>' +
        '<li>Personal Information: we may collect personal information such as your name, ' +
        'business information, contact details, and other relevant details necessary for providing banking services.</li>' +
        '<br><br><br><br>' +
        '<li>Financial Information: MoneyFin may collect financial information required for banking transactions, ' +
        'including account balances, transaction history, and related data.</li> ' +
        '<br><br><br>' +
        '<li>Device Information: We may collect information about the devices you use to access MoneyFin, ' +
        'such as device type, operating system, and unique device identifiers.</li>' +
        '</ul></div>',
    )
    .configDisplayName('Text Widget: Information We Collect')
    .gridPosition({ y: 6, rows: 18 } as GridPosition)
    .cssConfig(textwidgetCssConfig)
    .isRemovable(true)
    .build(),
  new TextWidgetBuilder()
    .text(
      '<div class="w-100 list-style-disc">' +
        '<h3>How We Use Your Information</h3>' +
        '<p>MoneyFin uses your information for the following purposes:</p>' +
        '<ul>' +
        '<li>Providing and improving our banking services.</li>' +
        '<li>Processing transactions and managing your accounts.</li>' +
        '<li>Communicating with you about account updates, promotions, and security alerts.</li><br>' +
        '<li>Enhancing the security and integrity of our application.</li>' +
        '</ul></div>',
    )
    .configDisplayName('Text Widget: How We Use Your Information')
    .gridPosition({ y: 26, rows: 17 } as GridPosition)
    .cssConfig(textwidgetCssConfig)
    .isRemovable(true)
    .build(),
  new TextWidgetBuilder()
    .text(
      '<div class="w-100">' +
        '<h3>Information Sharing</h3>' +
        '<p>We do not sell, trade, or otherwise transfer your personal information to outside parties. ' +
        'However, we may share information with trusted third parties who assist us in operating our application, conducting our business, ' +
        'or servicing you, provided that such parties agree to keep this information confidential.</p></div>',
    )
    .configDisplayName('Text Widget: Information Sharing')
    .gridPosition({ y: 44, rows: 10 } as GridPosition)
    .cssConfig(textwidgetCssConfig)
    .isRemovable(true)
    .build(),
  new QuickLinkWidgetBuilder()
    .zone(APP_ZONES.PROFILE)
    .navigateToPageUrl('profile')
    .navigationOption('use-as-navigation')
    .role()
    .text('Back')
    .configDisplayName('Quick Link: Back')
    .cssConfig(backbuttonCssConfig)
    .gridPosition({ y: 56, rows: 2 } as GridPosition)
    .setGlobalStylingClass(true, `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`)
    .build(),
];
