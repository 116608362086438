import {
  CommonPageConfig,
  RevenirZonePagesTags,
  WorkflowWidgetConfig,
} from '@finxone-platform/shared/sys-config-types';
import { PageConfig } from '../../../zones/mobile/default-zone-page-setups';
import { defaultPageConfigurations } from '../../widgets/workflow-ui-constants.v2.type';
import { revenirArchivedTransactionsWidgets } from './revenir-zone-pages/archived-transactions-widgets';
import { revenirArchivedTripsWidgets } from './revenir-zone-pages/archived-trips-widgets';
import { transactionListWidgets } from './revenir-zone-pages/transaction-list-widgets';
import { tripLandingWidgets } from './revenir-zone-pages/trip-landing-widgets';
import { archiveTripConfirmationWidgets } from './trip-zone/page-widgets/archive-trip-confirmation';
import { barcodeScanningWidgets } from './trip-zone/page-widgets/barcode-scanning-widgets';
import { barcodeWidgets, kioskMapPageWidgets } from './trip-zone/page-widgets/barcode-widget';
import { confirmArchiveSuccessWidgets } from './trip-zone/page-widgets/confirm-archive-success-widget';
import { tripZoneEditTransactionStep2Widgets } from './trip-zone/page-widgets/edit-transaction-widgets';
import { tripZoneEnteringEUWidgets } from './trip-zone/page-widgets/entering-eu-country-widgets';
import { tripZoneExitPointWidgets } from './trip-zone/page-widgets/exit-point';
import {
  infoPage2Widgets,
  infoPage3Widgets,
  infoPageWidgets,
} from './trip-zone/page-widgets/info-page-declaration-widget';
import { tripZoneLeavingEUWidgets } from './trip-zone/page-widgets/leaving-eu-country-widgets';
import {
  boardingPassMissingInformationWidgets,
  passportMissingInformationWidgets,
} from './trip-zone/page-widgets/missing-information-widgets';
import { tripZoneMultipleCountryNotificationWidgets } from './trip-zone/page-widgets/multiple-country-notification';
import { tripZoneReviewDetailsWidgets } from './trip-zone/page-widgets/review-details';
import { reviewPhotoWidgets } from './trip-zone/page-widgets/review-photo-widgets';
import { tripZoneReviewUploadBoardingPassWidgets } from './trip-zone/page-widgets/review-upload-boarding-pass';
import { barcodeDeclarationWidgets } from './trip-zone/page-widgets/scan-barcode-declaration-widget';
import { scanningReceiptWidgets } from './trip-zone/page-widgets/scanning-receipt';
import { successArchivedTripWidgets } from './trip-zone/page-widgets/success-archived-trip';
import { tripZoneTransactionSummaryWidgets } from './trip-zone/page-widgets/transaction-summary-widgets';
import { tripZoneTripStartWidgets } from './trip-zone/page-widgets/trip-start';
import { tripZoneUploadBoardingPassWidgets } from './trip-zone/page-widgets/upload-boarding-pass';
import {
  passportNoticePageWidgets,
  passportProcessingPageWidgets,
  passportSuccessPageWidgets,
  passportUploadfailedPageWidgets,
  uploadPassportPageWidgets,
} from './trip-zone/upload-passport-widgets';

export function revenirMobilePagesConfig(): {
  name: string;
  title: string;
  url?: string;
  widgets?: WorkflowWidgetConfig[];
  tags: string[];
  pageConfiguration: CommonPageConfig;
}[] {
  const revenirPages: PageConfig[] = [
    {
      name: 'trips-home-page',
      title: 'Trips (Home Page)',
      tags: [],
      widgets: tripLandingWidgets,
    },
    {
      name: 'transaction-list',
      title: 'Transaction List',
      tags: [],
      widgets: transactionListWidgets,
    },
    {
      name: 'archived-trips',
      title: 'Archived Trips',
      tags: [],
      widgets: revenirArchivedTripsWidgets,
    },
    {
      name: 'archived-transactions',
      title: 'Archived Transactions',
      tags: [],
      widgets: revenirArchivedTransactionsWidgets,
    },
    {
      name: 'archive-trip-notifications',
      title: 'Archive Trip Notifications',
      widgets: archiveTripConfirmationWidgets,
      tags: [],
    },
    {
      name: 'archive-trip-notifications-success',
      title: 'Archive Trip Notifications Success',
      widgets: successArchivedTripWidgets,
      tags: [],
    },
    {
      name: 'transaction-detected-notifications',
      title: 'Transaction Detected Notifications',
      tags: [],
      widgets: [],
    },
    {
      name: 'trip-start',
      title: 'Trip Start',
      widgets: tripZoneTripStartWidgets,
      tags: [],
    },
    {
      name: 'entering-the-eu',
      title: 'Entering The EU',
      widgets: tripZoneEnteringEUWidgets,
      tags: [],
    },
    {
      name: 'leaving-the-eu',
      title: 'Leaving The EU',
      widgets: tripZoneLeavingEUWidgets,
      tags: [],
    },
    {
      name: 'exit-point',
      title: 'Exit Point',
      widgets: tripZoneExitPointWidgets,
      tags: [],
    },
    {
      name: 'multiple-country-notification',
      title: 'Multiple Country  Notification',
      widgets: tripZoneMultipleCountryNotificationWidgets,
      tags: [],
    },
    {
      name: 'missing-passport',
      title: 'Missing Passport',
      widgets: passportMissingInformationWidgets,
      tags: [],
    },
    {
      name: 'upload-passport',
      title: 'Upload Passport',
      widgets: uploadPassportPageWidgets,
      tags: [],
    },
    {
      name: 'passport-notice',
      title: 'Passport Notice',
      widgets: passportNoticePageWidgets,
      tags: [],
    },
    {
      name: 'passport-processing',
      title: 'Passport Processing',
      widgets: passportProcessingPageWidgets,
      tags: [],
    },
    {
      name: 'passport-success',
      title: 'Passport Success',
      widgets: passportSuccessPageWidgets,
      tags: [],
    },
    {
      name: 'passport-upload-failed',
      title: 'Passport - Upload failed',
      widgets: passportUploadfailedPageWidgets,
      tags: [],
    },
    {
      name: 'missing-boarding-pass',
      title: 'Missing Boarding Pass',
      widgets: boardingPassMissingInformationWidgets,
      tags: [],
    },
    {
      name: 'upload-boarding-pass',
      title: 'Upload Boarding Pass',
      widgets: tripZoneUploadBoardingPassWidgets,
      tags: [],
    },
    {
      name: 'review-upload-boarding-pass',
      title: 'Review Upload Boarding Pass',
      widgets: tripZoneReviewUploadBoardingPassWidgets,
      tags: [],
    },
    {
      name: 'review-details',
      title: 'Review Details',
      widgets: tripZoneReviewDetailsWidgets,
      tags: [],
    },
    {
      name: 'edit-transaction',
      title: 'Edit Transaction',
      widgets: tripZoneEditTransactionStep2Widgets,
      tags: [],
      pageConfiguration: {
        url: 'edit-transaction',
        pageType: 'dynamicGrid',
        isRemovable: false,
        mainComponentPageName: 'ParentContainerComponent',
        isBodyScrollingEnabled: true,
        ...defaultPageConfigurations,
      },
    },
    {
      name: 'review-photo',
      title: 'Review Photo',
      widgets: reviewPhotoWidgets,
      tags: [],
    },
    {
      name: 'scanning-receipt',
      title: 'Scanning Receipt',
      widgets: scanningReceiptWidgets,
      tags: [],
    },
    {
      name: 'transaction-summary',
      title: 'Transaction Summary',
      widgets: tripZoneTransactionSummaryWidgets,
      tags: [],
    },
    { name: 'barcode-scanning', title: 'Barcode scanning', widgets: barcodeScanningWidgets, tags: [] },
    {
      name: 'scan-barcode-declaration',
      title: 'Scan Barcode Declaration',
      widgets: barcodeDeclarationWidgets,
      tags: [],
    },
    {
      name: 'info-page',
      title: 'Info Page',
      widgets: infoPageWidgets,
      tags: [],
    },
    {
      name: 'info-page-2',
      title: 'Info Page 2',
      widgets: infoPage2Widgets,
      tags: [],
    },
    {
      name: 'info-page-3',
      title: 'Info Page 3',
      widgets: infoPage3Widgets,
      tags: [],
    },
    {
      name: 'barcodes',
      title: 'Barcodes',
      widgets: barcodeWidgets,
      tags: [],
      pageConfiguration: { isBodyScrollingEnabled: true } as CommonPageConfig,
    },
    {
      name: 'kiosk-map',
      title: 'Kiosk Map',
      widgets: kioskMapPageWidgets,
      tags: [],
    },
    {
      name: 'confirm-archive-success',
      title: 'Confirm And Archive Success',
      widgets: confirmArchiveSuccessWidgets,
      tags: [],
    },
  ];
  const pagesArray: {
    name: string;
    title: string;
    url?: string;
    widgets?: WorkflowWidgetConfig[];
    tags: string[];
    pageConfiguration: CommonPageConfig;
  }[] = [];
  revenirPages.forEach((page) => {
    pagesArray.push({
      name: page.name,
      title: page.title,
      tags: [...page.tags, RevenirZonePagesTags.revenir],
      url: page.name,
      widgets: page.widgets,
      pageConfiguration: { ...defaultPageConfigurations, ...page?.pageConfiguration } as CommonPageConfig,
    });
  });
  return pagesArray;
}
