import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonActionType,
  GridPosition,
  SINK_TYPE,
  WidgetNames,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import {
  CustomQuestionWidgetBuilder,
  DesktopBulkPaymentListingWidgetBuilder,
  FormSubmissionButtonWidgetBuilder,
  LineWidgetBuilder,
  TextWidgetBuilder,
} from '../../../widgets/example-builder';

import { inputSettings } from '../../../widgets';
import {
  backCTAForBeneficiary,
  bulkPaymentFooter,
  getQuickLinkWidget,
  refreshBeneficiaryButton,
} from '../common-widgets-utils';
import { reviewBeneficiaryCounters } from '../utils/bulk-payment-util';
import { bulkPaymentReviewListingColumns } from './bulk-payment-history-widget';

export const bulkPaymentBeneficiaryReviewWidgets = [
  ...bulkBenReviewHeader(),
  ...bulkPaymentListing(),
  ...bulkPaymentFooter(28),
];
function bulkBenReviewHeader() {
  return [
    new TextWidgetBuilder()
      .text('<h3>Bulk Payment - Beneficiary Review</h3>')
      .configDisplayName('Text Widget: Bulk Pay')
      .gridPosition({ x: 0, y: 1, rows: 1, cols: 15 } as GridPosition)
      .cssConfig({
        fontSize: 24,
        isTextBold: true,
        textAlignment: 'left',
      })
      .build(),
    new TextWidgetBuilder()
      .text('<p>There are some things you need to know before adding beneficiaries.</p>')
      .configDisplayName('Text Widget: Sub header')
      .gridPosition({ x: 0, y: 2, rows: 1, cols: 20 } as GridPosition)
      .cssConfig({
        fontSize: 20,
        color: 'var(--palette-color-1)',
        textAlignment: 'left',
      })
      .build(),
    ...backCTAForBeneficiary,
    new FormSubmissionButtonWidgetBuilder()
      .gridPosition({ x: 37, y: 1, rows: 1, cols: 6 } as GridPosition)
      .cssConfig({
        fontSize: 14,
        verticalAlignment: 'center',
        textAlignment: 'center',
        disabledBgColor: 'var(--palette-color-3)',
        disabledTextColor: 'var(--palette-color-6)',
      })
      .attributes({
        widgetProperties: {
          textContent: 'Add Beneficiaries',
          buttonActionType: ButtonActionType.BULK_PAYMENT_ADD_BENEFICIARY,
          zoneToNavigate: ' ',
          urlToNavigate: ' ',
        } as BaseWidgetProperties,
      } as WorkflowWidgetConfigurationAttributes)
      .build(),
    new TextWidgetBuilder()
      .text(reviewBeneficiaryCounters)
      .configDisplayName('Text Widget: Counters')
      .gridPosition({ x: 0, y: 3, rows: 3, cols: 32 } as GridPosition)
      .cssConfig({
        fontSize: 20,
        color: 'var(--palette-color-1)',
        textAlignment: 'center',
        verticalAlignment: 'center',
        backGroundColor: '#F2F4F7',
        paddingLeft: 10,
        paddingRight: 10,
      })
      .build(),
    new TextWidgetBuilder()
      .text(`Any missing data? Try refreshing using the “Refresh Data” button.`)
      .configDisplayName('Text Widget: Refresh Data Text')
      .gridPosition({ x: 34, y: 3, rows: 2, cols: 9 } as GridPosition)
      .cssConfig({
        fontSize: 16,
        color: 'var(--typography-color-0)',
        textAlignment: 'right',
      })
      .build(),
    refreshBeneficiaryButton,
    new TextWidgetBuilder()
      .text(
        `<p><span class="font-weight-bold"> Note before proceeding : </span> The red-highlighted rows show errors; you can't proceed with them. Yellow-highlighted rows indicate duplications. Blue-highlighted rows indicate a mismatch in the CoP check with the beneficiary. Review carefully before moving forward.</p>`,
      )
      .configDisplayName('Text Widget: Note Before Proceed')
      .gridPosition({ x: 0, y: 6, rows: 2, cols: 38 } as GridPosition)
      .cssConfig({
        fontSize: 16,
        color: 'var(--typography-color-0)',
        textAlignment: 'left',
      })
      .build(),
  ];
}
function bulkPaymentListing() {
  return [
    new DesktopBulkPaymentListingWidgetBuilder()
      .text('Bulk Payment Listing')
      .configDisplayName(`Bulk Payment Listing`)
      .gridPosition({ x: 0, y: 9, rows: 11, cols: 45 } as GridPosition)
      .cssConfig({
        color: 'var(--palette-color-1)',
        textAlignment: 'left',
        justifyContent: 'left',
        fontSize: '16',
        borderWidth: 1,
        borderColor: 'var(--palette-color-2)',
        backGroundColor: 'var(--palette-color-6)',
        borderStyle: 'solid',
        borderRadiusUnit: 'px',
        borderRadiusTopLeft: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusBottomRight: 5,
      })
      .attributes({
        widgetProperties: {
          icon: 'search',
          textContent: ``,
          showEntity: false,
          entity: 'bulk_payment',
          menuOptions: [
            { label: 'Inspect Suggestion', value: 'inspect-suggestion', isIdNotExist: '' },
            { label: 'Continue Anyway', value: 'continue-anyway', isIdNotExist: '' },
            { label: 'Skip', value: 'skip', isIdNotExist: '' },
          ],
        } as BaseWidgetProperties,
        columns: bulkPaymentReviewListingColumns,
        viewListOption: 'none',
      } as unknown as WorkflowWidgetConfigurationAttributes)
      .inputSetting({
        ...inputSettings,
        css_config: {
          ...inputSettings.css_config,
          inputWidth: 110,
        },
        label: 'Search',
        isIconAllowed: true,
      })
      .build(),
    new CustomQuestionWidgetBuilder()
      .gridPosition({ x: 0, y: 20, rows: 2, cols: 45 } as GridPosition)
      .configDisplayName(`Custom Question Widget: Checkbox`)
      .cssConfig({ borderWidth: '0' })
      .attributes({
        customDataQuestionData: {
          data_type: 'stringArray',
          entity: 'bulk_payment',
          sink: SINK_TYPE.METADATA,
          isUpdateToHasura: false,
        },
        label: ``,
        inputType: 'checkbox',
        questionUniqueId: 'beneficiary show in beneficiary list',
        elementsConfig: [
          {
            field_name: WidgetNames.CUSTOM_QUESTION_WIDGET,
            label: 'checkbox',
            type: 'checkbox',
            section: 'questionwidget',
            show: false,
            options: [],
            uniqueId: 'beneficiary show in beneficiary list',
          },
        ],
        validationRules: [],
        questionProperties: {
          name: 'beneficiary show in beneficiary list',
          label: 'label',
          type: 'checkbox',
          options: [
            {
              text: 'Display newly added beneficiaries in the existing beneficiary list.',
              value: 'add_beneficiary_in_existing_beneficiary',
              suboption: [],
            },
          ],
          preSelectSubAnswer: 'false',
        },
        widgetProperties: { showHeader: false, showSubHeader: false },
      } as unknown as WorkflowWidgetConfigurationAttributes)
      .build(),
    new LineWidgetBuilder()
      .configDisplayName('Line Widget : Beneficiary Listing Line')
      .gridPosition({ x: 0, y: 22, rows: 1, cols: 45 } as GridPosition)
      .attributes({
        widgetProperties: {
          color: 'var(--border-color-1)',
          'border-bottom': 'var(--border-color-1)',
          lineThickness: 1,
          lineWidth: '100',
          textContent: '',
          show: true,
          contentAlignment: 'center',
        } as BaseWidgetProperties,
      } as WorkflowWidgetConfigurationAttributes)
      .build(),
    new TextWidgetBuilder()
      .text(
        '<p>Please carefully review the payment details before proceeding with execution. Once you proceed with adding the beneficiaries, they will be included in the existing list of your beneficiaries. Once payments are initiated, they cannot be reversed. If any modifications are needed,  you`ll need to upload a new CSV file.</p>',
      )
      .configDisplayName('Text Widget: Note Text')
      .gridPosition({ x: 0, y: 23, rows: 2, cols: 45 } as GridPosition)
      .cssConfig({
        fontSize: 16,
        textAlignment: 'left',
      })
      .build(),

    getQuickLinkWidget({
      text: 'Upload New File',
      position: { x: 0, y: 25, rows: 1, cols: 10 } as GridPosition,
      pageUrl: 'bulk-payment-upload-download',
      displayName: 'Quick Link: Upload New File',
      styles: {
        color: 'var(--palette-color-3)',
        fontSize: '16',
        backGroundColor: 'transparent',
        textAlignment: 'center',
        justifyContent: 'center',
        borderColor: 'var(--palette-color-3)',
        borderStyle: 'solid',
        borderWidth: 1,
      },
      zone: APP_ZONES.PAYMENT,
      isRemovable: false,
      icon: 'upload',
    }),
  ];
}
