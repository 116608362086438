import {
  accountCredentialCurrencyFlagInfoTemplate,
  accountCredentialCurrencyInfoTemplate,
  accountCredentialInfoTemplate,
  accountTransactionBalanceTemplate,
  accountTransactionCurrencyInfoTemplate,
} from '@finxone-platform/shared/default-templates';
import {
  APP_ZONES,
  ButtonSizeOptions,
  ButtonTypes,
  CommonPageConfig,
  GridPosition,
  Page,
  WorkflowWidgetConfig,
} from '@finxone-platform/shared/sys-config-types';
import {
  CurrencyExchangeTransactionHistoryBuilder,
  QuickLinkWidgetBuilder,
  SwitchNavigatorWidgetBuilder,
  TextWidgetBuilder,
} from '../../widgets/example-builder';
import { defaultPageConfigurations, switchNavigatorCss } from '../../widgets/workflow-ui-constants.v2.type';
import { exchangeTransactionElaboratedViewWidgets } from './widget-sections/exchange-transaction-elaborated-view';

export const defaultAccountsPages: {
  name: string;
  title: string;
  url?: string;
  widgets: WorkflowWidgetConfig[];
  tags: string[];
  pageConfiguration?: CommonPageConfig;
}[] = [
  {
    name: 'account-transactions',
    title: 'Account Transactions',
    tags: [],
    url: 'account-transactions',
    widgets: [
      new TextWidgetBuilder()
        .text('<h2 class="w-100 font-weight-bold"> Account Transactions </h2>')
        .configDisplayName('Account Credentials')
        .gridPosition({ y: 1 } as GridPosition)
        .cssConfig({
          textAlignment: 'left',
          justifyContent: 'left',
          fontSize: '20',
        })
        .isRemovable(true)
        .build(),

      new TextWidgetBuilder()
        .text(accountTransactionCurrencyInfoTemplate)
        .configDisplayName('Currency Type')
        .gridPosition({ y: 3 } as GridPosition)
        .cssConfig({
          textAlignment: 'left',
          justifyContent: 'left',
          fontSize: '18',
        })
        .isRemovable(true)
        .build(),

      new SwitchNavigatorWidgetBuilder()
        .linksData([
          {
            textContent: 'Transactions',
            pageIndex: 0,
            zone: APP_ZONES.ACCOUNTS_ZONE,
          },
          {
            textContent: 'Credentials',
            pageIndex: 1,
            zone: APP_ZONES.ACCOUNTS_ZONE,
          },
        ])
        .navigationOption('use-as-navigation')
        .role()
        .gridPosition({ y: 5, rows: 2 } as GridPosition)
        .cssConfig({
          ...switchNavigatorCss,
          backGroundColor: 'var(--palette-color-3)',
          disabledTextColor: 'var(--palette-color-1)',
          fontSize: 14,
          borderColor: 'var(--palette-color-1)',
        })
        .build(),

      new TextWidgetBuilder()
        .text(accountTransactionBalanceTemplate)
        .configDisplayName('Balance')
        .gridPosition({ y: 8, rows: 5 } as GridPosition)
        .cssConfig({
          textAlignment: 'left',
          justifyContent: 'left',
          fontSize: '30',
          backGroundColor: '#F2F4F7',
          paddingLeft: '30',
          paddingTop: '25',
        })
        .isRemovable(true)
        .build(),

      new CurrencyExchangeTransactionHistoryBuilder()
        .gridPosition({ y: 14, rows: 13 } as GridPosition)
        .isRemovable(true)
        .build(),

      new QuickLinkWidgetBuilder()
        .zone(APP_ZONES.PAYMENT)
        .navigateToPageUrl('exchange-currency-buy')
        .navigationOption('use-as-navigation')
        .role()
        .configDisplayName('Quick Link: Exchange Currency')
        .text('Exchange Currency')
        .gridPosition({ y: 28, rows: 2 } as GridPosition)
        .cssConfig({
          backGroundColor: 'var(--palette-color-3)',
          color: 'var(--palette-color-6)',
          borderRadiusTopLeft: 6,
          borderRadiusBottomLeft: 6,
          borderRadiusTopRight: 6,
          borderRadiusBottomRight: 6,
          fontSize: 14,
          marginBottom: 25,
          borderWidth: '1',
          borderColor: 'var(--palette-color-3)',
        })
        .isRemovable(true)
        .setGlobalStylingClass(true, `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`)
        .build(),

      new QuickLinkWidgetBuilder()
        .zone(APP_ZONES.PAYMENT)
        .navigateToPageUrl('currency-accounts-landing')
        .navigationOption('use-as-navigation')
        .role()
        .configDisplayName('Quick Link: Back')
        .text('Back')
        .gridPosition({ y: 31, rows: 2 } as GridPosition)
        .cssConfig({
          color: 'var(--palette-color-3)',
          borderWidth: '1',
          borderColor: 'var(--palette-color-3)',
          fontSize: 14,
          marginBottom: 25,
          borderRadiusTopLeft: 6,
          borderRadiusBottomLeft: 6,
          borderRadiusTopRight: 6,
          borderRadiusBottomRight: 6,
        })
        .isRemovable(true)
        .setGlobalStylingClass(true, `${ButtonTypes.SECONDARY} ${ButtonSizeOptions.FULL_WIDTH}`)
        .build(),
    ],
  },
  {
    name: Page.ACCOUNT_CREDENTIALS,
    title: 'Account Credentials',
    tags: [],
    url: 'account-credentials',
    widgets: [
      new TextWidgetBuilder()
        .text('<h2 class="w-100 font-weight-bold"> Account Credentials </h2>')
        .configDisplayName('Account Credentials')
        .gridPosition({ y: 1 } as GridPosition)
        .cssConfig({
          textAlignment: 'left',
          justifyContent: 'left',
          fontSize: '20',
        })
        .isRemovable(true)
        .build(),

      new TextWidgetBuilder()
        .text(accountCredentialCurrencyInfoTemplate)
        .configDisplayName('Currency Type')
        .gridPosition({ y: 3 } as GridPosition)
        .cssConfig({
          textAlignment: 'left',
          justifyContent: 'left',
          fontSize: '18',
        })
        .isRemovable(true)
        .build(),

      new SwitchNavigatorWidgetBuilder()
        .linksData([
          {
            textContent: 'Transactions',
            pageIndex: 0,
            zone: APP_ZONES.ACCOUNTS_ZONE,
          },
          {
            textContent: 'Credentials',
            pageIndex: 1,
            zone: APP_ZONES.ACCOUNTS_ZONE,
          },
        ])
        .navigationOption('use-as-navigation')
        .role()
        .gridPosition({ y: 5, rows: 2 } as GridPosition)
        .cssConfig({
          ...switchNavigatorCss,
          backGroundColor: 'var(--palette-color-3)',
          disabledTextColor: 'var(--palette-color-1)',
          fontSize: 14,
          borderColor: 'var(--palette-color-1)',
        })
        .build(),

      new TextWidgetBuilder()
        .text(accountCredentialCurrencyFlagInfoTemplate)
        .configDisplayName('Currency Flag Type')
        .gridPosition({ y: 8, rows: 3 } as GridPosition)
        .cssConfig({
          backGroundColor: '#F2F4F7',
          textAlignment: 'left',
          paddingTop: '15',
          paddingLeft: '18',
          justifyContent: 'left',
          fontSize: '20',
          isTextBold: true,
        })
        .isRemovable(true)
        .build(),

      new TextWidgetBuilder()
        .text(accountCredentialInfoTemplate)
        .configDisplayName('Account Information')
        .gridPosition({ y: 12, rows: 15 } as GridPosition)
        .cssConfig({
          backGroundColor: '#F2F4F7',
          paddingTop: '30',
          paddingBottom: '30',
          paddingLeft: '20',
          paddingRight: '30',
          borderRadiusTopLeft: '5',
          borderRadiusTopRight: '5',
          borderRadiusBottomLeft: '5',
          borderRadiusBottomRight: '5',
          display: 'flex',
          verticalAlignment: 'start',
          textAlignment: 'left',
          fontSize: 18,
        })
        .isRemovable(true)
        .build(),

      new QuickLinkWidgetBuilder()
        .zone(APP_ZONES.PAYMENT)
        .navigateToPageUrl('exchange-currency-buy')
        .navigationOption('use-as-navigation')
        .role()
        .configDisplayName('Quick Link: Exchange Currency')
        .text('Exchange Currency')
        .gridPosition({ y: 28, rows: 2 } as GridPosition)
        .cssConfig({
          backGroundColor: 'var(--palette-color-3)',
          color: 'var(--palette-color-6)',
          borderRadiusTopLeft: 6,
          borderRadiusBottomLeft: 6,
          borderRadiusTopRight: 6,
          borderRadiusBottomRight: 6,
          fontSize: 14,
          marginBottom: 25,
          borderWidth: '1',
          borderColor: 'var(--palette-color-3)',
        })
        .isRemovable(true)
        .setGlobalStylingClass(true, `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`)
        .build(),

      new QuickLinkWidgetBuilder()
        .zone(APP_ZONES.PAYMENT)
        .navigateToPageUrl('currency-accounts-landing')
        .navigationOption('use-as-navigation')
        .role()
        .configDisplayName('Quick Link: Back')
        .text('Back')
        .gridPosition({ y: 31, rows: 2 } as GridPosition)
        .cssConfig({
          color: 'var(--palette-color-3)',
          borderWidth: '1',
          borderColor: 'var(--palette-color-3)',
          fontSize: 14,
          marginBottom: 25,
          borderRadiusTopLeft: 6,
          borderRadiusBottomLeft: 6,
          borderRadiusTopRight: 6,
          borderRadiusBottomRight: 6,
        })
        .isRemovable(true)
        .setGlobalStylingClass(true, `${ButtonTypes.SECONDARY} ${ButtonSizeOptions.FULL_WIDTH}`)
        .build(),
    ],
  },
  {
    name: 'exchange-elaborated-view',
    title: 'Exchange Elaborated View',
    tags: [],
    url: 'exchange-elaborated-view',
    widgets: exchangeTransactionElaboratedViewWidgets(),
    pageConfiguration: {
      ...defaultPageConfigurations,
      isUsingHtmlStructure: true,
      backgroundColor: 'var(--color-background)',
    } as CommonPageConfig,
  },
];
