import {
  Constants,
  GridPosition,
  TabsOrientationType,
  WidgetNames,
  WidgetTitles,
  WorkflowWidgetConfig,
} from '@finxone-platform/shared/sys-config-types';

// Tab presets
const generateTabWidgetConfig = (
  tabCount: number,
  orientation: TabsOrientationType,
  widgetDisplayName: string,
  widgetIconPath: string,
): WorkflowWidgetConfig => {
  return {
    widgetTypeConfig: 'new',
    uniqueId: Math.floor(Math.random() * (1000000000 + 1)),
    name: WidgetNames.TAB,
    widgetConfiguration: {
      widgetDisplayName: widgetDisplayName,
      widgetConfigDisplayName: WidgetTitles.TAB,
      attributes: {
        elementsConfig: [],
        css_config: {
          elementStyling: {
            tabCss: `hlm-tabs-list {\n}\nhlm-tabs-list button {\n}\nhlm-tabs-list button:hover {\n}\nhlm-tabs-list button[data-state=active] {\n}`,
          },
        },
        widgetProperties: {
          textContent: '',
          tabOrientationType: orientation,
          tabConfigs: [...Array(tabCount)].map((_, index) => ({
            title: `Tab ${index + 1}`,
            tabRef: `tab${index + 1}`,
            containerName: `Tab: ${index + 1}`,
          })),
        },
      },
      isRemovable: true,
    },
    gridPosition: undefined as unknown as GridPosition,
    widgetIconPath: widgetIconPath,
    childComponents: [...Array(tabCount)].map(() => []),
  } as WorkflowWidgetConfig;
};

export const twoTabsHorizontalWidgetConfig = generateTabWidgetConfig(
  2,
  TabsOrientationType.HORIZONTAL,
  'Horizontal - 2',
  'two-column-tab-horizontal-layout',
);

export const threeTabsHorizontalWidgetConfig = generateTabWidgetConfig(
  3,
  TabsOrientationType.HORIZONTAL,
  'Horizontal - 3',
  'three-column-tab-horizontal-layout',
);

export const threeTabsVerticalWidgetConfig = generateTabWidgetConfig(
  3,
  TabsOrientationType.VERTICAL,
  'Vertical - 3',
  'tab-three-vertical-layout',
);

export const fourTabsVerticalWidgetConfig = generateTabWidgetConfig(
  4,
  TabsOrientationType.VERTICAL,
  'Vertical - 4',
  'tab-four-vertical-layout',
);

// Multi column presets
const generateColumnWidgetConfig = (
  columnCount: number,
  widgetDisplayName: string,
  widgetIconPath: string,
): WorkflowWidgetConfig => {
  return {
    widgetTypeConfig: 'new',
    uniqueId: Math.floor(Math.random() * (1000000000 + 1)),
    name: WidgetNames.TWO_COLUMN,
    widgetConfiguration: {
      widgetDisplayName: widgetDisplayName,
      widgetConfigDisplayName: WidgetTitles.MULTI_COLUMN,
      attributes: {
        outerCss: 'height: 100px;',
        elementsConfig: [],
        css_config: {
          ...Constants.defaultBaseCssConfig,
          borderWidthTop: 0,
          borderWidthBottom: 0,
          borderWidthLeft: 0,
          borderWidthRight: 0,
          borderWidthUnit: 'px',
          columnGap: 0,
          columnGapUnit: 'px',
          borderClass: 'none',
        },
        widgetProperties: {
          textContent: '',
          columnOneOuterCss: '',
          columnTwoOuterCss: '',
          columnOnePercent: 50,
          selectedNoOfColumn: columnCount,
          selectedColumnRange: [],
          selectedSplitType: 'even',
          columnConfigs: [...Array(columnCount)].map((_, index) => ({
            outerCssConfig: '',
            containerName: `Column: ${index + 1}`,
          })),
        },
      },
      isRemovable: true,
    },
    gridPosition: undefined as unknown as GridPosition,
    widgetIconPath: widgetIconPath,
    childComponents: [...Array(columnCount)].map(() => []),
  };
};

export const twoColumnsWidgetConfig = generateColumnWidgetConfig(
  2,
  '2 Column',
  'two-column-tab-horizontal-layout',
);
export const threeColumnsWidgetConfig = generateColumnWidgetConfig(
  3,
  '3 Column',
  'three-column-tab-horizontal-layout',
);
export const fourColumnsWidgetConfig = generateColumnWidgetConfig(4, '4 Column', 'column-four-layout');

export const fiveColumnsWidgetConfig = generateColumnWidgetConfig(5, '5 Column', 'column-five-layout');
