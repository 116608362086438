import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { FormActionTypeEnum } from '@finxone-platform/form-action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import {
  AddEditFeeGroupRequestPayload,
  BaseWidgetProperties,
} from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { take, tap } from 'rxjs';
import { UpdateFormDataActionWithId } from '../../../actions/form-submission.action';
import { FeeManagementService } from '../../../services/fee-management-service/fee-management.service';
import { FormActionState } from '../../../state/form-submision.state';
import { redirectToPage } from '../cta-button-actions.utils';

/**
 * Handles the submission of adding or editing a fee group based on the form data.
 *
 * @param widgetProperties - The properties of the widget triggering the action.
 * @param injector - The Angular injector used to retrieve necessary services.
 *
 * This function retrieves the form data from the store and checks its validity.
 * If the form is valid, it constructs a payload and submits it to the FeeManagementService.
 * Depending on whether an ID is present in the form data, it either updates an existing fee group
 * or creates a new one. Upon successful submission, a success alert is shown and the user is redirected.
 * If the form is invalid, an error alert is shown and form errors are displayed.
 */
export function handleAddEditFeeGroupSubmission(widgetProperties: BaseWidgetProperties, injector: Injector) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  const alertHandlerService = injector.get(AlertHandlerService);
  const feeManagementService = injector.get(FeeManagementService);
  const formData = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.ADD_FEE_GROUP),
  )?.formData;
  if (formData) {
    if (formData?.isFormValid) {
      const payload: AddEditFeeGroupRequestPayload = {
        name: formData?.groupName,
        description: formData?.description,
      };
      if (formData?.id) {
        feeManagementService
          .submitEditFeeGroupRequest(payload, formData.id)
          .pipe(
            take(1),
            tap((res) => {
              if (res) {
                alertHandlerService.showAlertFn('success', 'Fee group updated successfully');
                redirectToPage(router, widgetProperties, injector);
              }
            }),
          )
          .subscribe();
      } else {
        feeManagementService
          .submitAddFeeGroupRequest(payload)
          .pipe(
            take(1),
            tap((res) => {
              if (res) {
                alertHandlerService.showAlertFn('success', 'Fee group created successfully');
                redirectToPage(router, widgetProperties, injector);
              }
            }),
          )
          .subscribe();
      }
    } else {
      alertHandlerService.showAlertFn('error', 'Please provide all details in the form before proceeding');
      store.dispatch(
        new UpdateFormDataActionWithId({ showFormErrors: true }, FormActionTypeEnum.ADD_FEE_GROUP),
      );
    }
  }
}
