import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonActionType,
  GridPosition,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import {
  FormSubmissionButtonWidgetBuilder,
  ReviewUploadBoardingPassWidgetBuilder,
} from '../../../../widgets/example-builder';
import { commonBackButtonQuickLink, commonTextWidget } from '../../../common/common-widgets';

export const tripZoneReviewUploadBoardingPassWidgets = [
  commonBackButtonQuickLink({
    cssConfig: {
      iconSize: 35,
      iconUnit: 'px',
    },
    gridPosition: {
      rows: 2,
      cols: 2,
      y: 0,
      x: 0,
      layerIndex: 1,
      resizeEnabled: true,
      dragEnabled: true,
    },
  }),

  commonTextWidget({
    textContent: 'Review Boarding Pass Details',
    configDisplayName: 'Review Boarding Pass Details',
    cssConfig: {
      fontSize: 24,
      isTextBold: true,
      textFont: 'var(--font-family)',
    },
    gridPosition: {
      y: 2,
      x: 1,
      rows: 3,
      cols: 13,
      resizeEnabled: true,
      dragEnabled: true,
      layerIndex: 1,
    },
  }),

  new ReviewUploadBoardingPassWidgetBuilder().gridPosition({ y: 6, rows: 15 } as GridPosition).build(),

  new FormSubmissionButtonWidgetBuilder()
    .configDisplayName('Button: Upload boarding pass')
    .attributes({
      widgetProperties: {
        textContent: 'Upload boarding pass',
        zoneToNavigate: APP_ZONES.REVENIR_ZONE,
        urlToNavigate: 'exit-point',
        buttonActionType: ButtonActionType.REVENIR_PREVIEW_BOARDING_PASS,
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .cssConfig({
      disabledBgColor: 'var(--palette-color-3)',
      disabledTextColor: 'var(--palette-color-6)',
    })
    .gridPosition({
      x: 0,
      y: 26,
      rows: 2,
    } as GridPosition)
    .build(),
];
