import { formatNameAsUrl } from '@app/finxone-web-frontend/app/lib/utils/zone-url.utils';
import { WorkflowPageConfig } from '@finxone-platform/shared/sys-config-types';
import { PageConfig } from '../../../../zones/mobile/default-zone-page-setups';
import { AddEditFeeWidgetBuilder } from '../../../widgets/example-builder';
import { defaultPageConfigurations } from '../../../widgets/workflow-ui-constants.v2.type';

export function buildFeeManagementZonePagesConfigMobile(): WorkflowPageConfig[] {
  const zonePages: WorkflowPageConfig[] = [];
  const initialPages: PageConfig[] = [
    {
      name: 'Fee/Client List',
      title: 'Fee/Client List',
      widgets: [new AddEditFeeWidgetBuilder().build()],
      tags: [],
    },
  ];

  initialPages.forEach((page: PageConfig) => {
    const pageObj: WorkflowPageConfig = {
      name: page.name,
      title: page.title,
      widgets: page.widgets ?? [],
      pageConfiguration: {
        ...defaultPageConfigurations,
        url: formatNameAsUrl(page.name),
        pageType: 'dynamicGrid',
        isRemovable: false,
        mainComponentPageName: 'ParentContainerComponent',
      },
    };
    zonePages.push(pageObj);
  });
  return zonePages;
}
