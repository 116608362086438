import { Injector } from '@angular/core';
import { ButtonActionType, UiZoneWidgetAttributeConfig } from '@finxone-platform/shared/sys-config-types';
import { functionCtaType } from '../cta-button-actions.utils';
import { paymentApproverInitiateDomesticPayment } from '../domestic-payments/payment-approver-init-payment.utils';
import { checkPaymentRequest } from '../domestic-payments/payment-request';
import { paymentRequestApprove } from '../domestic-payments/payment-request-approve';
import { paymentRequestProcessing } from '../domestic-payments/payment-request-processing';
import { paymentRequestReject } from '../domestic-payments/payment-request-reject';

export const paymentRequestActions: Map<ButtonActionType, functionCtaType> = new Map([
  [
    ButtonActionType.PAYMENT_REQUEST,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) =>
      checkPaymentRequest(attri.widgetProperties, injector),
  ],
  [
    ButtonActionType.PAYMENT_REQUEST_PROCESSING,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) =>
      paymentRequestProcessing(attri.widgetProperties, injector),
  ],
  [
    ButtonActionType.PAYMENT_REQUEST_APPROVE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) =>
      paymentRequestApprove(attri.widgetProperties, injector),
  ],
  [
    ButtonActionType.PAYMENT_REQUEST_REJECT,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) =>
      paymentRequestReject(attri.widgetProperties, injector),
  ],
  [
    ButtonActionType.PAYMENT_APPROVER_MAKE_PAYMENT,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) =>
      paymentApproverInitiateDomesticPayment(attri.widgetProperties, injector),
  ],
]);
