import { ElementRef } from '@angular/core';

//Setting overflow to hidden to ensure no scrollbar appear on custom grid
export function setOverFlowHiddenOnBodyTag() {
  const body = document.getElementById('web-app');
  if (body) {
    body.style.overflow = 'hidden';
  }
}

export function setOverFlowAutoOnBodyTag() {
  const body = document.getElementById('web-app');
  if (body) {
    body.style.overflow = 'auto';
  }
}

export function scrollLoading(id: string, eleRef: ElementRef<any>): string {
  const sectionHeight = eleRef.nativeElement.closest('section')?.style.height;
  const element = document.getElementById(id);

  let elementHeight: string;

  if (element) {
    if (element.scrollHeight > 0) {
      elementHeight = element.scrollHeight - 100 + 'px';
    } else if (element.offsetTop > 0) {
      elementHeight = element.offsetTop - 150 + 'px';
    } else {
      elementHeight = '400px';
    }
  } else {
    elementHeight = '400px';
  }

  return sectionHeight ?? elementHeight;
}
