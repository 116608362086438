import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { KeycloakCustomService } from '@app/finxone-web-frontend/app/lib/services/keycloak-wrapper-service/keycloak-custom.service';

@Component({
  selector: 'finxone-web-frontend-pin-boxes',
  templateUrl: './pin-boxes.component.html',
  styleUrl: './pin-boxes.component.scss',
})
export class PinBoxesComponent implements AfterViewInit, OnDestroy {
  @ViewChild('pinInput', { static: false }) pinInput!: ElementRef<HTMLInputElement>;
  @Input() public pin = '';
  @Input() public error = '';
  @Input() public maxLength = 6;
  @Input() confirmationType: string | undefined;
  @Input() localeValues: any;
  @Output() pinBoxes = new EventEmitter();
  @Output() pinChange = new EventEmitter<string>();
  public pinBoxesFocused = true;
  public timer = 60;
  public isDisableResendBtn: boolean;
  private intervalId: NodeJS.Timer;
  public isOtp: boolean;
  constructor(private keycloakService: KeycloakCustomService) {}

  ngAfterViewInit() {
    if (this.confirmationType === 'SMS' || this.confirmationType === 'EMAIL') {
      this.isOtp = true;
      if (this.confirmationType === 'SMS') {
        //changing to PHONE to match the keycloak service
        this.confirmationType = 'PHONE';
      }
      this.sendOtp();
    }
    setTimeout(() => {
      this.pinInput.nativeElement.focus();
    }, 1000);
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  private startCountdown(): void {
    this.timer = 60; // Reset timer to 60 seconds
    this.isDisableResendBtn = true;
    this.intervalId = setInterval(() => {
      if (this.timer > 0) {
        this.timer--;
      } else {
        this.clearTimer();
      }
    }, 1000);
  }
  private clearTimer(): void {
    this.isDisableResendBtn = false;
    clearInterval(this.intervalId);
  }

  public async sendOtp() {
    if (!this.confirmationType) return;
    this.startCountdown();
    await this.keycloakService.sendChallenge({ type: this.confirmationType });
  }

  public handleDesktopPinBoxes(e: Event) {
    const input: string = (e.target as HTMLInputElement).value.replace(/[^0-9]/g, '');
    if (input.length <= this.maxLength) {
      this.pin = input;
      this.pinChange.emit(this.pin);
      this.error = '';
      this.pinBoxes.emit();
    }
  }

  public pinBoxesFocus() {
    this.pinInput.nativeElement.focus();
    this.pinBoxesFocused = true;
  }

  public pinBoxesBlur() {
    this.pinBoxesFocused = false;
  }
}
