import { userInfoReviewTemplate } from '@finxone-platform/shared/default-templates';
import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonActionType,
  ButtonSizeOptions,
  ButtonTypes,
  CommonPageConfig,
  DatePickerTypes,
  GridPosition,
  KycLevel,
  PageHooks,
  SupportedDevice,
  WidgetNames,
  WorkflowPageConfig,
  WorkflowWidgetConfig,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import {
  buildOtpVerificationPageWidgetsConfig,
  getPageSubheaderTextWidget,
  getPageTitleTextWidget,
} from '../../page-builder-utils/widget-builder-utils/otp-verification-page';
import { PageConfig } from '../../zones/mobile/default-zone-page-setups';
import {
  BirthdayWidgetBuilder,
  EditAddressWidgetBuilder,
  EmailWidgetBuilder,
  FormSubmissionButtonWidgetBuilder,
  ImageWidgetBuilder,
  NameWidgetBuilder,
  PhoneWidgetBuilder,
  QuickLinkWidgetBuilder,
  TextWidgetBuilder,
} from '../widgets/example-builder';
import { defaultInputCssConfig } from '../widgets/payments/payments.type';
import {
  businessStatusScreen,
  businessStatusScreenDesktop,
  businessStatusScreenTablet,
} from '../widgets/verification/business-status-screen.pages';
import {
  personalStatusScreen,
  personalStatusScreenDesktop,
  personalStatusScreenTablet,
} from '../widgets/verification/personal-status-screen.pages';
import { sumsubWidgetConfig } from '../widgets/verification/verification.type';
import { defaultPageConfigurations } from '../widgets/workflow-ui-constants.v2.type';
import { getBackButtonWidget } from '../zones/manage-users-zone/common-utils';
import { formatPageName } from './utils';
export const profileConfirmPageConfig: WorkflowPageConfig = {
  name: WidgetNames.VERIFICATION_DETAIL_VERIFICATION,
  title: 'User Profile Confirmation',
  pageConfiguration: {
    mainComponentPageName: 'ParentContainerComponent',
    isRemovable: false,
    pageType: 'dynamicGrid',
    url: 'profile-confirm',
    ...defaultPageConfigurations,
  },
  widgets: [],
};

export const thirdPartyKycPageConfig: WorkflowPageConfig = {
  name: WidgetNames.VERIFICATION_PAGE,
  title: 'Third Party KYC',
  pageConfiguration: {
    mainComponentPageName: 'ParentContainerComponent',
    isRemovable: false,
    pageType: 'staticGrid',
    url: 'verify',
    ...defaultPageConfigurations,
    gridsterMargin: {
      left: 0,
      right: 0,
      top: 2,
      unit: 'em',
    },
  },
  widgets: [sumsubWidgetConfig()],
};

export function buildDefaultVerificationPages({
  userType,
  deviceType,
}: {
  userType: string[];
  deviceType: SupportedDevice;
}): PageConfig[] {
  const verificationPages: PageConfig[] = [
    {
      name: 'review-information',
      title: 'Review Information',
      widgets: [
        new ImageWidgetBuilder()
          .configDisplayName('Image Widget: Success Icon')
          .cssConfig({
            selectedobjectfit: 'contain',
          })
          .attributes({
            widgetProperties: {
              imageAssetUrl: 'assets/images/yay-happy-party.svg',
              textContent: '',
            } as BaseWidgetProperties,
          } as WorkflowWidgetConfigurationAttributes)
          .gridPosition({
            x: deviceType === 'desktop' ? 15 : 0,
            rows: 3,
            y: 0,
          } as GridPosition)
          .build(),
        new TextWidgetBuilder()
          .text('<div class="w-100">Welcome to {{ appData.systemName }}!</div>')
          .configDisplayName('Text Widget: Welcome Text')
          .gridPosition({
            x: deviceType === 'desktop' ? 15 : 0,
            y: 3,
          } as GridPosition)
          .cssConfig({
            color: 'var(--typography-color-0)',
            fontSize: '24',
            textAlignment: 'center',
            isTextBold: true,
          })
          .isRemovable(false)
          .build(),
        new TextWidgetBuilder()
          .text(
            '<div class="w-100">Welcome to our application! Review your summary below, and make any necessary changes on the edit information page. If everything looks good, you can proceed to the sumsub verification.</div>',
          )
          .configDisplayName('Text Widget: Welcome Text')
          .gridPosition({
            x: deviceType == 'desktop' ? 15 : 0,
            y: 5,
            rows: 6,
          } as GridPosition)
          .cssConfig({
            color: 'var(--typography-color-1)',
            fontSize: '16',
            textAlignment: 'center',
          })
          .isRemovable(false)
          .build(),
        new TextWidgetBuilder()
          .text(userInfoReviewTemplate)
          .configDisplayName('Text Widget: User Info')
          .cssConfig({
            color: 'var(--typography-color-0)',
            fontSize: '16',
            textAlignment: 'left',
            backGroundColor: 'var(--palette-color-4)',
            paddingTop: '20',
            paddingBottom: '20',
            paddingLeft: '15',
            paddingRight: '15',
            verticalAlignment: 'start',
            borderRadiusTopLeft: '5',
            borderRadiusTopRight: '5',
            borderRadiusBottomLeft: '5',
            borderRadiusBottomRight: '5',
          })
          .gridPosition({
            x: deviceType == 'desktop' ? 15 : 0,
            y: 11,
            rows: 18,
          } as GridPosition)
          .isRemovable(false)
          .build(),
        new QuickLinkWidgetBuilder()
          .zone(APP_ZONES.VERIFICATION)
          .navigateToPageUrl('information-confirmation')
          .navigationOption('use-as-navigation')
          .role()
          .text('Verify Me')
          .configDisplayName('Quick Link: Verify Me')
          .cssConfig({
            borderWidth: 1,
            borderColor: 'var(--border-color-0)',
            backGroundColor: 'var(--palette-color-3)',
            color: 'var(--palette-color-6)',
            borderRadiusTopLeft: 5,
            borderRadiusBottomLeft: 5,
            borderRadiusTopRight: 5,
            borderRadiusBottomRight: 5,
            fontSize: 16,
          })
          .gridPosition({
            x: deviceType == 'desktop' ? 15 : 0,
            y: 30,
            rows: 2,
          } as GridPosition)
          .setGlobalStylingClass(true, `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`)
          .build(),

        new FormSubmissionButtonWidgetBuilder()
          .configDisplayName('Button: Edit Information')
          .attributes({
            widgetProperties: {
              textContent: 'Edit Information',
              zoneToNavigate: APP_ZONES.VERIFICATION,
              urlToNavigate: 'edit-information-name',
              buttonActionType: ButtonActionType.VERIFICATION_EDIT_INFORMATION,
            } as BaseWidgetProperties,
          } as WorkflowWidgetConfigurationAttributes)
          .cssConfig({
            backGroundColor: 'var(--palette-color-6)',
            color: 'var(--palette-color-3)',
            borderRadiusTopLeft: 6,
            borderRadiusBottomLeft: 6,
            borderRadiusTopRight: 6,
            borderRadiusBottomRight: 6,
            fontSize: 14,
            marginTop: 0,
            marginBottom: 0,
            paddingTop: 10,
            paddingBottom: 15,
            paddingLeft: 15,
            paddingRight: 15,
            borderWidth: '1',
            borderColor: 'var(--palette-color-3)',
          })
          .isRemovable(false)
          .gridPosition({
            x: deviceType == 'desktop' ? 15 : 0,
            y: 33,
            rows: 2,
            cols: 15,
          } as GridPosition)
          .setGlobalStylingClass(true, `${ButtonTypes.SECONDARY} ${ButtonSizeOptions.FULL_WIDTH}`)
          .build(),
      ],
      tags: ['org', 'user'],
    },
    {
      name: 'edit-information-name',
      title: 'Edit Information - Name',
      widgets: [
        getPageTitleTextWidget({
          gridPosition: { x: deviceType == 'desktop' ? 15 : 0 },
        }),
        getPageSubheaderTextWidget({
          gridPosition: { x: deviceType == 'desktop' ? 15 : 0 },
        }),
        new TextWidgetBuilder()
          .text('<div class="w-100">Full Name - Information</div>')
          .configDisplayName('Text Widget: Full Name')
          .gridPosition({
            x: deviceType == 'desktop' ? 15 : 0,
            y: 4,
            rows: 2,
          } as GridPosition)
          .cssConfig({
            color: 'var(--typography-color-0)',
            fontSize: '20',
            textAlignment: 'left',
            isTextBold: true,
          })
          .isRemovable(false)
          .build(),
        new NameWidgetBuilder()
          .cssConfig({
            borderWidth: '0',
            isTextBold: true,
            fontSize: '14',
          })
          .isRemovable(false)
          .gridPosition({
            x: deviceType == 'desktop' ? 15 : 0,
            y: 6,
            rows: 10,
          } as GridPosition)
          .build(),
        new TextWidgetBuilder()
          .text('<div class="w-100">Date of Birth</div>')
          .configDisplayName('Text Widget: Date of Birth')
          .gridPosition({
            x: deviceType == 'desktop' ? 15 : 0,
            y: 16,
            rows: 2,
          } as GridPosition)
          .cssConfig({
            color: 'var(--typography-color-0)',
            fontSize: '20',
            textAlignment: 'left',
            isTextBold: true,
          })
          .isRemovable(false)
          .build(),
        new BirthdayWidgetBuilder()
          .cssConfig({
            borderWidth: '0',
            isTextBold: true,
            fontSize: '14',
          })
          .isRemovable(false)
          .gridPosition({
            x: deviceType == 'desktop' ? 15 : 0,
            y: 18,
            rows: 5,
          } as GridPosition)
          .setWidgetProperties({
            textContent: '',
            dateType: DatePickerTypes.INPUT,
            inputFieldSettings: {
              css_config: defaultInputCssConfig,
            },
          })
          .build(),
        new QuickLinkWidgetBuilder()
          .zone(APP_ZONES.VERIFICATION)
          .navigateToPageUrl('edit-information-contact')
          .navigationOption('use-as-navigation')
          .role()
          .text('Next')
          .configDisplayName('Quick Link: Next')
          .cssConfig({
            borderWidth: 1,
            borderColor: 'var(--border-color-0)',
            backGroundColor: 'var(--palette-color-3)',
            color: 'var(--palette-color-6)',
            borderRadiusTopLeft: 5,
            borderRadiusBottomLeft: 5,
            borderRadiusTopRight: 5,
            borderRadiusBottomRight: 5,
            fontSize: 16,
          })
          .gridPosition({
            x: deviceType == 'desktop' ? 15 : 0,
            y: 26,
            rows: 2,
          } as GridPosition)
          .setGlobalStylingClass(true, `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`)
          .build(),
        getBackButtonWidget(
          {
            position: {
              x: deviceType == 'desktop' ? 15 : 0,
              y: 29,
              rows: 2,
              cols: 15,
            } as GridPosition,
            styles: {},
          },
          true,
          `${ButtonTypes.SECONDARY} ${ButtonSizeOptions.FULL_WIDTH}`,
        ),
      ],
      tags: ['org', 'user'],
    },
    {
      name: 'edit-information-contact',
      title: 'Edit Information - Contact',
      widgets: [
        getPageTitleTextWidget({
          gridPosition: { x: deviceType == 'desktop' ? 15 : 0 },
        }),
        getPageSubheaderTextWidget({
          gridPosition: { x: deviceType == 'desktop' ? 15 : 0 },
        }),
        new TextWidgetBuilder()
          .text('<div class="w-100">Contact Details</div>')
          .configDisplayName('Text Widget: Contact Details')
          .gridPosition({
            x: deviceType == 'desktop' ? 15 : 0,
            y: 5,
            rows: 2,
          } as GridPosition)
          .cssConfig({
            color: 'var(--typography-color-0)',
            fontSize: '20',
            textAlignment: 'left',
            isTextBold: true,
          })
          .isRemovable(false)
          .build(),
        new EmailWidgetBuilder()
          .cssConfig({
            borderWidth: '0',
            isTextBold: true,
            fontSize: '14',
          })
          .isRemovable(false)
          .gridPosition({
            x: deviceType == 'desktop' ? 15 : 0,
            y: 7,
            rows: 4,
          } as GridPosition)
          .build(),
        new PhoneWidgetBuilder()
          .cssConfig({
            borderWidth: '0',
            isTextBold: true,
            fontSize: '14',
          })
          .isRemovable(false)
          .gridPosition({
            x: deviceType == 'desktop' ? 15 : 0,
            y: 12,
            rows: 4,
          } as GridPosition)
          .build(),
        new FormSubmissionButtonWidgetBuilder()
          .configDisplayName('Button: Next')
          .attributes({
            widgetProperties: {
              textContent: 'Next',
              zoneToNavigate: APP_ZONES.VERIFICATION,
              urlToNavigate: 'edit-information-address',
              buttonActionType: ButtonActionType.KYC_EMAIL_PHONE_VERIFIED_CHECK,
            } as BaseWidgetProperties,
          } as WorkflowWidgetConfigurationAttributes)
          .cssConfig({
            marginTop: 10,
          })
          .gridPosition({
            x: deviceType == 'desktop' ? 15 : 0,
            y: 26,
            rows: 2,
          } as GridPosition)
          .setGlobalStylingClass(true, `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`)
          .build(),
        getBackButtonWidget(
          {
            position: {
              x: deviceType == 'desktop' ? 15 : 0,
              y: 29,
              rows: 2,
              cols: 15,
            } as GridPosition,
            styles: {},
          },
          true,
          `${ButtonTypes.SECONDARY} ${ButtonSizeOptions.FULL_WIDTH}`,
        ),
      ],
      tags: ['org', 'user'],
    },
    {
      name: 'edit-information-address',
      title: 'Edit Information - Address',
      widgets: [
        getPageTitleTextWidget({
          gridPosition: { x: deviceType == 'desktop' ? 13 : 0 },
        }),
        getPageSubheaderTextWidget({
          gridPosition: { x: deviceType == 'desktop' ? 13 : 0 },
        }),
        new TextWidgetBuilder()
          .text('<div class="w-100">Address</div>')
          .configDisplayName('Text Widget: Address')
          .gridPosition({
            x: deviceType == 'desktop' ? 15 : 0,
            y: 5,
            rows: 2,
          } as GridPosition)
          .cssConfig({
            color: 'var(--typography-color-0)',
            fontSize: '20',
            textAlignment: 'left',
            isTextBold: true,
          })
          .isRemovable(false)
          .build(),
        new EditAddressWidgetBuilder()
          .configDisplayName('Address Widget')
          .cssConfig({
            paddingLeft: 5,
            paddingRight: 5,
            isTextBold: true,
            fontSize: '14',
          })
          .gridPosition({
            x: deviceType == 'desktop' ? 15 : 0,
            y: 5,
            rows: 29,
          } as GridPosition)
          .build(),
        new FormSubmissionButtonWidgetBuilder()
          .configDisplayName('Button: Review Information - Business')
          .attributes({
            widgetProperties: {
              textContent: 'Save',
              zoneToNavigate: APP_ZONES.ORGANISATION_VERIFICATION,
              urlToNavigate: 'review-information/organisation-anonymous/default',
              buttonActionType: ButtonActionType.SUBMIT_PROFILE_CHANGES_IN_VERIFICATION_FLOW,
            } as BaseWidgetProperties,
          } as WorkflowWidgetConfigurationAttributes)
          .cssConfig({
            marginTop: 0,
            verticalAlignment: 'center',
          })
          .isRemovable(false)
          .gridPosition({
            x: deviceType == 'desktop' ? 15 : 0,
            y: 38,
            rows: 2,
          } as GridPosition)
          .setGlobalStylingClass(true, `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`)
          .build(),
        getBackButtonWidget(
          {
            position: {
              x: deviceType == 'desktop' ? 15 : 0,
              y: 41,
              rows: 2,
              cols: 15,
            } as GridPosition,
            styles: {},
          },
          true,
          `${ButtonTypes.SECONDARY} ${ButtonSizeOptions.FULL_WIDTH}`,
        ),
      ],
      tags: ['org', 'user'],
    },
    {
      name: 'information-confirmation',
      title: 'Information Confirmation',
      widgets: [
        new ImageWidgetBuilder()
          .configDisplayName('Image Widget: Search Icon')
          .cssConfig({
            selectedobjectfit: 'contain',
          })
          .gridPosition({
            x: deviceType == 'desktop' ? 15 : 1,
            y: 1,
            rows: 4,
            cols: 4,
          } as GridPosition)
          .attributes({
            widgetProperties: {
              textContent: '',
              imageAssetUrl: 'assets/images/search.svg',
            } as BaseWidgetProperties,
          } as WorkflowWidgetConfigurationAttributes)
          .build(),

        new TextWidgetBuilder()
          .text('<h4 class="w-100 "> {{ profile.firstName }}, Let\'s confirm your details. </h4>')
          .configDisplayName('Text Widget - Information')
          .gridPosition({
            x: deviceType == 'desktop' ? 15 : 0,
            y: 5,
            rows: 2,
          } as GridPosition)
          .cssConfig({
            textAlignment: 'left',
            justifyContent: 'left',
            fontSize: '24',
            lineHeight: '32',
            textFont: 'var(--font-family)',
          })
          .isRemovable(false)
          .build(),

        new TextWidgetBuilder()
          .text(
            '<p class="w-100 "> By proceeding I consent to MoneyFin accessing the company details to verify the identity. </p>',
          )
          .configDisplayName('Text Widget: Verify content')
          .gridPosition({
            x: deviceType == 'desktop' ? 15 : 0,
            y: 7,
            rows: 4,
          } as GridPosition)
          .cssConfig({
            justifyContent: 'left',
            textAlignment: 'left',
            fontSize: '16',
            lineHeight: '24',
            textFont: 'var(--font-family)',
            color: 'var(--typography-color-1)',
          })
          .isRemovable(false)
          .build(),

        new ImageWidgetBuilder()
          .configDisplayName('Image Widget: Powered by')
          .cssConfig({
            selectedobjectfit: 'contain',
          })
          .gridPosition({
            x: deviceType == 'desktop' ? 15 : 4,
            y: 21,
            rows: 3,
            cols: 6,
          } as GridPosition)
          .attributes({
            widgetProperties: {
              textContent: '',
              imageAssetUrl: 'assets/images/sumsub-logo.svg',
            } as BaseWidgetProperties,
          } as WorkflowWidgetConfigurationAttributes)
          .build(),

        new QuickLinkWidgetBuilder()
          .zone(APP_ZONES.VERIFICATION)
          .navigateToPageUrl(`sumsub-workflow?type=${KycLevel.PERSONAL}`)
          .navigationOption('use-as-navigation')
          .role()
          .text('Verify Details')
          .configDisplayName('Quick Link: Verify Details')
          .cssConfig({
            borderWidth: 1,
            borderColor: 'var(--border-color-0)',
            backGroundColor: 'var(--palette-color-3)',
            color: 'var(--palette-color-6)',
            borderRadiusTopLeft: 5,
            borderRadiusBottomLeft: 5,
            borderRadiusTopRight: 5,
            borderRadiusBottomRight: 5,
            fontSize: 16,
          })
          .gridPosition({
            x: deviceType == 'desktop' ? 15 : 0,
            y: 25,
            rows: 2,
          } as GridPosition)
          .setGlobalStylingClass(true, `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`)
          .build(),
        getBackButtonWidget(
          {
            position: {
              x: deviceType == 'desktop' ? 15 : 0,
              y: 28,
              rows: 2,
              cols: 15,
            } as GridPosition,
            styles: {},
          },
          true,
          `${ButtonTypes.SECONDARY} ${ButtonSizeOptions.FULL_WIDTH}`,
        ),
      ],
      tags: ['org', 'user'],
    },
    {
      name: 'email-confirmation',
      title: 'Email Confirmation',
      widgets: buildOtpVerificationPageWidgetsConfig('email', 'kyc', deviceType == 'desktop'),
      tags: ['org', 'user'],
    },
    {
      name: 'phone-confirmation',
      title: 'Phone Confirmation',
      widgets: buildOtpVerificationPageWidgetsConfig('phone', 'kyc', deviceType == 'desktop'),
      tags: ['org', 'user'],
    },
    {
      name: 'sumsub-workflow',
      title: 'Sumsub Workflow',
      widgets: [sumsubWidgetConfig(deviceType == 'desktop')],
      tags: ['org', 'user'],
    },

    statusScreenBasedOnDeviceType({
      deviceType,
      mobileWidgets: businessStatusScreen,
      desktopWidgets: businessStatusScreenDesktop,
      tabletWidgets: businessStatusScreenTablet,
      tags: ['org'],
    }),

    statusScreenBasedOnDeviceType({
      deviceType,
      mobileWidgets: personalStatusScreen,
      desktopWidgets: personalStatusScreenDesktop,
      tabletWidgets: personalStatusScreenTablet,
      tags: ['user'],
    }),
  ];

  const pagesArray: {
    name: string;
    title: string;
    url?: string;
    widgets?: WorkflowWidgetConfig[];
    tags: string[];
    pageConfiguration: CommonPageConfig;
  }[] = [];
  verificationPages.forEach((page) => {
    let pageHooks: PageHooks[] = [];
    if (page.name == 'status-screen' && page.tags.includes('org')) {
      pageHooks = [
        {
          name: 'OrganisationKybCheck',
          enabled: true,
        },
      ];
    } else if (page.name == 'status-screen' && page.tags.includes('user')) {
      pageHooks = [
        {
          name: 'PersonalKycCheck',
          enabled: true,
        },
      ];
    }
    pagesArray.push({
      name: formatPageName(page.title),
      title: page.title,
      url: formatPageName(page.title),
      widgets: page.widgets,
      tags: page.tags,
      pageConfiguration: {
        ...defaultPageConfigurations,
        isRemovable: false,
        gridsterMargin: {
          left: 1,
          right: 1,
          top: 2,
          unit: 'em',
        },
        pageHooks: pageHooks,
      } as CommonPageConfig,
    });
  });

  return [
    ...new Set(
      userType?.flatMap((userType) => {
        return pagesArray?.filter((page) => {
          return page.tags.includes(userType);
        });
      }),
    ),
  ];
}

const statusScreenBasedOnDeviceType = ({
  deviceType,
  mobileWidgets,
  desktopWidgets,
  tabletWidgets,
  tags,
}: {
  deviceType: SupportedDevice;
  mobileWidgets: WorkflowWidgetConfig[];
  desktopWidgets: WorkflowWidgetConfig[];
  tabletWidgets: WorkflowWidgetConfig[];
  tags: string[];
}) => {
  switch (deviceType) {
    case 'desktop':
      return {
        name: 'status-screen',
        title: 'Status Screen',
        widgets: desktopWidgets,
        tags,
      };

    case 'tablet':
      return {
        name: 'status-screen',
        title: 'Status Screen',
        widgets: tabletWidgets,
        tags,
      };

    default:
      return {
        name: 'status-screen',
        title: 'Status Screen',
        widgets: mobileWidgets,
        tags,
      };
  }
};
