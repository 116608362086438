import { TitleCasePipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { NgIcon } from '@ng-icons/core';
import { lucideChevronRight } from '@ng-icons/lucide';
import { HlmIconComponent, provideIcons } from '@spartan-ng/ui-icon-helm';
import { BrnContextMenuTriggerDirective, BrnMenuTriggerDirective } from '@spartan-ng/ui-menu-brain';
import { HlmMenuBarModule } from '@spartan-ng/ui-menu-helm';

export interface ContextMenuItem {
  name: string;
  isEnabled: boolean;
  onClick?: (param: any) => void;
  hideCondition?: boolean;
  itemClass?: string;
  children?: ContextMenuItem[];
  slug?: string;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'finxone-menu',
  templateUrl: './finxone-menu.component.html',
  styleUrls: ['./finxone-menu.component.scss'],
  standalone: true,
  imports: [
    HlmMenuBarModule,
    TitleCasePipe,
    BrnMenuTriggerDirective,
    BrnContextMenuTriggerDirective,
    HlmIconComponent,
    NgIcon,
  ],
  providers: [provideIcons({ lucideChevronRight })],
})
export class FinxoneMenuComponent implements AfterViewInit {
  @ViewChild('contextMenu') contextMenu!: ElementRef;
  @Output() menuItemClickEvent = new EventEmitter<ContextMenuItem>();
  @Input() menuItemList: ContextMenuItem[];
  @Input() contextMenuEvent: MouseEvent;
  @Input() menuVariant: any = 'default'; // used type any because it infers a string union (variant names) as type
  @Input() subMenuIconName = 'lucideChevronRight';

  @Input() enableMenuPosition?: {
    top: boolean;
    left: boolean;
  } = {
    top: true,
    left: true,
  };

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.setContextMenuPosition();
  }

  private setContextMenuPosition() {
    if (this.contextMenu && this.contextMenuEvent) {
      // Get the position of the click
      const { clientX, clientY } = this.contextMenuEvent;

      // Set the styles dynamically using Renderer2
      if (this.enableMenuPosition?.top) {
        this.renderer.setStyle(this.contextMenu.nativeElement, 'top', `${clientY}px`);
      }
      if (this.enableMenuPosition?.left) {
        this.renderer.setStyle(this.contextMenu.nativeElement, 'left', `${clientX}px`);
      }
    }
  }

  menuItemClick(menuItem: ContextMenuItem) {
    this.menuItemClickEvent.emit(menuItem);
  }
}
