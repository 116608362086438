import {
  Constants,
  GridPosition,
  WidgetNames,
  WidgetTitles,
  WorkflowWidgetConfig,
} from '@finxone-platform/shared/sys-config-types';

export const sectionWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: Math.floor(Math.random() * (1000000000 + 1)),
  name: WidgetNames.SECTION,
  widgetConfiguration: {
    widgetDisplayName: 'Section',
    widgetConfigDisplayName: WidgetTitles.SECTION,
    attributes: {
      outerCss: 'height: 100px;',
      elementsConfig: [],
      css_config: {},
      widgetProperties: {
        textContent: '',
      },
    },
    isRemovable: true,
  },
  gridPosition: undefined as unknown as GridPosition,
  widgetIconPath: 'section-widget',
  childComponents: [],
} as WorkflowWidgetConfig;

export const twoColumnWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: Math.floor(Math.random() * (1000000000 + 1)),
  name: WidgetNames.TWO_COLUMN,
  widgetConfiguration: {
    widgetDisplayName: 'Multi Column',
    widgetConfigDisplayName: WidgetTitles.MULTI_COLUMN,
    attributes: {
      outerCss: 'height: 100px;',
      elementsConfig: [],
      css_config: {
        ...Constants.defaultBaseCssConfig,
        borderWidthTop: 0,
        borderWidthBottom: 0,
        borderWidthLeft: 0,
        borderWidthRight: 0,
        borderWidthUnit: 'px',
        columnGap: 0,
        columnGapUnit: 'px',
        borderClass: 'none',
      },
      widgetProperties: {
        textContent: '',
        columnOneOuterCss: '',
        columnTwoOuterCss: '',
        columnOnePercent: 50,
        selectedNoOfColumn: 2,
        selectedColumnRange: [],
        selectedSplitType: 'even',
        columnConfigs: [
          {
            outerCssConfig: '',
            containerName: 'Column: 1',
          },
          {
            outerCssConfig: '',
            containerName: 'Column: 2',
          },
        ],
      },
    },
    isRemovable: true,
  },
  gridPosition: undefined as unknown as GridPosition,
  widgetIconPath: 'multi-column',
  childComponents: [[], []],
} as WorkflowWidgetConfig;

export const tabWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: Math.floor(Math.random() * (1000000000 + 1)),
  name: WidgetNames.TAB,
  widgetConfiguration: {
    widgetDisplayName: 'Tabs',
    widgetConfigDisplayName: WidgetTitles.TAB,
    attributes: {
      elementsConfig: [],
      css_config: {
        elementStyling: {
          tabCss: `hlm-tabs-list {\n}\nhlm-tabs-list button {\n}\nhlm-tabs-list button:hover {\n}\nhlm-tabs-list button[data-state=active] {\n}`,
        },
      },
      widgetProperties: {
        textContent: '',
        tabConfigs: [
          {
            title: 'Tab 1',
            tabRef: 'tab1',
            containerName: 'Tab: 1',
          },
          {
            title: 'Tab 2',
            tabRef: 'tab2',
            containerName: 'Tab: 2',
          },
          {
            title: 'Tab 3',
            tabRef: 'tab3',
            containerName: 'Tab: 3',
          },
        ],
      },
    },
    isRemovable: true,
  },
  gridPosition: undefined as unknown as GridPosition,
  widgetIconPath: 'tab-widget',
  childComponents: [[], [], []],
} as WorkflowWidgetConfig;

export const spacerWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: Math.floor(Math.random() * (1000000000 + 1)),
  name: WidgetNames.SPACER,
  widgetConfiguration: {
    widgetDisplayName: WidgetTitles.SPACER,
    widgetConfigDisplayName: WidgetTitles.SPACER,
    attributes: {
      outerCss: '',
      elementsConfig: [],
      css_config: {
        height: 15,
        heightUnit: 'px',
      },
      widgetProperties: {
        textContent: '',
      },
    },
    isRemovable: true,
  },
  gridPosition: undefined as unknown as GridPosition,
  widgetIconPath: 'spacer-widget',
} as WorkflowWidgetConfig;
