import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { FormActionTypeEnum, SEPAPaymentRailTypeEnum } from '@finxone-platform/form-action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { APP_ZONES, BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { UpdateFormDataAction } from '../../../actions/form-submission.action';
import { CreatePayment } from '../../../actions/payment.action';
import { TransferMoneyRequest, paymentRailOptions } from '../../../services/account-service/account.type';
import { AccountState } from '../../../state/account.state';
import { FormActionState } from '../../../state/form-submision.state';
import { redirectToPage } from '../cta-button-actions.utils';

/**
 * Submits a payment initiation request using the provided injector and widget properties.
 *
 * This function retrieves necessary services and state data using the injector,
 * constructs a payment request payload, and dispatches actions to update form data
 * and create a payment. If the request data is valid, it navigates to the payment
 * processing page. Otherwise, it shows an error alert.
 *
 * @param injector - The Angular injector used to retrieve services.
 * @param widgetProperties - The properties of the widget, including navigation details.
 */
export function submitInitiatorPayment(injector: Injector, widgetProperties: BaseWidgetProperties) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  const alertHandlerService = injector.get(AlertHandlerService);
  if (!widgetProperties['zoneToNavigate']) {
    widgetProperties['zoneToNavigate'] = APP_ZONES.PAYMENT;
  }
  const requestData = store.selectSnapshot(FormActionState.getFormActionState)?.response?.formData;
  const activeAccountId = store.selectSnapshot(AccountState.getCurrentAccountId);

  const sepaFormState = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.SEPA_PAYMENT),
  )?.formData;

  if (requestData && Object.keys(requestData).length) {
    const payload: TransferMoneyRequest = {
      sourceAccountId: activeAccountId,
      bid: requestData.bid,
      amount: requestData.amount,
      paymentReference: requestData.paymentReference,
      transferReasonId: requestData.transferReasonId,
      paymentRailOption: sepaFormState.paymentRail as SEPAPaymentRailTypeEnum,
      scheme: paymentRailOptions[sepaFormState?.paymentRail as SEPAPaymentRailTypeEnum],
    };
    store.dispatch(
      new UpdateFormDataAction({
        transferDate: `${new Date()}`,
      }),
    );
    store.dispatch(new CreatePayment(payload));
    widgetProperties['urlToNavigate'] = `payment-processing-initiator`;
    redirectToPage(router, widgetProperties, injector, true);
  } else {
    alertHandlerService.showAlertFn('error', 'Payment request data not available, please try again');
    console.error('payment request data not available, please try again');
  }
}
