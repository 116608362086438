import { WidgetNames, WorkflowWidgetConfig } from '@finxone-platform/shared/sys-config-types';
import { getOtpVerificationWidgetConfig } from '../../page-builder-utils/widget-builder-utils/common-widget-utils';
import { getOtpVerificationPaymentApprovalWidgetConfig } from '../../page-builder-utils/widget-builder-utils/desktop-page-utils/payment-approver-page.utils';
import { BaseWidgetBuilder, initWidgetBuilder } from './base-builder';
import {
  otpVerificationInternationalConfig,
  otpVerificationWidgetConfig,
} from './workflow-ui-constants.v2.type';

class BaseOtpVerificationBuilder extends BaseWidgetBuilder {
  constructor(config: WorkflowWidgetConfig) {
    super();
    this.widget = initWidgetBuilder(config);
  }
  setWidgetContext(type: string, flow: string): this {
    this.widget.widgetConfiguration.attributes.widgetProperties.widgetContext = { type, flow };
    return this;
  }
}
export class OtpVerificationWidgetBuilder extends BaseOtpVerificationBuilder {
  constructor() {
    super(otpVerificationWidgetConfig);
  }
}

export class OtpVerificationInternationalBuilder extends BaseOtpVerificationBuilder {
  constructor() {
    super(otpVerificationInternationalConfig);
  }
}

export class ApproverPaymentsOtpVerificationWidgetBuilder extends BaseOtpVerificationBuilder {
  constructor() {
    super(
      getOtpVerificationWidgetConfig(
        JSON.parse(JSON.stringify(otpVerificationWidgetConfig)),
        WidgetNames.OTP_VERIFICATION_APPROVER_PAYMENTS,
      ),
    );
  }
}

export class OtpVerificationPaymentApprovalWidgetBuilder extends BaseOtpVerificationBuilder {
  constructor() {
    super(
      getOtpVerificationPaymentApprovalWidgetConfig(JSON.parse(JSON.stringify(otpVerificationWidgetConfig))),
    );
  }
}

export class BulkPaymentsOtpVerificationWidgetBuilder extends BaseOtpVerificationBuilder {
  constructor() {
    super(
      getOtpVerificationWidgetConfig(
        JSON.parse(JSON.stringify(otpVerificationWidgetConfig)),
        WidgetNames.OTP_VERIFICATION_BULK_PAYMENTS,
      ),
    );
  }
}
