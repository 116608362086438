import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonActionType,
  GridPosition,
  WorkflowWidgetConfig,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import {
  AddFeeGroupWidgetBuilder,
  FormSubmissionButtonWidgetBuilder,
} from '../../../defaults/widgets/example-builder';
import { getBackButtonWidget } from '../../../defaults/zones/manage-users-zone/common-utils';
import { generateTextWidget } from '../../../defaults/zones/payments/common-widgets-utils';

export function generateAddFeeGroupPages(): WorkflowWidgetConfig[] {
  const widgets = [
    generateTextWidget({
      text: 'Fee management - Add/Edit Group',
      displayName: 'Page Heading',
      isRemovable: false,
      position: {
        x: 1,
        y: 0,
        rows: 1,
        cols: 36,
      } as GridPosition,
      styles: {
        fontSize: 24,
        color: 'var(--typography-color-0)',
        isTextBold: true,
        textAlignment: 'left',
      },
    }),
    generateTextWidget({
      text: 'Access the drilldown view of each account to gain detailed insights into the fee structure and review the associated transaction history.',
      displayName: 'Page Sub Heading',
      isRemovable: false,
      position: {
        x: 1,
        y: 1,
        rows: 1,
        cols: 36,
      } as GridPosition,
      styles: {
        fontSize: 20,
        color: 'var(--typography-color-1)',
        textAlignment: 'left',
      },
    }),
    getBackButtonWidget({
      position: {
        x: 38,
        y: 0,
        rows: 1,
        cols: 4,
      } as GridPosition,
      styles: {
        fontSize: 14,
        textAlignment: 'center',
        color: 'var(--palette-color-3)',
        backGroundColor: 'transparent',
        borderWidth: 1,
        borderColor: 'var(--palette-color-3)',
      },
    }),
    new FormSubmissionButtonWidgetBuilder()
      .configDisplayName('Button: Charge/Save')
      .attributes({
        widgetProperties: {
          textContent: 'Add/Save',
          zoneToNavigate: APP_ZONES.FEE_MANAGEMENT,
          urlToNavigate: 'validation-add-edit-group-details',
          buttonActionType: ButtonActionType.ADD_EDIT_FEE_GROUP,
        } as BaseWidgetProperties,
      } as WorkflowWidgetConfigurationAttributes)
      .cssConfig({
        marginTop: 0,
      })
      .gridPosition({
        x: 43,
        y: 0,
        rows: 1,
        cols: 4,
      } as GridPosition)
      .build(),
    new AddFeeGroupWidgetBuilder().isRemovable(false).build(),
  ];
  return widgets;
}
