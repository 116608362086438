import {
  APP_ZONES,
  ButtonSizeOptions,
  ButtonTypes,
  GridPosition,
} from '@finxone-platform/shared/sys-config-types';
import { QuickLinkWidgetBuilder, TextWidgetBuilder } from '../../../widgets/example-builder';
import { backbuttonCssConfig, textwidgetCssConfig } from '../../../widgets/profile/profile.type';

export const privacyPolicy = [
  new TextWidgetBuilder()
    .text(
      '<div class="w-100">' +
        '<h1><b>Privacy Policy</b></h1> ' +
        '<p>Nullam fringilla lectus massa, eget accumsan lacus iaculis non. Mauris dictum dui sit amet ex porta lacinia. Integer quis urna pulvinar, auctor nibh et, sagittis lorem. Aenean vel efficitur odio.</p></div>',
    )
    .configDisplayName('Text Widget: Privacy Policy')
    .gridPosition({ y: 1, rows: 9 } as GridPosition)
    .cssConfig(textwidgetCssConfig)
    .isRemovable(false)
    .build(),
  new TextWidgetBuilder()
    .text(
      '<div class="w-100">' +
        '<p>Sed non imperdiet odio, vitae vestibulum massa. Morbi accumsan leo non leo finibus, a pharetra massa pretium. Nam vel ornare magna. Morbi in velit sed tellus maximus gravida sed id turpis.</p></div>',
    )
    .configDisplayName('Text Widget: Lorem lardui lordi')
    .gridPosition({ y: 10, rows: 18 } as GridPosition)
    .cssConfig(textwidgetCssConfig)
    .isRemovable(true)
    .build(),
  new TextWidgetBuilder()
    .text(
      '<div class="w-100">' +
        '<p>urna ac pretium accumsan, diam dolor congue nisi, sit amet elementum ex orci ac est.</p></div>',
    )
    .configDisplayName('Text Widget: Lorem sumbut colour sit shiva')
    .gridPosition({ y: 28, rows: 17 } as GridPosition)
    .isRemovable(true)
    .cssConfig(textwidgetCssConfig)
    .build(),
  new TextWidgetBuilder()
    .text(
      '<div class="w-100 list-style-disc">' +
        '<h3>elementum ornare lacus.</h3> ' +
        '<ul>' +
        '<li>Vestibulum bibendum eget odio at sodales. Vivamus ornare sed augue eget commodo. In vitae dapibus nisl, non condimentum elit. ' +
        'Phasellus urna mauris, posuere a sapien ut, tincidunt aliquam metus. Donec vitae tortor eu urna congue scelerisque.</li>' +
        '<br><br><br><br>' +
        '<li>In eu blandit arcu. Suspendisse non ex orci. Fusce consectetur velit id enim luctus ' +
        'Nullam convallis nulla vel nunc sollicitudin, vitae ullamcorper arcu mattis. Cras ullamcorper urna a arcu efficitur, at aliquam leo fermentum.</li>' +
        '</ul></div>',
    )
    .configDisplayName('Text Widget: In eu crata arpru')
    .cssConfig(textwidgetCssConfig)
    .gridPosition({ y: 45, rows: 10 } as GridPosition)
    .isRemovable(true)
    .build(),
  new QuickLinkWidgetBuilder()
    .zone(APP_ZONES.PROFILE)
    .navigationOption('use-as-navigation')
    .navigateToPageUrl('profile')
    .role()
    .configDisplayName('Quick Link: Back')
    .text('Back')
    .gridPosition({ y: 60, rows: 2 } as GridPosition)
    .cssConfig(backbuttonCssConfig)
    .setGlobalStylingClass(true, `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`)
    .build(),
];
