import { OrganisationProfileType, SupportedDevice } from '@finxone-platform/shared/sys-config-types';
import { PageConfig, buildDynamicZoneConfig } from '../../../../zones/mobile/default-zone-page-setups';

import { organisationDesktopProfilePages } from './desktop/pages';
import { organisationMobileProfilePages } from './mobile/pages';
import { organisationTabletProfilePages } from './tablet/pages';

export const defaultOrganisationProfilePages: PageConfig[] = [
  {
    name: 'Profile Landing Page',
    title: 'Profile Landing Page',
    tags: [OrganisationProfileType.ORGANISATION_PROFILE],
    url: 'organisation-profile-landing',
    widgets: [],
  },
];

export function buildOrganisationProfilePages(device: SupportedDevice, tags: string[]) {
  const pageSetups = {
    mobile: organisationMobileProfilePages,
    tablet: organisationTabletProfilePages,
    desktop: organisationDesktopProfilePages,
  };

  // Finds associated pages for each tag and then deduplicates the resulting array to only get unique pages
  const pages = [
    ...new Set(
      tags.flatMap((tag) => {
        return pageSetups[device].filter((page) => {
          return page.tags.includes(tag);
        });
      }),
    ),
  ];

  return [...buildDynamicZoneConfig(pages)];
}
