/* eslint-disable @typescript-eslint/member-ordering */
import { NgIf, NgStyle } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { WidgetStyle } from '@finxone-platform/shared/sys-config-types';
import { OverlayListenerOptions, OverlayOptions } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'workflow-finx-dropdown',
  templateUrl: './workflow-finx-dropdown.component.html',
  styleUrls: ['./workflow-finx-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WorkflowFinxDropdownComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [NgIf, DropdownModule, ReactiveFormsModule, NgStyle, FormsModule],
})
export class WorkflowFinxDropdownComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() options: any[] = [];
  @Input() class = '';
  // When using formControlValue we still need to set [value] at the moment
  // This is due to value getting set on the p-dropdown as '' overriding the formControlValue
  // Need to come up with a better way to handle this.
  // Maybe switch template depending on if formControlName is set or not?
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() value?: any = '';
  @Input() appendto = '';
  @Input() error = '';
  @Input() placeholder = '';
  @Input() isDisabled = false;
  @Input() optionLabel: string;
  @Input() optionValue: string;
  @Input() closeOnBlur = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() valueChange = new EventEmitter<any>();
  @Output() errorChange = new EventEmitter<string>();
  @Output() emitBlur = new EventEmitter();
  public readonly dropdownStringControl = new FormControl();
  @Input() prefixIcon = false;
  @Input() id?: string;
  @Input() label?: string;
  @Input() formControlName: string;
  @Input() formGroup: FormGroup;
  @Input() filter = false;
  // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-empty-function
  onModelChange: Function = () => {};

  // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-empty-function
  onModelTouched: Function = () => {};
  @Input() textColor: WidgetStyle;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('pdropdown', { static: false }) private pdropdown: any;

  constructor(private renderer: Renderer2, public el: ElementRef, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.dropdownStringControl.valueChanges.subscribe((dateString) => {
      this.onModelChange(dateString);
      this.onModelTouched();
      this.cd.detectChanges();
    });
    if (this.formControlName && this.formGroup) {
      const formControl = this.formGroup.get(this.formControlName);
      if (formControl) {
        this.dropdownStringControl.setValue(formControl.value);
      }
    }
  }

  writeValue(value: Date | null): void {
    value = value ?? new Date();

    this.dropdownStringControl.setValue(value);
    this.cd.markForCheck();
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  registerOnChange(fn: Function): void {
    this.onModelChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.dropdownStringControl.disable();
    } else {
      this.dropdownStringControl.enable();
    }
    this.cd.markForCheck();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange = (event: any) => {
    this.value = event.value;
    this.valueChange.emit(this.value ?? '');
    this.error = '';
    this.errorChange.emit('');
  };

  public onBlur() {
    if (this.closeOnBlur) this.pdropdown.hide();
  }

  ngAfterViewInit() {
    if (this.textColor) {
      this.renderer.setStyle(
        this.pdropdown?.el?.nativeElement?.firstChild?.childNodes[1],
        'color',
        this.textColor['color'],
      );
    }
  }

  getOverlayOptions(): OverlayOptions {
    return {
      listener: (event: Event, options?: OverlayListenerOptions) => {
        if (options?.type === 'scroll') {
          return false;
        }
        return options?.valid;
      },
    };
  }
}
