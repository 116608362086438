import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonActionType,
  GridPosition,
  WorkflowPageConfig,
  WorkflowUserType,
  WorkflowWidgetConfig,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import { FormSubmissionButtonWidgetBuilder, TextWidgetBuilder } from '../widgets/example-builder';
import { defaultPageConfigurations } from '../widgets/workflow-ui-constants.v2.type';
import { generateTextWidget } from '../zones/payments/common-widgets-utils';

export function generateOnboardingDefaultPage(userType?: string[] | undefined): WorkflowPageConfig {
  if (userType?.includes(WorkflowUserType.ORG)) {
    return {
      name: 'Organisation Profile',
      title: 'Organisation Profile',
      pageConfiguration: {
        mainComponentPageName: 'ParentContainerComponent',
        isRemovable: false,
        pageType: 'dynamicGrid',
        url: 'organisation-profile',
        isCustomGridActive: true,
        ...defaultPageConfigurations,
      },
      widgets: generateOnboardingPageWidgetsForBusinessUsers(),
    };
  } else {
    return {
      name: 'onboarding',
      title: 'Onboarding',
      pageConfiguration: {
        mainComponentPageName: 'ParentContainerComponent',
        isRemovable: false,
        pageType: 'dynamicGrid',
        url: 'onboarding',
        ...defaultPageConfigurations,
      },
      widgets: [],
    };
  }
}

function generateOnboardingPageWidgetsForBusinessUsers(): WorkflowWidgetConfig[] {
  return [
    new TextWidgetBuilder()
      .text(
        `Do you already have a personal account{{#if formSubmissionFormData.orgName }} with {{formSubmissionFormData.orgName}}{{/if}}? If yes, please log in.`,
      )
      .gridPosition({
        x: 0,
        y: 6,
        rows: 2,
        cols: 15,
      } as GridPosition)
      .configDisplayName('Text Widget: Disclaimer Text')
      .cssConfig({
        fontSize: 16,
        color: 'var(--typography-color-0)',
        isTextBold: true,
        textAlignment: 'left',
        textFont: 'var(--font-family)',
      })
      .isRemovable(false)
      .build(),
    new FormSubmissionButtonWidgetBuilder()
      .configDisplayName('Button: Log In')
      .attributes({
        widgetProperties: {
          textContent: 'Log In',
          zoneToNavigate: APP_ZONES.ONBOARDING,
          urlToNavigate: 'login',
          buttonActionType: ButtonActionType.REDIRECT_BUSINESS_USER_TO_LOGIN,
        } as BaseWidgetProperties,
      } as WorkflowWidgetConfigurationAttributes)
      .cssConfig({
        marginTop: 0,
        textFont: 'var(--font-family)',
        paddingTop: 15,
        paddingBottom: 15,
      })
      .gridPosition({
        x: 0,
        y: 9,
        rows: 2,
        cols: 15,
      } as GridPosition)
      .build(),
    generateTextWidget({
      text: `<div class="w-100" >
        <div class="or-content">
          <span> Or </span>
        </div></div>`,
      displayName: 'Text Widget: Separator Text',
      isRemovable: false,
      position: {
        x: 0,
        y: 13,
        rows: 1,
        cols: 15,
      } as GridPosition,
      styles: {
        fontSize: 14,
        color: 'var(--typography-color-0)',
        isTextBold: false,
        textAlignment: 'left',
        textFont: 'var(--font-family)',
      },
    }),
    generateTextWidget({
      text: `Don't have an account? Sign up here.`,
      displayName: 'Text Widget: Helper Text',
      isRemovable: false,
      position: {
        x: 0,
        y: 16,
        rows: 1,
        cols: 15,
      } as GridPosition,
      styles: {
        fontSize: 14,
        color: 'var(--typography-color-0)',
        isTextBold: false,
        textAlignment: 'left',
        textFont: 'var(--font-family)',
      },
    }),
    new FormSubmissionButtonWidgetBuilder()
      .configDisplayName('Button: Sign Up')
      .attributes({
        widgetProperties: {
          textContent: 'Sign Up',
          zoneToNavigate: APP_ZONES.ONBOARDING,
          urlToNavigate: 'sign-up',
          buttonActionType: ButtonActionType.REDIRECT_BUSINESS_USER_TO_LOGIN,
        } as BaseWidgetProperties,
      } as WorkflowWidgetConfigurationAttributes)
      .cssConfig({
        marginTop: 0,
        borderWidth: 1,
        color: 'var(--palette-color-3)',
        fontSize: '16',
        backGroundColor: 'var(--palette-color-6)',
        textAlignment: 'center',
        justifyContent: 'center',
        borderColor: 'var(--palette-color-3)',
        borderStyle: 'solid',
        borderRadiusTopLeft: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusBottomRight: 5,
        textFont: 'var(--font-family)',
        paddingTop: 15,
        paddingBottom: 15,
      })
      .gridPosition({
        x: 0,
        y: 17,
        rows: 2,
        cols: 15,
      } as GridPosition)
      .build(),
  ];
}
