import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonActionType,
  GridPosition,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import { FormSubmissionButtonWidgetBuilder } from '../../../widgets/example-builder';
import {
  backCTAForBeneficiary,
  bulkPaymentFooter,
  generateBulkPaymentListing,
  generateTextWidget,
  getQuickLinkWidget,
  refreshBeneficiaryButton,
} from '../common-widgets-utils';
import { reviewBeneficiaryValidationCounters } from '../utils/bulk-payment-util';
import { bulkPaymentReviewListingColumns } from './bulk-payment-history-widget';

export const bulkPaymentBeneficiaryValidationWidgets = [
  ...bulkBenReviewHeader(),
  ...bulkPaymentListing(),
  ...bulkPaymentFooter(24),
];
function bulkBenReviewHeader() {
  return [
    generateTextWidget({
      text: '<h3>Bulk Payment - Beneficiary (error validation)</h3>',
      position: { x: 0, y: 1, rows: 1, cols: 25 } as GridPosition,
      displayName: 'Text Widget: Bulk Payment Header ',
      styles: {
        fontSize: 24,
        isTextBold: true,
        textAlignment: 'left',
      },
      isRemovable: true,
    }),
    generateTextWidget({
      text: '<p>There are some things you need to know before adding beneficiaries.</p>',
      position: { x: 0, y: 2, rows: 1, cols: 20 } as GridPosition,
      displayName: 'Text Widget:  Sub header',
      styles: {
        fontSize: 20,
        color: 'var(--palette-color-1)',
        textAlignment: 'left',
      },
      isRemovable: true,
    }),
    ...backCTAForBeneficiary,
    new FormSubmissionButtonWidgetBuilder()
      .gridPosition({ x: 37, y: 1, rows: 1, cols: 6 } as GridPosition)
      .cssConfig({
        fontSize: 14,
        verticalAlignment: 'center',
        textAlignment: 'center',
        disabledBgColor: 'var(--palette-color-3)',
        disabledTextColor: 'var(--palette-color-6)',
      })
      .attributes({
        widgetProperties: {
          textContent: 'Proceed',
          buttonActionType: ButtonActionType.BULK_PAYMENT_BENEFICIARY_VALIDATION,
          zoneToNavigate: ' ',
          urlToNavigate: ' ',
        } as BaseWidgetProperties,
      } as WorkflowWidgetConfigurationAttributes)
      .build(),
    generateTextWidget({
      text: reviewBeneficiaryValidationCounters,
      position: { x: 0, y: 3, rows: 3, cols: 27 } as GridPosition,
      displayName: 'Text Widget: Counters',
      styles: {
        fontSize: 20,
        color: 'var(--palette-color-1)',
        textAlignment: 'center',
        verticalAlignment: 'center',
        backGroundColor: '#F2F4F7',
        paddingLeft: 10,
        paddingRight: 10,
      },
      isRemovable: true,
    }),
    refreshBeneficiaryButton,
    generateTextWidget({
      text: '<p><span class="font-weight-bold"> Note  : </span> The beneficiaries are now added to the existing list. The red-highlighted rows indicate errors, depicting that there was an issue adding them. You need to remove the error entries to proceed forward with executing the transactions.</p>',
      position: { x: 0, y: 6, rows: 2, cols: 38 } as GridPosition,
      displayName: 'Text Widget: Note',
      styles: {
        fontSize: 16,
        color: 'var(--typography-color-0)',
        textAlignment: 'left',
      },
      isRemovable: true,
    }),
  ];
}
function bulkPaymentListing() {
  return [
    generateBulkPaymentListing({
      text: 'Bulk Payment Listing',
      position: { x: 0, y: 9, rows: 11, cols: 45 } as GridPosition,
      displayName: 'Bulk Payment Listing',
      styles: {
        color: 'var(--palette-color-1)',
        textAlignment: 'left',
        justifyContent: 'left',
        fontSize: '16',
        borderWidth: 1,
        borderColor: 'var(--palette-color-2)',
        backGroundColor: 'var(--palette-color-6)',
        borderStyle: 'solid',
        borderRadiusUnit: 'px',
        borderRadiusTopLeft: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusBottomRight: 5,
      },
      isRemovable: false,
      columns: bulkPaymentReviewListingColumns,
    }),
    generateTextWidget({
      text: '<p>Please carefully review the payment details before proceeding with execution. Once you proceed with adding the beneficiaries, they will be included in the existing list of your beneficiaries. Once payments are initiated, they cannot be reversed. If any modifications are needed,  you`ll need to upload a new CSV file.</p>',
      position: { x: 0, y: 20, rows: 2, cols: 45 } as GridPosition,
      displayName: 'Text Widget:  Note Text',
      styles: {
        fontSize: 16,
        textAlignment: 'left',
      },
      isRemovable: true,
    }),
    getQuickLinkWidget({
      text: 'Upload New File',
      position: { x: 0, y: 22, rows: 1, cols: 10 } as GridPosition,
      pageUrl: 'bulk-payment-upload-download',
      displayName: 'Quick Link: Upload New File',
      styles: {
        color: 'var(--palette-color-3)',
        fontSize: '16',
        backGroundColor: 'transparent',
        textAlignment: 'center',
        justifyContent: 'center',
        borderColor: 'var(--palette-color-3)',
        borderStyle: 'solid',
        borderWidth: 1,
      },
      zone: APP_ZONES.PAYMENT,
      isRemovable: false,
      icon: 'upload',
    }),
  ];
}
