import {
  APP_ZONES,
  CommonPageConfig,
  PermissionConfig,
  WorkflowConfig,
  WorkflowPageConfig,
} from '@finxone-platform/shared/sys-config-types';
import { defaultPageConfigurations } from '../../defaults/widgets/workflow-ui-constants.v2.type';
import { exchangeTransactionElaboratedViewWidgets } from '../../defaults/zones/accounts/widget-sections/exchange-transaction-elaborated-view';

// This migartion is responsible for add a new page "Exchange Elaborated View" after the 'Account Credentials' under Account zone
//Story 6684

const exchangeTransactionElaboratedViewPage: WorkflowPageConfig = {
  name: 'exchange-elaborated-view',
  title: 'Exchange Elaborated View',
  pageConfiguration: {
    ...(defaultPageConfigurations as CommonPageConfig),
    url: 'exchange-elaborated-view',
    isUsingHtmlStructure: true,
  },
  widgets: exchangeTransactionElaboratedViewWidgets(),
};

export const getV1_0_150ToV1_0_151 = (workflowConfig: WorkflowConfig) => {
  const oneToOneKeys = Object.keys(workflowConfig);
  const mappingObject = {};
  // Assign mappings to not modify this version upgrade
  oneToOneKeys.forEach((key) => {
    Object.assign(mappingObject, {
      [key]: key,
    });
  });
  return {
    ...mappingObject,
    // add new version number
    version: {
      key: 'version',
      transform: function () {
        return 'v1.0.151';
      },
    },
    permissionSections: {
      key: 'permissionSections',
      transform: function (permissionSection: PermissionConfig): PermissionConfig {
        const { roles, subscriptions } = permissionSection;
        roles.forEach((item) => {
          const accountZone = item.zones[APP_ZONES.ACCOUNTS_ZONE];
          if (accountZone) {
            const mobilePages = accountZone?.canvases?.subscription?.['default']?.mobile?.pages as
              | WorkflowPageConfig[]
              | undefined;
            const desktopPages = accountZone?.canvases?.subscription?.['default']?.desktop?.pages as
              | WorkflowPageConfig[]
              | undefined;
            const tabletPages = accountZone?.canvases?.subscription?.['default']?.tablet?.pages as
              | WorkflowPageConfig[]
              | undefined;
            if (mobilePages?.length) {
              addNewPage(mobilePages);
            }
            if (desktopPages?.length) {
              addNewPage(desktopPages);
            }
            if (tabletPages?.length) {
              addNewPage(tabletPages);
            }
          }
        });
        return <PermissionConfig>{
          zones: permissionSection.zones,
          subscriptions,
          roles,
        };
      },
    },
  };
};

function addNewPage(pagesArray: WorkflowPageConfig[]): WorkflowPageConfig[] {
  const accountCredentialPageIndex = pagesArray.findIndex((page) => page.name === 'account-credentials');
  const exchangeTransactionElaboratedViewPresent = pagesArray.some(
    (page) => page.name === exchangeTransactionElaboratedViewPage.name,
  );
  if (accountCredentialPageIndex !== -1 && !exchangeTransactionElaboratedViewPresent)
    pagesArray.splice(accountCredentialPageIndex + 1, 0, exchangeTransactionElaboratedViewPage);
  return pagesArray;
}
