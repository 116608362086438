import {
  BaseWidgetProperties,
  ButtonActionType,
  GridPosition,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';

import { addBackButton } from '../../../../page-builder-utils/widget-builder-utils/common-widget-utils';
import {
  FileUploadWidgetBuilder,
  FormSubmissionButtonWidgetBuilder,
  LineWidgetBuilder,
  TextWidgetBuilder,
} from '../../../widgets/example-builder';
import { bulkPaymentFooter } from '../common-widgets-utils';

export const bulkPaymentUploadDownloadWidgets = [
  ...bulkPaymentUploadHeader(),
  ...bulkPaymentUploadCsv(),
  ...bulkPaymentDownloadCsv(),
  ...bulkPaymentFooter(30),
];

function bulkPaymentUploadHeader() {
  const currentAccountTemplate = `<div class="border p-3 w-100"> <div class="is-space-between"> <div class="is-vertical-align text-left"> <div class="font-weight-bold typog-lg">{{ currency }}</div> </div> <div class="is-vertical-align text-right"> <div class="is-right font-weight-semi typog-sm"><span class="text-capitalize pr-2">{{ country }}</span>  {{{ currencyToFlag currency "44px" }}} </div> </div> </div> <hr class="dashed-line my-5" /> 
<div class="is-space-between"> 
<div class="is-vertical-align text-left"> <div class="typog-xs label-small">Acc no.</div> <div class="typog-sm">{{ accountNumber }}</div> </div> 
<div class="is-vertical-align text-left"> <div class="typog-xs label-small">Sort Code</div> <div class="typog-sm">{{ sortCode }}</div> </div>
 <div class="is-vertical-align text-right"> <div class="typog-xs label-small">Available Balance</div> <div class="typog-sm font-weight-bold"> 
{{ currencyPipe availableBalance currencyCode=currency }} </div> </div> </div> </div>`;

  return [
    new TextWidgetBuilder()
      .text('<h3>Bulk Pay</h3>')
      .configDisplayName('Text Widget: Bulk Pay')
      .gridPosition({ x: 0, y: 1, cols: 4, rows: 1 } as GridPosition)
      .cssConfig({
        fontSize: 20,
        isTextBold: true,
        textAlignment: 'left',
      })
      .build(),
    new TextWidgetBuilder()
      .text('<p>Please download the provided template or proceed if you already have one to upload.</p>')
      .configDisplayName('Text Widget: Sub header')
      .gridPosition({ x: 0, y: 2, cols: 20, rows: 1 } as GridPosition)
      .cssConfig({
        fontSize: 16,
        color: 'var(--palette-color-1)',
        textAlignment: 'left',
      })
      .build(),
    ...addBackButton(
      {
        x: 25,
        y: 1,
        cols: 5,
        rows: 1,
        resizeEnabled: true,
        dragEnabled: true,
      },
      {
        color: 'var(--palette-color-3)',
        backGroundColor: 'var(--palette-color-6)',
        borderWidth: 1,
        borderColor: 'var(--palette-color-3)',
        fontSize: 14,
        textAlignment: 'center',
        verticalAlignment: 'center',
      },
    ),
    new FormSubmissionButtonWidgetBuilder()
      .gridPosition({ y: 1, x: 32, cols: 6, rows: 1 } as GridPosition)
      .cssConfig({
        fontSize: 14,
        verticalAlignment: 'center',
        textAlignment: 'center',
        disabledBgColor: 'var(--palette-color-3)',
        disabledTextColor: 'var(--palette-color-6)',
      })
      .attributes({
        widgetProperties: {
          textContent: 'Proceed',
          buttonActionType: ButtonActionType.BULK_PAYMENT_DOWNLOAD_UPLOAD_PROCESSING,
          zoneToNavigate: ' ',
          urlToNavigate: ' ',
        } as BaseWidgetProperties,
      } as WorkflowWidgetConfigurationAttributes)
      .build(),
    new TextWidgetBuilder()
      .text('<h5>Payout Account</h5>')
      .configDisplayName('Text Widget:Payout Account')
      .gridPosition({ cols: 4, y: 3, x: 0, rows: 1 } as GridPosition)
      .cssConfig({
        fontSize: 18,
        verticalAlignment: 'left',
        textAlignment: 'left',
      })
      .build(),
    new TextWidgetBuilder()
      .text(currentAccountTemplate)
      .configDisplayName('Text Widget:Selected Account')
      .gridPosition({ cols: 15, y: 5, x: 0, rows: 5 } as GridPosition)
      .cssConfig({
        fontSize: 12,
        verticalAlignment: '',
        textAlignment: '',
        borderColor: 'var(--palette-color-4)',
        borderWidth: '1',
      })
      .build(),
    new LineWidgetBuilder()
      .configDisplayName('Line Widget')
      .gridPosition({ cols: 45, y: 11, x: 0, rows: 1 } as GridPosition)
      .attributes({
        widgetProperties: {
          color: 'var(--border-color-1)',
          'border-bottom': 'var(--border-color-1)',
          lineThickness: 1,
          lineWidth: '100',
          textContent: '',
          show: true,
          contentAlignment: 'center',
        } as BaseWidgetProperties,
      } as WorkflowWidgetConfigurationAttributes)
      .build(),
  ];
}

function bulkPaymentUploadCsv() {
  return [
    new TextWidgetBuilder()
      .text('<h3>Upload CSV of beneficiaries</h3>')
      .configDisplayName('Text Widget: Upload CSV of beneficiaries')
      .gridPosition({ cols: 10, y: 13, x: 0, rows: 1 } as GridPosition)
      .cssConfig({
        fontSize: 20,
        isTextBold: true,
        textAlignment: 'left',
      })
      .build(),
    new TextWidgetBuilder()
      .text(
        '<p>Please upload the template. We`ll authenticate and provide a list to review beneficiaries and amounts added before executing the payment.</p>',
      )
      .configDisplayName('Text Widget:Upload CSV Sub header')
      .gridPosition({ cols: 45, y: 14, x: 0, rows: 1 } as GridPosition)
      .cssConfig({
        fontSize: 16,
        color: 'var(--typography-color-1)',
        textAlignment: 'left',
      })
      .build(),
    new TextWidgetBuilder()
      .text(
        '<p>Note: The uploaded list may contain new beneficiaries. There will be a confirmation required from your end after proceeding to the payment step, to either add the newly displayed beneficiary or simply proceed with the payment without adding them.</p>',
      )
      .configDisplayName('Text Widget:Upload CSV Sub header')
      .gridPosition({ cols: 30, y: 15, x: 0, rows: 2 } as GridPosition)
      .cssConfig({
        fontSize: 16,
        color: 'var(--typography-color-0)',
        textAlignment: 'left',
      })
      .build(),

    new FileUploadWidgetBuilder()
      .gridPosition({
        x: 0,
        y: 18,
        rows: 4,
        cols: 13,
        layerIndex: 0,
      } as GridPosition)
      .attributes({
        widgetProperties: {
          textContent: 'Upload CSV',
          icon: 'upload',
        } as BaseWidgetProperties,
        maxFileUpload: '1',
        selectedEntityOption: 'bulk_payments',
        uploadType: 'document',
        uploadFileLabel: '',
      } as unknown as WorkflowWidgetConfigurationAttributes)
      .build(),
    new LineWidgetBuilder()
      .configDisplayName('Line Widget : Upload CSV')
      .gridPosition({ x: 0, y: 22, rows: 1, cols: 45 } as GridPosition)
      .attributes({
        widgetProperties: {
          color: 'var(--border-color-1)',
          'border-bottom': 'var(--border-color-1)',
          lineThickness: 1,
          lineWidth: '100',
          textContent: '',
          show: true,
          contentAlignment: 'center',
        } as BaseWidgetProperties,
      } as WorkflowWidgetConfigurationAttributes)
      .build(),
  ];
}
function bulkPaymentDownloadCsv() {
  return [
    new TextWidgetBuilder()
      .text('<h3>Download Template CSV</h3>')
      .configDisplayName('Text Widget: Download Template CSV')
      .gridPosition({ x: 0, y: 23, rows: 1, cols: 10 } as GridPosition)
      .cssConfig({
        fontSize: 20,
        textAlignment: 'left',
      })
      .build(),
    new TextWidgetBuilder()
      .text(
        '<p>Note :  We only accept payments using our provided template. Using any other CSV file will result in rejection due to potential risks associated with sensitive information and misinterpretation of data. Please ensure that you utilize our template for a seamless payment experience.</p>',
      )
      .configDisplayName('Text Widget:Download CSV Sub header')
      .gridPosition({ x: 0, y: 24, rows: 2, cols: 45 } as GridPosition)
      .cssConfig({
        fontSize: 16,
        color: 'var(--typography-color-0)',
        textAlignment: 'left',
      })
      .build(),
    new FormSubmissionButtonWidgetBuilder()
      .configDisplayName('CTA Button: Download CSV')
      .cssConfig({
        backGroundColor: 'var(--palette-color-6)',
        color: 'var(--palette-color-3)',
        borderRadiusTopLeft: 6,
        borderRadiusBottomLeft: 6,
        borderRadiusTopRight: 6,
        borderRadiusBottomRight: 6,
        fontSize: 14,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        borderWidth: '1',
        borderColor: 'var(--palette-color-3)',
      })
      .attributes({
        widgetProperties: {
          textContent: 'Download CSV',
          buttonActionType: ButtonActionType.DOWNLOAD_CSV,
          zoneToNavigate: '',
          urlToNavigate: '',
          lefticon: 'download',
        } as BaseWidgetProperties,
      } as WorkflowWidgetConfigurationAttributes)
      .gridPosition({ x: 0, y: 26, rows: 2, cols: 13 } as GridPosition)
      .build(),
    new LineWidgetBuilder()
      .configDisplayName('Line Widget : Download CSV')
      .gridPosition({ x: 0, y: 29, rows: 1, cols: 45 } as GridPosition)
      .attributes({
        widgetProperties: {
          color: 'var(--border-color-1)',
          'border-bottom': 'var(--border-color-1)',
          lineThickness: 1,
          lineWidth: '100',
          textContent: '',
          show: true,
          contentAlignment: 'center',
        } as BaseWidgetProperties,
      } as WorkflowWidgetConfigurationAttributes)
      .build(),
  ];
}
