export const notificationAccountInfoTemplate = `<div class="w-100 h-100">
<div class="is-space-between">
    <div class='is-vertical-align'>
        <div class='is-vertical-align align-left'>
            <div class="ben-value font-weight-bold">{{notification.selectedNotification.sender.name}}</div>
            <div class="ben-label">{{notification.selectedNotification.sender.accountNumber}}</div>
        </div>
    </div>
    <div class='is-vertical-align text-right'>
        <div class="ben-value">&nbsp;</div>
        <div class="ben-label">{{notification.selectedNotification.sender.sortCode}}</div>
    </div>
</div>
</div>`;
