import {
  Constants,
  GridPosition,
  WidgetNames,
  WidgetTitles,
  WorkflowWidgetConfig,
} from '@finxone-platform/shared/sys-config-types';
import { getZoneSpecificCSSConfig } from '../onboarding/onboarding.type';
import { defaultInputCssConfig } from '../payments/payments.type';

export const userManagementWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: WidgetNames.USER_MANAGEMENT_WIDGET,
  name: WidgetNames.USER_MANAGEMENT_WIDGET,
  widgetConfiguration: {
    widgetDisplayName: 'User Properties',
    widgetConfigDisplayName: WidgetTitles.USER_PROPERTIES,
    attributes: {
      widgetProperties: { textContent: '' },
      elementsConfig: [],
      css_config: {
        ...Constants.defaultBaseCssConfig,
        color: 'var(--color-0)',
      },
      columns: [
        {
          label: 'First Name',
          value: 'firstName',
          selected: true,
        },
        {
          label: 'Last Name',
          value: 'lastName',
          selected: true,
        },
        {
          label: 'Email',
          value: 'email',
          selected: true,
        },
        {
          label: 'Phone',
          value: 'phoneNumber',
          selected: true,
        },
      ],
    },
    isRemovable: true,
  },
  gridPosition: {
    x: 0,
    y: 0,
    minItemCols: 1,
    minItemRows: 1,
    rows: 16,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const userDetailWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: Math.floor(Math.random() * (1000000000 + 1)),
  name: WidgetNames.USER_DETAIL_WIDGET,
  widgetConfiguration: {
    isVisible: true,
    widgetConfigDisplayName: 'User Detail',
    widgetDisplayName: WidgetTitles.USER_DETAIL_WIDGET,
    attributes: {
      elementsConfig: [],
      widgetProperties: { textContent: '' },
      css_config: Constants.defaultBaseCssConfig,
    },
    isRemovable: true,
    isWidgetPage: true,
  },
  gridPosition: {
    x: 3,
    y: 2,
    minItemCols: 1,
    minItemRows: 15,
    rows: 15,
    cols: 9,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const userContactDetailWidgetConfig = {
  widgetTypeConfig: 'new',
  name: WidgetNames.CONTACT_DETAIL_WIDGET,
  uniqueId: Math.floor(Math.random() * (1000000000 + 1)),
  widgetConfiguration: {
    isVisible: true,
    widgetConfigDisplayName: 'Contact Detail',
    widgetDisplayName: WidgetTitles.CONTACT_DETAIL_WIDGET,
    attributes: {
      elementsConfig: [],
      widgetProperties: { textContent: '' },
      css_config: Constants.defaultBaseCssConfig,
    },
    isRemovable: true,
    showMargin: true,
    showPadding: true,
    showTextColor: true,
    showFontStyle: true,
    showHorizontalAlign: true,
    showVerticalAlign: true,
    isWidgetPage: true,
  },
  gridPosition: {
    x: 3,
    y: 15,
    minItemCols: 1,
    minItemRows: 15,
    rows: 8,
    cols: 9,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const userAccountDetailWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: Math.floor(Math.random() * (1000000000 + 1)),
  name: WidgetNames.ACCOUNT_DETAIL_WIDGET,
  widgetConfiguration: {
    isVisible: true,
    widgetConfigDisplayName: 'Account Detail',
    widgetDisplayName: WidgetTitles.ACCOUNT_DETAIL_WIDGET,
    attributes: {
      elementsConfig: [],
      widgetProperties: { textContent: '' },
      css_config: Constants.defaultBaseCssConfig,
    },
    isRemovable: true,
    isWidgetPage: true,
    uniqueId: 1345566576,
  },
  gridPosition: {
    x: 3,
    y: 40,
    minItemCols: 1,
    minItemRows: 10,
    rows: 10,
    cols: 9,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const importUserWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: Math.floor(Math.random() * (1000000000 + 1)),
  name: WidgetNames.IMPORT_USER_WIDGET,
  widgetConfiguration: {
    isVisible: true,
    widgetConfigDisplayName: 'Import User',
    widgetDisplayName: WidgetTitles.IMPORT_USER_WIDGET,
    elementsConfig: [],
    attributes: {
      css_config: Constants.defaultBaseCssConfig,
      elementsConfig: [],
      widgetProperties: { textContent: '' },
    },
    isRemovable: true,
    isWidgetPage: true,
  },
  gridPosition: {
    x: 3,
    y: 20,
    minItemCols: 1,
    minItemRows: 15,
    rows: 15,
    cols: 9,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const accountListWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: Math.floor(Math.random() * (1000000000 + 1)),
  name: WidgetNames.ACCOUNT_LIST_WIDGET,
  widgetConfiguration: {
    isVisible: true,
    widgetConfigDisplayName: 'Account List',
    widgetDisplayName: WidgetTitles.ACCOUNT_LIST_WIDGET,
    attributes: {
      elementsConfig: [],
      widgetProperties: { textContent: '' },
      css_config: Constants.defaultBaseCssConfig,
    },
    isWidgetPage: true,
    isRemovable: true,
  },
  gridPosition: {
    x: 3,
    y: 30,
    minItemCols: 1,
    minItemRows: 40,
    rows: 40,
    cols: 9,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const passwordSettingWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: Math.floor(Math.random() * (1000000000 + 1)),
  name: WidgetNames.PASSWORD_SETTING_WIDGET,
  widgetConfiguration: {
    isVisible: true,
    widgetConfigDisplayName: 'Password Setting',
    widgetDisplayName: WidgetTitles.PASSWORD_SETTING_WIDGET,
    attributes: {
      css_config: Constants.defaultBaseCssConfig,
      elementsConfig: [],
      widgetProperties: { textContent: '' },
    },
    isRemovable: true,
  },
  gridPosition: {
    x: 3,
    y: 0,
    minItemCols: 3,
    minItemRows: 30,
    rows: 30,
    cols: 9,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const addUserButtonWidgetConfig = {
  widgetTypeConfig: 'new',
  name: WidgetNames.CTA_BUTTON_WIDGET,
  uniqueId: Math.floor(Math.random() * (1000000000 + 1)),
  widgetConfiguration: {
    isVisible: true,
    widgetConfigDisplayName: 'CTA - Submit !',
    widgetDisplayName: WidgetTitles.CTA_BUTTON_WIDGET,
    attributes: {
      elementsConfig: [],
      label: 'CTA - Submit !',
      sub_heading: ' ',
      title: 'CTA - Submit !',
      btnFnName: 'addEditUser',
      show: true,
      css_config: {
        ...Constants.defaultBaseCssConfig,
        verticalAlignment: 'center',
        textAlignment: 'center',
        color: '',
        backGroundColor: '',
      },
      widgetProperties: {
        textContent: 'CTA - Submit !',
      },
    },
    isRemovable: true,
  },
  gridPosition: {
    x: 3,
    y: 30,
    cols: 9,
    rows: 5,
    minItemRows: 5,
    dragEnabled: true,
    resizeEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const addressWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: Math.floor(Math.random() * (1000000000 + 1)),
  name: WidgetNames.ADDRESS_WIDGET_USER,
  widgetConfiguration: {
    isVisible: true,
    widgetConfigDisplayName: 'Address',
    widgetDisplayName: WidgetTitles.ADDRESS_WIDGET,
    attributes: {
      css_config: Constants.defaultBaseCssConfig,
      widgetProperties: {
        textContent: '',
      },
      elementsConfig: [],
    },
    isRemovable: true,
  },
  gridPosition: {
    x: 3,
    y: 0,
    minItemCols: 1,
    minItemRows: 20,
    rows: 20,
    cols: 9,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const assignPersonaRoleWidgetConfig: WorkflowWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: WidgetNames.ASSIGN_PERSONA_ROLE,
  name: WidgetNames.ASSIGN_PERSONA_ROLE,
  widgetConfiguration: {
    isRemovable: false,
    widgetDisplayName: WidgetTitles.ASSIGN_PERSONA_ROLE,
    widgetConfigDisplayName: WidgetTitles.ASSIGN_PERSONA_ROLE,
    attributes: {
      widgetProperties: {
        textContent: '',
        assignPersonaLabel: 'Assign Persona',
        assignRolePermissionLabel: 'Assign Role/Permission',
        assignPersonaSelectionValue: [],
        inputFieldSettings: {
          isIconAllowed: false,
          css_config: { ...defaultInputCssConfig, inputWidth: 95 },
        },
      },

      field_attributes: [
        {
          name: 'assign_persona',
          decision_config: { required: true },
          label: 'Assign Persona',
          type: 'dropdown',
          options: [],
          additional_config: {},
        },
        {
          name: 'assign_role_permission',
          decision_config: { required: true },
          label: 'Assign Role/Permission',
          type: 'dropdown',
          options: [],
          additional_config: {},
        },
      ],
      elementsConfig: [],
      name: WidgetNames.ASSIGN_PERSONA_ROLE,
      label: WidgetTitles.ASSIGN_PERSONA_ROLE,
      css_config: {
        ...Constants.defaultBaseCssConfig,
        fontSize: 16,
        textFont: 'var(--font-family)',
      },
    },
  },
  gridPosition: {
    y: 4,
    x: 0,
    rows: 6,
    cols: 14,
  } as GridPosition,
  widgetIconPath: 'generic-widget',
};

export function generateManageUserStep01WidgetsConfig() {
  return {
    widgetTypeConfig: 'new',
    uniqueId: WidgetNames.MANAGE_USER_STEP_01_WIDGET,
    name: WidgetNames.MANAGE_USER_STEP_01_WIDGET,
    widgetConfiguration: {
      isVisible: true,
      widgetDisplayName: '',
      widgetConfigDisplayName: 'Manage User Step 01 Widget',
      isRemovable: false,
      attributes: {
        elementsConfig: [],
        widgetProperties: {
          textContent: '',
          inputFieldSettings: {
            css_config: defaultInputCssConfig,
          },
        },
        show: true,
        decision_config: {
          required: true,
        },
        field_attributes: [
          {
            name: 'first_name',
            decision_config: {
              required: true,
            },
            label: 'First Name *',
            type: 'text',
            options: [],
            additional_config: {},
          },
          {
            name: 'middle_name',
            decision_config: { required: false },
            label: 'Middle Name',
            type: 'text',
            options: [],
            additional_config: {},
          },
          {
            name: 'last_name',
            decision_config: { required: true },
            label: 'Last Name *',
            type: 'text',
            options: [],
            additional_config: {},
          },
          {
            name: 'country_code',
            type: 'dropdown',
            options: [{ country_codes_list: [] }],
            decision_config: {
              required: true,
            },
          },
          {
            name: 'phone',
            label: 'Phone Number',
            type: 'number',
            options: [],
            decision_config: {
              required: true,
              pattern: true,
              verifyViaOTP: true,
            },
            additional_config: {},
          },
        ],
        css_config: getZoneSpecificCSSConfig(),
      },
    },
    gridPosition: { y: 8, rows: 9, x: 0, cols: 13 } as GridPosition,
    widgetIconPath: '',
  } as WorkflowWidgetConfig;
}

export function generateManageUserStep02WidgetsConfig() {
  return {
    widgetTypeConfig: 'new',
    uniqueId: WidgetNames.MANAGE_USER_STEP_02_WIDGET,
    name: WidgetNames.MANAGE_USER_STEP_02_WIDGET,
    widgetConfiguration: {
      isVisible: true,
      widgetDisplayName: '',
      widgetConfigDisplayName: 'Manage User Step 02 Widget',
      isRemovable: false,
      attributes: {
        widgetProperties: {
          textContent: '',
          inputFieldSettings: {
            css_config: defaultInputCssConfig,
          },
        },
        elementsConfig: [],
        label: 'Password',
        show: true,
        field_attributes: [
          {
            name: 'password',
            decision_config: {
              required: true,
            },
            label: 'Password',
            type: 'password',
            options: [],
            additional_config: {},
          },
          {
            name: 'confirm_password',
            decision_config: {
              required: true,
            },
            label: 'Confirm password',
            type: 'password',
            options: [],
            additional_config: {},
          },
          {
            name: 'userName',
            decision_config: {
              required: true,
            },
            label: 'Username',
            type: 'text',
            options: [],
            additional_config: {},
          },
        ],
        css_config: getZoneSpecificCSSConfig(),
      },
    },
    gridPosition: { y: 7, rows: 8, x: 0, cols: 13 } as GridPosition,
    widgetIconPath: '',
  } as WorkflowWidgetConfig;
}

export const userListingColumns = [
  {
    label: 'First Name',
    value: 'firstName',
    handleBarKey: '<b>{{firstName }}</b>',
    selected: true,
  },
  {
    label: 'Last Name',
    value: 'lastName',
    handleBarKey: '<b>{{ lastName }}</b>',
    selected: true,
  },
  {
    label: 'Username',
    value: 'username',
    handleBarKey: '{{username}}',
    selected: true,
  },
  {
    label: 'Persona',
    value: 'persona',
    handleBarKey: '{{persona}}',
    selected: true,
  },
  {
    label: 'Email',
    value: `email'`,
    handleBarKey: '{{ email}}',
    selected: true,
  },
  {
    label: 'Phone Number',
    value: 'phoneNumber',
    handleBarKey: '{{phoneNumber}}',
    selected: true,
  },
];
export function generateManageUserStep03WidgetsConfig() {
  return {
    widgetTypeConfig: 'new',
    uniqueId: WidgetNames.MANAGE_USER_STEP_03_WIDGET,
    name: WidgetNames.MANAGE_USER_STEP_03_WIDGET,
    widgetConfiguration: {
      isRemovable: false,
      widgetDisplayName: WidgetTitles.MANAGE_USER_STEP_03_WIDGET,
      widgetConfigDisplayName: 'Add User Step 3 Widget',
      attributes: {
        css_config: Constants.defaultBaseCssConfig,
        widgetProperties: {
          textContent: '',
          streetLabel: 'Street',
          cityLabel: 'Town/City',
          stateRegionLabel: 'State/Region',
          countryLabel: 'Country',
          zipPostalLabel: 'ZIP/Postal Code',
          inputFieldSettings: {
            isIconAllowed: false,
            css_config: defaultInputCssConfig,
          },
        },
        elementsConfig: [],
      },
    },
    gridPosition: { y: 7, rows: 12, x: 0, cols: 13 } as GridPosition,
    widgetIconPath: '',
  } as WorkflowWidgetConfig;
}

export function generateManageUsersAssignPersonaWidgetsConfig() {
  return {
    widgetTypeConfig: 'new',
    uniqueId: WidgetNames.MANAGE_USER_ASSIGN_PERSONA,
    name: WidgetNames.MANAGE_USER_ASSIGN_PERSONA,
    widgetConfiguration: {
      isVisible: true,
      widgetDisplayName: '',
      widgetConfigDisplayName: 'Assign Persona Widget',
      isRemovable: false,
      attributes: {
        widgetProperties: {
          textContent: '',
          assignPersonaLabel: 'Assign Persona',
          assignRolePermissionLabel: 'Assign Role/Permission',
          assignPersonaSelectionValue: [],
          inputFieldSettings: {
            isIconAllowed: false,
            css_config: defaultInputCssConfig,
          },
        },
        elementsConfig: [],
        field_attributes: [
          {
            name: 'email',
            label: 'Email',
            type: 'email',
            field_config: {
              required: true,
              pattern: true,
            },
            options: [],
            additional_config: {},
          },
          {
            name: 'assign_persona',
            decision_config: { required: true },
            label: 'Assign Persona',
            type: 'dropdown',
            options: [],
            additional_config: {},
          },
          {
            name: 'assign_role_permission',
            decision_config: { required: true },
            label: 'Assign Role/Permission',
            type: 'dropdown',
            options: [],
            additional_config: {},
          },
        ],
        css_config: {
          ...Constants.defaultBaseCssConfig,
          textFont: 'var(--font-family)',
          paddingLeft: 10,
          paddingRight: 10,
        },
      },
    },
    gridPosition: { y: 4, rows: 8, x: 0, cols: 13 } as GridPosition,
    widgetIconPath: '',
  } as WorkflowWidgetConfig;
}

export function generateViewUsersAssignPersonaWidgetsConfig() {
  return {
    widgetTypeConfig: 'new',
    uniqueId: WidgetNames.VIEW_USER_ASSIGN_PERSONA,
    name: WidgetNames.VIEW_USER_ASSIGN_PERSONA,
    widgetConfiguration: {
      isVisible: true,
      widgetDisplayName: '',
      widgetConfigDisplayName: 'View User Assign Persona Widget',
      isRemovable: false,
      attributes: {
        widgetProperties: {
          textContent: '',
          assignPersonaLabel: 'Assign Persona',
          assignRolePermissionLabel: 'Assign Role/Permission',
          assignPersonaSelectionValue: [],
          inputFieldSettings: {
            isIconAllowed: false,
            css_config: defaultInputCssConfig,
          },
        },
        elementsConfig: [],
        field_attributes: [
          {
            name: 'assign_persona',
            decision_config: { required: true },
            label: 'Assign Persona',
            type: 'dropdown',
            options: [],
            additional_config: {},
          },
          {
            name: 'assign_role_permission',
            decision_config: { required: true },
            label: 'Assign Role/Permission',
            type: 'dropdown',
            options: [],
            additional_config: {},
          },
        ],
        css_config: {
          ...Constants.defaultBaseCssConfig,
          textFont: 'var(--font-family)',
          borderWidth: 1,
          borderColor: 'var(--border-color-1)',
          paddingUnit: 'px',
          paddingTop: 20,
          paddingLeft: 20,
          paddingRight: 20,
          paddingBottom: 46,
          borderRadiusTopLeft: 5,
          borderRadiusBottomLeft: 5,
          borderRadiusTopRight: 5,
          borderRadiusBottomRight: 5,
        },
      },
    },
    gridPosition: { y: 7, rows: 6, x: 15, cols: 12 } as GridPosition,
    widgetIconPath: '',
  } as WorkflowWidgetConfig;
}
export const guestUserListingColumns = [
  {
    label: 'Name/ID',
    value: 'firstName',
    handleBarKey: '<b>{{firstName }} {{lastName }}</b>',
    selected: true,
  },
  {
    label: 'Guest Type',
    value: 'guest_type',
    handleBarKey: '{{guest_type}}',
    selected: true,
  },
  {
    label: 'Invite Status',
    value: 'inviteStatus',
    handleBarKey: '{{inviteStatus}}',
    selected: true,
  },
  {
    label: 'Phone Number',
    value: 'phoneNumber',
    handleBarKey: '{{phoneNumber}}',
    selected: true,
  },
  {
    label: 'Email',
    value: `email'`,
    handleBarKey: '{{ email}}',
    selected: true,
  },
];
