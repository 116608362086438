import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { BeneficiariesSuccessCodes } from '@app/translations';
import { OtpParam } from '@finxone-platform/form-action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { APP_ZONES, BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { take, tap } from 'rxjs';
import { AccountService } from '../../../services/account-service/account-service.service';
import { FormActionState } from '../../../state/form-submision.state';
import { ProjectSettingsState } from '../../../state/project-settings.state';
import { RoleState } from '../../../state/role.state';
import { findAndShowAlertFromCode } from '../../alert-code-utils/alert-code.utils';
import { redirectToPage } from '../cta-button-actions.utils';

/**
 * Removes a beneficiary from an account using the provided widget properties and injector.
 *
 * This function retrieves necessary services and states using the injector, including
 * the Store, Router, AlertHandlerService, and AccountService. It attempts to remove
 * a beneficiary identified by the beneficiary ID from the form action state. If the
 * removal is successful, it shows a success alert and redirects to a specified page.
 * If the removal fails or the beneficiary ID is not found, it shows an error alert.
 *
 * @param widgetProperties - The properties of the widget used for navigation and configuration.
 * @param injector - The Angular injector used to retrieve necessary services.
 */
export function removeBeneficiaryFromAccount(widgetProperties: BaseWidgetProperties, injector: Injector) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  const alertHandlerService = injector.get(AlertHandlerService);
  const accountService = injector.get(AccountService);
  const beneficiaryId = store.selectSnapshot(FormActionState.getFormActionState)?.response?.formData?.bid;
  if (beneficiaryId) {
    accountService
      ?.removeBeneficiary(beneficiaryId)
      .pipe(
        take(1),
        tap((response) => {
          if (response) {
            findAndShowAlertFromCode(store, router, alertHandlerService, [
              BeneficiariesSuccessCodes.REMOVE_BENEFICIARY,
            ]);
            redirectToPage(router, widgetProperties, injector);
          } else {
            alertHandlerService.showAlertFn('error', 'An unexpected error occurred, please try again');
          }
        }),
      )
      .subscribe();
  } else {
    alertHandlerService.showAlertFn('error', 'Selected Beneficiary not found, please try again');
  }
}

/**
 * Handles the process of removing a beneficiary from an account, with conditional
 * redirection based on project settings and user roles. If SMS authentication is
 * enabled and the current role is not excluded from SMS authentication for removing
 * beneficiaries, redirects to an OTP page. Otherwise, directly removes the beneficiary.
 *
 * @param widgetProperties - The properties of the widget initiating the action.
 * @param injector - The Angular injector used to retrieve necessary services.
 */
export function callRemoveBeneficiaryAPI(widgetProperties: BaseWidgetProperties, injector: Injector) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  const projectSettingsData = store.selectSnapshot(ProjectSettingsState.getProjectSettings);
  const roleData = store.selectSnapshot(RoleState.getRole);

  if (projectSettingsData?.smsAuthenticationEnabled) {
    if (projectSettingsData.smsAuthenticationExclusion?.['removeBeneficiary']?.includes(roleData?.role)) {
      // previous scenario
      removeBeneficiaryFromAccount(widgetProperties, injector);
    } else {
      const parsedWidgetProperties = JSON.parse(JSON.stringify(widgetProperties));
      // OTP page redirection
      parsedWidgetProperties['zoneToNavigate'] = APP_ZONES.PAYMENT;
      parsedWidgetProperties['urlToNavigate'] = `authentication-sms?otp=${OtpParam.RemoveBeneficiary}`;
      redirectToPage(router, parsedWidgetProperties, injector);
    }
  } else {
    // previous scenario
    removeBeneficiaryFromAccount(widgetProperties, injector);
  }
}
