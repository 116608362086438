import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonActionType,
  GridPosition,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import {
  BackButtonWidgetBuilder,
  FormSubmissionButtonWidgetBuilder,
  LineWidgetBuilder,
  MultiIconQuickLinkWidgetBuilder,
  ProgressBarBuilder,
  QuickLinkWidgetBuilder,
  TextWidgetBuilder,
} from '../../widgets/example-builder';

const brokerSummaryContentCssConfig = {
  color: 'var(--typography-color-0)',
  fontSize: 16,
  textAlignment: 'left',
  borderRadiusTopLeft: 5,
  borderRadiusBottomLeft: 5,
  borderRadiusTopRight: 5,
  borderRadiusBottomRight: 5,
  paddingTop: 20,
  paddingLeft: 20,
  paddingRight: 20,
  paddingBottom: 20,
  borderWidth: 1,
  borderColor: 'var(--border-color-1)',
};

const defaultMultiQuickLinkEditWidgetConfig = {
  paddingLeft: 15,
  paddingRight: 15,
  fontSize: 16,
  borderRadiusTopLeft: 5,
  borderRadiusBottomLeft: 5,
  borderRadiusTopRight: 5,
  borderRadiusBottomRight: 5,
  borderWidth: 1,
  borderColor: 'var(--border-color-1)',
  backGroundColor: '#ffffff00', //transparent color
  color: 'var(--typography-color-0)',
  isTextBold: true,
};

export const commonLineWidget = ({
  gridPosition,
  configDisplayName,
  cssConfig,
}: {
  gridPosition: GridPosition;
  configDisplayName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cssConfig?: any;
}) =>
  new LineWidgetBuilder()
    .configDisplayName(`Line Widget: ${configDisplayName ?? ''}`)
    .gridPosition(gridPosition)
    .attributes({
      css_config: {
        marginTop: 15,
        ...cssConfig,
      },
      widgetProperties: {
        'border-bottom': 'var(--border-color-1)',
        color: 'var(--border-color-1)',
        contentAlignment: 'center',
        lineWidth: '100',
        lineThickness: 1,
        show: true,
        textContent: '',
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .build();

export const generateMultiQuickLinkWidget = ({
  textContent,
  navigateToPageUrl,
  displayName,
  gridPosition,
  zoneName,
  rightIcon = 'pencil',
  leftIcon = '',
  cssConfig,
}: {
  textContent: string;
  navigateToPageUrl: string;
  displayName: string;
  gridPosition: GridPosition;
  zoneName: APP_ZONES;
  rightIcon?: string;
  leftIcon?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cssConfig?: any;
}) =>
  new MultiIconQuickLinkWidgetBuilder()
    .icons({
      left: leftIcon,
      right: rightIcon,
    })
    .template(textContent)
    .zone(zoneName)
    .role()
    .navigateToPageUrl(navigateToPageUrl)
    .configDisplayName(displayName)
    .navigationOption('use-as-navigation')
    .cssConfig({ ...defaultMultiQuickLinkEditWidgetConfig, ...cssConfig })
    .gridPosition(gridPosition)
    .isRemovable(true)
    .build();

export function brokerSummaryTextWidget({
  textContent,
  displayName,
  gridPosition,
  cssConfig,
}: {
  textContent: string;
  displayName: string;
  gridPosition: GridPosition;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cssConfig?: any;
}) {
  return new TextWidgetBuilder()
    .text(textContent)
    .gridPosition(gridPosition)
    .configDisplayName(`Text Widget: ${displayName ?? ''}`)
    .cssConfig({ ...brokerSummaryContentCssConfig, ...cssConfig })
    .build();
}

export const commonProgressBarWidget = ({
  completionProgressPercentage,
  cssConfig,
  gridPosition,
}: {
  completionProgressPercentage: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cssConfig?: any;
  gridPosition?: GridPosition;
}) =>
  new ProgressBarBuilder()
    .configDisplayName('Progress Bar Widget')
    .setWidgetProperties({
      textContent: '',
      completionProgressPercentage,
      progressBarActiveColor: 'var(--palette-color-3)',
      progressBarColor: 'var(--palette-color-4)',
    })
    .cssConfig({
      color: 'var(--typography-color-0)',
      textAlignment: 'center',
      justifyContent: 'center',
      fontSize: 24,
      backGroundColor: 'transparent',
      ...cssConfig,
    })
    .gridPosition({
      y: 0,
      x: 0,
      rows: 2,
      cols: 15,
      ...gridPosition,
    } as GridPosition)
    .isRemovable(true)
    .build();

export const commonTextWidget = ({
  textContent,
  gridPosition,
  cssConfig,
  configDisplayName = '',
}: {
  textContent: string;
  gridPosition?: GridPosition;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cssConfig?: any;
  configDisplayName?: string;
}) =>
  new TextWidgetBuilder()
    .configDisplayName(`Text Widget ${configDisplayName ?? ''}`)
    .text(textContent)
    .cssConfig({
      color: 'var(--typography-color-0)',
      textAlignment: 'center',
      ...cssConfig,
    })
    .gridPosition({
      y: 0,
      x: 0,
      rows: 2,
      cols: 10,
      ...gridPosition,
    } as GridPosition)
    .build();

export const commonBackButtonQuickLink = ({
  gridPosition,
  cssConfig,
}: {
  gridPosition: GridPosition;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cssConfig?: any;
}) =>
  new BackButtonWidgetBuilder()
    .configDisplayName('Back Navigation Button')
    .cssConfig({
      ...cssConfig,
    })
    .gridPosition({ ...gridPosition, resizeEnabled: true, dragEnabled: true })
    .build();

export const commonQuickLink = ({
  text,
  gridPosition,
  zone,
  navigateToPageUrl,
  cssConfig,
  icon,
  configDisplayName = '',
}: {
  text: string;
  gridPosition?: GridPosition;
  zone: string;
  navigateToPageUrl: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cssConfig?: any;
  icon?: string;
  configDisplayName?: string;
}) =>
  new QuickLinkWidgetBuilder()
    .icon(icon ?? '')
    .zone(zone)
    .navigateToPageUrl(navigateToPageUrl)
    .navigationOption('use-as-navigation')
    .role()
    .text(text)
    .configDisplayName(`Quick Link ${configDisplayName ?? ''}`)
    .cssConfig({
      marginTop: 15,
      marginBottom: 15,
      color: 'var(--palette-color-6)',
      backGroundColor: 'var(--palette-color-3)',
      borderRadiusTopLeft: 5,
      borderRadiusBottomLeft: 5,
      borderRadiusTopRight: 5,
      borderRadiusBottomRight: 5,
      fontSize: 16,
      ...cssConfig,
    })
    .gridPosition({
      y: 27,
      x: 0,
      rows: 2,
      cols: 15,
      ...gridPosition,
    } as GridPosition)
    .build();

export const commonCTAButton = ({
  textContent,
  buttonActionType,
  zoneToNavigate,
  urlToNavigate,
  formKey,
  gridPosition,
  cssConfig,
  configDisplayName,
  lefticon,
}: {
  textContent: string;
  buttonActionType: ButtonActionType;
  zoneToNavigate: string;
  urlToNavigate: string;
  formKey?: string;
  gridPosition?: GridPosition;
  cssConfig?: any;
  configDisplayName?: string;
  lefticon?: string;
}) =>
  new FormSubmissionButtonWidgetBuilder()
    .gridPosition(gridPosition ?? ({ y: 27, x: 0, rows: 2, cols: 15 } as GridPosition))
    .configDisplayName(`CTA Button ${configDisplayName ?? ''}`)
    .cssConfig(
      cssConfig ?? {
        fontSize: 14,
        verticalAlignment: 'center',
        borderRadiusTopLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusBottomRight: 5,
        disabledBgColor: 'var(--palette-color-3)',
        disabledTextColor: 'var(--palette-color-6)',
      },
    )
    .attributes({
      widgetProperties: {
        textContent,
        buttonActionType,
        zoneToNavigate,
        urlToNavigate,
        formKey,
        lefticon,
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .build();
