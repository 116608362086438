import { Injectable } from '@angular/core';
import { PaginatedResponse } from '@finxone-platform/shared/sys-config-types';
import { PaymentRequestListResponse, PaymentRequestPayload } from '@finxone-platform/shared/utils';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { SetPaymentRequestSummaryApprovedorRejectedDate } from '../actions/notification.action';
import {
  CreatePaymentRequest,
  GeneratePaymentRequest,
  GeneratePaymentRequestPayload,
  GetPaymentRequest,
  PaymentRequestFailed,
  PaymentRequestSuccess,
  ResetPaymentRequestPayload,
  UpdatePaymentRequest,
  UpdatePaymentRequestFailed,
  UpdatePaymentRequestSuccess,
} from '../actions/payment-request.action';
import { AddProgressBarStack, RemoveProgressBarStack } from '../actions/progress-bar.action';
import { AccountService } from '../services/account-service/account-service.service';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PaymentStateModel {
  paymentRequestPendingAndApproval: PaginatedResponse<PaymentRequestListResponse>;
  paymentRequestPayload?: { ids: string[]; approvedStatus?: string };
}

@State<PaymentStateModel>({
  name: 'paymentRequest',
  defaults: {
    paymentRequestPendingAndApproval: {
      result: [],
      limit: 0,
      page: 0,
      totalPages: 0,
      totalItems: 0,
    },
  },
})
@Injectable()
export class PaymentRequestState {
  @Selector()
  static paymentRequest(state: PaymentStateModel) {
    return state;
  }

  constructor(private accountService: AccountService) {}

  @Action(CreatePaymentRequest)
  createPayment(ctx: StateContext<PaymentStateModel>, payload: CreatePaymentRequest) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'CreatePaymentRequest' }));

    return this.accountService.makePaymentRequest(payload.paymentRequest).pipe(
      tap(() => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'CreatePaymentRequest' }));
        ctx.dispatch(new PaymentRequestSuccess());
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'CreatePaymentRequest' }));
        ctx.dispatch(new PaymentRequestFailed());
        throw _err;
      }),
    );
  }

  @Action(UpdatePaymentRequest)
  updatePaymentRequest(ctx: StateContext<PaymentStateModel>, payload: UpdatePaymentRequest) {
    const approvalStatus = payload.paymentRequest.approvedStatus;
    const payloadPayment: PaymentRequestPayload = {
      ids: [payload.paymentRequest.id],
      approvedStatus: approvalStatus,
    };
    return this.accountService?.generatePaymentRequest(payloadPayment).pipe(
      tap((response) => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'UpdatePaymentRequest' }));

        // update the summary data
        ctx.dispatch(new SetPaymentRequestSummaryApprovedorRejectedDate(new Date().toString()));

        if (approvalStatus === 'approved') {
          ctx.dispatch(new UpdatePaymentRequestSuccess());
        }

        return response;
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'UpdatePaymentRequest' }));
        ctx.dispatch(new UpdatePaymentRequestFailed());
        throw _err;
      }),
    );
  }
  @Action(GetPaymentRequest)
  fetchPaymentRequest(ctx: StateContext<PaymentStateModel>, action: GetPaymentRequest) {
    try {
      return this.accountService
        .getPaymentRequest(action?.page, action?.limit, action?.status, action?.searchValue)
        .pipe(
          tap((listData) => {
            ctx.patchState({
              ...ctx.getState(),
              paymentRequestPendingAndApproval: listData,
            });
          }),
        );
    } catch (err) {
      return throwError(() => err);
    }
  }

  @Action(GeneratePaymentRequest)
  generatePaymentRequest(ctx: StateContext<PaymentStateModel>, action: GeneratePaymentRequest) {
    try {
      return this.accountService?.generatePaymentRequest(action.payload);
    } catch (err) {
      return throwError(() => err);
    }
  }

  @Action(GeneratePaymentRequestPayload)
  fetchPaymentRequestPayload(ctx: StateContext<PaymentStateModel>, action: GeneratePaymentRequestPayload) {
    ctx.patchState({
      ...ctx.getState(),
      paymentRequestPayload: action.payload,
    });
  }

  @Action(ResetPaymentRequestPayload)
  resetFormData({ patchState }: StateContext<PaymentStateModel>) {
    patchState({
      paymentRequestPayload: {
        ids: [],
        approvedStatus: '',
      },
    });
  }
}
