export const enterEmailDesktopConfig = {
  forgotPass: { x: 17, y: 4, rows: 2, cols: 15 },
  enterYourEmail: { x: 17, y: 6, rows: 2, cols: 15 },
  backButton: { x: 17, y: 13, rows: 2, cols: 15 },
  enterEmail: {
    x: 17,
    y: 8,
    rows: 5,
    cols: 15,
  },
};

export const enterEmailMobileConfig = {
  forgotPass: { x: 0, y: 2, rows: 3, cols: 15 },
  enterYourEmail: { x: 0, y: 4, rows: 3, cols: 15 },
  backButton: { x: 0, y: 20, rows: 3, cols: 15 },
  enterEmail: {
    x: 0,
    y: 6,
    rows: 12,
    cols: 15,
  },
};
