import {
  APP_ZONES,
  BaseWidgetProperties,
  GridPosition,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import { inputSettings } from '../../../widgets';
import { userListingColumns } from '../../../widgets/admin-roles/user-managment.widgets';
import {
  DesktopManageUserListingWidgetBuilder,
  LineWidgetBuilder,
  QuickLinkWidgetBuilder,
  TextWidgetBuilder,
} from '../../../widgets/example-builder';

export const manageUsersWidgets = [
  new TextWidgetBuilder()
    .text('<h3>Manage Users</h3>')
    .configDisplayName('Text Widget: Manage Users')
    .gridPosition({ cols: 40, y: 0, x: 0, rows: 2 } as GridPosition)
    .cssConfig({
      color: 'var(--typography-color-0)',
      textAlignment: 'left',
      fontSize: 20,
      isTextBold: true,
    })
    .build(),

  new LineWidgetBuilder()
    .configDisplayName('Line Widget')
    .gridPosition({
      cols: 40,
      y: 1,
      x: 0,
      rows: 1,
      layerIndex: 1,
    } as GridPosition)
    .attributes({
      widgetProperties: {
        textContent: '',
        show: true,
        color: 'var(--border-color-1)',
        paddingTop: 20,
        paddingBottom: 20,
        lineThickness: 1,
        lineWidth: 100,
        contentAlignment: 'center',
      } as BaseWidgetProperties,
    } as unknown as WorkflowWidgetConfigurationAttributes)
    .build(),

  new DesktopManageUserListingWidgetBuilder()
    .text('Listing Widget')
    .configDisplayName(`Listing Widget `)
    .gridPosition({ x: 0, y: 5, rows: 14, cols: 40 } as GridPosition)
    .cssConfig({
      color: 'var(--palette-color-1)',
      textAlignment: 'left',
      justifyContent: 'left',
      fontSize: '16',
      borderWidth: 1,
      borderColor: 'var(--palette-color-2)',
      borderStyle: 'solid',
      borderRadiusUnit: 'px',
      borderRadiusTopLeft: 5,
      borderRadiusBottomLeft: 5,
      borderRadiusTopRight: 5,
      borderRadiusBottomRight: 5,
    })
    .attributes({
      widgetProperties: {
        icon: 'search',
        textContent: ``,
        showEntity: false,
        entityInformation: {
          entity: 'employee',
          dateFromKey: 'start',
          dateToKey: 'end',
        },
      } as BaseWidgetProperties,
      tableGridSetting: {
        showGridLines: false,
        gridLinesColor: 'var(--border-color-1)',
      },
      rowSettings: {
        rowBgColor: 'var(--palette-color-6)',
        showErrorDuplicationRowBgColor: false,
      },
      columns: userListingColumns,
      selectedListOption: 'all',
      viewListOption: 'all',
    } as unknown as WorkflowWidgetConfigurationAttributes)
    .inputSetting({
      ...inputSettings,
      css_config: {
        ...inputSettings.css_config,
        inputWidth: 110,
      },
      label: 'Search',
      isIconAllowed: true,
    })
    .build(),
  new TextWidgetBuilder()
    .text('<h3>User List</h3>')
    .configDisplayName('Text Widget: User List')
    .gridPosition({
      cols: 15,
      y: 3,
      x: 0,
      rows: 2,
      layerIndex: 1,
    } as GridPosition)
    .cssConfig({
      color: 'var(--palette-color-1)',
      textAlignment: 'left',
      fontSize: 16,
      marginTop: 2,
      paddingLeft: 3,
      paddingUnit: 'rem',
      isTextBold: true,
    })
    .build(),
  new QuickLinkWidgetBuilder()
    .zone(APP_ZONES.USER_MANAGEMENT)
    .navigateToPageUrl('assign-persona')
    .navigationOption('use-as-navigation')
    .role()
    .configDisplayName(`Quick Link: Assign Persona to New User`)
    .cssConfig({
      color: 'var(--palette-color-6)',
      backGroundColor: 'var(--palette-color-3)',
      borderWidth: 1,
      borderColor: 'var(--palette-color-3)',
      borderRadiusTopLeft: 5,
      borderRadiusBottomLeft: 5,
      borderRadiusTopRight: 5,
      borderRadiusBottomRight: 5,
      fontSize: 16,
    })
    .gridPosition({ y: 19, x: 0, rows: 2, cols: 12 } as GridPosition)
    .setWidgetProperties({
      textContent: 'Assign Persona to New User',
      icon: 'plus-circle',
    })
    .build(),
];
