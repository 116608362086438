import {
  APP_ZONES,
  ButtonActionType,
  ButtonSizeOptions,
  ButtonTypes,
  GridPosition,
  PermissionConfig,
  WidgetNames,
  WorkflowConfig,
  WorkflowPageConfig,
} from '@finxone-platform/shared/sys-config-types';
import { FormSubmissionButtonWidgetBuilder } from '../../defaults/widgets';

//Bug - 6758
// This migration is responsible for replacing the 'back' quick link on 'Card Cancelled' page with CTA

const CtaButtonConfig = new FormSubmissionButtonWidgetBuilder()
  .configDisplayName('Button: Submit Cancel card')
  .setWidgetProperties({
    textContent: 'Back to Home',
    zoneToNavigate: APP_ZONES.LANDING,
    urlToNavigate: 'home',
    buttonActionType: ButtonActionType.REFRESH_CARDS_AND_REDIRECT,
  })
  .gridPosition({ y: 28, rows: 3 } as GridPosition)
  .cssConfig({
    fontSize: 16,
    textFont: 'var(--font-family)',
  })
  .setGlobalStylingClass(true, `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`)
  .build();

export const getV1_0_145ToV1_0_146 = (workflowConfig: WorkflowConfig) => {
  const oneToOneKeys = Object.keys(workflowConfig);
  const mappingObject = {};
  // Assign mappings to not modify this version upgrade
  oneToOneKeys.forEach((key) => {
    Object.assign(mappingObject, {
      [key]: key,
    });
  });
  return {
    ...mappingObject,
    // add new version number
    version: {
      key: 'version',
      transform: () => {
        return 'v1.0.146';
      },
    },
    permissionSections: {
      key: 'permissionSections',
      transform: (permissionSection: PermissionConfig): PermissionConfig => {
        const { roles, subscriptions } = permissionSection;
        roles.forEach((role) => {
          const cardManagementZone = role.zones[APP_ZONES.CARD_MANAGEMENT_ZONE];
          if (cardManagementZone) {
            updatePagesByDeviceType(cardManagementZone);
          }
        });
        return <PermissionConfig>{
          zones: permissionSection.zones,
          subscriptions,
          roles,
        };
      },
    },
  };
};

const updatePagesByDeviceType = (zone: { canvases: any }) => {
  const devices = ['mobile', 'desktop', 'tablet'] as const;

  devices.forEach((device) => {
    const pages = zone?.canvases?.subscription?.['default']?.[device]?.pages as
      | WorkflowPageConfig[]
      | undefined;
    if (pages?.length) {
      updateTargetPages(pages);
    }
  });
};

function updateTargetPages(pages: WorkflowPageConfig[]) {
  const page = pages.find((page) => page.pageConfiguration.url === 'card-cancelled');
  if (page) findTargetWidgetAndUpdate(page);

  return pages;
}

function findTargetWidgetAndUpdate(page: WorkflowPageConfig | undefined) {
  const widgetIndex = page?.widgets.findIndex((widget) => widget.name === WidgetNames.QUICK_LINKS_WIDGET);
  if (widgetIndex && page?.widgets) {
    page.widgets[widgetIndex] = CtaButtonConfig;
  }
  return page;
}
