import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { ACCOUNTTYPE, BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { FormActionState } from '../../../state/form-submision.state';
import { redirectToPage } from '../cta-button-actions.utils';

/**
 * Edits the beneficiary based on the account type and redirects to the appropriate page.
 *
 * @param widgetProperties - The properties of the widget, including navigation details.
 * @param injector - The Angular injector used to retrieve services.
 *
 * This function retrieves the current form action state to determine the beneficiary's account type.
 * It sets the navigation URL in the widget properties based on whether the account type is personal
 * or business, and then redirects to the corresponding page using the router.
 */
export function editBeneficiary(widgetProperties: BaseWidgetProperties, injector: Injector) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  const beneficiaryType = store.selectSnapshot(FormActionState.getFormActionState)?.response?.formData
    ?.accountType;
  const parsedWidgetProperties = JSON.parse(JSON.stringify(widgetProperties));
  if (beneficiaryType === ACCOUNTTYPE.PERSONAL) {
    parsedWidgetProperties['urlToNavigate'] = 'edit-beneficiary-personal';
    redirectToPage(router, parsedWidgetProperties, injector);
  } else {
    parsedWidgetProperties['urlToNavigate'] = 'edit-beneficiary-business';
    redirectToPage(router, parsedWidgetProperties, injector);
  }
}
