import { UUID } from '@finxone-platform/shared/base-types';
import {
  beneficiarySelectionTemplate,
  bulkPaymentAccountListingTemplate,
  currencyItemTemplate,
  paymentIntlNewBeneficiaryAddedTemplate,
} from '@finxone-platform/shared/default-templates';
import {
  APP_ZONES,
  Constants,
  DummyRole,
  InputCssConfig,
  InputFieldSettings,
  WidgetNames,
  WidgetTitles,
  WorkflowWidgetConfig,
  cryptoRandomUUID,
} from '@finxone-platform/shared/sys-config-types';
import {
  addBeneFasterPaymentNestedWidgets,
  addBeneficiaryNestedWidget,
  intlBeneAddressNestedWidgets,
} from '../nested-widgets/beneficiary/beneficiary';
import { exchangeQuoteTemplate } from '../../zones/payments/templates/exchange-quote.template';
import { exchangeSummaryTemplate } from '../../zones/payments/templates/exchange-summary.template';

export function generateAddBeneficiaryWidget(type: 'business' | 'personal'): WorkflowWidgetConfig {
  const addBeneficiaryWidget: WorkflowWidgetConfig = {
    widgetTypeConfig: 'new',
    uniqueId: new UUID().toString(),
    name: WidgetNames.ADD_BENEFICIARY_WIDGET,
    widgetConfiguration: {
      isRemovable: false,
      widgetDisplayName: '',
      widgetConfigDisplayName: WidgetTitles.ADD_BENEFICIARY_WIDGET,
      nestedWidgets: addBeneficiaryNestedWidget,
      attributes: {
        widgetProperties: {
          textContent: '',
          recipientNameLabel: type === 'business' ? 'Business Name' : 'Recipient Name',
          firstNameLabel: 'First Name',
          lastNameLabel: 'Last Name',
          accountNumberLabel: 'Account Number',
          sortCodeLabel: 'Sort Code',
          bicLabel: 'BIC (Optional)',
          ibanLabel: 'IBAN',
          buttonContent: 'Proceed',
          beneficiaryType: type,
        },
        elementsConfig: [],
        css_config: Constants.defaultBaseCssConfig,
      },
    },
    gridPosition: {
      x: 0,
      y: 10,
      rows: 19,
      cols: 15,
      dragEnabled: true,
      resizeEnabled: true,
    },
    widgetIconPath: '',
  };
  return addBeneficiaryWidget as WorkflowWidgetConfig;
}

export const defaultInputCssConfig = {
  backgroundColor: 'transparent',
  inactiveBorderColor: 'var(--border-color-2)',
  inactiveBackgroundColor: 'transparent',
  borderRadiusTopLeft: 5,
  borderRadiusTopRight: 5,
  borderRadiusBottomLeft: 5,
  borderRadiusBottomRight: 5,
  borderWidth: 1,
  borderColor: 'var(--border-color-1)',
  inputTextColor: 'var(--typography-color-0)',
  borderStyleUnit: 'px',
  inputHeight: 44,
  inputWidth: 100,
  iconSize: 24,
  iconSizeUnit: 'px',
};

export function getAddBeneficiaryWidget(type: 'business' | 'personal'): WorkflowWidgetConfig {
  const addBeneficiaryUkfpWidget: WorkflowWidgetConfig = {
    widgetTypeConfig: 'new',
    uniqueId: new UUID().toString(),
    name: WidgetNames.ADD_BENEFICIARY_FASTER_PAYMENTS,
    widgetConfiguration: {
      isRemovable: false,
      widgetDisplayName: '',
      widgetConfigDisplayName: WidgetTitles.ADD_BENEFICIARY_WIDGET,
      nestedWidgets: addBeneFasterPaymentNestedWidgets,
      attributes: {
        widgetProperties: {
          textContent: '',
          recipientNameLabel: type === 'business' ? 'Business Name' : 'Recipient Name',
          firstNameLabel: 'First Name',
          lastNameLabel: 'Last Name',
          accountNumberLabel: 'Account Number',
          sortCodeLabel: 'Sort Code',
          bicLabel: 'BIC',
          ibanLabel: 'IBAN',
          buttonContent: 'Proceed',
          beneficiaryType: type,
        },
        elementsConfig: [],
        css_config: Constants.defaultBaseCssConfig,
      },
    },
    gridPosition: {
      x: 0,
      y: 10,
      rows: 19,
      cols: 15,
      dragEnabled: true,
      resizeEnabled: true,
    },
    widgetIconPath: '',
  };
  return addBeneficiaryUkfpWidget as WorkflowWidgetConfig;
}

export function getIntlBeneficiaryAddressWidget(): WorkflowWidgetConfig {
  const intlBeneficiaryAddressWidget: WorkflowWidgetConfig = {
    widgetTypeConfig: 'new',
    uniqueId: WidgetNames.INTL_BENEFICIARY_ADDRESS,
    name: WidgetNames.INTL_BENEFICIARY_ADDRESS,
    widgetConfiguration: {
      isRemovable: false,
      widgetDisplayName: '',
      widgetConfigDisplayName: WidgetTitles.INTL_BENEFICIARY_ADDRESS_WIDGET,
      nestedWidgets: intlBeneAddressNestedWidgets,
      attributes: {
        css_config: Constants.defaultBaseCssConfig,
        widgetProperties: {
          textContent: '',
          enableAddress2: true,
          enableAddress3: true,
          enableAddress4: true,
          streetLabel: 'Street',
          addressLine1: 'Address line 1',
          addressLine2: 'Address line 2',
          addressLine3: 'Address line 3',
          addressLine4: 'Address line 4',
          cityLabel: 'City',
          stateRegionLabel: 'State/Region',
          countryLabel: 'Country',
          zipPostalLabel: 'Postal code',
        },
        elementsConfig: [],
      },
    },
    gridPosition: {
      x: 0,
      y: 4,
      rows: 33,
      cols: 15,
      dragEnabled: true,
      resizeEnabled: true,
    },
    widgetIconPath: '',
  };
  return intlBeneficiaryAddressWidget;
}

export const intlBeneficiaryAdded: WorkflowWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: WidgetNames.INTL_BENEFICIARY_ADDED,
  name: WidgetNames.INTL_BENEFICIARY_ADDED,
  widgetConfiguration: {
    isRemovable: false,
    widgetDisplayName: '',
    widgetConfigDisplayName: WidgetTitles.INTL_BENEFICIARY_ADDED_WIDGET,
    attributes: {
      widgetProperties: {
        textContent: paymentIntlNewBeneficiaryAddedTemplate,
      },
      elementsConfig: [],
      css_config: {
        ...Constants.defaultBaseCssConfig,
        fontWeight: 400,
        isTextBold: true,
        fontSize: 18,
      },
    },
  },
  gridPosition: {
    x: 0,
    y: 8,
    rows: 13,
    cols: 15,
    dragEnabled: true,
    resizeEnabled: true,
  },
  widgetIconPath: '',
};

export const addBeneficiaryIntlWidget = (type: 'business' | 'personal'): WorkflowWidgetConfig => ({
  widgetTypeConfig: 'new',
  uniqueId: cryptoRandomUUID(),
  name: WidgetNames.ADD_BENEFICIARY_INTL_WIDGET,
  widgetConfiguration: {
    isRemovable: false,
    widgetDisplayName: '',
    widgetConfigDisplayName: WidgetTitles.ADD_BENEFICIARY_INTL_WIDGET,
    attributes: {
      widgetProperties: {
        textContent: '',
        beneficiaryType: type,
        buttonContent: 'Proceed',
        inputFieldSettings: { css_config: defaultInputCssConfig },
        ...{
          name: 'Full Name',
          firstName: 'First Name',
          lastName: 'Last Name',
          bankName: 'Bank Name',
          accountNumber: 'Account Number',
          iban: 'IBAN',
          aba: 'ABA Routing Number',
          bic: 'SWIFT/BIC Code',
          'rtn-canada': 'RTN',
          'sort-code': 'Sort Code',
          'bsb-code': 'BSB Number',
          ifsc: 'IFSC Code',
          'bank-code': 'Bank Code',
          'branch-code': 'Branch Code',
          cnaps: 'CNAPS',
          clabe: 'CLABE',
          sortCodeLabel: 'Sort Code',
        },
      },
      elementsConfig: [],
      css_config: Constants.defaultBaseCssConfig,
    },
  },
  gridPosition: {
    x: 0,
    y: 9,
    rows: 10,
    cols: 15,
    dragEnabled: true,
    resizeEnabled: true,
  },
  widgetIconPath: '',
});

export function getAddIntlBeneficiaryCredentialsWidget(
  type: 'business' | 'personal',
  override?: any,
): WorkflowWidgetConfig {
  const addIntlBeneficiaryCredentialsWidget: WorkflowWidgetConfig = {
    widgetTypeConfig: 'new',
    uniqueId: cryptoRandomUUID(),
    name: WidgetNames.INTERNATIONAL_BENEFICIARY_CREDENTIALS_WIDGET,
    widgetConfiguration: {
      isRemovable: false,
      widgetDisplayName: '',
      widgetConfigDisplayName: WidgetTitles.INTERNATIONAL_BENEFICIARY_CREDENTIALS_WIDGET,
      nestedWidgets: {
        continueButton: {
          name: WidgetNames.BUTTON,
          widgetConfigDisplayName: 'Continue Button Styling',
          attributes: {
            widgetProperties: {
              textContent: 'Continue',
            },
            elementsConfig: [],
            css_config: {
              ...Constants.defaultUiNestedButtonCssConfig,
              verticalAlignment: 'center',
            },
          },
        },

        previousButton: {
          name: WidgetNames.BUTTON,
          widgetConfigDisplayName: 'Previous Button Styling',
          attributes: {
            widgetProperties: {
              textContent: 'Previous',
            },
            elementsConfig: [],
            css_config: {
              ...Constants.defaultUiNestedButtonCssConfig,
              backGroundColor: 'var(--palette-color-2)',
              color: 'var(--palette-color-0)',
              borderWidth: 1,
              borderColor: 'var(--border-color-1)',
              borderStyle: 'solid',
              verticalAlignment: 'center',
              marginRight: 10,
              marginTop: 15,
            },
          },
        },

        nextButton: {
          name: WidgetNames.BUTTON,
          widgetConfigDisplayName: 'Next Button Styling',
          attributes: {
            widgetProperties: {
              textContent: 'Next',
            },
            elementsConfig: [],
            css_config: {
              ...Constants.defaultUiNestedButtonCssConfig,
              backGroundColor: 'var(--palette-color-2)',
              color: 'var(--palette-color-0)',
              borderWidth: 1,
              borderColor: 'var(--border-color-1)',
              borderStyle: 'solid',
              verticalAlignment: 'center',
              marginLeft: 10,
              marginTop: 15,
            },
          },
        },
      },
      attributes: {
        widgetProperties: {
          textContent: '',
          beneficiaryType: type,
          name: 'Full Name',
          firstName: 'First Name',
          lastName: 'Last Name',
          bankName: 'Bank Name',
          accountNumber: 'Account Number',
          iban: 'IBAN',
          aba: 'ABA Routing Number',
          bic: 'SWIFT/BIC Code',
          'rtn-canada': 'RTN',
          'sort-code': 'Sort Code',
          'bsb-code': 'BSB Number',
          ifsc: 'IFSC  Code',
          'bank-code': 'Bank Code',
          'branch-code': 'Branch Code',
          cnaps: 'CNAPS',
          clabe: 'CLABE',
          sortCodeLabel: 'Sort Code',
          buttonContent: 'Proceed',
          inputFieldSettings: {
            css_config: defaultInputCssConfig,
          },
        },
        elementsConfig: [],
        css_config: Constants.defaultBaseCssConfig,
      },
    },
    gridPosition: {
      x: 0,
      y: 9,
      rows: 10,
      cols: 15,
      dragEnabled: true,
      resizeEnabled: true,
      ...override?.gridPosition,
    },
    widgetIconPath: '',
  };
  return addIntlBeneficiaryCredentialsWidget;
}

export const beneficiarySelectionWidgetConfig: WorkflowWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: cryptoRandomUUID(),
  name: WidgetNames.BENEFICIARY_SELECTION_WIDGET,
  widgetConfiguration: {
    widgetDisplayName: WidgetTitles.BENEFICIARY_SELECTION_WIDGET,
    widgetConfigDisplayName: WidgetTitles.BENEFICIARY_SELECTION_WIDGET,
    isRemovable: true,
    attributes: {
      widgetProperties: {
        textContent: '',
        icon: 'search',
        inputFieldSettings: {
          isIconAllowed: true,
          label: 'Search',
          isAllowed: true,
          css_config: {
            iconSize: 24,
            iconSizeUnit: 'px',
          } as InputCssConfig,
        },
      },
      elementsConfig: [],
      css_config: {
        ...Constants.defaultBaseCssConfig,
        display: 'flex',
        borderColor: 'var(--border-color-1)',
        borderRadiusBottomRight: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusTopLeft: 5,
        borderRadiusUnit: 'px',
        borderStyle: 'solid',
        borderWidth: '1',
        paddingTop: 24,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 24,
        backGroundColor: '#fff',
        color: 'var(--typography-color-0)',
        isTextBold: true,
        fontSize: 16,
      },
      // Widget specific below
      titleTemplate: '',
      pageSize: 5,
      pagingEnabled: true,
      itemConfig: {
        itemTemplate: beneficiarySelectionTemplate,
      },
      iconConfig: {
        leftIcon: 'user-icon-beneficiary',
        rightIcon: '',
      },
    },
  },
  gridPosition: {
    x: 0,
    y: 2,
    minItemCols: 15,
    minItemRows: 15,
    resizeEnabled: true,
    dragEnabled: true,
    cols: 15,
    rows: 18,
  },
  widgetIconPath: 'generic-widget',
};

export const confirmBeneficiaryDetailsWidget: WorkflowWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: WidgetNames.CONFIRM_BENEFICIARY_DETAILS,
  name: WidgetNames.CONFIRM_BENEFICIARY_DETAILS,
  widgetConfiguration: {
    isRemovable: false,
    widgetDisplayName: '',
    widgetConfigDisplayName: WidgetTitles.CONFIRM_BENEFICIARY_DETAILS,
    attributes: {
      widgetProperties: { textContent: '' },
      elementsConfig: [],
      css_config: {
        ...Constants.defaultBaseCssConfig,
        backGroundColor: 'var(--palette-color-3)',
        color: 'var(--palette-color-6)',
        textAlignment: 'center',
        paddingTop: '11',
        paddingLeft: '11',
        paddingRight: '11',
        paddingBottom: '11',
        borderRadiusTopLeft: '9',
        borderRadiusBottomLeft: '9',
        borderRadiusTopRight: '9',
        borderRadiusBottomRight: '9',
        lineHeight: '24',
        fontWeight: 400,
        fontSize: '16',
      },
    },
  },
  gridPosition: {
    x: 0,
    y: 8,
    rows: 4,
    cols: 15,
    dragEnabled: true,
    resizeEnabled: true,
  },
  widgetIconPath: '',
};

export const quickLinksWidgetConfig: WorkflowWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: WidgetNames.QUICK_LINKS_WIDGET,
  name: WidgetNames.QUICK_LINKS_WIDGET,
  widgetConfiguration: {
    isRemovable: false,
    widgetDisplayName: '',
    widgetConfigDisplayName: WidgetTitles.QUICK_LINKS_WIDGET,
    attributes: {
      widgetProperties: { textContent: '' },
      elementsConfig: [],
      css_config: {
        ...Constants.defaultBaseCssConfig,
      },
    },
  },
  gridPosition: {
    x: 0,
    y: 0,
    rows: 1,
    cols: 5,
    dragEnabled: true,
    resizeEnabled: true,
  },
  widgetIconPath: '',
};

export const exchangeCurrencyWidgetConfig: WorkflowWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: cryptoRandomUUID(),
  name: WidgetNames.EXCHANGE_CURRENCY_WIDGET,
  widgetConfiguration: {
    widgetConfigDisplayName: WidgetTitles.EXCHANGE_CURRENCY_WIDGET,
    widgetDisplayName: WidgetTitles.EXCHANGE_CURRENCY_WIDGET,
    isRemovable: true,
    attributes: {
      widgetProperties: {
        textContent: '',
        contextPage: 'Buy',
        inputFieldSettings: {
          isIconAllowed: false,
          sellAccountLabel: 'Sell',
          buyAccountLabel: 'Buy',
          amountInputLabel: 'Amount',
          showFlags: true,
          css_config: defaultInputCssConfig,
        },
      },
      elementsConfig: [],
      field_attributes: [
        {
          name: 'sellAccountId',
          decision_config: { required: true },
          label: 'Sell',
          type: 'dropdown',
          options: [],
          additional_config: {},
        },
        {
          name: 'amount',
          decision_config: { required: true },
          label: 'Amount',
          type: 'text',
          options: [],
          additional_config: {},
        },
        {
          name: 'buyAccountId',
          decision_config: { required: true },
          label: 'Buy',
          type: 'dropdown',
          options: [],
          additional_config: {},
        },
      ],
      css_config: {
        ...Constants.defaultBaseCssConfig,
        paddingTop: 8,
        paddingLeft: 8,
        paddingRight: 8,
        paddingBottom: 8,
        borderRadiusTopLeft: 18,
        borderRadiusBottomLeft: 18,
        borderRadiusTopRight: 18,
        borderRadiusBottomRight: 18,
        borderWidth: 1,
        borderColor: '#EBEBEB',
        alertColor: '#2C70C9',
      },
    },
  },
  gridPosition: {
    x: 0,
    y: 1,
    cols: 15,
    rows: 15,
    minItemRows: 15,
    dragEnabled: true,
    resizeEnabled: true,
  },
  widgetIconPath: 'generic-widget',
};

export const addCurrencyAccountWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: WidgetNames.ADD_CURRENCY_ACCOUNT_WIDGET,
  name: WidgetNames.ADD_CURRENCY_ACCOUNT_WIDGET,
  widgetConfiguration: {
    widgetDisplayName: WidgetTitles.ADD_CURRENCY_ACCOUNT_WIDGET,
    widgetConfigDisplayName: WidgetTitles.ADD_CURRENCY_ACCOUNT_WIDGET,
    attributes: {
      elementsConfig: [],
      name: WidgetNames.ADD_CURRENCY_ACCOUNT_WIDGET,
      label: WidgetTitles.ADD_CURRENCY_ACCOUNT_WIDGET,
      zone: '',
      role: '',
      pageUrl: '',
      css_config: {
        ...Constants.defaultBaseCssConfig,
        fontSize: 16,
        textFont: 'Mulish',
        textAlignment: 'space-between',
        borderRadiusTopLeft: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusBottomRight: 5,
        borderWidth: 0,
        borderColor: 'var(--border-color-1)',
        borderStyle: 'solid',
      },
      widgetProperties: {
        textContent: currencyItemTemplate,
        icon: 'search',
        inputFieldSettings: {
          isAllowed: true,
          isIconAllowed: true,
          label: 'Search for Currencies',
          css_config: {
            iconSize: 24,
            iconSizeUnit: 'px',
          } as InputCssConfig,
        },
        allowedCurrencies: [],
      },
    },
    isRemovable: true,
  },
  gridPosition: {
    x: 0,
    y: 0,
    minItemCols: 1,
    minItemRows: 1,
    rows: 15,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const quoteExpirationAttributes = {
  name: 'quoteExpiration',
  decision_config: { required: false },
  label: 'Quote Expiration',
  type: 'number',
  options: [],
  additional_config: {
    min: 0,
    max: 60,
  },
  value: 35,
};

export const exchangeQuoteWidgetConfig: WorkflowWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: WidgetNames.EXCHANGE_QUOTE_WIDGET,
  name: WidgetNames.EXCHANGE_QUOTE_WIDGET,
  widgetConfiguration: {
    isRemovable: true,
    widgetDisplayName: WidgetTitles.EXCHANGE_QUOTE_WIDGET,
    widgetConfigDisplayName: WidgetTitles.EXCHANGE_QUOTE_WIDGET,
    attributes: {
      widgetProperties: {
        textContent: exchangeQuoteTemplate,
      },
      elementsConfig: [],
      css_config: {
        ...Constants.defaultBaseCssConfig,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 10,
        paddingBottom: 10,
        shadowBlur: 0,
        shadowSpread: 0,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        backGroundColor: 'var(--palette-color-5)',
        fontSize: '16',
      },
      field_attributes: [quoteExpirationAttributes],
    },
  },
  widgetIconPath: 'generic-widget',
};

export const exchangeQuoteSummaryConfig: WorkflowWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: WidgetNames.EXCHANGE_QUOTE_SUMMARY,
  name: WidgetNames.EXCHANGE_QUOTE_SUMMARY,
  widgetConfiguration: {
    isRemovable: true,
    widgetDisplayName: WidgetTitles.EXCHANGE_QUOTE_SUMMARY,
    widgetConfigDisplayName: WidgetTitles.EXCHANGE_QUOTE_SUMMARY,
    attributes: {
      widgetProperties: {
        textContent: exchangeSummaryTemplate,
      },
      elementsConfig: [],
      css_config: {
        ...Constants.defaultBaseCssConfig,
        backGroundColor: 'var(--palette-color-4)',
        paddingUnit: 'px',
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 10,
        paddingBottom: 10,
        borderWidth: '0',
        borderStyle: 'solid',
      },
      field_attributes: [],
    },
  },
  gridPosition: {
    x: 0,
    y: 14,
    rows: 9,
    cols: 15,
    dragEnabled: true,
    resizeEnabled: true,
  },
  widgetIconPath: '',
};

export const inputSettings = {
  isAllowed: true,
  label: 'Search for Accounts',
  type: 'search',
  css_config: {
    backgroundColor: 'transparent',
    inactiveBorderColor: 'var(--border-color-2)',
    inactiveBackgroundColor: '',
    borderRadiusTopLeft: 5,
    borderRadiusTopRight: 5,
    borderRadiusBottomLeft: 5,
    borderRadiusBottomRight: 5,
    borderWidth: 1,
    borderColor: 'var(--border-color-1)',
    inputTextColor: 'var(--typography-color-0)',
    borderStyleUnit: 'px',
    inputHeight: 44,
    inputWidth: 100,
    iconSize: 24,
    iconSizeUnit: 'px',
  },
} as InputFieldSettings;

export const intlCurrencyCountryWidgetConfig: WorkflowWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: WidgetNames.INTERNATIONAL_CURRENCY_COUNTRY,
  name: WidgetNames.INTERNATIONAL_CURRENCY_COUNTRY,
  widgetConfiguration: {
    widgetDisplayName: 'Intl. Currency & Country',
    widgetConfigDisplayName: WidgetTitles.INTERNATIONAL_CURRENCY_COUNTRY,
    nestedWidgets: {
      continueButton: {
        name: WidgetNames.BUTTON,
        widgetConfigDisplayName: 'Continue Button Styling',
        attributes: {
          widgetProperties: {
            textContent: 'Continue',
          },
          elementsConfig: [],
          css_config: {
            ...Constants.defaultUiNestedButtonCssConfig,
          },
        },
      },
    },
    attributes: {
      widgetProperties: {
        textContent: '',
        showNestedWidget: true,
        inputFieldSettings: {
          isIconAllowed: false,
          currencyLabel: 'Currency',
          countryLabel: 'Country',
          css_config: defaultInputCssConfig,
        },
      },

      field_attributes: [
        {
          name: 'currency',
          decision_config: { required: true },
          label: 'Currency',
          type: 'dropdown',
          options: [],
          additional_config: {},
        },
        {
          name: 'country',
          decision_config: { required: true },
          label: 'Country',
          type: 'dropdown',
          options: [],
          additional_config: {},
        },
      ],
      elementsConfig: [],
      name: WidgetNames.INTERNATIONAL_CURRENCY_COUNTRY,
      label: WidgetTitles.INTERNATIONAL_CURRENCY_COUNTRY,
      css_config: {
        ...Constants.defaultBaseCssConfig,
        fontSize: 16,
        textFont: 'Mulish',
        textAlignment: 'space-between',
        borderRadiusTopLeft: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusBottomRight: 5,
        paddingTop: 1,
        paddingLeft: 1,
        paddingRight: 1,
        paddingBottom: 1,
        borderWidth: 0,
        borderColor: 'var(--border-color-1)',
        borderStyle: 'solid',
      },
    },
    isRemovable: true,
  },
  gridPosition: {
    x: 0,
    y: 0,
    minItemCols: 1,
    minItemRows: 1,
    rows: 11,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const BackButtonWidgetBuilderCssConfig = {
  color: 'var(--palette-color-3)',
  backGroundColor: 'var(--palette-color-6)',
  borderWidth: '1',
  borderColor: 'var(--palette-color-3)',
  fontSize: '14',
  borderRadiusTopLeft: '6',
  borderRadiusTopRight: '6',
  borderRadiusBottomLeft: '6',
  borderRadiusBottomRight: '6',
  marginBottom: '12',
  marginTop: '12',
  paddingTop: '11',
  paddingBottom: '11',
  paddingRight: '11',
  paddingLeft: '11',
  textAlignment: 'center',
  justifyContent: 'center',
  textFont: 'var(--font-family)',
};
export const bulkPaymentAccountsListingWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: WidgetNames.BULK__PAYMENT_ACCOUNTS_LISTING_WIDGET,
  name: WidgetNames.BULK__PAYMENT_ACCOUNTS_LISTING_WIDGET,
  widgetConfiguration: {
    widgetConfigDisplayName: 'Accounts Listing',
    widgetDisplayName: WidgetTitles.LANDING_ACCOUNTS_LISTING,
    attributes: {
      navigationOption: 'use-as-navigation',
      role: DummyRole.EMPTY_ROLE,
      zone: APP_ZONES.PAYMENT,
      pageUrl: 'bulk-payment-upload-download',
      elementsConfig: [],
      widgetProperties: {
        textContent: bulkPaymentAccountListingTemplate,
        inputFieldSettings: {
          isAllowed: true,
          isIconAllowed: true,
          css_config: {
            iconSize: 24,
            iconSizeUnit: 'px',
          } as InputCssConfig,
          label: 'Search for Accounts',
        },
        icon: 'search',
      },
      css_config: Constants.defaultBaseCssConfig,
      contextSwitch: false,
      pagingEnabled: true,
    },
    isRemovable: true,
  },
  gridPosition: {
    dragEnabled: true,
    resizeEnabled: true,
    x: 0,
    y: 3,
    rows: 25,
    cols: 15,
  },
  widgetIconPath: '',
} as WorkflowWidgetConfig;
