import { totalBalanceTemplate } from '@finxone-platform/shared/default-templates';
import {
  Constants,
  GridPosition,
  WidgetNames,
  WidgetTitles,
  WorkflowWidgetConfig,
} from '@finxone-platform/shared/sys-config-types';
import { ProfilePictureWidgetBuilder, TextWidgetBuilder } from '../../../../widgets/example-builder';
import { generateMultiIconQuickLinkBuilderProfile } from '../utils/common-utils';

export const profileLandingPageWidgets = [
  new ProfilePictureWidgetBuilder().gridPosition({ y: 2, rows: 3 } as GridPosition).build(),
  {
    widgetTypeConfig: 'new',
    uniqueId: WidgetNames.NOTIFICATION_BELL_WIDGET,
    name: WidgetNames.NOTIFICATION_BELL_WIDGET,
    widgetConfiguration: {
      isRemovable: true,
      widgetDisplayName: '',
      widgetConfigDisplayName: WidgetTitles.NOTIFICATION_BELL_WIDGET,
      attributes: {
        widgetProperties: {
          textContent: '',
        },
        elementsConfig: [],
        css_config: {
          ...Constants.defaultBaseCssConfig,
          paddingTop: 8,
          paddingLeft: 8,
          paddingRight: 8,
          paddingBottom: 8,
          borderRadiusTopLeft: 18,
          borderRadiusBottomLeft: 18,
          borderRadiusTopRight: 18,
          borderRadiusBottomRight: 18,
          borderWidth: 1,
          borderColor: 'var(--border-color-1)',
          alertColor: 'var(--alerts-color-1)',
        },
      },
    },
    gridPosition: {
      x: 12,
      y: 2,
      rows: 2,
      cols: 2,
      dragEnabled: true,
      resizeEnabled: true,
    },
    widgetIconPath: '',
  } as WorkflowWidgetConfig,
  new TextWidgetBuilder()
    .text('<div class="w-100"><p>{{ profile.fullName }}</p></div>')
    .configDisplayName('Text Widget: User Name')
    .gridPosition({ y: 7, rows: 1 } as GridPosition)
    .cssConfig({
      color: 'var(--typography-color-0)',
      textFont: 'var(--font-family)',
      display: 'flex',
      verticalAlignment: 'start',
      textAlignment: 'left',
    })
    .isRemovable(false)
    .build(),
  new TextWidgetBuilder()
    .text('<div class="w-100"><p>{{ profile.email }}</p></div>')
    .configDisplayName('Text Widget: User Email')
    .cssConfig({
      color: 'var(--typography-color-1)',
      textFont: 'var(--font-family)',
      display: 'flex',
      verticalAlignment: 'start',
      textAlignment: 'left',
    })
    .gridPosition({ y: 8, rows: 1 } as GridPosition)
    .isRemovable(false)
    .build(),
  new TextWidgetBuilder()
    .text(totalBalanceTemplate)
    .configDisplayName('Text Widget: Total Balance')
    .cssConfig({
      backGroundColor: 'var(--palette-color-0)',
      color: 'var(--palette-color-6)',
      textFont: 'var(--font-family)',
      textAlignment: 'left',
      justifyContent: 'flex-start',
      borderRadiusTopLeft: 5,
      borderRadiusTopRight: 5,
      borderRadiusBottomLeft: 5,
      borderRadiusBottomRight: 5,
      paddingTop: 15,
      paddingBottom: 15,
      paddingLeft: 11,
      paddingRight: 11,
      fontSize: 30,
    })
    .gridPosition({ y: 10, rows: 5 } as GridPosition)
    .build(),
  generateMultiIconQuickLinkBuilderProfile(
    'user-edit',
    'arrow-right',
    'User Profile',
    'user-profile-summary',
    'Multi Icon Quick Link: User Profile',
    16,
  ),
  generateMultiIconQuickLinkBuilderProfile(
    'setting-gear',
    'arrow-right',
    'Settings',
    'settings',
    'Multi Icon Quick Link: Settings',
    19,
  ),
  generateMultiIconQuickLinkBuilderProfile(
    'key-icon',
    'arrow-right',
    'Privacy Policy',
    'privacy-policy',
    'Multi Icon Quick Link: Privacy Policy',
    22,
  ),
];
