import { APP_ZONES, ButtonActionType, GridPosition } from '@finxone-platform/shared/sys-config-types';
import { BackButtonWidgetBuilder } from '../../../../widgets';
import { commonCTAButton, commonTextWidget } from '../../../common/common-widgets';

export const archiveTripConfirmationWidgets = [
  commonTextWidget({
    textContent: `Archive {{ revenirFormState.archivedTripDetails.trip_name }}?`,
    cssConfig: {
      textFont: 'var(--font-family)',
      textAlignment: 'center',
      fontSize: 24,
      isTextBold: true,
      color: 'var(--typography-color-0)',
    },
    gridPosition: {
      y: 8,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 3,
      cols: 15,
    },
  }),

  commonTextWidget({
    textContent: `Do you want to move your {{ revenirFormState.archivedTripDetails.trip_name }} trip to the Trip archives?`,
    cssConfig: {
      textFont: 'var(--font-family)',
      fontSize: 16,
      color: 'var(--typography-color-0)',
      paddingLeft: 20,
      paddingRight: 20,
    },
    gridPosition: {
      x: 0,
      y: 11,
      rows: 3,
      cols: 15,
      resizeEnabled: true,
      dragEnabled: true,
    },
  }),

  commonCTAButton({
    zoneToNavigate: APP_ZONES.REVENIR_ZONE,
    urlToNavigate: 'exit-point',
    textContent: 'Archive',
    buttonActionType: ButtonActionType.CONFIRM_ARCHIVE_TRIP,
    formKey: '',
    gridPosition: { y: 15, rows: 2, cols: 15 } as GridPosition,
    cssConfig: {
      fontSize: 16,
      marginLeft: 20,
      marginRight: 20,
      marginBottom: 5,
      borderRadiusTopLeft: 8,
      borderRadiusTopRight: 8,
      borderRadiusBottomLeft: 8,
      borderRadiusBottomRight: 8,
      paddingTop: 8,
      paddingRight: 12,
      paddingLeft: 8,
      paddingBottom: 12,
      textFont: 'var(--font-family)',
    },
  }),

  new BackButtonWidgetBuilder()
    .defaultBackButton()
    .configDisplayName('Back Navigation Button')
    .text('Cancel')
    .cssConfig({
      textAlign: 'center',
      verticalAlignment: 'center',
      marginTop: 5,
      marginBottom: 0,
      marginLeft: 20,
      marginRight: 20,
      fontSize: 16,
      color: 'var(--palette-color-1)',
      backGroundColor: 'var(--palette-color-6)',
      borderWidth: 1,
      borderColor: 'var(--palette-color-3)',
      borderRadiusTopLeft: 8,
      borderRadiusTopRight: 8,
      borderRadiusBottomLeft: 8,
      borderRadiusBottomRight: 8,
      paddingTop: 8,
      paddingRight: 12,
      paddingLeft: 8,
      paddingBottom: 12,
      textFont: 'var(--font-family)',
    })
    .gridPosition({
      y: 17,
      x: 0,
      rows: 2,
      cols: 15,
      resizeEnabled: true,
      dragEnabled: true,
    })
    .build(),
];
