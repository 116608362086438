import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { FormActionTypeEnum } from '@finxone-platform/form-action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { UpdateFormDataActionWithId } from '../../../actions/form-submission.action';
import { BeneficiaryState } from '../../../state/beneficiary.state';
import { FormActionState } from '../../../state/form-submision.state';
import { formatNameAsUrl } from '../../zone-url.utils';
import { redirectToPage } from '../cta-button-actions.utils';

/**
 * Sets beneficiary data and redirects to the edit beneficiary page.
 *
 * This function retrieves the current form data and beneficiary list from the store.
 * If a matching beneficiary is found, it updates the form data with the beneficiary's
 * details and redirects to the appropriate edit page based on the account type.
 * If no matching beneficiary is found, it shows an error alert and redirects to the
 * beneficiary list page.
 *
 * @param widgetProperties - The properties of the widget, including navigation URLs.
 * @param injector - The injector used to retrieve necessary services.
 */
export function setBeneficiaryDataAndRedirectToEditBeneficiaryPage(
  widgetProperties: BaseWidgetProperties,
  injector: Injector,
) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  const alertHandlerService = injector.get(AlertHandlerService);

  const existingFormData = store.selectSnapshot(FormActionState.getFormActionState)?.response?.formData;
  const beneficiariesList = store.selectSnapshot(BeneficiaryState.getBeneficiary).items;

  if (!existingFormData?.bid || !beneficiariesList) {
    alertHandlerService.showAlertFn('error', 'Beneficiary data not found, please try again');
    widgetProperties['urlToNavigate'] = 'beneficiary-list';
    redirectToPage(router, widgetProperties, injector);
    return;
  }

  const selected = beneficiariesList.find((v) => v.id === existingFormData.bid);
  if (!selected) return;

  const addressInfo = { ...selected.address };
  const destinationIdentifierInfo = {
    ...selected.destinationIdentifier,
    bankId: selected.destinationIdentifier['id'],
  };
  delete destinationIdentifierInfo['id'];

  const formActionData: Record<string, any> = {
    ...selected,
    ...addressInfo,
    ...destinationIdentifierInfo,
    destinationIdentifier: {
      ...destinationIdentifierInfo,
      id: destinationIdentifierInfo['bankId'],
    },
    countryIso2: existingFormData.beneficiaryCountry,
  };
  delete formActionData['address'];
  delete formActionData['customerId'];

  store.dispatch(
    new UpdateFormDataActionWithId(
      formActionData as Record<string, string | null | undefined>,
      FormActionTypeEnum.INTL_BENEFICIARY_ADD,
    ),
  );

  const newWidgetProps = { ...widgetProperties };
  const accountTypeUrl = formatNameAsUrl(selected.accountType);

  if (!widgetProperties['urlToNavigate'].includes(accountTypeUrl)) {
    newWidgetProps['urlToNavigate'] += `-${accountTypeUrl}`;
  }

  redirectToPage(router, newWidgetProps, injector);
}
