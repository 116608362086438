import { GridPosition } from '@finxone-platform/shared/sys-config-types';
import { generateManageUserStep02WidgetsConfig } from '../../../widgets/admin-roles/user-managment.widgets';
import {
  generateCommonProgressBarWidget,
  generateHeaderTextWidget,
  generateSubHeaderLeftTextWidget,
  generateSubHeaderRightTextWidget,
  getBackButtonWidget,
  getQuickLinkWidget,
  lineWidgetBuilderConfig,
} from '../common-utils';

export const addUsersStepTwoWidgets = [
  generateHeaderTextWidget(),

  lineWidgetBuilderConfig,

  generateSubHeaderRightTextWidget('Login Details'),

  generateSubHeaderLeftTextWidget('Step 2/3'),

  generateCommonProgressBarWidget('65'),

  generateManageUserStep02WidgetsConfig(),

  getBackButtonWidget({
    position: { y: 15, x: 0, rows: 1, cols: 3 } as GridPosition,
    styles: {
      fontSize: 16,
      textAlignment: 'center',
      color: 'var(--palette-color-3)',
      backGroundColor: 'var(--palette-color-6)',
      borderWidth: 1,
      borderColor: 'var(--palette-color-3)',
    },
  }),
  getQuickLinkWidget({
    text: 'Next',
    position: { y: 15, x: 4, rows: 1, cols: 4 } as GridPosition,
    pageUrl: 'add-user-step-3',
    displayName: 'Quick Link: Next',
  }),
];
