import { Constants, GridPosition, WorkflowWidgetConfig } from '@finxone-platform/shared/sys-config-types';
import { BackButtonWidgetBuilder, SpacerWidgetBuilder, TextWidgetBuilder } from '../../../widgets';

const transactionDetailTemplate = `
  <div class="w-100">
    <div class="is-space-between w-100 mb-3">
      <div class="labelText textOnSurfaceMuted">Status</div>
      <div class="body1 text-right textOnSurface text-capitalize">
        {{{cards.cardManagement.currentCardTransactionDetail.status}}}
      </div>
    </div>
    
    {{#if cards.cardManagement.currentCardTransactionDetail.transactionInfo.originalAmount}}
    <div class="is-space-between w-100 mb-3">
      <div class="labelText textOnSurfaceMuted">Original trx amount</div>
      <div class="body1 text-right textOnSurface">
        {{ currencyPipe cards.cardManagement.currentCardTransactionDetail.transactionInfo.originalAmount 
          currencyCode=cards.cardManagement.currentCardTransactionDetail.transactionInfo.originalAmountCurrency 
          display='symbol-narrow' }}
      </div>
    </div>
    {{/if}}

    {{#if (eq cards.cardManagement.currentCardTransactionDetail.type 'payment')}}
      <div class="is-space-between w-100 mb-3">
        <div class="labelText textOnSurfaceMuted">Converted Amount</div>
        <div class="body1 text-right textOnSurface">
          {{ currencyPipe cards.cardManagement.currentCardTransactionDetail.transactionInfo.amount 
            currencyCode=cards.cardManagement.currentCardTransactionDetail.transactionInfo.currency 
            display='symbol-narrow' }}
        </div>
      </div>
    {{/if}}

    {{#if cards.cardManagement.currentCardTransactionDetail.feeInfo.feeAmount}}
    <div class="is-space-between w-100 mb-3">
      <div class="labelText textOnSurfaceMuted">Fees</div>
      <div class="body1 text-right textOnSurface">
        {{#if cards.cardManagement.currentCardTransactionDetail.feeInfo.feeCurrency}}
          {{ currencyPipe cards.cardManagement.currentCardTransactionDetail.feeInfo.feeAmount 
            currencyCode=cards.cardManagement.currentCardTransactionDetail.feeInfo.feeCurrency 
            display='symbol-narrow' }}
        {{else}}
          {{ currencyPipe cards.cardManagement.currentCardTransactionDetail.feeInfo.feeAmount 
            currencyCode=cards.cardManagement.currentCardTransactionDetail.transactionInfo.originalAmountCurrency display='symbol-narrow' }}
        {{/if}}
      </div>
    </div>
    {{/if}}

    {{#if (eq cards.cardManagement.currentCardTransactionDetail.type 'payment')}}
      <div class="is-space-between w-100 mb-3">
        <div class="labelText textOnSurfaceMuted">ECB markup</div>
        <div class="body1 text-right textOnSurface">
          {{#if cards.cardManagement.currentCardTransactionDetail.feeInfo.ecbMarkup}}
            {{{cards.cardManagement.currentCardTransactionDetail.feeInfo.ecbMarkup}}}
          {{else}} 
            -- 
          {{/if}}
        </div>
      </div>
    {{/if}}

    <div class="is-space-between w-100">
      <div class="labelText textOnSurfaceMuted">Details</div>
      <div class="body1 text-right textOnSurface">
      {{#if cards.cardManagement.currentCardTransactionDetail.merchantInfo.city}}
      {{{cards.cardManagement.currentCardTransactionDetail.merchantInfo.city}}}
      {{#if cards.cardManagement.currentCardTransactionDetail.merchantInfo.country}}, {{/if}}
       {{/if}}
      {{#if cards.cardManagement.currentCardTransactionDetail.merchantInfo.country}}
      {{{cards.cardManagement.currentCardTransactionDetail.merchantInfo.country}}}
      {{/if}}  
      </div>
    </div>
 
  </div>
`;

const profileDetailTemplate = `<div class="w-100">
      <div class="is-space-between  d-flex w-100 mb-3">
        <div class="labelText textOnSurfaceMuted">Full Name</div>
        <div class="body1 text-right textOnSurface text-capitalize">
        {{{cards.cardManagement.currentCardTransactionDetail.merchantInfo.name}}}
        </div>
      </div>
      <div class="is-space-between w-100 mb-3">
        <div class="labelText textOnSurfaceMuted">Type</div>
        <div class="body1 text-right textOnSurface text-capitalize">
        {{{cards.cardManagement.currentCardTransactionDetail.type}}}
        </div>
      </div>
       <div class="is-space-between w-100">
        <div class="labelText textOnSurfaceMuted">Posted Date</div>
        <div class="body1 text-right textOnSurface">
         {{ datePipe cards.cardManagement.currentCardTransactionDetail.date format='d MMM HH:mm'}}
        </div>
      </div>
    </div>`;

export const cardTransactionElaboratedViewWidgets: WorkflowWidgetConfig[] = [
  new TextWidgetBuilder()
    .configDisplayName('Text Widget: Payment Details')
    .setWidgetProperties({ textStyleClass: 'h6', textContent: 'Payment Details' })
    .cssConfig({
      color: 'var(--color-on-background)',
      textAlignment: 'center',
      textFont: 'var(--font-family)',
    })
    .gridPosition({
      x: 0,
      y: 0,
      rows: 2,
      cols: 15,
    } as GridPosition)
    .build(),

  new SpacerWidgetBuilder().setHeight({ height: 30, unit: 'px' }).build(),

  new TextWidgetBuilder()
    .configDisplayName('Text Widget: Icon & Amount')
    .setWidgetProperties({
      textStyleClass: 'h4',
      textContent: `
      <div>
      <span class="svg-background d-inline-block ">
      {{#if (eq cards.cardManagement.currentCardTransactionDetail.type "atm-withdrawal")}}
        {{{ svgIcon 'money-simple-from-bracket-p' '32px' '32px' }}}
      {{/if}}
      {{#if (eq cards.cardManagement.currentCardTransactionDetail.type "chargeback")}}
        {{{ svgIcon 'refund-p' '32px' '32px' }}}
      {{/if}}
      {{#if (eq cards.cardManagement.currentCardTransactionDetail.type "payment")}}
        {{{ svgIcon 'shopping-bag-p' '32px' '32px' }}}
      {{/if}}
      {{#if (eq cards.cardManagement.currentCardTransactionDetail.type "refund")}}
        {{{ svgIcon 'refund-p' '32px' '32px' }}}
      {{/if}}
      {{#if (eq cards.cardManagement.currentCardTransactionDetail.type "money-transfer")}}
        {{{ svgIcon 'top-up-p' '32px' '32px' }}}
      {{/if}}
      </span>
      <div class="mt-4">{{currencyPipe cards.cardManagement.currentCardTransactionDetail.transactionInfo.amount currencyCode=cards.cardManagement.currentCardTransactionDetail.transactionInfo.currency display='symbol-narrow'}}</div>
      </div>
    `,
    })
    .cssConfig({
      textAlignment: 'center',
      textFont: 'var(--font-family)',
    })
    .gridPosition({
      x: 0,
      y: 2,
      rows: 3,
      cols: 15,
    } as GridPosition)
    .build(),

  new SpacerWidgetBuilder().setHeight({ height: 30, unit: 'px' }).build(),

  new TextWidgetBuilder()
    .configDisplayName('Text Widget: Profile Details')
    .setWidgetProperties({
      textStyleClass: 'body1',
      textContent: profileDetailTemplate,
    })
    .cssConfig({
      ...Constants.defaultBaseCssConfig,
      backGroundColor: 'var(--color-surface)',
      borderRadiusTopLeft: 8,
      borderRadiusBottomLeft: 8,
      borderRadiusTopRight: 8,
      borderRadiusBottomRight: 8,
      paddingTop: 24,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 24,
    })
    .gridPosition({
      x: 0,
      y: 6,
      rows: 5,
      cols: 15,
    } as GridPosition)
    .build(),

  new SpacerWidgetBuilder().setHeight({ height: 30, unit: 'px' }).build(),

  new TextWidgetBuilder()
    .configDisplayName('Text Widget: Transaction Details')
    .setWidgetProperties({
      textStyleClass: 'body1',
      textContent: transactionDetailTemplate,
    })
    .cssConfig({
      ...Constants.defaultBaseCssConfig,
      backGroundColor: 'var(--color-surface)',
      borderRadiusTopLeft: 8,
      borderRadiusBottomLeft: 8,
      borderRadiusTopRight: 8,
      borderRadiusBottomRight: 8,
      paddingTop: 24,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 24,
    })
    .gridPosition({
      x: 0,
      y: 11,
      rows: 7,
      cols: 15,
    } as GridPosition)
    .build(),

  new SpacerWidgetBuilder().setHeight({ height: 30, unit: 'px' }).build(),

  new BackButtonWidgetBuilder()
    .icon('')
    .configDisplayName('Close Button')
    .text('Close')
    .cssConfig({
      fontSize: 16,
      textAlignment: 'center',
      backGroundColor: 'var(--color-surface)',
      borderWidth: 1,
      borderColor: 'var(--color-primary)',
      color: 'var(--color-primary)',
      borderRadiusTopLeft: 6,
      borderRadiusBottomLeft: 6,
      borderRadiusTopRight: 6,
      borderRadiusBottomRight: 6,
    })
    .setGlobalStylingClass(true, 'secondaryButton full-width')
    .build(),
];
