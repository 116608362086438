export const paymentIntlNewBeneficiaryAddedTemplate = `<div class="w-100 h-100 p-2">
  <div class="d-flex">
    <div class="col-6-xs">
      <div class="text-label">Full Name</div>
      <div >{{intlBeneficiaryAdd.name}}</div>
    </div>
    <div class="ml-auto vertical-center">
      {{{ countryCodeToFlag intlBeneficiaryAdd.countryIso2 '40px' }}}
    </div>
  </div>
  <hr/>
  <div class="d-flex">
    <div class="col-6-xs">
      <div class="text-label">Currency</div>
      <div >{{ intlBeneficiaryAdd.currency }}</div>
    </div>
    <div class="col-6-xs">
      <div class="text-label">Country</div>
      <div >{{ intlBeneficiaryAdd.countryIso2 }}</div>
    </div>
  </div>
  <hr/>
  <div class="d-flex">
    {{#if intlBeneficiaryAdd.accountNumber}}
      <div class="col-6-xs">
        <div class="text-label">Account number</div>
        <div >{{intlBeneficiaryAdd.accountNumber}}</div>
      </div>
    {{/if}}
    {{#if intlBeneficiaryAdd.bankName}}
      <div class="col-6-xs">
        <div class="text-label">Bank Name</div>
        <div >{{ intlBeneficiaryAdd.bankName }}</div>
      </div>
    {{/if}}
  </div>
  <div class="d-flex">
    {{#if intlBeneficiaryAdd.iban}}
      <div class="d-flex w-100">
        <div class="col">
          <div class="text-label">IBAN</div>
          <div >{{intlBeneficiaryAdd.iban}}</div>
        </div>
      </div>
    {{/if}}
  </div>
</div>`;
