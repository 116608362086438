export const keycloakMessages = {
  // Basic action buttons used throughout the UI
  doLogIn: 'Login',
  doRegister: 'Register',
  doRegisterSecurityKey: 'Register', // Used for security key registration
  doCancel: 'Cancel',
  doSubmit: 'Submit',
  doBack: 'Back',
  doYes: 'Yes',
  doNo: 'No',
  doContinue: 'Continue',
  doIgnore: 'Ignore',
  doDecline: 'Decline',
  doForgotPassword: 'Forgot the password?',
  doClickHere: 'Click here',

  // Administrative actions
  doImpersonate: 'Impersonate', // Used by admins to impersonate users
  doTryAgain: 'Try again',
  doTryAnotherWay: 'Try Another Way',
  doConfirmDelete: 'Confirm deletion',

  // Account management messages
  errorDeletingAccount: 'Error happened while deleting account',
  deletingAccountForbidden: 'You do not have enough permissions to delete your own account, contact admin.',

  // Kerberos authentication related
  kerberosNotConfigured: 'Kerberos Not Configured',
  kerberosNotConfiguredTitle: 'Kerberos Not Configured',
  bypassKerberosDetail:
    'Either you are not logged in by Kerberos or your browser is not set up for Kerberos login. Please click continue to login in through other means',
  kerberosNotSetUp: 'Kerberos is not set up. You cannot login.',

  // Login and registration page titles
  registerTitle: 'Register',
  loginAccountTitle: 'Login',
  loginTitle: 'Login', // {0} is replaced with realm or client name
  loginTitleHtml: '{0}',
  impersonateTitle: '{0} Impersonate User',
  impersonateTitleHtml: '<strong>{0}</strong> Impersonate User',
  realmChoice: 'Realm',
  unknownUser: 'Unknown user',

  // Authentication setup and verification
  loginTotpTitle: 'Mobile Authenticator Setup',
  loginProfileTitle: 'Update Account Information',
  loginIdpReviewProfileTitle: 'Update Account Information',
  loginTimeout: 'Your login attempt timed out. Login will start from the beginning.',
  reauthenticate: 'Please re-authenticate to continue',
  authenticateStrong: 'Strong authentication required to continue',

  // OAuth/OIDC consent screen
  oauthGrantTitle: 'Grant Access to {0}',
  oauthGrantTitleHtml: '{0}',
  oauthGrantInformation: 'Make sure you trust {0} by learning how {0} will handle your data.',
  oauthGrantReview: 'You could review the',
  oauthGrantTos: 'terms of service.',
  oauthGrantPolicy: 'privacy policy.',

  // Error messages
  errorTitle: 'We are sorry...',
  errorTitleHtml: 'We are <strong>sorry</strong> ...',

  // Email related messages
  emailVerifyTitle: 'Email verification',
  emailForgotTitle: 'Forgot Your Password?',
  updateEmailTitle: 'Update email',
  emailUpdateConfirmationSentTitle: 'Confirmation email sent',
  emailUpdateConfirmationSent:
    'A confirmation email has been sent to {0}. You must follow the instructions of the former to complete the email update.',
  emailUpdatedTitle: 'Email updated',
  emailUpdated: 'The account email has been successfully updated to {0}.',

  // Status codes
  codeSuccessTitle: 'Success code',
  codeErrorTitle: 'Error code: {0}',

  // Browser requirements
  displayUnsupported: 'Requested display type unsupported',
  browserRequired: 'Browser required to login',
  browserContinue: 'Browser required to complete login',
  browserContinuePrompt: 'Open browser and continue login? [y/n]:',
  browserContinueAnswer: 'y',

  // Transport types
  usb: 'USB',
  nfc: 'NFC',
  bluetooth: 'Bluetooth',
  internal: 'Internal',
  unknown: 'Unknown',

  // Terms and conditions
  termsTitle: 'Terms and Conditions',
  termsPlainText: 'Terms and conditions to be defined.',
  termsAcceptanceRequired: 'You must agree to our terms and conditions.',
  acceptTerms: 'I agree to the terms and conditions',

  // Credential management
  deleteCredentialTitle: 'Delete {0}',
  deleteCredentialMessage: 'Do you want to delete {0}?',

  // Recaptcha messages
  recaptchaFailed: 'Invalid Recaptcha',
  recaptchaNotConfigured: 'Recaptcha is required, but not configured',
  consentDenied: 'Consent denied.',

  // Form fields and labels
  noAccount: 'New user?',
  username: 'Username',
  usernameOrEmail: 'Username or Email Address',
  firstName: 'First name',
  givenName: 'Given name',
  fullName: 'Full name',
  lastName: 'Last name',
  familyName: 'Family name',
  email: 'Email',
  password: 'Password',
  passwordConfirm: 'Confirm password',
  passwordNew: 'New Password',
  passwordNewConfirm: 'New Password confirmation',
  hidePassword: 'Hide password',
  showPassword: 'Show password',
  rememberMe: 'Remember me',
  authenticatorCode: 'One-time code',

  // Address fields
  address: 'Address',
  street: 'Street',
  locality: 'City or Locality',
  region: 'State, Province, or Region',
  postal_code: 'Zip or Postal code',
  country: 'Country',

  // User profile fields
  emailVerified: 'Email verified',
  website: 'Web page',
  phoneNumber: 'Phone number',
  phoneNumberVerified: 'Phone number verified',
  gender: 'Gender',
  birthday: 'Birthdate',
  zoneinfo: 'Time zone',
  gssDelegationCredential: 'GSS Delegation Credential',
  logoutOtherSessions: 'Sign out from other devices',

  // OAuth2/OIDC Scope consent texts - Used during authorization requests
  profileScopeConsentText: 'User profile',
  emailScopeConsentText: 'Email address',
  addressScopeConsentText: 'Address',
  phoneScopeConsentText: 'Phone number',
  offlineAccessScopeConsentText: 'Offline Access',
  samlRoleListScopeConsentText: 'My Roles',
  rolesScopeConsentText: 'User roles',
  organizationScopeConsentText: 'Organization',

  // Login page tooltips
  restartLoginTooltip: 'Restart login',

  // Time-based One-Time Password (TOTP) setup instructions
  loginTotpIntro: 'You need to set up a One Time Password generator to access this account',
  loginTotpStep1: 'Install one of the following applications on your mobile:',
  loginTotpStep2: 'Open the application and scan the barcode:',
  loginTotpStep3: 'Enter the one-time code provided by the application and click Submit to finish the setup.',
  loginTotpStep3DeviceName: 'Provide a Device Name to help you manage your OTP devices.',
  loginTotpManualStep2: 'Open the application and enter the key:',
  loginTotpManualStep3: 'Use the following configuration values if the application allows setting them:',
  loginTotpUnableToScan: 'Unable to scan?',
  loginTotpScanBarcode: 'Scan barcode?',

  // TOTP form fields
  loginCredential: 'Credential',
  loginOtpOneTime: 'One-time code',
  loginTotpType: 'Type',
  loginTotpAlgorithm: 'Algorithm',
  loginTotpDigits: 'Digits',
  loginTotpInterval: 'Interval',
  loginTotpCounter: 'Counter',
  loginTotpDeviceName: 'Device Name',

  // TOTP types
  'loginTotp.totp': 'Time-based',
  'loginTotp.hotp': 'Counter-based',

  // Supported authenticator apps
  totpAppFreeOTPName: 'FreeOTP',
  totpAppGoogleName: 'Google Authenticator',
  totpAppMicrosoftAuthenticatorName: 'Microsoft Authenticator',

  // Authenticator selection
  loginChooseAuthenticator: 'Select login method',

  // OAuth Device Authorization Grant messages
  oauthGrantRequest: 'Do you grant these access privileges?',
  inResource: 'in',
  oauth2DeviceVerificationTitle: 'Device Login',
  verifyOAuth2DeviceUserCode: 'Enter the code provided by your device and click Submit',
  oauth2DeviceInvalidUserCodeMessage: 'Invalid code, please try again.',
  oauth2DeviceExpiredUserCodeMessage:
    'The code has expired. Please go back to your device and try connecting again.',
  oauth2DeviceVerificationCompleteHeader: 'Device Login Successful',
  oauth2DeviceVerificationCompleteMessage: 'You may close this browser window and go back to your device.',
  oauth2DeviceVerificationFailedHeader: 'Device Login Failed',
  oauth2DeviceVerificationFailedMessage:
    'You may close this browser window and go back to your device and try connecting again.',
  oauth2DeviceConsentDeniedMessage: 'Consent denied for connecting the device.',
  oauth2DeviceAuthorizationGrantDisabledMessage:
    'Client is not allowed to initiate OAuth 2.0 Device Authorization Grant. The flow is disabled for the client.',

  // Email verification instructions
  emailVerifyInstruction1:
    'An email with instructions to verify your email address has been sent to your address {0}.',
  emailVerifyInstruction2: "Haven't received a verification code in your email?",
  emailVerifyInstruction3: 'to re-send the email.',

  // Identity Provider linking
  emailLinkIdpTitle: 'Link {0}',
  emailLinkIdp1:
    'An email with instructions to link {0} account {1} with your {2} account has been sent to you.',
  emailLinkIdp2: "Haven't received a verification code in your email?",
  emailLinkIdp3: 'to re-send the email.',
  emailLinkIdp4: 'If you already verified the email in different browser',
  emailLinkIdp5: 'to continue.',

  // Navigation
  backToLogin: '« Back to Login',

  // Password reset instructions
  emailInstruction:
    'Enter your username or email address and we will send you instructions on how to create a new password.',
  emailInstructionUsername:
    'Enter your username and we will send you instructions on how to create a new password.',

  // Application integration
  copyCodeInstruction: 'Please copy this code and paste it into your application:',

  // Page expiration
  pageExpiredTitle: 'Page has expired',
  pageExpiredMsg1: 'To restart the login process',
  pageExpiredMsg2: 'To continue the login process',

  // User information
  personalInfo: 'Personal Info:',

  // Role names and descriptions
  role_admin: 'Admin',
  role_realm_admin: 'Realm Admin',
  role_create_realm: 'Create realm',
  role_create_client: 'Create client',
  role_view_realm: 'View realm',
  role_view_users: 'View users',
  role_view_applications: 'View applications',
  role_view_clients: 'View clients',
  role_view_events: 'View events',
  role_view_identity_providers: 'View identity providers',
  role_manage_realm: 'Manage realm',
  role_manage_users: 'Manage users',
  role_manage_applications: 'Manage applications',
  role_manage_identity_providers: 'Manage identity providers',
  role_manage_clients: 'Manage clients',
  role_manage_events: 'Manage events',
  role_view_profile: 'View profile',
  role_manage_account: 'Manage account',
  role_manage_account_links: 'Manage account links',
  role_read_token: 'Read token',
  role_offline_access: 'Offline access',

  // Client application names
  client_account: 'Account',
  client_account_console: 'Account Console',
  client_security_admin_console: 'Security Admin Console',
  client_admin_cli: 'Admin CLI',
  client_realm_management: 'Realm Management',
  client_broker: 'Broker',

  // Form validation
  requiredFields: 'Required fields',

  // Authentication error messages
  invalidUserMessage: 'Invalid username or password.',
  invalidUsernameMessage: 'Invalid username.',
  invalidUsernameOrEmailMessage: 'Invalid username or email.',
  invalidPasswordMessage: 'Invalid password.',
  invalidEmailMessage: 'Invalid email address.',

  // Account status messages
  accountDisabledMessage: 'Account is disabled, contact your administrator.',
  accountTemporarilyDisabledMessage:
    'Account is temporarily disabled; contact your administrator or retry later.',
  sessionLimitExceeded: 'There are too many sessions',
  identityProviderLogoutFailure: 'SAML IdP Logout Failure',

  // Session and token expiration messages
  expiredCodeMessage: 'Login timeout. Please sign in again.',
  expiredActionMessage: 'Action expired. Please continue with login now.',
  expiredActionTokenNoSessionMessage: 'Action expired.',
  expiredActionTokenSessionExistsMessage: 'Action expired. Please start again.',

  // Required field validation messages
  missingFirstNameMessage: 'Please specify first name.',
  missingLastNameMessage: 'Please specify last name.',
  missingEmailMessage: 'Please specify email.',
  missingUsernameMessage: 'Please specify username.',
  missingPasswordMessage: 'Please specify password.',
  missingTotpMessage: 'Please specify authenticator code.',
  missingTotpDeviceNameMessage: 'Please specify device name.',
  notMatchPasswordMessage: "Passwords don't match.",

  // Generic form validation errors
  'error-invalid-value': 'Invalid value.',
  'error-invalid-blank': 'Please specify value.',
  'error-empty': 'Please specify value.',
  'error-invalid-length': 'Length must be between {1} and {2}.',
  'error-invalid-length-too-short': 'Minimal length is {1}.',
  'error-invalid-length-too-long': 'Maximal length is {2}.',
  'error-invalid-email': 'Invalid email address.',
  'error-invalid-number': 'Invalid number.',
  'error-number-out-of-range': 'Number must be between {1} and {2}.',
  'error-number-out-of-range-too-small': 'Number must have minimal value of {1}.',
  'error-number-out-of-range-too-big': 'Number must have maximal value of {2}.',
  'error-pattern-no-match': 'Invalid value.',
  'error-invalid-uri': 'Invalid URL.',
  'error-invalid-uri-scheme': 'Invalid URL scheme.',
  'error-invalid-uri-fragment': 'Invalid URL fragment.',
  'error-user-attribute-required': 'Please specify this field.',
  'error-invalid-date': 'Invalid date.',
  'error-user-attribute-read-only': 'This field is read only.',
  'error-username-invalid-character': 'Value contains invalid character.',
  'error-person-name-invalid-character': 'Value contains invalid character.',
  'error-reset-otp-missing-id': 'Please choose an OTP configuration.',

  // Password-specific validation
  invalidPasswordExistingMessage: 'Invalid existing password.',
  invalidPasswordBlacklistedMessage: 'Invalid password: password is blacklisted.',
  invalidPasswordConfirmMessage: "Password confirmation doesn't match.",
  invalidTotpMessage: 'Invalid authenticator code.',

  // Duplicate entry messages
  usernameExistsMessage: 'Username already exists.',
  emailExistsMessage: 'Email already exists.',

  // Federated identity messages
  federatedIdentityExistsMessage:
    'User with {0} {1} already exists. Please login to account management to link the account.',
  federatedIdentityUnavailableMessage:
    'User {0} authenticated with identity provider {1} does not exist. Please contact your administrator.',
  federatedIdentityUnmatchedEssentialClaimMessage:
    'The ID token issued by the identity provider does not match the configured essential claim. Please contact your administrator.',

  // Account linking messages
  confirmLinkIdpTitle: 'Account already exists',
  confirmOverrideIdpTitle: 'Broker link already exists',
  federatedIdentityConfirmLinkMessage: 'User with {0} {1} already exists. How do you want to continue?',
  federatedIdentityConfirmOverrideMessage:
    'You are trying to link your account {0} with the {1} account {2}. But your account is already linked with different {3} account {4}. Can you confirm if you want to replace the existing link with the new account?',
  federatedIdentityConfirmReauthenticateMessage: 'Authenticate to link your account with {0}',
  nestedFirstBrokerFlowMessage: 'The {0} user {1} is not linked to any known user.',
  confirmLinkIdpReviewProfile: 'Review profile',
  confirmLinkIdpContinue: 'Add to existing account',
  confirmOverrideIdpContinue: 'Yes, override link with current account',

  // Account setup requirement messages
  configureTotpMessage: 'You need to set up Mobile Authenticator to activate your account.',
  configureBackupCodesMessage: 'You need to set up Backup Codes to activate your account.',
  updateProfileMessage: 'You need to update your user profile to activate your account.',
  updatePasswordMessage: 'You need to change your password to activate your account.',
  updateEmailMessage: 'You need to update your email address to activate your account.',
  resetPasswordMessage: 'You need to change your password.',
  verifyEmailMessage: 'You need to verify your email address to activate your account.',
  linkIdpMessage: 'You need to verify your email address to link your account with {0}.',

  // Email notification messages
  emailSentMessage: 'You should receive an email shortly with further instructions.',
  emailSendErrorMessage: 'Failed to send email, please try again later.',

  // Account update confirmation messages
  accountUpdatedMessage: 'Your account has been updated.',
  accountPasswordUpdatedMessage: 'Your password has been updated.',

  // Delegation messages
  delegationCompleteHeader: 'Login Successful',
  delegationCompleteMessage: 'You may close this browser window and go back to your console application.',
  delegationFailedHeader: 'Login Failed',
  delegationFailedMessage:
    'You may close this browser window and go back to your console application and try logging in again.',

  // Access control
  noAccessMessage: 'No access',
  // Password policy validation messages
  invalidPasswordMinLengthMessage: 'Invalid password: minimum length {0}.',
  invalidPasswordMaxLengthMessage: 'Invalid password: maximum length {0}.',
  invalidPasswordMinDigitsMessage: 'Invalid password: must contain at least {0} numerical digits.',
  invalidPasswordMinLowerCaseCharsMessage:
    'Invalid password: must contain at least {0} lower case characters.',
  invalidPasswordMinUpperCaseCharsMessage:
    'Invalid password: must contain at least {0} upper case characters.',
  invalidPasswordMinSpecialCharsMessage: 'Invalid password: must contain at least {0} special characters.',
  invalidPasswordNotUsernameMessage: 'Invalid password: must not be equal to the username.',
  invalidPasswordNotContainsUsernameMessage: 'Invalid password: Can not contain the username.',
  invalidPasswordNotEmailMessage: 'Invalid password: must not be equal to the email.',
  invalidPasswordRegexPatternMessage: 'Invalid password: fails to match regex pattern(s).',
  invalidPasswordHistoryMessage: 'Invalid password: must not be equal to any of last {0} passwords.',
  invalidPasswordGenericMessage: "Invalid password: new password doesn't match password policies.",

  // System and protocol errors
  failedToProcessResponseMessage: 'Failed to process response',
  httpsRequiredMessage: 'HTTPS required',
  realmNotEnabledMessage: 'Realm not enabled',
  invalidRequestMessage: 'Invalid Request',

  // Logout messages
  successLogout: 'You are logged out',
  failedLogout: 'Logout failed',

  // Client and flow related messages
  unknownLoginRequesterMessage: 'Unknown login requester',
  loginRequesterNotEnabledMessage: 'Login requester not enabled',
  bearerOnlyMessage: 'Bearer-only applications are not allowed to initiate browser login',
  standardFlowDisabledMessage:
    'Client is not allowed to initiate browser login with given response_type. Standard flow is disabled for the client.',
  implicitFlowDisabledMessage:
    'Client is not allowed to initiate browser login with given response_type. Implicit flow is disabled for the client.',
  invalidRedirectUriMessage: 'Invalid redirect uri',
  unsupportedNameIdFormatMessage: 'Unsupported NameIDFormat',
  invalidRequesterMessage: 'Invalid requester',

  // Registration and credential management
  registrationNotAllowedMessage: 'Registration not allowed',
  resetCredentialNotAllowedMessage: 'Reset Credential not allowed',

  // Permission and authentication errors
  permissionNotApprovedMessage: 'Permission not approved.',
  noRelayStateInResponseMessage: 'No relay state in response from identity provider.',
  insufficientPermissionMessage: 'Insufficient permissions to link identities.',

  // Identity Provider error messages
  couldNotProceedWithAuthenticationRequestMessage:
    'Could not proceed with authentication request to identity provider.',
  couldNotObtainTokenMessage: 'Could not obtain token from identity provider.',
  unexpectedErrorRetrievingTokenMessage: 'Unexpected error when retrieving token from identity provider.',
  unexpectedErrorHandlingResponseMessage: 'Unexpected error when handling response from identity provider.',
  identityProviderAuthenticationFailedMessage:
    'Authentication failed. Could not authenticate with identity provider.',
  couldNotSendAuthenticationRequestMessage: 'Could not send authentication request to identity provider.',
  unexpectedErrorHandlingRequestMessage:
    'Unexpected error when handling authentication request to identity provider.',

  // Session and code validation
  invalidAccessCodeMessage: 'Invalid access code.',
  sessionNotActiveMessage: 'Session not active.',
  invalidCodeMessage: 'An error occurred, please login again through your application.',
  cookieNotFoundMessage: 'Cookie not found. Please make sure cookies are enabled in your browser.',
  insufficientLevelOfAuthentication: 'The requested level of authentication has not been satisfied.',

  // Additional Identity Provider messages
  identityProviderUnexpectedErrorMessage: 'Unexpected error when authenticating with identity provider',
  identityProviderMissingStateMessage: 'Missing state parameter in response from identity provider.',
  identityProviderMissingCodeOrErrorMessage:
    'Missing code or error parameter in response from identity provider.',
  identityProviderInvalidResponseMessage: 'Invalid response from identity provider.',
  identityProviderInvalidSignatureMessage: 'Invalid signature in response from identity provider.',
  identityProviderNotFoundMessage: 'Could not find an identity provider with the identifier.',
  identityProviderLinkSuccess:
    'You successfully verified your email. Please go back to your original browser and continue there with the login.',

  // Email verification and account linking
  staleCodeMessage: 'This page is no longer valid, please go back to your application and sign in again',
  realmSupportsNoCredentialsMessage: 'Realm does not support any credential type.',
  credentialSetupRequired: 'Cannot login, credential setup required.',
  identityProviderNotUniqueMessage:
    'Realm supports multiple identity providers. Could not determine which identity provider should be used to authenticate with.',
  emailVerifiedMessage: 'Your email address has been verified.',
  emailVerifiedAlreadyMessage: 'Your email address has been verified already.',
  staleEmailVerificationLink:
    'The link you clicked is an old stale link and is no longer valid. Maybe you have already verified your email.',
  identityProviderAlreadyLinkedMessage:
    'Federated identity returned by {0} is already linked to another user.',

  // Account linking confirmations
  confirmAccountLinking: 'Confirm linking the account {0} of identity provider {1} with your account.',
  confirmEmailAddressVerification: 'Confirm validity of e-mail address {0}.',
  confirmExecutionOfActions: 'Perform the following action(s)',

  // Navigation and UI messages
  backToApplication: '« Back to Application',
  missingParameterMessage: 'Missing parameters: {0}',
  clientNotFoundMessage: 'Client not found.',
  clientDisabledMessage: 'Client disabled.',
  invalidParameterMessage: 'Invalid parameter: {0}',

  // Session management
  alreadyLoggedIn: 'You are already logged in.',
  differentUserAuthenticated:
    "You are already authenticated as different user '{0}' in this session. Please sign out first.",
  brokerLinkingSessionExpired: 'Requested broker account linking, but current session is no longer valid.',
  proceedWithAction: '» Click here to proceed',
  acrNotFulfilled: 'Authentication requirements not fulfilled',

  // Required actions
  'requiredAction.CONFIGURE_TOTP': 'Configure OTP',
  'requiredAction.TERMS_AND_CONDITIONS': 'Terms and Conditions',
  'requiredAction.UPDATE_PASSWORD': 'Update Password',
  'requiredAction.UPDATE_PROFILE': 'Update Profile',
  'requiredAction.VERIFY_EMAIL': 'Verify Email',
  'requiredAction.CONFIGURE_RECOVERY_AUTHN_CODES': 'Generate Recovery Codes',
  'requiredAction.webauthn-register-passwordless': 'Webauthn Register Passwordless',
  'requiredAction.webauthn-register': 'Webauthn Register',

  // Token validation
  invalidTokenRequiredActions: 'Required actions included in the link are not valid',

  // X509 Certificate login
  doX509Login: 'You will be logged in as:',
  clientCertificate: 'X509 client certificate:',
  noCertificate: '[No Certificate]',

  // System errors
  pageNotFound: 'Page not found',
  internalServerError: 'An internal server error has occurred',

  // Console-based authentication messages
  'console-username': 'Username:',
  'console-password': 'Password:',
  'console-otp': 'One Time Password:',
  'console-new-password': 'New Password:',
  'console-confirm-password': 'Confirm Password:',
  'console-update-password': 'Update of your password is required.',
  'console-verify-email':
    'You need to verify your email address. We sent an email to {0} that contains a verification code. Please enter this code into the input below.',
  'console-email-code': 'Email Code:',
  'console-accept-terms': 'Accept Terms? [y/n]:',
  'console-accept': 'y',

  // OpenShift-specific scope descriptions
  'openshift.scope.user_info': 'User information',
  'openshift.scope.user_check-access': 'User access information',
  'openshift.scope.user_full': 'Full Access',
  'openshift.scope.list-projects': 'List projects',

  // SAML authentication messages
  'saml.post-form.title': 'Authentication Redirect',
  'saml.post-form.message': 'Redirecting, please wait.',
  'saml.post-form.js-disabled':
    'JavaScript is disabled. We strongly recommend to enable it. Click the button below to continue.',
  'saml.artifactResolutionServiceInvalidResponse': 'Unable to resolve artifact.',

  // Authenticator display names and help texts
  'otp-display-name': 'Authenticator Application',
  'otp-help-text': 'Enter a verification code from authenticator application.',
  'otp-reset-description': 'Which OTP configuration should be removed?',
  'password-display-name': 'Password',
  'password-help-text': 'Sign in by entering your password.',

  // Authentication form display names and help texts
  'auth-username-form-display-name': 'Username',
  'auth-username-form-help-text': 'Start sign in by entering your username',
  'auth-username-password-form-display-name': 'Username and password',
  'auth-username-password-form-help-text': 'Sign in by entering your username and password.',
  'auth-x509-client-username-form-display-name': 'X509 Certificate',
  'auth-x509-client-username-form-help-text': 'Sign in with an X509 client certificate.',

  // Recovery code authentication
  'auth-recovery-authn-code-form-display-name': 'Recovery Authentication Code',
  'auth-recovery-authn-code-form-help-text':
    'Enter a recovery authentication code from a previously generated list.',
  'auth-recovery-code-info-message': 'Enter the specified recovery code.',
  'auth-recovery-code-prompt': 'Recovery code #{0}',
  'auth-recovery-code-header': 'Login with a recovery authentication code',
  'recovery-codes-error-invalid': 'Invalid recovery authentication code',

  // Recovery code configuration and management
  'recovery-code-config-header': 'Recovery Authentication Codes',
  'recovery-code-config-warning-title': "These recovery codes won't appear again after leaving this page",
  'recovery-code-config-warning-message':
    'Make sure to print, download, or copy them to a password manager and keep them save. Canceling this setup will remove these recovery codes from your account.',

  // Recovery code actions
  'recovery-codes-print': 'Print',
  'recovery-codes-download': 'Download',
  'recovery-codes-copy': 'Copy',
  'recovery-codes-copied': 'Copied',
  'recovery-codes-confirmation-message': 'I have saved these codes somewhere safe',
  'recovery-codes-action-complete': 'Complete setup',
  'recovery-codes-action-cancel': 'Cancel setup',

  // Recovery code download information
  'recovery-codes-download-file-header': 'Keep these recovery codes somewhere safe.',
  'recovery-codes-download-file-description':
    'Recovery codes are single-use passcodes that allow you to sign in to your account if you do not have access to your authenticator.',
  'recovery-codes-download-file-date': 'These codes were generated on',
  'recovery-codes-label-default': 'Recovery codes',

  // WebAuthn Error Messages
  'webauthn-error-title': 'Passkey Error',
  'webauthn-error-registration': 'Failed to register your Passkey.<br/> {0}',
  'webauthn-error-api-get': 'Failed to authenticate by the Passkey.<br/> {0}',
  'webauthn-error-different-user': 'First authenticated user is not the one authenticated by the Passkey.',
  'webauthn-error-auth-verification': 'Passkey authentication result is invalid.<br/> {0}',
  'webauthn-error-register-verification': 'Passkey registration result is invalid.<br/> {0}',
  'webauthn-error-user-not-found': 'Unknown user authenticated by the Passkey.',

  // Passkey Authentication Messages
  'passkey-login-title': 'Passkey login',
  'passkey-available-authenticators': 'Available Passkeys',
  'passkey-unsupported-browser-text':
    'Passkey is not supported by this browser. Try another one or contact your administrator.',
  'passkey-doAuthenticate': 'Sign in with Passkey',
  'passkey-createdAt-label': 'Created',
  'passkey-autofill-select': 'Select your passkey',

  // Identity Provider Messages
  'identity-provider-redirector': 'Connect with another Identity Provider',
  'identity-provider-login-label': 'Or sign in with',
  'idp-email-verification-display-name': 'Email Verification',
  'idp-email-verification-help-text': 'Link your account by validating your email.',
  'idp-username-password-form-display-name': 'Username and password',
  'idp-username-password-form-help-text': 'Link your account by logging in.',

  // Account Deletion Messages
  finalDeletionConfirmation:
    'If you delete your account, it cannot be restored. To keep your account, click Cancel.',
  irreversibleAction: 'This action is irreversible',
  deleteAccountConfirm: 'Delete account confirmation',
  deletingImplies: 'Deleting your account implies:',
  errasingData: 'Erasing all your data',
  loggingOutImmediately: 'Logging you out immediately',
  accountUnusable: 'Any subsequent use of the application will not be possible with this account',
  userDeletedSuccessfully: 'User deleted successfully',

  // Access Control Messages
  'access-denied': 'Access denied',
  'access-denied-when-idp-auth': 'Access denied when authenticating with {0}',

  // Logout Messages
  'frontchannel-logout.title': 'Logging out',
  'frontchannel-logout.message': 'You are logging out from following apps',
  logoutConfirmTitle: 'Logging out',
  logoutConfirmHeader: 'Do you want to log out?',
  doLogout: 'Logout',

  // User Management Messages
  readOnlyUsernameMessage: "You can't update your username as it is read-only.",
  'error-invalid-multivalued-size': 'Attribute {0} must have at least {1} and at most {2} value(s).',

  // Organization Management Messages
  'organization.confirm-membership.title': 'You are about to join organization ${kc.org.name}',
  'organization.confirm-membership':
    'By clicking on the link below, you will become a member of the {0} organization:',
  'organization.member.register.title': 'Create an account to join the ${kc.org.name} organization',
  'organization.select': 'Select an organization to proceed:',
};
