import {
  manageUserPersonaRole,
  manageUsersLoginDetails,
  manageUsersPersonalInformation,
  manageUsersReviewAddressTemplate,
} from '@finxone-platform/shared/default-templates';
import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonActionType,
  Constants,
  GridPosition,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import { FormSubmissionButtonWidgetBuilder, LineWidgetBuilder } from '../../../widgets/example-builder';
import { generateMultiQuickLinkWidget } from '../../common/common-widgets';
import { generateHeaderTextWidget, generateTextWidget } from '../common-utils';

export const defaultTextWidgetCssConfig = {
  ...Constants.defaultBaseCssConfig,
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 15,
  borderRadiusTopLeft: 5,
  borderRadiusBottomLeft: 5,
  borderRadiusTopRight: 5,
  borderRadiusBottomRight: 5,
  borderColor: 'var(--border-color-1)',
  backGroundColor: 'var(--palette-color-6)',
  justifyContent: 'left',
  verticalAlignment: 'center',
  borderWidth: 1,
};
const commonEditDetailsTextWidget = ({ gridPosition }: { gridPosition: GridPosition }) =>
  generateTextWidget({
    text: 'Please edit the details if there are any changes you see.',
    position: gridPosition,
    styles: {
      paddingLeft: 5,
      margintop: 12,
      fontSize: 14,
      textAlignment: 'left',
      color: 'var(--typography-color-1)',
    },
    displayName: 'Text Widget: Info Text',
    isRemovable: true,
  });

const commonEditDetailsMultiIconQuickLinkWidget = ({
  gridPosition,
  displayName,
  navigateToPageUrl,
}: {
  gridPosition: GridPosition;
  displayName: string;
  navigateToPageUrl: string;
}) =>
  generateMultiQuickLinkWidget({
    zoneName: APP_ZONES.USER_MANAGEMENT,
    navigateToPageUrl: navigateToPageUrl,
    displayName: `Multi Quick Link: ${displayName}`,
    textContent: displayName,
    gridPosition: gridPosition,
  });

export const manageUsersReviewDetailsWidgets = [
  generateHeaderTextWidget(),

  new LineWidgetBuilder()
    .configDisplayName('Line Widget: Header Bottom Line')
    .gridPosition({
      y: 1,
      rows: 1,
      x: 1,
      cols: 40,
    } as GridPosition)
    .attributes({
      widgetProperties: {
        color: 'var(--border-color-1)',
        'border-bottom': 'var(--border-color-1)',
        lineThickness: 1,
        lineWidth: '100',
        textContent: '',
        show: true,
        contentAlignment: 'center',
        paddingLeft: 2,
      } as BaseWidgetProperties,
      css_config: {
        marginTop: 0,
      },
    } as WorkflowWidgetConfigurationAttributes)
    .build(),

  new FormSubmissionButtonWidgetBuilder()
    .configDisplayName('CTA Button: Discard ')
    .gridPosition({
      y: 1,
      x: 30,
      rows: 1,
      cols: 4,
    } as GridPosition)
    .cssConfig({
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      verticalAlignment: 'center',
      color: 'var(--palette-color-3)',
      borderWidth: 1,
      backGroundColor: 'var(--palette-color-6)',
      borderColor: 'var(--palette-color-3)',
    })
    .attributes({
      widgetProperties: {
        textContent: 'Discard',
        zoneToNavigate: APP_ZONES.USER_MANAGEMENT,
        urlToNavigate: 'select-user',
        buttonActionType: ButtonActionType.ASSIGN_PERSONA_FORM_STATE_CLEAR_AND_REDIRECT,
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .isRemovable(false)
    .build(),

  new FormSubmissionButtonWidgetBuilder()
    .configDisplayName('Button: Send Invite')
    .gridPosition({
      y: 1,
      x: 35,
      rows: 1,
      cols: 5,
    } as GridPosition)
    .cssConfig({
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      verticalAlignment: 'center',
    })
    .attributes({
      widgetProperties: {
        textContent: 'Send Invite',
        zoneToNavigate: APP_ZONES.USER_MANAGEMENT,
        urlToNavigate: '',
        buttonActionType: ButtonActionType.ADD_USER_SEND_INVITE,
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .isRemovable(false)
    .build(),

  generateTextWidget({
    text: 'Review Details',
    position: {
      y: 3,
      x: 0,
      rows: 1,
      cols: 10,
    } as GridPosition,
    displayName: 'Text Widget: Review Details',
    styles: {
      color: 'var(--typography-color-0)',
      fontSize: 24,
      textAlignment: 'left',
    },
    isRemovable: true,
  }),

  generateTextWidget({
    text: 'Please review and edit the details if there are any changes you see. ',
    position: { x: 0, y: 4, rows: 1, cols: 15 } as GridPosition,
    styles: {
      color: 'var(--typography-color-1)',
      fontSize: 14,
      textAlignment: 'left',
    },
    displayName: 'Text widget: Info',
    isRemovable: true,
  }),

  commonEditDetailsMultiIconQuickLinkWidget({
    displayName: 'Personal Information',
    navigateToPageUrl: 'add-user-step-1',
    gridPosition: {
      y: 6,
      x: 0,
      rows: 2,
      cols: 12,
    } as GridPosition,
  }),

  commonEditDetailsTextWidget({
    gridPosition: {
      y: 8,
      x: 0,
      rows: 1,
      cols: 12,
    } as GridPosition,
  }),

  generateTextWidget({
    text: manageUsersPersonalInformation,
    position: {
      y: 10,
      x: 0,
      rows: 6,
      cols: 12,
    } as GridPosition,
    displayName: 'Text Widget: Personal Information ',
    styles: defaultTextWidgetCssConfig,
    isRemovable: false,
  }),

  commonEditDetailsMultiIconQuickLinkWidget({
    displayName: 'Persona & Role',
    navigateToPageUrl: 'assign-persona',
    gridPosition: {
      y: 6,
      x: 13,
      rows: 2,
      cols: 12,
    } as GridPosition,
  }),

  commonEditDetailsTextWidget({
    gridPosition: {
      y: 8,
      x: 13,
      rows: 1,
      cols: 12,
    } as GridPosition,
  }),

  generateTextWidget({
    text: manageUserPersonaRole,
    position: {
      y: 10,
      x: 13,
      rows: 5,
      cols: 12,
    } as GridPosition,
    displayName: 'Text Widget: Assign Persona ',
    styles: defaultTextWidgetCssConfig,
    isRemovable: false,
  }),

  commonEditDetailsMultiIconQuickLinkWidget({
    displayName: 'Address',
    navigateToPageUrl: 'add-user-step-3',
    gridPosition: {
      y: 6,
      x: 26,
      rows: 2,
      cols: 12,
    } as GridPosition,
  }),

  commonEditDetailsTextWidget({
    gridPosition: {
      y: 8,
      x: 26,
      rows: 1,
      cols: 12,
    } as GridPosition,
  }),

  generateTextWidget({
    text: manageUsersReviewAddressTemplate,
    position: {
      y: 10,
      x: 26,
      rows: 6,
      cols: 12,
    } as GridPosition,
    displayName: 'Text Widget: Address ',
    styles: defaultTextWidgetCssConfig,
    isRemovable: false,
  }),
  commonEditDetailsMultiIconQuickLinkWidget({
    displayName: 'Login Details',
    navigateToPageUrl: 'add-user-step-2',
    gridPosition: {
      y: 17,
      x: 0,
      rows: 2,
      cols: 12,
    } as GridPosition,
  }),

  commonEditDetailsTextWidget({
    gridPosition: {
      y: 19,
      x: 0,
      rows: 1,
      cols: 12,
    } as GridPosition,
  }),

  generateTextWidget({
    text: manageUsersLoginDetails,
    position: {
      y: 21,
      x: 0,
      rows: 3,
      cols: 12,
    } as GridPosition,
    displayName: 'Text Widget: Login Details ',
    styles: defaultTextWidgetCssConfig,
    isRemovable: false,
  }),
];
