import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonActionType, UiZoneWidgetAttributeConfig } from '@finxone-platform/shared/sys-config-types';
import { functionCtaType, redirectToPage } from '../cta-button-actions.utils';
import { checkAuthSmsStatusAndRedirect } from '../domestic-payments/check-sms-auth-status-and-redirect';
import { checkSourceAccountAndRedirectToPayNewIntlBeneficiary } from '../intl-payments/check-source-account-and-redirect-intl-beneficiary';
import { submitInternationalPayment } from '../intl-payments/submit-international-payment';

export const paymentsActions: Map<ButtonActionType, functionCtaType> = new Map([
  [
    ButtonActionType.PAY_NEW_INTL_BENEFICIARY,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      checkSourceAccountAndRedirectToPayNewIntlBeneficiary(attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.INIT_INTL_PAYMENT,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      submitInternationalPayment(attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.SEPA_TRANSFER_PAYMENT_FLOW,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      redirectToPage(injector.get(Router), attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.CHECK_SMS_AUTH_STATUS_AND_REDIRECT,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      checkAuthSmsStatusAndRedirect(attri.widgetProperties, injector);
    },
  ],
]);
