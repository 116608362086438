import { formatNameAsUrl } from '@app/finxone-web-frontend/app/lib/utils/zone-url.utils';
import {
  ACCOUNTTYPE,
  ActiveDevice,
  BeneficiaryZonePagesTags,
  ButtonSizeOptions,
  ButtonTypes,
  CommonPageConfig,
  GridPosition,
  PaymentFlowTypes,
  PaymentProvider,
  WorkflowPageConfig,
  WorkflowWidgetConfig,
} from '@finxone-platform/shared/sys-config-types';
import { editBeneficiaryDesktopPageWidgets } from '../../../../page-builder-utils/widget-builder-utils/beneficiary-zone/edit-beneficiary-page-widgets.utils';
import { generateEditBeneficiaryIntlPageWidgetsConfig } from '../../../../page-builder-utils/widget-builder-utils/beneficiary-zone/edit-intl-beneficiary-page-widgets.utils';
import { generateDesktopViewbeneficiaryPageWidgets } from '../../../../page-builder-utils/widget-builder-utils/beneficiary-zone/view-beneficiary-page-widgets.utils';
import { generateViewIntlbeneficiaryPageWidgets } from '../../../../page-builder-utils/widget-builder-utils/beneficiary-zone/view-intl-beneficiary-page-widgets.utils';
import { PageConfig } from '../../../../zones/mobile/default-zone-page-setups';
import { formatPageName } from '../../../pages/utils';
import { BackButtonWidgetBuilder, TextWidgetBuilder } from '../../../widgets/example-builder';
import { defaultPageConfigurations } from '../../../widgets/workflow-ui-constants.v2.type';
import { getBeneficiaryListConfig } from '../../payments/payments-page-setups';

export function buildBeneficiariesZonePagesConfigDesktop(): WorkflowPageConfig[] {
  const zonePages: WorkflowPageConfig[] = [];
  const initialPages: PageConfig[] = [
    {
      name: 'Beneficiary List',
      title: 'Beneficiary List',
      widgets: [
        new TextWidgetBuilder()
          .text('Beneficiaries')
          .configDisplayName('Text Widget: Beneficiaries')
          .cssConfig({
            isTextBold: true,
            fontSize: 24,
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 0,
            textAlign: 'center',
          })
          .gridPosition({ rows: 2, y: 2 } as GridPosition)
          .isRemovable(true)
          .build(),

        getBeneficiaryListConfig({
          textContent: `
         <div class="text-wrap">
         <h4>{{ name }}</h4>
         <span>{{ currencyName }} | {{ currencyCode }}</span>
         </div>`,
          widgetContext: {
            pageType: PaymentFlowTypes.ALL_PAYMENTS,
          },
          gridPosition: {
            y: 4,
            row: 10,
          },
        }),

        new BackButtonWidgetBuilder()
          .defaultBackButton()
          .cssConfig({
            color: 'var(--palette-color-3)',
            backGroundColor: 'var(--palette-color-6)',
            borderColor: 'var(--palette-color-3)',
            borderWidth: 1,
            marginLeft: 3,
            marginright: 3,
            marginTop: 6,
            marginBottom: 6,
          })
          .gridPosition({ y: 22, rows: 2, cols: 15 } as GridPosition)
          .isRemovable(true)
          .setGlobalStylingClass(true, `${ButtonTypes.SECONDARY} ${ButtonSizeOptions.FULL_WIDTH}`)
          .build(),
      ],
      tags: [],
    },
  ];

  initialPages.forEach((page: PageConfig) => {
    const pageObj: WorkflowPageConfig = {
      name: page.name,
      title: page.title,
      widgets: page.widgets ?? [],
      pageConfiguration: {
        ...defaultPageConfigurations,
        url: formatNameAsUrl(page.name),
        pageType: 'dynamicGrid',
        isRemovable: false,
        mainComponentPageName: 'ParentContainerComponent',
      },
    };
    zonePages.push(pageObj);
  });
  return zonePages;
}

function generateBeneficiaryZoneAdditionalPages(activeProjectPaymentGateway: PaymentProvider): {
  name: string;
  title: string;
  widgets: WorkflowWidgetConfig[];
  tags: BeneficiaryZonePagesTags[];
  pageConfiguration?: CommonPageConfig;
}[] {
  return [
    {
      name: 'View Beneficiary',
      title: 'View Beneficiary',
      widgets: generateDesktopViewbeneficiaryPageWidgets(activeProjectPaymentGateway),
      tags: [BeneficiaryZonePagesTags.VIEW_BENEFICIARY],
      pageConfiguration: {
        ...defaultPageConfigurations,
        isBodyScrollingEnabled: true,
        isUsingHtmlStructure: true,
      } as CommonPageConfig,
    },
    {
      name: 'View Beneficiary Intl',
      title: 'View Beneficiary Intl',
      widgets: generateViewIntlbeneficiaryPageWidgets(ActiveDevice.DESKTOP),
      tags: [BeneficiaryZonePagesTags.VIEW_BENEFICIARY],
    },
    {
      name: 'Edit Beneficiary (Personal)',
      title: 'Edit Beneficiary (Personal)',
      widgets: editBeneficiaryDesktopPageWidgets(ACCOUNTTYPE.PERSONAL),
      tags: [BeneficiaryZonePagesTags.EDIT_BENEFICIARY],
      pageConfiguration: {
        ...defaultPageConfigurations,
        isBodyScrollingEnabled: true,
        isUsingHtmlStructure: true,
      } as CommonPageConfig,
    },
    {
      name: 'Edit Beneficiary (Business)',
      title: 'Edit Beneficiary (Business)',
      widgets: editBeneficiaryDesktopPageWidgets(ACCOUNTTYPE.BUSINESS),
      tags: [BeneficiaryZonePagesTags.EDIT_BENEFICIARY],
      pageConfiguration: {
        ...defaultPageConfigurations,
        isBodyScrollingEnabled: true,
        isUsingHtmlStructure: true,
      } as CommonPageConfig,
    },
    {
      name: 'Edit Beneficiary (Intl Personal)',
      title: 'Edit Beneficiary (Intl Personal)',
      widgets: generateEditBeneficiaryIntlPageWidgetsConfig(ActiveDevice.DESKTOP, ACCOUNTTYPE.PERSONAL),
      tags: [BeneficiaryZonePagesTags.EDIT_BENEFICIARY],
    },
    {
      name: 'Edit Beneficiary (Intl Business)',
      title: 'Edit Beneficiary (Intl Business)',
      widgets: generateEditBeneficiaryIntlPageWidgetsConfig(ActiveDevice.DESKTOP, ACCOUNTTYPE.BUSINESS),
      tags: [BeneficiaryZonePagesTags.EDIT_BENEFICIARY],
    },
  ];
}

export function buildBeneficiaryZoneAdditionalPagesDesktop(
  pageTags: BeneficiaryZonePagesTags[],
  activeProjectPaymentGateway: PaymentProvider,
): {
  name: string;
  title: string;
  url?: string;
  widgets?: WorkflowWidgetConfig[];
  tags: string[];
  pageConfiguration: CommonPageConfig;
}[] {
  const zonePages: {
    name: string;
    title: string;
    url?: string;
    widgets?: WorkflowWidgetConfig[];
    tags: string[];
    pageConfiguration: CommonPageConfig;
  }[] = [];
  pageTags.forEach((tag) => {
    const beneficiaryZoneAdditionalPagesConfig =
      generateBeneficiaryZoneAdditionalPages(activeProjectPaymentGateway);
    beneficiaryZoneAdditionalPagesConfig.forEach((page: PageConfig) => {
      if (page.tags.includes(tag)) {
        const pageObj: {
          name: string;
          title: string;
          url?: string;
          widgets?: WorkflowWidgetConfig[];
          tags: string[];
          pageConfiguration: CommonPageConfig;
        } = {
          name: page.name,
          title: page.title,
          widgets: page.widgets ?? [],
          tags: page.tags,
          pageConfiguration: {
            ...defaultPageConfigurations,
            ...page.pageConfiguration,
            url: formatPageName(page.name),
            pageType: 'dynamicGrid',
            isRemovable: false,
            backgroundColor: 'var(--color-background)',
            mainComponentPageName: 'ParentContainerComponent',
          },
        };
        zonePages.push(pageObj);
      }
    });
  });
  return zonePages;
}
