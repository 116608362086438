import { GridPosition } from '@finxone-platform/shared/sys-config-types';
import {
  BiometricsAuthenticationWidgetBuilder,
  SpacerWidgetBuilder,
  TextWidgetBuilder,
} from '../../../widgets/example-builder';

export const authenticationWidgets = [
  new SpacerWidgetBuilder().setHeight({ height: 5, unit: 'px' }).build(),
  new BiometricsAuthenticationWidgetBuilder()
    .cssConfig({
      paddingTop: 20,
      paddingBottom: 20,
    })
    .build(),
  new SpacerWidgetBuilder().setHeight({ height: 30, unit: 'px' }).build(),
  new TextWidgetBuilder()
    .text(
      `<div>Add extra security by using your phone's biometrics and authentication. Require biometrics to unlock <strong>MoneyFin.</strong>
        </div>`,
    )
    .configDisplayName('Add extra security text')
    .gridPosition({ y: 5, rows: 4 } as GridPosition)
    .cssConfig({
      textAlignment: 'left',
      fontSize: '14',
      textFont: 'var(--font-family)',
    })
    .isRemovable(true)
    .build(),
  new SpacerWidgetBuilder().setHeight({ height: 45, unit: 'rem' }).build(),
  new TextWidgetBuilder()
    .text(
      `<div>By setting up biometrics initiation, you allow MoneyFin to analyze your biometrics that has already been setup in your phone settings. Read more in <a href='/zones/profile/privacy-policy' class="btn-link"><b>Privacy Policy<b/></a></div>`,
    )
    .configDisplayName('Privacy Policy text')
    .gridPosition({ y: 21, rows: 6 } as GridPosition)
    .cssConfig({
      textAlignment: 'center',
      fontSize: '14',
      textFont: 'var(--font-family)',
      color: 'var(--palette-color-6)',
      backGroundColor: 'var(--palette-color-0)',
      borderRadiusTopLeft: '5',
      borderRadiusTopRight: '5',
      borderRadiusBottomLeft: '5',
      borderRadiusBottomRight: '5',
      paddingRight: 34,
      paddingLeft: 34,
      paddingTop: 10,
      paddingBottom: 10,
    })
    .isRemovable(true)
    .build(),
];
