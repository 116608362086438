import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { FormActionTypeEnum } from '@finxone-platform/form-action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import {
  APP_ZONES,
  BaseWidgetProperties,
  RevenirPassportKYCStatus,
  RevenirTripType,
} from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { debounceTime, filter, switchMap, take } from 'rxjs';
import { ResetFormDataAction } from '../../../actions/form-submission.action';
import {
  ConfirmAndArchiveTrip,
  CreateRevenirTrip,
  GetRevenirSubjectStatus,
  MakeTripAsArchiveTrip,
} from '../../../actions/revenir.action';
import { ApiActions, ApiLoadingState } from '../../../state/api-loading.state';
import { FormActionState } from '../../../state/form-submision.state';
import { RevenirState } from '../../../state/revenir.state';
import { redirectToPage } from '../cta-button-actions.utils';

/**
 * Initiates the process of adding a Revenir trip by dispatching a CreateRevenirTrip action
 * with the current form state and then redirects to the trips home page.
 *
 * @param widgetProperties - The properties of the widget, including navigation details.
 * @param injector - The Angular injector used to retrieve services like Store and Router.
 */
export function addRevenirTrip(widgetProperties: BaseWidgetProperties, injector: Injector) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  const data = store.select(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.ADD_TRIP_REVENIR_ZONE),
  );
  data
    .pipe(
      switchMap((res) => {
        return store.dispatch(new CreateRevenirTrip(res));
      }),
    )
    .subscribe(() => {
      widgetProperties['urlToNavigate'] = 'trips-home-page';
      widgetProperties['zoneToNavigate'] = APP_ZONES.REVENIR_ZONE;
      store.dispatch(new ResetFormDataAction());
      redirectToPage(router, widgetProperties, injector);
    });
}

export function archiveRevenirTrip(store: Store) {
  const data = store.selectSnapshot(RevenirState.getArchivedRevenirTripDetails);
  store.dispatch(new MakeTripAsArchiveTrip(data as RevenirTripType));
}

const removeSpecialCharacters = (str: string): string => {
  const cleanedStatus = str?.replace(/[\W_]+$/, '');
  return cleanedStatus ?? ''; // Retains only alphanumeric characters and spaces
};

/**
 * Handles the navigation logic for a Revenir trip based on the KYC status and boarding pass upload status.
 *
 * @param widgetProperties - The properties of the widget used for navigation.
 * @param injector - The Angular injector for retrieving services.
 *
 * This function dispatches actions to reset form data and get the Revenir subject status.
 * It checks the KYC status and boarding pass upload status to determine the appropriate navigation URL.
 * If the KYC status is "PASSED", it checks if a boarding pass is required and sets the navigation URL accordingly.
 * If the KYC status is "PROCESSING", it shows an alert indicating that the passport is still being verified.
 * If the KYC status is "FAILED" or "UPLOAD_A_PASSPORT", it sets the navigation URL to 'missing-passport'.
 */
export function checkBarcodeScanDoneRevenirTrip(widgetProperties: BaseWidgetProperties, injector: Injector) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  const alertHandlerService = injector.get(AlertHandlerService);

  store.dispatch(new ResetFormDataAction());
  store.dispatch(new GetRevenirSubjectStatus());
  widgetProperties['zoneToNavigate'] = APP_ZONES.REVENIR_ZONE;

  const tripDetails = store.selectSnapshot(RevenirState.getSelectedRevenirTripDetails);
  const isBoardingPassUploaded = tripDetails?.is_boarding_pass_uploaded;
  const countryExit = tripDetails?.country_exit;
  const countryEntry = tripDetails?.country_entry;

  store
    .select(ApiLoadingState.getApiState(ApiActions.revenirKycStatus))
    .pipe(
      filter((apiState) => !apiState.isLoading),
      debounceTime(300),
    )
    .subscribe(() => {
      store
        .select(RevenirState.getRevenirStateDetails)
        .pipe(take(1))
        .subscribe((state) => {
          const status = state.kyc_status;
          if (!status) {
            return;
          }

          // If the Passport is uploaded then check this below condition '.

          /* If the boarding pass has not been uploaded and either the exit or entry country is Belgium, it sets urlToNavigate to 'missing-boarding-pass'. */
          /* If the boarding pass has been uploaded or neither the exit nor entry country is not Belgium, it sets urlToNavigate to 'barcode-scanning' .*/

          // If the status is anything other than "passed" (indicating that the passport is not uploaded), then redirect to 'missing-passport'.

          const statusLowerCase = status?.toLowerCase();

          switch (removeSpecialCharacters(statusLowerCase)) {
            case RevenirPassportKYCStatus.PASSED:
              {
                const isBoardingPassRequired =
                  !isBoardingPassUploaded && (countryExit === 'BEL' || countryEntry === 'BEL');

                const urlToNavigate = isBoardingPassRequired ? 'missing-boarding-pass' : 'barcode-scanning';

                widgetProperties['urlToNavigate'] = urlToNavigate;

                redirectToPage(router, widgetProperties, injector);
              }
              break;

            case RevenirPassportKYCStatus.PROCESSING:
              alertHandlerService.resetAlertFn();
              alertHandlerService.showAlertFn(
                'error',
                'Your passport is still being verified. Please try again later',
              );
              break;

            case RevenirPassportKYCStatus.FAILED:
              alertHandlerService.resetAlertFn();
              alertHandlerService.showAlertFn(
                'error',
                'Your passport has failed verification. Please try uploading your passport again',
              );
              widgetProperties['urlToNavigate'] = 'missing-passport';
              redirectToPage(router, widgetProperties, injector);
              break;

            case RevenirPassportKYCStatus.UPLOAD_A_PASSPORT:
              widgetProperties['urlToNavigate'] = 'missing-passport';
              redirectToPage(router, widgetProperties, injector);
              break;
          }
        });
    });
}

/**
 * Confirms and archives a Revenir trip by dispatching the appropriate action.
 *
 * This function retrieves the selected trip details from the RevenirState
 * and dispatches a ConfirmAndArchiveTrip action with the trip ID. It also
 * updates the widget properties to navigate to the 'confirm-archive-success'
 * page within the REVENIR_ZONE.
 *
 * @param widgetProperties - The properties of the widget, including navigation details.
 * @param injector - The Angular Injector used to retrieve services like Store and Router.
 */
export function confirmScanArchiveRevenirTrip(widgetProperties: BaseWidgetProperties, injector: Injector) {
  const store = injector.get(Store);
  const router = injector.get(Router);

  widgetProperties['urlToNavigate'] = 'confirm-archive-success';
  widgetProperties['zoneToNavigate'] = APP_ZONES.REVENIR_ZONE;
  redirectToPage(router, widgetProperties, injector);
  const tripDetails = store.selectSnapshot(RevenirState.getSelectedRevenirTripDetails);
  store.dispatch(new ConfirmAndArchiveTrip(tripDetails?.trip_id as string));
}

export function downloadTaxFreeForms() {
  const flutterWebView = (window as any).flutter_inappwebview;
  const url = 'https://revenir.projects.finxone.com/assets/themes/BVEmodenormal.pdf';
  if (flutterWebView) {
    flutterWebView.callHandler('pdfDownloadHandler', [url]);
  } else {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.click();
  }
}

/**
 * Navigates to a specific page based on the boarding pass upload status.
 *
 * This function retrieves the selected Revenir trip details from the store
 * and determines the navigation URL based on whether the boarding pass has
 * been uploaded. It then redirects to the appropriate page using the provided
 * router and widget properties.
 *
 * @param widgetProperties - The properties of the widget, including navigation details.
 * @param injector - The Angular injector used to retrieve services.
 */
export function skipBoardingPass(widgetProperties: BaseWidgetProperties, injector: Injector) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  const data = store.selectSnapshot(RevenirState.getSelectedRevenirTripDetails);
  widgetProperties['zoneToNavigate'] = APP_ZONES.REVENIR_ZONE;
  widgetProperties['urlToNavigate'] =
    data && !data.is_boarding_pass_uploaded ? 'trips-home-page' : 'exit-point';

  redirectToPage(router, widgetProperties, injector);
}

/**
 * Navigates to the appropriate page based on the boarding pass upload status.
 *
 * This function retrieves the selected trip details from the store and determines
 * the navigation URL based on whether the boarding pass has been uploaded. It then
 * redirects to the specified page within the REVENIR_ZONE.
 *
 * @param widgetProperties - The properties of the widget, including navigation details.
 * @param injector - The Angular injector used to retrieve services.
 */
export function reviewBoardingPass(widgetProperties: BaseWidgetProperties, injector: Injector) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  const data = store.selectSnapshot(RevenirState.getSelectedRevenirTripDetails);
  widgetProperties['zoneToNavigate'] = APP_ZONES.REVENIR_ZONE;
  widgetProperties['urlToNavigate'] =
    data && !data.is_boarding_pass_uploaded ? 'barcode-scanning' : 'exit-point';

  redirectToPage(router, widgetProperties, injector);
}

/**
 * Navigates to a specific page based on the boarding pass status and country details
 * of the selected Revenir trip. If the boarding pass is not uploaded and the trip
 * involves Belgium as an entry or exit point, redirects to the 'missing-boarding-pass' page.
 * Otherwise, redirects to the 'barcode-scanning' page.
 *
 * @param widgetProperties - The properties of the widget, including navigation details.
 * @param injector - The Angular injector used to retrieve services.
 */
export function passportDetails(widgetProperties: BaseWidgetProperties, injector: Injector) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  const data = store.selectSnapshot(RevenirState.getSelectedRevenirTripDetails);
  widgetProperties['zoneToNavigate'] = APP_ZONES.REVENIR_ZONE;
  if (
    data &&
    !data.is_boarding_pass_uploaded &&
    (data.country_exit === 'BEL' || data.country_entry === 'BEL')
  ) {
    widgetProperties['urlToNavigate'] = 'missing-boarding-pass';
    redirectToPage(router, widgetProperties, injector);
  } else if (
    (data && data?.is_boarding_pass_uploaded) ||
    (data?.country_exit !== 'BEL' && data?.country_entry !== 'BEL')
  ) {
    widgetProperties['urlToNavigate'] = 'barcode-scanning';
    redirectToPage(router, widgetProperties, injector);
  }
}
