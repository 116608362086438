import {
  notificationAccountInfoTemplate,
  notificationExchangeAccountInfoTemplate,
  notificationTransactionAccountInfoTemplate,
} from '@finxone-platform/shared/default-templates';
import {
  APP_ZONES,
  BaseWidgetProperties,
  DefaultToggleOption,
  DocumentMonthsUpdate,
  DocumentType,
  DownloadDocumentType,
  GridPosition,
  NotificationAlertTypeEnum,
  NotificationChannelEnum,
  NotificationSettingsWidgetLevel,
  WorkflowWidgetConfig,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import {
  DownloadDocumentWidgetBuilder,
  ImageWidgetBuilder,
  NotificationsWidgetBuilder,
  QuickLinkWidgetBuilder,
  SwitchNavigatorWidgetBuilder,
  TextWidgetBuilder,
} from '../../../../widgets/example-builder';
import { switchNavigatorCss } from '../../../../widgets/workflow-ui-constants.v2.type';

export const commonTextWidgetTransactionAccountInformation = [
  new TextWidgetBuilder()
    .text(notificationTransactionAccountInfoTemplate)
    .configDisplayName('Receiver Information')
    .gridPosition({ y: 10, rows: 14 } as GridPosition)
    .cssConfig({
      backGroundColor: 'var(--palette-color-5)',
      paddingTop: '25',
      paddingBottom: '25',
      paddingLeft: '20',
      paddingRight: '20',
      borderRadiusTopLeft: '5',
      borderRadiusTopRight: '5',
      borderRadiusBottomLeft: '5',
      borderRadiusBottomRight: '5',
      display: 'flex',
      verticalAlignment: 'start',
      textAlignment: 'left',
    })
    .isRemovable(false)
    .build(),
];

export const commonTextWidgetAccountInformation = [
  new TextWidgetBuilder()
    .text(notificationAccountInfoTemplate)
    .configDisplayName('Sender Information')
    .gridPosition({ y: 24, rows: 4 } as GridPosition)
    .cssConfig({
      backGroundColor: 'var(--palette-color-5)',
      marginTop: '10',
      paddingTop: '25',
      paddingBottom: '25',
      paddingLeft: '20',
      paddingRight: '20',
      borderRadiusTopLeft: '5',
      borderRadiusTopRight: '5',
      borderRadiusBottomLeft: '5',
      borderRadiusBottomRight: '5',
      display: 'flex',
      verticalAlignment: 'start',
      textAlignment: 'left',
    })
    .isRemovable(false)
    .build(),
];

export const commonTextWidgetConversionDetail = [
  new TextWidgetBuilder()
    .text(notificationExchangeAccountInfoTemplate)
    .configDisplayName('Exchange Detail')
    .gridPosition({ y: 11, rows: 25 } as GridPosition)
    .cssConfig({
      backGroundColor: 'var(--palette-color-5)',
      paddingTop: '25',
      paddingBottom: '25',
      paddingLeft: '20',
      paddingRight: '20',
      borderRadiusTopLeft: '5',
      borderRadiusTopRight: '5',
      borderRadiusBottomLeft: '5',
      borderRadiusBottomRight: '5',
      display: 'flex',
      verticalAlignment: 'start',
      textAlignment: 'left',
    })
    .isRemovable(false)
    .build(),
];

export function commonNotificationImageWidget(override: any = {}): WorkflowWidgetConfig {
  return new ImageWidgetBuilder()
    .configDisplayName(override?.configDisplayName)
    .cssConfig({
      selectedobjectfit: 'contain',
      ...override?.css_config,
    })
    .gridPosition(
      override?.gridPosition ? (override.gridPosition as GridPosition) : ({ y: 0, rows: 1 } as GridPosition),
    )
    .attributes(
      override?.attributes
        ? (override.attributes as WorkflowWidgetConfigurationAttributes)
        : ({
            widgetProperties: {
              textContent: '',
            } as BaseWidgetProperties,
          } as WorkflowWidgetConfigurationAttributes),
    )
    .build();
}

export function commonNotificationHeaderTextWidget(override: any = {}): WorkflowWidgetConfig {
  return new TextWidgetBuilder()
    .text(override?.text)
    .configDisplayName(override?.configDisplayName)
    .gridPosition(
      override?.gridPosition ? (override.gridPosition as GridPosition) : ({ y: 1, rows: 3 } as GridPosition),
    )
    .cssConfig(
      override?.css_config
        ? override?.css_config
        : {
            color: 'var(--palette-color-0)',
            textAlignment: 'center',
            justifyContent: 'center',
            fontSize: '20',
            isTextBold: 'bold',
          },
    )
    .isRemovable(false)
    .build();
}

export function commonNotificationDescriptionTextWidget(override: any = {}): WorkflowWidgetConfig {
  return new TextWidgetBuilder()
    .text(override?.text)
    .configDisplayName(override?.configDisplayName)
    .gridPosition(
      override?.gridPosition ? (override.gridPosition as GridPosition) : ({ y: 4, rows: 6 } as GridPosition),
    )
    .cssConfig(
      override?.css_config
        ? override?.css_config
        : {
            color: 'var(--palette-color-1)',
            textAlignment: 'center',
            justifyContent: 'center',
            fontSize: '14',
            paddingTop: '1',
            paddingBottom: '1',
            paddingLeft: '1',
            paddingRight: '1',
          },
    )
    .isRemovable(false)
    .build();
}

export function commonNotificationQuickLinkWidget(
  displayName: string,
  text: string,
  globalStyling = false,
  globalStylingClass?: string,
): WorkflowWidgetConfig {
  return new QuickLinkWidgetBuilder()
    .zone(APP_ZONES.CHART)
    .navigateToPageUrl('profile')
    .navigationOption('use-as-navigation')
    .role()
    .text(text)
    .cssConfig({
      color: 'var(--palette-color-3)',
      borderWidth: '1',
      borderColor: 'var(--palette-color-3)',
      backGroundColor: 'var(--palette-color-6)',
      fontSize: '16',
      borderRadiusTopLeft: '5',
      borderRadiusTopRight: '5',
      borderRadiusBottomLeft: '5',
      borderRadiusBottomRight: '5',
      marginBottom: '12',
      marginTop: '12',
      paddingTop: '11',
      paddingBottom: '11',
      paddingRight: '11',
      paddingleft: '11',
      textAlignment: 'center',
      justifyContent: 'center',
    })
    .gridPosition({ y: 32, rows: 2 } as GridPosition)
    .configDisplayName(displayName)
    .setGlobalStylingClass(globalStyling, globalStylingClass)
    .build();
}

export function downloadDocumentWidget(
  documentType: DownloadDocumentType = DownloadDocumentType.PROOF_OF_PAYMENT,
  override: any = {},
): WorkflowWidgetConfig {
  return new DownloadDocumentWidgetBuilder()
    .attributes({
      documentType: documentType,
      timeInterval: DocumentMonthsUpdate.NONE,
      downloadDocumentAs: DocumentType.PDF,
      textInputHeader: 'Download',
      elementsConfig: [],
      widgetProperties: {
        textContent: 'Download',
      },
      css_config: {
        borderWidth: 0,
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
      },
    })
    .gridPosition(
      override?.gridPosition ? (override.gridPosition as GridPosition) : ({ y: 29, rows: 2 } as GridPosition),
    )
    .configDisplayName('Download Document')
    .isRemovable(false)
    .build();
}

export function getSwitchNavigatorWidgetConfigNotificationsPage(): WorkflowWidgetConfig {
  return new SwitchNavigatorWidgetBuilder()
    .linksData([
      {
        textContent: 'In-app',
        pageUrl: 'notification-settings-in-app',
        zone: APP_ZONES.PROFILE,
      },
      {
        textContent: 'SMS',
        pageUrl: 'notification-settings-in-sms',
        zone: APP_ZONES.PROFILE,
      },
      {
        textContent: 'Email',
        pageUrl: 'notification-settings-in-email',
        zone: APP_ZONES.PROFILE,
      },
      {
        textContent: 'Push',
        pageUrl: 'notification-settings-in-push',
        zone: APP_ZONES.PROFILE,
      },
    ])
    .navigationOption('use-as-navigation')
    .role()
    .gridPosition({ y: 2 } as GridPosition)
    .cssConfig({
      ...switchNavigatorCss,
      borderColor: 'var(--palette-color-4)',
      backGroundColor: 'var(--palette-color-0)',
      disabledTextColor: 'var(--typography-color-0)',
    })
    .build();
}

export function getNotificationSettingsValue(
  notificationCategory: string,
  channel: NotificationChannelEnum,
  defaultToggle: DefaultToggleOption,
): NotificationSettingsWidgetLevel {
  return {
    category: notificationCategory,
    isActive: true,
    channel: channel,
    defaultToggle,
  };
}

export function getNotificationWidgetConfig(
  notificationCategory: string,
  channel: NotificationChannelEnum,
  defaultToggle = DefaultToggleOption.OFF,
): WorkflowWidgetConfig {
  let gridPositionY: number;
  let textTemplate: string;
  switch (notificationCategory) {
    case NotificationAlertTypeEnum.DAILY_BALANCE:
      gridPositionY = 6;
      textTemplate = `<p class="m-0">Daily Balance Updates</p>`;
      break;
    case NotificationAlertTypeEnum.TRANSACTION_ALERTS:
      gridPositionY = 11;
      textTemplate = `<p class="m-0">Transaction Alerts</p>`;
      break;
    case NotificationAlertTypeEnum.ADD_BENEFICIARY_ALERTS:
      gridPositionY = 16;
      textTemplate = `<p class="m-0">Beneficiary Alerts</p>`;
      break;
    case NotificationAlertTypeEnum.PAYMENTS_REQUEST_ALERTS:
      gridPositionY = 24;
      textTemplate = `<p class="m-0">Payment Request Status</p>`;
      break;
    default:
      gridPositionY = 0;
      textTemplate = '';
  }
  return new NotificationsWidgetBuilder()
    .notificationSetting(getNotificationSettingsValue(notificationCategory, channel, defaultToggle))
    .text(textTemplate)
    .gridPosition({ y: gridPositionY } as GridPosition)
    .isRemovable(false)
    .build();
}
