import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonActionType,
  GridPosition,
  WorkflowWidgetConfig,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';

import {
  FeeConfigurationWidgetBuilder,
  FormSubmissionButtonWidgetBuilder,
} from '../../../defaults/widgets/example-builder';
import { getBackButtonWidget } from '../../../defaults/zones/manage-users-zone/common-utils';
import { generateTextWidget } from '../../../defaults/zones/payments/common-widgets-utils';

export function generateAdHocFeePageWidgets(): WorkflowWidgetConfig[] {
  return [
    generateTextWidget({
      text: `{{#if selectedClientDetails.name}}{{ selectedClientDetails.name }}'s{{else}} -- {{/if}} Fee Management - Add/Edit Fee`,
      displayName: 'Text Widget: Page Heading',
      isRemovable: false,
      position: {
        x: 1,
        y: 0,
        rows: 1,
        cols: 36,
      } as GridPosition,
      styles: {
        fontSize: 24,
        color: 'var(--typography-color-0)',
        isTextBold: true,
        textAlignment: 'left',
      },
    }),
    generateTextWidget({
      text: 'Access the drilldown view of each account to gain detailed insights into the fee structure and review the associated transaction history.',
      displayName: 'Text Widget: Page Sub Heading',
      isRemovable: false,
      position: {
        x: 1,
        y: 1,
        rows: 1,
        cols: 36,
      } as GridPosition,
      styles: {
        fontSize: 20,
        color: 'var(--typography-color-1)',
        textAlignment: 'left',
      },
    }),
    getBackButtonWidget({
      position: {
        x: 38,
        y: 0,
        rows: 1,
        cols: 4,
      } as GridPosition,
      styles: {
        fontSize: 14,
        textAlignment: 'center',
        color: 'var(--palette-color-3)',
        backGroundColor: 'transparent',
        borderWidth: 1,
        borderColor: 'var(--palette-color-3)',
      },
    }),
    new FormSubmissionButtonWidgetBuilder()
      .configDisplayName('Button: Save')
      .attributes({
        widgetProperties: {
          textContent: 'Save',
          zoneToNavigate: APP_ZONES.FEE_MANAGEMENT,
          urlToNavigate: 'validation-add-edit-fee-client',
          buttonActionType: ButtonActionType.CREATE_AD_HOC_FEE,
        } as BaseWidgetProperties,
      } as WorkflowWidgetConfigurationAttributes)
      .cssConfig({
        marginTop: 0,
        disabledBgColor: 'var(--palette-color-4)',
        disabledTextColor: 'var(--typography-color-1)',
        disabledBorderColor: 'var(--border-color-1)',
      })
      .gridPosition({
        x: 43,
        y: 0,
        rows: 1,
        cols: 4,
      } as GridPosition)
      .build(),
    new FeeConfigurationWidgetBuilder().isRemovable(false).build(),
  ];
}
