import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonActionType, UiZoneWidgetAttributeConfig } from '@finxone-platform/shared/sys-config-types';
import { functionCtaType, redirectToPage } from '../cta-button-actions.utils';
import { checkFormActionDataKycDetailsAndRedirect } from '../kyc-zone/kyc-zone-actions.utils';
import { submitVerificationProfileChanges } from '../verification-zone/submit-profile-changes-in-verification-flow';

export const kycVerificationActions: Map<ButtonActionType, functionCtaType> = new Map([
  [
    ButtonActionType.REVIEW_INFORMATION_VERIFICATION_PERSONAL,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      redirectToPage(injector.get(Router), attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.REVIEW_INFORMATION_VERIFICATION_BUSINESS,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      redirectToPage(injector.get(Router), attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.KYC_EMAIL_PHONE_VERIFIED_CHECK,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      checkFormActionDataKycDetailsAndRedirect(attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.SUBMIT_PROFILE_CHANGES_IN_VERIFICATION_FLOW,
    (injector: Injector) => {
      submitVerificationProfileChanges(injector);
    },
  ],
  [
    ButtonActionType.VERIFICATION_EDIT_INFORMATION,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      redirectToPage(injector.get(Router), attri.widgetProperties, injector);
    },
  ],
]);
