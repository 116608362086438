import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonActionType,
  GridPosition,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import { generateManageUsersAssignPersonaWidgetsConfig } from '../../../widgets/admin-roles/user-managment.widgets';
import { FormSubmissionButtonWidgetBuilder } from '../../../widgets/example-builder';
import { generateTextWidget, getBackButtonWidget, lineWidgetBuilderConfig } from '../common-utils';

export const assignPersonaWidgets = [
  generateTextWidget({
    text: 'Assign Persona',
    position: {
      y: 0,
      x: 0,
      rows: 2,
      cols: 10,
    } as GridPosition,
    displayName: 'Text Widget: Assign Persona',
    styles: {
      color: 'var(--typography-color-0)',
      fontSize: 20,
      isTextBold: true,
      textAlignment: 'left',
      marginTop: 12,
      paddingTop: 8,
    },
    isRemovable: true,
  }),

  lineWidgetBuilderConfig,

  getBackButtonWidget({
    position: { y: 1, x: 30, rows: 1, cols: 4 } as GridPosition,
    styles: {
      fontSize: 16,
      textAlignment: 'center',
      color: 'var(--palette-color-3)',
      backGroundColor: 'var(--palette-color-6)',
      borderWidth: 1,
      borderColor: 'var(--palette-color-3)',
    },
  }),

  new FormSubmissionButtonWidgetBuilder()
    .configDisplayName('CTA Button:Assign ')
    .gridPosition({
      y: 1,
      x: 35,
      rows: 1,
      cols: 5,
    } as GridPosition)
    .cssConfig({
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      verticalAlignment: 'center',
    })
    .attributes({
      widgetProperties: {
        textContent: 'Assign',
        zoneToNavigate: APP_ZONES.USER_MANAGEMENT,
        urlToNavigate: 'add-user-step-1',
        buttonActionType: ButtonActionType.ASSIGN_PERSONA,
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .isRemovable(false)
    .build(),

  generateManageUsersAssignPersonaWidgetsConfig(),
];
