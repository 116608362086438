/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { BeneficiariesSuccessCodes, BeneficiaryErrorCodes } from '@app/translations';
import { FormActionTypeEnum, OtpParam } from '@finxone-platform/form-action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { APP_ZONES, BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { Observable, catchError, take, tap } from 'rxjs';
import { UpdateSelectedBeneficiaryStateInfo } from '../../../actions/beneficiary.action';
import { SetFormActionWithId } from '../../../actions/form-submission.action';
import { AccountService } from '../../../services/account-service/account-service.service';
import {
  GetBeneficiaryResponse,
  UpdateBeneficiaryRequest,
} from '../../../services/account-service/account.type';
import { FormActionState } from '../../../state/form-submision.state';
import { ProjectSettingsState } from '../../../state/project-settings.state';
import { RoleState } from '../../../state/role.state';
import { findAndShowAlertFromCode } from '../../alert-code-utils/alert-code.utils';
import { redirectToPage } from '../cta-button-actions.utils';

/**
 * Submits an edit request for a beneficiary after validating form data.
 *
 * This function retrieves necessary services and state using dependency injection,
 * validates the form data, and constructs a payload for updating beneficiary information.
 * If the form data is valid, it sends an update request to the account service and handles
 * the response by updating the store and redirecting the user. In case of an error or
 * invalid form data, it triggers appropriate alerts.
 *
 * @param _widgetProperties - Properties of the widget initiating the request.
 * @param injector - Injector for retrieving necessary services and state.
 */
export function submitEditBeneficiaryRequest(_widgetProperties: BaseWidgetProperties, injector: Injector) {
  const _store = injector.get(Store);
  const _router = injector.get(Router);
  const _alertHandlerService = injector.get(AlertHandlerService);
  const _accountService = injector.get(AccountService);
  const formStateData = _store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.INTL_BENEFICIARY_ADD),
  )?.formData;
  const allFormsValid = !!(
    formStateData?.['isCountryCurrencyFormValid'] &&
    formStateData?.['isCredentialsFormValid'] &&
    formStateData?.['isAddressFormValid']
  );
  if (allFormsValid) {
    const { isCountryCurrencyFormValid, isCredentialsFormValid, isAddressFormValid, ...restFormStateData } =
      formStateData;
    const payload: UpdateBeneficiaryRequest = {
      ...restFormStateData,
      destinationIdentifier: {
        ...formStateData.destinationIdentifier,
        currency: formStateData.currency,
      },
      country: formStateData.country.key,
    } as UpdateBeneficiaryRequest;
    _accountService
      .updateBeneficiaryInfo(removeEmptyKeysFromPayload(payload))
      .pipe(
        take(1),
        tap((response) => {
          if (response?.id) {
            delete formStateData['isCountryCurrencyFormValid'];
            delete formStateData['isCredentialsFormValid'];
            delete formStateData['isAddressFormValid'];

            _store.dispatch(new UpdateSelectedBeneficiaryStateInfo(response));
            _store.dispatch(new SetFormActionWithId({ type: '' }, FormActionTypeEnum.INTL_BENEFICIARY_ADD));
            redirectToPage(_router, _widgetProperties, injector);
            findAndShowAlertFromCode(_store, _router, _alertHandlerService, [
              BeneficiariesSuccessCodes.UPDATE_BENEFICIARY,
            ]);
          }
        }),
        catchError<GetBeneficiaryResponse, any>((_err: any, _caught: Observable<GetBeneficiaryResponse>) => {
          findAndShowAlertFromCode(_store, _router, _alertHandlerService, [
            BeneficiaryErrorCodes.FAILED_TO_UPDATE_BENEFICIARY,
          ]);
        }),
      )
      .subscribe();
  } else {
    _alertHandlerService.showAlertFn('error', 'Please complete the form before proceeding.');
  }
}

/**
 * Calls the API to edit an international beneficiary based on project settings and role data.
 * If SMS authentication is enabled and the current role is not excluded from SMS authentication,
 * redirects to the OTP page. Otherwise, directly submits the edit beneficiary request.
 *
 * @param _widgetProperties - The properties of the widget initiating the request.
 * @param injector - The Angular injector used to retrieve services.
 */
export function callEditIntlBeneficiaryAPI(_widgetProperties: BaseWidgetProperties, injector: Injector) {
  const _store = injector.get(Store);
  const _router = injector.get(Router);
  const projectSettingsData = _store.selectSnapshot(ProjectSettingsState.getProjectSettings);
  const roleData = _store.selectSnapshot(RoleState.getRole);

  if (projectSettingsData?.smsAuthenticationEnabled) {
    if (projectSettingsData.smsAuthenticationExclusion?.['editBeneficiary']?.includes(roleData?.role)) {
      // previous scenario
      submitEditBeneficiaryRequest(_widgetProperties, injector);
    } else {
      const parsedWidgetProperties = JSON.parse(JSON.stringify(_widgetProperties));
      // OTP page redirection
      parsedWidgetProperties['zoneToNavigate'] = APP_ZONES.PAYMENT;
      parsedWidgetProperties['urlToNavigate'] = `authentication-sms?otp=${OtpParam.EditIntlBeneficiary}`;
      redirectToPage(_router, parsedWidgetProperties, injector);
    }
  } else {
    // previous scenario
    submitEditBeneficiaryRequest(_widgetProperties, injector);
  }
}

function removeEmptyKeysFromPayload(formStateData: UpdateBeneficiaryRequest): UpdateBeneficiaryRequest {
  const filteredPayload = {} as UpdateBeneficiaryRequest;
  for (const [key, value] of Object.entries(formStateData)) {
    if (formStateData[key as keyof UpdateBeneficiaryRequest] !== '') {
      filteredPayload[key as keyof UpdateBeneficiaryRequest] = value;
    }
  }
  return filteredPayload;
}
