import { Injectable } from '@angular/core';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import {
  AccountInfoType,
  PaginatedResponse,
  PaymentProvider,
  accountServicePaymentRequest,
} from '@finxone-platform/shared/sys-config-types';
import { Action, Selector, State, StateContext, createSelector } from '@ngxs/store';
import { Observable, catchError, map, of, tap, throwError } from 'rxjs';
import {
  ClearAccountCache,
  CreateCurrencyAccount,
  CreateCurrencyAccountSuccess,
  GetAccount,
  GetTotalWealth,
  SetAccountState,
  SetLoadingStatus,
  SetTotalWealth,
  UpdateCurrentAccountId,
  UpdatePaymentGatewayConfig,
} from '../actions/account.action';
import { AddProgressBarStack, RemoveProgressBarStack } from '../actions/progress-bar.action';
import { AccountService } from '../services/account-service/account-service.service';
import { ApiActions, UpdateApiIsLoadingAction } from './api-loading.state';

export interface AccountStateModel {
  accounts: AccountInfoType[];
  accountActivePage: number;
  accountPageLimit: number;
  accountTotalPages: number;
  currentAccountId: string;
  paymentGatewayConfig: PaymentGatewayConfig;
  isLoading: boolean;
  enableTotalWealth?: boolean;
  totalWealthValue?: string;
  totalWealthCalculating?: boolean;
}

export interface PaymentGatewayConfig {
  payment: PaymentProvider;
  sandboxMode?: string;
  checkPayeeEnabled: boolean;
  bankProvider: string;
  supportedCurrency: string[];
  paymentRequest: accountServicePaymentRequest;
}

@State<AccountStateModel>({
  name: 'account',
  defaults: {
    accounts: [],
    currentAccountId: '',
    accountPageLimit: 10,
    accountActivePage: 0,
    accountTotalPages: 0,
    isLoading: false,
    enableTotalWealth: false,
    totalWealthCalculating: true,
    paymentGatewayConfig: {
      payment: PaymentProvider.MODULR,
      sandboxMode: '',
      supportedCurrency: [],
      bankProvider: '',
      checkPayeeEnabled: false,
      paymentRequest: {
        enabled: false,
        paymentApproverRoles: [],
        paymentRequestorRoles: [],
        paymentInitiatorRoles: [],
      },
    },
  },
})
@Injectable()
export class AccountState {
  constructor(private accountService: AccountService, private alertHandlerService: AlertHandlerService) {}

  @Selector()
  static getAccounts(state: AccountStateModel) {
    return state;
  }

  @Selector()
  static getCurrentAccountId(state: AccountStateModel) {
    return state.currentAccountId;
  }

  @Selector()
  static getCurrentAccount(state: AccountStateModel) {
    return state.accounts?.find((account) => account.accountId === state.currentAccountId);
  }

  static getCurrentAccountDetails(currentAccountId: string) {
    return createSelector([AccountState], (state: AccountStateModel) => {
      return state.accounts?.find((account) => account.accountId === currentAccountId);
    });
  }

  @Selector()
  static getPaymentGatewayConfig(state: AccountStateModel) {
    return state.paymentGatewayConfig;
  }

  @Selector()
  static getLoadingStatus(state: AccountStateModel) {
    return state.isLoading;
  }

  @Action(SetLoadingStatus)
  setLoadingStatus(ctx: StateContext<AccountStateModel>, action: SetLoadingStatus) {
    try {
      ctx.patchState({
        ...ctx.getState(),
        isLoading: action.isLoading,
      });
      return true;
    } catch (err) {
      return throwError(() => err);
    }
  }

  @Action(SetAccountState)
  setAccountState(ctx: StateContext<AccountStateModel>, action: SetAccountState) {
    ctx.setState(action.state);
  }

  @Action(UpdatePaymentGatewayConfig)
  updatePaymentGatewayConfig(ctx: StateContext<AccountStateModel>, action: UpdatePaymentGatewayConfig) {
    ctx.patchState({
      ...ctx.getState(),
      paymentGatewayConfig: action.payload,
    });
  }

  @Action(ClearAccountCache)
  clearAccountCache(ctx: StateContext<AccountStateModel>) {
    ctx.patchState({
      accounts: [],
      currentAccountId: '',
      accountPageLimit: 10,
      accountActivePage: 0,
      accountTotalPages: 0,
      isLoading: false,
    });
  }

  @Action(GetAccount)
  fetchAccount(ctx: StateContext<AccountStateModel>, action: GetAccount) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'GetAccount' }));
    ctx.dispatch(new UpdateApiIsLoadingAction(ApiActions.getAccounts, true));

    let params = {
      page: action.page,
      size: action.limit,
      status: action.status,
    };
    if (action.searchParams) {
      params = { ...params, ...action.searchParams };
    }

    return this.accountService.getAccounts(params).pipe(
      tap((accountsResult: PaginatedResponse<AccountInfoType>) => {
        const prevData: AccountInfoType[] = ctx.getState().accounts;
        let accountPrevResult;

        if (action.page === 1) {
          accountPrevResult = accountsResult.result;
        } else {
          accountPrevResult = prevData.concat(accountsResult.result);
        }

        ctx.patchState({
          accounts: accountPrevResult,
          accountActivePage: accountsResult.page,
          accountPageLimit: accountsResult.limit,
          accountTotalPages: accountsResult.totalPages,
          isLoading: false,
        });

        const currentId = ctx.getState().currentAccountId;
        const accountExists = accountsResult?.result?.some((account) => account.accountId === currentId);

        if (!currentId || (currentId && !accountExists)) {
          ctx.patchState({
            currentAccountId: accountsResult?.result?.[0]?.accountId,
          });
        }

        ctx.dispatch(new UpdateApiIsLoadingAction(ApiActions.getAccounts, false));
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'GetAccount' }));
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        ctx.dispatch(new UpdateApiIsLoadingAction(ApiActions.getAccounts, false));
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'GetAccount' }));
        throw _err;
      }),
    );
  }

  @Action(CreateCurrencyAccount)
  createCurrencyAccount(ctx: StateContext<AccountStateModel>, action: CreateCurrencyAccount) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'CreateCurrencyAccount' }));
    const payloadData = {
      currency: action.currency,
    };
    return this.accountService.addCurrencyAccount(payloadData).pipe(
      tap((response: AccountInfoType) => {
        const accounts: AccountInfoType[] = ctx.getState().accounts;
        accounts.push(response);
        this.alertHandlerService.showAlertFn('success', 'Currency Account added successfully');

        ctx.patchState({
          ...ctx.getState(),
          accounts: accounts,
        });

        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'CreateCurrencyAccount' }));
        ctx.dispatch(new CreateCurrencyAccountSuccess());
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'CreateCurrencyAccount' }));
        throw _err;
      }),
    );
  }

  @Action(UpdateCurrentAccountId)
  UpdateCurrentAccountId(ctx: StateContext<AccountStateModel>, action: UpdateCurrentAccountId) {
    ctx.patchState({
      ...ctx.getState(),
      currentAccountId: action.currentAccountId,
    });
  }

  @Action(SetTotalWealth)
  enableTotalWealth(ctx: StateContext<AccountStateModel>, action: { enableTotalWealth: boolean }) {
    ctx.patchState({
      ...ctx.getState(),
      enableTotalWealth: action.enableTotalWealth,
    });
  }

  @Action(GetTotalWealth)
  getTotalWealth(ctx: StateContext<AccountStateModel>, action: { baseCurrency: string }) {
    ctx.patchState({
      ...ctx.getState(),
      totalWealthCalculating: true,
    });
    return this.accountService.getTotalWealth(action.baseCurrency).pipe(
      map((response) => {
        if (response) {
          ctx.patchState({
            ...ctx.getState(),
            totalWealthValue: response.totalWealth,
            totalWealthCalculating: false,
          });
        }
      }),
      catchError((error) => {
        ctx.patchState({
          ...ctx.getState(),
          totalWealthCalculating: false,
        });
        console.error('Error While fetching Total wealth:', error);
        return of(null);
      }),
    );
  }
}
