// eslint-disable-next-line @nx/enforce-module-boundaries
import { UUID } from '@finxone-platform/shared/base-types';
import {
  RelevantBaasTags,
  SupportedDevice,
  WidgetNames,
  WorkflowPageConfig,
  WorkflowZoneConfig,
  WorkflowZoneDevicesConfig,
  ZONETYPEOPTION,
  commonPageConfigValue,
  cryptoRandomUUID,
} from '@finxone-platform/shared/sys-config-types';
import { zoneToMobilePageComponentDefaults } from '../../zones/mobile/default-zone-page-setups';
import { biometricsAuthenticationWidgetConfig } from '../widgets';

export function createDefaultPagesInZoneIfNeeded(
  zoneName: string,
  zone: WorkflowZoneDevicesConfig,
  device: SupportedDevice,
  relevantBaasTags: RelevantBaasTags[],
): void {
  const pages = zone[device]?.pages;

  if (!pages) {
    zone[device] = {
      zoneTypeConfig: 'new',
      pages: [],
      zoneConfiguration: {
        zonePageType: 'singlePage',
        zoneType: ZONETYPEOPTION.DEFAULT,
      },
    };
  }

  if (zone[device]) {
    createZone(
      zoneName,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      zone[device]!,
      relevantBaasTags,
    );
  }
}

function createZone(zone: string, zoneConfig: WorkflowZoneConfig, relevantBaasTags: RelevantBaasTags[]) {
  const currentPageNames = zoneConfig.pages.map((page) => page.name);

  const defaultPageConfigFunc = zoneToMobilePageComponentDefaults.get(zone);
  const defaultPageConfig = defaultPageConfigFunc ? defaultPageConfigFunc({ relevantBaasTags }) : null;
  if (defaultPageConfig) {
    zoneConfig.pages = [];

    defaultPageConfig.forEach((page) => {
      if (!currentPageNames.includes(page.name)) zoneConfig.pages.push(page);
    });
  }
}

export function createPage(
  zone: string,
  zoneConfig: WorkflowZoneConfig,
  pageIndex: number,
  pageType: 'dynamicGrid' | 'staticGrid' = 'dynamicGrid',
  relevantBaasTags: RelevantBaasTags[] = [],
): void {
  const defaultPageConfigFunc = zoneToMobilePageComponentDefaults.get(zone);
  const defaultPageConfig = defaultPageConfigFunc ? defaultPageConfigFunc({ relevantBaasTags }) : null;
  if (defaultPageConfig) {
    defaultPageConfig.forEach((page) => {
      zoneConfig.pages.push(page);
    });
  } else {
    while (!zoneConfig.pages[pageIndex]) {
      zoneConfig.pages.push(createPageType(pageType, 'something', 'Something Big', new UUID().toString()));
    }
  }
}

export function createPageType(
  type: 'dynamicGrid' | 'staticGrid' | 'staticPage',
  name: string,
  title: string,
  url: string,
  mainComponentPageName = 'ParentContainerComponent',
): WorkflowPageConfig {
  const pageTypes = new Map<'dynamicGrid' | 'staticGrid' | 'staticPage', WorkflowPageConfig>([
    [
      'dynamicGrid',
      {
        pageConfiguration: {
          mainComponentPageName: '',
          isRemovable: true,
          pageType: 'dynamicGrid',
          url: '',
          ...commonPageConfigValue,
        },
        widgets: [],
        name: '',
        title: '',
      } as WorkflowPageConfig,
    ],
    [
      'staticGrid',
      {
        pageConfiguration: {
          mainComponentPageName: '',
          isRemovable: true,
          pageType: 'staticGrid',
          url: '',
          ...commonPageConfigValue,
        },
        widgets: [],
        name: '',
        title: '',
      },
    ],
  ]);

  const page = pageTypes.get(type) as WorkflowPageConfig;
  page.pageConfiguration.mainComponentPageName = mainComponentPageName;
  page.title = title;
  page.name = name;
  page.pageConfiguration.url = url;
  page.pageUniqueId = cryptoRandomUUID();
  return page;
}

export function formatPageName(pageName: string): string {
  if (pageName?.length)
    return pageName
      .replace(/[^a-zA-Z0-9\s]/g, '')
      .replace(/\s+/g, '-')
      .toLowerCase();
  else return '';
}

export const configKeyToPageNamesMap: Map<string, string[]> = new Map([
  [
    biometricsAuthenticationWidgetConfig.widgetConfiguration?.attributes?.elementsConfig[0]?.field_name,
    ['enable-authentication-confirmation', 'disable-authentication-confirmation'],
  ],
]);

/**
 * Splits a unique widget identifier into its layout widget index and sub-container widget index.
 *
 * @param {number | string} containerWidgetUniqueId - A unique identifier in the format "layoutWidgetUniqueId+subContainerWidgetIndex".
 *                                      Example: "3f1402ca-a26c-4ada-8030-90c02eb169d5+1"
 * @returns {Object} An object containing:
 *  - `layoutWidgetUniqueId`: The parsed layout widget index (defaults to '' if invalid).
 *  - `subContainerWidgetIndex`: The parsed sub-container widget index (defaults to 0 if invalid).
 */
export const splitLayoutContainerWidgetUniqueId = (
  containerWidgetUniqueId: number | string,
): {
  layoutWidgetUniqueId: string;
  subContainerWidgetIndex: number;
} => {
  try {
    // Convert the uniqueId to a string and split by '+'
    const parts = String(containerWidgetUniqueId).split('+');

    // Example: parts[0]: 3f1402ca-a26c-4ada-8030-90c02eb169d5
    // Example: parts[1]: 1
    const layoutWidgetUniqueId = parts[0];
    const subContainerWidgetIndex = Number(parts[1]);

    // Return extracted values with validation
    return {
      layoutWidgetUniqueId: layoutWidgetUniqueId,
      subContainerWidgetIndex: isNaN(subContainerWidgetIndex) ? 0 : subContainerWidgetIndex,
    };
  } catch (error) {
    return {
      layoutWidgetUniqueId: '',
      subContainerWidgetIndex: 0,
    };
  }
};

/**
 * Generates a default container name based on the widget type and index.
 *
 * This function assigns a descriptive name to a widget's sub-container based on its type.
 * If the widget type is recognized (e.g., `TWO_COLUMN` or `TAB`), it returns a formatted
 * name with the corresponding index. Otherwise, it defaults to a generic "Sub Container" label.
 *
 * @returns {string} The generated container name.
 */
export const getDefaultContainerName = (widgetName: WidgetNames, widgetIndex: number): string => {
  const index = `${widgetIndex + 1}`;
  switch (widgetName) {
    case WidgetNames.TWO_COLUMN:
      return `Column: ${index}`;
    case WidgetNames.TAB:
      return `Tab: ${index}`;
    default:
      return `Sub Container: ${index}`;
  }
};

/**
 * Mapping of widget types to their respective container configuration property names.
 *
 * This object stores the configuration property name for different widget types,
 * allowing dynamic access to container settings based on the widget type.
 * If a widget type is not listed, it does not have a predefined container configuration.
 */
export const containerConfigName: Partial<Record<WidgetNames, string>> = {
  [WidgetNames.TWO_COLUMN]: 'columnConfigs',
  [WidgetNames.TAB]: 'tabConfigs',
};

/**
 * Adds default container configuration data.
 *
 * This function ensures that each sub-container in `containerConfig` has a valid `containerName`.
 * if childComponentOuterCss is present, it takes the value of outerCssConfig from there and update into new config, it is only applicable for two-column widget.
 * If `containerConfig` is provided, it updates the specific sub-container at `subContainerWidgetIndex`.
 * If not, it initializes a new container configuration array with default values.
 *
 * @returns {Record<string, unknown>[]} - The updated or newly created container configuration array.
 */
export const addDefaultContainerConfigData = (
  containerConfig: Record<string, unknown>[],
  childComponentOuterCss: string[],
  subContainerWidgetIndex: number,
  totalContainerLength: number,
  widgetName: WidgetNames,
): Record<string, unknown>[] => {
  if (containerConfig?.length) {
    if (!containerConfig?.[subContainerWidgetIndex]?.['containerName']) {
      containerConfig[subContainerWidgetIndex] = {
        ...containerConfig?.[subContainerWidgetIndex],
        containerName: getDefaultContainerName(widgetName, subContainerWidgetIndex),
      };
    }
    return containerConfig;
  }

  const checkedChildComponentOuterCss = widgetName === WidgetNames.TWO_COLUMN ? childComponentOuterCss : [];

  return Array.from({ length: totalContainerLength }, (_, index) => ({
    outerCssConfig: checkedChildComponentOuterCss?.[index] || '',
    containerName: getDefaultContainerName(widgetName, index),
  }));
};
