import {
  BaseWidgetProperties,
  ButtonSizeOptions,
  ButtonTypes,
} from '@finxone-platform/shared/sys-config-types';
import {
  commonNotificationDescriptionTextWidget,
  commonNotificationHeaderTextWidget,
  commonNotificationImageWidget,
  commonNotificationQuickLinkWidget,
  commonTextWidgetAccountInformation,
  commonTextWidgetTransactionAccountInformation,
  downloadDocumentWidget,
} from '../utils/notification-utils';

export const notificationTransactionFailedWidgets = [
  commonNotificationImageWidget({
    configDisplayName: 'Image Widget: Failed Icon',
    attributes: {
      widgetProperties: {
        textContent: '',
        imageAssetUrl: 'assets/images/failed.svg',
      } as BaseWidgetProperties,
    },
  }),
  commonNotificationHeaderTextWidget({
    text: '<div class="w-100"><p class="m-0">Transaction Failed</p></div>',
    configDisplayName: 'Transaction Failed Header',
  }),
  commonNotificationDescriptionTextWidget({
    text: '<div class="w-100"><p>Reference Number: {{notification.selectedNotification.transactionNumber}}</p><p>Your transaction has failed. If you have any questions or concerns, please feel free to contact our customer support.</p></div>',
    configDisplayName: 'Description Text',
  }),
  ...commonTextWidgetTransactionAccountInformation,
  ...commonTextWidgetAccountInformation,
  downloadDocumentWidget(),
  commonNotificationQuickLinkWidget(
    'Quick Link: Confirm & Close',
    'Confirm & Close',
    true,
    `${ButtonTypes.SECONDARY} ${ButtonSizeOptions.FULL_WIDTH}`,
  ),
];
