import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { redirectToPage } from '../cta-button-actions.utils';

/**
 * Executes a transaction action by navigating through a series of pages.
 *
 * This function first sets the navigation URL to 'scanning-receipt' and redirects
 * the user to that page. After a delay of 3 seconds, it updates the navigation URL
 * to 'add-transaction-step-2' and redirects the user again.
 *
 * @param widgetProperties - The properties of the widget, including navigation details.
 * @param injector - The Angular injector used to retrieve services like the Router.
 */
export function saveTransactionAction(widgetProperties: BaseWidgetProperties, injector: Injector) {
  const router = injector.get(Router);
  widgetProperties['urlToNavigate'] = 'scanning-receipt';
  redirectToPage(router, widgetProperties, injector);

  setTimeout(() => {
    widgetProperties['urlToNavigate'] = 'add-transaction-step-2';
    redirectToPage(router, widgetProperties, injector);
  }, 3000);
}
