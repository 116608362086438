import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonSizeOptions,
  ButtonTypes,
  GridPosition,
  WidgetTitles,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import {
  BackButtonWidgetBuilder,
  ImageWidgetBuilder,
  QuickLinkWidgetBuilder,
  ResendLinkWidgetBuilder,
  TextWidgetBuilder,
} from '../../../widgets/example-builder';
import {
  getForgotPasswordWidget,
  getPasswordChangeWidget,
  getPasswordEnterEmailWidget,
} from '../../../widgets/profile/profile.type';
import { getTextWidgetForPaymentStatusPages } from '../../payments/payments-page-setups';
import {
  checkEmailDesktopConfig,
  checkEmailMobileConfig,
} from './password-coordinates/check-email-coordinates';
import {
  enterEmailDesktopConfig,
  enterEmailMobileConfig,
} from './password-coordinates/enter-email-coordinates';
import {
  forgotPassDesktopConfig,
  forgotPassMobileConfig,
} from './password-coordinates/forgot-password-coordinates';
import { passResetDesktopConfig, passResetMobileConfig } from './password-coordinates/passResetCoordinates';

export const passwordChangeWidgets = [
  getPasswordChangeWidget(),
  getForgotPasswordWidget(WidgetTitles.PASSWORD_NEW_WIDGET),
  new QuickLinkWidgetBuilder()
    .zone(APP_ZONES.PROFILE)
    .navigateToPageUrl('bank-statements-custom-date-range')
    .navigationOption('use-as-navigation')
    .role()
    .text('Cancel')
    .cssConfig({
      color: 'var(--palette-color-0)',
      borderWidth: '1',
      borderColor: 'var(--palette-color-0)',
      fontSize: 14,
      marginBottom: 25,
      borderRadiusTopLeft: 6,
      borderRadiusBottomLeft: 6,
      borderRadiusTopRight: 6,
      borderRadiusBottomRight: 6,
    })
    .gridPosition({
      x: 0,
      y: 20,
      rows: 2,
      cols: 15,
    } as GridPosition)
    .configDisplayName('Quick Link: Cancel')
    .isRemovable(false)
    .setGlobalStylingClass(true, `${ButtonTypes.SECONDARY} ${ButtonSizeOptions.FULL_WIDTH}`)
    .build(),
  new TextWidgetBuilder()
    .text(
      'Passwords must be greater than 8 characters having a minimum of 1 number, 1 letter and 1 special symbol.',
    )
    .configDisplayName('Password Length')
    .gridPosition({ x: 0, y: 24, rows: 3, cols: 15 } as GridPosition)
    .cssConfig({
      textAlignment: 'left',
      fontSize: '14',
      color: 'var(--typography-color-1)',
      textFont: 'var(--font-family)',
    })
    .isRemovable(false)
    .build(),
];

export const passwordChangePassWidgets = [
  new ImageWidgetBuilder()
    .configDisplayName('Image Widget: Success Icon')
    .cssConfig({
      selectedobjectfit: 'contain',
    })
    .attributes({
      widgetProperties: {
        imageAssetUrl: 'assets/images/tick-success.svg',
        textContent: '',
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .gridPosition({ y: 10, rows: 5 } as GridPosition)
    .build(),
  getTextWidgetForPaymentStatusPages({
    text: 'Password Changed Successfully',
    configDisplayName: 'Text Widget: Password Change Succeeded',
    gridPosition: { y: 16, rows: 3 },
  }),
  new QuickLinkWidgetBuilder()
    .zone(APP_ZONES.PROFILE)
    .navigateToPageUrl('settings')
    .navigationOption('use-as-navigation')
    .role()
    .text('Go Back to Settings')
    .configDisplayName('Quick Link : Go Back')
    .cssConfig({
      backGroundColor: 'var(--palette-color-3)',
      color: 'var(--palette-color-6)',
      borderRadiusTopLeft: 5,
      borderRadiusBottomLeft: 5,
      borderRadiusTopRight: 5,
      borderRadiusBottomRight: 5,
      fontSize: 16,
      marginBottom: 25,
    })
    .gridPosition({ y: 20, rows: 2 } as GridPosition)
    .setGlobalStylingClass(true, `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`)
    .build(),
];

export const enterEmailWidgets = (device: 'desktop' | 'mobile') => {
  const deviceConfig = device === 'desktop' ? enterEmailDesktopConfig : enterEmailMobileConfig;
  return [
    new TextWidgetBuilder()
      .text('Forgot Password')
      .configDisplayName('Forgot Password')
      .gridPosition(deviceConfig.forgotPass as GridPosition)
      .cssConfig({
        textAlignment: 'left',
        fontSize: '20',
        fontWeight: 700,
        color: 'var(--typography-color-0)',
        textFont: 'var(--font-family)',
      })
      .isRemovable(false)
      .build(),
    new TextWidgetBuilder()
      .text(
        'Please enter your email so we can send a link that will further redirect you to the password reset page.',
      )
      .configDisplayName('Text')
      .gridPosition(deviceConfig.enterYourEmail as GridPosition)
      .cssConfig({
        textAlignment: 'left',
        fontSize: '16',
        color: 'var(--typography-color-1)',
        textFont: 'var(--font-family)',
      })
      .isRemovable(false)
      .build(),
    getPasswordEnterEmailWidget(deviceConfig.enterEmail),
    new BackButtonWidgetBuilder()
      .defaultBackButton()
      .configDisplayName('Back Button')
      .text('Back')
      .cssConfig({
        backGroundColor: 'var(--palette-color-6)',
        color: 'var(--typography-color-1)',
        borderColor: 'var(--border-color-2)',
        borderWidth: 1,
        borderRadiusTopLeft: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusBottomRight: 5,
        fontSize: 16,
        marginBottom: 25,
      })
      .gridPosition(deviceConfig.backButton as GridPosition)
      .isRemovable(false)
      .build(),
  ];
};

export const passwordResetValidationWidgets = (device: 'desktop' | 'mobile') => {
  const deviceConfig = device === 'desktop' ? passResetDesktopConfig : passResetMobileConfig;
  return [
    new ImageWidgetBuilder()
      .configDisplayName('Image Widget: Success Icon')
      .cssConfig({
        selectedobjectfit: 'contain',
      })
      .attributes({
        widgetProperties: {
          imageAssetUrl: 'assets/images/tick-success.svg',
          textContent: '',
        } as BaseWidgetProperties,
      } as WorkflowWidgetConfigurationAttributes)
      .gridPosition(deviceConfig.imageWidget as GridPosition)
      .build(),
    new TextWidgetBuilder()
      .text('Password Changed Successfully')
      .configDisplayName('Password Changed Successfully')
      .gridPosition(deviceConfig.passChangeHeader as GridPosition)
      .cssConfig({
        textAlignment: 'center',
        fontSize: '25',
        fontWeight: 700,
        color: 'var(--typography-color-0)',
        textFont: 'var(--font-family)',
      })
      .isRemovable(false)
      .build(),
    new TextWidgetBuilder()
      .text(
        'Your password has been changed successfully. You can now securely access your account using your updated credentials.',
      )
      .configDisplayName('Success Text')
      .gridPosition(deviceConfig.passChangedTxt as GridPosition)
      .cssConfig({
        textAlignment: 'center',
        fontSize: '14',
        color: 'var(--typography-color-1)',
        textFont: 'var(--font-family)',
      })
      .isRemovable(false)
      .build(),
    new QuickLinkWidgetBuilder()
      .zone(APP_ZONES.LOGIN)
      .navigateToPageUrl('login')
      .navigationOption('use-as-navigation')
      .role()
      .text('Proceed to Login')
      .configDisplayName('Quick Link : Go to login')
      .cssConfig({
        backGroundColor: 'var(--palette-color-3)',
        color: 'var(--palette-color-6)',
        borderRadiusTopLeft: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusBottomRight: 5,
        fontSize: 16,
        marginBottom: 25,
      })
      .gridPosition(deviceConfig.proceedToLogin as GridPosition)
      .build(),
  ];
};

export const passwordForgotWidgets = (device: 'desktop' | 'mobile') => {
  const deviceConfig = device === 'desktop' ? forgotPassDesktopConfig : forgotPassMobileConfig;
  return [
    getForgotPasswordWidget(
      WidgetTitles.PASSWORD_FORGOT_WIDGET,
      'Update Password',
      deviceConfig.passForgot,
      'Update Password',
    ),
    new TextWidgetBuilder()
      .text(
        'Passwords must be greater than 8 characters having a minimum of 1 number, 1 letter and 1 special symbol.',
      )
      .configDisplayName('Password Length')
      .gridPosition(deviceConfig.passText as GridPosition)
      .cssConfig({
        textAlignment: 'left',
        fontSize: '16',
        color: 'var(--typography-color-1)',
        textFont: 'var(--font-family)',
      })
      .isRemovable(false)
      .build(),
    new QuickLinkWidgetBuilder()
      .zone(APP_ZONES.LOGIN)
      .navigateToPageUrl('login')
      .navigationOption('use-as-navigation')
      .role()
      .text('Cancel')
      .cssConfig({
        color: 'var(--palette-color-0)',
        borderWidth: '1',
        borderColor: 'var(--palette-color-0)',
        fontSize: 14,
        marginBottom: 25,
        borderRadiusTopLeft: 6,
        borderRadiusBottomLeft: 6,
        borderRadiusTopRight: 6,
        borderRadiusBottomRight: 6,
      })
      .gridPosition(deviceConfig.cancelButton as GridPosition)
      .configDisplayName('Quick Link: Cancel')
      .isRemovable(false)
      .setGlobalStylingClass(true, `${ButtonTypes.SECONDARY} ${ButtonSizeOptions.FULL_WIDTH}`)
      .build(),
  ];
};

export const passwordCheckEmailWidgets = (device: 'desktop' | 'mobile') => {
  const deviceConfig = device === 'desktop' ? checkEmailDesktopConfig : checkEmailMobileConfig;
  return [
    new ImageWidgetBuilder()
      .configDisplayName('Image Widget: Success Icon')
      .cssConfig({
        selectedobjectfit: 'contain',
      })
      .attributes({
        widgetProperties: {
          imageAssetUrl: 'assets/icon-library/envelope.svg',
          textContent: '',
        } as BaseWidgetProperties,
      } as WorkflowWidgetConfigurationAttributes)
      .gridPosition(deviceConfig.Imagewidget as GridPosition)
      .build(),
    getTextWidgetForPaymentStatusPages({
      text: 'Check your inbox',
      configDisplayName: 'Text Widget: Check your inbox',
      gridPosition: deviceConfig.checkEmail as GridPosition,
    }),
    new TextWidgetBuilder()
      .text(
        'Use the link we sent to reset your password. Can’t find it? Tap on the “Resend Link” button below.',
      )
      .configDisplayName('Text Widget: Text')
      .gridPosition(deviceConfig.Text as GridPosition)
      .cssConfig({
        textAlignment: 'center',
        fontSize: '16',
        color: 'var(--typography-color-1)',
        textFont: 'var(--font-family)',
      })
      .isRemovable(false)
      .build(),
    new ResendLinkWidgetBuilder()
      .zone(APP_ZONES.SIGNED_OUT_FORGOT_PASSWORD)
      .role()
      .text('Resend Link')
      .configDisplayName('Resend Link : Resend link')
      .cssConfig({
        backGroundColor: 'var(--palette-color-3)',
        color: 'var(--palette-color-6)',
        borderWidth: 1,
        borderRadiusTopLeft: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusBottomRight: 5,
        fontSize: 16,
      })
      .gridPosition(deviceConfig.resendLink as GridPosition)
      .isRemovable(false)
      .build(),
    new BackButtonWidgetBuilder()
      .defaultBackButton()
      .configDisplayName('Change Email')
      .text('Change Email')
      .cssConfig({
        backGroundColor: 'var(--palette-color-6)',
        color: 'var(--typography-color-1)',
        borderColor: 'var(--border-color-2)',
        borderWidth: 1,
        borderRadiusTopLeft: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusBottomRight: 5,
        fontSize: 16,
        marginBottom: 25,
      })
      .gridPosition(deviceConfig.changeEmail as GridPosition)
      .isRemovable(false)
      .build(),
  ];
};
