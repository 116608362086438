import {
  UiZoneWidgetConfig,
  WidgetNames,
  WorkflowWidgetConfig,
  is2dArray,
  isNewWorkflowWidgetConfig,
} from '@finxone-platform/shared/sys-config-types';
import { mapH1WidgetFunction } from '../display-widgets/h1.utils';
import { mapIframeWidgetFunction } from '../display-widgets/iframe.utils';
import { mapImageOrCarousalSectionsFunction } from '../display-widgets/image-carousal.utils';
import { mapLineWidgetFunction } from '../display-widgets/line.utils';
import { mapTextWidgetFunction } from '../display-widgets/text.utils';
import { mapAccountBalanceAndCardWidgetFunction } from '../widgets/account-balance-and-card.utils';
import { mapAccountMonthlyInAndOutWidgetFunction } from '../widgets/account-monthly-in-and-out.utils';
import { mapBackButtonWidgetFunction } from '../widgets/back-button.utils';
import { mapPaymentButtonWidgetFunction } from '../widgets/payment-button.utils';
import { mapProfileWidgetFunction } from '../widgets/profile.utils';
import { mapQuickLinksWidgetFunction } from '../widgets/quick-links.utils';
import { mapTextItemWidgetFunction } from '../widgets/text-item.utils';
import { mapUserManagementWidgetFunction } from '../widgets/user-management.utils';

export const mapDefaultWidgetFunction = function mapDefaultWidget(
  item: WorkflowWidgetConfig,
): UiZoneWidgetConfig {
  const nestedWidgets = Object.fromEntries(
    Object.entries(item.widgetConfiguration?.nestedWidgets ?? {}).map(([key, value]) => {
      return [key, value.attributes];
    }),
  );
  return {
    nestedWidgets: nestedWidgets,
    attributes: item.widgetConfiguration?.attributes,
    name: item.name,
    gridPosition: item.gridPosition,
    uniqueId: item.uniqueId,
    childComponents:
      isNewWorkflowWidgetConfig(item) && item.childComponents
        ? mapChildComponents(item.childComponents)
        : undefined,
  } as UiZoneWidgetConfig;
};

function mapChildComponents(childComponents: any[][] | any[]): any[][] | any[] {
  if (is2dArray(childComponents)) {
    const list = childComponents as any[][];
    return list?.map((widgets) => {
      return widgets.map((widget) => {
        const mappingFunction = widgetToMappingFunctionMap.get(widget.name) ?? mapDefaultWidgetFunction;
        return mappingFunction(widget);
      });
    });
  }
  const list = childComponents as any[];
  return list?.map((widget) => {
    const mappingFunction = widgetToMappingFunctionMap.get(widget.name) ?? mapDefaultWidgetFunction;
    return mappingFunction(widget);
  });
}

export const widgetToMappingFunctionMap = new Map<
  string | undefined | null,
  (item: WorkflowWidgetConfig) => UiZoneWidgetConfig
>([
  [undefined, mapDefaultWidgetFunction],
  [null, mapDefaultWidgetFunction],
  ['', mapDefaultWidgetFunction],
  [WidgetNames.ACCOUNT_BALANCE_WIDGET, mapAccountBalanceAndCardWidgetFunction],
  [WidgetNames.PAYMENT_BUTTON, mapPaymentButtonWidgetFunction],
  ['profile-page', mapProfileWidgetFunction],
  [WidgetNames.ACCOUNT_MONTHLY_IN_AND_OUT_WIDGET, mapAccountMonthlyInAndOutWidgetFunction],
  [WidgetNames.LINE_WIDGET, mapLineWidgetFunction],
  [WidgetNames.IFRAME_WIDGET, mapIframeWidgetFunction],
  [WidgetNames.H1_WIDGET, mapH1WidgetFunction],
  [WidgetNames.TEXT_WIDGET, mapTextWidgetFunction],
  [WidgetNames.IMAGE_WIDGET, mapImageOrCarousalSectionsFunction],
  [WidgetNames.CAROUSEL_WIDGET, mapImageOrCarousalSectionsFunction],
  [WidgetNames.QUICK_LINKS_WIDGET, mapQuickLinksWidgetFunction],
  [WidgetNames.TEXT_ITEM_WIDGET, mapTextItemWidgetFunction],
  [WidgetNames.ACCOUNT_NUMBER, mapTextItemWidgetFunction],
  [WidgetNames.SORT_CODE, mapTextItemWidgetFunction],
  [WidgetNames.IBAN_NUMBER, mapTextItemWidgetFunction],
  [WidgetNames.MULTI_ICON_QUICK_LINK_WIDGET, mapQuickLinksWidgetFunction],

  [WidgetNames.BACK_BUTTON_WIDGET, mapBackButtonWidgetFunction],
  // Onboarding widgets
  [WidgetNames.NAME_WIDGET, mapDefaultWidgetFunction],
  [WidgetNames.EMAIL_WIDGET, mapDefaultWidgetFunction],
  [WidgetNames.COMPANY_HOUSE_CHECK, mapDefaultWidgetFunction],
  [WidgetNames.PHONE_WIDGET, mapDefaultWidgetFunction],
  [WidgetNames.MOT_VERIFICATION, mapDefaultWidgetFunction],
  [WidgetNames.BIRTHDAY_WIDGET, mapDefaultWidgetFunction],
  [WidgetNames.ADDRESS_WIDGET, mapDefaultWidgetFunction],
  [WidgetNames.CUSTOM_QUESTION_WIDGET, mapDefaultWidgetFunction],
  [WidgetNames.PROGRESSBAR_WIDGET, mapImageOrCarousalSectionsFunction],

  [WidgetNames.USER_MANAGEMENT_WIDGET, mapUserManagementWidgetFunction],

  // Verification zone mostly
  [WidgetNames.CTA_BUTTON_WIDGET, mapDefaultWidgetFunction],
  [WidgetNames.SUMSUB_WIDGET, mapDefaultWidgetFunction],

  [WidgetNames.SIMPLE_BALANCE_WIDGET, mapDefaultWidgetFunction],
  [WidgetNames.BENEFICIARY_CAROUSEL, mapDefaultWidgetFunction],
]);
