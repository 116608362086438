/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  BaseWidgetProperties,
  ButtonSizeOptions,
  ButtonTypes,
  Constants,
  GridPosition,
  WidgetNames,
  WorkflowWidgetConfig,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import {
  BackButtonWidgetBuilder,
  ImageWidgetBuilder,
  TextWidgetBuilder,
} from '../../defaults/widgets/example-builder';
import {
  OtpVerificationInternationalBuilder,
  OtpVerificationWidgetBuilder,
} from '../../defaults/widgets/otp-builder';
import { defaultInputCssConfig } from '../../defaults/widgets/payments/payments.type';
const verificationPageWidgetOverridesDesktop = {
  imageWidget: {
    gridPosition: {
      x: 15,
      y: 1,
    },
  },
  textWidgetHeader: {
    gridPosition: {
      x: 15,
      y: 5,
    },
  },
  textWidgetSubheader: {
    gridPosition: {
      x: 15,
      y: 7,
    },
  },
  otpVerificationWidget: {
    gridPosition: {
      x: 15,
      y: 9,
    },
  },
  backButtonWidget: {
    gridPosition: {
      x: 15,
      y: 18,
    },
  },
};

const verificationPageWidgetOverrides = (override: any, isDesktop = false) => {
  return {
    imageWidget: {
      image: override.pageType === 'email' ? 'envelope-open' : 'mobile-vibration',
      displayName: override.pageType === 'email' ? 'Envelope Open' : 'Mobile Vibration',
      gridPosition: isDesktop
        ? verificationPageWidgetOverridesDesktop.imageWidget.gridPosition
        : {
            y: 3,
          },
    },
    textWidgetHeader: {
      title: override.pageType === 'email' ? 'Check Your Inbox!' : 'Check Your SMS!',
      fontSize: override.pageType === 'sms' ? '18px' : '24px',
      gridPosition: isDesktop
        ? verificationPageWidgetOverridesDesktop.textWidgetHeader.gridPosition
        : {
            y: 7,
            ...override?.titleTextWidget?.gridPosition,
          },
    },
    textWidgetSubheader: {
      content: `<div class="w-100"><div>We have sent you a ${
        override.pageType === 'email' ? '4' : '6'
      } digit code at:</div> <div><b>${override.textWidgetSubheader.contentKey}</b></div></div>`,
      gridPosition: isDesktop
        ? verificationPageWidgetOverridesDesktop.textWidgetSubheader.gridPosition
        : {
            y: 9,
            ...override?.textWidgetSubheader?.gridPosition,
          },
    },
    otpVerificationWidget: {
      widgetContext: {
        type: override.pageType,
        flow: override.otpVerificationWidget.flow,
      },
      gridPosition: isDesktop
        ? verificationPageWidgetOverridesDesktop.otpVerificationWidget.gridPosition
        : {
            y: 11,
            rows: 8,
            ...override?.otpVerificationWidget?.gridPosition,
          },
    },
    backButtonWidget: isDesktop
      ? verificationPageWidgetOverridesDesktop.backButtonWidget.gridPosition
      : {
          gridPosition: {
            y: 1,
            ...override?.backButtonWidget?.gridPosition,
          },
        },
  };
};

export function buildOtpVerificationPageWidgetsConfig(
  pageType: string,
  zone: string,
  isDesktop = false,
): WorkflowWidgetConfig[] {
  const widgetArray = [];
  const overrideData = createOverrideData(pageType, zone);
  const verificationPageTypeData = verificationPageWidgetOverrides(overrideData, isDesktop);
  const imageWidget = getImageWidget(verificationPageTypeData.imageWidget);
  const TextWidgetPageHeader = getPageTitleTextWidget(verificationPageTypeData.textWidgetHeader);
  const TextWidgetPageSubHeader = getPageSubheaderTextWidget(verificationPageTypeData.textWidgetSubheader);
  const otpVerificationWidget = getOTPVerificationWidget(verificationPageTypeData.otpVerificationWidget);
  const backButtonWidget = getBackButtonWidget(verificationPageTypeData.backButtonWidget);

  widgetArray.push(
    imageWidget,
    TextWidgetPageHeader,
    TextWidgetPageSubHeader,
    otpVerificationWidget,
    backButtonWidget,
  );
  if (zone === 'payment') widgetArray.splice(0, 1);
  return widgetArray;
}

export function buildOtpVerificationPageInternationalConfig(
  pageType: string,
  zone: string,
): WorkflowWidgetConfig[] {
  const widgetArray = [];
  const overrideData = createOverrideData(pageType, zone);
  const verificationPageTypeData = verificationPageWidgetOverrides(overrideData);

  const imageWidget = getImageWidget(verificationPageTypeData.imageWidget);
  const TextWidgetPageHeader = getPageTitleTextWidget(verificationPageTypeData.textWidgetHeader);
  const TextWidgetPageSubHeader = getPageSubheaderTextWidget(verificationPageTypeData.textWidgetSubheader);
  const otpVerificationWidget = getOTPVerificationInternational(
    verificationPageTypeData.otpVerificationWidget,
  );
  const backButtonWidget = getBackButtonWidget(verificationPageTypeData.backButtonWidget);

  widgetArray.push(
    imageWidget,
    TextWidgetPageHeader,
    TextWidgetPageSubHeader,
    otpVerificationWidget,
    backButtonWidget,
  );
  if (zone === 'payment') widgetArray.splice(0, 1);
  return widgetArray;
}

function createOverrideData(pageType: string, zone: string) {
  switch (zone) {
    case 'kyc':
      return {
        pageType: pageType,
        textWidgetSubheader: {
          contentKey:
            pageType === 'email'
              ? '{{ userPendingKycForm.email }}'
              : '{{ userPendingKycForm.country_code }}-{{ userPendingKycForm.phone }}',
        },
        otpVerificationWidget: {
          flow: zone,
        },
      };
    case 'payment':
      return {
        pageType: pageType,
        titleTextWidget: {
          gridPosition: { rows: 1, y: 6, x: 0 } as GridPosition,
        },
        textWidgetSubheader: {
          contentKey: '{{ profile.phoneNumber }}',
          gridPosition: { rows: 2, y: 7, x: 0 } as GridPosition,
        },
        otpVerificationWidget: {
          flow: zone,
          gridPosition: {
            y: 10,
            rows: 10,
            x: 0,
          } as GridPosition,
        },
        backButtonWidget: {
          gridPosition: {
            y: 0,
            x: 0,
          },
        },
      };
    case 'onboarding':
    case 'profile':
      return {
        pageType: pageType,
        textWidgetSubheader: {
          contentKey:
            pageType === 'email'
              ? '{{ editUserProfileForm.email }}'
              : '{{ editUserProfileForm.country_code }}-{{ editUserProfileForm.phone }}',
        },
        otpVerificationWidget: {
          flow: zone,
        },
      };

    default:
      return null;
  }
}

export function getOtpVerificationPageWidgetsConfig(): WorkflowWidgetConfig[] {
  getImageWidget({
    image: 'envelope-open',
    displayName: 'Envelope Open',
    gridPosition: {
      y: 1,
    },
  });
  getPageTitleTextWidget({
    title: 'Check Your Inbox!',
    gridPosition: {
      y: 5,
    },
  });
  getPageSubheaderTextWidget({
    content: `<div class="w-100"><div>We have sent you a 4 digit code at:</div> <div><b>{{ formSubmissionFormData.email }}</b></div></div>`,
    gridPosition: {
      y: 7,
    },
  });
  getOTPVerificationWidget({
    widgetContext: { type: 'email', flow: 'profile' },
    gridPosition: {
      y: 9,
      rows: 8,
    },
  });
  getBackButtonWidget({
    gridPosition: {
      y: 18,
    },
  });
  return [];
}

export function getPageTitleTextWidget(override?: any): WorkflowWidgetConfig {
  const fontSize = override?.fontSize || '24px';

  return new TextWidgetBuilder()
    .text(`<div class="w-100">${override?.title ? override.title : 'Edit Information'}</div>`)
    .configDisplayName(`Text Widget: ${override?.title ? override.title : 'Edit Information'}`)
    .gridPosition({ y: 0, ...override?.gridPosition } as GridPosition)
    .cssConfig({
      color: 'var(--typography-color-0)',
      fontSize: fontSize, // Apply font size
      textAlignment: 'left',
      isTextBold: true,
    })
    .isRemovable(false)
    .build();
}

export function getPageSubheaderTextWidget(override?: any): WorkflowWidgetConfig {
  return new TextWidgetBuilder()
    .text(
      `${
        override?.content
          ? override.content
          : '<div class="w-100">Edit your information based on the previous onboarding steps</div>'
      }`,
    )
    .configDisplayName('Text Widget: Info Text')
    .gridPosition({ y: 2, rows: 2, ...override?.gridPosition } as GridPosition)
    .cssConfig({
      color: 'var(--typography-color-1)',
      fontSize: '16px',
      textAlignment: 'left',
    })
    .isRemovable(false)
    .build();
}

export function getImageWidget(override?: any): WorkflowWidgetConfig {
  return new ImageWidgetBuilder()
    .configDisplayName(`Image Widget: ${override.displayName}`)
    .cssConfig({
      selectedobjectfit: 'contain',
    })
    .gridPosition({
      y: 1,
      rows: 4,
      cols: 4,
      x: 1,
      ...override?.gridPosition,
    } as GridPosition)
    .attributes({
      widgetProperties: {
        textContent: '',
        imageAssetUrl: `assets/images/${override?.image}.svg`,
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .build();
}

function setupNestedButtons(): Record<string, any> {
  return {
    resendButton: {
      name: WidgetNames.BUTTON,
      widgetConfigDisplayName: 'Resend Button Styling',
      attributes: {
        widgetProperties: {
          textContent: 'Resend Code',
        },
        elementsConfig: [],
        css_config: {
          ...Constants.defaultUiNestedButtonCssConfig,
          backGroundColor: 'transparent',
          color: 'var(--palette-color-3)',
          textAlignment: 'center',
          isTextBold: true,
          isTextUnderline: true,
        },
      },
    },
    verifyButton: {
      name: WidgetNames.BUTTON,
      widgetConfigDisplayName: 'Verify Button Styling',
      attributes: {
        widgetProperties: {
          textContent: 'Verify',
          globalStyling: true,
          globalStylingClass: `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`,
        },
        elementsConfig: [],
        css_config: {
          ...Constants.defaultUiNestedButtonCssConfig,
        },
      },
    },
    timerContent: {
      name: WidgetNames.TIMER_BUTTON,
      widgetConfigDisplayName: 'Timer Content Styling',
      attributes: {
        widgetProperties: {
          textContent: '',
        },
        elementsConfig: [],
        css_config: {
          ...Constants.defaultUiNestedButtonCssConfig,
          backGroundColor: 'transparent',
          color: 'var(--palette-color-3)',
          textAlignment: 'center',
          isTextBold: true,
          isTextUnderline: false,
          borderWidth: 0,
          borderStyle: 'solid',
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
  };
}

export function getOTPVerificationWidget(override: any): WorkflowWidgetConfig {
  return new OtpVerificationWidgetBuilder()
    .setWidgetContext(override.widgetContext.type, override.widgetContext.flow)
    .inputSetting({
      label: '',
      isTimerSeparated: true,
      css_config: {
        ...defaultInputCssConfig,
        inputWidth: 98,
      },
    })
    .cssConfig({
      marginLeft: 1,
      marginRight: 1,
    })
    .nestedWidgets({
      ...setupNestedButtons(),
    })
    .gridPosition({
      y: 7,
      rows: 8,
      ...override?.gridPosition,
    } as GridPosition)
    .build();
}

export function getOTPVerificationInternational(override: any): WorkflowWidgetConfig {
  return new OtpVerificationInternationalBuilder()
    .setWidgetContext(override.widgetContext.type, override.widgetContext.flow)
    .inputSetting({
      label: '',
      isTimerSeparated: true,
      css_config: {
        ...defaultInputCssConfig,
        inputWidth: 98,
      },
    })
    .cssConfig({
      marginLeft: 1,
      marginRight: 1,
    })
    .nestedWidgets({
      ...setupNestedButtons(),
    })
    .gridPosition({
      y: 7,
      rows: 8,
      ...override?.gridPosition,
    } as GridPosition)
    .build();
}

export function getBackButtonWidget(override?: any): WorkflowWidgetConfig {
  return new BackButtonWidgetBuilder()
    .gridPosition({
      y: 1,
      rows: 1,
      cols: 1,
      ...override?.gridPosition,
    } as GridPosition)
    .cssConfig({
      backGroundColor: 'transparent',
      color: 'var(--palette-color-3)',
    })
    .isRemovable(false)
    .build();
}
