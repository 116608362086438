import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { tap } from 'rxjs';
import { ResetSelectedClient } from '../../../actions/fee-management.action';
import { FeeManagementService } from '../../../services/fee-management-service/fee-management.service';
import { FeeManagementState } from '../../../state/fee-management.state';
import { redirectToPage } from '../cta-button-actions.utils';

/**
 * Handles the removal of a client from a fee group.
 *
 * This function retrieves the necessary services and state using the provided injector.
 * It checks if the selected client details are available, and if so, attempts to remove
 * the client from the fee group using the FeeManagementService. Upon successful removal,
 * it dispatches an action to reset the selected client and redirects to a specified page.
 * Alerts are shown to indicate success or failure of the operation.
 *
 * @param widgetProperties - The properties of the widget, used for navigation.
 * @param injector - The injector used to retrieve necessary services and state.
 */
export function handleRemoveClientFromFeeGroupRequest(
  widgetProperties: BaseWidgetProperties,
  injector: Injector,
) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  const alertHandlerService = injector.get(AlertHandlerService);
  const feeManagementService = injector.get(FeeManagementService);
  const selectedClientDetails = store.selectSnapshot(
    FeeManagementState.getFeeManagement,
  )?.selectedClientDetails;
  if (selectedClientDetails?.id) {
    feeManagementService
      .removeClientFromFeeGroup(selectedClientDetails.feeGroupId, selectedClientDetails.id)
      .pipe(
        tap((response) => {
          if (response) {
            alertHandlerService.showAlertFn('success', 'Client removed from fee group successfully');
            store.dispatch(new ResetSelectedClient());
            redirectToPage(router, widgetProperties, injector);
          } else {
            alertHandlerService.showAlertFn('error', 'An unexpected error occurred, please try again');
          }
        }),
      )
      .subscribe();
  }
}
