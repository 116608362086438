import { FormActionTypeEnum } from '@finxone-platform/form-action';
import { APP_ZONES, ButtonActionType } from '@finxone-platform/shared/sys-config-types';
import { getTripInformationExitWidget } from '../../../../widgets/workflow-ui-constants.v2.type';
import { commonCTAButton, commonTextWidget } from '../../../common/common-widgets';
import { CommonTripZoneWidgets } from './common-widgets';

export const tripZoneExitPointWidgets = [
  ...new CommonTripZoneWidgets().commonExitPointWidgets,

  commonTextWidget({
    textContent:
      '<span class="text-capitalize">Exit point from {{ tripZoneFormState.leaving_country}}</span>',
    cssConfig: {
      textAlignment: 'left',
      fontSize: 24,
      isTextBold: true,
      textFont: 'var(--font-family)',
    },
    gridPosition: {
      y: 3,
      x: 2,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 13,
    },
  }),

  getTripInformationExitWidget(),

  commonCTAButton({
    zoneToNavigate: APP_ZONES.REVENIR_ZONE,
    urlToNavigate: 'review-details',
    textContent: 'Continue',
    buttonActionType: ButtonActionType.TRIP_EXIT_POINT_CONTINUE,
    formKey: FormActionTypeEnum.ADD_TRIP_REVENIR_ZONE,
  }),
];
