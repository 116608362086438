import { APP_ZONES, WorkflowWidgetConfig } from '@finxone-platform/shared/sys-config-types';
import { TextWidgetBuilder } from '../../widgets/example-builder';
import { landingAccountsListingWidgetConfig } from '../../widgets/workflow-ui-constants.v2.type';

export const defaultLandingPages: {
  name: string;
  title: string;
  url?: string;
  widgets: WorkflowWidgetConfig[];
  tags: string[];
}[] = [
  {
    name: APP_ZONES.LANDING,
    title: 'Homepage',
    url: 'home',
    widgets: [],
    tags: [],
  },
  {
    name: 'landing-select-account',
    title: 'Select Account',
    url: 'select-account',
    widgets: [
      new TextWidgetBuilder()
        .text(
          `<h1>
          {{#if (isAfterAndBefore "00:00:00" "12:00:00") }} Morning {{/if}}
          {{#if (isAfterAndBefore "12:00:00" "17:00:00") }} Afternoon {{/if}}
          {{#if (isAfterAndBefore "17:00:00" "23:59:59") }} Evening {{/if}}
          <b>{{profile.firstName}},</b>
          </h1>`,
        )
        .configDisplayName('Greeting')
        .cssConfig({
          textAlignment: 'left',
          justifyContent: 'left',
          fontSize: '12',
        })
        .isRemovable(true)
        .build(),

      landingAccountsListingWidgetConfig,
    ],
    tags: [],
  },
];
