import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonActionType,
  GridPosition,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import { FormSubmissionButtonWidgetBuilder, LineWidgetBuilder } from '../../../widgets/example-builder';
import {
  backCTAForBeneficiary,
  bulkPaymentFooter,
  generateBulkPaymentListing,
  generateTextWidget,
  getHeaderWidget,
  getQuickLinkWidget,
} from '../common-widgets-utils';
import { prePaymentCounters } from '../utils/bulk-payment-util';
import { getBulkPaymentListingColumns } from './utils';

export const bulkPaymentPrePaymentSummaryWidgets = [...bulkBenReviewHeader(), ...bulkPaymentFooter(24)];
function bulkBenReviewHeader() {
  return [
    ...getHeaderWidget(
      '<h3>CSV File Processed</h3>',
      '<p>Please review and proceed with payment for the uploaded recipient list.</p>',
    ),
    ...backCTAForBeneficiary,
    new FormSubmissionButtonWidgetBuilder()
      .gridPosition({ x: 37, y: 1, rows: 1, cols: 6 } as GridPosition)
      .cssConfig({
        fontSize: 14,
        verticalAlignment: 'center',
        textAlignment: 'center',
        disabledBgColor: 'var(--palette-color-3)',
        disabledTextColor: 'var(--palette-color-6)',
      })
      .attributes({
        widgetProperties: {
          textContent: 'Proceed to make payment',
          buttonActionType: ButtonActionType.BULK_PAYMENT_MAKE_PAYMENT,
          zoneToNavigate: APP_ZONES.PAYMENT,
          urlToNavigate: 'bulk-payment-authentication-sms',
        } as BaseWidgetProperties,
      } as WorkflowWidgetConfigurationAttributes)
      .build(),
    generateTextWidget({
      text: '<p>Please carefully review the payment details before proceeding with execution. The beneficiaries have been added to your list. Once payments are initiated, they cannot be reversed. If any modifications are needed, you`ll need to upload a new  CSV file.</p>',
      position: { x: 0, y: 3, rows: 2, cols: 45 } as GridPosition,
      displayName: 'Text Widget: Counters',
      styles: {
        fontSize: 16,
        color: 'var(--palette-color-0)',
        textAlignment: 'left',
      },
      isRemovable: true,
    }),
    getQuickLinkWidget({
      text: 'Upload New File',
      position: { x: 0, y: 5, rows: 1, cols: 10 } as GridPosition,
      pageUrl: 'bulk-payment-upload-download',
      displayName: 'Quick Link: Upload New File',
      styles: {
        color: 'var(--palette-color-3)',
        fontSize: '16',
        backGroundColor: 'transparent',
        textAlignment: 'center',
        justifyContent: 'center',
        borderColor: 'var(--palette-color-3)',
        borderStyle: 'solid',
        borderWidth: 1,
      },
      zone: APP_ZONES.PAYMENT,
      isRemovable: false,
      icon: 'upload',
    }),
    new LineWidgetBuilder()
      .configDisplayName('Line Widget : Line')
      .gridPosition({ x: 0, y: 6, rows: 1, cols: 45 } as GridPosition)
      .attributes({
        widgetProperties: {
          color: 'var(--border-color-1)',
          'border-bottom': 'var(--border-color-1)',
          lineThickness: 1,
          lineWidth: '100',
          textContent: '',
          show: true,
          contentAlignment: 'center',
        } as BaseWidgetProperties,
      } as WorkflowWidgetConfigurationAttributes)
      .build(),
    generateTextWidget({
      text: '<h4>Pre Payment Summary</h4>',
      position: { x: 0, y: 7, rows: 1, cols: 25 } as GridPosition,
      displayName: 'Text Widget: Pre Payment Summary ',
      styles: {
        fontSize: 20,
        isTextBold: true,
        textAlignment: 'left',
      },
      isRemovable: true,
    }),
    generateTextWidget({
      text: '<p>Please review and proceed with payment for the uploaded recipient list.</p>',
      position: { x: 0, y: 8, rows: 1, cols: 20 } as GridPosition,
      displayName: 'Text Widget:Summary Sub header',
      styles: {
        fontSize: 16,
        color: 'var(--palette-color-1)',
        textAlignment: 'left',
      },
      isRemovable: true,
    }),
    generateTextWidget({
      text: prePaymentCounters,
      position: { x: 0, y: 9, rows: 3, cols: 45 } as GridPosition,
      displayName: 'Text Widget: Counters',
      styles: {
        fontSize: 20,
        color: 'var(--palette-color-1)',
        textAlignment: 'center',
        verticalAlignment: 'center',
        backGroundColor: '#F2F4F7',
        paddingLeft: 10,
        paddingRight: 10,
      },
      isRemovable: true,
    }),
    generateBulkPaymentListing({
      text: 'Bulk Payment Listing',
      position: { x: 0, y: 13, rows: 11, cols: 45 } as GridPosition,
      displayName: 'Bulk Payment Listing',
      styles: {
        color: 'var(--palette-color-1)',
        textAlignment: 'left',
        justifyContent: 'left',
        fontSize: '16',
        borderWidth: 1,
        borderColor: 'var(--palette-color-2)',
        backGroundColor: 'var(--palette-color-6)',
        borderStyle: 'solid',
        borderRadiusUnit: 'px',
        borderRadiusTopLeft: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusBottomRight: 5,
      },
      isRemovable: false,
      columns: getBulkPaymentListingColumns({
        label: 'Payment Reason',
        value: `transactionPaymentReason`,
        handleBarKey: '{{ transactionPaymentReason}}',
        selected: true,
      }),
    }),
  ];
}
