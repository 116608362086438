import { Injectable, signal } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class CtaButtonSignalService {
  private _buttonDisabled = signal<any[]>([]);
  private _buttonHide = signal<any[]>([]);
  public buttonDisabled = this._buttonDisabled;
  public buttonHide = this._buttonHide;

  setSignal(newValue: any): void {
    /* false = disabled, true = enabled
    e.g. this.ctaButtonSignalService.setSignal({ [ButtonActionType.EXCHANGE_RE_QUOTE]: false }); */

    const index = this._buttonDisabled().findIndex((button) => Object.keys(newValue)[0] in button);
    if (index > -1) {
      this._buttonDisabled().splice(index, 1);
    }
    this._buttonDisabled.update((val) => [...val, newValue]);
  }

  setHideButtonSignal(newValue: any): void {
    const index = this._buttonHide().findIndex((button) => Object.keys(newValue)[0] in button);
    if (index > -1) {
      this._buttonHide().splice(index, 1);
    }
    this._buttonHide.update((val) => [...val, newValue]);
  }
}
