import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonActionType,
  ButtonSizeOptions,
  ButtonTypes,
  ConditionEnum,
  ConditionalWidgetVariableEnum,
  DummyRole,
  GridPosition,
  KycLevel,
  KycStatus,
  NavigationOptionEnum,
  WidgetNames,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import { ConditionalWidgetBuilder, FormSubmissionButtonWidgetBuilder } from '../example-builder';
import {
  statusScreenCommonCssConfigConditionalWidget,
  statusScreenHeaderTextWidget,
  sumsubVerificationPersonalConditionalWidget,
  verificationReviewInformationCTAButtonCssConfig,
} from './common-widgets';

const businessOnboardingConditionalWidget = ({ gridPosition }: { gridPosition?: GridPosition }) =>
  new ConditionalWidgetBuilder()
    .isRemovable(true)
    .configDisplayName('Conditional Widget - Business Onboarding')
    .nestedWidgets({
      elseCondition: {
        name: WidgetNames.CONDITIONAL_WIDGET_IF_ELSE_CONFIG,
        widgetConfigDisplayName: 'Failure condition',
        isRemovable: true,
        attributes: {
          widgetProperties: {
            textContent: '<h4>Business Onboarding</h4><span class="fail">Completion required</span>',
            righticon: 'arrow-right',
            lefticon: 'briefcase-icon',
            isTextEditable: true,
          },
          elementsConfig: [],
          role: 'organisation-anonymous',
          navigationOption: NavigationOptionEnum.USE_AS_NAVIGATION,
          zone: APP_ZONES.ORGANISATION_ONBOARDING,
          pageUrl: `onboarding`,
          css_config: statusScreenCommonCssConfigConditionalWidget,
        },
      },
      ifCondition: {
        name: WidgetNames.CONDITIONAL_WIDGET_IF_ELSE_CONFIG,
        widgetConfigDisplayName: 'Success condition',
        isRemovable: true,
        attributes: {
          elementsConfig: [],
          role: DummyRole.EMPTY_ROLE,
          navigationOption: NavigationOptionEnum.USE_AS_BLANK,
          zone: '',
          pageUrl: ``,
          css_config: statusScreenCommonCssConfigConditionalWidget,
          widgetProperties: {
            textContent: '<h4>Business Onboarding</h4><span class="success">Completed</span>',
            righticon: '',
            lefticon: 'briefcase-icon',
            isTextEditable: true,
          },
        },
      },
    })
    .attributes({
      selectedVariable: ConditionalWidgetVariableEnum.BUSINESS_ONBOARDING,
      selectedCondition: ConditionEnum.GREATER_THAN,
      selectedValue: 0,
    } as unknown as WorkflowWidgetConfigurationAttributes)
    .gridPosition(
      gridPosition ??
        ({
          y: 8,
          x: 0,
          rows: 3,
          cols: 15,
        } as GridPosition),
    )
    .build();

const organisationVerificationConditionalWidget = ({ gridPosition }: { gridPosition?: GridPosition }) =>
  new ConditionalWidgetBuilder()
    .isRemovable(true)
    .configDisplayName('Conditional Widget - Submsub Verification - Business')
    .nestedWidgets({
      elseCondition: {
        name: WidgetNames.CONDITIONAL_WIDGET_IF_ELSE_CONFIG,
        widgetConfigDisplayName: 'Failure condition',
        isRemovable: true,
        attributes: {
          widgetProperties: {
            textContent:
              '<h4>Sumsub Verification (Business)</h4><span class="fail">Completion required</span>',
            righticon: 'arrow-right',
            lefticon: 'playlist-icon',
            isTextEditable: true,
          },
          elementsConfig: [],
          css_config: statusScreenCommonCssConfigConditionalWidget,
          role: 'organisation-anonymous',
          navigationOption: NavigationOptionEnum.USE_AS_NAVIGATION,
          zone: APP_ZONES.ORGANISATION_VERIFICATION,
          pageUrl: `sumsub-workflow/organisation-anonymous/default?type=${KycLevel.BUSINESS}`,
        },
      },
      ifCondition: {
        name: WidgetNames.CONDITIONAL_WIDGET_IF_ELSE_CONFIG,
        widgetConfigDisplayName: 'Success condition',
        isRemovable: true,
        attributes: {
          elementsConfig: [],
          css_config: statusScreenCommonCssConfigConditionalWidget,
          role: DummyRole.EMPTY_ROLE,
          navigationOption: NavigationOptionEnum.USE_AS_BLANK,
          zone: '',
          pageUrl: ``,
          widgetProperties: {
            textContent: '<h4>Sumsub Verification (Business)</h4><span class="success">Completed</span>',
            righticon: '',
            lefticon: 'playlist-icon',
            isTextEditable: true,
          },
        },
      },
    })
    .attributes({
      selectedVariable: ConditionalWidgetVariableEnum.KYB_BUSINESS,
      selectedCondition: ConditionEnum.EQUAL,
      selectedValue: KycStatus.SUCCESS,
    } as unknown as WorkflowWidgetConfigurationAttributes)
    .gridPosition(
      gridPosition ??
        ({
          y: 12,
          x: 0,
          rows: 3,
          cols: 15,
        } as GridPosition),
    )
    .build();

const formSubmissionCTAButton = ({ gridPosition }: { gridPosition?: GridPosition }) =>
  new FormSubmissionButtonWidgetBuilder()
    .configDisplayName('Button: Review Information - Business')
    .attributes({
      widgetProperties: {
        textContent: 'Review Information - Business',
        zoneToNavigate: APP_ZONES.ORGANISATION_VERIFICATION,
        urlToNavigate: 'review-information/organisation-anonymous/default',
        buttonActionType: ButtonActionType.REVIEW_INFORMATION_VERIFICATION_BUSINESS,
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .cssConfig(verificationReviewInformationCTAButtonCssConfig)
    .isRemovable(false)
    .gridPosition(gridPosition ?? ({ x: 0, y: 23, rows: 2, cols: 15 } as GridPosition))
    .setGlobalStylingClass(true, `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`)
    .build();

export const businessStatusScreen = [
  statusScreenHeaderTextWidget({}),

  businessOnboardingConditionalWidget({}),

  organisationVerificationConditionalWidget({}),

  formSubmissionCTAButton({}),
];

export const businessStatusScreenDesktop = [
  statusScreenHeaderTextWidget({
    gridPosition: {
      y: 1,
      x: 13,
      rows: 2,
      cols: 15,
    } as GridPosition,
  }),

  sumsubVerificationPersonalConditionalWidget({
    gridPosition: {
      y: 4,
      x: 13,
      rows: 3,
      cols: 15,
    } as GridPosition,
  }),

  businessOnboardingConditionalWidget({
    gridPosition: {
      y: 8,
      x: 13,
      rows: 3,
      cols: 15,
    } as GridPosition,
  }),

  organisationVerificationConditionalWidget({
    gridPosition: {
      y: 12,
      x: 13,
      rows: 3,
      cols: 15,
    } as GridPosition,
  }),

  formSubmissionCTAButton({
    gridPosition: { x: 13, y: 20, rows: 2, cols: 15 } as GridPosition,
  }),
];

export const businessStatusScreenTablet = [
  statusScreenHeaderTextWidget({
    gridPosition: {
      y: 1,
      x: 2,
      rows: 2,
      cols: 15,
    } as GridPosition,
  }),

  businessOnboardingConditionalWidget({
    gridPosition: {
      y: 8,
      x: 2,
      rows: 2,
      cols: 15,
    } as GridPosition,
  }),

  organisationVerificationConditionalWidget({
    gridPosition: {
      y: 12,
      x: 2,
      rows: 2,
      cols: 15,
    } as GridPosition,
  }),

  formSubmissionCTAButton({
    gridPosition: { x: 2, y: 20, rows: 2, cols: 15 } as GridPosition,
  }),
];
