import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { FormActionTypeEnum, SEPAPaymentRailTypeEnum } from '@finxone-platform/form-action';
import {
  APP_ZONES,
  BaseWidgetProperties,
  PaymentStatusScreen,
} from '@finxone-platform/shared/sys-config-types';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { map, take } from 'rxjs';
import { ClearFormDataActionWithId, UpdateFormDataAction } from '../../../actions/form-submission.action';
import {
  CreatePaymentRequest,
  PaymentRequestFailed,
  PaymentRequestSuccess,
} from '../../../actions/payment-request.action';
import { paymentRailOptions } from '../../../services/account-service/account.type';
import { AccountState } from '../../../state/account.state';
import { FormActionState } from '../../../state/form-submision.state';
import { redirectToPage } from '../cta-button-actions.utils';

/**
 * Processes a payment request by dispatching actions to create a payment request
 * and update form data. It navigates to different pages based on the payment
 * request's success or failure status. The function utilizes the provided
 * widget properties and injector to interact with the store, actions, and router.
 *
 * @param widgetProperties - The properties of the widget, including navigation details.
 * @param injector - The Angular injector used to retrieve services like Store, Actions, and Router.
 */
export function paymentRequestProcessing(widgetProperties: BaseWidgetProperties, injector: Injector) {
  const store = injector.get(Store);
  const action = injector.get(Actions);
  const router = injector.get(Router);
  const formState = store.selectSnapshot(FormActionState.getFormActionState);
  const activeAccountId = store.selectSnapshot(AccountState.getCurrentAccountId);
  if (!widgetProperties['zoneToNavigate']) {
    widgetProperties['zoneToNavigate'] = APP_ZONES.PAYMENT;
  }
  const parsedWidgetProperties = JSON.parse(JSON.stringify(widgetProperties));

  if (formState?.response?.formData) {
    parsedWidgetProperties['urlToNavigate'] = PaymentStatusScreen.PAYMENT_PROCESSING;
    redirectToPage(router, parsedWidgetProperties, injector, true);

    const sepaFormState = store.selectSnapshot(
      FormActionState.getFormActionStateWithId(FormActionTypeEnum.SEPA_PAYMENT),
    )?.formData;

    store.dispatch(
      new CreatePaymentRequest({
        ...formState?.response?.formData,
        scheme: paymentRailOptions[sepaFormState?.paymentRail as SEPAPaymentRailTypeEnum],
        paymentRailOption: sepaFormState?.paymentRail,
        sourceAccountId: activeAccountId,
      }),
    );
    store.dispatch(
      new UpdateFormDataAction({
        transferDate: `${new Date()}`,
      }),
    );

    action
      .pipe(
        ofActionDispatched(PaymentRequestSuccess),
        take(1),
        map(() => {
          store.dispatch(new ClearFormDataActionWithId(FormActionTypeEnum.SEPA_PAYMENT));
          store.dispatch(new ClearFormDataActionWithId(FormActionTypeEnum.SEPA_PAYMENT_RAIL_FLAG));
          parsedWidgetProperties['urlToNavigate'] = PaymentStatusScreen.PAYMENT_REQUEST_SENT;
          redirectToPage(router, parsedWidgetProperties, injector, true);
        }),
      )
      .subscribe();

    action
      .pipe(
        ofActionDispatched(PaymentRequestFailed),
        take(1),
        map(() => {
          store.dispatch(new ClearFormDataActionWithId(FormActionTypeEnum.SEPA_PAYMENT));
          store.dispatch(new ClearFormDataActionWithId(FormActionTypeEnum.SEPA_PAYMENT_RAIL_FLAG));
          parsedWidgetProperties['urlToNavigate'] = PaymentStatusScreen.PAYMENT_REQUEST_FAILED;
          redirectToPage(router, parsedWidgetProperties, injector, true);
        }),
      )
      .subscribe();
  }
}
