import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import {
  APP_ZONES,
  BaseWidgetProperties,
  UiZoneWidgetAttributeConfig,
} from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { take, tap } from 'rxjs';
import { CancelCardAction } from '../../../actions/card-management.action';
import { GetCards } from '../../../actions/card.actions';
import { CardState } from '../../../state/card.state';
import { redirectToPage } from '../cta-button-actions.utils';

/**
 * Handles the cancellation of a card by dispatching a CancelCardAction
 * with the current card ID. If the card ID is missing, an error alert
 * is shown. Upon successful cancellation, redirects to the 'card-cancelled'
 * page using the provided router and widget properties.
 *
 * @param injector - The Angular Injector used to retrieve necessary services.
 */
export function onCancelCard(injector: Injector): void {
  const store = injector.get(Store);
  const alertHandlerService = injector.get(AlertHandlerService);
  const router = injector.get(Router);
  const cardId = store.selectSnapshot(CardState.getCurrentCardId);
  const mockWidgetProperties: BaseWidgetProperties = {
    textContent: '',
    zoneToNavigate: APP_ZONES.CARD_MANAGEMENT_ZONE,
    urlToNavigate: '',
  };
  if (!cardId) {
    alertHandlerService.showAlertFn('error', 'Card information is missing.');
    return;
  }
  store
    .dispatch(new CancelCardAction(cardId))
    .pipe(
      take(1),
      tap({
        next: () => {
          mockWidgetProperties['urlToNavigate'] = `card-cancelled`;
          redirectToPage(router, mockWidgetProperties, injector);
        },
        error: (error) => {},
      }),
    )
    .subscribe();
}

/**
 * Refreshes the list of cards by dispatching the GetCards action and then redirects
 * the user to a specified page based on the provided widget attributes.
 *
 * @param injector - The Angular Injector used to retrieve necessary services.
 * @param attri - Configuration attributes for the UI zone widget, including
 *                properties for navigation.
 */
export function refreshCardsAndRedirect(injector: Injector, attri: UiZoneWidgetAttributeConfig) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  store.dispatch(new GetCards());
  redirectToPage(router, attri.widgetProperties, injector);
}
