import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { KeycloakEvent, KeycloakEventType, KeycloakOptions, KeycloakService } from 'keycloak-angular';
import { Subject, filter, firstValueFrom } from 'rxjs';
import { IS_EXTERNAL_KEYCLOAK } from '../../../lib/http-constants';
import { UpdateCurrentAccountId } from '../../actions/account.action';
import { clearLocalStorageExcept } from '../../utils/utils';
import { Config } from '../config-service/config.type';

@Injectable({
  providedIn: 'root',
})
export class KeycloakWrapperService implements OnDestroy {
  private tabCloseInterval: ReturnType<typeof setInterval>;
  constructor(
    private keycloakService: KeycloakService,
    private http: HttpClient,
    private readonly store: Store,
  ) {
    keycloakService.keycloakEvents$
      .pipe(filter((event) => event.type === KeycloakEventType.OnTokenExpired))
      .subscribe(() => {
        console.log('expired token ' + new Date());
        keycloakService
          .updateToken(5)
          .then((refreshed) => {
            if (refreshed) {
              console.log('refreshed ' + new Date());
              const token = keycloakService.getKeycloakInstance().token;
              if (token) localStorage.setItem('token', token);
              const refreshToken = keycloakService.getKeycloakInstance().refreshToken;
              if (refreshToken) localStorage.setItem('refreshToken', refreshToken);
            } else {
              console.error('Failed to refresh access token ' + new Date());
            }
          })
          .catch(() => {
            console.error('Failed to refresh access token ' + new Date());
          });
      });
    // Set up visibility change handling
    if (typeof document !== 'undefined') {
      // Check if we need to clear currentAccountId on page load
      const lastActiveTime = localStorage.getItem('lastActiveTime');
      const isWebView = this.isWebView();

      // Only clear if:
      // 1. Not in WebView, or
      // 2. In WebView but token is expired (real session end)
      if (lastActiveTime && !isWebView) {
        const timeDiff = Date.now() - parseInt(lastActiveTime);
        // If it's been more than 5 seconds since last activity, clear currentAccountId

        if (timeDiff > 5000) {
          this.clearCurrentAccountIdFromAccountState();
        }
      }

      // Update last active time when page becomes visible
      document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'visible') {
          localStorage.setItem('lastActiveTime', Date.now().toString());
        }
      });

      // Update last active time periodically while page is visible
      const updateLastActiveTime = () => {
        if (document.visibilityState === 'visible') {
          localStorage.setItem('lastActiveTime', Date.now().toString());
        }
      };
      this.tabCloseInterval = setInterval(updateLastActiveTime, 5000);
    }
  }

  ngOnDestroy(): void {
    if (this.tabCloseInterval) {
      clearInterval(this.tabCloseInterval);
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    document.removeEventListener('visibilitychange', () => {});
  }

  public idleTimeLogout() {
    console.log(`KeycloakWrapperService idleTimeLogout: clearLocalStorageExcept`);
    this.clearCurrentAccountIdFromAccountState();
    clearLocalStorageExcept([
      'account',
      'transactions',
      'beneficiary',
      'notifications',
      'lastLoggedInId',
      'APP_PREFERENCES',
      'firstTime',
    ]);
    this.keycloakService.logout(window.location.origin + '/auth');
  }

  public login(options?: Keycloak.KeycloakLoginOptions, additionalParams?: KeycloakLoginParams) {
    return this.keycloakService.login(options);
  }

  public loginLink(options?: Keycloak.KeycloakLoginOptions, additionalParams?: KeycloakLoginParams) {
    navigateToKeycloakLogin(
      {
        baseUrl: this.keycloakService.getKeycloakInstance().authServerUrl ?? '',
        realm: this.keycloakService.getKeycloakInstance().realm ?? '',
        clientId: this.keycloakService.getKeycloakInstance().clientId ?? '',
        redirectUri: options?.redirectUri,
      },
      additionalParams,
    );
  }

  public init(options?: KeycloakOptions): Promise<boolean> {
    return this.keycloakService.init(options);
  }

  public get keycloakEvents$(): Subject<KeycloakEvent> {
    return this.keycloakService.keycloakEvents$;
  }

  public updateToken(minValidity?: number | undefined): Promise<boolean> {
    return this.keycloakService.updateToken(minValidity).then((refreshed) => {
      if (refreshed) {
        console.log('refreshed ' + new Date());
        const token = this.getKeycloakInstance().token;
        if (token) localStorage.setItem('token', token);
        const refreshToken = this.getKeycloakInstance().refreshToken;
        if (refreshToken) localStorage.setItem('refreshToken', refreshToken);
        return refreshed;
      } else {
        console.error('Failed to refresh access token ' + new Date());
        return refreshed;
      }
    });
  }

  public getToken(): Promise<string> {
    return this.keycloakService.getToken();
  }

  public logout(redirectUri?: string | undefined): Promise<void> {
    console.log(`KeycloakWrapperService logout: clearLocalStorageExcept`);
    this.clearCurrentAccountIdFromAccountState();
    clearLocalStorageExcept([
      'account',
      'transactions',
      'beneficiary',
      'notifications',
      'lastLoggedInId',
      'APP_PREFERENCES',
      'firstTime',
    ]);
    return this.keycloakService.logout(redirectUri);
  }

  public refreshInit(config: Config) {
    const baseConfig = this.getBaseKeycloakConfig(config);
    const options = {
      ...baseConfig,
      initOptions: {
        ...baseConfig.initOptions,
        token: (window as any).accessToken || undefined,
        refreshToken: (window as any).refreshToken || undefined,
        idToken: (window as any).idToken || undefined,
      },
    };
    this.keycloakService.init(options);
  }

  public getBaseKeycloakConfig(config: Config): KeycloakOptions {
    return {
      config: {
        url: config.api_endpoints['auth_service'],
        realm: config.auth.realm,
        clientId: config.auth.client_id,
      },
      initOptions: {
        enableLogging: false,
        flow: 'standard',
        checkLoginIframe: false,
      },
      shouldAddToken: (request: any) => {
        const { url } = request;
        const acceptablePaths = ['/assets', '/clients/public', '.svg'];
        const isAcceptablePathMatch = acceptablePaths.some((path) => url.includes(path));
        return !isAcceptablePathMatch;
      },
      updateMinValidity: 60,
      shouldUpdateToken(request: any) {
        return true;
      },
    };
  }

  private clearCurrentAccountIdFromAccountState() {
    this.store.dispatch(new UpdateCurrentAccountId(''));
  }

  private isWebView(): boolean {
    // Check for flutter WebView indicators
    const flutterWebView = (window as any).flutter_inappwebview;
    if (flutterWebView) {
      return true;
    } else {
      return false;
    }
  }

  public isLoggedIn(): boolean {
    return this.keycloakService.isLoggedIn();
  }

  public getKeycloakInstance(): Keycloak.KeycloakInstance {
    return this.keycloakService.getKeycloakInstance();
  }

  public isTokenExpired(minValidity?: number): boolean {
    return this.keycloakService.isTokenExpired(minValidity);
  }

  /**
   * Generic method to call endpoints from our custom keycloak extension
   * Includes logic for handling proxy for extension if running locally
   *
   * @param method
   * @param endpoint
   * @param options
   */
  public async useCustomKeycloakService<T>(
    method: 'GET' | 'POST' | 'PATCH',
    endpoint: string,
    options?: {
      data?: any;
      params?: any;
      headers?: Record<string, string>;
      isExternalKeycloak?: boolean;
    },
  ): Promise<T> {
    const token: string = await this.getToken();
    const keycloak: Keycloak.KeycloakInstance = this.getKeycloakInstance();

    const isLocalKeycloak: boolean = keycloak?.authServerUrl?.includes('localhost:8088') ?? false; // Check for local keycloak
    // Use relative path for localhost (proxy will handle it)
    // Use absolute path for all other environments
    const url: string = isLocalKeycloak
      ? `/auth/realms/${keycloak.realm}/${endpoint}`
      : `${keycloak.authServerUrl}/realms/${keycloak.realm}/${endpoint}`;

    return firstValueFrom(
      this.http.request<T>(method, url, {
        body: options?.data,
        params: options?.params,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          ...options?.headers,
        },
        context: new HttpContext().set(IS_EXTERNAL_KEYCLOAK, options?.isExternalKeycloak ?? false),
      }),
    );
  }
}

// Type definitions for Keycloak configuration
interface KeycloakConfig {
  baseUrl: string;
  realm: string;
  clientId: string;
  redirectUri?: string;
}

// Standard OAuth2/OIDC parameters
interface StandardOAuthParams {
  response_type?: 'code' | 'token' | 'id_token';
  scope?: string;
  redirect_uri?: string;
  state?: string;
  nonce?: string;
  client_id: string;
}

// Keycloak-specific parameters
interface KeycloakParams {
  login_hint?: string;
  kc_idp_hint?: string;
  ui_locales?: string;
  prompt?: 'none' | 'login' | 'consent' | 'select_account';
  max_age?: number;
  acr_values?: string;
}

// Allow any additional custom parameters
interface CustomParams {
  [key: string]: string | number | boolean | string[] | undefined;
}

// Combined parameter types
type KeycloakLoginParams = Partial<StandardOAuthParams & KeycloakParams & CustomParams>;

/**
 * Builds and navigates to a Keycloak login URL with typed parameters
 * @param config - Keycloak configuration
 * @param additionalParams - Additional query parameters (optional)
 * @param newTab - Whether to open in a new tab (optional)
 */
function navigateToKeycloakLogin(config: KeycloakConfig, additionalParams: KeycloakLoginParams = {}): void {
  // Remove trailing slashes from base URL
  const cleanBaseUrl = config.baseUrl.replace(/\/+$/, '');

  // Construct the Keycloak authorization endpoint URL
  const authUrl = `${cleanBaseUrl}/realms/${encodeURIComponent(config.realm)}/protocol/openid-connect/auth`;

  // Define standard OAuth2/OIDC parameters
  const standardParams: StandardOAuthParams = {
    client_id: config.clientId,
    response_type: 'code',
    scope: 'openid',
    redirect_uri: config.redirectUri,
  };

  // Merge standard and additional parameters
  const params = {
    ...standardParams,
    ...additionalParams,
  };

  // Create URL object
  const url = new URL(authUrl);
  const searchParams = new URLSearchParams();

  // Add all parameters, handling arrays and null/undefined
  Object.entries(params).forEach(([key, value]) => {
    if (value === null || value === undefined) {
      return; // Skip null/undefined values
    }

    if (Array.isArray(value)) {
      // Handle array values
      value.forEach((item) => searchParams.append(key, item.toString()));
    } else {
      searchParams.append(key, value.toString());
    }
  });

  // Set the search parameters on the URL
  url.search = searchParams.toString();

  // Navigate to the URL
  window.location.href = url.toString();
}
