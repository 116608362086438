import {
  APP_ZONES,
  CommonPageConfig,
  ContainerType,
  cryptoRandomUUID,
  PaymentFlowTypes,
  ProfilePagesTags,
  RelevantBaasTags,
  WidgetNames,
  WorkflowConfig,
  WorkflowPageConfig,
  WorkflowUserType,
  WorkflowWidgetConfig,
} from '@finxone-platform/shared/sys-config-types';
import { generateOnboardingDefaultPage } from '../../defaults/pages/onboarding.pages';
import { buildDefaultVerificationPages } from '../../defaults/pages/verification.pages';
import {
  defaultModalConfiguration,
  defaultPageConfigurations,
} from '../../defaults/widgets/workflow-ui-constants.v2.type';
import { defaultAccountsPages } from '../../defaults/zones/accounts/accounts-page-setups';
import { buildBeneficiariesZonePagesConfig } from '../../defaults/zones/beneficiaries-zone/mobile-pages-setup/beneficiaries-zone-pages-setup-mobile';
import { initialCardManagementPage } from '../../defaults/zones/card-management/card-management-page-setups';
import { buildFeeManagementZonePagesConfigMobile } from '../../defaults/zones/fee-management-zone/mobile-pages-setup/fee-management-zone-pages-setup-mobile';
import { defaultLandingPages } from '../../defaults/zones/landing/landing-page-setups';
import { defaultOrganisationAnonymousRolePagesConfig } from '../../defaults/zones/organisation-user/onboarding-organisation/onboarding-organisation';
import { defaultOrganisationProfilePages } from '../../defaults/zones/organisation-user/organisation-profile/org-profile-page-setups';
import { buildMobileVerificationOrgZonePagesConfig } from '../../defaults/zones/organisation-user/verification-organisation/verification-organisation-mobile-page-setup/verification-organisation-mobile-page-setup';
import { buildPaymentsPages } from '../../defaults/zones/payments/payments-page-setups';
import { buildProfileDefaultPages } from '../../defaults/zones/profile/profile-page-setups';
import {
  enterEmailWidgets,
  passwordCheckEmailWidgets,
  passwordForgotWidgets,
  passwordResetValidationWidgets,
} from '../../defaults/zones/profile/widget-sections/password';
import { revenirMobilePagesConfig } from '../../defaults/zones/revenir/revenir-page-setup';
import { removeSpaceFromRole } from '../../mapping/utils/zone-mapping.utils';
export type PageConfig = {
  name: string;
  title: string;
  url?: string;
  widgets?: WorkflowWidgetConfig[];
  isRemovable?: boolean;
  pageConfiguration?: CommonPageConfig;
  tags: string[];
  packs?: string[];
  pageUniqueId?: string;
};

export function getLandingZoneDefaultPage(): WorkflowPageConfig[] {
  const landingDefaultPageComponent = [
    ...buildDynamicZoneConfig(defaultLandingPages),
  ] as WorkflowPageConfig[];
  return landingDefaultPageComponent;
}

export const generalDefaultPageComponent = {
  name: APP_ZONES.GENERAL,
  title: 'General Zone',
  pageConfiguration: {
    mainComponentPageName: 'ParentContainerComponent',
    isRemovable: false,
    pageType: 'dynamicGrid',
    url: 'general',
    ...defaultPageConfigurations,
  },
  widgets: [],
} as WorkflowPageConfig;

export const chatDefaultPageComponent = {
  name: APP_ZONES.CHART,
  title: 'Spending Page',
  pageConfiguration: {
    mainComponentPageName: 'ParentContainerComponent',
    isRemovable: false,
    pageType: 'dynamicGrid',
    url: 'spending',
    ...defaultPageConfigurations,
  },
  widgets: [],
} as WorkflowPageConfig;

export function getPaymentDefaultPageComponent(): WorkflowPageConfig[] {
  const paymentDefaultPageComponent = [
    // This could be customised to dynamically select tags in the future and add
    // the actual required pages for the payment type the project should support
    // rather than hard coding uk faster payments
    ...buildDynamicZoneConfig(buildPaymentsPages([PaymentFlowTypes.UK_FASTER_PAYMENTS])),
  ] as WorkflowPageConfig[];

  return paymentDefaultPageComponent;
}

export function getAccountZoneDefaultPage(): WorkflowPageConfig[] {
  const accountDefaultPageComponent = [
    ...buildDynamicZoneConfig(defaultAccountsPages),
  ] as WorkflowPageConfig[];

  return accountDefaultPageComponent;
}

export function getSignOutZoneDefaultPage(): WorkflowPageConfig[] {
  const forgotPassWordDefaultPageConfigMobile = [
    ...buildDynamicZoneConfig([
      {
        name: 'enter-email',
        title: 'Enter Email',
        widgets: enterEmailWidgets('mobile'),
        url: 'enter-email',
      },
      {
        name: 'pass-email-check',
        title: 'Password Check Email',
        widgets: passwordCheckEmailWidgets('mobile'),
        url: 'password-check-email',
      },
      {
        name: 'pass-forgot',
        title: 'Forgot Password',
        widgets: passwordForgotWidgets('mobile'),
        url: 'forgot-password',
      },
      {
        name: 'pass-reset-validation',
        title: 'Password Reset Validation',
        widgets: passwordResetValidationWidgets('mobile'),
        url: 'pass-reset-validation',
      },
    ]),
  ] as WorkflowPageConfig[];
  return forgotPassWordDefaultPageConfigMobile;
}

export const menuDefaultPageComponent = {
  name: APP_ZONES.CHART,
  title: 'Spending Page',
  pageConfiguration: {
    mainComponentPageName: 'ParentContainerComponent',
    isRemovable: false,
    pageType: 'dynamicGrid',
    url: 'spending',
    ...defaultPageConfigurations,
  },
  widgets: [],
} as WorkflowPageConfig;

export const commonPageComponentsForMobileAndDesktopAndTablet = new Map<
  string,
  ({
    userType,
    relevantBaasTags,
  }: {
    userType?: WorkflowUserType[];
    relevantBaasTags: RelevantBaasTags[];
  }) => WorkflowPageConfig[]
>([
  [
    APP_ZONES.PROFILE,
    ({ relevantBaasTags }) => {
      return buildDynamicZoneConfig(
        buildProfileDefaultPages(
          [
            ProfilePagesTags.DEFAULT_PAGE,
            ProfilePagesTags.CURRENCYCLOUD_REGULATIONS,
            ProfilePagesTags.SETTINGS,
            ProfilePagesTags.USER_PROFILE,
          ],
          relevantBaasTags,
        ),
      );
    },
  ],
  [
    APP_ZONES.ONBOARDING,
    ({ userType }) => {
      return [generateOnboardingDefaultPage(userType)];
    },
  ],
  [
    APP_ZONES.VERIFICATION,
    ({ userType }) => {
      return buildDynamicZoneConfig(
        buildDefaultVerificationPages({
          userType: userType as string[],
          deviceType: 'mobile',
        }),
      );
    },
  ],
  [
    APP_ZONES.LANDING,
    () => {
      return [...getLandingZoneDefaultPage()];
    },
  ],
  [
    APP_ZONES.GENERAL,
    () => {
      return [generalDefaultPageComponent];
    },
  ],
  [
    APP_ZONES.CHART,
    () => {
      return [chatDefaultPageComponent];
    },
  ],
  [
    APP_ZONES.PAYMENT,
    () => {
      return [...getPaymentDefaultPageComponent()];
    },
  ],
  [
    APP_ZONES.ACCOUNTS_ZONE,
    () => {
      return [...getAccountZoneDefaultPage()];
    },
  ],
  [
    APP_ZONES.SIGNED_OUT_FORGOT_PASSWORD,
    () => {
      return [...getSignOutZoneDefaultPage()];
    },
  ],
  [
    APP_ZONES.FEE_MANAGEMENT,
    () => {
      return [...buildFeeManagementZonePagesConfigMobile()];
    },
  ],
  [
    APP_ZONES.REVENIR_ZONE,
    () => {
      return [...getRevenirZoneDefaultPage()];
    },
  ],
  [
    APP_ZONES.CARD_MANAGEMENT_ZONE,
    () => {
      return [...buildDynamicZoneConfig(initialCardManagementPage)];
    },
  ],
]);

export const guestLandingPageConfigMobile = new Map<
  string,
  ({
    userType,
    relevantBaasTags,
  }: {
    userType?: string[];
    relevantBaasTags: RelevantBaasTags[];
  }) => WorkflowPageConfig[]
>([
  [
    APP_ZONES.BENEFICIARY_ZONE,
    () => {
      return buildBeneficiariesZonePagesConfig();
    },
  ],
]);

export const zoneToMobilePageComponentDefaults = new Map<
  string,
  ({
    userType,
    relevantBaasTags,
  }: {
    userType?: WorkflowUserType[];
    relevantBaasTags: RelevantBaasTags[];
  }) => WorkflowPageConfig[]
>([
  ...commonPageComponentsForMobileAndDesktopAndTablet,
  ...guestLandingPageConfigMobile,
  [
    APP_ZONES.MENU_ZONE,
    () => {
      return [
        {
          name: APP_ZONES.MENU_ZONE,
          title: 'Menu Page',
          pageConfiguration: {
            mainComponentPageName: 'ParentContainerComponent',
            isRemovable: false,
            url: 'menu',
            pageType: 'dynamicGrid',
            ...defaultPageConfigurations,
            gridsterMargin: {
              left: 0,
              right: 0,
              top: 0,
              unit: 'em',
            },
          },
          widgets: [],
        },
      ];
    },
  ],
  [
    APP_ZONES.ORGANISATION_ONBOARDING,
    () => {
      return buildOrganisationOnboardingConfig();
    },
  ],
  [
    APP_ZONES.ORGANISATION_VERIFICATION,
    () => {
      return [...buildMobileVerificationOrgZonePagesConfig()];
    },
  ],
  [
    APP_ZONES.ORGANISATION_PROFILE,
    () => {
      return [...getBusinessProfileZoneDefaultPage()];
    },
  ],
]);

export function buildOrganisationOnboardingConfig(isDesktop = false): WorkflowPageConfig[] {
  return defaultOrganisationAnonymousRolePagesConfig(isDesktop);
}

export function getBusinessProfileZoneDefaultPage(): WorkflowPageConfig[] {
  const buildOrganisationProfileConfig = [
    ...buildDynamicZoneConfig(defaultOrganisationProfilePages),
  ] as WorkflowPageConfig[];
  return buildOrganisationProfileConfig;
}

export function buildDynamicZoneConfig(
  pageConfig: {
    name: string;
    title: string;
    url?: string;
    tags?: string[];
    containerType?: ContainerType;
    widgets?: WorkflowWidgetConfig[];
    isRemovable?: boolean;
    pageConfiguration?: CommonPageConfig;
    pageUniqueId?: string;
  }[],
): WorkflowPageConfig[] {
  const pagesConfig: WorkflowPageConfig[] = [];
  pageConfig.forEach((page) => {
    const pageObj: WorkflowPageConfig = {
      name: page.name,
      title: page.title,
      widgets: page.widgets ?? [],
      containerType: page?.containerType ?? ContainerType.PAGE,
      pageConfiguration: {
        url: page.url ?? removeSpaceFromRole(page.name),
        pageType: 'dynamicGrid',
        isRemovable: page.isRemovable ?? false,
        mainComponentPageName: 'ParentContainerComponent',
        modalConfiguration: {
          ...{
            url: page.url ?? removeSpaceFromRole(page.name),
            pageType: 'dynamicGrid',
            isRemovable: page.isRemovable ?? false,
            mainComponentPageName: 'PageAsModalComponent',
          },
          ...defaultModalConfiguration,
          ...page?.pageConfiguration?.modalConfiguration,
        },
        ...defaultPageConfigurations,
        ...page.pageConfiguration,
      },
      tags: page?.tags,
      pageUniqueId: cryptoRandomUUID(),
    };
    pagesConfig.push(pageObj);
  });

  return pagesConfig;
}

const removeSpaceAddHyphen = (name: string) => {
  try {
    return name.toLowerCase().replace(/\s/g, '-');
  } catch (error) {
    return '';
  }
};

export const onUpdateCustomData = ({
  pageConfig,
  workflowConfig,
}: {
  pageConfig: WorkflowPageConfig[] | undefined;
  workflowConfig: WorkflowConfig;
}) => {
  const widgets = pageConfig?.map((item) => item.widgets)?.flat();
  widgets?.forEach((widget) => {
    if (widget.name === WidgetNames.CUSTOM_QUESTION_WIDGET) {
      const attributes = widget.widgetConfiguration.attributes;
      const questionProperties = attributes['questionProperties'];
      const customDataQuestionData = attributes?.['customDataQuestionData'];
      const questionUniqueId = widget.widgetConfiguration.attributes?.['questionUniqueId'];
      const formattedQuestionUniqueId = removeSpaceAddHyphen(questionUniqueId);
      questionProperties.name =
        customDataQuestionData.entity === APP_ZONES.ORGANISATION_ONBOARDING
          ? questionProperties.name
          : formattedQuestionUniqueId;
      workflowConfig = {
        ...workflowConfig,
        customData: {
          ...workflowConfig?.customData,
          questions: {
            ...workflowConfig?.customData?.questions,
            [formattedQuestionUniqueId]: {
              ...workflowConfig?.customData?.questions?.[formattedQuestionUniqueId],
              questionUniqueId: formattedQuestionUniqueId,
              type: questionProperties['type'],
              header: attributes['label'],
              subHeader: attributes['sub_heading'],
              questionProperties: questionProperties,
              validationRules: attributes?.['validationRules'] ?? [],
            },
          },
          data: {
            ...workflowConfig?.customData?.data,
            [formattedQuestionUniqueId]: {
              ...workflowConfig?.customData?.data?.[formattedQuestionUniqueId],
              data_type: customDataQuestionData.data_type,
              entity: customDataQuestionData.entity,
              sink: customDataQuestionData.sink,
            },
          },
        },
      };
    }
  });
  return workflowConfig.customData;
};

export function getRevenirZoneDefaultPage(): WorkflowPageConfig[] {
  const revenirDefaultPage = [...buildDynamicZoneConfig(revenirMobilePagesConfig())] as WorkflowPageConfig[];
  return revenirDefaultPage;
}
