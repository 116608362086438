import {
  ButtonSizeOptions,
  ButtonTypes,
  Constants,
  DocumentMonthsUpdate,
  DownloadDocumentType,
  PRIVACY_SLUG,
  WORKFLOW_POPUPS,
  WidgetNames,
  WidgetTitles,
  WorkflowWidgetConfig,
  cryptoRandomUUID,
} from '@finxone-platform/shared/sys-config-types';
import { downloadDocNestedWidgets } from '../nested-widgets/statement/statement';

export const multiRoleWidgetWidgetConfig: WorkflowWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: Math.floor(Math.random() * (1000000000 + 1)),
  name: WidgetNames.MULTI_ROLE_WIDGET,
  widgetConfiguration: {
    widgetConfigDisplayName: 'Multi Organisation',
    widgetDisplayName: WidgetTitles.MULTI_ROLE,
    isRemovable: true,
    attributes: {
      widgetProperties: {
        textContent: '',
      },
      elementsConfig: [],
      field_attributes: [
        {
          name: 'organisation',
          decision_config: { required: false },
          label: 'Organisation',
          type: 'dropdown',
          options: [],
          additional_config: {},
        },
        {
          name: 'role',
          decision_config: { required: true },
          label: 'Switch Role to:',
          type: 'dropdown',
          options: [],
          additional_config: {},
        },
      ],
      css_config: Constants.defaultMultiRoleBaseCssConfig,
    },
  },
  gridPosition: {
    x: 0,
    y: 1,
    cols: 15,
    rows: 7,
    minItemRows: 10,
    dragEnabled: true,
    resizeEnabled: true,
  },
  widgetIconPath: 'generic-widget',
};

export const profileNewFieldWidgetConfig: WorkflowWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: Math.floor(Math.random() * (1000000000 + 1)),
  name: WidgetNames.QUESTION_WIDGET,
  widgetConfiguration: {
    widgetConfigDisplayName: 'New Field',
    widgetDisplayName: WidgetTitles.ADD_FIELDS,
    attributes: {
      widgetProperties: { textContent: '' },
      elementsConfig: [
        {
          field_name: 'add_question_widget',
          label: 'Add Question',
          type: 'dropdown',
          section: 'questionwidget',
          show: true,
          options: [],
        },
      ],
      css_config: Constants.defaultBaseCssConfig,
    },
    isRemovable: true,
  },
  gridPosition: {
    dragEnabled: true,
    resizeEnabled: true,
    x: 0,
    y: 0,
    rows: 4,
    cols: 15,
  },
  widgetIconPath: 'generic-widget',
  popupType: WORKFLOW_POPUPS.CUSTOM_QUESTION_WIDGET,
};

export const privacyWidgetConfig: WorkflowWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: WidgetNames.USER_PRIVACY_WIDGET,
  name: WidgetNames.USER_PRIVACY_WIDGET,
  widgetConfiguration: {
    widgetConfigDisplayName: 'Privacy',
    widgetDisplayName: WidgetTitles.USER_PRIVACY_WIDGET,
    attributes: {
      elementsConfig: [
        {
          field_name: PRIVACY_SLUG.TERMS,
          label: 'Terms and conditions',
          type: 'text',
          show: true,
          options: [],
        },
        {
          field_name: PRIVACY_SLUG.PRIVACY_POLICY,
          label: 'Privacy Policy',
          type: 'text',
          show: true,
          options: [],
        },
      ],
      widgetProperties: { textContent: '' },
      css_config: Constants.defaultBaseCssConfig,
    },
    isRemovable: true,
  },
  gridPosition: {
    dragEnabled: true,
    resizeEnabled: true,
    x: 0,
    y: 0,
    rows: 16,
    cols: 15,
  },
  widgetIconPath: 'generic-widget',
};

export const logoutWidgetConfig: WorkflowWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: cryptoRandomUUID(),
  name: WidgetNames.LOGOUT,
  widgetConfiguration: {
    widgetDisplayName: 'Logout',
    widgetConfigDisplayName: WidgetTitles.LOGOUT,
    attributes: {
      elementsConfig: [],
      slug: 'logout',
      label: 'Logout',
      css_config: {
        ...Constants.defaultBaseCssConfig,
        textAlignment: 'left',
        verticalAlignment: 'center',
        textFont: 'Mulish',
        fontSize: 20,
        shadowBlur: 0,
        shadowSpread: 0,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
      },
      widgetProperties: {
        globalStyling: true,
        globalStylingClass: `${ButtonTypes.SECONDARY} ${ButtonSizeOptions.FULL_WIDTH}`,
        textContent: 'Logout',
      },
    },
    isRemovable: true,
  },
  gridPosition: {
    dragEnabled: true,
    resizeEnabled: true,
    x: 0,
    y: 0,
    rows: 4,
    cols: 15,
    minItemRows: 4,
  },
  widgetIconPath: 'generic-widget',
};

export const profilePictureWidgetConfig: WorkflowWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: WidgetNames.PROFILE_PICTURE_WIDGET,
  name: WidgetNames.PROFILE_PICTURE_WIDGET,
  widgetConfiguration: {
    widgetConfigDisplayName: 'Profile Picture',
    widgetDisplayName: WidgetTitles.PROFILE_PICTURE_WIDGET,
    attributes: {
      elementsConfig: [],
      widgetProperties: { textContent: '' },
      css_config: {
        ...Constants.defaultBaseCssConfig,
        width: 60,
        height: 60,
        dimensionUnit: 'px',
      },
    },
    isRemovable: true,
  },
  gridPosition: {
    dragEnabled: true,
    resizeEnabled: true,
    x: 0,
    y: 0,
    rows: 5,
    cols: 5,
  },
  widgetIconPath: 'generic-widget',
};

export const profileNotificationBellConfig: WorkflowWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: WidgetNames.NOTIFICATION_BELL_WIDGET,
  name: WidgetNames.NOTIFICATION_BELL_WIDGET,
  widgetConfiguration: {
    widgetConfigDisplayName: 'Notification Bell',
    widgetDisplayName: WidgetTitles.NOTIFICATION_BELL_WIDGET,
    attributes: {
      elementsConfig: [],
      widgetProperties: { textContent: '' },
      css_config: {
        ...Constants.defaultBaseCssConfig,
        paddingTop: 8,
        paddingLeft: 8,
        paddingRight: 8,
        paddingBottom: 8,
        borderRadiusTopLeft: 18,
        borderRadiusBottomLeft: 18,
        borderRadiusTopRight: 18,
        borderRadiusBottomRight: 18,
        borderWidth: 1,
        borderColor: '#EBEBEB',
        alertColor: '#2C70C9',
      },
    },
    isRemovable: true,
  },
  gridPosition: {
    dragEnabled: true,
    resizeEnabled: true,
    x: 12,
    y: 0,
    rows: 2,
    cols: 2,
  },
  widgetIconPath: 'generic-widget',
};

export const biometricsAuthenticationWidgetConfig: WorkflowWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: WidgetNames.BIOMETRICS_AUTHENTICATION_WIDGET,
  name: WidgetNames.BIOMETRICS_AUTHENTICATION_WIDGET,
  widgetConfiguration: {
    widgetConfigDisplayName: 'Authentication & Biometrics',
    widgetDisplayName: WidgetTitles.BIOMETRICS_AUTHENTICATION_WIDGET,
    attributes: {
      elementsConfig: [
        {
          field_name: 'requireConfirmation',
          label: 'Require Confirmation',
          section: 'profile',
          type: 'checkbox',
          show: false,
          options: [],
        },
      ],
      widgetProperties: {
        textContent: '<div class="font-weight-medium">Use Authentication</div>',
      },
      css_config: {
        ...Constants.defaultBaseCssConfig,
        fontSize: 16,
        textFont: 'Mulish',
        textAlignment: 'space-between',
        borderRadiusTopLeft: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusBottomRight: 5,
        borderWidth: 1,
        paddingLeft: 16,
        paddingRight: 16,
        borderColor: 'var(--border-color-1)',
        borderStyle: 'solid',
        iconSize: 24,
        verticalAlignment: 'center',
      },
    },
    isRemovable: true,
  },
  gridPosition: {
    dragEnabled: true,
    resizeEnabled: true,
    x: 0,
    y: 2,
    rows: 3,
    cols: 15,
    minItemCols: 1,
    minItemRows: 1,
  },
  widgetIconPath: 'generic-widget',
};

export const backbuttonCssConfig = {
  color: 'var(--palette-color-6)',
  borderWidth: '1',
  borderColor: 'var(--palette-color-3)',
  backGroundColor: 'var(--palette-color-3)',
  fontSize: '16',
  borderRadiusTopLeft: '5',
  borderRadiusTopRight: '5',
  borderRadiusBottomLeft: '5',
  borderRadiusBottomRight: '5',
  marginBottom: '12',
  marginTop: '12',
  paddingTop: '11',
  paddingBottom: '11',
  paddingRight: '11',
  paddingleft: '11',
  textAlignment: 'center',
  justifyContent: 'center',
  textFont: 'Mulish',
};

export const textwidgetCssConfig = {
  color: 'var(--typography-color-0)',
  textFont: 'Mulish',
  display: 'flex',
  verticalAlignment: 'start',
  textAlignment: 'left',
};

export const multiIconWidgetCssConfig = {
  backGroundColor: 'var(--palette-color-6)',
  color: 'var(--typography-color-0)',
  textAlignment: 'center',
  borderRadiusTopLeft: 5,
  borderRadiusTopRight: 5,
  borderRadiusBottomLeft: 5,
  borderRadiusBottomRight: 5,
  borderWidth: 1,
  borderColor: 'var(--border-color-1)',
  fontSize: 12,
  textFont: 'Mulish',
};

export const downloadDocumentWidgetConfig: WorkflowWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: cryptoRandomUUID(),
  name: WidgetNames.DOWNLOAD_DOCUMENT_WIDGET,
  widgetConfiguration: {
    widgetConfigDisplayName: WidgetTitles.DOWNLOAD_DOCUMENT_WIDGET,
    widgetDisplayName: WidgetTitles.DOWNLOAD_DOCUMENT_WIDGET,
    nestedWidgets: downloadDocNestedWidgets,
    attributes: {
      documentType: DownloadDocumentType.BANK_STATEMENT,
      timeInterval: DocumentMonthsUpdate.LAST_MONTH,
      textInputHeader: '',
      textInputSubHeader: ``,
      elementsConfig: [],
      widgetProperties: {
        textContent:
          '<div class="is-center" style="background-color: var(--palette-color-3); color: var(--palette-color-6); height: 50px; border-radius: 6px;">Download</div>',
      },
      css_config: {
        ...Constants.defaultBaseCssConfig,
        paddingTop: 25,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        borderRadiusTopLeft: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusBottomRight: 5,
        borderColor: 'var(--border-color-1)',
        backGroundColor: 'var(--palette-color-6)',
        justifyContent: 'left',
      },
    },
    isRemovable: true,
  },
  gridPosition: {
    dragEnabled: true,
    resizeEnabled: true,
    x: 0,
    y: 0,
    rows: 7,
    cols: 15,
  },
  widgetIconPath: 'generic-widget',
};

export const calendarWidgetConfig: WorkflowWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: cryptoRandomUUID(),
  name: WidgetNames.RANGE_CALENDAR_WIDGET,
  widgetConfiguration: {
    widgetConfigDisplayName: WidgetTitles.RANGE_CALENDAR_WIDGET,
    widgetDisplayName: WidgetTitles.RANGE_CALENDAR_WIDGET,
    nestedWidgets: {
      resetButton: {
        name: WidgetNames.BUTTON,
        widgetConfigDisplayName: 'Reset changes button',
        attributes: {
          widgetProperties: {
            textContent: 'Reset changes',
            globalStyling: true,
            globalStylingClass: `${ButtonTypes.SECONDARY} ${ButtonSizeOptions.FULL_WIDTH}`,
          },
          elementsConfig: [],
          css_config: {
            ...Constants.defaultUiNestedButtonCssConfig,
            paddingBottom: 15,
            paddingTop: 15,
            color: 'var(--palette-color-3)',
            backGroundColor: 'var(--palette-color-6)',
            isTextUnderline: true,
          },
        },
      },
    },
    attributes: {
      elementsConfig: [],
      widgetProperties: { textContent: '' },
      css_config: {
        ...Constants.defaultBaseCssConfig,
        backGroundColor: 'var(--palette-color-6)',
      },
    },
    isRemovable: true,
  },
  gridPosition: {
    dragEnabled: true,
    resizeEnabled: true,
    x: 0,
    y: 0,
    rows: 20,
    cols: 15,
  },
  widgetIconPath: 'generic-widget',
};

export const getPasswordChangeWidget = (): WorkflowWidgetConfig => {
  const changePasswordWidget: WorkflowWidgetConfig = {
    widgetTypeConfig: 'new',
    uniqueId: WidgetNames.PASSWORD_CHANGE,
    name: WidgetNames.PASSWORD_CHANGE,
    widgetConfiguration: {
      isRemovable: false,
      widgetDisplayName: '',
      widgetConfigDisplayName: WidgetTitles.PASSWORD_CHANGE_WIDGET,
      attributes: {
        css_config: Constants.defaultBaseCssConfig,
        widgetProperties: {
          textContent: 'Current Password',
          subHeadingLabel: 'Current Password',
          passFieldLabel: 'Enter Existing Password',
          passFieldPC: 'Type Current Password',
        },
        elementsConfig: [],
      },
    },
    gridPosition: {
      x: 0,
      y: 2,
      rows: 6,
      cols: 15,
      dragEnabled: true,
      resizeEnabled: true,
    },
    widgetIconPath: '',
  };
  return changePasswordWidget;
};

export const getForgotPasswordWidget = (
  widgetConfigDisplayName: WidgetTitles.PASSWORD_FORGOT_WIDGET | WidgetTitles.PASSWORD_NEW_WIDGET,
  heading = 'New Password',
  gridPosition = {
    x: 0,
    y: 8,
    rows: 11,
    cols: 15,
  },
  widgetBtnText = 'Next',
): WorkflowWidgetConfig => {
  const forgotPasswordWidget: WorkflowWidgetConfig = {
    widgetTypeConfig: 'new',
    uniqueId:
      widgetConfigDisplayName === WidgetTitles.PASSWORD_FORGOT_WIDGET
        ? WidgetNames.PASSWORD_FORGOT
        : WidgetNames.PASSWORD_UPDATE,
    name:
      widgetConfigDisplayName === WidgetTitles.PASSWORD_FORGOT_WIDGET
        ? WidgetNames.PASSWORD_FORGOT
        : WidgetNames.PASSWORD_UPDATE,
    widgetConfiguration: {
      isRemovable: false,
      widgetDisplayName: '',
      widgetConfigDisplayName,
      nestedWidgets: {
        reviewDetailsButton: {
          name: WidgetNames.BUTTON,
          widgetConfigDisplayName: widgetBtnText,
          attributes: {
            widgetProperties: {
              textContent: widgetBtnText,
              globalStyling: true,
              globalStylingClass: `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`,
            },
            elementsConfig: [],
            css_config: {
              ...Constants.defaultUiNestedButtonCssConfig,
              color: 'var(--palette-color-6)',
              marginUnit: 'px',
              marginTop: 10,
              backGroundColor: 'var(--palette-color-3)',
            },
          },
        },
      },
      attributes: {
        css_config: Constants.defaultBaseCssConfig,
        widgetProperties: {
          textContent: 'New Password',
          subHeadingLabel: heading,
          passFieldLabel1: 'Create New Password',
          passFieldPC1: 'Type New Password',
          passFieldLabel2: 'Re-Enter New Password',
          passFieldPC2: 'Re-Enter New Password',
        },
        elementsConfig: [],
      },
    },
    gridPosition: {
      ...gridPosition,
      dragEnabled: true,
      resizeEnabled: true,
    },
    widgetIconPath: '',
  };
  return forgotPasswordWidget;
};

export const getPasswordEnterEmailWidget = (
  gridPosition = {
    x: 0,
    y: 6,
    rows: 12,
    cols: 15,
  },
): WorkflowWidgetConfig => {
  const passwordEnterEmailWidget: WorkflowWidgetConfig = {
    widgetTypeConfig: 'new',
    uniqueId: WidgetNames.PASSWORD_ENTER_EMAIL,
    name: WidgetNames.PASSWORD_ENTER_EMAIL,
    gridPosition: {
      ...gridPosition,
      dragEnabled: true,
      resizeEnabled: true,
    },
    widgetConfiguration: {
      isRemovable: false,
      widgetConfigDisplayName: WidgetTitles.ENTER_EMAIL_WIDGET,
      widgetDisplayName: '',
      nestedWidgets: {
        reviewDetailsButton: {
          widgetConfigDisplayName: 'Send Link',
          name: WidgetNames.BUTTON,
          attributes: {
            widgetProperties: {
              textContent: 'Send Link',
              globalStyling: true,
              globalStylingClass: `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`,
            },
            elementsConfig: [],
            css_config: {
              ...Constants.defaultUiNestedButtonCssConfig,
              color: 'var(--palette-color-6)',
              marginUnit: 'px',
              marginBottom: 25,
              paddingTop: 15,
              paddingBottom: 15,
              backGroundColor: 'var(--palette-color-3)',
            },
          },
        },
      },
      attributes: {
        css_config: Constants.defaultBaseCssConfig,
        widgetProperties: {
          textContent: 'Forget Password',
          emailLabel: 'Email',
          emailPlaceHolder: 'Enter your email',
        },
        elementsConfig: [],
      },
    },
    widgetIconPath: '',
  };
  return passwordEnterEmailWidget;
};

export const getEnterEmailWidget = (
  gridPosition = {
    x: 0,
    y: 6,
    rows: 12,
    cols: 15,
  },
): WorkflowWidgetConfig => {
  const forgotPasswordWidget: WorkflowWidgetConfig = {
    widgetTypeConfig: 'new',
    uniqueId: WidgetNames.PASSWORD_ENTER_EMAIL,
    name: WidgetNames.PASSWORD_ENTER_EMAIL,
    widgetConfiguration: {
      isRemovable: false,
      widgetDisplayName: '',
      widgetConfigDisplayName: WidgetTitles.ENTER_EMAIL_WIDGET,
      nestedWidgets: {
        reviewDetailsButton: {
          name: WidgetNames.BUTTON,
          widgetConfigDisplayName: 'Send Link',
          attributes: {
            widgetProperties: {
              textContent: 'Send Link',
              globalStyling: true,
              globalStylingClass: `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`,
            },
            elementsConfig: [],
            css_config: {
              ...Constants.defaultUiNestedButtonCssConfig,
              color: 'var(--palette-color-6)',
              marginUnit: 'px',
              marginBottom: 25,
              paddingTop: 15,
              paddingBottom: 15,
              backGroundColor: 'var(--palette-color-3)',
            },
          },
        },
      },
      attributes: {
        css_config: Constants.defaultBaseCssConfig,
        widgetProperties: {
          textContent: 'Forget Password',
          emailLabel: 'Email',
          emailPlaceHolder: 'Enter your email',
        },
        elementsConfig: [],
      },
    },
    gridPosition: {
      ...gridPosition,
      dragEnabled: true,
      resizeEnabled: true,
    },
    widgetIconPath: '',
  };
  return forgotPasswordWidget;
};
