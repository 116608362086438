import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { FormActionTypeEnum } from '@finxone-platform/form-action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import {
  APP_ZONES,
  AddEmployeeRequestType,
  BaseWidgetProperties,
  UiZoneWidgetAttributeConfig,
} from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { map } from 'rxjs';
import { ResetFormDataAction } from '../../../actions/form-submission.action';
import { GetUsersList } from '../../../actions/users-list.action';
import { MetadataService } from '../../../services/metadata-service/metadata-service.service';
import { FormActionState } from '../../../state/form-submision.state';
import { ProfileState } from '../../../state/user-profile.state';
import { redirectToPage } from '../cta-button-actions.utils';

const ERROR_MESSAGES = {
  email: 'Email is a mandatory field.',
  name: 'First name and last name are mandatory fields.',
  phone: 'Phone and Country code are mandatory fields.',
  userName: 'User name is a mandatory field.',
  password: 'Password is a mandatory field.',
  confirmPassword: "Confirm password doesn't match with password.",
};

/**
 * Validates the employee data object to ensure all mandatory fields are present
 * and correct. If any validation fails, an error alert is triggered using the
 * AlertHandlerService with the appropriate error message.
 *
 * @param data - The employee data object to validate.
 * @param alertHandlerService - The service used to display alerts for validation errors.
 * @returns A boolean indicating whether the employee data is valid.
 */
function validateEmployeeData(data: any, alertHandlerService: AlertHandlerService): boolean {
  if (!data['email']) {
    alertHandlerService.showAlertFn('error', ERROR_MESSAGES.email);
    return false;
  }
  if (!data['first_name'] || !data['last_name']) {
    alertHandlerService.showAlertFn('error', ERROR_MESSAGES.name);
    return false;
  }
  if (!data['phone'] && !data['country_code']) {
    alertHandlerService.showAlertFn('error', ERROR_MESSAGES.phone);
    return false;
  }
  if (!data['userName']) {
    alertHandlerService.showAlertFn('error', ERROR_MESSAGES.userName);
    return false;
  }
  if (!data['password']) {
    alertHandlerService.showAlertFn('error', ERROR_MESSAGES.password);
    return false;
  }
  if (data['password'] !== data['confirm_password']) {
    alertHandlerService.showAlertFn('error', ERROR_MESSAGES.confirmPassword);
    return false;
  }
  return true;
}

/**
 * Adds a new employee using the provided widget properties and injector.
 *
 * This function retrieves necessary services and state values using the injector,
 * constructs an employee request object, and validates the employee data.
 * If validation passes, it sends a request to add the employee via the metadata service.
 * Upon successful addition, it redirects to a specified page and updates the user list.
 * If the addition fails, it redirects to an error page.
 *
 * @param widgetProperties - The properties of the widget initiating the action.
 * @param injector - The Angular injector used to retrieve services.
 * @param attri - Configuration attributes for the UI zone widget.
 */
export function addEmployeeMethod(
  widgetProperties: BaseWidgetProperties,
  injector: Injector,
  attri: UiZoneWidgetAttributeConfig,
) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  const alertHandlerService = injector.get(AlertHandlerService);
  const metadataService = injector.get(MetadataService);

  const addEmployeeStateValue = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.ADD_EMPLOYEE),
  );

  const formActionStateValue = store.selectSnapshot(FormActionState.getFormActionState);

  const profileStateValue = store.selectSnapshot(ProfileState.getProfile);

  const addEmployeeRequestFormData = {
    ...addEmployeeStateValue?.formData,
    ...formActionStateValue.response?.formData,
    activeOrganisationId: profileStateValue.activeOrganisationId,
  };

  if (!validateEmployeeData(addEmployeeRequestFormData, alertHandlerService)) {
    return;
  }

  const {
    first_name: firstName,
    middle_name: middleName,
    last_name: lastName,
    email,
    activeOrganisationId: organisationId,
    password,
    country_code,
    phone,
    userName,
    confirm_password,
  } = addEmployeeRequestFormData;

  const addEmployeeRequest: AddEmployeeRequestType = {
    firstName,
    middleName,
    lastName,
    email,
    organisationId,
    roleId: 'employee',
    emailVerified: false,
    phoneVerified: false,
    additionalRoles: [],
    password,
    phoneNumber: `${country_code}${phone}`,
    userName,
    profilePicture: addEmployeeRequestFormData?.[attri?.['profilePictureConfiguration']?.pictureKey]?.[0]?.id,
  };

  metadataService
    .addEmployee(addEmployeeRequest)
    .pipe(
      map((response) => {
        if (response['userId']) {
          redirectToPage(router, widgetProperties);
          store.dispatch(new GetUsersList(organisationId, 1, 10));
        } else {
          redirectToPage(router, {
            textContent: '',
            zoneToNavigate: APP_ZONES.USER_MANAGEMENT,
            urlToNavigate: 'assign-failed',
          } as BaseWidgetProperties);
        }
        store.dispatch(new ResetFormDataAction());
      }),
    )
    .subscribe();
}

/**
 * Invites an employee to an organization using the provided widget properties and injector.
 *
 * @param widgetProperties - The properties of the widget used for navigation and configuration.
 * @param injector - The injector used to retrieve necessary services and store instances.
 *
 * This method retrieves the current form action state and profile state from the store,
 * checks if the email field is present in the form data, and shows an error alert if not.
 * It then sends an invite request to the MetadataService with the employee's email and
 * organization ID. If the user exists, it navigates to a success page and resets the form data;
 * otherwise, it navigates to the original page.
 */
export const inviteEmployeeMethod = (widgetProperties: BaseWidgetProperties, injector: Injector) => {
  const store = injector.get(Store);
  const router = injector.get(Router);
  const metadataService = injector.get(MetadataService);
  const alertHandlerService = injector.get(AlertHandlerService);
  const formActionStateValue = store.selectSnapshot(FormActionState.getFormActionState);

  const profileStateValue = store.selectSnapshot(ProfileState.getProfile);

  const inviteEmployeeRequest = formActionStateValue?.response?.formData;

  if (!inviteEmployeeRequest?.['email']) {
    alertHandlerService.showAlertFn('error', 'Email is a mandatory field.');
    return;
  }
  const parsedWidgetProperties = JSON.parse(JSON.stringify(widgetProperties));
  parsedWidgetProperties['urlToNavigate'] = 'assign-success';
  metadataService
    .inviteEmployee({
      email: inviteEmployeeRequest['email'],
      orgId: profileStateValue.activeOrganisationId as string,
      persona: 'employee', // static as of now, it has to be there in app grid
      roles: [],
    })
    .pipe(
      map((response) => {
        if (response['userId'] && response['userExist']) {
          redirectToPage(router, parsedWidgetProperties, injector);
          store.dispatch(new ResetFormDataAction());
        } else {
          redirectToPage(router, widgetProperties, injector);
        }
      }),
    )
    .subscribe();
};
