import { feeTemplate } from '@finxone-platform/shared/default-templates';
import {
  APP_ZONES,
  BaseWidgetProperties,
  GridPosition,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import { ImageWidgetBuilder } from '../../../defaults/widgets/example-builder';
import { getBackButtonWidget } from '../../../defaults/zones/manage-users-zone/common-utils';
import {
  generateTextWidget,
  getQuickLinkWidget,
} from '../../../defaults/zones/payments/common-widgets-utils';
export function addEditValidationWidgets(widgetsOverrides: { redirectionUrl: string }) {
  const addEditValidationWidgets = [
    new ImageWidgetBuilder()
      .configDisplayName('Image Widget: Success Icon')
      .cssConfig({
        selectedobjectfit: 'contain',
      })
      .gridPosition({ y: 1, x: 21, rows: 2, cols: 3 } as GridPosition)
      .attributes({
        widgetProperties: {
          textContent: '',
          imageAssetUrl: 'assets/images/tick-success.svg',
        } as BaseWidgetProperties,
      } as WorkflowWidgetConfigurationAttributes)
      .build(),

    generateTextWidget({
      text: '{{feeManagement.addEditFeeData.name}} updated!',
      position: { x: 11, y: 3, rows: 2, cols: 23 } as GridPosition,
      displayName: 'Header Text',
      styles: {
        fontSize: 24,
        isTextBold: true,
        verticalAlignment: 'start',
        textAlignment: 'center',
        noAlignment: true,
        color: 'var(--palette-color-0)',
        textFont: 'var(--font-family)',
        paddingTop: 28,
      },
      isRemovable: true,
    }),

    generateTextWidget({
      text: 'Below are the details for the fee that you updated. Please review the details.',
      position: { x: 9, y: 5, rows: 2, cols: 28 } as GridPosition,
      displayName: 'Information Content',
      styles: {
        verticalAlignment: 'start',
        textAlignment: 'center',
        fontSize: '16',
        paddingTop: 5,
        lineHeight: '24',
        textFont: 'var(--font-family)',
        color: 'var(--typography-color-1)',
      },
      isRemovable: true,
    }),

    generateTextWidget({
      text: feeTemplate,
      position: { x: 6, y: 7, rows: 6, cols: 33 } as GridPosition,
      styles: {
        backGroundColor: 'var(--palette-color-5)',
        paddingTop: '30',
        paddingLeft: '30',
        paddingRight: '30',
        display: 'flex',
        verticalAlignment: 'start',
        textAlignment: 'left',
        borderRadiusTopLeft: '5',
        borderRadiusTopRight: '5',
        borderRadiusBottomLeft: '5',
        borderRadiusBottomRight: '5',
        textFont: 'var(--font-family)',
      },
      displayName: 'Fee Template',
      isRemovable: false,
    }),

    getBackButtonWidget({
      position: {
        y: 14,
        x: 16,
        rows: 1,
        cols: 5,
      } as GridPosition,
      styles: {
        color: 'var(--palette-color-3)',
        backGroundColor: 'var(--palette-color-6)',
        borderWidth: 1,
        borderColor: 'var(--palette-color-3)',
        fontSize: 14,
        textAlign: 'center',
        verticalAlignment: 'center',
      },
    }),

    getQuickLinkWidget({
      text: 'Fee List',
      position: {
        x: 23,
        y: 14,
        rows: 1,
        cols: 5,
      } as GridPosition,
      pageUrl: widgetsOverrides.redirectionUrl,
      displayName: 'Quick Link: Fee List',
      styles: {
        borderWidth: 1,
        color: 'var(--palette-color-6)',
        fontSize: '16',
        backGroundColor: 'var(--palette-color-3)',
        textAlignment: 'center',
        justifyContent: 'center',
        borderColor: 'var(--palette-color-3)',
        borderStyle: 'solid',
        borderRadiusTopLeft: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusBottomRight: 5,
      },
      zone: APP_ZONES.FEE_MANAGEMENT,
      isRemovable: true,
    }),
  ];
  return addEditValidationWidgets;
}
