import { feeGroupDetailsTemplate } from '@finxone-platform/shared/default-templates';
import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonActionType,
  GridPosition,
  WorkflowWidgetConfig,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import { FormSubmissionButtonWidgetBuilder } from '../../../defaults/widgets/example-builder';
import { getBackButtonWidget } from '../../../defaults/zones/manage-users-zone/common-utils';
import { generateTextWidget } from '../../../defaults/zones/payments/common-widgets-utils';
import { generateImageWidgetConfig } from '../common-widget-utils';

export function generateAddFeeGroupConfirmationPageWidgets(): WorkflowWidgetConfig[] {
  return [
    generateImageWidgetConfig({
      pageType: 'Success',
      imageName: 'tick-success',
      gridPosition: {
        y: 5,
        x: 19,
      },
    }),
    generateTextWidget({
      text: 'Group details updated/added!',
      displayName: 'Text Widget: Title',
      isRemovable: false,
      position: {
        y: 9,
        x: 11,
        rows: 1,
        cols: 21,
      } as GridPosition,
      styles: {
        fontSize: 24,
        color: 'var(--typography-color-0)',
        isTextBold: true,
        textAlignment: 'center',
      },
    }),
    generateTextWidget({
      text: 'The group details has been saved under the { feeGroup.name }.',
      displayName: 'Text Widget: Sub Title',
      isRemovable: false,
      position: {
        y: 10,
        x: 11,
        rows: 1,
        cols: 21,
      } as GridPosition,
      styles: {
        fontSize: 20,
        color: 'var(--typography-color-1)',
        textAlignment: 'center',
      },
    }),
    generateTextWidget({
      text: feeGroupDetailsTemplate,
      displayName: 'Text Widget: Fee Group Details',
      isRemovable: false,
      position: {
        y: 11,
        x: 11,
        rows: 5,
        cols: 21,
      } as GridPosition,
      styles: {
        fontSize: 20,
        backGroundColor: 'var(--palette-color-5)',
        textAlignment: 'left',
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 10,
        paddingRight: 10,
        borderRadiusTopLeft: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusBottomRight: 5,
      },
    }),
    getBackButtonWidget({
      position: {
        y: 17,
        x: 17,
        rows: 1,
        cols: 4,
      } as GridPosition,
      styles: {
        fontSize: 14,
        textAlignment: 'center',
        color: 'var(--palette-color-3)',
        backGroundColor: 'transparent',
        borderWidth: 1,
        borderColor: 'var(--palette-color-3)',
      },
    }),
    new FormSubmissionButtonWidgetBuilder()
      .configDisplayName('Button: Review Information - Personal')
      .attributes({
        widgetProperties: {
          textContent: 'Fee Groups',
          zoneToNavigate: APP_ZONES.FEE_MANAGEMENT,
          urlToNavigate: 'group-list',
          buttonActionType: ButtonActionType.CLEAR_FORM_STATE_AND_REDIRECT_TO_FEE_GROUPS_LISTING,
        } as BaseWidgetProperties,
      } as WorkflowWidgetConfigurationAttributes)
      .cssConfig({
        backGroundColor: 'var(--palette-color-3)',
        color: 'var(--palette-color-6)',
        marginTop: 0,
        marginBottom: 0,
      })
      .isRemovable(false)
      .gridPosition({
        y: 17,
        x: 22,
        rows: 1,
        cols: 4,
      } as GridPosition)
      .build(),
  ];
}
