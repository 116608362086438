import { Constants, WidgetNames } from '@finxone-platform/shared/sys-config-types';

export const txHistoryNestedWidgets = {
  transactionButtons: {
    name: WidgetNames.TRANSACTION_BUTTON,
    widgetConfigDisplayName: 'Transaction Button Styling',
    attributes: {
      widgetProperties: {
        textContent: '',
      },
      elementsConfig: [],
      css_config: {
        ...Constants.defaultUiNestedTransactionButtonCssConfig,
      },
    },
  },
  transactionList: {
    name: WidgetNames.TRANSACTION_LIST,
    widgetConfigDisplayName: 'Transaction List Styling',
    attributes: {
      widgetProperties: {
        textContent: '',
      },
      elementsConfig: [],
      css_config: {
        ...Constants.defaultUiNestedTransactionListCssConfig,
      },
    },
  },
};
