import { FormActionTypeEnum } from '@finxone-platform/form-action';
import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonActionType,
  GridPosition,
  SINK_TYPE,
  ValidationRuleTypes,
  WidgetNames,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import {
  CustomQuestionWidgetBuilder,
  LineWidgetBuilder,
  UploadBoardingPassWidgetBuilder,
} from '../../../../widgets/example-builder';
import { commonBackButtonQuickLink, commonCTAButton, commonTextWidget } from '../../../common/common-widgets';

export const tripZoneUploadBoardingPassWidgets = [
  commonBackButtonQuickLink({
    cssConfig: {
      iconSize: 35,
      iconUnit: 'px',
    },
    gridPosition: {
      y: 0,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 2,
    },
  }),

  commonTextWidget({
    textContent: 'Boarding Pass Details',
    configDisplayName: 'Boarding Pass Details',
    cssConfig: {
      textAlignment: 'center',
      fontSize: 24,
      isTextBold: true,
      paddingLeft: 10,
      paddingRight: 10,
      marginLeft: 10,
      marginRight: 10,
      textFont: 'var(--font-family)',
    },
    gridPosition: {
      y: 2,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 3,
      cols: 15,
    },
  }),

  commonTextWidget({
    textContent: 'Please fill in the details of your boarding pass or ticket',
    configDisplayName: 'Sub title',
    cssConfig: {
      fontSize: 17,
      color: 'var(--typography-color-0)',
      paddingRight: 10,
      textAlignment: 'center',
      textFont: 'var(--font-family)',
    },
    gridPosition: {
      x: 0,
      y: 6,
      rows: 2,
      cols: 15,
      resizeEnabled: true,
      dragEnabled: true,
    },
  }),

  new CustomQuestionWidgetBuilder()
    .gridPosition({ x: 0, y: 9, rows: 4, cols: 15 } as GridPosition)
    .configDisplayName(`Custom Question Widget: Reference Number `)
    .cssConfig({ borderWidth: '0', isTextBold: true, textFont: 'var(--font-family)' })
    .attributes({
      customDataQuestionData: {
        data_type: 'stringArray',
        entity: APP_ZONES.REVENIR_ZONE,
        sink: SINK_TYPE.METADATA,
        isUpdateToHasura: false,
      },
      label: `Reference Number`,
      inputType: 'text',
      questionUniqueId: 'trip-boarding-reference-number',
      elementsConfig: [
        {
          field_name: WidgetNames.CUSTOM_QUESTION_WIDGET,
          label: `Reference Number`,
          type: 'text',
          section: 'questionwidget',
          show: false,
          options: [],
          uniqueId: 'trip-boarding-reference-number',
        },
      ],
      validationRules: [
        {
          type: ValidationRuleTypes.REQUIRED,
          value: true,
        },
      ],
      questionProperties: {
        name: 'trip-boarding-reference-number',
        label: 'Reference Number',
        type: 'text',
        preSelectSubAnswer: 'false',
      },
      widgetProperties: {
        showHeader: false,
        showSubHeader: false,
        headerConfig: {
          headerFontSize: '18',
        },
      },
    } as unknown as WorkflowWidgetConfigurationAttributes)
    .build(),

  new CustomQuestionWidgetBuilder()
    .gridPosition({ x: 0, y: 13, rows: 4, cols: 15 } as GridPosition)
    .configDisplayName(`Custom Question Widget: Destination `)
    .cssConfig({ borderWidth: '0', textFont: 'var(--font-family)' })
    .attributes({
      customDataQuestionData: {
        data_type: 'stringArray',
        entity: APP_ZONES.REVENIR_ZONE,
        sink: SINK_TYPE.METADATA,
        isUpdateToHasura: false,
      },
      label: `Destination`,
      inputType: 'text',
      questionUniqueId: 'trip-boarding-destination',
      elementsConfig: [
        {
          field_name: WidgetNames.CUSTOM_QUESTION_WIDGET,
          label: `Destination`,
          type: 'text',
          section: 'questionwidget',
          show: false,
          options: [],
          uniqueId: 'trip-boarding-destination',
        },
      ],
      validationRules: [
        {
          type: ValidationRuleTypes.REQUIRED,
          value: true,
        },
      ],
      questionProperties: {
        name: 'trip-boarding-destination',
        label: 'Destination',
        type: 'text',
        preSelectSubAnswer: 'false',
      },
      widgetProperties: {
        showHeader: false,
        showSubHeader: false,
        headerConfig: {
          headerFontSize: '18',
        },
      },
    } as unknown as WorkflowWidgetConfigurationAttributes)
    .build(),

  new LineWidgetBuilder()
    .configDisplayName('Line Widget: Line')
    .gridPosition({
      y: 18,
      rows: 1,
      x: 0,
      cols: 15,
    } as GridPosition)
    .attributes({
      widgetProperties: {
        color: 'var(--border-color-1)',
        'border-bottom': 'var(--border-color-1)',
        lineThickness: 1,
        lineWidth: '100',
        textContent: '',
        show: true,
        contentAlignment: 'center',
        paddingLeft: 2,
      } as BaseWidgetProperties,
      css_config: {
        marginTop: 15,
      },
    } as WorkflowWidgetConfigurationAttributes)
    .build(),

  commonTextWidget({
    textContent: 'Please upload your ticket, reservation or boarding pass as proof of outbound travel ',
    configDisplayName: 'Sub title',
    cssConfig: {
      fontSize: 18,
      color: 'var(--typography-color-0)',
      paddingRight: 10,
      textAlignment: 'center',
      isTextBold: true,
      textFont: 'var(--font-family)',
    },
    gridPosition: {
      x: 0,
      y: 20,
      rows: 3,
      cols: 15,
      resizeEnabled: true,
      dragEnabled: true,
    },
  }),

  new UploadBoardingPassWidgetBuilder()
    .addCustomWidgetProperties({
      formKey: FormActionTypeEnum.ADD_TRIP_REVENIR_ZONE,
      textContent: 'Upload Boarding Pass',
      uploadType: 'boarding-pass',
    })
    .gridPosition({
      y: 23,
      x: 0,
      rows: 6,
      cols: 15,
    } as GridPosition)
    .cssConfig({
      paddingTop: 12,
      paddingBottom: 12,
      paddingLeft: 20,
      paddingRight: 20,
      marginTop: 90,
    })
    .build(),

  commonCTAButton({
    zoneToNavigate: APP_ZONES.REVENIR_ZONE,
    urlToNavigate: 'exit-point',
    textContent: 'Skip',
    configDisplayName: 'Skip',
    gridPosition: { y: 29, x: 0, rows: 2, cols: 15 } as GridPosition,
    buttonActionType: ButtonActionType.SKIP_BOARDING_PASS_DETAILS,
  }),
];
