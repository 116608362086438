import { APP_ZONES, ButtonActionType } from '@finxone-platform/shared/sys-config-types';
import {
  commonBackButtonQuickLink,
  commonCTAButton,
  commonProgressBarWidget,
  commonTextWidget,
} from '../../../common/common-widgets';

export const tripZoneReviewDetailsWidgets = [
  commonProgressBarWidget({ completionProgressPercentage: '100' }),

  commonBackButtonQuickLink({
    cssConfig: {
      iconSize: 35,
      iconUnit: 'px',
    },
    gridPosition: {
      y: 3,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 2,
      layerIndex: 1,
    },
  }),

  commonTextWidget({
    textContent: 'Review Trip details',
    cssConfig: {
      textAlignment: 'left',
      fontSize: 24,
      isTextBold: true,
      textFont: 'var(--font-family)',
    },
    gridPosition: {
      y: 3,
      x: 4,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 11,
    },
  }),

  commonTextWidget({
    textContent: 'Trip Summary',
    cssConfig: {
      textAlignment: 'center',
      fontSize: 20,
      paddingLeft: 10,
      paddingRight: 10,
      marginLeft: 10,
      marginRight: 10,
      textFont: 'var(--font-family)',
    },
    gridPosition: {
      y: 6,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 15,
    },
  }),
  commonTextWidget({
    textContent:
      '<div class="travel-info text-left w-100"> <div class="row mt-3"> <label class="font-weight-bold">Date </label> <p class="w-100 mt-1">{{datePipe tripZoneFormState.startDate format="dd/MM/y"}} - {{datePipe tripZoneFormState.endDate format="dd/MM/y"}} </p> </div> <div class="row mt-3"> <label class="font-weight-bold">Entry point country </label> <p class="w-100 mt-1 text-capitalize">{{tripZoneFormState.entering_country}}</p> </div> <div class="row mt-3"> <label class="font-weight-bold">Exit point country </label> <p class="w-100 mt-1 text-capitalize">{{tripZoneFormState.leaving_country}}</p> </div> <div class="row mt-3"> <label class="font-weight-bold">Exit point Travel info </label> <p class="w-100 mt-1">{{tripZoneFormState.modeOfTravel}}</p> <p class="w-100">{{tripZoneFormState.departureInput}}</p> <p class="w-100 ">{{tripZoneFormState.approxTime}}</p> </div> </div>',
    cssConfig: {
      paddingLeft: 10,
    },
    gridPosition: {
      y: 10,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 15,
      cols: 15,
    },
  }),
  commonCTAButton({
    textContent: 'Save Trip',
    buttonActionType: ButtonActionType.ADD_REVENIR_NEW_TRIP,
    zoneToNavigate: APP_ZONES.REVENIR_ZONE,
    urlToNavigate: 'trips-home-page',
  }),
];
