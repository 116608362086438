import {
  Constants,
  cryptoRandomUUID,
  WidgetNames,
  WidgetTitles,
  WorkflowWidgetConfig,
} from '@finxone-platform/shared/sys-config-types';

export function getVerificationOrgEditRegisterNumberWidget(): WorkflowWidgetConfig {
  return {
    widgetTypeConfig: 'new',
    uniqueId: cryptoRandomUUID(),
    name: WidgetNames.VERIFICATION_ORG_EDIT_REGISTER_NUMBER,
    widgetConfiguration: {
      isRemovable: false,
      widgetDisplayName: 'Registration number',
      widgetConfigDisplayName: WidgetTitles.VERIFICATION_ORG_EDIT_REGISTER_NUMBER,
      attributes: {
        widgetProperties: {
          textContent: '',
          inputLabel: 'Registration number',
        },
        elementsConfig: [],
        css_config: {
          ...Constants.defaultBaseCssConfig,
        },
      },
    },
    widgetIconPath: '',
  };
}

export function getVerificationOrgEditIncorporationDateWidget(): WorkflowWidgetConfig {
  return {
    widgetTypeConfig: 'new',
    uniqueId: cryptoRandomUUID(),
    name: WidgetNames.VERIFICATION_ORG_EDIT_INCORPORATION_DATE,
    widgetConfiguration: {
      isRemovable: false,
      widgetDisplayName: WidgetTitles.VERIFICATION_ORG_EDIT_INCORPORATION_DATE,
      widgetConfigDisplayName: WidgetTitles.VERIFICATION_ORG_EDIT_INCORPORATION_DATE,
      attributes: {
        widgetProperties: {
          textContent: '',
          dayLabel: 'Day',
          monthLabel: 'Month',
          yearLabel: 'Year',
        },
        elementsConfig: [],
        css_config: {
          ...Constants.defaultBaseCssConfig,
        },
      },
    },
    widgetIconPath: '',
  };
}
