import { GridPosition } from '@finxone-platform/shared/sys-config-types';
import { BackButtonWidgetBuilder, TextWidgetBuilder } from '../../../widgets';

export const zoneHeaderTitle = ({
  textContent,
  gridPosition,
  cssConfig,
  fontSize = 16,
}: {
  textContent?: string;
  gridPosition?: GridPosition;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cssConfig?: any;
  fontSize?: number;
}) =>
  new TextWidgetBuilder()
    .text(textContent ?? 'Add Facility')
    .gridPosition({
      y: 0,
      x: 0,
      rows: 2,
      cols: 10,
      ...gridPosition,
    } as GridPosition)
    .configDisplayName(`Text Widget`)
    .cssConfig({
      color: 'var(--typography-color-0)',
      fontSize: fontSize,
      isTextBold: true,
      paddingLeft: 40,
      textAlignment: 'left',
      ...cssConfig,
    })
    .build();

export const headerSubTitle = ({
  textContent,
  gridPosition,
  cssConfig,
}: {
  textContent: string;
  gridPosition?: GridPosition;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cssConfig?: any;
}) =>
  new TextWidgetBuilder()
    .text(textContent)
    .gridPosition({
      y: 3,
      x: 0,
      rows: 2,
      cols: 15,
      ...gridPosition,
    } as GridPosition)
    .configDisplayName(`Text Widget: ${textContent}`)
    .cssConfig({
      color: 'var(--typography-color-0)',
      fontSize: 20,
      paddingLeft: 40,
      textAlignment: 'left',
      ...cssConfig,
    })
    .build();

export const backButtonQuickLink = ({
  gridPosition,
  cssConfig,
}: {
  gridPosition: GridPosition;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cssConfig?: any;
}) =>
  new BackButtonWidgetBuilder()
    .defaultBackButton()
    .configDisplayName('Back Navigation Button')
    .cssConfig({
      color: 'var(--palette-color-3)',
      backGroundColor: 'var(--palette-color-6)',
      borderWidth: 1,
      borderColor: 'var(--palette-color-3)',
      fontSize: 14,
      paddingLeft: 20,
      paddingRight: 20,
      marginTop: 14,
      marginBottom: 14,
      textAlign: 'center',
      verticalAlignment: 'center',
      ...cssConfig,
    })
    .gridPosition({ ...gridPosition, resizeEnabled: true, dragEnabled: true })
    .build();
