import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { APP_ZONES, BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { redirectToPage } from '../cta-button-actions.utils';

export const addBeneficiaryIntlProceed = (injector: Injector) => {
  const router = injector.get(Router);

  const widgetProperties = {
    zoneToNavigate: APP_ZONES.PAYMENT,
    urlToNavigate: 'beneficiary-address',
  } as unknown as BaseWidgetProperties;

  redirectToPage(router, widgetProperties, injector);
};
