import {
  APP_ZONES,
  GridPosition,
  NavigationOptionEnum,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import { QuickLinkWidgetBuilder, RevenirArchivedTripsWidgetBuilder } from '../../../widgets';
import { commonBackButtonQuickLink, commonTextWidget } from '../../common';

export const revenirArchivedTripsWidgets = [
  commonBackButtonQuickLink({
    cssConfig: {
      iconSize: 35,
      iconUnit: 'px',
    },
    gridPosition: {
      y: 0,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 2,
      layerIndex: 1,
    },
  }),

  new QuickLinkWidgetBuilder()
    .icon('archive')
    .navigationOption(NavigationOptionEnum.USE_AS_BLANK)
    .role()
    .text('')
    .configDisplayName(`Quick Link: Archive`)
    .cssConfig({
      marginTop: 15,
      marginBottom: 15,
      color: 'var(--palette-color-3)',
      backGroundColor: 'var(--palette-color-6)',
      borderRadiusTopLeft: 5,
      borderRadiusBottomLeft: 5,
      borderRadiusTopRight: 5,
      borderRadiusBottomRight: 5,
      fontSize: 16,
    })
    .gridPosition({
      y: 0,
      x: 13,
      rows: 2,
      cols: 2,
    } as GridPosition)
    .build(),

  commonTextWidget({
    textContent: 'Archived',
    configDisplayName: 'Archived',
    cssConfig: {
      fontSize: 24,
      isTextBold: true,
      textFont: 'var(--font-family)',
    },
    gridPosition: {
      y: 2,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 15,
      layerIndex: 0,
    },
  }),

  new RevenirArchivedTripsWidgetBuilder()
    .isRemovable(false)
    .gridPosition({
      x: 0,
      y: 5,
      rows: 24,
      cols: 15,
    } as GridPosition)
    .attributes({
      cssConfig: {
        textFont: 'var(--font-family)',
      },
      pageSize: 5,
      pagingEnabled: true,
      widgetProperties: {
        textContent: '',
        zoneToNavigate: APP_ZONES.REVENIR_ZONE,
        urlToNavigate: 'archived-transactions',
        icon: 'chevron-right',
      },
    } as unknown as WorkflowWidgetConfigurationAttributes)
    .build(),
];
