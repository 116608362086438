import { WorkflowWidgetConfig } from '@finxone-platform/shared/sys-config-types';
import { TextWidgetBuilder } from '../../../../widgets';

export const infoText = 'Edit your information based on the previous onboarding steps.';

export function createTextWidget(
  displayName: string,
  text: string,
  cssConfig: any,
  textStyleClass: string,
): WorkflowWidgetConfig {
  return new TextWidgetBuilder()
    .configDisplayName(displayName)
    .setWidgetProperties({ textStyleClass: textStyleClass, textContent: text })
    .cssConfig(cssConfig)
    .isRemovable(true)
    .build();
}
