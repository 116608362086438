import { Constants, WidgetNames } from '@finxone-platform/shared/sys-config-types';

const defaultCardStylingOptions = {
  defaultCardTemplate: `<div class="is-vertical-align justify-content-between h-100 ml-3">
  <div class="mt-3">
    <div class="typog-xs">{{ card.name }}</div>
    <div class="typog-xxs">{{ card.expiry }}</div>
  </div>
  <div class="mb-3 typog-xl font-weight-medium">{{ 'capitaliseFirstLetter' card.type}} Card</div>
</div>`,
  frozenCardTemplate: `<div class="is-center is-vertical-align frozen-gradient frozen-blur h-100 fade-in">
    <div>
      <svg width="32px" height="32px">
  <use xlink:href="assets/icon-library/material/mat_frozen.svg#icon-frozen"></use>
  </svg>
  </div>
  <div>Frozen</div>
  </div>`,
  useGradient: true,
  imagePath: '',
};

const defaultCardCssConfig = {
  backGroundColor: '#0000ff',
  backgroundGradient: 'linear-gradient(106.06deg, #000B0C 1.1%, #3E3E3E 47.43%, #000B0C 100%)',
  width: 100,
  height: 175,
  widthDimensionUnit: '%',
  heightDimensionUnit: 'px',
  borderWidth: 0,
};

export const cardsNestedWidgets = {
  prepaidVirtualTopUp: {
    name: WidgetNames.CARD,
    isRemovable: true,
    widgetConfigDisplayName: 'Prepaid Virtual Card (Top Up)',
    attributes: {
      widgetProperties: {
        textContent: '',
        cardStylingOptions: {
          ...defaultCardStylingOptions,
        },
      },
      elementsConfig: [],
      css_config: {
        ...Constants.defaultUiNestedCardCssConfig,
        ...defaultCardCssConfig,
      },
    },
  },
  prepaidPhysicalTopUp: {
    name: WidgetNames.CARD,
    isRemovable: true,
    widgetConfigDisplayName: 'Prepaid Physical Card (Top Up)',
    attributes: {
      widgetProperties: {
        textContent: '',
        cardStylingOptions: {
          ...defaultCardStylingOptions,
        },
      },
      elementsConfig: [],
      css_config: {
        ...Constants.defaultUiNestedCardCssConfig,
        ...defaultCardCssConfig,
      },
    },
  },
  debitVirtualCurrent: {
    name: WidgetNames.CARD,
    isRemovable: true,
    widgetConfigDisplayName: 'Debit Virtual Card (Current)',
    attributes: {
      widgetProperties: {
        textContent: '',
        cardStylingOptions: {
          ...defaultCardStylingOptions,
        },
      },
      elementsConfig: [],
      css_config: {
        ...Constants.defaultUiNestedCardCssConfig,
        ...defaultCardCssConfig,
      },
    },
  },
  debitPhysicalCurrent: {
    name: WidgetNames.CARD,
    isRemovable: true,
    widgetConfigDisplayName: 'Debit Physical Card (Current)',
    attributes: {
      widgetProperties: {
        textContent: '',
        cardStylingOptions: {
          ...defaultCardStylingOptions,
        },
      },
      elementsConfig: [],
      css_config: {
        ...Constants.defaultUiNestedCardCssConfig,
        ...defaultCardCssConfig,
      },
    },
  },
  noCard: {
    name: WidgetNames.CARD,
    isRemovable: true,
    widgetConfigDisplayName: 'No Card - Styling',
    attributes: {
      widgetProperties: {
        textContent: '',
        cardStylingOptions: {
          useGradient: false,
          defaultCardTemplate: `<div class="is-vertical-align w-100 h-100">
<div class="typog-s d-flex mt-2 mr-2 is-right">+ Add Card</div>
<p class=" is-center h-100 mb-5 ">No Cards are registered to this account</p>
</div>`,
        },
      },
      elementsConfig: [],
      css_config: {
        ...Constants.defaultUiNestedCardCssConfig,
        width: 100,
        height: 175,
        widthDimensionUnit: '%',
        heightDimensionUnit: 'px',
        borderColor: 'var(--typography-color-1)',
        color: 'var(--typography-color-1)',
        borderWidth: 2,
        backGroundColor: 'var(--palette-color-6)',
      },
    },
  },
};
