import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { ProjectSettingsState } from '../../../state/project-settings.state';
import { redirectToPage } from '../cta-button-actions.utils';
import { submitDomesticPaymentRequest } from './submit-payment-request';

/**
 * Checks the SMS authentication status from the project settings and redirects
 * based on the status. If SMS authentication is enabled, it redirects to the
 * specified page using the provided widget properties. If not enabled, it sets
 * the navigation URL to 'payment-processing', submits a domestic payment request,
 * and then redirects.
 *
 * @param widgetProperties - The properties of the widget, including navigation details.
 * @param injector - The Angular injector used to retrieve services.
 */
export function checkAuthSmsStatusAndRedirect(widgetProperties: BaseWidgetProperties, injector: Injector) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  const isSmsAuthEnabled = store.selectSnapshot(
    ProjectSettingsState.getProjectSettings,
  ).smsAuthenticationEnabled;

  if (isSmsAuthEnabled) {
    redirectToPage(router, widgetProperties, injector);
  } else {
    widgetProperties['urlToNavigate'] = 'payment-processing';
    submitDomesticPaymentRequest(injector);
    redirectToPage(router, widgetProperties, injector);
  }
}
