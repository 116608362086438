import { Router } from '@angular/router';
import { APP_ZONES, BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';

import { HttpErrorResponse } from '@angular/common/http';
import { Injector } from '@angular/core';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { PaymentRequestPayload } from '@finxone-platform/shared/utils';
import { GeneratePaymentRequest } from '../../../actions/payment-request.action';
import { FormActionState } from '../../../state/form-submision.state';
import { NotificationState } from '../../../state/notifications.state';
import { redirectToPage } from '../cta-button-actions.utils';

/**
 * Handles the rejection of a payment request by dispatching a rejection action
 * and navigating to the appropriate page based on the result.
 *
 * @param widgetProperties - The properties of the widget, including navigation details.
 * @param injector - The Angular injector used to retrieve services and dependencies.
 *
 * @throws Error if FormState data is not found.
 */
export function paymentRequestReject(widgetProperties: BaseWidgetProperties, injector: Injector) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  const alertHandlerService = injector.get(AlertHandlerService);
  const formState = store.selectSnapshot(FormActionState.getFormActionState);
  if (!widgetProperties['zoneToNavigate']) {
    widgetProperties['zoneToNavigate'] = APP_ZONES.PAYMENT;
  }
  const parsedWidgetProperties = JSON.parse(JSON.stringify(widgetProperties));

  if (formState?.response?.formData) {
    const paymentRequestSummary = store.selectSnapshot(
      NotificationState.getNotification,
    ).paymentRequestSummary;

    if (paymentRequestSummary) {
      const payload: PaymentRequestPayload = {
        ids: [paymentRequestSummary.recordId],
        approvedStatus: 'rejected',
      };
      store.dispatch(new GeneratePaymentRequest(payload)).subscribe({
        next: () => {
          parsedWidgetProperties['urlToNavigate'] = 'payment-request-failed';
          redirectToPage(router, parsedWidgetProperties, injector, true);
        },
        error: (err: HttpErrorResponse) => {
          parsedWidgetProperties['zoneToNavigate'] = APP_ZONES.PROFILE;
          parsedWidgetProperties['urlToNavigate'] = 'notification-centre';
          redirectToPage(router, parsedWidgetProperties, injector, true);
          console.error('Error generating payment request:', err);
        },
      });
    }
  } else {
    alertHandlerService.showAlertFn('error', 'FormState data not found.');
    throw Error('FormState data not found.');
  }
}
