import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { FormActionTypeEnum } from 'libs/shared/enums/form-action.enum';
import { FormActionState } from '../../../state/form-submision.state';
import { redirectToPage } from '../cta-button-actions.utils';

/**
 * Checks the KYC details in the form action state and redirects the user based on verification status.
 *
 * @param widgetProperties - The properties of the widget used for navigation.
 * @param injector - The Angular injector used to retrieve services.
 *
 * Retrieves the form action state for KYC details and checks if the email and phone are verified.
 * If either is not verified, an error alert is shown. If both are verified, the user is redirected
 * to the appropriate page.
 */
export function checkFormActionDataKycDetailsAndRedirect(
  widgetProperties: BaseWidgetProperties,
  injector: Injector,
) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  const alertHandlerService = injector.get(AlertHandlerService);
  const formStateData = store.selectSnapshot(FormActionState.getFormActionState)?.[
    FormActionTypeEnum.KYC_DETAILS
  ]?.formData;
  if (formStateData) {
    if (formStateData?.['isEmailVerified'] === false || formStateData?.['isPhoneVerified'] === false) {
      alertHandlerService.showAlertFn('error', 'Please verify phone and email beofre proceeding');
      return;
    }
    if (formStateData?.['isEmailVerified'] === true || formStateData?.['isPhoneVerified'] === true) {
      redirectToPage(router, widgetProperties, injector);
    }
  }
}
