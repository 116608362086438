import { bulkpaymentListing } from '../../../widgets';

export const bulkPaymentListingColumns = [
  ...bulkpaymentListing,
  {
    label: 'New / Existing',
    value: `beneficiaryExistStatus`,
    handleBarKey: '{{beneficiaryExistStatus}}',
    selected: true,
  },
];

export function getBulkPaymentListingColumns(column: {
  label: string;
  value: string;
  handleBarKey: string;
  selected: boolean;
}) {
  const columns = JSON.parse(JSON.stringify(bulkPaymentListingColumns));
  columns.splice(7, 1);
  columns.push(column);
  return columns;
}
