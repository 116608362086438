import {
  ADDRESS_FIELD_ATTRIBUTES,
  Constants,
  PAGELAYOUTTYPE,
  WidgetNames,
  WidgetTitles,
  WorkflowWidgetConfig,
} from '@finxone-platform/shared/sys-config-types';
import { generateOnboardingWidgetsWorkflowWidgetConfig } from '../onboarding/onboarding.type';

const NameWidget = generateOnboardingWidgetsWorkflowWidgetConfig(WidgetNames.NAME_WIDGET);

export const profileDetailVerificationWidgetConfig = {
  ...NameWidget,
  widgetConfiguration: {
    ...NameWidget.widgetConfiguration,
    widgetDisplayName: WidgetTitles.PROFILE_DETAILS,
  },
  gridPosition: {
    x: 0,
    y: 0,
    minItemCols: 15,
    minItemRows: 8,
    rows: 8,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
  alternativeAddWidgets: [],
  roleHook: 'verification-profile-widget-page-hook',
} as WorkflowWidgetConfig;

export const kycNameWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: WidgetNames.NAME_WIDGET,
  name: WidgetNames.NAME_WIDGET,
  widgetConfiguration: {
    isVisible: true,
    widgetConfigDisplayName: 'Name',
    widgetDisplayName: WidgetTitles.VERIFICATION,
    isRemovable: false,
    attributes: {
      widgetProperties: { textContent: '' },
      elementsConfig: [],
      label: 'Name',
      sub_heading: 'Enter your name',
      show: true,
      decision_config: {
        required: true,
      },
      field_attributes: [
        {
          name: 'first_name',
          decision_config: {
            required: true,
          },
          label: 'First Name',
          type: 'text',
          options: [],
          additional_config: {},
        },
        {
          name: 'middle_name',
          decision_config: { required: false },
          label: 'Middle Name',
          type: 'text',
          options: [],
          additional_config: {},
        },
        {
          name: 'last_name',
          decision_config: { required: true },
          label: 'Last Name',
          type: 'text',
          options: [],
          additional_config: {},
        },
      ],
      css_config: Constants.defaultBaseCssConfig,
    },
  },
  gridPosition: {
    x: 0,
    y: 2,
    cols: 15,
    rows: 4,
    dragEnabled: true,
    resizeEnabled: true,
    minItemRows: 4,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const kycPhoneWidgetConfig = {
  widgetTypeConfig: 'new',
  name: WidgetNames.PHONE_WIDGET,
  uniqueId: WidgetNames.PHONE_WIDGET,
  widgetConfiguration: {
    isVisible: true,
    widgetDisplayName: WidgetTitles.VERIFICATION,
    widgetConfigDisplayName: 'Phone Number',
    attributes: {
      widgetProperties: { textContent: '' },
      elementsConfig: [
        {
          field_name: 'verificationViaOTP',
          label: 'Check for Phone Validation (OTP)',
          section: 'signup_widget',
          type: 'text',
          show: true,
          options: [],
        },
      ],
      label: 'Phone',
      sub_heading: 'Enter and verify your phone number',
      show: true,
      field_attributes: [
        {
          name: 'country_code',
          label: 'Country Code',
          type: 'dropdown',
          options: [{ country_codes_list: [] }],
          decision_config: {
            required: true,
          },
        },
        {
          name: 'phone',
          label: 'Phone',
          type: 'number',
          options: [],
          decision_config: {
            required: true,
            pattern: true,
            verifyViaOTP: true,
          },
          additional_config: {},
        },
      ],
      css_config: Constants.defaultBaseCssConfig,
    },
    isRemovable: false,
  },
  gridPosition: {
    x: 0,
    y: 4,
    cols: 15,
    rows: 4,
    minItemRows: 4,
    dragEnabled: true,
    resizeEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const kycBirthdayWidgetConfig = generateOnboardingWidgetsWorkflowWidgetConfig(
  WidgetNames.BIRTHDAY_WIDGET,
);

export const kycEmailWidgetConfig = {
  widgetTypeConfig: 'new',
  name: WidgetNames.EMAIL_WIDGET,
  uniqueId: WidgetNames.EMAIL_WIDGET,
  widgetConfiguration: {
    isVisible: true,
    widgetDisplayName: WidgetTitles.VERIFICATION,
    widgetConfigDisplayName: 'Email Address',
    attributes: {
      widgetProperties: { textContent: '' },
      elementsConfig: [],
      label: 'Email',
      sub_heading: 'Enter and verify your email address',
      show: true,
      decision_config: {
        required: true,
        pattern: true,
        verifyEmail: false,
      },
      field_attributes: [
        {
          name: 'email',
          label: 'Email',
          type: 'email',
          field_config: {
            required: true,
            pattern: true,
          },
          options: [],
          additional_config: {},
        },
      ],
      css_config: Constants.defaultBaseCssConfig,
    },
    isRemovable: false,
  },
  gridPosition: {
    x: 0,
    y: 8,
    cols: 15,
    rows: 4,
    minItemRows: 4,
    dragEnabled: true,
    resizeEnabled: true,
  },
  widgetIconPath: 'generic-widget',
  pageType: PAGELAYOUTTYPE.WIDGET,
} as WorkflowWidgetConfig;

export const kycAddressWidgetConfig = {
  widgetTypeConfig: 'new',
  name: WidgetNames.ADDRESS_WIDGET,
  uniqueId: WidgetNames.ADDRESS_WIDGET,
  widgetConfiguration: {
    isVisible: true,
    widgetConfigDisplayName: 'Address',
    widgetDisplayName: WidgetTitles.VERIFICATION,
    attributes: {
      elementsConfig: [],
      widgetProperties: { textContent: '' },
      label: 'Address',
      sub_heading: 'Enter your address',
      show: true,
      field_attributes: ADDRESS_FIELD_ATTRIBUTES,
      css_config: Constants.defaultBaseCssConfig,
    },
    isRemovable: false,
  },
  gridPosition: {
    x: 0,
    y: 10,
    cols: 15,
    rows: 36,
    minItemRows: 36,
    dragEnabled: true,
    resizeEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export function sumsubWidgetConfig(isDesktop = false): WorkflowWidgetConfig {
  return {
    widgetTypeConfig: 'new',
    name: WidgetNames.SUMSUB_WIDGET,
    uniqueId: WidgetNames.SUMSUB_WIDGET,
    widgetConfiguration: {
      isVisible: true,
      uniqueId: Math.floor(Math.random() * (1000000000 + 1)),
      widgetConfigDisplayName: 'Sumsub Verification',
      widgetDisplayName: WidgetTitles.VERIFICATION,
      attributes: {
        elementsConfig: [],
        widgetProperties: { textContent: '' },
        label: 'Sumsub Verification',
        sub_heading: 'Enter the code sent to',
        show: true,
        title: 'Sumsub Verification',
        css_config: Constants.defaultBaseCssConfig,
      },
      isRemovable: false,
    },
    gridPosition: {
      x: isDesktop ? 15 : 0,
      y: 0,
      cols: 15,
      rows: 30,
      minItemRows: 7,
      dragEnabled: true,
      resizeEnabled: true,
    },
    widgetIconPath: 'generic-widget',
  };
}
