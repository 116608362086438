import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonActionType,
  ButtonSizeOptions,
  ButtonTypes,
  Constants,
  WorkflowWidgetConfig,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import {
  BackButtonWidgetBuilder,
  FormSubmissionButtonWidgetBuilder,
  SpacerWidgetBuilder,
  TextWidgetBuilder,
} from '../../../../widgets';

export function authenticationConfirmationPageWidgets(
  pageType: 'enable' | 'disable',
): WorkflowWidgetConfig[] {
  return [
    new TextWidgetBuilder()
      .setTextStylingClass('')
      .text(`<h6>${pageType === 'enable' ? 'Enable' : 'Disable'} Authentication</h6>`)
      .configDisplayName(`Text: ${pageType === 'enable' ? 'Enable' : 'Disable'} Authentication`)
      .cssConfig({
        ...Constants.defaultBaseCssConfig,
        color: 'var(--color-on-surface)',
      })
      .build(),
    new SpacerWidgetBuilder().setHeight({ height: 5, unit: 'px' }).build(),
    new TextWidgetBuilder()
      .setTextStylingClass('subtitle1')
      .text(`Are you sure you want to ${pageType} authentication?`)
      .configDisplayName(`Text: Information`)
      .cssConfig({
        ...Constants.defaultBaseCssConfig,
        color: 'var(--color-on-surface-muted)',
      })
      .build(),
    new SpacerWidgetBuilder().setHeight({ height: 54, unit: 'vh' }).build(),
    new FormSubmissionButtonWidgetBuilder()
      .configDisplayName('Button: Save Changes')
      .attributes({
        widgetProperties: {
          textContent: `Yes, ${pageType}`,
          buttonActionType: ButtonActionType.TOGGLE_BIOMETRIC_AUTHENTICATION,
          zoneToNavigate: APP_ZONES.PROFILE,
          urlToNavigate: 'authentication',
        } as BaseWidgetProperties,
      } as WorkflowWidgetConfigurationAttributes)
      .cssConfig({
        marginTop: 0,
        verticalAlignment: 'center',
      })
      .isRemovable(false)
      .setGlobalStylingClass(true, `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`)
      .build(),
    new SpacerWidgetBuilder().setHeight({ height: 30, unit: 'px' }).build(),
    new BackButtonWidgetBuilder()
      .icon('')
      .configDisplayName('Back Button')
      .text('Back')
      .cssConfig({
        marginTop: 0,
        textAlignment: 'center',
      })
      .setGlobalStylingClass(true, `${ButtonTypes.SECONDARY} ${ButtonSizeOptions.FULL_WIDTH}`)
      .build(),
  ];
}
