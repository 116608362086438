export const removeGroupNameTemplate = `<div class="w-100 h-100">
<div class="">
    <div class="ben-label pb-2">Group Name</div>
    <div class='font-weight-bold event-value typog-lg'>{{feeManagement.selectedFeeGroupData.name}}</div>
</div>
<hr class="my-4 mx-1">
<div class="">
    <div class="ben-label pb-2">Description</div>
    <div class="font-weight-normal">{{feeManagement.selectedFeeGroupData.description}}</div>
</div>
<hr class="my-4 mx-1">
<div class="">
    <div class="ben-label pb-2">Clients Linked</div>
    <div class="font-weight-normal">{{feeManagement.selectedFeeGroupData.clientLinked}}</div>
</div>
</div>`;

export const removeFeeTemplate = `
<div class="w-100 h-100">
    <div class="text-center">
        <div class="ben-label pb-2">Fee Name</div>
        <div class='font-weight-bold event-value text-capitalize typog-lg'>{{feeManagement.selectedFeeData.feeName}}</div>
    </div>
    <hr class="my-4 mx-1">
    <div class="d-flex">
        <div class="pr-2 col-2">
            <div class="ben-label pb-2">Currency</div>
            <div class="font-weight-normal">{{feeManagement.selectedFeeData.currency}}</div>
        </div>
        <div class="pr-2 col-2">
            <div class="ben-label pb-2">Frequency</div>
            <div class="font-weight-normal text-capitalize">{{feeManagement.selectedFeeData.feeFrequencySettings.frequencyType}}</div>
        </div>
        <div class="pr-2 col-2">
            <div class="ben-label pb-2">Amount / Percentage</div>
            <div class="font-weight-normal">{{ feeChargeValue feeManagement.selectedFeeData.feeCharge feeManagement.selectedFeeData.unitChargeType feeManagement.selectedFeeData.currency }}</div>
        </div>
        <div class="pr-2 col-2">
            <div class="ben-label pb-2">Unit Charge</div>
            <div class="font-weight-normal text-capitalize">{{feeManagement.selectedFeeData.unitChargeType}}</div>
        </div>
        <div class="pr-2 col-2">
            <div class="ben-label pb-2">Effective from: </div>
            <div class="font-weight-normal">{{ datePipe feeManagement.selectedFeeData.feeFrequencySettings.startDate  format='d MMM y'}}</div>
        </div>
        <div class="col-2">
            <div class="ben-label pb-2">Status</div>
            <div class="font-weight-normal text-capitalize">{{feeManagement.selectedFeeData.status}}</div>
        </div>
    </div>
</div>`;
