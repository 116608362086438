import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonActionType,
  Constants,
  DefaultToggleOption,
  GridPosition,
  WidgetNames,
  WidgetTitles,
  WorkflowWidgetConfig,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import { BaseWidgetBuilder, initWidgetBuilder } from '../base-builder';
import {
  CardsWidgetBuilder,
  FormSubmissionButtonWidgetBuilder,
  MultiIconQuickLinkWidgetBuilder,
  QuickLinkWidgetBuilder,
  TextWidgetBuilder,
} from '../example-builder';

export const cardsWidget: WorkflowWidgetConfig = new CardsWidgetBuilder()
  .cssConfig({
    borderWidth: 0,
  })
  .gridPosition({
    y: 3,
  } as GridPosition)
  .build();

// Freeze Card Toggle Widget

const freezeCardToggleWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: Math.floor(Math.random() * (1000000000 + 1)),
  name: WidgetNames.FREEZE_CARD_TOGGLE_SWITCH,
  widgetConfiguration: {
    widgetDisplayName: WidgetTitles.FREEZE_CARD,
    widgetConfigDisplayName: WidgetTitles.FREEZE_CARD,
    attributes: {
      elementsConfig: [],
      name: WidgetNames.FREEZE_CARD_TOGGLE_SWITCH,
      label: WidgetTitles.FREEZE_CARD,
      css_config: {
        ...Constants.defaultBaseCssConfig,
        fontSize: 16,
        textFont: 'var(--font-family)',
        textAlignment: 'space-between',
        borderWidth: 0,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 10,
        paddingBottom: 10,
        iconSize: 32,
        iconUnit: 'px',
      },
      widgetProperties: {
        textContent: '<p class="mb-0 ml-1">Freeze</p>',
        icon: 'ac-unit',
        freezeToggleSettings: {
          defaultToggle: DefaultToggleOption.OFF,
          type: 'with-toggle',
        },
      },
    },
    isRemovable: true,
  },
  gridPosition: {
    x: 0,
    y: 18,
    minItemCols: 1,
    minItemRows: 1,
    rows: 3,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: '',
} as WorkflowWidgetConfig;

class FreezeCardToggleWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(freezeCardToggleWidgetConfig);
  }
}

export const freezeCardToggleWidget: WorkflowWidgetConfig = new FreezeCardToggleWidgetBuilder().build();

// Show PIN CTA button
export const createShowPINCTAButton = new FormSubmissionButtonWidgetBuilder()
  .configDisplayName('Button: Show PIN')
  .attributes({
    widgetProperties: {
      textContent: 'Show PIN',
      zoneToNavigate: APP_ZONES.CARD_MANAGEMENT_ZONE,
      urlToNavigate: 'show-pin',
      buttonActionType: ButtonActionType.SHOW_PIN,
      lefticon: 'dialpad',
      righticon: 'chevron-forward',
    } as BaseWidgetProperties,
  } as WorkflowWidgetConfigurationAttributes)
  .cssConfig({
    color: 'var(--color-on-surface)',
    backGroundColor: 'var(--color-background)',
    lefticonSize: 24,
    righticonSize: 24,
    marginRight: 10,
    marginLeft: 10,
  })
  .hideCondition("eq cards.currentCardDetail.type 'virtual'")
  .gridPosition({
    y: 7,
    x: 0,
    rows: 3,
    cols: 15,
  } as GridPosition)
  .build();

// Show Pin Multi Icon Quicklink

export const showPinMultiIconQL: WorkflowWidgetConfig = new MultiIconQuickLinkWidgetBuilder()
  .icons({
    left: 'dialpad',
    right: 'chevron-forward',
  })
  .template('Show Pin')
  .zone(APP_ZONES.PAYMENT)
  .role()
  .navigateToPageUrl('show-pin')
  .configDisplayName('Multi Icon: Show Pin')
  .navigationOption('use-as-navigation')
  .isRemovable(false)
  .cssConfig({
    ...Constants.defaultBaseCssConfig,
    fontSize: 16,
    textFont: 'var(--font-family)',
    textAlignment: 'space-between',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
    paddingBottom: 10,
  })
  .gridPosition({ y: 18 } as GridPosition)
  .hideCondition("eq cards.currentCardDetail.type 'virtual'")
  .build();

// Text Widget - "Show Pin"

export const showPinTextWidget: WorkflowWidgetConfig = new TextWidgetBuilder()
  .text(
    'Note: The "Show PIN" feature will be unavailable for virtual cards. It is exclusive to physical cards.',
  )
  .configDisplayName('Text: Show Pin')
  .cssConfig({
    ...Constants.defaultBaseCssConfig,
    paddingLeft: 10,
    paddingRight: 10,
  })
  .gridPosition({ y: 21, cols: 15 } as GridPosition)
  .hideCondition("eq cards.currentCardDetail.type 'virtual'")
  .build();

// Add Card Multi Icon Quicklink

export const addCardMultiIconQL: WorkflowWidgetConfig = new MultiIconQuickLinkWidgetBuilder()
  .icons({
    left: 'add-card',
    right: 'chevron-forward',
  })
  .template('Add Card')
  .zone(APP_ZONES.PAYMENT)
  .role()
  .navigateToPageUrl('add-card')
  .configDisplayName('Multi Icon QL: Add Card')
  .navigationOption('use-as-navigation')
  .isRemovable(false)
  .cssConfig({
    ...Constants.defaultBaseCssConfig,
    fontSize: 16,
    textFont: 'var(--font-family)',
    textAlignment: 'space-between',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
    paddingBottom: 10,
  })
  .gridPosition({ y: 23 } as GridPosition)
  .build();

// Replace Card Multi Icon Quicklink

export const replaceCardMultiIconQL: WorkflowWidgetConfig = new MultiIconQuickLinkWidgetBuilder()
  .icons({
    left: 'credit-card-off',
    right: 'chevron-forward',
  })
  .template('Replace Card')
  .zone(APP_ZONES.PAYMENT)
  .role()
  .navigateToPageUrl('replace-card')
  .configDisplayName('Multi Icon QL: Replace Card')
  .navigationOption('use-as-navigation')
  .isRemovable(false)
  .cssConfig({
    ...Constants.defaultBaseCssConfig,
    fontSize: 16,
    textFont: 'var(--font-family)',
    textAlignment: 'space-between',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
    paddingBottom: 10,
  })
  .gridPosition({ y: 32 } as GridPosition)
  .build();

// Quicklinks

const sharedQLStyles = {
  borderWidth: 1,
  borderColor: 'var(--border-color-1)',
  borderRadiusTopLeft: 66,
  borderRadiusTopRight: 66,
  borderRadiusBottomLeft: 66,
  borderRadiusBottomRight: 66,
  paddingLeft: 9,
};

export const transactionIconQuicklink: WorkflowWidgetConfig = new QuickLinkWidgetBuilder()
  .icon('compare-arrows' ?? '')
  .zone(APP_ZONES.CARD_MANAGEMENT_ZONE)
  .navigateToPageUrl('card-transactions')
  .navigationOption('use-as-navigation')
  .role()
  .text('')
  .gridPosition({
    x: 1,
    y: 15,
    rows: 2,
    cols: 3,
  } as GridPosition)
  .cssConfig({
    ...sharedQLStyles,
    paddingLeft: 0,
  })
  .build();

export const cardDetailsQuicklink: WorkflowWidgetConfig = new FormSubmissionButtonWidgetBuilder()
  .configDisplayName('Button: Card Details')
  .attributes({
    widgetProperties: {
      textContent: '',
      zoneToNavigate: APP_ZONES.CARD_MANAGEMENT_ZONE,
      urlToNavigate: 'card-details',
      buttonActionType: ButtonActionType.GET_SENSITIVE_CARD_DETAILS,
      lefticon: 'problem',
    } as BaseWidgetProperties,
  } as WorkflowWidgetConfigurationAttributes)
  .gridPosition({
    x: 6,
    y: 15,
    rows: 2,
    cols: 3,
  } as GridPosition)
  .cssConfig({
    ...sharedQLStyles,
    color: 'var(--color-on-surface)',
    backGroundColor: 'var(--color-background)',
    paddingLeft: 0,
  })
  .build();

export const cardLimitQuicklink: WorkflowWidgetConfig = new QuickLinkWidgetBuilder()
  .icon('bar-chart' ?? '')
  .zone(APP_ZONES.CARD_MANAGEMENT_ZONE)
  .navigateToPageUrl('show-card-limits')
  .navigationOption('use-as-navigation')
  .role()
  .text('')
  .gridPosition({
    x: 11,
    y: 15,
    rows: 2,
    cols: 3,
  } as GridPosition)
  .cssConfig({
    ...sharedQLStyles,
    paddingLeft: 0,
  })
  .build();

export const addFundsIconQuicklink: WorkflowWidgetConfig = new QuickLinkWidgetBuilder()
  .icon('mat_add' ?? '')
  .zone(APP_ZONES.CARD_MANAGEMENT_ZONE)
  .navigateToPageUrl('top-up-input-amount')
  .navigationOption('use-as-navigation')
  .role()
  .text('Fund Card')
  .gridPosition({
    x: 9,
    y: 0,
    rows: 2,
    cols: 6,
  } as GridPosition)
  .cssConfig({
    ...sharedQLStyles,
    backGroundColor: 'var(--border-color-1)',
    fontSize: 14,
    textAlignment: 'left',
    paddingLeft: 13,
  })
  .build();

// Text Widgets

export const cardBalanceTextWidget: WorkflowWidgetConfig = new TextWidgetBuilder()
  .text('£ 15,542.56')
  .configDisplayName('Text: Card Balance')
  .gridPosition({
    x: 0,
    y: 0,
    rows: 1,
    cols: 7,
  } as GridPosition)
  .cssConfig({
    fontSize: 20,
    textAlignment: 'left',
  })
  .build();

export const availableBalanceTextWidget: WorkflowWidgetConfig = new TextWidgetBuilder()
  .text('Available Balance')
  .configDisplayName('Text: Available Balance')
  .gridPosition({
    x: 0,
    y: 1,
    rows: 1,
    cols: 7,
  } as GridPosition)
  .cssConfig({
    fontSize: 14,
    color: 'var(--palette-color-1)',
    textAlignment: 'left',
  })
  .build();

export const transactionsTextWidget: WorkflowWidgetConfig = new TextWidgetBuilder()
  .text('Transactions')
  .gridPosition({
    x: 0,
    y: 14,
    rows: 1,
    cols: 5,
  } as GridPosition)
  .cssConfig({
    fontSize: 14,
  })
  .build();

export const cardDetailsTextWidget: WorkflowWidgetConfig = new TextWidgetBuilder()
  .text('Card Details')
  .configDisplayName('Text: Card Details')
  .gridPosition({
    x: 5,
    y: 14,
    rows: 1,
    cols: 5,
  } as GridPosition)
  .cssConfig({
    fontSize: 14,
  })
  .build();

export const cardLimitTextWidget: WorkflowWidgetConfig = new TextWidgetBuilder()
  .text('View Limits')
  .configDisplayName('Text: View Limits')
  .gridPosition({
    x: 10,
    y: 14,
    rows: 1,
    cols: 5,
  } as GridPosition)
  .cssConfig({
    fontSize: 14,
  })
  .build();

export const cancelCardMultiIconQL: WorkflowWidgetConfig = new MultiIconQuickLinkWidgetBuilder()
  .icons({
    left: 'credit-card-off',
    right: 'chevron-forward',
  })
  .template('Cancel Card')
  .zone(APP_ZONES.PAYMENT)
  .role()
  .navigateToPageUrl('cancel-card')
  .configDisplayName('Multi Icon QL: Cancel Card')
  .navigationOption('use-as-navigation')
  .isRemovable(false)
  .cssConfig({
    ...Constants.defaultBaseCssConfig,
    fontSize: 16,
    textFont: 'var(--font-family)',
    textAlignment: 'space-between',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
    paddingBottom: 10,
  })
  .gridPosition({ y: 26 } as GridPosition)
  .build();

export const editCardNameMultiIconQL: WorkflowWidgetConfig = new MultiIconQuickLinkWidgetBuilder()
  .icons({
    left: 'edit-card',
    right: 'chevron-forward',
  })
  .template('Edit Card Name')
  .zone(APP_ZONES.PAYMENT)
  .role()
  .navigateToPageUrl('set-card-name')
  .configDisplayName('Multi Icon QL: Add Card')
  .navigationOption('use-as-navigation')
  .isRemovable(false)
  .cssConfig({
    ...Constants.defaultBaseCssConfig,
    fontSize: 16,
    textFont: 'var(--font-family)',
    textAlignment: 'space-between',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
    paddingBottom: 10,
  })
  .gridPosition({ y: 29 } as GridPosition)
  .build();
