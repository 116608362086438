import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { FormActionTypeEnum } from '@finxone-platform/form-action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import {
  AdHocFeeRequest,
  BaseWidgetProperties,
  ClientDetailsType,
  FeeItem,
  FeeUnitChargeTypeEnum,
} from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { take, tap } from 'rxjs';
import { ValidationFeeDetails } from '../../../actions/fee-management.action';
import { ResetFormDataAction, UpdateFormDataActionWithId } from '../../../actions/form-submission.action';
import { FeeManagementService } from '../../../services/fee-management-service/fee-management.service';
import { FeeManagementState } from '../../../state/fee-management.state';
import { FormActionState } from '../../../state/form-submision.state';
import { removeNullUndefinedValuesFromObject } from '../../utils';
import { redirectToPage } from '../cta-button-actions.utils';

/**
 * Handles the submission of an ad-hoc fee form by validating form data and client details.
 * If the form is valid, it creates a payload for the fee request and removes any null or undefined values.
 * Depending on whether a fee ID is present, it either submits an edit request or a create request.
 * If the form is invalid or required data is missing, it triggers an alert to show form errors.
 *
 * @param widgetProperties - The properties of the widget initiating the fee submission.
 * @param injector - The injector used to retrieve necessary services and state.
 */
export function handleAdHocFeeSubmission(widgetProperties: BaseWidgetProperties, injector: Injector) {
  const store = injector.get(Store);
  const alertHandlerService = injector.get(AlertHandlerService);
  const formDataWithId = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.ADD_EDIT_FEE),
  )?.formData;
  const formData = store.selectSnapshot(FormActionState.getFormActionState)?.response?.formData;
  const clientDetails = store.selectSnapshot(FeeManagementState.getFeeManagement)?.selectedClientDetails;
  const selectedFeeDetails = store.selectSnapshot(FeeManagementState.getFeeManagement)?.selectedFeeData;
  if (formDataWithId && clientDetails && formData) {
    if (formDataWithId?.isCurrencyFeeFormValid) {
      const feeChargeValue =
        formDataWithId.feeDetails.unitCharge.value === FeeUnitChargeTypeEnum.FIXED_FEE
          ? formDataWithId.feeDetails?.feeAmount
          : formDataWithId.feeDetails?.feePercentage;
      let payload = createPayload(
        feeChargeValue,
        clientDetails,
        formData,
        formDataWithId,
        selectedFeeDetails,
      );
      payload = removeNullUndefinedValuesFromObject<AdHocFeeRequest>(payload);
      if (selectedFeeDetails?.id) {
        submitEditRequest(injector, widgetProperties, payload, selectedFeeDetails.id);
      } else {
        submitCreateRequest(injector, widgetProperties, payload);
      }
    } else {
      showAlertAndFormErrors(store, alertHandlerService);
    }
  } else {
    showAlertAndFormErrors(store, alertHandlerService);
  }
}

function createPayload(
  feeChargeValue: number,
  clientDetails: ClientDetailsType,
  formData: any,
  formDataWithId: any,
  selectedFeeDetails?: FeeItem,
): AdHocFeeRequest {
  return {
    name: formDataWithId.feeDetails?.feeName,
    currency: formData?.currency ?? selectedFeeDetails?.currency,
    clientId: clientDetails.id,
    accountId: formData?.accountId ?? selectedFeeDetails?.adHocFee?.accountId,
    unitChargeType: formDataWithId.feeDetails?.unitCharge.value,
    unitChargeInfo: {
      feeCharge: feeChargeValue,
      minFeeAmount: formDataWithId.feeDetails?.minimumFeeAmount,
      maxFeeAmount: formDataWithId.feeDetails?.maximumFeeAmount,
    },
    frequencyType: formDataWithId.scheduleDetails?.frequency.id,
    frequencyInfo: {
      startDate: formDataWithId.scheduleDetails?.effectiveFrom,
      endDate: formDataWithId.scheduleDetails?.endDate,
    },
  };
}

/**
 * Submits an edit request for a fee using the FeeManagementService.
 *
 * @param injector - The Injector instance to retrieve required services.
 * @param widgetProperties - Properties of the widget for navigation purposes.
 * @param payload - The payload containing the fee details to be edited.
 * @param id - The unique identifier of the fee to be edited.
 *
 * Utilizes the FeeManagementService to submit the edit request and handles
 * the response. On success, it shows a success alert, resets form data,
 * validates fee details, and redirects to a specified page.
 */
function submitEditRequest(
  injector: Injector,
  widgetProperties: BaseWidgetProperties,
  payload: AdHocFeeRequest,
  id: string,
) {
  const feeManagementService = injector.get(FeeManagementService);
  const store = injector.get(Store);
  const router = injector.get(Router);
  const alertHandlerService = injector.get(AlertHandlerService);
  feeManagementService
    .submitEditFeeRequest(payload, id)
    .pipe(
      take(1),
      tap((res) => {
        if (res) {
          alertHandlerService.showAlertFn('success', 'Fee updated successfully');
          store.dispatch(new ResetFormDataAction());
          store.dispatch(new ValidationFeeDetails(res));
          redirectToPage(router, widgetProperties, injector);
        }
      }),
    )
    .subscribe();
}

/**
 * Submits a request to create an ad-hoc fee using the provided payload.
 *
 * @param injector - The Injector instance used to retrieve required services.
 * @param widgetProperties - Properties of the widget initiating the request.
 * @param payload - The AdHocFeeRequest payload containing fee details.
 *
 * Utilizes the FeeManagementService to send the creation request. Upon successful
 * creation, it triggers a success alert, resets form data, validates fee details,
 * and redirects to a specified page.
 */
function submitCreateRequest(
  injector: Injector,
  widgetProperties: BaseWidgetProperties,
  payload: AdHocFeeRequest,
) {
  const feeManagementService = injector.get(FeeManagementService);
  const store = injector.get(Store);
  const router = injector.get(Router);
  const alertHandlerService = injector.get(AlertHandlerService);
  feeManagementService
    .createAdHocFeeFeeRequest(payload)
    .pipe(
      take(1),
      tap((res) => {
        if (res) {
          alertHandlerService.showAlertFn('success', 'Fee created successfully');
          store.dispatch(new ResetFormDataAction());
          store.dispatch(new ValidationFeeDetails(res));
          redirectToPage(router, widgetProperties, injector);
        }
      }),
    )
    .subscribe();
}

function showAlertAndFormErrors(store: Store, alertHandlerService: AlertHandlerService) {
  alertHandlerService.showAlertFn('error', 'Please provide all details in the form before proceeding');
  store.dispatch(new UpdateFormDataActionWithId({ showFormErrors: true }, FormActionTypeEnum.ADD_EDIT_FEE));
}
