export enum ProfileErrorCodes {
  FIRST_NAME_REQUIRED = 'profile_first_name_required',
  FIRST_NAME_IS_STRING = 'profile_first_name_is_string',
  MIDDLE_NAME_IS_STRING = 'profile_middle_name_is_string',
  LAST_NAME_REQUIRED = 'profile_last_name_required',
  LAST_NAME_IS_STRING = 'profile_last_name_is_string',
  PASSWORD_REQUIRED = 'profile_password_required',
  PASSWORD_IS_STRING = 'profile_password_is_string',
  COUNTRY_REQUIRED = 'profile_country_required',
  COUNTRY_IS_STRING = 'profile_country_is_string',
  COUNTRY_IS_ISO31661ALPHA2 = 'profile_country_is_ISO31661Alpha2',
  ADDRESS1_IS_STRING = 'profile_address1_is_string',
  ADDRESS2_IS_STRING = 'profile_address2_is_string',
  ADDRESS3_IS_STRING = 'profile_address3_is_string',
  ADDRESS4_IS_STRING = 'profile_address4_is_string',
  FLAT_NAME_IS_STRING = 'profile_flat_name_is_string',
  CITY_REQUIRED = 'profile_city_required',
  CITY_IS_STRING = 'profile_city_is_string',
  POSTAL_CODE_REQUIRED = 'profile_postal_code_required',
  POSTAL_CODE_IS_STRING = 'profile_postal_code_is_string',
  DOB_REQUIRED = 'profile_dob_required',
  DOB_IS_STRING = 'profile_dob_is_string',
  DOB_IS_DATE_STRING = 'profile_dob_is_date_string',
  EMAIL_REQUIRED = 'profile_email_required',
  EMAIL_INVALID = 'profile_email_invalid',
  EMAIL_VERIFIED_BOOLEAN = 'profile_email_verified_boolean',
  USERNAME_IS_STRING = 'profile_username_is_string',
  PROPERTY_NAME_IS_STRING = 'profile_property_name_is_string',
  PROPERTY_NUMBER_IS_STRING = 'profile_property_number_is_string',
  ORGANISATION_ID_IS_STRING = 'profile_organisation_id_is_string',
  ORGANISATION_ID_IS_UUID = 'profile_organisation_id_is_uuid',
  PHONE_REQUIRED = 'profile_phone_required',
  PHONE_IS_STRING = 'profile_phone_is_string',
  PHONE_IS_VALID = 'profile_phone_is_valid',
  PHONE_VERIFIED_BOOLEAN = 'profile_phone_verified_boolean',
  ROLE_ID_IS_STRING = 'profile_role_id_is_string',

  //Change password codes
  CURRENT_PASSWORD_REQUIRED = 'profile_current_password_required',
  CURRENT_PASSWORD_NOT_MATCH = 'profile_current_password_not_match',
  PASSWORD_MIN_LENGTH = 'profile_password_min_length',
  PASSWORD_MAX_LENGTH = 'profile_password_max_length',
  CONFIRM_PASSWORD_REQUIRED = 'profile_confirm_password_required',
  CONFIRM_PASSWORD_MIN_LENGTH = 'profile_confirm_password_min_length',
  CONFIRM_PASSWORD_MAX_LENGTH = 'profile_confirm_password_max_length',
  NEW_AND_CONFIRM_PASSWORD_NOT_MATCH = 'profile_new_and_confirm_password_not_match',
  USER_NOT_EXIST = 'profile_user_not_exist',

  //Biometric Authentication
  BIOMETRIC_AUTH_ENABLED_SUCCESSFULLY = 'enabled_successfully',
  BIOMETRIC_AUTH_DISABLED_SUCCESSFULLY = 'disabled_successfully',
}
