import { APP_ZONES, GridPosition } from '@finxone-platform/shared/sys-config-types';
import { commonQuickLink, commonTextWidget } from '../../../common/common-widgets';

export const infoPageWidgets = [
  commonTextWidget({
    textContent: `Locate your tax refund kiosks`,
    configDisplayName: 'Heading',
    cssConfig: {
      textAlignment: 'center',
      fontSize: 24,
      isTextBold: true,
      textFont: 'var(--font-family)',
    },
    gridPosition: {
      y: 2,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 15,
    },
  }),

  commonTextWidget({
    textContent: 'At your final point of departure please locate your tax refund kiosk.',
    cssConfig: {
      fontSize: 20,
      textAlignment: 'center',
      textFont: 'var(--font-family)',
    },
    gridPosition: {
      x: 0,
      y: 23,
      rows: 3,
      cols: 15,
    } as GridPosition,
  }),

  commonQuickLink({
    text: 'Continue',
    zone: APP_ZONES.REVENIR_ZONE,
    navigateToPageUrl: 'info-page-2',
    configDisplayName: 'Navigate: Continue',
    cssConfig: {
      fontSize: 16,
      color: 'var(--palette-color-6)',
      backGroundColor: 'var(--palette-color-3)',
      marginTop: 0,
      marginBottom: 0,
      textFont: 'var(--font-family)',
    },
  }),
];

export const infoPage2Widgets = [
  commonTextWidget({
    textContent: `Refund Confirmation`,
    configDisplayName: 'Heading',
    cssConfig: {
      textAlignment: 'center',
      fontSize: 24,
      isTextBold: true,
      textFont: 'var(--font-family)',
    },
    gridPosition: {
      y: 2,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 15,
    },
  }),

  commonTextWidget({
    textContent: 'You will receive confirmation that your refund has been paid.',
    cssConfig: {
      fontSize: 20,
      textAlignment: 'center',
      textFont: 'var(--font-family)',
    },
    gridPosition: {
      x: 0,
      y: 23,
      rows: 3,
      cols: 15,
    } as GridPosition,
  }),

  commonQuickLink({
    text: 'Continue',
    zone: APP_ZONES.REVENIR_ZONE,
    navigateToPageUrl: 'info-page-3',
    configDisplayName: 'Navigate: Continue',
    cssConfig: {
      fontSize: 16,
      color: 'var(--palette-color-6)',
      backGroundColor: 'var(--palette-color-3)',
      textFont: 'var(--font-family)',
      marginTop: 0,
      marginBottom: 0,
    },
  }),
];

export const infoPage3Widgets = [
  commonTextWidget({
    textContent: `Scan Your Barcodes`,
    configDisplayName: 'Heading',
    cssConfig: {
      textAlignment: 'center',
      textFont: 'var(--font-family)',
      fontSize: 24,
      isTextBold: true,
    },
    gridPosition: {
      y: 2,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 15,
    },
  }),

  commonTextWidget({
    textContent: 'Scan all the barcodes that you have in your Trip to get your full refund.',
    cssConfig: {
      fontSize: 20,
      textAlignment: 'center',
      textFont: 'var(--font-family)',
    },
    gridPosition: {
      x: 0,
      y: 23,
      rows: 3,
      cols: 15,
    } as GridPosition,
  }),

  commonQuickLink({
    text: 'Continue',
    zone: APP_ZONES.REVENIR_ZONE,
    navigateToPageUrl: 'barcodes',
    configDisplayName: 'Navigate: Continue',
    cssConfig: {
      fontSize: 16,
      color: 'var(--palette-color-6)',
      backGroundColor: 'var(--palette-color-3)',
      textFont: 'var(--font-family)',
      marginTop: 0,
      marginBottom: 0,
    },
  }),
];
