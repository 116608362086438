import {
  APP_ZONES,
  BaseWidgetProperties,
  GridPosition,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import { GroupsListingWidgetBuilder } from '../../../defaults/widgets/example-builder';
import { inputSettings } from '../../../defaults/widgets/payments/payments.type';
import { getBackButtonWidget } from '../../../defaults/zones/manage-users-zone/common-utils';
import {
  generateTextWidget,
  getQuickLinkWidget,
} from '../../../defaults/zones/payments/common-widgets-utils';

const groupsListingColumns = [
  {
    label: 'Group name',
    value: `groupName`,
    handleBarKey: '{{name}}',
    selected: true,
  },
  {
    label: 'Number of clients linked:',
    value: `number-of-clients-linked`,
    handleBarKey: '{{clientLinked}}',
    selected: true,
  },
  {
    label: 'Group creation date:',
    value: `group-creation-date`,
    handleBarKey: "{{ datePipe createdAt format='d MMM y'}}",
    selected: true,
  },
];

export const groupListingWidgets = [
  generateTextWidget({
    text: 'Fee Groups Listing',
    position: { x: 1, y: 0, rows: 1, cols: 20 } as GridPosition,
    displayName: 'Header Text',
    styles: {
      fontSize: 24,
      isTextBold: true,
      verticalAlignment: 'start',
      textAlignment: 'left',
      noAlignment: true,
    },
    isRemovable: true,
  }),

  generateTextWidget({
    text: 'List the groups created to date. Drill down into each group to view the clients linked with that particular group.',
    position: { x: 1, y: 1, rows: 2, cols: 27 } as GridPosition,
    displayName: 'Information Content',
    styles: {
      verticalAlignment: 'start',
      textAlignment: 'left',
      fontSize: '16',
      lineHeight: '24',
      textFont: 'var(--font-family)',
      color: 'var(--typography-color-1)',
    },
    isRemovable: true,
  }),

  getBackButtonWidget({
    position: {
      y: 0,
      x: 32,
      rows: 1,
      cols: 4,
    } as GridPosition,
    styles: {
      color: 'var(--palette-color-3)',
      backGroundColor: 'var(--palette-color-6)',
      borderWidth: 1,
      borderColor: 'var(--border-color-0)',
      fontSize: 14,
      paddingLeft: 20,
      paddingRight: 20,
      textAlign: 'center',
      verticalAlignment: 'center',
    },
  }),

  getQuickLinkWidget({
    text: 'Add/Edit Fee',
    position: {
      x: 37,
      y: 0,
      rows: 1,
      cols: 4,
    } as GridPosition,
    pageUrl: 'group-fee-charge-add-edit-fee',
    displayName: 'Quick Link: Add/Edit Fee',
    styles: {
      borderWidth: 1,
      color: 'var(--palette-color-3)',
      fontSize: '16',
      backGroundColor: 'var(--palette-color-6)',
      textAlignment: 'center',
      justifyContent: 'center',
      borderColor: 'var(--palette-color-3)',
      borderStyle: 'solid',
      borderRadiusTopLeft: 5,
      borderRadiusBottomLeft: 5,
      borderRadiusTopRight: 5,
      borderRadiusBottomRight: 5,
    },
    zone: APP_ZONES.FEE_MANAGEMENT,
    isRemovable: true,
  }),

  getQuickLinkWidget({
    text: 'Add New Group',
    position: {
      x: 42,
      y: 0,
      rows: 1,
      cols: 5,
    } as GridPosition,
    pageUrl: 'add-edit-group-details',
    displayName: 'Quick Link: Add New Group',
    styles: {
      borderWidth: 1,
      color: 'var(--palette-color-6)',
      fontSize: '16',
      backGroundColor: 'var(--palette-color-3)',
      textAlignment: 'center',
      justifyContent: 'center',
      borderColor: 'var(--palette-color-3)',
      borderStyle: 'solid',
      borderRadiusTopLeft: 5,
      borderRadiusBottomLeft: 5,
      borderRadiusTopRight: 5,
      borderRadiusBottomRight: 5,
    },
    zone: APP_ZONES.FEE_MANAGEMENT,
    isRemovable: true,
  }),

  new GroupsListingWidgetBuilder()
    .text('')
    .configDisplayName(`Listing Widget`)
    .gridPosition({ x: 1, y: 4, rows: 15, cols: 46 } as GridPosition)
    .cssConfig({
      color: 'var(--palette-color-1)',
      textAlignment: 'left',
      justifyContent: 'left',
      fontSize: '16',
      borderWidth: 1,
      borderColor: 'var(--palette-color-2)',
      backGroundColor: 'var(--palette-color-6)',
      borderStyle: 'solid',
      borderRadiusUnit: 'px',
    })
    .attributes({
      widgetProperties: {
        icon: 'search',
        textContent: ``,
        showEntity: false,
        memuOptions: [
          { label: 'View group', value: 'view-group' },
          { label: 'Edit group', value: 'edit-group' },
          { label: 'Remove group', value: 'remove' },
        ],
      } as BaseWidgetProperties,
      columns: groupsListingColumns,
    } as unknown as WorkflowWidgetConfigurationAttributes)
    .inputSetting({
      ...inputSettings,
      css_config: {
        ...inputSettings.css_config,
        inputWidth: 120,
      },
      label: 'Search',
      isIconAllowed: true,
    })
    .isRemovable(false)
    .build(),
];
