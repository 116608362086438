import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonActionType,
  ButtonSizeOptions,
  ButtonTypes,
  Constants,
  GridPosition,
  WorkflowWidgetConfig,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import {
  BackButtonWidgetBuilder,
  CardNameWidgetBuilder,
  CardSensitiveDetailsBuilder,
  CardsWidgetBuilder,
  FormSubmissionButtonWidgetBuilder,
  MultiIconQuickLinkWidgetBuilder,
  QuickLinkWidgetBuilder,
  TextWidgetBuilder,
  addFundsIconQuicklink,
  availableBalanceTextWidget,
  cardBalanceTextWidget,
} from '../../../widgets';

export const cardDetailsWidgets: WorkflowWidgetConfig[] = [
  cardBalanceTextWidget,
  availableBalanceTextWidget,
  addFundsIconQuicklink,
  new CardsWidgetBuilder()
    .cssConfig({
      borderWidth: 0,
      backGroundColor: 'var(--border-color-1)',
    })
    .gridPosition({ y: 3 } as GridPosition)
    .build(),

  new FormSubmissionButtonWidgetBuilder()
    .configDisplayName('Button: Share Details')
    .attributes({
      widgetProperties: {
        textContent: 'Share Details',
        zoneToNavigate: APP_ZONES.CARD_MANAGEMENT_ZONE,
        buttonActionType: ButtonActionType.SECURE_CVV_DETAILS,
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .cssConfig({
      borderRadiusUnit: 'px',
      borderRadiusTopLeft: 33,
      borderRadiusBottomLeft: 33,
      borderRadiusTopRight: 33,
      borderRadiusBottomRight: 33,
      backGroundColor: 'var(--border-color-1)',
      color: 'var(--palette-color-0)',
      fontSize: 14,
    })
    .gridPosition({ y: 11, x: 4, rows: 2, cols: 7 } as GridPosition)
    .addCustomWidgetProperties({
      lefticon: 'mat_share',
    })
    .build(),

  new CardSensitiveDetailsBuilder().gridPosition({ y: 14 } as GridPosition).build(),
  new TextWidgetBuilder()
    .text('<div class="font-weight-medium">More Actions<div>')
    .cssConfig({
      fontSize: '20',
      fontUnit: 'px',
      textAlignment: 'flex-start',
    })
    .gridPosition({ y: 22, rows: 1 } as GridPosition)
    .build(),

  new MultiIconQuickLinkWidgetBuilder()
    .icons({
      left: 'credit-card-off',
      right: 'arrow-right',
    })
    .template('Replace Card')
    .zone(APP_ZONES.CARD_MANAGEMENT_ZONE)
    .role()
    .navigateToPageUrl('replace-card')
    .configDisplayName('Multi Icon QL: Replace Card')
    .navigationOption('use-as-navigation')
    .isRemovable(false)
    .cssConfig({
      ...Constants.defaultBaseCssConfig,
      fontSize: 16,
      textFont: 'var(--font-family)',
      textAlignment: 'space-between',
      paddingTop: 10,
      paddingBottom: 10,
      rightIconSize: 24,
      leftIconSize: 32,
      rightIconColor: 'var(--typography-color-1)',
    })
    .gridPosition({ y: 23 } as GridPosition)
    .build(),

  new QuickLinkWidgetBuilder()
    .zone(APP_ZONES.CARD_MANAGEMENT_ZONE)
    .navigateToPageUrl('landing-page')
    .navigationOption('use-as-navigation')
    .role()
    .configDisplayName('Quick Link: Back')
    .text('Back')
    .gridPosition({ y: 27, rows: 2 } as GridPosition)
    .cssConfig({
      backGroundColor: 'var(--palette-color-6)',
      color: 'var(--typography-color-0)',
      borderRadiusTopLeft: 3,
      borderRadiusBottomLeft: 3,
      borderRadiusTopRight: 3,
      borderRadiusBottomRight: 3,
      fontSize: 14,
      borderWidth: '1',
      borderColor: 'var(--typography-color-0)',
    })
    .isRemovable(true)
    .build(),
];

export const cancelCardConfirmationWidgets: WorkflowWidgetConfig[] = [
  new TextWidgetBuilder()
    .configDisplayName('Text Widget: Cancel card')
    .setWidgetProperties({ textStyleClass: 'h6', textContent: 'Cancel Card' })
    .cssConfig({
      color: 'var(--color-on-background)',
      textAlignment: 'flex-start',
      textFont: 'var(--font-family)',
    })
    .gridPosition({
      x: 0,
      y: 0,
      rows: 2,
      cols: 15,
    } as GridPosition)
    .build(),

  new TextWidgetBuilder()
    .configDisplayName('Text Widget: Confirm Cancel Card Question')
    .setWidgetProperties({
      textStyleClass: 'subtitle1',
      textContent: 'Are you sure you want to cancel this card?',
    })
    .cssConfig({
      color: 'var(--color-on-background-muted)',
      textAlignment: 'flex-start',
      textFont: 'var(--font-family)',
    })
    .gridPosition({
      x: 0,
      y: 2,
      rows: 2,
      cols: 15,
    } as GridPosition)
    .build(),

  new TextWidgetBuilder()
    .configDisplayName('Text Widget: Cancel Card Instructions')
    .setWidgetProperties({
      textStyleClass: 'subtitle1',
      textContent: 'This action cannot be undone. Any deposits will also be cancelled.',
    })
    .cssConfig({
      color: 'var(--color-on-background-muted)',
      textAlignment: 'flex-start',
      textFont: 'var(--font-family)',
    })
    .gridPosition({
      x: 0,
      y: 4,
      rows: 3,
      cols: 15,
    } as GridPosition)
    .build(),

  new TextWidgetBuilder()
    .configDisplayName('Text Widget: Card Details')
    .text(
      ` <div class="w-100">
          <div class="d-flex is-space-between mb-5">
            <div class="labelText" style="color: var(--color-on-surface-muted)">Card Number</div>
            <div class="body1" style="color: var(--color-on-surface)">{{cards.currentCardDetail.maskedPan}}</div>
          </div>
          <div class="d-flex is-space-between">
            <div class="labelText" style="color: var(--color-on-surface-muted)">Available Balance</div>
            <div class="body1" style="color: var(--color-on-surface)">{{ currencyPipe cards.currentCardDetail.availableBalance currencyCode=cards.currentCardDetail.currency display="symbol-narrow" }}</div>
          </div>
        </div>`,
    )
    .cssConfig({
      textFont: 'var(--font-family)',
      textAlignment: 'flex-start',
      backGroundColor: 'var(--color-surface)',
      paddingTop: 24,
      paddingRight: 20,
      paddingLeft: 20,
      paddingBottom: 24,
      borderRadiusTopLeft: 8,
      borderRadiusBottomLeft: 8,
      borderRadiusTopRight: 8,
      borderRadiusBottomRight: 8,
      borderRadiusUnit: 'px',
    })
    .gridPosition({
      x: 0,
      y: 8,
      rows: 4,
      cols: 15,
    } as GridPosition)
    .build(),

  new FormSubmissionButtonWidgetBuilder()
    .configDisplayName('Button: Submit Cancel card')
    .setWidgetProperties({
      textContent: 'Yes, cancel',
      zoneToNavigate: APP_ZONES.CARD_MANAGEMENT_ZONE,
      urlToNavigate: 'card-cancelled',
      buttonActionType: ButtonActionType.CANCEL_CARD,
    })
    .gridPosition({ y: 26, rows: 2 } as GridPosition)
    .cssConfig({
      borderRadiusUnit: 'px',
      borderRadiusTopLeft: 8,
      borderRadiusBottomLeft: 8,
      borderRadiusTopRight: 8,
      borderRadiusBottomRight: 8,
      fontSize: 16,
      textFont: 'var(--font-family)',
    })
    .setGlobalStylingClass(true, `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`)
    .build(),

  new BackButtonWidgetBuilder()
    .defaultBackButton()
    .cssConfig({
      borderRadiusUnit: 'px',
      borderRadiusTopLeft: 3,
      borderRadiusBottomLeft: 3,
      borderRadiusTopRight: 3,
      borderRadiusBottomRight: 3,
      fontSize: 16,
      textFont: 'var(--font-family)',
    })
    .gridPosition({ y: 29, rows: 2, cols: 15 } as GridPosition)
    .isRemovable(false)
    .setGlobalStylingClass(true, `${ButtonTypes.SECONDARY} ${ButtonSizeOptions.FULL_WIDTH}`)
    .build(),
];

export const cancelCardSuccessWidgets: WorkflowWidgetConfig[] = [
  new TextWidgetBuilder()
    .configDisplayName('Text Widget: Card Cancelled')
    .setWidgetProperties({ textStyleClass: 'h6', textContent: 'Card Cancelled' })
    .cssConfig({
      color: 'var(--color-on-background)',
      textAlignment: 'flex-start',
      textFont: 'var(--font-family)',
    })
    .gridPosition({
      x: 0,
      y: 0,
      rows: 2,
      cols: 15,
    } as GridPosition)
    .build(),

  new TextWidgetBuilder()
    .configDisplayName('Text Widget: Confirmation Cancel Card')
    .setWidgetProperties({
      textStyleClass: 'subtitle1',
      textContent: 'Your card has been successfully cancelled and rendered inactive.',
    })
    .cssConfig({
      color: 'var(--color-on-background-muted)',
      textAlignment: 'flex-start',
      textFont: 'var(--font-family)',
    })
    .gridPosition({
      x: 0,
      y: 2,
      rows: 2,
      cols: 15,
    } as GridPosition)
    .build(),

  new TextWidgetBuilder()
    .configDisplayName('Text Widget: Cancel Card Information')
    .setWidgetProperties({
      textStyleClass: 'subtitle1',
      textContent:
        'All balances will be credited to your account. Pending refunds may delay card termination, and credited amounts may take 1-2 business days to appear.',
    })
    .cssConfig({
      color: 'var(--color-on-background-muted)',
      textAlignment: 'flex-start',
      textFont: 'var(--font-family)',
    })
    .gridPosition({
      x: 0,
      y: 4,
      rows: 3,
      cols: 15,
    } as GridPosition)
    .build(),

  new TextWidgetBuilder()
    .configDisplayName('Text Widget: Card Details')
    .text(
      ` <div class="w-100">
          <div class="d-flex is-space-between mb-5">
            <div class="labelText" style="color: var(--color-on-surface-muted)">Card Number</div>
            <div class="body1" style="color: var(--color-on-surface)">{{cards.currentCardDetail.maskedPan}}</div>
          </div>
          <div class="d-flex is-space-between">
            <div class="labelText" style="color: var(--color-on-surface-muted)">Available Balance</div>
            <div class="body1" style="color: var(--color-on-surface)">{{ currencyPipe cards.currentCardDetail.availableBalance currencyCode=cards.currentCardDetail.currency display="symbol-narrow" }}</div>
          </div>
        </div>`,
    )
    .cssConfig({
      textFont: 'var(--font-family)',
      textAlignment: 'flex-start',
      backGroundColor: 'var(--color-surface)',
      paddingTop: 24,
      paddingRight: 20,
      paddingLeft: 20,
      paddingBottom: 24,
      borderRadiusTopLeft: 8,
      borderRadiusBottomLeft: 8,
      borderRadiusTopRight: 8,
      borderRadiusBottomRight: 8,
      borderRadiusUnit: 'px',
    })
    .gridPosition({
      x: 0,
      y: 8,
      rows: 4,
      cols: 15,
    } as GridPosition)
    .build(),

  new FormSubmissionButtonWidgetBuilder()
    .configDisplayName('Button: Submit Cancel card')
    .setWidgetProperties({
      textContent: 'Back to Home',
      zoneToNavigate: APP_ZONES.LANDING,
      urlToNavigate: 'home',
      buttonActionType: ButtonActionType.REFRESH_CARDS_AND_REDIRECT,
    })
    .gridPosition({ y: 28, rows: 3 } as GridPosition)
    .cssConfig({
      fontSize: 16,
      textFont: 'var(--font-family)',
    })
    .setGlobalStylingClass(true, `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`)
    .build(),
];

export const setCardNameWidgets: WorkflowWidgetConfig[] = [
  new TextWidgetBuilder()
    .configDisplayName('Text Widget: Set Card Name')
    .setWidgetProperties({ textStyleClass: 'h6', textContent: 'Set Card Name' })
    .cssConfig({
      color: 'var(--color-on-background)',
      textAlignment: 'flex-start',
      textFont: 'var(--font-family)',
    })
    .gridPosition({
      x: 0,
      y: 0,
      rows: 2,
      cols: 15,
    } as GridPosition)
    .build(),

  new TextWidgetBuilder()
    .configDisplayName('Text Widget: Set Card Name Instructions')
    .setWidgetProperties({
      textStyleClass: 'subtitle1',
      textContent: 'Set a name to make your card identifiable.',
    })
    .cssConfig({
      color: 'var(--color-on-background-muted)',
      textAlignment: 'flex-start',
      textFont: 'var(--font-family)',
    })
    .gridPosition({
      x: 0,
      y: 2,
      rows: 2,
      cols: 15,
    } as GridPosition)
    .build(),

  new CardNameWidgetBuilder().gridPosition({ x: 0, y: 5, rows: 4, cols: 15 } as GridPosition).build(),

  new TextWidgetBuilder()
    .configDisplayName('Text Widget: Set Card Name Examples')
    .setWidgetProperties({
      textStyleClass: 'subtitle2',
      textContent: 'Examples: General, Shopping, Household, Subscriptions, Kids, etc.',
    })
    .cssConfig({
      color: 'var(--color-on-background-muted)',
      textAlignment: 'flex-start',
      textFont: 'var(--font-family)',
    })
    .gridPosition({
      x: 0,
      y: 8,
      rows: 2,
      cols: 15,
    } as GridPosition)
    .build(),

  new FormSubmissionButtonWidgetBuilder()
    .configDisplayName('Button: Submit Set Name')
    .setWidgetProperties({
      textContent: 'Set Name',
      zoneToNavigate: APP_ZONES.CARD_MANAGEMENT_ZONE,
      urlToNavigate: 'landing-page',
      buttonActionType: ButtonActionType.SET_CARD_NAME,
    })
    .gridPosition({ y: 26, rows: 2 } as GridPosition)
    .cssConfig({
      borderRadiusUnit: 'px',
      borderRadiusTopLeft: 8,
      borderRadiusBottomLeft: 8,
      borderRadiusTopRight: 8,
      borderRadiusBottomRight: 8,
      fontSize: 16,
      textFont: 'var(--font-family)',
    })
    .setGlobalStylingClass(true, `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`)
    .build(),

  new BackButtonWidgetBuilder()
    .defaultBackButton()
    .cssConfig({
      borderRadiusUnit: 'px',
      borderRadiusTopLeft: 3,
      borderRadiusBottomLeft: 3,
      borderRadiusTopRight: 3,
      borderRadiusBottomRight: 3,
      fontSize: 16,
      textFont: 'var(--font-family)',
    })
    .gridPosition({ y: 29, rows: 2, cols: 15 } as GridPosition)
    .isRemovable(false)
    .setGlobalStylingClass(true, `${ButtonTypes.SECONDARY} ${ButtonSizeOptions.FULL_WIDTH}`)
    .build(),
];
