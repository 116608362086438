import { revenirTransactionHistoryItemTemplate } from '@finxone-platform/shared/default-templates';
import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonActionType,
  GridPosition,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import { RevenirTransactionWidgetBuilder, TextWidgetBuilder } from '../../../widgets/example-builder';
import { getAddAndSelectTripWidget } from '../../../widgets/workflow-ui-constants.v2.type';
import { commonCTAButton, commonQuickLink } from '../../common/common-widgets';

export const tripLandingWidgets = [
  commonQuickLink({
    text: '',
    zone: APP_ZONES.PROFILE,
    navigateToPageUrl: 'settings',
    cssConfig: {
      color: 'var(--palette-color-0)',
      backGroundColor: 'var(--palette-color-6)',
      textFont: 'var(--font-family)',
    },
    gridPosition: { y: 0, x: 0, rows: 2, cols: 2 } as GridPosition,
    icon: 'burger-icon',
  }),

  commonQuickLink({
    text: '',
    zone: APP_ZONES.REVENIR_ZONE,
    navigateToPageUrl: 'kiosk-map',
    cssConfig: {
      color: 'var(--palette-color-0)',
      backGroundColor: 'var(--palette-color-6)',
    },
    gridPosition: { y: 0, x: 10, rows: 2, cols: 2 } as GridPosition,
    icon: 'map-icon',
  }),
  commonQuickLink({
    text: '',
    zone: APP_ZONES.REVENIR_ZONE,
    navigateToPageUrl: 'archived-trips',
    cssConfig: {
      color: 'var(--palette-color-0)',
      backGroundColor: 'var(--palette-color-6)',
    },
    gridPosition: { y: 0, x: 13, rows: 2, cols: 2 } as GridPosition,
    icon: 'archive',
  }),
  new TextWidgetBuilder()
    .text('<h3 class="normal-line-height">Trips</h3>')
    .configDisplayName('Text Widget: Trips')
    .gridPosition({ y: 2, rows: 2 } as GridPosition)
    .cssConfig({
      color: 'var(--typography-color-0)',
      textFont: 'var(--font-family)',
      display: 'flex',
      verticalAlignment: 'center',
      textAlignment: 'center',
      isTextBold: true,
      fontSize: 23,
      justifyContent: 'center',
      paddingLeft: 20,
      paddingRight: 20,
    })
    .isRemovable(false)
    .build(),
  getAddAndSelectTripWidget(),
  new RevenirTransactionWidgetBuilder()
    .isRemovable(false)
    .gridPosition({
      x: 0,
      y: 5,
      rows: 12,
      cols: 15,
    } as GridPosition)
    .cssConfig({
      disabledBgColor: 'var(--palette-color-3)',
      disabledTextColor: 'var(--palette-color-6)',
      fontSize: 14,
      textFont: 'var(--font-family)',
    })
    .attributes({
      pageSize: '5',
      widgetProperties: {
        textContent: ``,
      } as BaseWidgetProperties,
      titleTemplate: 'Trip Transaction',
      transactionItemConfig: {
        showAllTransaction: true,
        isTitleSwitchShown: true,
        transactionHistoryItemTemplate: revenirTransactionHistoryItemTemplate,
        zoneToNavigate: APP_ZONES.REVENIR_ZONE,
        urlToNavigate: 'transaction-list',
      },
    } as unknown as WorkflowWidgetConfigurationAttributes)
    .build(),

  commonCTAButton({
    zoneToNavigate: APP_ZONES.REVENIR_ZONE,
    urlToNavigate: 'missing-passport',
    textContent: `Barcode`,
    configDisplayName: 'Barcode',
    buttonActionType: ButtonActionType.REVENIR_TRIP_BARCODE_SCAN_CHECK,
    formKey: '',
    gridPosition: { y: 28, x: 0, rows: 2, cols: 15 } as GridPosition,
    lefticon: 'barcode-icon',
    cssConfig: {
      color: 'var(--palette-color-0)',
      backGroundColor: 'var(--palette-color-6)',
      fontSize: 20,
      marginTop: 0,
      marginBottom: 0,
      borderRadiusTopLeft: 6,
      borderRadiusTopRight: 6,
      borderRadiusBottomLeft: 6,
      borderRadiusBottomRight: 6,
      borderWidth: 1,
      borderColor: 'var(--palette-color-0)',
    },
  }),
];
