import {
  AllowedCurrencyType,
  LinksListType,
  NotificationSettingsWidgetLevel,
  WidgetNames,
  WidgetTitles,
  assignGroupWidgetConfig,
  feeClientListingWidgetConfig,
  groupsListingWidgetConfig,
} from '@finxone-platform/shared/sys-config-types';
import { assignPersonaRoleWidgetConfig } from './admin-roles/user-managment.widgets';
import { BaseWidgetBuilder, IWidgetBuilder, initWidgetBuilder } from './base-builder';
import { getEditBeneficiaryAddressWidget } from './beneficiary-zone/beneficiary-widgets';
import { getIntlCurrencyCountryWidgetConfigEditBeneficairy } from './beneficiary-zone/edit-beneficiary.type';
import {
  addToGooglePayWidgetConfig,
  carouselWidgetWidgetConfig,
  currencyInputFieldWidgetConfig,
  getCTAButtonsWidgetConfig,
  getProgressBarWidgetConfig,
  iFrameWidgetWidgetConfig,
  imageWidgetWidgetConfig,
  lineWidgetWidgetConfig,
  paymentReasonWidgetConfig,
  paymentRefConfig,
  textWidgetWidgetConfig,
} from './display-widgets.type';
import { cardNameWidgetConfig } from './forms/card-name-widget-config';
import {
  sectionWidgetConfig,
  spacerWidgetConfig,
  tabWidgetConfig,
  twoColumnWidgetConfig,
} from './layout-widget-constants';
import { generateOnboardingWidgetsWorkflowWidgetConfig } from './onboarding/onboarding.type';
import {
  addCurrencyAccountWidgetConfig,
  bulkPaymentAccountsListingWidgetConfig,
  confirmBeneficiaryDetailsWidget,
  exchangeCurrencyWidgetConfig,
  intlCurrencyCountryWidgetConfig,
} from './payments/payments.type';
import {
  biometricsAuthenticationWidgetConfig,
  calendarWidgetConfig,
  downloadDocumentWidgetConfig,
  profilePictureWidgetConfig,
} from './profile/profile.type';
import {
  accountsContextDropdownWidgetConfig,
  addEditFeeWidgetConfig,
  addEmployeeStep1WidgetsConfig,
  addEmployeeStep2WidgetsConfig,
  addFeeGroupWidgetConfig,
  backButtonWidgetConfig,
  backNextButtonWidgetConfig,
  cardPinWidgetConfig,
  cardSensitiveDetailsConfig,
  cardsWidgetConfig,
  conditionalWidgetConfig,
  currencyAccountsListingWidgetConfig,
  customQuestionWidgetConfig,
  desktopBulkPaymentHistoryListingWidgetConfig,
  desktopBulkPaymentListingWidgetConfig,
  desktopManageUserListingWidgetConfig,
  desktopPaymentRequestPendingListingWidgetConfig,
  documentPreviewWidgetConfig,
  editAddressWidget,
  euCountryWidgetConfig,
  exchangetransactionHistoryWidgetConfig,
  fileUploadWidgetConfig,
  generateFeeClientAccountsListingWidgetConfig,
  generateFeeConfigurationWidgetConfig,
  lottieAnimationWidgetConfig,
  multiIconQuickLinkWidgetConfig,
  notificationWidgetConfig,
  paymentRailSelectorWidgetConfig,
  profilePicDisplayWidgetConfig,
  profilePictureUploadWidgetConfig,
  questionSummaryWidgetConfig,
  quickLinkWidgetConfig,
  resendLinkWidgetConfig,
  revenirArchivedTripsWidgetConfig,
  revenirBarcodeImageViewerWidgetConfig,
  revenirTransactionDocumentWidgetConfig,
  revenirTransactionItemListWidgetConfig,
  revenirTransactionListWidgetConfig,
  revenirTripReclaimStatusWidgetConfig,
  reviewUploadBoardingPassComponentWidgetConfig,
  secondaryIdentifierWidgetConfig,
  switchNavigatorQuickLinkWidgetConfig,
  uploadBoardingPassWidgetConfig,
  uploadFileWithPopupWidgetConfig,
  uploadPassportWidgetConfig,
  uploadPreviewFileWidgetConfig,
  userNameWidgetConfig,
  verificationOrganisationCompanyNameWidget,
} from './workflow-ui-constants.v2.type';

export class LineWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(lineWidgetWidgetConfig);
  }
}

export class NameWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(generateOnboardingWidgetsWorkflowWidgetConfig(WidgetNames.NAME_WIDGET));
  }
}

export class BirthdayWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(
      generateOnboardingWidgetsWorkflowWidgetConfig(WidgetNames.BIRTHDAY_WIDGET),
    );
  }
}

export class PhoneWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(generateOnboardingWidgetsWorkflowWidgetConfig(WidgetNames.PHONE_WIDGET));
  }
}

export class EmailWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(generateOnboardingWidgetsWorkflowWidgetConfig(WidgetNames.EMAIL_WIDGET));
  }
}

export class AddressWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(
      generateOnboardingWidgetsWorkflowWidgetConfig(WidgetNames.ADDRESS_WIDGET),
    );
  }
}

export class PasswordWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(
      generateOnboardingWidgetsWorkflowWidgetConfig(WidgetNames.PASSWORD_WIDGET),
    );
  }
}

export class TextWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(textWidgetWidgetConfig);
  }

  updateWidgetName(widgetName: WidgetNames): this {
    this.widget.name = widgetName;
    return this;
  }

  setTextStylingClass(className: string): this {
    this.widget.widgetConfiguration.attributes.widgetProperties.textStyleClass = className;
    return this;
  }

  isTextEditable(isTextEditable: boolean): IWidgetBuilder {
    this.widget.widgetConfiguration.attributes.widgetProperties['isTextEditable'] = isTextEditable;
    return this;
  }
}

export class ProfilePictureWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(profilePictureWidgetConfig);
  }
}
export class QuickLinkWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(quickLinkWidgetConfig);
  }

  icon(text: string): this {
    this.widget.widgetConfiguration.attributes.widgetProperties.icon = text;
    return this;
  }

  zone(zone: string): this {
    this.widget.widgetConfiguration.attributes['zone'] = zone;
    return this;
  }
}
export class ResendLinkWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(resendLinkWidgetConfig);
  }

  icon(text: string): this {
    this.widget.widgetConfiguration.attributes.widgetProperties.icon = text;
    return this;
  }

  zone(zone: string): this {
    this.widget.widgetConfiguration.attributes['zone'] = zone;
    return this;
  }
}
export class MultiIconQuickLinkWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(multiIconQuickLinkWidgetConfig);
  }

  icons(icons: { left: string; right: string }): this {
    this.widget.widgetConfiguration.attributes['iconConfig'].leftIcon = icons.left;
    this.widget.widgetConfiguration.attributes['iconConfig'].rightIcon = icons.right;
    return this;
  }

  zone(zone: string): this {
    this.widget.widgetConfiguration.attributes['zone'] = zone;
    return this;
  }
  template(template: string): this {
    this.widget.widgetConfiguration.attributes['itemConfig'].itemTemplate = template;
    return this;
  }
}

export class ConfirmBeneficiaryDetailsWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(confirmBeneficiaryDetailsWidget);
  }
}

export class NotificationsWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(notificationWidgetConfig);
  }

  notificationSetting(setting: NotificationSettingsWidgetLevel): this {
    this.widget.widgetConfiguration.attributes.widgetProperties['notificationSettings'] = setting;
    return this;
  }
}
export class DownloadDocumentWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(downloadDocumentWidgetConfig);
  }
}

export class CurrencyAccountsListingWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(currencyAccountsListingWidgetConfig);
  }
}

export class FeeClientAccountsListingWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(generateFeeClientAccountsListingWidgetConfig());
  }
}

export class BulkPaymentAccountsListingWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(bulkPaymentAccountsListingWidgetConfig);
  }
}

export class AddCurrencyAccountWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(addCurrencyAccountWidgetConfig);
  }
  allowedCurrencies(currencies: AllowedCurrencyType[]) {
    this.widget.widgetConfiguration.attributes.widgetProperties.allowedCurrencies = currencies;
    return this;
  }
  setZone(zone: string): this {
    this.widget.widgetConfiguration.attributes['zone'] = zone;
    return this;
  }
}

export class LottieAnimationWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(lottieAnimationWidgetConfig);
  }
}

export class ProgressBarBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(getProgressBarWidgetConfig());
  }
}

export class PaymentRefConfigBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(paymentRefConfig);
  }
}

export class CurrencyInputFieldWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(currencyInputFieldWidgetConfig);
  }
}

export class ExchangeCurrencyWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(exchangeCurrencyWidgetConfig);
    this.widget.widgetConfiguration.attributes.css_config = {
      ...this.widget.widgetConfiguration.attributes.css_config,
      borderWidth: 0,
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 0,
    };
  }
  context(context: 'Buy' | 'Sell'): IWidgetBuilder {
    this.widget.widgetConfiguration.attributes.widgetProperties.context = context;
    return this;
  }
}

export class MandatoryCTAButtonBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(
      getCTAButtonsWidgetConfig(WidgetNames.MANDATORY_CTA_BUTTON, WidgetTitles.MANDATORY_CTA_BUTTON),
    );
  }
}

export class FormSubmissionButtonWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(
      getCTAButtonsWidgetConfig(WidgetNames.ACTION_BUTTON, WidgetTitles.MANDATORY_CTA_BUTTON),
    );
  }
}

export class EntityMandatoryCTAButtonBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(
      getCTAButtonsWidgetConfig(WidgetNames.ENTITY_MANDATORY_CTA_BUTTON, WidgetTitles.MANDATORY_CTA_BUTTON),
    );
  }
}

export class SwitchNavigatorWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(switchNavigatorQuickLinkWidgetConfig);
  }

  linksData(linksData: LinksListType[]): this {
    this.widget.widgetConfiguration.attributes.linksList = linksData;
    return this;
  }
}

export class ImageWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(imageWidgetWidgetConfig);
  }
}
export class QuestionSummaryWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(questionSummaryWidgetConfig);
  }
}
export class CurrencyExchangeTransactionHistoryBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(exchangetransactionHistoryWidgetConfig);
  }
}
export class InternationalCurrencyCountryWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(intlCurrencyCountryWidgetConfig);
  }
}
export class EditBeneficiaryInternationalCurrencyCountryWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(
      getIntlCurrencyCountryWidgetConfigEditBeneficairy(
        JSON.parse(JSON.stringify(intlCurrencyCountryWidgetConfig)),
      ),
    );
  }
}

export class CustomQuestionWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(customQuestionWidgetConfig);
  }
}

export class BackButtonWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(backButtonWidgetConfig);
  }

  icon(iconName: string): IWidgetBuilder {
    this.widget.widgetConfiguration.attributes.widgetProperties.icon = iconName;
    return this;
  }

  defaultBackButton(): IWidgetBuilder {
    return this.setWidgetProperties({
      icon: '',
      textContent: 'Back',
    }).cssConfig({
      color: 'var(--typography-color-0)',
      textFont: 'var(--font-family)',
      fontSize: 14,
      display: 'flex',
      verticalAlignment: 'center',
      textAlignment: 'center',
      backGroundColor: 'var(--palette-color-3)',
      borderRadiusUnit: 'px',
      borderRadiusTopLeft: 6,
      borderRadiusTopRight: 6,
      borderRadiusBottomLeft: 6,
      borderRadiusBottomRight: 6,
    });
  }
}

export class FileUploadWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(fileUploadWidgetConfig);
  }
}

export class CarouselWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(carouselWidgetWidgetConfig);
  }
}
export class CompanyNameWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(verificationOrganisationCompanyNameWidget);
  }
}
export class EditAddressWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(editAddressWidget);
  }
}

export class FeeClientListingWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(feeClientListingWidgetConfig);
  }
}
export class GroupsListingWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(groupsListingWidgetConfig);
  }
}

export class AssignGroupWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(assignGroupWidgetConfig);
  }
}

export class PaymentReasonWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(paymentReasonWidgetConfig);
  }
}

export class DesktopManageUserListingWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(desktopManageUserListingWidgetConfig);
  }
}

export class UserNameWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(userNameWidgetConfig);
  }
}

export class AddEmployeeStep1WidgetsBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(addEmployeeStep1WidgetsConfig);
  }
}

export class AddEmployeeStep2WidgetsBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(addEmployeeStep2WidgetsConfig);
  }
}

export class ManageUserViewAssignPersonaWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(assignPersonaRoleWidgetConfig);
  }
}

export class ProfilePictureUploadWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(profilePictureUploadWidgetConfig);
  }
}

export class ProfilePictureDisplayWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(profilePicDisplayWidgetConfig);
  }
}

export class DesktopBulkPaymentListingWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(desktopBulkPaymentListingWidgetConfig);
  }
}

export class DesktopBulkPaymentHistoryListingWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(desktopBulkPaymentHistoryListingWidgetConfig);
  }
}

export class AccountContextDropdownWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(accountsContextDropdownWidgetConfig);
  }
}

export class OnboardingNextBackWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(backNextButtonWidgetConfig);
  }
}

export class ConditionalWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(conditionalWidgetConfig);
  }
}

export class AddEditFeeWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(addEditFeeWidgetConfig);
  }
}

export class FeeConfigurationWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(generateFeeConfigurationWidgetConfig());
  }
}

export class AddFeeGroupWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(addFeeGroupWidgetConfig);
  }
}
export class CalendarWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(calendarWidgetConfig);
  }
}

export class UploadFileWithPopupWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(uploadFileWithPopupWidgetConfig);
  }
}

export class EUCountryWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(euCountryWidgetConfig);
  }

  fieldName(name: string): IWidgetBuilder {
    this.widget.widgetConfiguration.attributes.widgetProperties['fieldName'] = name;
    return this;
  }
}

export class DocumentPreviewWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(documentPreviewWidgetConfig);
  }
}

export class ReviewUploadBoardingPassWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(reviewUploadBoardingPassComponentWidgetConfig);
  }
}

export class RevenirTransactionWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(revenirTransactionListWidgetConfig);
  }
}

export class RevenirTransactionItemListWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(revenirTransactionItemListWidgetConfig);
  }
}
export class UploadPreviewFileWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(uploadPreviewFileWidgetConfig);
  }
}

export class RevenirTransactionDocumentWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(revenirTransactionDocumentWidgetConfig);
  }
}

export class CardsWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(cardsWidgetConfig);
  }
}

export class CardPINWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(cardPinWidgetConfig);
  }
}
export class CardSensitiveDetailsBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(cardSensitiveDetailsConfig);
  }
}

export class RevenirArchivedTripsWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(revenirArchivedTripsWidgetConfig);
  }
}

export class RevenirBarcodeImageViewerWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(revenirBarcodeImageViewerWidgetConfig);
  }
}
export class UploadBoardingPassWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(uploadBoardingPassWidgetConfig);
  }
}

export class RevenirTripReclaimStatusWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(revenirTripReclaimStatusWidgetConfig);
  }
}

export class UploadPassportWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(uploadPassportWidgetConfig);
  }
}

export class iFrameWidgetWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(iFrameWidgetWidgetConfig);
  }
}

export class DesktopPaymentRequestPendingListingWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(desktopPaymentRequestPendingListingWidgetConfig);
  }
}

export class TabWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(tabWidgetConfig);
  }
}

export class SectionWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(sectionWidgetConfig);
  }
}

export class TwoColumnWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(twoColumnWidgetConfig);
  }
}
export class SecondaryIdentifierWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(secondaryIdentifierWidgetConfig);
  }
}

export class SpacerWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(spacerWidgetConfig);
  }
  setHeight(config: { height: number; unit: string }): this {
    this.widget.widgetConfiguration.attributes.css_config.height = config.height;
    this.widget.widgetConfiguration.attributes.css_config.heightUnit = config.unit;
    return this;
  }
}
export class CardNameWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(cardNameWidgetConfig);
  }
}

export class BiometricsAuthenticationWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(biometricsAuthenticationWidgetConfig);
  }
}

export class AddToGooglePayWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(addToGooglePayWidgetConfig());
  }
}

export class PaymentRailSelectorWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(paymentRailSelectorWidgetConfig);
  }
}

export class EditBeneficiaryAddressWidgetBuilder extends BaseWidgetBuilder {
  constructor() {
    super();
    this.widget = initWidgetBuilder(getEditBeneficiaryAddressWidget());
  }
}
