import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '@app/finxone-web-frontend/app/lib/services/config-service/config-service.service';
import {
  ButtonActionType,
  ExchangeQuotePayload,
  ExchangeQuoteResponse,
  ExchangeTransactionResponse,
} from '@finxone-platform/shared/sys-config-types';
import { Observable, catchError, tap, BehaviorSubject } from 'rxjs';
import { CtaButtonSignalService } from '@app/finxone-web-frontend/app/lib/services/cta-button-signal-service/cta-button-signal.service';

@Injectable({
  providedIn: 'root',
})
export class ExchangeService {
  private baseUrl = '';
  public lastQuoteResponseData$ = new BehaviorSubject<ExchangeQuoteResponse | null>(null);

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private ctaButtonSignalService: CtaButtonSignalService,
  ) {
    this.configService.getApi('account_service').subscribe((response) => {
      this.baseUrl = response;
    });
  }

  public rates(rateRequest: { base: string; symbols: string[] }): Observable<{
    base: string;
    rates: {
      [currency: string]: number;
    };
  }> {
    return this.http.post<{
      base: string;
      rates: {
        [currency: string]: number;
      };
    }>(this.baseUrl + '/exchanges/rates', rateRequest);
  }

  public postExchangeQuote(requestData: ExchangeQuotePayload): Observable<ExchangeQuoteResponse> {
    return this.http.post<ExchangeQuoteResponse>(this.baseUrl + '/exchanges/quote', requestData).pipe(
      tap((response) => {
        this.ctaButtonSignalService.setSignal({ [ButtonActionType.EXCHANGE_RE_QUOTE]: false });
        this.lastQuoteResponseData$.next(response); // Store and emit new value
      }),
    );
  }

  public postRefreshQuote(
    quoteId: string,
    requestData: ExchangeQuotePayload,
  ): Observable<ExchangeQuoteResponse> {
    const refreshUrl = `${this.baseUrl + '/exchanges/quote'}/${quoteId}`;
    return this.http.post<ExchangeQuoteResponse>(refreshUrl, requestData).pipe(
      tap((response) => {
        this.ctaButtonSignalService.setSignal({ [ButtonActionType.EXCHANGE_RE_QUOTE]: false });
        this.lastQuoteResponseData$.next(response); // Store and emit new value
      }),
    );
  }

  public postAcceptQuote(quoteId: string): Observable<any> {
    return this.http.post(this.baseUrl + '/exchanges/transactions', {
      quoteId,
    });
  }

  public getExchangeDetail(exchangeId: string): Observable<ExchangeTransactionResponse> {
    const url = `${this.baseUrl}/exchanges/transactions/${exchangeId}`;

    return this.http.get<ExchangeTransactionResponse>(url).pipe(
      catchError<any, Observable<boolean>>((_err) => {
        console.error(`Error getting exchange detail for exchangeId ${exchangeId}: ${JSON.stringify(_err)}`);
        throw _err;
      }),
    );
  }
}
