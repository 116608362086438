/* eslint-disable @nx/enforce-module-boundaries */
import { ContainerType, WorkflowPageConfig } from '@finxone-platform/shared/sys-config-types';

import { generateAdHocFeePageWidgets } from '../../../../page-builder-utils/widget-builder-utils/fee-management-zone/ad-hoc-fee-page.utils';
import { generateAddEditFeePageWidgets } from '../../../../page-builder-utils/widget-builder-utils/fee-management-zone/add-edit-fee-page.utils';
import { generateAddFeeGroupConfirmationPageWidgets } from '../../../../page-builder-utils/widget-builder-utils/fee-management-zone/add-edit-group-confirmation-page.utils';
import { generateAddFeeGroupPages } from '../../../../page-builder-utils/widget-builder-utils/fee-management-zone/add-fee-group-page.utils';
import { generateClientDrilldownViewPageWidgets } from '../../../../page-builder-utils/widget-builder-utils/fee-management-zone/client-drilldown-view-page.utils';
import { generateClientLevelFeeHistoryPageWidgets } from '../../../../page-builder-utils/widget-builder-utils/fee-management-zone/client-level-fee-charge-history-page.utils';
import { generateClientLevelFeeListPageWidgets } from '../../../../page-builder-utils/widget-builder-utils/fee-management-zone/client-level-fee-page.utils';
import { confirmationRemovingClientFromListingWidgets } from '../../../../page-builder-utils/widget-builder-utils/fee-management-zone/confirmation-remove-client-from-group.utils';
import {
  confirmationRemoveFeeWidgets,
  confirmationRemovingGroupWidgets,
} from '../../../../page-builder-utils/widget-builder-utils/fee-management-zone/confirmation-remove-page.utils';
import { generateConfirmationRevertFeePageWidgets } from '../../../../page-builder-utils/widget-builder-utils/fee-management-zone/confirmation-revert-fee-page.utils';
import { generateFeeChargeHistoryPageWidgets } from '../../../../page-builder-utils/widget-builder-utils/fee-management-zone/fee-charge-history-page-widgets.utils';
import {
  clientListingWidget,
  feeListingWidget,
} from '../../../../page-builder-utils/widget-builder-utils/fee-management-zone/fee-client-listing-page.utils';
import { generateFeeManagementNotAvailablePageWidgets } from '../../../../page-builder-utils/widget-builder-utils/fee-management-zone/fee-management-not-available-page';
import {
  groupDrilldownViewClientListingWidget,
  groupDrilldownViewFeeListingWidget,
} from '../../../../page-builder-utils/widget-builder-utils/fee-management-zone/group-drilldown-view-page.utils';
import { groupListingWidgets } from '../../../../page-builder-utils/widget-builder-utils/fee-management-zone/groups-listing-page.utils';
import { generateValidationFaileFeePageWidgets } from '../../../../page-builder-utils/widget-builder-utils/fee-management-zone/validation-failed-fee-page.utils';
import { addEditValidationWidgets } from '../../../../page-builder-utils/widget-builder-utils/fee-management-zone/validation-pages.utils';
import { PageConfig } from '../../../../zones/mobile/default-zone-page-setups';
import { defaultPageConfigurations } from '../../../widgets/workflow-ui-constants.v2.type';

export function buildFeeManagementZonePagesConfigDesktop(): WorkflowPageConfig[] {
  const zonePages: WorkflowPageConfig[] = [];
  const initialPages: PageConfig[] = [
    {
      name: 'charge-history-fee',
      title: 'Charge History - Fee',
      widgets: generateFeeChargeHistoryPageWidgets(),
      tags: [],
    },
    {
      name: 'management-fee-list',
      title: 'Management - Fee List',
      widgets: feeListingWidget,
      tags: [],
    },
    {
      name: 'management-client-list',
      title: 'Management - Client List',
      widgets: clientListingWidget,
      tags: [],
    },
    {
      name: `client-information`,
      title: `Client - Information`,
      widgets: generateClientDrilldownViewPageWidgets(),
      tags: [],
    },
    {
      name: 'client-fee-charge-add-edit-fee',
      title: 'Client - Fee Charge (Add/Edit Fee)',
      widgets: generateAdHocFeePageWidgets(),
      tags: [],
    },
    {
      name: 'clients-fee-list',
      title: `Client's - Fee List`,
      widgets: generateClientLevelFeeListPageWidgets(),
      tags: [],
    },
    {
      name: 'clients-fee-history',
      title: `Client's - Fee History`,
      widgets: generateClientLevelFeeHistoryPageWidgets(),
      tags: [],
    },
    {
      name: 'group-list',
      title: 'Group List',
      widgets: groupListingWidgets,
      tags: [],
    },
    {
      name: 'group-information-fee-list',
      title: 'Group Information - Fee List',
      widgets: groupDrilldownViewFeeListingWidget,
      tags: [],
    },
    {
      name: 'group-information-client-list',
      title: 'Group Information - Client List',
      widgets: groupDrilldownViewClientListingWidget,
      tags: [],
    },
    {
      name: 'add-edit-group-details',
      title: 'Add/Edit Group - Details',
      widgets: generateAddFeeGroupPages(),
      tags: [],
    },
    {
      name: 'group-fee-charge-add-edit-fee',
      title: 'Group - Fee Charge (Add/Edit Fee)',
      widgets: generateAddEditFeePageWidgets('desktop'),
      tags: [],
    },
    {
      name: 'confirmation-remove-fee',
      title: 'Confirmation - Remove Fee',
      widgets: confirmationRemoveFeeWidgets,
      tags: [],
    },
    {
      name: 'confirmation-remove-client',
      title: 'Confirmation - Remove Client',
      widgets: confirmationRemovingClientFromListingWidgets,
      tags: [],
    },
    {
      name: 'validation-add-edit-group-details',
      title: 'Validation - Add/Edit Group Details',
      widgets: generateAddFeeGroupConfirmationPageWidgets(),
      tags: [],
    },
    {
      name: 'confirmation-remove-group',
      title: 'Confirmation - Remove Group',
      widgets: confirmationRemovingGroupWidgets,
      tags: [],
    },
    {
      name: 'validation-add-edit-fee-group',
      title: 'Validation - Add/Edit Fee (Group)',
      widgets: addEditValidationWidgets({
        redirectionUrl: 'management-fee-list',
      }),
      tags: [],
    },
    {
      name: 'validation-add-edit-fee-client',
      title: 'Validation - Add/Edit Fee (Client)',
      widgets: addEditValidationWidgets({
        redirectionUrl: 'clients-fee-list',
      }),
      tags: [],
    },
    {
      name: 'validation-failed-fee',
      title: 'Validation - Failed Fee',
      widgets: generateValidationFaileFeePageWidgets(),
      tags: [],
    },
    {
      name: 'confirmation-revert-fee',
      title: 'Confirmation - Revert Fee',
      widgets: generateConfirmationRevertFeePageWidgets(),
      tags: [],
    },
    {
      name: 'fee-management-not-available',
      title: 'Fee Management Not Available',
      widgets: generateFeeManagementNotAvailablePageWidgets(),
      tags: [],
    },
  ];

  initialPages.forEach((page: PageConfig) => {
    const pageObj: WorkflowPageConfig = {
      name: page.name,
      title: page.title,
      widgets: page.widgets ?? [],
      pageConfiguration: {
        ...defaultPageConfigurations,
        url: page.name,
        pageType: 'dynamicGrid',
        isRemovable: false,
        mainComponentPageName: 'ParentContainerComponent',
      },
      containerType: ContainerType.PAGE,
    };
    zonePages.push(pageObj);
  });
  return zonePages;
}
