import { WorkflowWidgetConfig } from '@finxone-platform/shared/sys-config-types';
import {
  FormSubmissionButtonWidgetBuilder,
  SectionWidgetBuilder,
  SpacerWidgetBuilder,
  TextWidgetBuilder,
  TwoColumnWidgetBuilder,
} from '../../../../widgets/example-builder';

import { reviewInformationOrganisation } from '@finxone-platform/shared/default-templates';
import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonActionType,
  ButtonSizeOptions,
  ButtonTypes,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import { QuickLinkWidgetBuilder } from '../../../../widgets/example-builder';
import { createTextWidget } from './common-widgets';

const headerText = `<h6>Welcome to {{ appData.systemName }}!</h6>`;
const informationText =
  '<p class="w-100"> Welcome! Check your summary below and edit if needed. If all is well, proceed to sumsub verification. </p>';

function getFormSubmissionButton() {
  return new FormSubmissionButtonWidgetBuilder()
    .configDisplayName('Button: Verify Me')
    .attributes({
      widgetProperties: {
        textContent: 'Verify Me',
        zoneToNavigate: APP_ZONES.ORGANISATION_VERIFICATION,
        urlToNavigate: 'information-confirmation',
        buttonActionType: ButtonActionType.UPDATE_ORGANISATION,
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .isRemovable(false)
    .setGlobalStylingClass(true, `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`)
    .build();
}

function getQuickWidgetConfig() {
  return new QuickLinkWidgetBuilder()
    .zone(APP_ZONES.ORGANISATION_VERIFICATION)
    .navigateToPageUrl('edit-information-company-name')
    .navigationOption('use-as-navigation')
    .role()
    .configDisplayName('Quick Link: Edit Information')
    .text('Edit Information')
    .isRemovable(false)
    .setGlobalStylingClass(true, `${ButtonTypes.SECONDARY} ${ButtonSizeOptions.FULL_WIDTH}`)
    .build();
}

export function mobileReviewInformationWidgets(): WorkflowWidgetConfig[] {
  return [
    createTextWidget(
      'Text Widget: Header Text',
      headerText,
      {
        textAlignment: 'center',
        fontSize: '24',
        isTextBold: true,
        marginTop: 35,
      },
      'h6',
    ),
    createTextWidget(
      'Text Widget: Information',
      informationText,
      {
        textAlignment: 'center',
        fontSize: '18',
        color: 'var(--color-on-surface-muted)',
        marginTop: 10,
      },
      'subtitle1',
    ),

    new TextWidgetBuilder()
      .text(reviewInformationOrganisation)
      .configDisplayName('Review Information')
      .cssConfig({
        backGroundColor: 'var(--color-surface)',
        paddingTop: '25',
        paddingBottom: '25',
        paddingLeft: '20',
        paddingRight: '20',
        borderRadiusTopLeft: '6',
        borderRadiusTopRight: '6',
        borderRadiusBottomLeft: '6',
        borderRadiusBottomRight: '6',
        display: 'flex',
        verticalAlignment: 'start',
        textAlignment: 'left',
        marginTop: 20,
      })
      .isRemovable(false)
      .build(),

    new SpacerWidgetBuilder().setHeight({ height: 60, unit: 'px' }).build(),

    getFormSubmissionButton(),

    new SpacerWidgetBuilder().setHeight({ height: 20, unit: 'px' }).build(),

    getQuickWidgetConfig(),
  ];
}

export function desktopReviewInformationWidgets(): WorkflowWidgetConfig[] {
  return [
    new SectionWidgetBuilder()
      .attributes({
        outerCss: `left: 30%; position: relative; align-content:center; width: 45%; height:100vh;`,
      } as unknown as WorkflowWidgetConfigurationAttributes)
      .childComponents([
        createTextWidget(
          'Text Widget: Header Text',
          headerText,
          {
            textAlignment: 'center',
            fontSize: '24',
            isTextBold: true,
            marginTop: 10,
          },
          'h6',
        ),

        createTextWidget(
          'Text Widget: Information',
          informationText,
          {
            textAlignment: 'center',
            fontSize: '18',
            color: 'var(--color-on-surface-muted)',
            marginTop: 10,
            marginBottom: 10,
          },
          'subtitle1',
        ),

        new TextWidgetBuilder()
          .text(reviewInformationOrganisation)
          .configDisplayName('Review Information')
          .cssConfig({
            backGroundColor: 'var(--color-surface)',
            paddingTop: '25',
            paddingBottom: '25',
            paddingLeft: '20',
            paddingRight: '20',
            borderRadiusTopLeft: '6',
            borderRadiusTopRight: '6',
            borderRadiusBottomLeft: '6',
            borderRadiusBottomRight: '6',
            display: 'flex',
            verticalAlignment: 'start',
            textAlignment: 'left',
            marginTop: 20,
          })
          .isRemovable(false)
          .build(),

        new SpacerWidgetBuilder().setHeight({ height: 40, unit: 'px' }).build(),

        new TwoColumnWidgetBuilder()
          .attributes({
            widgetProperties: {
              textContent: '',
              columnOnePercent: 50,
              selectedNoOfColumn: 3,
              selectedColumnRange: [30, 25, 25],
              selectedSplitType: 'custom',
            } as unknown as BaseWidgetProperties,
          } as unknown as WorkflowWidgetConfigurationAttributes)
          .cssConfig({
            columnGap: 30,
          })
          .childComponents([[], [getQuickWidgetConfig()], [getFormSubmissionButton()]])
          .build(),
      ])
      .build(),
  ];
}
