export const feeGroupDetailsTemplate = `
    <div class="w-100">
        <div class="col-12 pb-2">
            <div class="text-label">Group Name</div>
            <div class="text-value">
                <b>{{#if feeGroupDataFromFormState.groupName}}{{ feeGroupDataFromFormState.groupName }}{{else}} -- {{/if}}</b>
            </div>
        </div>
        <hr />
        <div class="col-12 mt-1">
            <div class="text-label">Description</div>
            <div class="text-value">
                <b>{{#if feeGroupDataFromFormState.description}}{{ feeGroupDataFromFormState.description }}{{else}} -- {{/if}}</b>
            </div>
        </div>
    </div>
`;
