import { GridPosition } from '@finxone-platform/shared/sys-config-types';
import {
  reviewInformationPersonalCTAButton,
  statusScreenHeaderTextWidget,
  sumsubVerificationPersonalConditionalWidget,
} from './common-widgets';

export const personalStatusScreen = [
  statusScreenHeaderTextWidget({}),

  sumsubVerificationPersonalConditionalWidget({}),

  reviewInformationPersonalCTAButton({
    gridPosition: { x: 0, y: 23, rows: 2, cols: 15 } as GridPosition,
  }),
];

export const personalStatusScreenDesktop = [
  statusScreenHeaderTextWidget({
    gridPosition: {
      y: 1,
      x: 13,
      rows: 2,
      cols: 15,
    } as GridPosition,
  }),

  sumsubVerificationPersonalConditionalWidget({
    gridPosition: {
      y: 4,
      x: 13,
      rows: 3,
      cols: 15,
    } as GridPosition,
  }),

  reviewInformationPersonalCTAButton({
    gridPosition: { x: 13, y: 14, rows: 2, cols: 15 } as GridPosition,
  }),
];

export const personalStatusScreenTablet = [
  statusScreenHeaderTextWidget({
    gridPosition: {
      y: 1,
      x: 2,
      rows: 2,
      cols: 15,
    } as GridPosition,
  }),

  sumsubVerificationPersonalConditionalWidget({
    gridPosition: {
      y: 4,
      x: 2,
      rows: 3,
      cols: 15,
    } as GridPosition,
  }),

  reviewInformationPersonalCTAButton({
    gridPosition: { x: 2, y: 10, rows: 2, cols: 15 } as GridPosition,
  }),
];
