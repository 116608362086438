import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { FormActionTypeEnum } from '@finxone-platform/form-action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import {
  AddEditFeeRequestPayload,
  BaseWidgetProperties,
  FeeUnitChargeTypeEnum,
} from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { take, tap } from 'rxjs';
import { ResetSelectedFee, ValidationFeeDetails } from '../../../actions/fee-management.action';
import { ResetFormDataAction, UpdateFormDataActionWithId } from '../../../actions/form-submission.action';
import { FeeManagementService } from '../../../services/fee-management-service/fee-management.service';
import { FormActionState } from '../../../state/form-submision.state';
import { removeNullUndefinedValuesFromObject } from '../../utils';
import { redirectToPage } from '../cta-button-actions.utils';

/**
 * Handles the submission of add/edit fee forms by validating form data,
 * constructing the request payload, and invoking the appropriate service
 * methods for fee creation or update. Displays success or error alerts
 * based on the operation outcome and redirects to the specified page
 * upon successful submission.
 *
 * @param widgetProperties - The properties of the widget initiating the action.
 * @param injector - The Angular injector for retrieving required services.
 */
export function handleAddEditFeeSubmission(widgetProperties: BaseWidgetProperties, injector: Injector) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  const alertHandlerService = injector.get(AlertHandlerService);
  const feeManagementService = injector.get(FeeManagementService);
  const formData = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.ADD_EDIT_FEE),
  )?.formData;
  if (formData) {
    const isFormValid = formData?.isCurrencyFeeFormValid && formData?.isFeeGroupFormValid;
    if (isFormValid) {
      const feeChargeValue =
        formData.feeDetails.unitCharge.value === FeeUnitChargeTypeEnum.FIXED_FEE
          ? formData.feeDetails?.feeAmount
          : formData.feeDetails?.feePercentage;
      let payload: AddEditFeeRequestPayload = {
        name: formData.feeDetails?.feeName,
        currency: formData.currency,
        feeGroupId: formData.feeGroup.id,
        unitChargeType: formData.feeDetails?.unitCharge.value,
        unitChargeInfo: {
          feeCharge: feeChargeValue,
          minFeeAmount: formData.feeDetails?.minimumFeeAmount,
          maxFeeAmount: formData.feeDetails?.maximumFeeAmount,
        },
        frequencyType: formData.scheduleDetails?.frequency.id,
        frequencyInfo: {
          startDate: formData.scheduleDetails?.effectiveFrom,
          endDate: formData.scheduleDetails?.endDate,
        },
      };
      payload = removeNullUndefinedValuesFromObject<AddEditFeeRequestPayload>(payload);
      if (formData?.id?.length) {
        feeManagementService
          .submitEditFeeRequest(payload, formData.id)
          .pipe(
            take(1),
            tap((res) => {
              if (res) {
                store.dispatch(new ValidationFeeDetails(res));
                alertHandlerService.showAlertFn('success', 'Fee updated successfully');
                store.dispatch(new ResetFormDataAction());
                store.dispatch(new ResetSelectedFee());
                redirectToPage(router, widgetProperties, injector);
              }
            }),
          )
          .subscribe();
      } else {
        feeManagementService
          .submitAddEditFeeRequest(payload)
          .pipe(
            take(1),
            tap((res) => {
              if (res) {
                alertHandlerService.showAlertFn('success', 'Fee created successfully');
                store.dispatch(new ValidationFeeDetails(res));
                store.dispatch(new ResetFormDataAction());
                redirectToPage(router, widgetProperties, injector);
              }
            }),
          )
          .subscribe();
      }
    } else {
      alertHandlerService.showAlertFn('error', 'Please provide all details in the form before proceeding');
      store.dispatch(
        new UpdateFormDataActionWithId({ showFormErrors: true }, FormActionTypeEnum.ADD_EDIT_FEE),
      );
    }
  } else {
    alertHandlerService.showAlertFn('error', 'Please provide all details in the form before proceeding');
    store.dispatch(new UpdateFormDataActionWithId({ showFormErrors: true }, FormActionTypeEnum.ADD_EDIT_FEE));
  }
}
