import { clientDetailsTemplate } from '@finxone-platform/shared/default-templates';
import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonActionType,
  GridPosition,
  InputCssConfig,
  WorkflowWidgetConfig,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import {
  AssignGroupWidgetBuilder,
  FeeClientAccountsListingWidgetBuilder,
  FormSubmissionButtonWidgetBuilder,
} from '../../../defaults/widgets/example-builder';
import { inputSettings } from '../../../defaults/widgets/payments/payments.type';
import { getBackButtonWidget } from '../../../defaults/zones/manage-users-zone/common-utils';
import {
  generateTextWidget,
  getQuickLinkWidget,
} from '../../../defaults/zones/payments/common-widgets-utils';

export function generateClientDrilldownViewPageWidgets(): WorkflowWidgetConfig[] {
  const widgets = [
    generateTextWidget({
      text: `Client Information - {{#if selectedClientDetails.name}}{{ selectedClientDetails.name }}{{else}} -- {{/if}}`,
      displayName: 'Text Widget: Page Heading',
      isRemovable: false,
      position: {
        x: 1,
        y: 0,
        rows: 1,
        cols: 36,
      } as GridPosition,
      styles: {
        fontSize: 24,
        color: 'var(--typography-color-0)',
        isTextBold: true,
        textAlignment: 'left',
      },
    }),
    getBackButtonWidget({
      position: {
        x: 38,
        y: 0,
        rows: 1,
        cols: 4,
      } as GridPosition,
      styles: {
        fontSize: 14,
        textAlignment: 'center',
        color: 'var(--palette-color-3)',
        backGroundColor: 'transparent',
        borderWidth: 1,
        borderColor: 'var(--palette-color-3)',
      },
    }),
    new FormSubmissionButtonWidgetBuilder()
      .configDisplayName('Button: Save')
      .attributes({
        widgetProperties: {
          textContent: 'Save',
          zoneToNavigate: APP_ZONES.FEE_MANAGEMENT,
          urlToNavigate: 'management-client-list',
          buttonActionType: ButtonActionType.UPDATE_CLIENT_FEE_GROUP,
        } as BaseWidgetProperties,
      } as WorkflowWidgetConfigurationAttributes)
      .cssConfig({
        marginTop: 0,
      })
      .gridPosition({
        x: 43,
        y: 0,
        rows: 1,
        cols: 4,
      } as GridPosition)
      .build(),
    generateTextWidget({
      text: clientDetailsTemplate,
      displayName: 'Text Widget: Client Details',
      isRemovable: false,
      position: {
        x: 1,
        y: 2,
        rows: 4,
        cols: 36,
      } as GridPosition,
      styles: {
        fontSize: 16,
        color: 'var(--typography-color-1)',
        isTextBold: false,
        backGroundColor: 'var(--palette-color-5)',
        textAlignment: 'left',
        verticalAlignment: 'center',
        borderRadiusTopLeft: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusBottomRight: 5,
      },
    }),
    new AssignGroupWidgetBuilder()
      .configDisplayName(`Assign Group  Widget`)
      .gridPosition({ x: 1, y: 6, rows: 3, cols: 8 } as GridPosition)
      .isRemovable(false)
      .build(),
    generateTextWidget({
      text: `Select the category under which you want to link this client. Different categories holds different fee management restrictions`,
      displayName: 'Text Widget:  Fee Category Info',
      isRemovable: false,
      position: {
        x: 1,
        y: 10,
        rows: 1,
        cols: 36,
      } as GridPosition,
      styles: {
        fontSize: 16,
        color: 'var(--typography-color-1)',
        isTextBold: false,
        textAlignment: 'left',
      },
    }),
    generateTextWidget({
      text: `Accounts Linked ({{ selectedClientDetails.accountsLinked }})`,
      displayName: 'Text Widget: Accounts Linked',
      isRemovable: false,
      position: {
        x: 1,
        y: 12,
        rows: 1,
        cols: 36,
      } as GridPosition,
      styles: {
        fontSize: 20,
        color: 'var(--typography-color-0)',
        isTextBold: true,
        textAlignment: 'left',
      },
    }),
    generateTextWidget({
      text: `Access the drill-down view of each account to charge an ad hoc fee.`,
      displayName: 'Text Widget: Account Listing Info',
      isRemovable: false,
      position: {
        x: 1,
        y: 13,
        rows: 1,
        cols: 36,
      } as GridPosition,
      styles: {
        fontSize: 16,
        color: 'var(--typography-color-1)',
        isTextBold: false,
        textAlignment: 'left',
      },
    }),
    getQuickLinkWidget({
      text: 'View Fee List',
      position: {
        x: 42,
        y: 13,
        rows: 1,
        cols: 5,
      } as GridPosition,
      pageUrl: 'clients-fee-list',
      displayName: 'Quick Link: Fee List',
      styles: {
        borderWidth: 1,
        color: 'var(--palette-color-3)',
        fontSize: '16',
        backGroundColor: 'var(--palette-color-6)',
        textAlignment: 'center',
        justifyContent: 'center',
        borderColor: 'var(--palette-color-3)',
        borderStyle: 'solid',
        borderRadiusTopLeft: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusBottomRight: 5,
      },
      zone: APP_ZONES.FEE_MANAGEMENT,
      isRemovable: true,
    }),
    new FeeClientAccountsListingWidgetBuilder()
      .inputSetting({
        ...inputSettings,
        isIconAllowed: true,
        css_config: {
          iconSize: 24,
          iconSizeUnit: 'px',
        } as InputCssConfig,
      })
      .gridPosition({ y: 14, x: 1 } as GridPosition)
      .isRemovable(false)
      .build(),
  ];
  return widgets;
}
