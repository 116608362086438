import { APP_ZONES, ButtonActionType, GridPosition } from '@finxone-platform/shared/sys-config-types';
import { LottieAnimationWidgetBuilder, UploadPassportWidgetBuilder } from '../../../widgets';
import {
  commonBackButtonQuickLink,
  commonCTAButton,
  commonQuickLink,
  commonTextWidget,
} from '../../common/common-widgets';

export const uploadPassportPageWidgets = [
  commonBackButtonQuickLink({
    cssConfig: {
      iconSize: 35,
      iconUnit: 'px',
    },
    gridPosition: {
      y: 0,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 2,
      layerIndex: 1,
    },
  }),

  commonTextWidget({
    textContent: 'Upload Passport',
    cssConfig: {
      textAlignment: 'center',
      fontSize: 28,
      isTextBold: true,
      paddingLeft: 20,
      paddingRight: 20,
      marginLeft: 20,
      marginRight: 20,
    },
    gridPosition: {
      y: 2,
      x: 0,
      rows: 2,
      cols: 15,
    } as GridPosition,
  }),

  new UploadPassportWidgetBuilder()
    .addCustomWidgetProperties({
      textContent: 'Upload document',
      uploadType: 'passport',
    })
    .gridPosition({
      y: 20,
      x: 0,
      rows: 6,
      cols: 15,
    } as GridPosition)
    .cssConfig({
      paddingTop: 12,
      paddingBottom: 12,
      paddingLeft: 20,
      paddingRight: 20,
      marginTop: 90,
    })
    .build(),

  commonQuickLink({
    cssConfig: {
      marginTop: 0,
      marginBottom: 0,
      fontSize: 14,
      verticalAlignment: 'center',
      disabledBgColor: 'var(--palette-color-3)',
      disabledTextColor: 'var(--palette-color-6)',
    },
    gridPosition: { y: 26, x: 0, rows: 2, cols: 15 } as GridPosition,
    text: 'Skip',
    configDisplayName: 'Skip',
    zone: APP_ZONES.REVENIR_ZONE,
    navigateToPageUrl: 'passport-notice',
  }),
];

export const passportNoticePageWidgets = [
  commonTextWidget({
    textContent: 'Just a note',
    configDisplayName: 'Heading',
    cssConfig: {
      textAlignment: 'center',
      fontSize: 28,
      isTextBold: true,
      paddingLeft: 20,
      paddingRight: 20,
      marginLeft: 20,
      marginRight: 20,
    },
    gridPosition: {
      y: 2,
      x: 0,
      rows: 2,
      cols: 15,
    } as GridPosition,
  }),

  commonTextWidget({
    textContent: 'To reclaim VAT you will need to upload a travel document such as a passport',
    configDisplayName: 'Information Text',
    cssConfig: {
      fontSize: 16,
      textAlignment: 'center',
      paddingLeft: 20,
      paddingRight: 20,
    },
    gridPosition: {
      x: 0,
      y: 5,
      rows: 3,
      cols: 15,
    } as GridPosition,
  }),

  commonCTAButton({
    zoneToNavigate: APP_ZONES.REVENIR_ZONE,
    urlToNavigate: 'missing-boarding-pass',
    textContent: 'I Understand',
    configDisplayName: 'I Understand',
    gridPosition: { y: 24, x: 0, rows: 2, cols: 15 } as GridPosition,
    buttonActionType: ButtonActionType.SKIP_PASSPORT_DETAILS,
  }),
];

export const passportSuccessPageWidgets = [
  commonTextWidget({
    textContent: 'Success!',
    configDisplayName: 'Heading',
    cssConfig: {
      textAlignment: 'center',
      fontSize: 28,
      isTextBold: true,
      paddingLeft: 20,
      paddingRight: 20,
      marginLeft: 20,
      marginRight: 20,
    },
    gridPosition: {
      x: 0,
      y: 2,
      rows: 2,
      cols: 15,
    } as GridPosition,
  }),

  commonTextWidget({
    textContent: 'You’ve successfully uploaded your passport.',
    configDisplayName: 'Information Text',
    cssConfig: {
      fontSize: 16,
      textAlignment: 'center',
    },
    gridPosition: {
      x: 0,
      y: 5,
      rows: 3,
      cols: 15,
    } as GridPosition,
  }),

  commonCTAButton({
    zoneToNavigate: APP_ZONES.REVENIR_ZONE,
    urlToNavigate: 'barcode-scanning',
    textContent: 'Continue',
    configDisplayName: 'Continue',
    gridPosition: { y: 24, x: 0, rows: 2, cols: 15 } as GridPosition,
    buttonActionType: ButtonActionType.PASSPORT_DETAILS,
  }),
];

export const passportProcessingPageWidgets = [
  new LottieAnimationWidgetBuilder()
    .setWidgetProperties({
      animationSource: 'Asset URL',
      assetUrl: 'assets/JSON/loader-animation.json',
      animationType: 'Loop',
      animationContainerType: 'Default',
      textContent: '',
    })
    .cssConfig({
      textAlignment: 'center',
      justifyContent: 'center',
      textFont: 'var(--font-family)',
    })
    .gridPosition({ x: 5, y: 10, rows: 5, cols: 5 } as GridPosition)
    .isRemovable(true)
    .build(),

  commonTextWidget({
    textContent: 'Uploading Please Wait',
    configDisplayName: 'Processing',
    cssConfig: {
      textAlignment: 'center',
      fontSize: 20,
      isTextBold: true,
      textFont: 'var(--font-family)',
    },
    gridPosition: {
      x: 4,
      y: 16,
      rows: 2,
      cols: 7,
    } as GridPosition,
  }),
];

export const passportUploadfailedPageWidgets = [
  commonTextWidget({
    textContent: 'Uh oh!',
    configDisplayName: 'Heading',
    cssConfig: {
      textAlignment: 'center',
      fontSize: 28,
      isTextBold: true,
      paddingLeft: 20,
      paddingRight: 20,
      marginLeft: 20,
      marginRight: 20,
    },
    gridPosition: {
      x: 0,
      y: 2,
      rows: 2,
      cols: 15,
    } as GridPosition,
  }),

  commonTextWidget({
    textContent: 'We ran into a problem Please try uploading it again.',
    configDisplayName: 'Information Text',
    cssConfig: {
      fontSize: 16,
      textAlignment: 'center',
    },
    gridPosition: {
      x: 1,
      y: 5,
      rows: 3,
      cols: 13,
    } as GridPosition,
  }),

  commonQuickLink({
    cssConfig: {
      marginTop: 0,
      marginBottom: 0,
      fontSize: 16,
      verticalAlignment: 'center',
      disabledBgColor: 'var(--palette-color-3)',
      disabledTextColor: 'var(--palette-color-6)',
    },
    gridPosition: { y: 24, x: 0, rows: 2, cols: 15 } as GridPosition,
    text: 'Try Again ',
    configDisplayName: 'Try Again',
    zone: APP_ZONES.REVENIR_ZONE,
    navigateToPageUrl: 'upload-passport',
  }),
];
