export enum CardsErrorCodes {
  CARD_DOES_NOT_EXIST = 'card_does_not_exist',
  FAILED_TO_FETCH_CARD = 'failed_to_fetch_card',
  FAILED_TO_ADD_CARD = 'failed_to_add_card',
  FAILED_TO_UPDATE_CARD = 'failed_to_update_card',
  PROVIDER_USER_NOT_FOUND = 'provider_user_not_found',
  FAILED_TO_FETCH_LINKED_ACCOUNTS_FROM_CARD_PROVIDER = 'failed_to_fetch_linked_accounts_from_card_provider',
  FAILED_TO_FETCH_CARD_TRANSACTIONS = 'failed_to_fetch_card_transactions',
  CARD_NOT_SPECIFIED = 'card_not_specified',
  CARD_BLOCKED = 'card_blocked',
}
