import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { BaseWidgetProperties, ListingTypeEnum } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { tap } from 'rxjs';
import {
  GetClientFeeChargeHistoryListing,
  GetFeeChargeHistoryListing,
  ResetSelectedFeeIds,
} from '../../../actions/fee-management.action';
import { FeeManagementService } from '../../../services/fee-management-service/fee-management.service';
import { FeeManagementState } from '../../../state/fee-management.state';
import { redirectToPage } from '../cta-button-actions.utils';

/**
 * Reverts charged fees based on selected fee IDs and updates the fee listing.
 *
 * @param widgetProperties - Properties of the widget, including navigation and listing type.
 * @param injector - Injector for retrieving necessary services.
 *
 * This function retrieves selected fee IDs from the store and attempts to revert the charged fees
 * using the FeeManagementService. Upon successful reversion, it resets the selected fee IDs and
 * dispatches actions to update the fee charge history listings based on the widget's listing type.
 * If a navigation zone is specified in the widget properties, it redirects to the appropriate page.
 * If no selected fee IDs are available, an error alert is displayed.
 */
export function revertChargedFee(widgetProperties: BaseWidgetProperties, injector: Injector) {
  const store = injector.get(Store);
  const _router = injector.get(Router);
  const feeManagementService = injector.get(FeeManagementService);
  const alertHandlerService = injector.get(AlertHandlerService);
  const selectedFeeIds = store.selectSnapshot(FeeManagementState.getFeeManagement)?.selectedFeeIds;
  const feeManagementState = store.selectSnapshot(FeeManagementState.getFeeManagement);
  if (selectedFeeIds) {
    feeManagementService
      .revertChargedFee(selectedFeeIds)
      .pipe(
        tap(() => {
          store.dispatch(new ResetSelectedFeeIds());
          if (widgetProperties?.['listingType'] === ListingTypeEnum.CLIENT_FEE_CHARGED_HISTORY) {
            const pageNumber = feeManagementState?.clientFeeChargeHistoryListing?.page;
            store.dispatch(
              new GetClientFeeChargeHistoryListing(
                pageNumber,
                100,
                feeManagementState?.selectedClientDetails?.id ?? '',
              ),
            );
          }
          if (widgetProperties?.['listingType'] === ListingTypeEnum.FEE_CHARGE_HISTORY_LISTING) {
            const pageNumber = feeManagementState?.feeChargeHistoryListing?.page;
            store.dispatch(new GetFeeChargeHistoryListing(pageNumber, 100));
          }
          if (widgetProperties?.['zoneToNavigate']) redirectToPage(_router, widgetProperties, injector);
        }),
      )
      .subscribe();
  } else {
    alertHandlerService.showAlertFn('error', 'Selected fee details not available', 'Failed to revert fee');
  }
}
