<div id="pin-input" class="mt-8">
  <div class="flex-center" class="mb-4">
    <div class="is-horizontal-align">
      <label for="code" class="typog-md font-weight-normal">{{ localeValues.subtitle }}</label>
    </div>
  </div>

  <div class="pin-container">
    <input
      #pinInput
      type="number"
      pattern="[0-9]*"
      inputmode="numeric"
      [value]="pin"
      (input)="handleDesktopPinBoxes($event)"
      class="pin-hidden-input"
      autocomplete="off"
    />

    <div
      class="pin-boxes"
      (click)="pinBoxesFocus()"
      (clickOutside)="pinBoxesBlur()"
      [ngClass]="{ focused: pinBoxesFocused }"
    >
      <div
        *ngFor="let _ of [].constructor(maxLength); let i = index"
        class="pin-box is-center inputField"
        [class.filled]="pin.length > i"
        [class.active]="pin.length === i"
        [class.error]="error"
      >
        <div *ngIf="pin[i]" class="pin-dot"></div>
        <div *ngIf="pin.length === i" class="pin-cursor"></div>
      </div>
    </div>
    <div *ngIf="isOtp">
      <div *ngIf="timer > 0" class="is-horizontal-align mt-4">
        {{ localeValues.resendFrontText }}{{ localeValues.resendFrontTimerText }}{{ timer
        }}{{ localeValues.resendBackText }}
      </div>
      <div *ngIf="!isDisableResendBtn" class="is-horizontal-align mt-4">
        <a (click)="sendOtp()">{{ localeValues.resendLinkText }}</a>
      </div>
    </div>
  </div>
</div>
