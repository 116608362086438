<div [ngStyle]="contentStyle">
  <form
    class="is-vertical-align h-100 w-100 pointer-events-none"
    [formGroup]="intlBeneficiaryCredentialsForm"
  >
    <drag-scroll
      [scrollbar-hidden]="true"
      [drag-scroll-disabled]="true"
      [snap-duration]="100"
      (indexChanged)="onIndexChanged($event)"
      class="w-100"
      #beneficiaryDetailsFieldsCarousel
    >
      <ng-container *ngFor="let field of fieldsList">
        <div class="w-100 carousel mr-0 pt-3" drag-scroll-item>
          <finxone-input
            type="text"
            [formControlName]="field.title"
            [label]="
              field.item.requirement === 'required'
                ? field.label + '*' + currentFieldAndCount
                : field.label + currentFieldAndCount
            "
            [value]="intlBeneficiaryCredentialsForm.get?.(field.title)?.value"
            (keydown.Tab)="onKeyDown($event)"
            (input)="valueChangeTrigger()"
            [validationConfig]="fieldsValidationConfig[field.title]"
          >
          </finxone-input>
          <div class="text-danger error-message" [ngClass]="{ active: !isFormFieldValid && nextBtnFailed }">
            <small>{{ getErrorMessage() }}</small>
          </div>
        </div>
      </ng-container>
    </drag-scroll>

    <div class="nav-buttons gap-1 space-evenly w-100 mb-4">
      <button
        (click)="goToPreviousField()"
        [ngStyle]="!buttonGlobalStyles.previousButton.isAllowedGlobalStyling ? previousButtonStyle : {}"
        class="d-flex"
        [ngClass]="
          buttonGlobalStyles.previousButton.isAllowedGlobalStyling
            ? buttonGlobalStyles.previousButton.globalStylingClass + ' buttonText'
            : ''
        "
        [disabled]="currentIndex === 0"
      >
        <mat-icon [svgIcon]="'left-arrow'" class="button-icon-svg"></mat-icon>
        {{ previousButtonContent }}
      </button>

      <button
        (click)="goToNextField()"
        [ngStyle]="!buttonGlobalStyles.nextButton.isAllowedGlobalStyling ? nextButtonStyle : {}"
        class="d-flex"
        [ngClass]="
          buttonGlobalStyles.nextButton.isAllowedGlobalStyling
            ? buttonGlobalStyles.nextButton.globalStylingClass + ' buttonText'
            : ''
        "
        [disabled]="currentIndex === fieldsList.length - 1"
      >
        {{ nextButtonContent }}
        <mat-icon [svgIcon]="'arrow-right'" class="button-icon-svg"></mat-icon>
      </button>
    </div>
    @if (!isSubmitTypeAsynchronous) {
    <div>
      <finx-button
        (click)="onSubmit()"
        [labeltext]="continueButtonContent"
        class="continue-button pointer-events-none d-flex"
        [buttonStyle]="!buttonGlobalStyles.continueButton.isAllowedGlobalStyling ? continueButtonStyle : {}"
        [class.h-100]="!buttonGlobalStyles.continueButton.isAllowedGlobalStyling"
        [class]="
          buttonGlobalStyles.continueButton.isAllowedGlobalStyling
            ? buttonGlobalStyles.continueButton.globalStylingClass + ' buttonText'
            : 'w-100 mandatory-cta-button'
        "
        [disabled]="intlBeneficiaryCredentialsForm.status === 'INVALID'"
        [class.disabled-class]="intlBeneficiaryCredentialsForm.status === 'INVALID'"
        [isAllowedGlobalStyling]="buttonGlobalStyles.continueButton.isAllowedGlobalStyling"
      ></finx-button>
    </div>
    }
  </form>
</div>
