import {
  APP_ZONES,
  BaseWidgetProperties,
  GridPosition,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import { BackButtonWidgetBuilder, LineWidgetBuilder } from '../../../widgets/example-builder';
import { BackButtonWidgetBuilderCssConfig } from '../../../widgets/payments/payments.type';
import {
  bulkPaymentFooter,
  generateImageWidget,
  generateTextWidget,
  getQuickLinkWidget,
} from '../common-widgets-utils';

export const bulkPaymentLandingWidgets = [
  generateImageWidget({
    position: { x: 20, y: 2, rows: 3, cols: 3 } as GridPosition,
    displayName: 'Image Widget: Bulk Edit',
    styles: {
      selectedobjectfit: 'contain',
    },
    atributes: {
      widgetProperties: {
        textContent: '',
        imageAssetUrl: 'assets/images/bulk-edit.svg',
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes,
    isRemovable: true,
  }),

  generateTextWidget({
    text: 'Bulk Payments to Personal/Business',
    position: { x: 14, y: 5, rows: 2, cols: 15 } as GridPosition,
    displayName: 'Header Text',
    styles: {
      fontSize: 24,
      isTextBold: true,
      verticalAlignment: 'start',
      textAlignment: 'left',
      noAlignment: true,
      paddingLeft: 64,
      paddingTop: 40,
    },
    isRemovable: true,
  }),

  generateTextWidget({
    text: 'Effortlessly handle hundreds of payments by simply uploading a single file. Eliminate the need for manual processing!',
    position: { x: 12, y: 7, rows: 3, cols: 18 } as GridPosition,
    displayName: 'Information Content',
    styles: {
      justifyContent: 'center',
      textAlignment: 'center',
      fontSize: '16',
      lineHeight: '24',
      textFont: 'var(--font-family)',
      color: 'var(--typography-color-1)',
    },
    isRemovable: true,
  }),

  generateImageWidget({
    position: { x: 10, y: 10, rows: 3, cols: 5 } as GridPosition,
    displayName: 'Image Widget: Instant Payment',
    styles: {
      selectedobjectfit: 'contain',
    },
    atributes: {
      widgetProperties: {
        textContent: '',
        imageAssetUrl: 'assets/images/instant-payment.svg',
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes,
    isRemovable: true,
  }),
  generateImageWidget({
    position: { x: 16, y: 10, rows: 3, cols: 5 } as GridPosition,
    displayName: 'Image Widget: Schedule Payment',
    styles: {
      selectedobjectfit: 'contain',
    },
    atributes: {
      widgetProperties: {
        textContent: '',
        imageAssetUrl: 'assets/images/scheduled-payments.svg',
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes,
    isRemovable: true,
  }),
  generateImageWidget({
    position: { x: 22, y: 10, rows: 3, cols: 5 } as GridPosition,
    displayName: 'Image Widget: Payments',
    styles: {
      selectedobjectfit: 'contain',
    },
    atributes: {
      widgetProperties: {
        textContent: '',
        imageAssetUrl: 'assets/images/payments-corporate-fare.svg',
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes,
    isRemovable: true,
  }),

  generateImageWidget({
    position: { x: 28, y: 10, rows: 3, cols: 5 } as GridPosition,
    displayName: 'Image Widget: Running Error',
    styles: {
      selectedobjectfit: 'contain',
    },
    atributes: {
      widgetProperties: {
        textContent: '',
        imageAssetUrl: 'assets/images/running-error.svg',
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes,
    isRemovable: true,
  }),

  new BackButtonWidgetBuilder()
    .defaultBackButton()
    .cssConfig(BackButtonWidgetBuilderCssConfig)
    .gridPosition({ x: 16, y: 15, rows: 2, cols: 5 } as GridPosition)
    .isRemovable(true)
    .build(),

  getQuickLinkWidget({
    text: 'Get Started',
    position: { x: 22, y: 15, rows: 2, cols: 5 } as GridPosition,
    pageUrl: 'bulk-payment-account-selection',
    displayName: 'Quick Link: Get Started',
    styles: {
      backGroundColor: 'var(--palette-color-3)',
      color: 'var(--palette-color-6)',
      borderWidth: '1',
      fontSize: '16',
      borderRadiusTopLeft: '5',
      borderRadiusTopRight: '5',
      borderRadiusBottomLeft: '5',
      borderRadiusBottomRight: '5',
      marginBottom: '12',
      marginTop: '12',
      paddingTop: '11',
      paddingBottom: '11',
      paddingRight: '11',
      paddingLeft: '11',
      textAlignment: 'center',
      justifyContent: 'center',
    },
    zone: APP_ZONES.PAYMENT,
    isRemovable: false,
  }),

  getQuickLinkWidget({
    text: 'View Bulk Payments History',
    position: { x: 16, y: 17, rows: 2, cols: 10 } as GridPosition,
    pageUrl: 'bulk-payment-history',
    displayName: 'Quick Link: View Bulk Payments History',
    styles: {
      color: 'var(--palette-color-3)',
      fontSize: '16',
      backGroundColor: 'transparent',
      textAlignment: 'right',
      isTextUnderline: true,
      justifyContent: 'center',
      marginRight: 70,
    },
    zone: APP_ZONES.PAYMENT,
    isRemovable: false,
  }),

  new LineWidgetBuilder()
    .configDisplayName('Line Widget')
    .gridPosition({ x: 1, y: 20, rows: 1, cols: 40 } as GridPosition)
    .attributes({
      widgetProperties: {
        color: 'var(--border-color-1)',
        'border-bottom': 'var(--border-color-1)',
        lineThickness: 1,
        lineWidth: '100',
        textContent: '',
        show: true,
        paddingTop: 20,
        paddingBottom: 20,
        contentAlignment: 'center',
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .build(),

  ...bulkPaymentFooter(21),
];
