import {
  ButtonSizeOptions,
  ButtonTypes,
  DefaultToggleOption,
  GridPosition,
  NotificationAlertTypeEnum,
  NotificationChannelEnum,
} from '@finxone-platform/shared/sys-config-types';
import { BackButtonWidgetBuilder, NotificationsWidgetBuilder } from '../../../../widgets/example-builder';
import { commonTextWidget } from '../../../common/common-widgets';
import { getTextWidgetConfig } from '../utils/common-utils';
import {
  getNotificationSettingsValue,
  getNotificationWidgetConfig,
  getSwitchNavigatorWidgetConfigNotificationsPage,
} from '../utils/notification-utils';

const textWidgetCommonCssConfig = {
  fontSize: 14,
  isTextBold: false,
  textAlignment: 'left',
  verticalAlignment: 'start',
  display: 'flex',
  color: 'var(--typography-color-0)',
  textFont: 'var(--font-family)',
};

export const notificationInPushWidgets = [
  getSwitchNavigatorWidgetConfigNotificationsPage(),

  commonTextWidget({
    textContent: `<p><b> Push notifications </b> are short, alert-style messages that pop up on your device screen, even when you're not using an app. They are sent to inform you about important updates, new messages, or other timely information, helping you stay informed without needing to open the app.</p>`,
    configDisplayName: `Push notification's information`,
    cssConfig: {
      fontSize: 14,
      isTextBold: false,
      textAlignment: 'left',
    },
    gridPosition: {
      y: 5,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 7,
      cols: 15,
    },
  }),

  getNotificationWidgetConfig(
    NotificationAlertTypeEnum.TRANSACTION_ALERTS,
    NotificationChannelEnum.PUSH_NATIVE,
    DefaultToggleOption.ON,
  ),

  getTextWidgetConfig(),

  getNotificationWidgetConfig(
    NotificationAlertTypeEnum.ADD_BENEFICIARY_ALERTS,
    NotificationChannelEnum.PUSH_NATIVE,
    DefaultToggleOption.ON,
  ),

  commonTextWidget({
    textContent: `
      <div class="w-100 list-style-disc">
        <ul class="pl-4">
          <li class="p-0" >Alerts relating to a new beneficiary being added, or an existing beneficiary being edited or deleted.</li>
        </ul>
      </div>
    `,
    configDisplayName: `Beneficiary's alert information`,
    cssConfig: textWidgetCommonCssConfig,
    gridPosition: {
      y: 19,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 3,
      cols: 15,
    },
  }),

  new NotificationsWidgetBuilder()
    .notificationSetting(
      getNotificationSettingsValue(
        NotificationAlertTypeEnum.DAILY_BALANCE,
        NotificationChannelEnum.PUSH_NATIVE,
        DefaultToggleOption.ON,
      ),
    )
    .text(`<p class="m-0">Daily Balance Updates</p>`)
    .gridPosition({ y: 24 } as GridPosition)
    .isRemovable(false)
    .build(),

  commonTextWidget({
    textContent: `
    <div class="w-100 p-0 list-style-disc">
        <ul class="pl-4">
        <li class="p-0">Balance updates after each transaction.</li>
        </ul>
    </div>
    `,
    configDisplayName: `Daily balance's information`,
    cssConfig: textWidgetCommonCssConfig,
    gridPosition: {
      y: 27,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 3,
      cols: 15,
    },
  }),

  new BackButtonWidgetBuilder()
    .defaultBackButton()
    .configDisplayName('Back Navigation Button')
    .text('Back')
    .cssConfig({
      color: 'var(--palette-color-3)',
      backGroundColor: 'var(--palette-color-6)',
      borderWidth: 1,
      borderColor: 'var(--palette-color-3)',
      fontSize: 14,
      paddingLeft: 20,
      paddingRight: 20,
      marginTop: 0,
      marginBottom: 0,
      textAlign: 'center',
      verticalAlignment: 'center',
    })
    .gridPosition({
      y: 32,
      x: 0,
      rows: 2,
      cols: 15,
      resizeEnabled: true,
      dragEnabled: true,
    })
    .setGlobalStylingClass(true, `${ButtonTypes.SECONDARY} ${ButtonSizeOptions.FULL_WIDTH}`)
    .build(),
];
