export const forgotPassDesktopConfig = {
  passForgot: { x: 17, y: 5, rows: 7, cols: 15 },
  passText: { x: 17, y: 12, rows: 2, cols: 15 },
  cancelButton: { x: 17, y: 16, rows: 2, cols: 15 },
};

export const forgotPassMobileConfig = {
  passForgot: { x: 0, y: 2, rows: 11, cols: 15 },
  passText: { x: 0, y: 12, rows: 3, cols: 15 },
  cancelButton: { x: 0, y: 27, rows: 2, cols: 15 },
};
