import {
  CommonPageConfig,
  PACKS,
  UserManagementPagesTags,
  WorkflowPageConfig,
  WorkflowWidgetConfig,
} from '@finxone-platform/shared/sys-config-types';

import { getSuccessOrErrorPageWidgets } from '../../../page-builder-utils/widget-builder-utils/desktop-page-utils/success-and-error-page.utils';
import { PageConfig } from '../../../zones/mobile/default-zone-page-setups';
import { formatPageName } from '../../pages/utils';
import { defaultPageConfigurations } from '../../widgets/workflow-ui-constants.v2.type';
import {
  addEmployeeFailedWidgets,
  addEmployeeStep1Widgets,
  addEmployeeStep2Widgets,
  addEmployeeStep3Widgets,
  addEmployeeSuccessWidgets,
  addEmployeeSummaryDetailsWidgets,
  assignPositionWidgets,
} from './add-employee/add-employee-widgets';
import { addUsersStepOneWidgets } from './manage-users-widgets-section/add-users-step-one-widgets';
import { addUsersStepThreeWidgets } from './manage-users-widgets-section/add-users-step-three-widgets';
import { addUsersStepTwoWidgets } from './manage-users-widgets-section/add-users-step-two-widgets';
import { assignPersonaWidgets } from './manage-users-widgets-section/assign-persona-widgets';
import { manageUsersReviewDetailsWidgets } from './manage-users-widgets-section/manage-users-review-details-widget';
import { manageUsersViewWidgets } from './manage-users-widgets-section/manage-users-view-widget';
import { manageUsersWidgets } from './manage-users-widgets-section/manage-users-widget';

export function buildDesktopManageUserZonePagesConfig(): WorkflowPageConfig[] {
  const setupPagesConfig: WorkflowPageConfig[] = [];
  const defaultManageUserZonePages: {
    name: string;
    title: string;
    widgets: WorkflowWidgetConfig[];
    tags: string[];
  }[] = [
    {
      name: 'Select User',
      title: 'Select User',
      widgets: manageUsersWidgets,
      tags: [UserManagementPagesTags.USER_DETAILS],
    },
    {
      name: 'View User',
      title: 'View User',
      widgets: manageUsersViewWidgets,
      tags: [UserManagementPagesTags.USER_DETAILS],
    },
  ];

  defaultManageUserZonePages.forEach((page) => {
    const pageObj: WorkflowPageConfig = {
      name: formatPageName(page.name),
      title: page.title,
      widgets: page.widgets,
      pageConfiguration: {
        url: formatPageName(page.name),
        pageType: 'dynamicGrid',
        isRemovable: false,
        mainComponentPageName: 'ParentContainerComponent',
        ...defaultPageConfigurations,
      },
    };
    setupPagesConfig.push(pageObj);
  });

  return setupPagesConfig;
}

export function buildManageUserZoneAdditionalPages(
  tags: string[],
  packs: string[],
): {
  name: string;
  title: string;
  url?: string;
  widgets?: WorkflowWidgetConfig[];
  tags: string[];
  pageConfiguration: CommonPageConfig;
  packs?: string[];
}[] {
  // Finds associated pages for each tag and then deduplicates the resulting array to only get unique pages
  const additionalManageUserZonePages = buildManageUserAdditionalPagesConfig();

  const tagsFilteredPages = additionalManageUserZonePages.filter((page) =>
    tags.some((tag) => page.tags.includes(tag)),
  );

  const packsFilteredPages = tagsFilteredPages.filter((page) =>
    packs.some((pack) => page?.packs?.includes(pack)),
  );

  return packsFilteredPages;
}

export function buildManageUserAdditionalPagesConfig(): {
  name: string;
  title: string;
  url?: string;
  widgets?: WorkflowWidgetConfig[];
  tags: string[];
  pageConfiguration: CommonPageConfig;
  packs: string[];
}[] {
  const additionalManageUserPages: PageConfig[] = [
    {
      name: 'Select User',
      title: 'Select User',
      widgets: manageUsersWidgets,
      tags: [UserManagementPagesTags.USER_DETAILS],
      packs: [PACKS.FINXONE],
    },
    {
      name: 'View User',
      title: 'View User',
      widgets: manageUsersViewWidgets,
      tags: [UserManagementPagesTags.USER_DETAILS],
      packs: [PACKS.FINXONE],
    },
    {
      name: 'Assign Persona',
      title: 'Assign Persona',
      widgets: assignPersonaWidgets,
      tags: [UserManagementPagesTags.ADD_USER_AND_PERSONA],
      packs: [PACKS.FINXONE],
    },
    {
      name: 'Add User - Step 1',
      title: 'Add User - Step 1',
      widgets: addUsersStepOneWidgets,
      tags: [UserManagementPagesTags.ADD_USER_AND_PERSONA],
      packs: [PACKS.FINXONE],
    },
    {
      name: 'Add User - Step 2',
      title: 'Add User - Step 2',
      widgets: addUsersStepTwoWidgets,
      tags: [UserManagementPagesTags.ADD_USER_AND_PERSONA],
      packs: [PACKS.FINXONE],
    },
    {
      name: 'Add User - Step 3',
      title: 'Add User - Step 3',
      widgets: addUsersStepThreeWidgets,
      tags: [UserManagementPagesTags.ADD_USER_AND_PERSONA],
      packs: [PACKS.FINXONE],
    },
    {
      name: 'Add User - Review',
      title: 'Add User - Review',
      widgets: manageUsersReviewDetailsWidgets,
      tags: [UserManagementPagesTags.ADD_USER_AND_PERSONA],
      packs: [PACKS.FINXONE],
    },
    {
      name: 'Access Granted',
      title: 'Access Granted',
      widgets: getSuccessOrErrorPageWidgets('success'),
      tags: [UserManagementPagesTags.ADD_USER_AND_PERSONA],
      packs: [PACKS.FINXONE],
    },
    {
      name: 'Access Error',
      title: 'Access Error',
      widgets: getSuccessOrErrorPageWidgets('error'),
      tags: [UserManagementPagesTags.ADD_USER_AND_PERSONA],
      packs: [PACKS.FINXONE],
    },
    {
      name: 'Assign Position',
      title: 'Assign Position',
      widgets: assignPositionWidgets,
      tags: [UserManagementPagesTags.ADD_USER_AND_PERSONA],
      packs: [PACKS.TRAVEL_MANAGEMENT],
    },
    {
      name: 'Add Employee Step 1',
      title: 'Add Employee Step 1',
      widgets: addEmployeeStep1Widgets,
      tags: [UserManagementPagesTags.ADD_USER_AND_PERSONA],
      packs: [PACKS.TRAVEL_MANAGEMENT],
    },
    {
      name: 'Add Employee Step 2',
      title: 'Add Employee Step 2',
      widgets: addEmployeeStep2Widgets,
      tags: [UserManagementPagesTags.ADD_USER_AND_PERSONA],
      packs: [PACKS.TRAVEL_MANAGEMENT],
    },
    {
      name: 'Add Employee Step 3',
      title: 'Add Employee Step 3',
      widgets: addEmployeeStep3Widgets,
      tags: [UserManagementPagesTags.ADD_USER_AND_PERSONA],
      packs: [PACKS.TRAVEL_MANAGEMENT],
    },
    {
      name: 'Add Employee Summary Details',
      title: 'Add Employee Summary Details',
      widgets: addEmployeeSummaryDetailsWidgets,
      tags: [UserManagementPagesTags.ADD_USER_AND_PERSONA],
      packs: [PACKS.TRAVEL_MANAGEMENT],
    },
    {
      name: 'Assign Success',
      title: 'Assign Success',
      widgets: addEmployeeSuccessWidgets,
      tags: [UserManagementPagesTags.ADD_USER_AND_PERSONA],
      packs: [PACKS.TRAVEL_MANAGEMENT],
    },
    {
      name: 'Assign Failed',
      title: 'Assign Failed',
      widgets: addEmployeeFailedWidgets,
      tags: [UserManagementPagesTags.ADD_USER_AND_PERSONA],
      packs: [PACKS.TRAVEL_MANAGEMENT],
    },
  ];

  const manageUserPagesArray: {
    name: string;
    title: string;
    url?: string;
    widgets?: WorkflowWidgetConfig[];
    tags: string[];
    pageConfiguration: CommonPageConfig;
    packs: string[];
  }[] = [];
  additionalManageUserPages.forEach((page) => {
    manageUserPagesArray.push({
      name: formatPageName(page.title),
      title: page.title,
      url: formatPageName(page.title),
      widgets: page.widgets,
      tags: page.tags,
      pageConfiguration: defaultPageConfigurations as CommonPageConfig,
      packs: page.packs as string[],
    });
  });
  return manageUserPagesArray;
}
