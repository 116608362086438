export function loadStyleSheet(themeUrl: string) {
  if (document.getElementById('globalTheme')) {
    document.getElementById('globalTheme')?.remove();
  }
  const styleElement = document.createElement('link');
  styleElement.setAttribute('id', 'globalTheme');
  styleElement.setAttribute('rel', 'stylesheet');
  styleElement.setAttribute('href', `${themeUrl}`);
  // Append the style element to the document's head
  document.head.appendChild(styleElement);
}

/**
 * Transforms raw CSS by prepending the dynamic ID/Selector to each existing selector.
 * @param css - The raw CSS string
 * @param id - The dynamic ID/Selector to prepend
 * @returns Transformed CSS string
 */
export function transformCSSWithDynamicSelector(css: string, id: string): string {
  // Use regex to prepend the ID to each selector
  return css.replace(/(^|\})\s*([^{]+)/g, (_, closeBrace, selector) => {
    const scopedSelector = selector
      .split(',')
      .map((s: string) => `${id} ${s.trim()}`)
      .join(', ');
    return `${closeBrace} ${scopedSelector}`;
  });
}
