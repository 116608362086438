import { Injector } from '@angular/core';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import {
  ButtonActionType,
  DocumentType,
  UiZoneWidgetAttributeConfig,
} from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import {
  addBeneficiary,
  approveBulkPayment,
  cancelAllPayment,
  downloadCsvTemplate,
  goToPrePayment,
  makePayment,
  proceedBulkPayment,
  proceedCSVFile,
  refreshBulkPayment,
  rejectAllPayment,
} from '../bulk-payments/bulk-payments.utils';
import { functionCtaType } from '../cta-button-actions.utils';
import { downloadBankStatement } from '../profile-zone/download-bank-statement.utils';

export const bulkPaymentsActions: Map<ButtonActionType, functionCtaType> = new Map([
  [
    ButtonActionType.REFRESH_BULK_PAYMENT_LIST,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      refreshBulkPayment(injector.get(Store));
    },
  ],
  [
    ButtonActionType.BULK_PAYMENT_BENEFICIARY_VALIDATION,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      goToPrePayment(injector);
    },
  ],
  [
    ButtonActionType.BULK_PAYMENT_ADD_BENEFICIARY,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      addBeneficiary(injector);
    },
  ],
  [
    ButtonActionType.DOWNLOAD_CSV_BANK_STATEMENT,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      downloadBankStatement(attri.widgetProperties, injector, DocumentType.CSV);
    },
  ],
  [
    ButtonActionType.DOWNLOAD_PDF_BANK_STATEMENT,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      downloadBankStatement(attri.widgetProperties, injector, DocumentType.PDF);
    },
  ],
  [
    ButtonActionType.BULK_PAYMENT_MAKE_PAYMENT,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      makePayment(attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.DOWNLOAD_CSV,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      downloadCsvTemplate(injector.get(AlertHandlerService));
    },
  ],
  [
    ButtonActionType.BULK_PAYMENT_APPROVER,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      approveBulkPayment(attri.widgetProperties, injector);
    },
  ],

  [
    ButtonActionType.BULK_PAYMENT_REJECT,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      rejectAllPayment(injector);
    },
  ],
  [
    ButtonActionType.BULK_PAYMENT_AUTHENTICATION_LINK,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      proceedBulkPayment(attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.BULK_PAYMENT_CANCEL,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      cancelAllPayment(injector);
    },
  ],
  [
    ButtonActionType.BULK_PAYMENT_DOWNLOAD_UPLOAD_PROCESSING,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      proceedCSVFile(injector);
    },
  ],
]);
