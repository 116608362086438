import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonActionType, UiZoneWidgetAttributeConfig } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { ResetFormDataAction } from '../../../actions/form-submission.action';
import { functionCtaType, redirectToPage } from '../cta-button-actions.utils';
import { handleAddEditFeeSubmission } from '../fee-management-zone/add-edit-fee-action.utils';
import { handleAddEditFeeGroupSubmission } from '../fee-management-zone/add-edit-fee-group-action.utils';
import { handleAdHocFeeSubmission } from '../fee-management-zone/create-ad-hoc-fee-action.utils';
import { removeFeeAction, removeFeeGroupAction } from '../fee-management-zone/remove-action.utils';
import { handleRemoveClientFromFeeGroupRequest } from '../fee-management-zone/remove-client-from-fee-group-action.utils';
import { retryFailedFee } from '../fee-management-zone/retry-fee-action.utils';
import { revertChargedFee } from '../fee-management-zone/revert-fee-action.utils';
import { handleUpdateClientFeeGroupRequest } from '../fee-management-zone/update-client-fee-group.utils';

export const feeManagementActions: Map<ButtonActionType, functionCtaType> = new Map([
  [
    ButtonActionType.ADD_EDIT_FEE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      handleAddEditFeeSubmission(attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.CREATE_AD_HOC_FEE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      handleAdHocFeeSubmission(attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.ADD_EDIT_FEE_GROUP,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      handleAddEditFeeGroupSubmission(attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.UPDATE_CLIENT_FEE_GROUP,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      handleUpdateClientFeeGroupRequest(attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.REMOVE_CLIENT_FROM_FEE_GROUP,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      handleRemoveClientFromFeeGroupRequest(attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.CLEAR_FORM_STATE_AND_REDIRECT_TO_FEE_GROUPS_LISTING,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      injector.get(Store).dispatch(new ResetFormDataAction());
      redirectToPage(injector.get(Router), attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.REMOVE_FEE_GROUP,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      removeFeeGroupAction(attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.REMOVE_FEE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      removeFeeAction(attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.RETRY_FEE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      retryFailedFee(attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.REVERT_FEE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      revertChargedFee(attri.widgetProperties, injector);
    },
  ],
]);
