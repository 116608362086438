import { Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { take, tap } from 'rxjs';
import { SetCardNameAction } from '../../../actions/card-management.action';
import { CloseModalPopupAction } from '../../../actions/page-context.action';
import { WorkflowFormsService } from '../../../services/workflow-forms/workflow-forms.service';
import { CardState } from '../../../state/card.state';
import { redirectToPage } from '../cta-button-actions.utils';

/**
 * Handles the process of setting a card name by retrieving necessary services and data.
 *
 * @param injector - The Angular Injector used to retrieve services.
 *
 * This function retrieves the Store and WorkflowFormsService instances from the injector,
 * obtains the current card ID and form data for the card name, and checks the form's validity.
 * If the form is valid, it proceeds to handle the card name form submission.
 */
export function onSetCardName(injector: Injector, widgetProperties: BaseWidgetProperties): void {
  const store = injector.get(Store);
  const workflowFormsService = injector.get(WorkflowFormsService);
  const cardId = store.selectSnapshot(CardState.getCurrentCardId);
  const data = workflowFormsService.getFormValues('cards.cardName');
  const isFormValid = workflowFormsService.isFormValid('cards.cardName');
  if (!isFormValid) {
    return;
  }
  handleCardNameForm(data, cardId, injector, widgetProperties);
}

/**
 * Handles the card name form submission by extracting the card name from the form data
 * and dispatching an action to set the card name in the store.
 *
 * @param data - The form data containing the card name.
 * @param cardId - The ID of the card to update, or null if not available.
 * @param injector - The Angular injector used to retrieve dependencies.
 */
function handleCardNameForm(
  data: any,
  cardId: string | null,
  injector: Injector,
  widgetProperties: BaseWidgetProperties,
): void {
  const cardName = data.cardName;
  dispatchSetCardName(cardId || '', cardName, injector, widgetProperties);
}

/**
 * Dispatches the SetCardNameAction to update the card name in the store and navigates
 * to the landing page upon successful dispatch. Logs an error if the dispatch fails.
 *
 * @param cardId - The unique identifier of the card to be updated.
 * @param cardName - The new name to be set for the card.
 * @param injector - The Angular Injector used to retrieve the Store and Router services.
 */
function dispatchSetCardName(
  cardId: string,
  cardName: string,
  injector: Injector,
  widgetProperties: BaseWidgetProperties,
): void {
  const store = injector.get(Store);
  store
    .dispatch(new SetCardNameAction(cardId, cardName))
    .pipe(
      take(1),
      tap({
        next: () => navigateToLandingPage(injector, widgetProperties),
        error: (error) => {
          console.error('Error dispatching SetCardNameAction:', error);
        },
      }),
    )
    .subscribe();
}

/**
 * Navigates to the landing page using the provided injector to obtain
 * necessary services. It constructs mock widget properties with a
 * predefined zone and URL, then utilizes the `redirectToPage` function
 * to perform the navigation.
 *
 * @param injector - The Injector instance used to retrieve the Router service.
 */
function navigateToLandingPage(injector: Injector, widgetProperties: BaseWidgetProperties): void {
  const router = injector.get(Router);
  const activatedRouter = injector.get(ActivatedRoute);
  const store = injector.get(Store);
  const isPrevModal = activatedRouter.snapshot?.queryParams?.['isModal'] === 'true';
  store.dispatch(new CloseModalPopupAction(isPrevModal));
  redirectToPage(router, widgetProperties, injector);
}
