import {
  manageUserLoginDetails,
  manageUserPersonalInformationTemplate,
  manageUsersAddressTemplate,
} from '@finxone-platform/shared/default-templates';
import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonActionType,
  Constants,
  GridPosition,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import { generateViewUsersAssignPersonaWidgetsConfig } from '../../../widgets/admin-roles/user-managment.widgets';
import { FormSubmissionButtonWidgetBuilder, LineWidgetBuilder } from '../../../widgets/example-builder';
import { generateTextWidget, getBackButtonWidget } from '../common-utils';

export const defaultTitleTextWidgetCssConfig = {
  ...Constants.defaultBaseCssConfig,
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 0,
  borderRadiusTopLeft: 5,
  borderRadiusBottomLeft: 5,
  borderRadiusTopRight: 5,
  borderRadiusBottomRight: 5,
  borderColor: 'var(--border-color-1)',
  backGroundColor: 'var(--palette-color-6)',
  justifyContent: 'left',
  verticalAlignment: 'center',
  borderWidth: 1,
  fontSize: 20,
  isTextBold: true,
};

export const manageUsersViewWidgets = [
  generateTextWidget({
    text: '{{manageUserView.fullName}}',
    position: {
      y: 1,
      rows: 1,
      cols: 20,
      x: 1,
    } as GridPosition,
    displayName: 'Text Widget: Name',
    styles: {
      color: 'var(--typography-color-0)',
      fontSize: 20,
      isTextBold: true,
      paddingLeft: 25,
      textAlignment: 'left',
    },
    isRemovable: true,
  }),
  getBackButtonWidget({
    position: { y: 1, x: 31, rows: 1, cols: 3 } as GridPosition,
    styles: {
      fontSize: 16,
      textAlignment: 'center',
      color: 'var(--palette-color-3)',
      backGroundColor: 'var(--palette-color-6)',
      borderWidth: 1,
      borderColor: 'var(--palette-color-3)',
    },
  }),
  new FormSubmissionButtonWidgetBuilder()
    .configDisplayName('Button:Edit and Save')
    .gridPosition({
      y: 1,
      x: 35,
      rows: 1,
      cols: 5,
    } as GridPosition)
    .cssConfig({
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      verticalAlignment: 'center',
    })
    .attributes({
      widgetProperties: {
        textContent: 'Edit and Save',
        zoneToNavigate: APP_ZONES.USER_MANAGEMENT,
        urlToNavigate: 'select-user',
        buttonActionType: ButtonActionType.MANAGE_USER_EDIT_AND_SAVE,
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .isRemovable(false)
    .build(),
  new LineWidgetBuilder()
    .configDisplayName('Line Widget:Header Bottom Line')
    .gridPosition({
      y: 2,
      rows: 1,
      x: 1,
      cols: 40,
    } as GridPosition)
    .attributes({
      widgetProperties: {
        color: 'var(--border-color-1)',
        'border-bottom': 'var(--border-color-1)',
        lineThickness: 1,
        lineWidth: '100',
        textContent: '',
        show: true,
        contentAlignment: 'center',
        paddingLeft: 2,
      } as BaseWidgetProperties,
      css_config: {
        marginTop: 0,
      },
    } as WorkflowWidgetConfigurationAttributes)
    .build(),

  generateTextWidget({
    text: 'Personal Information',
    position: {
      y: 4,
      x: 1,
      rows: 2,
      cols: 12,
    } as GridPosition,
    displayName: 'Text Widget:Personal Information',
    styles: defaultTitleTextWidgetCssConfig,
    isRemovable: false,
  }),
  generateTextWidget({
    text: manageUserPersonalInformationTemplate,
    position: {
      y: 7,
      x: 1,
      rows: 6,
      cols: 12,
    } as GridPosition,
    displayName: 'Text Widget:Personal Information',
    styles: defaultTitleTextWidgetCssConfig,
    isRemovable: false,
  }),

  generateTextWidget({
    text: 'Login Details',
    position: {
      y: 14,
      x: 1,
      rows: 2,
      cols: 12,
    } as GridPosition,
    displayName: 'Text Widget:Login Details',
    styles: defaultTitleTextWidgetCssConfig,
    isRemovable: false,
  }),
  generateTextWidget({
    text: manageUserLoginDetails,
    position: {
      y: 17,
      x: 1,
      rows: 4,
      cols: 12,
    } as GridPosition,
    displayName: 'Text Widget:Login Details',
    styles: defaultTitleTextWidgetCssConfig,
    isRemovable: false,
  }),

  generateTextWidget({
    text: 'Persona & Role',
    position: {
      y: 4,
      x: 15,
      rows: 2,
      cols: 12,
    } as GridPosition,
    displayName: 'Text Widget:Persona & Role',
    styles: defaultTitleTextWidgetCssConfig,
    isRemovable: false,
  }),
  generateViewUsersAssignPersonaWidgetsConfig(),
  generateTextWidget({
    text: 'Address',
    position: {
      y: 4,
      x: 29,
      rows: 2,
      cols: 12,
    } as GridPosition,
    displayName: 'Text Widget:Address',
    styles: defaultTitleTextWidgetCssConfig,
    isRemovable: false,
  }),
  generateTextWidget({
    text: manageUsersAddressTemplate,
    position: {
      y: 7,
      x: 29,
      rows: 6,
      cols: 12,
    } as GridPosition,
    displayName: 'Text Widget:Address',
    styles: defaultTitleTextWidgetCssConfig,
    isRemovable: false,
  }),
];
