import { APP_ZONES, ButtonActionType, GridPosition } from '@finxone-platform/shared/sys-config-types';
import { commonCTAButton, commonQuickLink, commonTextWidget } from '../../../common/common-widgets';

export const barcodeScanningWidgets = [
  commonTextWidget({
    textContent: 'Barcode Scanning',
    configDisplayName: 'Barcode Scanning',
    cssConfig: {
      textAlignment: 'center',
      fontSize: 24,
      isTextBold: true,
      textFont: 'var(--font-family)',
    },
    gridPosition: {
      y: 8,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 15,
    },
  }),
  commonTextWidget({
    textContent:
      'By scanning your barcodes you will finalise your Trip and not be able to add any more transactions',
    configDisplayName: 'Barcode Sub-title',
    cssConfig: {
      textAlignment: 'center',
      fontSize: 16,
      textFont: 'var(--font-family)',
    },
    gridPosition: {
      y: 10,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 4,
      cols: 15,
    },
  }),
  commonCTAButton({
    zoneToNavigate: APP_ZONES.REVENIR_ZONE,
    urlToNavigate: 'scan-barcode-declaration',
    textContent: 'I Understand',
    buttonActionType: ButtonActionType.BARCODE_CONFIRM_CTA,
    formKey: '',
    gridPosition: {
      y: 15,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 15,
    } as GridPosition,
    cssConfig: {
      fontSize: 16,
      borderRadiusTopLeft: 8,
      borderRadiusTopRight: 8,
      borderRadiusBottomLeft: 8,
      borderRadiusBottomRight: 8,
    },
  }),
  commonQuickLink({
    cssConfig: {
      color: 'var(--typography-color-0)',
      backGroundColor: 'var(--palette-color-6)',
      fontSize: 16,
      marginTop: 0,
      marginBottom: 0,
      borderWidth: 1,
      borderColor: 'var(--border-color-0)',
      borderRadiusTopLeft: 8,
      borderRadiusTopRight: 8,
      borderRadiusBottomLeft: 8,
      borderRadiusBottomRight: 8,
      textFont: 'var(--font-family)',
    },
    gridPosition: { y: 18, x: 0, rows: 2, cols: 15 } as GridPosition,
    text: 'Cancel',
    configDisplayName: 'Cancel',
    zone: APP_ZONES.REVENIR_ZONE,
    navigateToPageUrl: 'trips-home-page',
  }),
];
