import {
  Constants,
  DocumentMonthsUpdate,
  DocumentType,
  DownloadDocumentType,
  GridPosition,
  TransactionInitiator,
  WorkflowWidgetConfig,
} from '@finxone-platform/shared/sys-config-types';
import {
  BackButtonWidgetBuilder,
  DownloadDocumentWidgetBuilder,
  SpacerWidgetBuilder,
  TextWidgetBuilder,
} from '../../../widgets';
import { defaultPdfCssConfig } from '../../profile';

export const exchangeTransactionDetailTemplate = `
  <div class="w-100">
  <div class="is-space-between w-100 mb-4">
    <div class="labelText textOnSurfaceMuted">Sold</div>
    <div class="body1 text-right textOnSurface">{{ currencyPipe transaction.currentExchangeTransactionDetail.sellAmount currencyCode=transaction.currentExchangeTransactionDetail.sellCurrency }}</div>
  </div>

  <div class="is-space-between w-100 mb-4">
    <div class="labelText textOnSurfaceMuted">Bought</div>
    <div class="body1 text-right textOnSurface">{{ currencyPipe transaction.currentExchangeTransactionDetail.buyAmount currencyCode=transaction.currentExchangeTransactionDetail.buyCurrency }}</div>
  </div>

  <div class="is-space-between w-100 mb-4">
    <div class="labelText textOnSurfaceMuted">Total Fees</div>
    <div class="body1 text-right textOnSurface">{{ currencyPipe transaction.currentExchangeTransactionDetail.feeAmount currencyCode=transaction.currentExchangeTransactionDetail.feeCurrency }}</div>
  </div>

  <div class="is-space-between w-100 mb-4">
    <div class="labelText textOnSurfaceMuted">Exchange Rate</div>
    <div class="body1 text-right textOnSurface">{{ currencyPipe 1 currencyCode=transaction.currentExchangeTransactionDetail.sellCurrency }} = {{ currencyPipe transaction.currentExchangeTransactionDetail.exchangeRate currencyCode=transaction.currentExchangeTransactionDetail.buyCurrency }}</div>
  </div>

  <hr class="my-4">

  <div class="is-space-between w-100 mb-4">
    <div class="labelText textOnSurfaceMuted">Source</div>
    <div class="is-vertical-align">
      <div class="body1 text-right textOnSurface text-capitalize">{{ transaction.currentExchangeTransactionDetail.sender.name }}</div>
      <span class="text-right textOnSurface subtitle1">{{ transaction.currentExchangeTransactionDetail.sender.accountNumber }}</span>
    </div>
  </div>

  <div class="is-space-between w-100 mb-4">
    <div class="labelText textOnSurfaceMuted">Destination</div>
    <div class="is-vertical-align">
      <div class="body1 text-right textOnSurface text-capitalize">{{ transaction.currentExchangeTransactionDetail.receiver.name }}</div>
      <span class="text-right textOnSurface subtitle1">{{ transaction.currentExchangeTransactionDetail.receiver.accountNumber }}</span>
    </div>
  </div>

  <hr class="my-4">

  <div class="is-space-between w-100 mb-4">
    <div class="labelText textOnSurfaceMuted">Created at</div>
    <div class="body1 text-right textOnSurface">{{datePipe transaction.currentExchangeTransactionDetail.originalExchangeDate format="d MMM y"}}</div>
  </div>

  <div class="is-space-between w-100">
    <div class="labelText textOnSurfaceMuted">Completed at</div>
    <div class="body1 text-right textOnSurface">{{datePipe transaction.currentExchangeTransactionDetail.settlementDate format="d MMM y"}}</div>
  </div>
</div>
`;

export function exchangeTransactionElaboratedViewWidgets(): WorkflowWidgetConfig[] {
  const widgets: WorkflowWidgetConfig[] = [
    new SpacerWidgetBuilder().setHeight({ height: 30, unit: 'px' }).build(),

    new TextWidgetBuilder()
      .configDisplayName('Text Widget: Exchange Successful')
      .setWidgetProperties({ textStyleClass: 'h6', textContent: 'Exchange Successful' })
      .cssConfig({
        color: 'var(--color-on-background)',
        textAlignment: 'center',
        textFont: 'var(--font-family)',
      })
      .gridPosition({
        x: 0,
        y: 0,
        rows: 2,
        cols: 15,
      } as GridPosition)
      .build(),
    new SpacerWidgetBuilder().setHeight({ height: 30, unit: 'px' }).build(),
    new TextWidgetBuilder()
      .configDisplayName('Text Widget: Exchange Details')
      .setWidgetProperties({
        textStyleClass: 'body1',
        textContent: exchangeTransactionDetailTemplate,
      })
      .cssConfig({
        ...Constants.defaultBaseCssConfig,
        backGroundColor: 'var(--color-surface)',
        borderRadiusTopLeft: 8,
        borderRadiusBottomLeft: 8,
        borderRadiusTopRight: 8,
        borderRadiusBottomRight: 8,
        paddingTop: 24,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 24,
      })
      .gridPosition({
        x: 0,
        y: 3,
        rows: 7,
        cols: 15,
      } as GridPosition)
      .build(),
    new SpacerWidgetBuilder().setHeight({ height: 60, unit: 'px' }).build(),
    new DownloadDocumentWidgetBuilder()
      .attributes({
        documentType: DownloadDocumentType.PROOF_OF_EXCHANGE,
        timeInterval: DocumentMonthsUpdate.NONE,
        downloadDocumentAs: DocumentType.PDF,
        textInputHeader: 'Download Proof of Exchange',
        elementsConfig: [],
        widgetProperties: {
          textContent: 'Download Proof of Exchange',
          transactionInitiator: TransactionInitiator.TRANSACTION,
        },
        css_config: {
          ...defaultPdfCssConfig,
          borderWidth: 0,
          paddingTop: 0,
          paddingLeft: 0,
          paddingRight: 0,
          paddingBottom: 0,
        },
      })
      .gridPosition({ x: 15, y: 12, rows: 2 } as GridPosition)
      .configDisplayName('Download Proof of Exchange')
      .isRemovable(false)
      .build(),
    new SpacerWidgetBuilder().setHeight({ height: 20, unit: 'px' }).build(),
    new BackButtonWidgetBuilder()
      .icon('')
      .configDisplayName('Close Button')
      .text('Close')
      .setGlobalStylingClass(true, 'secondaryButton full-width')
      .build(),
  ];
  return widgets;
}
