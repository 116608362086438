import { APP_ZONES, GridPosition } from '@finxone-platform/shared/sys-config-types';
import { BackButtonWidgetBuilder } from '../../../../widgets';
import { commonQuickLink, commonTextWidget } from '../../../common/common-widgets';

export const boardingPassMissingInformationWidgets = [
  commonTextWidget({
    textContent: 'We’re missing some information!',
    cssConfig: {
      textAlignment: 'center',
      fontSize: 19,
      isTextBold: true,
      paddingLeft: 20,
      paddingRight: 20,
      marginLeft: 20,
      marginRight: 20,
      textFont: 'var(--font-family)',
    },
    gridPosition: {
      y: 3,
      x: 0,
      rows: 2,
      cols: 15,
    } as GridPosition,
  }),

  commonTextWidget({
    textContent: 'Please upload your boarding pass in order to generate a barcode for your trip.',
    cssConfig: {
      fontSize: 16,
      textAlignment: 'center',
      paddingLeft: 20,
      paddingRight: 20,
      textFont: 'var(--font-family)',
    },
    gridPosition: {
      x: 0,
      y: 5,
      rows: 5,
      cols: 15,
    } as GridPosition,
  }),

  commonQuickLink({
    cssConfig: {
      marginTop: 0,
      marginBottom: 0,
      fontSize: 14,
      verticalAlignment: 'center',
      disabledBgColor: 'var(--palette-color-3)',
      disabledTextColor: 'var(--palette-color-6)',
      borderRadiusTopLeft: 8,
      borderRadiusTopRight: 8,
      borderRadiusBottomLeft: 8,
      borderRadiusBottomRight: 8,
      textFont: 'var(--font-family)',
    },
    gridPosition: { y: 24, x: 1, rows: 2, cols: 13 } as GridPosition,
    text: 'Upload boarding pass',
    configDisplayName: 'Upload boarding pass',
    zone: APP_ZONES.REVENIR_ZONE,
    navigateToPageUrl: 'upload-boarding-pass',
  }),

  new BackButtonWidgetBuilder()
    .defaultBackButton()
    .configDisplayName('Back Navigation Button')
    .text('Cancel')
    .cssConfig({
      textAlign: 'center',
      verticalAlignment: 'center',
      isTextBold: true,
      fontSize: 16,
      textAlignment: 'center',
      color: 'var(--palette-color-0)',
      backGroundColor: 'var(--palette-color-6)',
      borderWidth: 2,
      borderColor: 'var(--palette-color-0)',
      borderRadiusTopLeft: 8,
      borderRadiusTopRight: 8,
      borderRadiusBottomLeft: 8,
      borderRadiusBottomRight: 8,
      textFont: 'var(--font-family)',
    })
    .gridPosition({
      x: 1,
      y: 27,
      rows: 2,
      cols: 13,
    } as GridPosition)
    .build(),
];

export const passportMissingInformationWidgets = [
  commonTextWidget({
    textContent: 'We’re missing some information!',
    cssConfig: {
      textAlignment: 'center',
      fontSize: 19,
      isTextBold: true,
      paddingLeft: 20,
      paddingRight: 20,
      marginLeft: 20,
      marginRight: 20,
    },
    gridPosition: {
      y: 6,
      x: 0,
      rows: 2,
      cols: 15,
    } as GridPosition,
  }),

  commonTextWidget({
    textContent: 'Please upload your Passport in order to generate a barcode for your trip.',
    cssConfig: {
      fontSize: 16,
      textAlignment: 'center',
      paddingLeft: 20,
      paddingRight: 20,
    },
    gridPosition: {
      x: 0,
      y: 9,
      rows: 3,
      cols: 15,
    } as GridPosition,
  }),

  commonQuickLink({
    cssConfig: {
      marginTop: 0,
      marginBottom: 0,
      fontSize: 14,
      verticalAlignment: 'center',
      disabledBgColor: 'var(--palette-color-3)',
      disabledTextColor: 'var(--palette-color-6)',
      borderRadiusTopLeft: 8,
      borderRadiusTopRight: 8,
      borderRadiusBottomLeft: 8,
      borderRadiusBottomRight: 8,
    },
    gridPosition: { y: 20, x: 1, rows: 2, cols: 13 } as GridPosition,
    text: 'Upload Passport',
    configDisplayName: 'Upload Passport',
    zone: APP_ZONES.REVENIR_ZONE,
    navigateToPageUrl: 'upload-passport',
  }),

  new BackButtonWidgetBuilder()
    .defaultBackButton()
    .configDisplayName('Back Navigation Button')
    .text('Cancel')
    .cssConfig({
      textAlign: 'center',
      verticalAlignment: 'center',
      isTextBold: true,
      fontSize: 16,
      textAlignment: 'center',
      color: 'var(--palette-color-0)',
      backGroundColor: 'var(--palette-color-6)',
      borderWidth: 2,
      borderColor: 'var(--palette-color-0)',
      borderRadiusTopLeft: 8,
      borderRadiusTopRight: 8,
      borderRadiusBottomLeft: 8,
      borderRadiusBottomRight: 8,
    })
    .gridPosition({
      x: 1,
      y: 23,
      rows: 2,
      cols: 13,
    } as GridPosition)
    .build(),
];
