import {
  BaseWidgetProperties,
  GridPosition,
  InputCssConfig,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import { BulkPaymentAccountsListingWidgetBuilder, LineWidgetBuilder } from '../../../widgets/example-builder';
import { inputSettings } from '../../../widgets/payments/payments.type';
import { bulkPaymentFooter, generateTextWidget } from '../common-widgets-utils';

export const accountSelectionWidgets = [
  generateTextWidget({
    text: 'Payout account',
    position: { x: 2, y: 0, rows: 2, cols: 15 } as GridPosition,
    displayName: 'Header Text',
    styles: {
      fontSize: 24,
      isTextBold: true,
      verticalAlignment: 'start',
      textAlignment: 'left',
      justifyContent: 'center',
      noAlignment: true,
      paddingLeft: 5,
      paddingTop: 30,
    },
    isRemovable: true,
  }),

  generateTextWidget({
    text: 'Please select the account you want to pay with.',
    position: { x: 2, y: 2, rows: 2, cols: 15 } as GridPosition,
    displayName: 'Information Context',
    styles: {
      justifyContent: 'center',
      verticalAlignment: 'start',
      textAlignment: 'left',
      fontSize: 20,
      paddingLeft: 5,
      textFont: 'var(--font-family)',
      color: 'var(--typography-color-1)',
    },
    isRemovable: true,
  }),

  new BulkPaymentAccountsListingWidgetBuilder()
    .inputSetting({
      ...inputSettings,
      css_config: {
        iconSize: 24,
        iconSizeUnit: 'px',
      } as InputCssConfig,
      isAllowed: true,
      isIconAllowed: true,
      label: 'Search for Accounts',
    })
    .cssConfig({
      paddingLeft: 5,
      fontSize: 14,
      textFont: 'var(--font-family)',
    })
    .gridPosition({ x: 2, y: 4, rows: 18 } as GridPosition)
    .build(),

  new LineWidgetBuilder()
    .configDisplayName('Line Widget')
    .gridPosition({ x: 1, y: 23, rows: 1, cols: 40 } as GridPosition)
    .attributes({
      widgetProperties: {
        color: 'var(--border-color-1)',
        'border-bottom': 'var(--border-color-1)',
        lineThickness: 1,
        lineWidth: '100',
        textContent: '',
        show: true,
        paddingTop: 20,
        paddingBottom: 20,
        contentAlignment: 'center',
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .build(),

  ...bulkPaymentFooter(24),
];
