import {
  ButtonSizeOptions,
  ButtonTypes,
  Constants,
  WidgetNames,
} from '@finxone-platform/shared/sys-config-types';

export const editBeneficaryNestedWidgets = {
  submitButton: {
    name: WidgetNames.BUTTON,
    widgetConfigDisplayName: 'Save Button Styling',
    attributes: {
      widgetProperties: {
        textContent: 'Save Changes',
        globalStyling: true,
        globalStylingClass: `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`,
      },
      elementsConfig: [],
      css_config: {
        ...Constants.defaultUiNestedButtonCssConfig,
      },
    },
  },
};

export const editBeneficaryIntlNestedWidgets = {
  previousButton: {
    name: WidgetNames.BUTTON,
    widgetConfigDisplayName: 'Previous Button Styling',
    attributes: {
      widgetProperties: {
        textContent: 'Previous',
        globalStyling: true,
        globalStylingClass: `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`,
      },
      elementsConfig: [],
      css_config: {
        ...Constants.defaultUiNestedButtonCssConfig,
        backGroundColor: 'var(--palette-color-2)',
        color: 'var(--palette-color-0)',
        borderWidth: 1,
        borderColor: 'var(--border-color-1)',
        borderStyle: 'solid',
        verticalAlignment: 'center',
        marginRight: 10,
        marginTop: 15,
      },
    },
  },

  nextButton: {
    name: WidgetNames.BUTTON,
    widgetConfigDisplayName: 'Next Button Styling',
    attributes: {
      widgetProperties: {
        textContent: 'Next',
        globalStyling: true,
        globalStylingClass: `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`,
      },
      elementsConfig: [],
      css_config: {
        ...Constants.defaultUiNestedButtonCssConfig,
        backGroundColor: 'var(--palette-color-2)',
        color: 'var(--palette-color-0)',
        borderWidth: 1,
        borderColor: 'var(--border-color-1)',
        borderStyle: 'solid',
        verticalAlignment: 'center',
        marginTop: 15,
        marginLeft: 10,
      },
    },
  },
};

export const addBeneFasterPaymentNestedWidgets = {
  submitButton: {
    name: WidgetNames.BUTTON,
    widgetConfigDisplayName: 'Submit Button Styling',
    attributes: {
      widgetProperties: {
        textContent: 'Proceed',
      },
      elementsConfig: [],
      css_config: {
        ...Constants.defaultUiNestedButtonCssConfig,
      },
    },
  },
};

export const intlBeneAddressNestedWidgets = {
  reviewDetailsButton: {
    name: WidgetNames.BUTTON,
    widgetConfigDisplayName: 'Review Details Button Styling',
    attributes: {
      widgetProperties: {
        textContent: 'Review Beneficiary Details',
      },
      elementsConfig: [],
      css_config: {
        ...Constants.defaultUiNestedButtonCssConfig,
      },
    },
  },
};

export const addBeneficiaryNestedWidget = {
  submitButton: {
    name: WidgetNames.BUTTON,
    widgetConfigDisplayName: 'Submit Button Styling',
    attributes: {
      widgetProperties: {
        textContent: 'Proceed',
        globalStylingClass: `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`,
        globalStyling: true,
      },
      elementsConfig: [],
      css_config: {
        ...Constants.defaultUiNestedButtonCssConfig,
      },
    },
  },
};
