import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { FormActionTypeEnum, SEPAPaymentRailTypeEnum } from '@finxone-platform/form-action';
import {
  APP_ZONES,
  BaseWidgetProperties,
  PaymentStatusScreen,
} from '@finxone-platform/shared/sys-config-types';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { map, take } from 'rxjs';
import { ClearFormDataActionWithId } from '../../../actions/form-submission.action';
import { CreatePayment, PaymentDeclined, PaymentSuccess } from '../../../actions/payment.action';
import { TransferMoneyRequest, paymentRailOptions } from '../../../services/account-service/account.type';
import { AccountState } from '../../../state/account.state';
import { FormActionState } from '../../../state/form-submision.state';
import { redirectToPage } from '../cta-button-actions.utils';

/**
 * Submits a domestic payment request using the provided injector to access
 * necessary services and state. It retrieves form data and the active account ID
 * from the store, dispatches a payment creation action, and listens for payment
 * success or decline actions. Upon receiving a payment status, it clears relevant
 * form data and redirects to the appropriate payment status screen.
 *
 * @param injector - The Angular injector used to access services like Store, Actions, and Router.
 */
export function submitDomesticPaymentRequest(injector: Injector): void {
  const store = injector.get(Store);
  const actions = injector.get(Actions);
  const router = injector.get(Router);

  let formStateData = store.selectSnapshot(FormActionState.getFormActionState).response?.formData;
  const activeAccountId = store.selectSnapshot(AccountState.getCurrentAccountId);
  const sepaFormState = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.SEPA_PAYMENT),
  )?.formData;
  const widgetProperties: BaseWidgetProperties = {
    textContent: '',
    zoneToNavigate: APP_ZONES.PAYMENT,
    urlToNavigate: ``,
  };
  formStateData = {
    ...formStateData,
    sourceAccountId: activeAccountId,
    ...(sepaFormState?.paymentRail && {
      scheme: paymentRailOptions[sepaFormState?.paymentRail as SEPAPaymentRailTypeEnum],
    }),
  };
  if (formStateData) {
    store.dispatch(new CreatePayment(formStateData as TransferMoneyRequest));

    actions
      .pipe(
        ofActionDispatched(PaymentSuccess),
        take(1),
        map(() => {
          store.dispatch(new ClearFormDataActionWithId(FormActionTypeEnum.SEPA_PAYMENT));
          store.dispatch(new ClearFormDataActionWithId(FormActionTypeEnum.SEPA_PAYMENT_RAIL_FLAG));
          widgetProperties['urlToNavigate'] = PaymentStatusScreen.PAYMENT_SUCCESS;
          redirectToPage(router, widgetProperties, injector);
        }),
      )
      .subscribe();

    actions
      .pipe(
        ofActionDispatched(PaymentDeclined),
        take(1),
        map(() => {
          store.dispatch(new ClearFormDataActionWithId(FormActionTypeEnum.SEPA_PAYMENT));
          store.dispatch(new ClearFormDataActionWithId(FormActionTypeEnum.SEPA_PAYMENT_RAIL_FLAG));
          widgetProperties['urlToNavigate'] = PaymentStatusScreen.PAYMENT_DECLINED;
          redirectToPage(router, widgetProperties, injector);
        }),
      )
      .subscribe();
  }
}
