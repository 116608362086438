import { APP_ZONES, GridPosition } from '@finxone-platform/shared/sys-config-types';
import { commonQuickLink, commonTextWidget } from '../../../common/common-widgets';

export const successArchivedTripWidgets = [
  commonTextWidget({
    textContent: `Success!`,
    cssConfig: {
      textFont: 'var(--font-family)',
      textAlignment: 'center',
      fontSize: 24,
      isTextBold: true,
      color: 'var(--palette-color-1)',
    },
    gridPosition: {
      y: 8,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 3,
      cols: 15,
    },
  }),

  commonTextWidget({
    textContent: `Your {{ revenirFormState.archivedTripDetails.trip_name }} Trips has been moved to Archives`,
    cssConfig: {
      textFont: 'var(--font-family)',
      fontSize: 16,
      color: 'var(--palette-color-1)',
      paddingLeft: 20,
      paddingRight: 20,
    },
    gridPosition: {
      x: 0,
      y: 11,
      rows: 3,
      cols: 15,
      resizeEnabled: true,
      dragEnabled: true,
    },
  }),

  commonQuickLink({
    text: 'Close',
    zone: APP_ZONES.REVENIR_ZONE,
    navigateToPageUrl: 'trips-home-page',
    cssConfig: {
      color: 'var(--palette-color-6)',
      backGroundColor: 'var(--palette-color-3)',
      fontSize: 16,
      marginTop: 0,
      marginRight: 20,
      marginBottom: 0,
      marginLeft: 20,
      paddingTop: 8,
      paddingRight: 12,
      paddingLeft: 8,
      paddingBottom: 12,
      borderRadiusTopLeft: 8,
      borderRadiusTopRight: 8,
      borderRadiusBottomLeft: 8,
      borderRadiusBottomRight: 8,
      textFont: 'var(--font-family)',
    },
    gridPosition: { y: 15, x: 0, rows: 2, cols: 15 } as GridPosition,
  }),
];
