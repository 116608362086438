import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { BulkPaymentMasterStatus, FormActionTypeEnum } from '@finxone-platform/form-action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import {
  APP_ZONES,
  BaseWidgetProperties,
  SystemRole,
  UiZoneWidgetAttributeConfig,
} from '@finxone-platform/shared/sys-config-types';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { delay, first, map, take } from 'rxjs';
import { ClearBulkPaymentList, GetBulkPaymentList } from '../../../actions/bulk-payment.action';
import {
  RemoveSelectedCSVFileFromBulkPaymentsFormState,
  UpdateFormDataActionWithId,
} from '../../../actions/form-submission.action';
import {
  ApproveOrRejectBulkPayment,
  BulkPaymentDeclined,
  BulkPaymentError,
  BulkPaymentSuccess,
  CreateBulkPayment,
} from '../../../actions/payment.action';
import { AccountService } from '../../../services/account-service/account-service.service';
import { BulkPaymentService } from '../../../services/bulk-payment-service/bulk-payment-service.service';
import { ConfigService } from '../../../services/config-service/config-service.service';
import { CtaButtonSignalService } from '../../../services/cta-button-signal-service/cta-button-signal.service';
import { AccountState } from '../../../state/account.state';
import { BulkPaymentListState } from '../../../state/bulk-payment.state';
import { FormActionState } from '../../../state/form-submision.state';
import { ProjectSettingsState } from '../../../state/project-settings.state';
import { ProfileState } from '../../../state/user-profile.state';
import { redirectToPage } from '../cta-button-actions.utils';
export const delayTime = 3000;
export function downloadCsvTemplate(alertHandlerService: AlertHandlerService) {
  const link = document.createElement('a');
  link.setAttribute('type', 'hidden');
  link.href = '/assets/template/bulk-payment-beneficiary-template.csv';
  link.download = 'bulk-payment-beneficiary-template.csv';
  document.body.appendChild(link);
  link.click();
  link.remove();
  alertHandlerService.showAlertFn('success', 'Template Download Successfully');
}

/**
 * Processes a CSV file for bulk payments by retrieving necessary services and data from the injector.
 * Validates the presence of a file ID before proceeding. If validation fails, an error alert is shown.
 * Clears the bulk payment list and redirects to the upload processing page.
 * Constructs a payload with file and account details, then attempts to upload the file.
 * On successful upload, updates the form state and redirects to the beneficiary review page.
 * On failure, redirects to the upload download page.
 *
 * @param injector - The Injector instance used to retrieve services and state.
 */
export function proceedCSVFile(injector: Injector) {
  const store = injector.get(Store);
  const accountService = injector.get(AccountService);
  const router = injector.get(Router);
  const alertHandlerService = injector.get(AlertHandlerService);
  const formActionData = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.BULK_PAYMENTS),
  );
  if (!formActionData?.formData?.myFiles?.[0]?.id) {
    alertHandlerService.showAlertFn('error', 'Not able to proceed without upload file!', 'File Not Added!');
    return;
  }
  const mockWidgetProperties = {
    textContent: '',
    zoneToNavigate: APP_ZONES.PAYMENT,
    urlToNavigate: 'bulk-payment-upload-processing',
  };

  store.dispatch(new ClearBulkPaymentList());

  redirectToPage(router, mockWidgetProperties, injector, true);

  const payload: uploadFileRequest = {
    fileId: formActionData?.formData.myFiles[0].id,
    fileName: formActionData?.formData.myFiles[0].filename,
    accountId: formActionData?.formData.selectedAccountId,
    inActiveBeneficiaryCheck: true,
    showInBeneficiaryList: false,
  };
  accountService
    .proceedUploadFile(payload)
    .pipe(delay(delayTime), take(1))
    .subscribe({
      next: (payload) => {
        store.dispatch(new RemoveSelectedCSVFileFromBulkPaymentsFormState());
        if (payload) {
          store.dispatch(
            new UpdateFormDataActionWithId(
              {
                masterFileId: payload.id,
              },
              FormActionTypeEnum.BULK_PAYMENTS,
            ),
          );
        }
        mockWidgetProperties['urlToNavigate'] = 'bulk-payment-beneficiary-review';
        redirectToPage(router, mockWidgetProperties, injector, true);
      },
      error: () => {
        mockWidgetProperties['urlToNavigate'] = 'bulk-payment-upload-download';
        redirectToPage(router, mockWidgetProperties, injector, true);
      },
    });
}

export type uploadFileRequest = {
  fileId: string;
  fileName: string;
  accountId: string;
  inActiveBeneficiaryCheck: boolean;
  showInBeneficiaryList: boolean;
};

export type UploadFileResponse = {
  id: string;
};

/**
 * Refreshes the bulk payment list by dispatching a new action to retrieve
 * the list of bulk payments. It selects the current form action data for
 * bulk payments from the store and uses the master file ID from the form
 * data to fetch the updated list.
 *
 * @param store - The NGXS store instance used to select state and dispatch actions.
 */
export function refreshBulkPayment(store: Store) {
  const formActionData = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.BULK_PAYMENTS),
  );
  store.dispatch(new GetBulkPaymentList(1, 10, formActionData?.formData.masterFileId));
}

/**
 * Navigates to the pre-payment summary page for bulk payments.
 *
 * This function retrieves the Angular Router instance from the provided
 * Injector and constructs a mock widget properties object with the
 * necessary navigation details. It then calls the `redirectToPage`
 * function to perform the navigation, replacing the current URL in
 * the browser's history.
 *
 * @param injector - The Angular Injector used to retrieve the Router instance.
 */
export function goToPrePayment(injector: Injector) {
  const router = injector.get(Router);
  const mockWidgetProperties: BaseWidgetProperties = {
    textContent: '',
    zoneToNavigate: APP_ZONES.PAYMENT,
    urlToNavigate: 'bulk-payment-pre-payment-summary',
  };
  redirectToPage(router, mockWidgetProperties, injector, true);
}

/**
 * Adds a beneficiary to a bulk payment process using the provided injector to access
 * necessary services and state. It retrieves form action data related to bulk payments
 * and custom questions, checks for the existence of a master file ID, and navigates
 * to appropriate pages based on the operation's success or failure. Alerts are shown
 * in case of errors or missing data.
 *
 * @param injector - The Angular Injector used to retrieve services and state.
 */
export function addBeneficiary(injector: Injector) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  const alertHandlerService = injector.get(AlertHandlerService);
  const accountService = injector.get(AccountService);
  const formActionData = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.BULK_PAYMENTS),
  );
  const formActionCustomData = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.CUSTOM_QUESTION),
  );
  const mockWidgetProperties: BaseWidgetProperties = {
    textContent: '',
    zoneToNavigate: APP_ZONES.PAYMENT,
    urlToNavigate: '',
  };
  if (!formActionData?.formData.masterFileId) {
    alertHandlerService.showAlertFn('error', 'File Id not exist');
    return;
  }
  mockWidgetProperties['urlToNavigate'] = 'bulk-payment-processing-add-beneficiary';
  redirectToPage(router, mockWidgetProperties, injector, true);
  const beneficiaryAddInList = !!formActionCustomData?.formData?.['beneficiary-show-in-beneficiary-list'];
  accountService
    .addBulkPaymentBeneficiary(formActionData?.formData.masterFileId, beneficiaryAddInList)
    .pipe(take(1), delay(delayTime))
    .subscribe({
      next: (payload) => {
        if (payload) {
          mockWidgetProperties['urlToNavigate'] = 'bulk-payment-review-error-handling';
          redirectToPage(router, mockWidgetProperties, injector, true);
        }
      },
      error: () => {
        mockWidgetProperties['urlToNavigate'] = 'bulk-payment-beneficiary-review';
        redirectToPage(router, mockWidgetProperties, injector, true);
      },
    });
}

/**
 * Initiates the bulk payment process by handling success and decline actions.
 *
 * This function listens for `BulkPaymentSuccess` and `BulkPaymentDeclined` actions
 * and redirects the user to the appropriate page based on the action dispatched.
 * It also checks if SMS authentication is enabled and redirects accordingly.
 *
 * @param widgetProperties - The properties of the widget initiating the bulk payment.
 * @param injector - The injector used to retrieve necessary services.
 */
export function proceedBulkPayment(widgetProperties: BaseWidgetProperties, injector: Injector) {
  const _actions = injector.get(Actions);
  const router = injector.get(Router);
  const mockWidgetProperties: BaseWidgetProperties = {
    textContent: '',
    zoneToNavigate: APP_ZONES.PAYMENT,
    urlToNavigate: 'bulk-payment-summary-elaborated-view',
  };
  _actions
    .pipe(
      ofActionDispatched(BulkPaymentSuccess),
      take(1),
      map(() => {
        redirectToPage(router, mockWidgetProperties, injector, true);
      }),
    )
    .subscribe();

  _actions
    .pipe(
      ofActionDispatched(BulkPaymentDeclined),
      take(1),
      map(() => {
        mockWidgetProperties['urlToNavigate'] = 'bulk-payment-pre-payment-summary';
        redirectToPage(router, mockWidgetProperties, injector, true);
      }),
    )
    .subscribe();
  redirectAsPerSmsAuthEnabled(widgetProperties, injector);
}

/**
 * Redirects the user based on the SMS authentication setting.
 *
 * This function checks if SMS authentication is enabled by retrieving the
 * project settings from the store. If enabled, it redirects the user to
 * the specified page using the `redirectToPage` function. If not enabled,
 * it modifies the `urlToNavigate` property of the `widgetProperties` to
 * 'bulk-payment-processing', submits a bulk payment API request, and then
 * redirects the user.
 *
 * @param widgetProperties - The properties of the widget, including navigation details.
 * @param injector - The injector used to retrieve necessary services.
 */
export function redirectAsPerSmsAuthEnabled(widgetProperties: BaseWidgetProperties, injector: Injector) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  const isSmsAuthEnabled = store.selectSnapshot(
    ProjectSettingsState.getProjectSettings,
  ).smsAuthenticationEnabled;
  if (isSmsAuthEnabled) {
    redirectToPage(router, widgetProperties, injector);
  } else {
    widgetProperties['urlToNavigate'] = 'bulk-payment-processing';
    submitBulkPaymentAPI(injector, isSmsAuthEnabled);
    redirectToPage(router, widgetProperties, injector);
  }
}

/**
 * Handles the approval process for a bulk payment based on widget properties.
 *
 * This function retrieves necessary services from the provided injector, such as
 * Store, Router, AlertHandlerService, and Actions. It listens for dispatched actions
 * related to bulk payment success or decline and navigates to the appropriate page
 * based on the action received. If SMS authentication is enabled, it redirects to
 * the current page; otherwise, it processes the bulk payment approval and navigates
 * accordingly.
 *
 * @param widgetProperties - The properties of the widget initiating the bulk payment.
 * @param injector - The injector used to retrieve necessary services.
 */
export function approveBulkPayment(widgetProperties: BaseWidgetProperties, injector: Injector) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  const alertHandlerService = injector.get(AlertHandlerService);
  const _actions = injector.get(Actions);
  const parsedWidgetProperties = JSON.parse(JSON.stringify(widgetProperties));
  _actions
    .pipe(
      ofActionDispatched(BulkPaymentSuccess),
      take(1),
      map(() => {
        parsedWidgetProperties['urlToNavigate'] = 'bulk-payment-summary-elaborated-view-approver-reject';
        redirectToPage(router, parsedWidgetProperties, injector, true);
      }),
    )
    .subscribe();

  _actions
    .pipe(
      ofActionDispatched(BulkPaymentDeclined),
      take(1),
      map(() => {
        parsedWidgetProperties['urlToNavigate'] = 'bulk-payment-pre-payment-summary';
        redirectToPage(router, parsedWidgetProperties, injector, true);
      }),
    )
    .subscribe();

  const isSmsAuthEnabled = store.selectSnapshot(
    ProjectSettingsState.getProjectSettings,
  ).smsAuthenticationEnabled;
  if (isSmsAuthEnabled) {
    redirectToPage(router, widgetProperties, injector);
  } else {
    parsedWidgetProperties['urlToNavigate'] = 'bulk-payment-processing';
    submitApproveBulkPaymentAPI(store, alertHandlerService);
    redirectToPage(router, parsedWidgetProperties, injector);
  }
}

/**
 * Submits an approval request for a bulk payment via the API.
 *
 * This function retrieves the master file ID and record ID from the store's
 * snapshot of the bulk payments form action state. If the master ID is found,
 * it dispatches an action to approve the bulk payment. If not, it triggers an
 * error alert and logs an error message.
 *
 * @param store - The NGXS store instance for state management.
 * @param alertHandlerService - Service for handling alert notifications.
 */
export function submitApproveBulkPaymentAPI(store: Store, alertHandlerService: AlertHandlerService) {
  const masterId = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.BULK_PAYMENTS),
  )?.formData.masterFileId as string;
  const recordId = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.BULK_PAYMENTS),
  )?.formData.recordId as string;
  if (masterId) {
    store.dispatch(new ApproveOrRejectBulkPayment(masterId, recordId, 'approved'));
  } else {
    alertHandlerService.showAlertFn('error', 'Bulk payment request data not available, please try again');
    console.error('Bulk payment request data not available, please try again');
  }
}

/**
 * Submits a bulk payment request using the provided injector to access
 * necessary services. Retrieves the master file ID from the store and
 * dispatches a CreateBulkPayment action if the ID is available. If SMS
 * authentication is enabled, redirects to the bulk payment processing page.
 * Displays an error alert if the payment request data is unavailable.
 *
 * @param injector - The Injector instance to retrieve services.
 * @param isSmsAuthEnabled - Optional flag to enable SMS authentication, defaults to true.
 */
export function submitBulkPaymentAPI(injector: Injector, isSmsAuthEnabled = true) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  const alertHandlerService = injector.get(AlertHandlerService);
  const masterId = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.BULK_PAYMENTS),
  )?.formData?.masterFileId as string;
  const mockWidgetProperties = {
    textContent: '',
    zoneToNavigate: APP_ZONES.PAYMENT,
    urlToNavigate: 'bulk-payment-processing',
  };
  if (masterId) {
    store.dispatch(new CreateBulkPayment(masterId));
    if (isSmsAuthEnabled) {
      redirectToPage(router, mockWidgetProperties, injector);
    }
  } else {
    alertHandlerService.showAlertFn('error', 'Payment request data not available, please try again');
    console.error('payment request data not available, please try again');
  }
}

/**
 * Cancels all bulk payments associated with the current form action state.
 *
 * @param injector - The Angular Injector used to retrieve necessary services.
 *
 * This function retrieves the current form action state for bulk payments and
 * checks if a master file ID exists. If not, it displays an error alert. If the
 * ID exists, it navigates to a specified page and attempts to cancel all payments
 * using the AccountService. Upon success, it shows a success alert and refreshes
 * the bulk payment list. If an error occurs, it displays an error alert and
 * redirects to the same page.
 */
export function cancelAllPayment(injector: Injector) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  const alertHandlerService = injector.get(AlertHandlerService);
  const accountService = injector.get(AccountService);
  const formActionData = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.BULK_PAYMENTS),
  );
  const mockWidgetProperties = {
    textContent: '',
    zoneToNavigate: APP_ZONES.PAYMENT,
    urlToNavigate: 'bulk-payment-summary-elaborated-view',
  };
  if (!formActionData?.formData.masterFileId) {
    alertHandlerService.showAlertFn('error', 'File Id not exist');
    return;
  }
  redirectToPage(router, mockWidgetProperties, injector, true);
  accountService
    .cancelAllPayment(formActionData?.formData.masterFileId)
    .pipe(delay(delayTime), take(1))
    .subscribe({
      next: (payload) => {
        alertHandlerService.showAlertFn('success', 'Payment cancel successfully');
        if (payload) refreshBulkPayment(store);
      },
      error: () => {
        mockWidgetProperties['urlToNavigate'] = 'bulk-payment-summary-elaborated-view';
        redirectToPage(router, mockWidgetProperties, injector, true);
      },
    });
}

/**
 * Handles the rejection of all bulk payments by dispatching an action to update the payment status
 * and navigating to a specific page. It retrieves necessary services and data using an Injector,
 * checks for the existence of a master file ID, and shows alerts based on the success or failure
 * of the operation. If the master file ID is missing, an error alert is displayed.
 *
 * @param injector - The Injector used to retrieve necessary services and store.
 */
export function rejectAllPayment(injector: Injector) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  const alertHandlerService = injector.get(AlertHandlerService);
  const _actions = injector.get(Actions);
  const formActionData = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.BULK_PAYMENTS),
  );
  const mockWidgetProperties = {
    textContent: '',
    zoneToNavigate: APP_ZONES.PAYMENT,
    urlToNavigate: '',
  };
  if (!formActionData?.formData.masterFileId) {
    alertHandlerService.showAlertFn('error', 'File Id not exist');
    return;
  }
  const masterId = formActionData?.formData.masterFileId as string;
  const recordId = formActionData?.formData.recordId as string;
  mockWidgetProperties['urlToNavigate'] = 'bulk-payment-summary-elaborated-view-approver-reject';
  redirectToPage(router, mockWidgetProperties, injector, true);
  _actions
    .pipe(
      ofActionDispatched(BulkPaymentDeclined),
      take(1),
      map(() => {
        alertHandlerService.showAlertFn('success', 'Payment reject successfully');
        refreshBulkPayment(store);
      }),
    )
    .subscribe();

  _actions
    .pipe(
      ofActionDispatched(BulkPaymentError),
      take(1),
      map(() => {
        mockWidgetProperties['urlToNavigate'] = 'bulk-payment-summary-elaborated-view-approver-reject';
        redirectToPage(router, mockWidgetProperties, injector, true);
      }),
    )
    .subscribe();
  if (masterId) {
    store.dispatch(new ApproveOrRejectBulkPayment(masterId, recordId, 'rejected'));
  } else {
    alertHandlerService.showAlertFn('error', 'Bulk payment request data not available, please try again');
    console.error('Bulk payment request data not available, please try again');
  }
}

/**
 * Initiates a bulk payment request and handles navigation and alerts based on the request outcome.
 *
 * @param injector - The Injector instance used to retrieve necessary services.
 * @param widgetProperties - The properties of the widget, including navigation details.
 *
 * The function retrieves necessary services using the injector, including the store, router,
 * alert handler, and bulk payment service. It selects the form action data for bulk payments
 * and updates the widget properties for navigation. It then requests a bulk payment using the
 * master file ID from the form action data. Upon successful request, it navigates to the bulk
 * payment history page and shows a success alert. If an error occurs, it shows an error alert.
 */
function requestPayment(injector: Injector, widgetProperties: BaseWidgetProperties) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  const alertHandlerService = injector.get(AlertHandlerService);
  const bulkPaymentService = injector.get(BulkPaymentService);
  const formActionData = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.BULK_PAYMENTS),
  );
  const parsedWidgetProperties = JSON.parse(JSON.stringify(widgetProperties));
  parsedWidgetProperties['urlToNavigate'] = 'bulk-payment-processing';
  redirectToPage(router, parsedWidgetProperties, injector, true);

  bulkPaymentService
    .requestBulkPayment(formActionData?.formData.masterFileId)
    .pipe(delay(delayTime), take(1))
    .subscribe({
      next: () => {
        parsedWidgetProperties['urlToNavigate'] = 'bulk-payment-history';
        alertHandlerService.showAlertFn('success', 'Your bulk payment request has been sent for approval');
        redirectToPage(router, parsedWidgetProperties, injector, true);
      },
    });
}

/**
 * Handles the payment process based on the user's role and system configuration.
 *
 * This function retrieves the active user's role and system configuration to determine
 * the appropriate payment action. It checks if the payment request feature is enabled
 * and the user's role within the organization to decide whether to proceed with a bulk
 * payment or request a payment. If the user lacks the necessary permissions, an error
 * alert is displayed.
 *
 * @param widgetProperties - The properties of the widget initiating the payment.
 * @param injector - The Angular injector used to retrieve services and dependencies.
 */
export function makePayment(widgetProperties: BaseWidgetProperties, injector: Injector) {
  const store = injector.get(Store);
  const alertHandlerService = injector.get(AlertHandlerService);
  const configService = injector.get(ConfigService);
  let activeUserType = '';
  let activeUser: SystemRole;
  const profile = store.selectSnapshot(ProfileState.getProfile);
  configService
    .getRoles()
    .pipe(
      first(),
      map((roles) => {
        Object.entries(roles).forEach(([roleKey, value]) => {
          if (roleKey === profile.activeRole) {
            activeUser = value;
          }
        });
        if (activeUser?.userType) {
          activeUserType = activeUser.userType;
        }
      }),
    )
    .subscribe();
  //get system config from form state
  const systemConfig = store.selectSnapshot(AccountState.getPaymentGatewayConfig);
  const activeOrg = profile.orgList?.find((org) => org.id === profile.activeOrganisationId);
  if (!systemConfig?.paymentRequest?.enabled && activeUserType !== 'org') {
    proceedBulkPayment(widgetProperties, injector);
    return;
  }

  const isPaymentInitiator = !!activeOrg?.roles.find((value) =>
    systemConfig?.paymentRequest?.paymentInitiatorRoles.includes(value),
  );
  const isPaymentRequestor = !!activeOrg?.roles.find((value) =>
    systemConfig?.paymentRequest?.paymentRequestorRoles.includes(value),
  );
  const isPaymentApprover = !!activeOrg?.roles.find((value) =>
    systemConfig?.paymentRequest?.paymentApproverRoles.includes(value),
  );
  if (isPaymentInitiator) {
    proceedBulkPayment(widgetProperties, injector);
  } else if (isPaymentRequestor || isPaymentApprover) {
    // call request API
    requestPayment(injector, widgetProperties);
  } else {
    alertHandlerService.showAlertFn(
      'error',
      "You don't have permission to make a payment or to request for a payment",
    );
  }
}

/**
 * Handles the logic for enabling or disabling the bulk payment approver/reject button
 * based on the user's role and the current status of the bulk payment.
 *
 * @param store - The NGXS store instance for accessing application state.
 * @param ctaButtonSignalService - Service to manage the state of CTA button signals.
 * @param attri - Configuration attributes for the UI zone widget.
 * @param configService - Service to retrieve configuration settings, including user roles.
 *
 * @returns A subscription to the bulk payment list state, which updates the button's
 *          disabled state based on the user's role and payment status.
 */
export function bulkPaymentApproverRejectButton(
  store: Store,
  ctaButtonSignalService: CtaButtonSignalService,
  attri: UiZoneWidgetAttributeConfig,
  configService: ConfigService,
) {
  let activeUserType = '';
  let activeUser: SystemRole;
  const profile = store.selectSnapshot(ProfileState.getProfile);
  configService
    .getRoles()
    .pipe(
      first(),
      map((roles) => {
        Object.entries(roles).forEach(([roleKey, value]) => {
          if (roleKey === profile.activeRole) {
            activeUser = value;
          }
        });
        if (activeUser?.userType) {
          activeUserType = activeUser.userType;
        }
      }),
    )
    .subscribe();
  const systemConfig = store.selectSnapshot(AccountState.getPaymentGatewayConfig);
  const activeOrg = profile.orgList?.find((org) => org.id === profile.activeOrganisationId);

  const data = store.select(BulkPaymentListState.getBulkPaymentList);
  return data.subscribe({
    next: (res) => {
      const masterBulkPaymentStatus = res?.meta?.bulkPaymentsSummary?.masterStatus;
      let isDisableButton = false;

      if (systemConfig?.paymentRequest?.enabled && activeUserType === 'org') {
        const isPaymentApprover = !!activeOrg?.roles.find((value) =>
          systemConfig?.paymentRequest?.paymentApproverRoles.includes(value),
        );
        if (masterBulkPaymentStatus == BulkPaymentMasterStatus.PENDING_APPROVAL && isPaymentApprover) {
          isDisableButton = true;
        }
        const key = attri?.['buttonActionType'] as string;
        ctaButtonSignalService.setSignal({
          [key]: isDisableButton,
        });
      }
    },
  });
}

/**
 * Determines the visibility status of the approve/reject button for bulk payments
 * based on the user's role and the current payment gateway configuration.
 *
 * @param store - The application state store.
 * @param ctaButtonSignalService - Service to manage CTA button signals.
 * @param attri - Configuration attributes for the UI widget.
 * @param configService - Service to retrieve role configurations.
 */
export function bulkPaymentApproveRejectButtonStatus(
  store: Store,
  ctaButtonSignalService: CtaButtonSignalService,
  attri: UiZoneWidgetAttributeConfig,
  configService: ConfigService,
) {
  let activeUserType = '';
  let activeUser: SystemRole;
  const profile = store.selectSnapshot(ProfileState.getProfile);
  configService
    .getRoles()
    .pipe(
      first(),
      map((roles) => {
        Object.entries(roles).forEach(([roleKey, value]) => {
          if (roleKey === profile.activeRole) {
            activeUser = value;
          }
        });
        if (activeUser?.userType) {
          activeUserType = activeUser.userType;
        }
      }),
    )
    .subscribe();
  //get system config from form state
  const systemConfig = store.selectSnapshot(AccountState.getPaymentGatewayConfig);
  const activeOrg = profile.orgList?.find((org) => org.id === profile.activeOrganisationId);
  if (systemConfig?.paymentRequest?.enabled && activeUserType === 'org') {
    const isPaymentInitiator = !!activeOrg?.roles.find((value) =>
      systemConfig?.paymentRequest?.paymentInitiatorRoles.includes(value),
    );
    const isPaymentRequestor = !!activeOrg?.roles.find((value) =>
      systemConfig?.paymentRequest?.paymentRequestorRoles.includes(value),
    );
    const isPaymentApprover = !!activeOrg?.roles.find((value) =>
      systemConfig?.paymentRequest?.paymentApproverRoles.includes(value),
    );
    const data = store.select(BulkPaymentListState.getBulkPaymentList);
    data.subscribe({
      next: (res) => {
        const masterStatus = res?.meta?.bulkPaymentsSummary?.masterStatus;
        let isHideButton = false;
        if (isPaymentApprover) {
          if (
            masterStatus &&
            [
              BulkPaymentMasterStatus.APPROVED,
              BulkPaymentMasterStatus.CANCELLED,
              BulkPaymentMasterStatus.REJECTED,
              BulkPaymentMasterStatus.COMPLETED,
              BulkPaymentMasterStatus.AUTHENTICATED,
            ].includes(masterStatus)
          ) {
            isHideButton = true;
          }
        } else if (isPaymentRequestor || isPaymentInitiator) {
          isHideButton = true;
        }
        const key = attri?.['buttonActionType'] as string;
        ctaButtonSignalService.setHideButtonSignal({
          [key]: isHideButton,
        });
      },
    });
  }
}
