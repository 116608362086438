import { SEPAPaymentRailTypeEnum } from '@finxone-platform/form-action';
import {
  ACCOUNTTYPE,
  AllTransactionType,
  CardInfoType,
  ExchangeCurrencyTransactionsResponse,
  ExchangeTransactionResponse,
  GridPosition,
  NotificationPayload,
} from '@finxone-platform/shared/sys-config-types';

export enum CARDSTATUS {
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
  SUSPENDED = 'SUSPENDED',
  CREATED = 'CREATED',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',
}

export type TransactionInfoType = {
  currency: string;
  amount: number;
  description: string;
  transactionDate: string;
  postedDate: string;
  transactionType: string;
  status: string;
  accountBalance: number;
};

export type TransactionDetailResponse = {
  sender: {
    accountNumber: string;
    sortCode?: string;
    name: string;
  };
  receiver: {
    accountNumber: string;
    sortCode?: string;
    name: string;
  };
  id: string;
  amount: number;
  dateOfTransaction: string;
  reference?: string;
  currency?: string;
};

export type NotificationDailyBalance = {
  dateTime: string;
  currency: string;
  amount: number;
};

export type NotificationModel = {
  notificationId: string;
  novuId: string;
  url: string;
  seen: boolean;
  content: string;
  createdAt: string;
  payload: NotificationPayload;
};

export type TransactionStateType = {
  isLoading: boolean;
  accounts: {
    [accountId: string]: {
      [txType in AllTransactionType]: {
        result: TransactionInfoType[] | ExchangeCurrencyTransactionsResponse[];
        totalPages: number;
        limit: number;
        page: number;
      };
    };
  };
  currentExchangeTransactionDetail: ExchangeTransactionResponse;
  currentTransactionId: string;
  currentTransactionType: string;
  currentTransactionDetails: TransactionDetailResponse;
};

export type AccountDebitCreditSummary = {
  total_credits: number;
  total_debits: number;
  last_updated: string;
};

export type AccountDebitCreditUpdateSummary = {
  credit_total: number;
  debit_total: number;
  last_updated_account_balance: string;
  last_updated: string;
};

export type TransferMoneyRequest = {
  sourceAccountId: string;
  bid: string;
  amount: number;
  paymentReference: string;
  transferReasonId: string;
  review?: boolean;
  paymentRailOption?: SEPAPaymentRailTypeEnum;
  // allows to override auto picked scheme in the api call if neeed
  scheme?:
    | 'swift'
    | 'local' // currencycloud specific
    | 'sepa'
    | 'sepa-instant'
    | 'sepa-target-2'
    | 'chaps'
    | 'bacs'
    | 'faster-payments'
    | 'ach'
    | 'wire'
    | 'eft';
};

export type TransferMoneyResponse = {
  id: string;
  amount?: number;
  currency?: string;
  feeAmount?: number;
  totalAmount?: number;
  receiverName?: string;
  receiverAccountNumber?: string;
  senderName?: string;
  senderAccountNumber?: string;
  paymentReference?: string;
  transactionNumber?: string;
};

export type PaymentRequestorRequest = {
  sourceAccountId: string;
  beneficiaryId: string;
  amount: number;
  currency: string;
  paymentReference: string;
  transferReasonId: string;
  transferReasonIdText?: string;
  paymentRailOption?: SEPAPaymentRailTypeEnum;
  scheme?:
    | 'swift'
    | 'local' // currencycloud specific
    | 'sepa'
    | 'sepa-instant'
    | 'sepa-target-2'
    | 'chaps'
    | 'bacs'
    | 'faster-payments'
    | 'ach'
    | 'wire'
    | 'eft';
};

export type PaymentRequestorResponse = {
  id: string;
};

export type PaymentRequestUpdateRequest = {
  id: string;
  approvedStatus: string;
};
export type TransferMoneyInternationalResponse = {
  amount: number;
  currency: string;
  feeAmount: number;
  totalAmount: number;
  receiverName: string;
  receiverAccountNumber: string;
  senderName: string;
  senderAccountNumber: string;
};
export type updateTransactionRequest = {
  accountId: string;
};

export type BeneficiaryDestinationIdentifier = {
  accountNumber?: string;
  sortCode?: string;
  country: string;
  currency: string;
  iban?: string;
  bic?: string;
  aba?: string;
  rtnCanada?: string;
  clabe?: string;
  bsbCode?: string;
  ifsc?: string;
  cnaps?: string;
  id?: string;
  bankId?: string;
};

export type GetBeneficiaryResponse = {
  id: string;
  name: string;
  accountType: ACCOUNTTYPE;
  updatedDate?: string;
  createdDate?: string;
  deletedDate?: string;
  destinationIdentifier: BeneficiaryDestinationIdentifier;
  address?: BeneficiaryIdentifierAddress;
};

export type GetTotalWealthResponse = {
  totalWealth: string;
};

export type BeneficiaryIdentifierAddress = {
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  addressLine4?: string;
  city: string;
  state?: string;
  country: string;
  postCode?: string;
};

export type Payee = {
  name: string;
  accountNumber: string;
  sortCode: string;
  accountType: ACCOUNTTYPE;
  srd?: string;
};

export type CheckPayeeResponse = {
  name: string;
  matchStatus: true;
  matchStatusCode: string;
};

export type AddBeneficiaryRequest = {
  name: string;
  accountType: ACCOUNTTYPE;
  accountId?: string;
  destinationIdentifier: BeneficiaryDestinationIdentifier;
  address?: BeneficiaryIdentifierAddress;
};
export interface UpdateBeneficiaryRequest extends AddBeneficiaryRequest {
  id?: string;
  bankId?: string;
}
export type AddBeneficiaryResponse = {
  id: string;
};

export type Transfer = {
  beneficiary: GetBeneficiaryResponse;
  transactionDate: Date;
  transferRequest: TransferMoneyRequest;
};

export type LayoutPositionUpdates = {
  layouts: WidgetLayoutPosition[];
};

export type WidgetLayoutPosition = {
  uniqueId: number | string;
  id: string;
  position: number;
  gridPosition?: GridPosition;
};

export type OutboundPaymentNotification = {
  accountId: string;
  amount: number;
  paymentId: string;
  status: string;
};

export type DailyBalanceValue = {
  date: string;
  balance: number;
};

export type AddAccountRequest = {
  userId: string;
};
export type AddAccountResponse = {
  id: string;
};

export type GetCardRequest = {
  userId: string;
  accountId: string;
};
export type GetCardResponse = {
  id: string;
  result: CardInfoType[];
};
export type TransferReason = {
  id: string;
  description: string;
};

export type BulkPaymentHistoryResponse = {
  page: number;
  limit: number;
  result: BulkPaymentHistoryResult[];
  totalPages: number;
  totalItems: number;
};

export type BulkPaymentHistoryResult = {
  InactiveBeneficiaryCheck: boolean;
  accountCurrency: string;
  accountId: string;
  accountName: string;
  created_at: string;
  executed_at: string;
  fileId: string;
  fileName: string;
  id: string;
  status: string;
  totalAmount: string;
  totalDuplicatePayments: number;
  totalErrors: number;
  totalFailedPayments: number;
  totalPayments: number;
  totalSchedulePayments: number;
  totalSuccessPayments: number;
  totalUniqueBeneficiaries: number;
  updated_at: string;
  userId: string;
};

export const paymentRailOptions: Record<SEPAPaymentRailTypeEnum, 'sepa' | 'sepa-instant' | 'sepa-target-2'> =
  {
    [SEPAPaymentRailTypeEnum.SEPA_CREDIT_TRANSFER]: 'sepa',
    [SEPAPaymentRailTypeEnum.SEPA_CREDIT_TRANSFER_INSTANT]: 'sepa-instant',
    [SEPAPaymentRailTypeEnum.TARGET2]: 'sepa-target-2',
  };
