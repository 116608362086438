import { revertFeeTemplate } from '@finxone-platform/shared/default-templates';
import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonActionType,
  GridPosition,
  WorkflowWidgetConfig,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import {
  FormSubmissionButtonWidgetBuilder,
  ImageWidgetBuilder,
} from '../../../defaults/widgets/example-builder';
import { getBackButtonWidget } from '../../../defaults/zones/manage-users-zone/common-utils';
import { generateTextWidget } from '../../../defaults/zones/payments/common-widgets-utils';
export function generateConfirmationRevertFeePageWidgets(): WorkflowWidgetConfig[] {
  return [
    new ImageWidgetBuilder()
      .configDisplayName('Image Widget: Error Icon')
      .cssConfig({
        selectedobjectfit: 'contain',
      })
      .gridPosition({ y: 5, x: 21, rows: 2, cols: 2 } as GridPosition)
      .attributes({
        widgetProperties: {
          textContent: '',
          imageAssetUrl: 'assets/icon-library/material/history.svg',
        } as BaseWidgetProperties,
      } as WorkflowWidgetConfigurationAttributes)
      .build(),

    generateTextWidget({
      text: 'Are you sure you want to revert {{feeManagement.selectedFeeHistoryItem.feeName}}?',
      position: { x: 11, y: 7, rows: 1, cols: 23 } as GridPosition,
      displayName: 'Header Text',
      styles: {
        fontSize: 24,
        isTextBold: true,
        verticalAlignment: 'start',
        textAlignment: 'center',
        noAlignment: true,
        color: 'var(--palette-color-0)',
        textFont: 'var(--font-family)',
        paddingTop: 10,
      },
      isRemovable: true,
    }),

    generateTextWidget({
      text: 'Upon confirming the action, the fee for the client will be reversed, resulting in a credit transaction for the client.',
      position: { x: 9, y: 8, rows: 1, cols: 28 } as GridPosition,
      displayName: 'Information Content',
      styles: {
        verticalAlignment: 'start',
        textAlignment: 'center',
        fontSize: '16',
        paddingTop: 5,
        lineHeight: '24',
        textFont: 'var(--font-family)',
        color: 'var(--typography-color-1)',
      },
      isRemovable: true,
    }),

    generateTextWidget({
      text: revertFeeTemplate,
      position: { x: 6, y: 9, rows: 6, cols: 33 } as GridPosition,
      styles: {
        backGroundColor: 'var(--palette-color-5)',
        paddingTop: '30',
        paddingLeft: '30',
        paddingRight: '30',
        display: 'flex',
        verticalAlignment: 'start',
        textAlignment: 'left',
        borderRadiusTopLeft: '5',
        borderRadiusTopRight: '5',
        borderRadiusBottomLeft: '5',
        borderRadiusBottomRight: '5',
        textFont: 'var(--font-family)',
      },
      displayName: 'Fee Template',
      isRemovable: false,
    }),

    getBackButtonWidget({
      position: {
        y: 16,
        x: 18,
        rows: 1,
        cols: 4,
      } as GridPosition,
      styles: {
        color: 'var(--palette-color-3)',
        backGroundColor: 'var(--palette-color-6)',
        borderWidth: 1,
        borderColor: 'var(--palette-color-3)',
        fontSize: 14,
        textAlign: 'center',
        verticalAlignment: 'center',
      },
    }),

    new FormSubmissionButtonWidgetBuilder()
      .configDisplayName('Button: Revert')
      .attributes({
        widgetProperties: {
          textContent: 'Revert',
          zoneToNavigate: APP_ZONES.FEE_MANAGEMENT,
          urlToNavigate: 'clients-fee-history',
          buttonActionType: ButtonActionType.REVERT_FEE,
        } as BaseWidgetProperties,
      } as WorkflowWidgetConfigurationAttributes)
      .cssConfig({
        backGroundColor: 'var(--palette-color-3)',
        color: 'var(--palette-color-6)',
        marginTop: 0,
        marginBottom: 0,
        disabledTextColor: 'var(--palette-color-6)',
        disabledBgColor: 'var(--palette-color-3)',
      })
      .isRemovable(false)
      .gridPosition({
        y: 16,
        x: 23,
        rows: 1,
        cols: 4,
      } as GridPosition)
      .build(),
    generateTextWidget({
      text: `<div>Need help with fees not being charged? <a class="text-underline">Click here</a> to learn more about common issues and troubleshooting steps. For further assistance, email our support team at support@example.com.</div>`,
      position: { x: 8, y: 18, rows: 2, cols: 28 } as GridPosition,
      displayName: 'Information Content',
      styles: {
        verticalAlignment: 'start',
        textAlignment: 'center',
        fontSize: '16',
        paddingTop: 5,
        lineHeight: '24',
        textFont: 'var(--font-family)',
        color: 'var(--typography-color-1)',
      },
      isRemovable: true,
    }),
  ];
}
