import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonActionType,
  GridPosition,
  SINK_TYPE,
  WidgetNames,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import { CustomQuestionWidgetBuilder, ImageWidgetBuilder } from '../../../../widgets';
import { commonBackButtonQuickLink, commonCTAButton, commonTextWidget } from '../../../common/common-widgets';

export const barcodeDeclarationWidgets = [
  commonBackButtonQuickLink({
    cssConfig: {
      iconSize: 35,
      iconUnit: 'px',
    },
    gridPosition: {
      y: 0,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 2,
    },
  }),
  commonTextWidget({
    textContent: 'Scan Barcode',
    configDisplayName: 'Scan Barcode',
    cssConfig: {
      textAlignment: 'center',
      fontSize: 24,
      isTextBold: true,
      textFont: 'var(--font-family)',
    },
    gridPosition: {
      y: 2,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 1,
      cols: 15,
    },
  }),
  commonTextWidget({
    textContent: 'Declaration',
    configDisplayName: 'Declaration',
    cssConfig: {
      textAlignment: 'center',
      fontSize: 16,
      isTextBold: true,
      textFont: 'var(--font-family)',
    },
    gridPosition: {
      y: 4,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 1,
      cols: 15,
    },
  }),
  new ImageWidgetBuilder()
    .configDisplayName('Image Widget: Success Icon')
    .cssConfig({
      selectedobjectfit: 'contain',
    })
    .attributes({
      widgetProperties: {
        imageAssetUrl: 'assets/images/scanning-declaration.svg',
        textContent: '',
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .gridPosition({
      y: 6,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 5,
      cols: 15,
    } as GridPosition)
    .build(),
  commonTextWidget({
    textContent: 'I confirm that...',
    configDisplayName: 'I confirm that...',
    cssConfig: {
      textAlignment: 'left',
      fontSize: 20,
      isTextBold: true,
      textFont: 'var(--font-family)',
    },
    gridPosition: {
      y: 12,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 1,
      cols: 15,
    },
  }),
  commonTextWidget({
    textContent: `<span class="mt-2 mr-2">{{{ imageFromImageName 'icon-library' 'barcode-declaration-icon' '20px' }}}</span>I am leaving the EU`,
    configDisplayName: 'I am leaving the EU',
    cssConfig: {
      textAlignment: 'left',
      fontSize: 16,
      textFont: 'var(--font-family)',
    },
    gridPosition: {
      y: 14,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 15,
    },
  }),
  commonTextWidget({
    textContent: `<span class="mt-2 mr-2">{{{ imageFromImageName 'icon-library' 'barcode-declaration-icon' '20px' }}}</span>All the goods on this reclaim are leaving the EU`,
    configDisplayName: 'All the goods',
    cssConfig: {
      textAlignment: 'left',
      fontSize: 16,
      textFont: 'var(--font-family)',
    },
    gridPosition: {
      y: 17,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 15,
    },
  }),
  commonTextWidget({
    textContent: `<span class="mt-2 mr-2">{{{ imageFromImageName 'icon-library' 'barcode-declaration-icon' '20px' }}}</span>All items are for personal use`,
    configDisplayName: 'All items are for personal use',
    cssConfig: {
      textAlignment: 'left',
      fontSize: 16,
      textFont: 'var(--font-family)',
    },
    gridPosition: {
      y: 20,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 15,
    },
  }),

  new CustomQuestionWidgetBuilder()
    .gridPosition({ x: 0, y: 23, rows: 3, cols: 15 } as GridPosition)
    .configDisplayName(`Custom Question Widget: Checkbox`)
    .cssConfig({ fontSize: 16, borderWidth: '0', textAlignment: 'left', textFont: 'var(--font-family)' })
    .attributes({
      customDataQuestionData: {
        data_type: 'stringArray',
        entity: 'revenir_zone',
        sink: SINK_TYPE.METADATA,
        isUpdateToHasura: false,
      },
      label: ``,
      inputType: 'checkbox',
      questionUniqueId: 'barcode scanning declaration confirmation',
      elementsConfig: [
        {
          field_name: WidgetNames.CUSTOM_QUESTION_WIDGET,
          label: 'checkbox',
          type: 'checkbox',
          section: 'questionwidget',
          show: false,
          options: [],
          uniqueId: 'barcode scanning declaration confirmation',
        },
      ],
      validationRules: [],
      questionProperties: {
        name: 'barcode scanning declaration confirmation',
        label: 'label',
        type: 'checkbox',
        options: [
          {
            text: 'I confirm that I am legally liable if I am not being truthful in any way. ',
            value: 'barcode_scanning_declaration_confirmation',
            suboption: [],
          },
        ],
        preSelectSubAnswer: 'false',
      },
      widgetProperties: { showHeader: false, showSubHeader: false },
    } as unknown as WorkflowWidgetConfigurationAttributes)
    .build(),

  commonCTAButton({
    zoneToNavigate: APP_ZONES.REVENIR_ZONE,
    urlToNavigate: 'info-page',
    textContent: 'I Confirm',
    buttonActionType: ButtonActionType.REVENIR_TRIP_BARCODE_CONFIRMATION_DECLARATION,
  }),
];
