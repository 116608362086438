import {
  APP_ZONES,
  ButtonSizeOptions,
  ButtonTypes,
  Constants,
  GridPosition,
  WorkflowWidgetConfig,
} from '@finxone-platform/shared/sys-config-types';
import {
  BackButtonWidgetBuilder,
  MultiIconQuickLinkWidgetBuilder,
  QuickLinkWidgetBuilder,
  TextWidgetBuilder,
} from '../../../../widgets/example-builder';

export const defaultPdfCssConfig = {
  ...Constants.defaultBaseCssConfig,
  paddingTop: 25,
  paddingLeft: 20,
  paddingRight: 20,
  paddingBottom: 20,
  borderRadiusTopLeft: 5,
  borderRadiusBottomLeft: 5,
  borderRadiusTopRight: 5,
  borderRadiusBottomRight: 5,
  borderColor: 'var(--border-color-1)',
  backGroundColor: 'var(--palette-color-6)',
  justifyContent: 'left',
  borderWidth: 1,
};
export function createTextWidget(override: any = {}): WorkflowWidgetConfig {
  return new TextWidgetBuilder()
    .text(override?.text)
    .configDisplayName(override?.configDisplayName)
    .gridPosition(
      override?.gridPosition ? (override.gridPosition as GridPosition) : ({ rows: 2 } as GridPosition),
    )
    .cssConfig({
      isTextBold: true,
      ...override?.css_config,
    })
    .isRemovable(true)
    .build();
}

export function createBackButtonWidget(gridPosition: GridPosition): WorkflowWidgetConfig {
  return new BackButtonWidgetBuilder()
    .defaultBackButton()
    .cssConfig({
      color: 'var(--typography-color-1)',
      backGroundColor: 'var(--palette-color-6)',
      borderColor: 'var(--border-color-1)',
      borderWidth: '1',
    })
    .configDisplayName('Button: Back Navigation')
    .gridPosition(gridPosition)
    .isRemovable(false)
    .setGlobalStylingClass(true, `${ButtonTypes.SECONDARY} ${ButtonSizeOptions.FULL_WIDTH}`)
    .build();
}

export function createQuickLinkWidget(override: any = {}): WorkflowWidgetConfig {
  return new QuickLinkWidgetBuilder()
    .zone(APP_ZONES.PROFILE)
    .navigateToPageUrl(override?.navigateToPageUrl)
    .navigationOption('use-as-navigation')
    .role()
    .text(override?.text)
    .cssConfig({
      ...override?.css_config,
    })
    .gridPosition(
      override?.gridPosition ? (override.gridPosition as GridPosition) : ({ rows: 2 } as GridPosition),
    )
    .configDisplayName(override?.configDisplayName)
    .setGlobalStylingClass(override?.globalStyling, override?.globalStylingClass)
    .build();
}

export function getWidgetCoordsPolygon(grid: GridPosition): { x: number; y: number }[] {
  return [
    { x: grid.x, y: grid.y },
    { x: grid.x + grid.cols, y: grid.y },
    { x: grid.x, y: grid.y + grid.y },
    { x: grid.x + grid.cols, y: grid.y + grid.rows },
  ];
}

export function pointInsidePolygon(point: { x: number; y: number }, polygon: { x: number; y: number }[]) {
  // ray-casting algorithm based on
  // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html
  const x = point.x,
    y = point.y;

  let inside = false;
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const xi = polygon[i].x,
      yi = polygon[i].y;
    const xj = polygon[j].x,
      yj = polygon[j].y;

    const intersect = yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
}

export function sortAndPushWidgetsDownIfNeededOnMobile(
  widgets: WorkflowWidgetConfig[],
): WorkflowWidgetConfig[] {
  let lastWidget: WorkflowWidgetConfig | null = null;

  const widgetsWithPosition = widgets.filter((widget) => widget.gridPosition);

  widgetsWithPosition
    .sort((a, b) => {
      return (a.gridPosition?.y || 0) - (b.gridPosition?.y || 0);
    })
    .map((widget) => {
      if (lastWidget?.gridPosition) {
        const lastWalkingIndex = lastWidget.gridPosition.y + lastWidget.gridPosition.rows;
        const lastWidgetPolygon = getWidgetCoordsPolygon(lastWidget.gridPosition);
        if (widget.gridPosition) {
          const widgetPoints = getWidgetCoordsPolygon(widget.gridPosition);
          if (widgetPoints.some((point) => pointInsidePolygon(point, lastWidgetPolygon))) {
            widget.gridPosition.y = lastWalkingIndex;
          }
        }
      }

      lastWidget = widget;
      return widget;
    });

  return widgets.sort((a, b) => {
    return (a.gridPosition?.y || 0) - (b.gridPosition?.y || 0);
  });
}

export function getBankAccountsWidgets(y: number): WorkflowWidgetConfig[] {
  const widgets: WorkflowWidgetConfig[] = [
    new MultiIconQuickLinkWidgetBuilder()
      .icons({
        left: 'pdf-file',
        right: 'arrow-right',
      })
      .template('<p class="multi-icon-header">Bank Statements</p>')
      .zone(APP_ZONES.PROFILE)
      .role()
      .navigateToPageUrl('bank-statements-account-file-type')
      .navigationOption('use-as-navigation')
      .configDisplayName('Multi Icon Quick Link: Bank Statements')
      .cssConfig({
        backGroundColor: 'var(--palette-color-6)',
        color: 'var(--typography-color-0)',
        textAlignment: 'space-between',
        borderRadiusTopLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusBottomRight: 5,
        borderWidth: 1,
        borderColor: 'var(--border-color-1)',
        fontSize: 16,
        textFont: 'var(--font-family)',
      })
      .isRemovable(true)
      .gridPosition({
        y: y ?? 31,
        x: 0,
        resizeEnabled: true,
        dragEnabled: true,
        rows: 3,
        cols: 15,
      } as GridPosition)
      .setGlobalStylingClass(true, `${ButtonTypes.MENU} ${ButtonSizeOptions.FULL_WIDTH}`)
      .build(),
  ];

  return widgets;
}

export function getFAQWidgets(y: number): WorkflowWidgetConfig[] {
  const widgets: WorkflowWidgetConfig[] = [
    new MultiIconQuickLinkWidgetBuilder()
      .icons({
        left: 'question-circle',
        right: 'arrow-right',
      })
      .template('<p class="multi-icon-header">FAQs</p>')
      .zone(APP_ZONES.PROFILE)
      .navigateToPageUrl('faqs-landing')
      .role()
      .navigationOption('use-as-navigation')
      .configDisplayName('Multi Icon Quick Link: FAQs')
      .cssConfig({
        backGroundColor: 'var(--palette-color-6)',
        color: 'var(--typography-color-0)',
        textAlignment: 'space-between',
        borderRadiusTopLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusBottomRight: 5,
        borderWidth: 1,
        borderColor: 'var(--border-color-1)',
        fontSize: 16,
        textFont: 'var(--font-family)',
      })
      .isRemovable(false)
      .gridPosition({
        y: y ?? 28,
        x: 0,
        resizeEnabled: true,
        dragEnabled: true,
        rows: 3,
        cols: 15,
      } as GridPosition)
      .setGlobalStylingClass(true, `${ButtonTypes.MENU} ${ButtonSizeOptions.FULL_WIDTH}`)
      .build(),
  ];

  return widgets;
}

export function getTextWidget(): WorkflowWidgetConfig {
  return new TextWidgetBuilder()
    .text(
      '<div class="w-100 p-0 list-style-disc">' +
        '<ul class="pl-4">' +
        '<li class="p-0">Balance updates after each transaction.</li>' +
        '</ul>' +
        '</div>',
    )
    .configDisplayName('Text Widget:Daily Balance Information')
    .gridPosition({ y: 9, rows: 2 } as GridPosition)
    .cssConfig({
      color: 'var(--typography-color-0)',
      textFont: 'var(--font-family)',
      display: 'flex',
      verticalAlignment: 'start',
      textAlignment: 'left',
      fontSize: '14',
    })
    .isRemovable(true)
    .build();
}
export function getTextWidgetConfig(): WorkflowWidgetConfig {
  return new TextWidgetBuilder()
    .text(
      '<div class="w-100 list-style-disc">' +
        '<ul class="pl-4">' +
        '<li class="p-0" >Notifications for successful/failed transactions.</li>' +
        '<li class="p-0" >Alerts for deposit arrivals.</li>' +
        '<li class="p-0" >Alerts for conversion notifications.</li>' +
        '</ul>' +
        '</div>',
    )
    .configDisplayName('Text Widget:Transactions Information')
    .gridPosition({ y: 14, rows: 5 } as GridPosition)
    .cssConfig({
      color: 'var(--typography-color-0)',
      textFont: 'var(--font-family)',
      display: 'flex',
      verticalAlignment: 'start',
      textAlignment: 'left',
      fontSize: '14',
    })
    .isRemovable(true)
    .build();
}

export function generateMultiIconQuickLinkBuilderProfile(
  leftIcon: string,
  rightIcon: string,
  headerText: string,
  pageUrl: string,
  displayName: string,
  gridYPosition: number,
) {
  return new MultiIconQuickLinkWidgetBuilder()
    .icons({
      left: leftIcon,
      right: rightIcon,
    })
    .template(`<p class="multi-icon-header">${headerText}</p>`)
    .zone(APP_ZONES.PROFILE)
    .role()
    .navigateToPageUrl(pageUrl)
    .navigationOption('use-as-navigation')
    .configDisplayName(displayName)
    .cssConfig({
      backGroundColor: 'var(--palette-color-6)',
      color: 'var(--typography-color-0)',
      textAlignment: 'space-between',
      borderRadiusTopLeft: 5,
      borderRadiusTopRight: 5,
      borderRadiusBottomLeft: 5,
      borderRadiusBottomRight: 5,
      borderWidth: 1,
      borderColor: 'var(--border-color-1)',
      fontSize: 16,
      textFont: 'var(--font-family)',
    })
    .isRemovable(false)
    .gridPosition({ y: gridYPosition } as GridPosition)
    .setGlobalStylingClass(true, `${ButtonTypes.MENU} ${ButtonSizeOptions.FULL_WIDTH}`)
    .build();
}
