import {
  APP_ZONES,
  RelevantBaasTags,
  WorkflowPageConfig,
  WorkflowUserType,
} from '@finxone-platform/shared/sys-config-types';
import { buildDefaultVerificationPages } from '../../defaults/pages/verification.pages';
import { defaultPageConfigurations } from '../../defaults/widgets/workflow-ui-constants.v2.type';
import { buildBeneficiariesZonePagesConfigDesktop } from '../../defaults/zones/beneficiaries-zone/desktop-pages-setup/beneficiaries-zone-pages-setup-desktop';
import { buildFeeManagementZonePagesConfigDesktop } from '../../defaults/zones/fee-management-zone/desktop-pages-setup/fee-management-zone-pages-setup-desktop';
import { buildDesktopManageUserZonePagesConfig } from '../../defaults/zones/manage-users-zone/manage-users-zone-page-setups';
import { buildDesktopVerificationOrgZonePagesConfig } from '../../defaults/zones/organisation-user/verification-organisation/verification-organisation-desktop-page-setup/verification-organisation-page-setups';
import {
  enterEmailWidgets,
  passwordCheckEmailWidgets,
  passwordForgotWidgets,
  passwordResetValidationWidgets,
} from '../../defaults/zones/profile/widget-sections/password';
import {
  buildDynamicZoneConfig,
  buildOrganisationOnboardingConfig,
  commonPageComponentsForMobileAndDesktopAndTablet,
  getBusinessProfileZoneDefaultPage,
  getRevenirZoneDefaultPage,
} from '../mobile/default-zone-page-setups';

export const forgotPassDefaultPageConfigDesktop = [
  ...buildDynamicZoneConfig([
    {
      name: 'enter-email',
      widgets: enterEmailWidgets('desktop'),
      url: 'enter-email',
      title: 'Enter Email',
    },
    {
      name: 'pass-email-check',
      widgets: passwordCheckEmailWidgets('desktop'),
      url: 'password-check-email',
      title: 'Password Check Email',
    },
    {
      name: 'pass-forgot',
      widgets: passwordForgotWidgets('desktop'),
      url: 'forgot-password',
      title: 'Forgot Password',
    },
    {
      name: 'pass-reset-validation',
      widgets: passwordResetValidationWidgets('desktop'),
      url: 'pass-reset-validation',
      title: 'Password Reset Validation',
    },
  ]),
] as WorkflowPageConfig[];

export const commonPageComponentsForDesktopAndTablet = new Map<
  string,
  ({
    userType,
    relevantBaasTags,
  }: {
    userType?: WorkflowUserType[];
    relevantBaasTags: RelevantBaasTags[];
  }) => WorkflowPageConfig[]
>([
  // ----------
  ...commonPageComponentsForMobileAndDesktopAndTablet,
  [
    APP_ZONES.SIGNED_OUT_FORGOT_PASSWORD,
    () => {
      return [...forgotPassDefaultPageConfigDesktop];
    },
  ],
  [
    APP_ZONES.MENU_ZONE,
    () => {
      return [
        {
          name: APP_ZONES.MENU_ZONE,
          title: 'Menu Page',
          pageConfiguration: {
            mainComponentPageName: 'ParentContainerComponent',
            isRemovable: false,
            url: 'menu',
            pageType: 'dynamicGrid',
            ...defaultPageConfigurations,
          },
          widgets: [],
        },
      ];
    },
  ],
  [
    APP_ZONES.ORGANISATION_ONBOARDING,
    () => {
      return buildOrganisationOnboardingConfig(true);
    },
  ],
  [
    APP_ZONES.ORGANISATION_VERIFICATION,
    () => {
      return [...buildDesktopVerificationOrgZonePagesConfig()];
    },
  ],
  [
    APP_ZONES.BENEFICIARY_ZONE,
    () => {
      return buildBeneficiariesZonePagesConfigDesktop();
    },
  ],
  [
    APP_ZONES.VERIFICATION,
    ({ userType }) => {
      return buildDynamicZoneConfig(
        buildDefaultVerificationPages({
          userType: userType as string[],
          deviceType: 'desktop',
        }),
      );
    },
  ],
  [
    APP_ZONES.FEE_MANAGEMENT,
    () => {
      return [...buildFeeManagementZonePagesConfigDesktop()];
    },
  ],
  [
    APP_ZONES.REVENIR_ZONE,
    () => {
      return [...getRevenirZoneDefaultPage()];
    },
  ],
]);

export const zoneToDesktopPageComponentDefaults = new Map<
  string,
  ({
    userType,
    relevantBaasTags,
  }: {
    userType?: WorkflowUserType[];
    relevantBaasTags: RelevantBaasTags[];
  }) => WorkflowPageConfig[]
>([
  [
    APP_ZONES.USER_MANAGEMENT,
    () => {
      return buildDesktopManageUserZonePagesConfig();
    },
  ],
  ...commonPageComponentsForDesktopAndTablet,

  [
    APP_ZONES.ORGANISATION_PROFILE,
    () => {
      return [...getBusinessProfileZoneDefaultPage()];
    },
  ],
]);
