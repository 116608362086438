export const accountCredentialInfoTemplate = `<div class="w-100 h-100 account-credentials">
<div class="is-space-between">
    {{#if (neq accountNumber '')}}
    <div class="is-vertical-align">
        <div class="is-vertical-align align-left">
            <div class="text-label">Account Number</div>
            <div class="text-value font-weight-bold" >
            {{ accountNumber }} 
            </div>
        </div>
    </div>
    {{/if}}
    {{#if (neq sortCode '')}}
    <div class="is-vertical-align">
        <div class="text-label">Sort Code</div>
        <div class="text-value font-weight-bold" >{{ sortCode }}</div>
    </div>
    {{/if}}
</div>
<div class="is-space-between my-4">
    {{#if (neq iban '')}}
    <div class="is-vertical-align align-left">
        <div class="text-label">IBAN Number</div>
        <div class="text-value font-weight-bold text-overflow"> {{ iban }} </div>
    </div>
    {{/if}}
    {{#if (neq bic '')}}
    <div class="is-vertical-align">
        <div class="text-label">Routing Code (ACH)</div>
        <div class="text-value font-weight-bold" > {{ bic }} </div>
    </div>
    {{/if}}
</div>
{{#if (neq accountCreatedDate '')}}
<div class="is-space-between my-4">
    <div class="is-vertical-align">
        <div class="is-vertical-align align-left">
            <div class="text-label">Account Creation Date</div>
            <div class="text-value font-weight-bold">{{ datePipe accountCreatedDate  format='d MMM y' }}</div>
        </div>
    </div>
</div>
{{/if}}
<div class="is-space-between my-4">
    <div class="is-vertical-align align-left">
        <div class="text-label">Currency</div>
        <div class="text-value font-weight-bold">{{ currencyFullName }}</div>
    </div>
    <div class="is-vertical-align">
        <div class="text-label">Balance</div>
        <div class="text-value font-weight-bold">{{ currencyPipe availableBalance currencyCode=currency display="symbol-narrow" }}</div>
    </div>
</div>
<div class="is-space-between my-4">
    <div class="is-vertical-align">
        <div class="is-vertical-align align-left">
            <div class="text-label">Country</div>
            <div class="text-value font-weight-bold">{{ country }}</div>
        </div>
    </div>
</div>
</div>`;

export const accountCredentialCurrencyInfoTemplate = `<div class="w-100 h-100">
<div class="w-100 font-weight-normal">{{ currencyFullName }} | {{ currency }} </div>
</div>`;

export const accountCredentialCurrencyFlagInfoTemplate = `<div class="w-100 h-100">
<div class="vertical-center" style="gap: 17px">
    <div>
        <div class="font-weight-bold">{{{ currencyToFlag currency '44px' }}}</div>
    </div>
    <div>
        <div class="font-weight-bold" >{{ currencyFullName }} | {{ currency }}</div>
    </div>
</div>
</div>`;
