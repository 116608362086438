import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { tap } from 'rxjs';
import { ResetFormDataAction } from '../../../actions/form-submission.action';
import { FeeManagementService } from '../../../services/fee-management-service/fee-management.service';
import { FeeManagementState } from '../../../state/fee-management.state';
import { FormActionState } from '../../../state/form-submision.state';
import { redirectToPage } from '../cta-button-actions.utils';

/**
 * Handles the request to update a client's fee group.
 *
 * This function retrieves necessary services and state data using the provided injector.
 * It checks for the presence of form data and client details, then attempts to update
 * the client's fee group using the FeeManagementService. Upon success, it shows a success
 * alert, resets the form data, and redirects to a specified page. If the update fails or
 * if required data is missing, it shows an error alert.
 *
 * @param widgetProperties - The properties of the widget initiating the request.
 * @param injector - The Angular injector used to retrieve services and state.
 */
export function handleUpdateClientFeeGroupRequest(
  widgetProperties: BaseWidgetProperties,
  injector: Injector,
) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  const alertHandlerService = injector.get(AlertHandlerService);
  const feeManagementService = injector.get(FeeManagementService);
  const formData = store.selectSnapshot(FormActionState.getFormActionState)?.response?.formData;
  const clientDetails = store.selectSnapshot(FeeManagementState.getFeeManagement)?.selectedClientDetails;
  if (formData && clientDetails) {
    feeManagementService
      .updateClientFeeGroupRequest(formData?.feeGroupId, clientDetails?.id)
      .pipe(
        tap((res) => {
          if (res) {
            alertHandlerService.showAlertFn('success', 'Client fee group updated successfully');
            store.dispatch(new ResetFormDataAction());
            redirectToPage(router, widgetProperties, injector);
          } else {
            alertHandlerService.showAlertFn('error', 'Client fee group updated failed, please try again');
          }
        }),
      )
      .subscribe();
  } else {
    alertHandlerService.showAlertFn('error', 'Details not available, please try again');
  }
}
