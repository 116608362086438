import {
  ADDRESS_FIELD_ATTRIBUTES,
  APP_ZONES,
  BaseCssConfig,
  Constants,
  DatePickerTypes,
  GridPosition,
  Page,
  WORKFLOW_POPUPS,
  WidgetNames,
  WidgetTitles,
  WorkflowWidgetConfig,
  WorkflowWidgetConfiguration,
} from '@finxone-platform/shared/sys-config-types';
import { emailNestedWidgets } from '../nested-widgets/onboarding/email';
import { phoneNestedWidgets } from '../nested-widgets/onboarding/phone';
import { defaultInputCssConfig } from '../payments/payments.type';

export const getShowHideSuccessErrorIcon = (isErrorIcon = false) => {
  return {
    field_name: isErrorIcon ? 'isShowErrorIcon' : 'isShowSuccessIcon',
    label: isErrorIcon ? 'Show Error Icon' : 'Show Success Icon',
    section: 'signup_widget',
    type: 'text',
    show: true,
    options: [],
  };
};

export const motVerificationQuestionWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: Math.floor(Math.random() * (1000000000 + 1)),
  name: WidgetNames.MOT_VERIFICATION,
  widgetConfiguration: {
    isVisible: true,
    widgetDisplayName: '',
    widgetConfigDisplayName: WidgetTitles.MOT_VERIFICATION,
    attributes: {
      label: 'Vehicle Verification',
      sub_heading: 'Please provide your registration number to verify your vehicle.',
      show: true,
      field_attributes: [
        {
          name: 'registered_number',
          label: 'Registered Number (Number Plate)',
          type: 'text',
          options: [],
          decision_config: {
            required: true,
            pattern: true,
            verifyViaOTP: true,
          },
          additional_config: {},
        },
      ],
      css_config: {
        ...Constants.defaultBaseCssConfig,
        borderColor: 'var(--color-4)',
        borderRadiusBottomRight: 8,
        borderRadiusBottomLeft: 8,
        borderRadiusTopRight: 8,
        borderRadiusTopLeft: 8,
        borderRadiusUnit: 'px',
        borderStyle: 'solid',
        borderWidth: 1,
        backGroundColor: 'var(--palette-color-6)',
        textInputFontSize: 16,
        textInputBackgroundColor: '#ffffff00',
      },
      elementsConfig: [
        {
          field_name: WidgetNames.MOT_VERIFICATION,
          label: 'Vehicle Verification',
          type: 'dropdown',
          section: 'questionwidget',
          show: false,
          options: [],
        },
        getShowHideSuccessErrorIcon(true),
        getShowHideSuccessErrorIcon(),
      ],
      widgetProperties: { textContent: '' },
    },
    isRemovable: true,
  },
  gridPosition: {
    dragEnabled: true,
    resizeEnabled: true,
    x: 0,
    y: 0,
    rows: 20,
    cols: 15,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const companyHouseCheckQuestionWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: Math.floor(Math.random() * (1000000000 + 1)),
  name: WidgetNames.COMPANY_HOUSE_CHECK,
  widgetConfiguration: {
    isVisible: true,
    widgetDisplayName: '',
    widgetConfigDisplayName: 'Company House Check',
    attributes: {
      label: 'Company Details',
      sub_heading: 'Verify your company details',
      show: true,
      decision_config: {
        required: true,
        checkCompanyHouse: true,
      },
      field_attributes: [
        {
          name: 'company_name',
          label: 'Company Name',
          type: 'text',
          field_config: {
            required: true,
          },
          options: [],
          additional_config: {},
        },
        {
          name: 'registration_number',
          label: 'Company Number',
          type: 'text',
          field_config: {
            required: true,
          },
          options: [],
          additional_config: {},
        },
      ],
      css_config: {
        ...Constants.defaultBaseCssConfig,
        borderColor: 'var(--color-4)',
        borderRadiusBottomRight: 8,
        borderRadiusBottomLeft: 8,
        borderRadiusTopRight: 8,
        borderRadiusTopLeft: 8,
        borderRadiusUnit: 'px',
        borderStyle: 'solid',
        borderWidth: 1,
        textInputFontSize: 16,
        textInputBackgroundColor: '#ffffff00',
      },
      widgetProperties: { textContent: '' },
      elementsConfig: [
        {
          field_name: Page.COMPANY_HOUSE_CHECK,
          label: 'Company House Check',
          type: 'dropdown',
          section: 'questionwidget',
          show: true,
          options: [],
        },
        getShowHideSuccessErrorIcon(true),
        getShowHideSuccessErrorIcon(),
      ],
    },
    isRemovable: true,
  },
  widgetIconPath: 'generic-widget',
  gridPosition: {
    x: 0,
    y: 0,
    rows: 16,
    cols: 15,
    dragEnabled: true,
    resizeEnabled: true,
  },
} as WorkflowWidgetConfig;

export const signupAutoBuildWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: Math.floor(Math.random() * (1000000000 + 1)),
  name: WidgetNames.NAME_WIDGET,
  widgetConfiguration: {
    isVisible: true,
    widgetDisplayName: WidgetTitles.AUTO_BUILD_FORM,
    widgetConfigDisplayName: '',
    isRemovable: false,
    attributes: {
      widgetProperties: { textContent: '' },
      elementsConfig: [],
      css_config: {},
    },
  },
  gridPosition: {
    x: 0,
    y: 0,
    cols: 15,
    rows: 4,
    dragEnabled: true,
    resizeEnabled: true,
  },
  widgetIconPath: 'generic-widget',
  popupType: WORKFLOW_POPUPS.SIGNUP_AUTO_BUILD_WIDGET,
} as WorkflowWidgetConfig;

export const integrationWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: Math.floor(Math.random() * (1000000000 + 1)),
  name: WidgetNames.INTEGRATION_WIDGET,
  widgetConfiguration: {
    isVisible: true,
    widgetDisplayName: WidgetTitles.INTEGRATION_WIDGET,
    widgetConfigDisplayName: '',
    isRemovable: false,
    attributes: {
      widgetProperties: { textContent: '' },
      elementsConfig: [],
      css_config: {},
    },
  },
  gridPosition: {
    x: 0,
    y: 0,
    cols: 15,
    rows: 4,
    dragEnabled: true,
    resizeEnabled: true,
  },
  widgetIconPath: 'generic-widget',
  popupType: WORKFLOW_POPUPS.INTEGRATION_WIDGET,
} as WorkflowWidgetConfig;

export function generateOnboardingWidgetsWorkflowWidgetConfig(
  widgetName: WidgetNames,
  zone?: APP_ZONES,
  device?: string,
): WorkflowWidgetConfig {
  return {
    // 16
    widgetTypeConfig: 'new',
    uniqueId: widgetName,
    name: widgetName,
    widgetConfiguration: OnboardingWidgetsConfigurationMap(widgetName, zone),
    gridPosition: getWidgetsGridPositionMap(widgetName, zone, device),
    widgetIconPath: 'generic-widget',
  } as WorkflowWidgetConfig;
}

export function OnboardingWidgetsConfigurationMap(
  widgetName: WidgetNames,
  zone?: APP_ZONES,
): WorkflowWidgetConfiguration | null {
  switch (widgetName) {
    case WidgetNames.NAME_WIDGET:
      return {
        isVisible: true,
        widgetDisplayName: WidgetTitles.SIGNUP,
        widgetConfigDisplayName: 'Name',
        isRemovable: false,
        attributes: {
          elementsConfig: [getShowHideSuccessErrorIcon(true), getShowHideSuccessErrorIcon()],
          widgetProperties: {
            textContent: '',
            inputFieldSettings: {
              css_config: defaultInputCssConfig,
            },
          },
          label: 'Name',
          sub_heading: 'Enter your name',
          show: true,
          decision_config: {
            required: true,
          },
          field_attributes: [
            {
              name: 'first_name',
              decision_config: {
                required: true,
              },
              label: 'First Name',
              type: 'text',
              options: [],
              additional_config: {},
            },
            {
              name: 'middle_name',
              decision_config: { required: false },
              label: 'Middle Name',
              type: 'text',
              options: [],
              additional_config: {},
            },
            {
              name: 'last_name',
              decision_config: { required: true },
              label: 'Last Name',
              type: 'text',
              options: [],
              additional_config: {},
            },
          ],
          css_config: getZoneSpecificCSSConfig(zone),
        },
      };
    case WidgetNames.PHONE_WIDGET:
      return {
        isVisible: true,
        widgetDisplayName: WidgetTitles.SIGNUP,
        widgetConfigDisplayName: 'Phone Number',
        attributes: {
          elementsConfig: [
            {
              field_name: 'verificationViaOTP',
              label: 'Check for Phone Validation (OTP)',
              section: 'signup_widget',
              type: 'text',
              show: true,
              options: [],
            },
            getShowHideSuccessErrorIcon(true),
            getShowHideSuccessErrorIcon(),
          ],
          widgetProperties: {
            textContent: '',
            inputFieldSettings: {
              css_config: defaultInputCssConfig,
            },
          },
          label: 'Phone',
          sub_heading: 'Enter and verify your phone number',
          show: true,
          field_attributes: [
            {
              name: 'country_code',
              label: 'Country Code',
              type: 'dropdown',
              options: [{ country_codes_list: [] }],
              decision_config: {
                required: true,
              },
            },
            {
              name: 'phone',
              label: 'Phone',
              type: 'number',
              options: [],
              decision_config: {
                required: true,
                pattern: true,
                verifyViaOTP: true,
              },
              additional_config: {},
            },
          ],
          css_config: getZoneSpecificCSSConfig(zone),
        },
        isRemovable: false,
        nestedWidgets: phoneNestedWidgets,
      };
    case WidgetNames.ADDRESS_WIDGET:
      return {
        isVisible: true,
        widgetConfigDisplayName: 'Address',
        widgetDisplayName: WidgetTitles.SIGNUP,
        attributes: {
          elementsConfig: [getShowHideSuccessErrorIcon(true), getShowHideSuccessErrorIcon()],
          widgetProperties: {
            textContent: '',
            inputFieldSettings: {
              css_config: defaultInputCssConfig,
            },
          },
          label: 'Address',
          sub_heading: 'Enter your address',
          show: true,
          field_attributes: ADDRESS_FIELD_ATTRIBUTES,
          css_config: getZoneSpecificCSSConfig(zone),
        },
        isRemovable: false,
      };
    case WidgetNames.EMAIL_WIDGET:
      return {
        isVisible: true,
        widgetConfigDisplayName: 'Email Address',
        widgetDisplayName: '',
        attributes: {
          widgetProperties: {
            textContent: '',
            inputFieldSettings: {
              css_config: defaultInputCssConfig,
            },
          },
          elementsConfig: [
            {
              field_name: 'verificationViaOTP',
              label: 'Check for Email Validation (OTP)',
              section: 'signup_widget',
              type: 'text',
              show: true,
              options: [],
            },
            getShowHideSuccessErrorIcon(true),
            getShowHideSuccessErrorIcon(),
          ],
          label: 'Email',
          sub_heading: 'Enter and verify your email address',
          show: true,
          decision_config: {
            required: true,
            pattern: true,
            verifyEmail: false,
          },
          field_attributes: [
            {
              name: 'email',
              label: 'Email',
              type: 'email',
              field_config: {
                required: true,
                pattern: true,
              },
              options: [],
              additional_config: {},
            },
          ],
          css_config: getZoneSpecificCSSConfig(zone),
        },
        isRemovable: false,
        nestedWidgets: emailNestedWidgets,
      };
    case WidgetNames.PASSWORD_WIDGET:
      return {
        isVisible: true,
        widgetConfigDisplayName: 'Password',
        widgetDisplayName: '',
        isRemovable: false,
        attributes: {
          widgetProperties: {
            textContent: '',
            inputFieldSettings: {
              css_config: defaultInputCssConfig,
            },
          },
          elementsConfig: [getShowHideSuccessErrorIcon(true), getShowHideSuccessErrorIcon()],
          label: 'Password',
          sub_heading: 'Set a password for your account',
          show: true,
          field_attributes: [
            {
              name: 'password',
              decision_config: {
                required: true,
              },
              label: 'Password',
              type: 'password',
              options: [],
              additional_config: {},
            },
            {
              name: 'confirm_password',
              decision_config: {
                required: true,
              },
              label: 'Confirm password',
              type: 'password',
              options: [],
              additional_config: {},
            },
          ],
          css_config: getZoneSpecificCSSConfig(zone),
        },
      };
    case WidgetNames.BIRTHDAY_WIDGET:
      return {
        isVisible: true,
        widgetConfigDisplayName: 'Date of Birth',
        widgetDisplayName: zone === APP_ZONES.ONBOARDING ? WidgetTitles.SIGNUP : WidgetTitles.VERIFICATION,
        isRemovable: false,
        attributes: {
          widgetProperties: {
            textContent: '',
            dateType: DatePickerTypes.DATEPICKER,
            inputFieldSettings: {
              css_config: defaultInputCssConfig,
            },
          },
          elementsConfig: [
            {
              field_name: 'min_age',
              label: 'Minimum Acceptance Age',
              section: zone === APP_ZONES.ONBOARDING ? 'signup_widget' : 'verification_widget',
              type: '18',
              show: true,
              options: [],
            },
            getShowHideSuccessErrorIcon(true),
            getShowHideSuccessErrorIcon(),
          ],
          label: 'Date of Birth',
          sub_heading: `Enter your date of Birth `,
          show: true,
          field_attributes: [
            {
              name: 'date_of_birth',
              label: 'Date of Birth',
              type: 'date',
              options: [],
              decision_config: {
                required: true,
                minimum_age: 18,
              },
              additional_config: {},
            },
          ],
          css_config: getZoneSpecificCSSConfig(zone),
        },
      };
    default:
      return null;
  }
}

export function getWidgetsGridPositionMap(
  widgetName: WidgetNames,
  zone?: APP_ZONES,
  device?: string,
): GridPosition | null {
  switch (widgetName) {
    case WidgetNames.NAME_WIDGET:
    case WidgetNames.PASSWORD_WIDGET:
      return {
        x: device == 'desktop' ? 15 : 0,
        y: 0,
        cols: 15,
        rows: device == 'desktop' ? 11 : 20,
        dragEnabled: true,
        resizeEnabled: true,
      };
    case WidgetNames.PHONE_WIDGET:
    case WidgetNames.EMAIL_WIDGET:
      return {
        x: device == 'desktop' ? 15 : 0,
        y: 0,
        cols: 15,
        rows: device == 'desktop' ? 6 : 12,
        dragEnabled: true,
        resizeEnabled: true,
      };
    case WidgetNames.ADDRESS_WIDGET:
      return {
        x: device == 'desktop' ? 15 : 0,
        y: 0,
        cols: 15,
        rows: device == 'desktop' ? 20 : 26,
        dragEnabled: true,
        resizeEnabled: true,
      };
    case WidgetNames.BIRTHDAY_WIDGET:
      return {
        x: device == 'desktop' ? 15 : 0,
        y: zone === APP_ZONES.ONBOARDING ? 0 : 6,
        cols: 15,
        rows: device == 'desktop' ? 15 : 22,
        minItemRows: 22,
        dragEnabled: true,
        resizeEnabled: true,
      };
    default:
      return null;
  }
}
export function getZoneSpecificCSSConfig(zone?: APP_ZONES): BaseCssConfig {
  if (zone === APP_ZONES.ONBOARDING) {
    return {
      ...Constants.defaultBaseCssConfig,
      borderColor: 'var(--color-4)',
      borderRadiusBottomRight: 8,
      borderRadiusBottomLeft: 8,
      borderRadiusTopRight: 8,
      borderRadiusTopLeft: 8,
      borderRadiusUnit: 'px',
      borderStyle: 'solid',
      borderWidth: 1,
      textFont: 'var(--font-family)',
    };
  } else {
    return {
      ...Constants.defaultBaseCssConfig,
      textFont: 'var(--font-family)',
    };
  }
}
