export const manageUsersPersonalInformation = `
<div class="w-100 h-100">
  <div class="content d-flex f-wrap mt-4">

    <div class="col-5 mb-5">
      <div class="text-label text-uppercase typog-xs font-weight-bold"> first name </div>
      <div class="text-value typog-md font-weight-normal"> {{addUserForm.first_name}} </div>
    </div>

    <div class="col-5 ml-8 mb-5">
      <div class="text-label text-uppercase typog-xs font-weight-bold "> middle name </div>
      <div class="text-value typog-md font-weight-normal"> {{#if addUserForm.middle_name}} {{ addUserForm.middle_name }}  {{else}} -- {{/if}}</div>
    </div>

    <div class="col-10 mb-5">
      <div class="text-label text-uppercase typog-xs font-weight-bold"> last name </div>
      <div class="text-value typog-md font-weight-normal"> {{addUserForm.last_name}} </div>
    </div>

    <div class="col-6 mb-5">
      <div class="text-label text-uppercase typog-xs font-weight-bold">phone number</div>
      <div class="text-value typog-md font-weight-normal"> {{addUserForm.country_code}} {{addUserForm.phone}} </div>
    </div>

  </div>
</div>
`;

export const manageUsersReviewAddressTemplate = `
<div class="w-100">
  <div class="content d-flex f-wrap mt-4">
    <div class="col-5 mb-5">
    <div class="label text-uppercase typog-xs font-weight-bold"> STREET </div>
    <div class="event-value typog-md font-weight-normal" > {{addUserForm.addressStreet}} </div>
    </div>

    <div class="col-5 ml-8 mb-5">
    <div class="label text-uppercase typog-xs font-weight-bold"> Country </div>
    <div class="event-value typog-md font-weight-normal" >{{addUserForm.addressCountry}}</div>
    </div>

    <div class="col-5 mb-5">
    <div class="label text-uppercase typog-xs font-weight-bold"> CITY </div>
    <div class="event-value typog-md font-weight-normal" > {{addUserForm.addressCity}} </div>
    </div>

    <div class="col-5 ml-8 mb-5">
    <div class="label text-uppercase typog-xs font-weight-bold"> ZIP/Postal code </div>
    <div class="event-value typog-md font-weight-normal"> {{addUserForm.addressPostCode}} </div>
    </div>

    <div class="col-5 mb-5">
    <div class="label text-uppercase typog-xs font-weight-bold"> state/region </div>
    <div class="event-value typog-md font-weight-normal" > {{addUserForm.addressState}} </div>
    </div>

  </div>
</div>
`;

export const manageUsersLoginDetails = `
<div class="w-100 ">
  <div class="content d-flex">
    <div class="col-5">
    <div class="label text-uppercase typog-xs font-weight-bold"> Username </div>
    <div class="event-value typog-md font-weight-normal" > {{addUserForm.userName}} </div>
    </div>

    <div class="col-5 ml-8">
    <div class="label text-uppercase typog-xs font-weight-bold"> Password </div>
    <div class="event-value typog-md font-weight-normal" > {{addUserForm.password}} </div>
    </div>
  </div>
</div>
`;

export const manageUserPersonaRole = `
<div class="w-100">
  <div class="content">
    <div class="mb-5">
      <div class="label text-uppercase typog-xs font-weight-bold"> Persona Assigned </div>
      <div class="event-value typog-md font-weight-normal"> {{addUserForm.assign_persona}} </div>
    </div>
    <div>
      <div class="label text-uppercase typog-xs font-weight-bold"> Role/permission assigned </div>
      <div class="event-value typog-md font-weight-normal">{{addUserForm.assign_role_permission}} </div>
    </div>
  </div>
</div>
`;
