import { Injectable } from '@angular/core';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { PaymentRequestPayload } from '@finxone-platform/shared/utils';
import { Action, State, StateContext } from '@ngxs/store';
import { Observable, catchError, delay, tap } from 'rxjs';
import { FormActionTypeEnum } from '../../../../../../libs/shared/enums/form-action.enum';
import { UpdateFormDataAction, UpdateFormDataActionWithId } from '../actions/form-submission.action';
import { UpdatePaymentRequestSuccess } from '../actions/payment-request.action';
import {
  ApproveOrRejectBulkPayment,
  BulkPaymentDeclined,
  BulkPaymentError,
  BulkPaymentSuccess,
  CreateBulkPayment,
  CreatePayment,
  InternationalCreatePayment,
  InternationalPaymentDeclined,
  InternationalPaymentSuccess,
  PaymentDeclined,
  PaymentSuccess,
} from '../actions/payment.action';
import { AddProgressBarStack, RemoveProgressBarStack } from '../actions/progress-bar.action';
import { AccountService } from '../services/account-service/account-service.service';
import { BulkPaymentService } from '../services/bulk-payment-service/bulk-payment-service.service';
import { delayTime } from '../utils/cta-button-actions/bulk-payments/bulk-payments.utils';

export interface PaymentStateModel {}

@State<PaymentStateModel>({
  name: 'payment',
  defaults: {},
})
@Injectable()
export class PaymentState {
  constructor(
    private accountService: AccountService,
    private bulkPaymentService: BulkPaymentService,
    private alertHandlerService: AlertHandlerService,
  ) {}

  @Action(CreatePayment)
  createPayment(ctx: StateContext<PaymentStateModel>, payload: CreatePayment) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'CreatePayment' }));

    return this.accountService.transferMoney(payload.paymentRequest).pipe(
      tap((response) => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'CreatePayment' }));
        ctx.dispatch(new PaymentSuccess());
        ctx.dispatch(
          new UpdateFormDataAction({
            referenceNumber: response?.transactionNumber,
          }),
        );
        ctx.dispatch(new UpdatePaymentRequestSuccess());
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'CreatePayment' }));
        ctx.dispatch(new PaymentDeclined());
        throw _err;
      }),
    );
  }

  @Action(InternationalCreatePayment)
  async createInternationalPayment(
    ctx: StateContext<PaymentStateModel>,
    payload: InternationalCreatePayment,
  ) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'InternationalCreatePayment' }));

    this.accountService
      .transferMoneyInternational(payload.paymentRequest)
      .pipe(
        tap((response) => {
          ctx.dispatch(
            new RemoveProgressBarStack({
              uniqueId: 'InternationalCreatePayment',
            }),
          );
          if (!payload.paymentRequest.review) {
            ctx.dispatch(new InternationalPaymentSuccess());
          }
          ctx.dispatch(
            new UpdateFormDataActionWithId(
              (response ?? {}) as any,
              FormActionTypeEnum.INTL_TRANSFER_RESPONSE,
            ),
          );
        }),
        catchError<unknown, Observable<boolean>>((_err) => {
          ctx.dispatch(
            new RemoveProgressBarStack({
              uniqueId: 'InternationalCreatePayment',
            }),
          );
          if (!payload.paymentRequest.review) {
            ctx.dispatch(new InternationalPaymentDeclined());
          }
          throw _err;
        }),
      )
      .subscribe();
  }

  @Action(CreateBulkPayment)
  CreateBulkPayment(ctx: StateContext<PaymentStateModel>, payload: CreateBulkPayment) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'CreateBulkPayment' }));

    return this.accountService.makeBulkPayment(payload.id).pipe(
      delay(delayTime),
      tap((response) => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'CreateBulkPayment' }));
        ctx.dispatch(new BulkPaymentSuccess());
        ctx.dispatch(
          new UpdateFormDataAction({
            referenceNumber: response?.transactionNumber,
          }),
        );
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'CreateBulkPayment' }));
        ctx.dispatch(new BulkPaymentDeclined());
        throw _err;
      }),
    );
  }
  @Action(ApproveOrRejectBulkPayment)
  ApproveOrRejectBulkPayment(ctx: StateContext<PaymentStateModel>, payload: ApproveOrRejectBulkPayment) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'ApproveOrRejectBulkPayment' }));
    const payloadPayment: PaymentRequestPayload = {
      ids: [payload.recordId],
      approvedStatus: payload.status,
    };

    return this.accountService?.generatePaymentRequest(payloadPayment).pipe(
      tap((response) => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'ApproveOrRejectBulkPayment' }));
        if (payload.status === 'rejected') {
          ctx.dispatch(new BulkPaymentDeclined());
        } else if (payload.status === 'approved') {
          ctx.dispatch(new BulkPaymentSuccess());
        }
        this.alertHandlerService.showAlertFn('success', `Payment ${payload.status} successfully.`);
        return response;
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'ApproveOrRejectBulkPayment' }));
        ctx.dispatch(new BulkPaymentError());
        throw _err;
      }),
    );
  }
}
