import {
  DocumentMonthsUpdate,
  DocumentType,
  DownloadDocumentType,
  GridPosition,
} from '@finxone-platform/shared/sys-config-types';

import { DownloadDocumentWidgetBuilder } from '../../../../widgets/example-builder';
import { calendarWidgetConfig } from '../../../../widgets/profile/profile.type';
import { createBackButtonWidget, createTextWidget } from '../utils/common-utils';

export const bankStatementsCustomDateRangeWidgets = [
  createTextWidget({
    text: '<h1>Pick Start & End Dates</h1>',
    configDisplayName: 'Text Widget: Pick Start & End Dates',
    gridPosition: { y: 1, rows: 3 },
    css_config: {
      color: 'var(--typography-color-0)',
      textAlignment: 'center',
      fontSize: '10',
      isTextBold: true,
    },
  }),
  calendarWidgetConfig,
  createBackButtonWidget({ y: 25, rows: 2, cols: 7 } as GridPosition),
  new DownloadDocumentWidgetBuilder()
    .attributes({
      documentType: DownloadDocumentType.BANK_STATEMENT,
      timeInterval: DocumentMonthsUpdate.NONE,
      downloadDocumentAs: DocumentType.CONTEXT,
      textInputHeader: 'Download',
      textInputSubHeader: ``,
      elementsConfig: [],
      widgetProperties: {
        textContent: 'Download',
      },
      css_config: {
        borderWidth: 0,
        paddingTop: 0,
        paddingLeft: 3,
        paddingRight: 3,
        paddingBottom: 0,
      },
    })
    .gridPosition({
      x: 8,
      y: 25,
      rows: 2,
      cols: 7,
      layerIndex: 1,
    } as GridPosition)
    .configDisplayName('Download Document')
    .isRemovable(false)
    .build(),
];
