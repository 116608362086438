import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { APP_ZONES, BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { map, take } from 'rxjs';
import {
  UpdatePaymentRequest,
  UpdatePaymentRequestFailed,
  UpdatePaymentRequestSuccess,
} from '../../../actions/payment-request.action';
import { PaymentDeclined } from '../../../actions/payment.action';
import { PaymentRequestUpdateRequest } from '../../../services/account-service/account.type';
import { NotificationState } from '../../../state/notifications.state';
import { redirectToPage } from '../cta-button-actions.utils';

/**
 * Approves a payment request by dispatching an update action and handling the response.
 *
 * @param injector - The Angular injector used to retrieve services.
 * @param widgetProperties - Properties of the widget used for navigation and configuration.
 *
 * This function retrieves necessary services and state using the injector, checks if
 * payment request summary data is available, and dispatches an update action to approve
 * the payment request. It listens for specific actions to determine the outcome of the
 * request and navigates to appropriate pages based on success, failure, or decline.
 * If the payment request data is unavailable, it shows an error alert.
 */
export function approvePaymentRequest(injector: Injector, widgetProperties: BaseWidgetProperties) {
  const store = injector.get(Store);
  const actions = injector.get(Actions);
  const router = injector.get(Router);
  const alertHandlerService = injector.get(AlertHandlerService);
  const paymentRequestSummary = store.selectSnapshot(NotificationState.getNotification).paymentRequestSummary;
  if (paymentRequestSummary) {
    actions
      .pipe(
        ofActionDispatched(UpdatePaymentRequestSuccess),
        take(1),
        map(() => {
          widgetProperties['urlToNavigate'] = 'payment-request-success';
          redirectToPage(router, widgetProperties, injector, true);
        }),
      )
      .subscribe();

    actions
      .pipe(
        ofActionDispatched(PaymentDeclined),
        take(1),
        map(() => {
          widgetProperties['urlToNavigate'] = 'payment-request-failed';
          redirectToPage(router, widgetProperties, injector, true);
        }),
      )
      .subscribe();

    actions
      .pipe(
        ofActionDispatched(UpdatePaymentRequestFailed),
        take(1),
        map(() => {
          widgetProperties['zoneToNavigate'] = APP_ZONES.PROFILE;
          widgetProperties['urlToNavigate'] = 'notification-centre';
          redirectToPage(router, widgetProperties, injector, true);
        }),
      )
      .subscribe();

    widgetProperties['urlToNavigate'] = 'payment-request-processing';
    redirectToPage(router, widgetProperties, injector, true);

    const updatePayload: PaymentRequestUpdateRequest = {
      id: paymentRequestSummary.recordId,
      approvedStatus: 'approved',
    };

    store.dispatch(new UpdatePaymentRequest(updatePayload));
  } else {
    alertHandlerService.showAlertFn('error', 'Payment request data not available, please try again');
    console.error('Payment request data not available, please try again');
  }
}
