import { APP_ZONES, ButtonActionType, GridPosition } from '@finxone-platform/shared/sys-config-types';
import { commonCTAButton, commonTextWidget } from '../../../common/common-widgets';

export const tripZoneMultipleCountryNotificationWidgets = [
  commonTextWidget({
    textContent: 'Multiple Country Travel',
    cssConfig: {
      textAlignment: 'center',
      fontSize: 24,
      isTextBold: true,
      paddingLeft: 20,
      paddingRight: 20,
      marginLeft: 20,
      marginRight: 20,
      textFont: 'var(--font-family)',
    },
    gridPosition: {
      y: 3,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 15,
    },
  }),

  commonTextWidget({
    textContent:
      'You will only be able to scan VAT Reclaim Barcodes for the items purchased in {{ tripZoneFormState.leaving_country }}. We will send you documents to reclaim VAT for items purchased in {{ tripZoneFormState.entering_country }}.',
    cssConfig: {
      fontSize: 16,
      color: 'var(--typography-color-1)',
      paddingLeft: 20,
      paddingRight: 20,
      textFont: 'var(--font-family)',
    },
    gridPosition: {
      x: 0,
      y: 6,
      rows: 5,
      cols: 15,
      resizeEnabled: true,
      dragEnabled: true,
    },
  }),
  commonCTAButton({
    zoneToNavigate: APP_ZONES.REVENIR_ZONE,
    urlToNavigate: 'exit-point',
    textContent: 'I Understand',
    buttonActionType: ButtonActionType.MULTIPLE_COUNTRY_NOTI,
    formKey: '',
    gridPosition: { y: 12, x: 2, rows: 2, cols: 11 } as GridPosition,
  }),
];
