import { UUID } from '@finxone-platform/shared/base-types';
import {
  ADDRESS_FIELD_ATTRIBUTES,
  APP_ZONES,
  DatePickerTypes,
  NavBar,
  SINK_TYPE,
  ValidationRuleTypes,
  WorkflowPageConfig,
  cryptoRandomUUID,
  defaultOrganisationAnonymousRoleCssConfig,
} from '@finxone-platform/shared/sys-config-types';
import { generateTextWidgetConfig } from '../../../../page-builder-utils/widget-builder-utils/common-widget-utils';
import { defaultInputCssConfig } from '../../../widgets/payments/payments.type';
import { getBackNextButton } from '../../../widgets/workflow-ui-constants.v2.type';

export const customDataQuestionData = ({
  entityType,
  sink,
  data_type,
}: {
  entityType: string;
  sink?: SINK_TYPE;
  data_type?: string;
}) => {
  return {
    data_type: data_type ?? 'string',
    entity: entityType,
    sink: sink ?? SINK_TYPE.HASURA,
    isUpdateToHasura: false,
  };
};

export function defaultOrganisationAnonymousRolePagesConfig(isDesktop = false) {
  const pages = [
    {
      name: 'Onboarding',
      title: 'Onboarding',
      pageConfiguration: {
        mainComponentPageName: 'ParentContainerComponent',
        isRemovable: false,
        pageType: 'dynamicGrid',
        url: 'onboarding',
        gridsterMargin: {
          left: 1,
          right: 1,
          top: 2,
          unit: 'em',
        },
        showHeader: false,
        labelsAsPlaceholder: false,
        hideNavigationMenu: false,
      },
      widgets: [
        {
          widgetTypeConfig: 'new',
          name: 'custom_question_widget',
          uniqueId: cryptoRandomUUID(),
          gridPosition: {
            x: 0,
            y: 2,
            cols: 15,
            rows: isDesktop ? 7 : 15,
            dragEnabled: true,
            resizeEnabled: true,
          },
          widgetIconPath: 'generic-widget',
          widgetConfiguration: {
            isVisible: true,
            attributes: {
              show: true,
              label: 'Name',
              css_config: defaultOrganisationAnonymousRoleCssConfig,
              sub_heading: 'Enter your organisation name',
              questionUniqueId: 'organisation-onboarding-name',
              elementsConfig: [
                {
                  field_name: 'custom_question_widget',
                  label: 'Name',
                  type: 'dropdown',
                  section: 'questionwidget',
                  show: false,
                  options: [],
                  uniqueId: cryptoRandomUUID(),
                },
              ],
              widgetProperties: {
                textContent: '',
                preSelectSubAnswer: 'false',
              },
              questionProperties: {
                name: 'name',
                label: '',
                type: 'text',
                options: [],
                preSelectSubAnswer: 'false',
              },
              customDataQuestionData: customDataQuestionData({
                entityType: APP_ZONES.ORGANISATION_ONBOARDING,
                sink: SINK_TYPE.METADATA,
                data_type: 'string',
              }),
              validationRules: [
                {
                  type: ValidationRuleTypes.REQUIRED,
                  value: 'true',
                },
              ],
            },
            isRemovable: false,
            widgetDisplayName: '',
            widgetConfigDisplayName: 'Custom Widget',
          },
        },
        generateTextWidgetConfig({
          gridPosition: {
            y: isDesktop ? 10 : 22,
            rows: 6,
          },
          cssConfig: {
            textAlignment: 'center',
            color: 'var(--typography-color-1)',
            fontSize: 14,
          },
          textContent: `Note: Please complete the entire onboarding process, including linking or creating an account. Exiting before completion means you won't be able to re-add the organisation with the same ID number for 24 hours.`,
          configDisplayName: 'Disclaimer',
        }),
      ],
    },
    {
      name: 'address_widget',
      title: 'Address',
      pageConfiguration: {
        mainComponentPageName: 'ParentContainerComponent',
        isRemovable: false,
        pageType: 'dynamicGrid',
        url: 'address',
        gridsterMargin: {
          left: 1,
          right: 1,
          top: 2,
          unit: 'em',
        },
        showHeader: false,
        labelsAsPlaceholder: false,
        isBodyScrollingEnabled: true,
        hideNavigationMenu: false,
      },
      widgets: [
        {
          widgetTypeConfig: 'new',
          uniqueId: 'address_widget',
          name: 'address_widget',
          widgetConfiguration: {
            isVisible: true,
            widgetConfigDisplayName: 'Address',
            widgetDisplayName: 'Signup',
            attributes: {
              elementsConfig: [],
              widgetProperties: {
                textContent: '',
                inputFieldSettings: {
                  css_config: defaultInputCssConfig,
                },
              },
              label: 'Address',
              sub_heading: 'Enter your address',
              show: true,
              field_attributes: ADDRESS_FIELD_ATTRIBUTES,
              css_config: defaultOrganisationAnonymousRoleCssConfig,
            },
            isRemovable: true,
          },
          gridPosition: {
            x: 0,
            y: 0,
            cols: 15,
            rows: 25,
            dragEnabled: true,
            resizeEnabled: true,
          },
          widgetIconPath: 'generic-widget',
        },
      ],
    },
    {
      name: 'organisation_registration_number',
      title: 'Organisation Registration Number',
      pageConfiguration: {
        mainComponentPageName: 'ParentContainerComponent',
        isRemovable: false,
        pageType: 'dynamicGrid',
        url: 'registration-number',
        gridsterMargin: {
          left: 1,
          right: 1,
          top: 2,
          unit: 'em',
        },
        showHeader: false,
        labelsAsPlaceholder: false,
        hideNavigationMenu: false,
      },
      widgets: [
        {
          widgetTypeConfig: 'new',
          name: 'custom_question_widget',
          uniqueId: cryptoRandomUUID(),
          gridPosition: {
            x: 0,
            y: 2,
            cols: 15,
            rows: isDesktop ? 7 : 15,
            dragEnabled: true,
            resizeEnabled: true,
          },
          widgetIconPath: 'generic-widget',
          widgetConfiguration: {
            isVisible: true,
            attributes: {
              show: true,
              label: 'Registration Number',
              css_config: defaultOrganisationAnonymousRoleCssConfig,
              sub_heading: 'Enter your organisation registration number',
              questionUniqueId: 'organisation-onboarding-registration-number',
              elementsConfig: [
                {
                  field_name: 'custom_question_widget',
                  label: 'Registration Number',
                  type: 'dropdown',
                  section: 'questionwidget',
                  show: false,
                  options: [],
                  uniqueId: cryptoRandomUUID(),
                },
              ],
              widgetProperties: {
                textContent: '',
                preSelectSubAnswer: 'false',
              },
              questionProperties: {
                name: 'registration-number',
                label: '',
                type: 'text',
                options: [],
                preSelectSubAnswer: 'false',
              },
              customDataQuestionData: customDataQuestionData({
                entityType: APP_ZONES.ORGANISATION_ONBOARDING,
                sink: SINK_TYPE.METADATA,
                data_type: 'string',
              }),
              validationRules: [
                {
                  type: ValidationRuleTypes.REQUIRED,
                  value: 'true',
                },
              ],
            },
            isRemovable: false,
            widgetDisplayName: '',
            widgetConfigDisplayName: 'Custom Widget',
          },
        },
      ],
    },
    {
      name: 'organisation_incorporation_date',
      title: 'Organisation Incorporation Date',
      pageConfiguration: {
        mainComponentPageName: 'ParentContainerComponent',
        isRemovable: false,
        pageType: 'dynamicGrid',
        url: 'incorporation-date',
        gridsterMargin: {
          left: 1,
          right: 1,
          top: 2,
          unit: 'em',
        },
        showHeader: false,
        labelsAsPlaceholder: false,
        hideNavigationMenu: false,
      },
      widgets: [
        {
          widgetTypeConfig: 'new',
          name: 'custom_question_widget',
          uniqueId: cryptoRandomUUID(),
          gridPosition: {
            x: 0,
            y: 2,
            cols: 15,
            rows: isDesktop ? 11 : 15,
            dragEnabled: true,
            resizeEnabled: true,
          },
          widgetIconPath: 'generic-widget',
          widgetConfiguration: {
            isVisible: true,
            attributes: {
              show: true,
              label: 'Incorporation Date',
              css_config: defaultOrganisationAnonymousRoleCssConfig,
              sub_heading: 'Enter your organisation date of incorporation',
              questionUniqueId: 'organisation-onboarding-incorporation-date',
              elementsConfig: [
                {
                  field_name: 'custom_question_widget',
                  label: 'Incorporation Date',
                  type: 'date',
                  section: 'questionwidget',
                  show: false,
                  options: [],
                  uniqueId: cryptoRandomUUID(),
                },
              ],
              widgetProperties: {
                textContent: '',
                preSelectSubAnswer: 'false',
                dateType: DatePickerTypes.INPUT,
                inputFieldSettings: {
                  css_config: defaultInputCssConfig,
                },
                dayLabel: 'Day',
                monthLabel: 'Month',
                yearLabel: 'Year',
              },
              questionProperties: {
                name: 'incorporation-date',
                label: '',
                type: 'date',
                options: [],
                preSelectSubAnswer: 'false',
              },
              customDataQuestionData: customDataQuestionData({
                entityType: APP_ZONES.ORGANISATION_ONBOARDING,
                sink: SINK_TYPE.METADATA,
                data_type: 'string',
              }),
              validationRules: [
                {
                  type: ValidationRuleTypes.REQUIRED,
                  value: 'true',
                },
              ],
            },
            isRemovable: false,
            widgetDisplayName: '',
            widgetConfigDisplayName: 'Custom Widget',
          },
        },
      ],
    },
  ] as WorkflowPageConfig[];
  addButtons(pages, isDesktop);
  return pages;
}

export function addButtons(pages: WorkflowPageConfig[], isDesktop: boolean) {
  if (isDesktop) {
    for (const page of pages) {
      page.widgets.forEach((widget) => {
        if (!widget.gridPosition) {
          throw new Error('Widget gridPosition must be defined');
        }
        widget.gridPosition.x = 15;
      });
      const lottieWidget = page.widgets.find((x) => x.name == 'lottie_animation');
      if (lottieWidget && lottieWidget.gridPosition) {
        lottieWidget.gridPosition.x = 21;
      }
      const lastWidget = page.widgets[page.widgets.length - 1];
      const buttonYIndex = lastWidget?.gridPosition
        ? lastWidget.gridPosition.y + lastWidget.gridPosition.rows
        : 0;
      if (page.name !== 'organisation-onboarding-completion') {
        page.widgets.push(
          getBackNextButton({
            icon: 'frame-arrow-right',
            gridPosition: {
              y: buttonYIndex + 3,
              x: 28,
              rows: 2,
              cols: 2,
              resizeEnabled: true,
              dragEnabled: true,
            },
            displayText: 'Next',
            buttonType: 'next',
          }),
        );
        page.widgets.push(
          getBackNextButton({
            icon: 'frame-arrow-left',
            gridPosition: {
              y: buttonYIndex + 3,
              x: 15,
              rows: 2,
              cols: 2,
              resizeEnabled: true,
              dragEnabled: true,
            },
            displayText: 'Back',
            buttonType: 'back',
          }),
        );
      }
    }
  } else {
    for (const page of pages) {
      const lastWidget = page.widgets[page.widgets.length - 1];
      const buttonYIndex = lastWidget?.gridPosition
        ? lastWidget.gridPosition.y + lastWidget.gridPosition.rows
        : 0;
      if (page.name !== 'organisation-onboarding-completion') {
        page.widgets.push(
          getBackNextButton({
            icon: '',
            gridPosition: {
              y: buttonYIndex + 3,
              x: 10,
              rows: 2,
              cols: 4,
              resizeEnabled: true,
              dragEnabled: true,
            },
            displayText: 'Forward Button : Continue',
            buttonType: 'next',
            text: 'Continue',
            shape: 'rectangle',
            styles: {
              paddingTop: 1,
              paddingLeft: 2.5,
              paddingRight: 2.5,
              paddingBottom: 1,
              paddingUnit: 'rem',
            },
          }),
        );
      }
    }
  }
}

export const defaultOrganisationAnonymousRole = {
  id: new UUID().toString(),
  name: 'Organisation anonymous',
  slug: 'organisation-anonymous',
  zones: {
    'organisation-onboarding': null,
    organisation_verification: null,
  },
  userType: 'org',
  is_removable: false,
  applied_all_zones: true,
  navBar: {} as NavBar,
};
