import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonActionType,
  GridPosition,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import {
  EUCountryWidgetBuilder,
  FormSubmissionButtonWidgetBuilder,
} from '../../../../widgets/example-builder';
import { commonBackButtonQuickLink, commonTextWidget } from '../../../common/common-widgets';
import { generateProgressBarWidget } from '../../../manage-users-zone/common-utils';

export const tripZoneEnteringEUWidgets = [
  generateProgressBarWidget({
    cssConfig: {
      paddingRight: 0,
    },
    completionProgressPercentage: '70',
    position: {
      y: 0,
      rows: 2,
      cols: 15,
    } as GridPosition,
  }),

  commonBackButtonQuickLink({
    cssConfig: {
      iconSize: 35,
      iconUnit: 'px',
    },
    gridPosition: {
      rows: 2,
      cols: 2,
      y: 3,
      x: 0,
      layerIndex: 0,
      resizeEnabled: true,
      dragEnabled: true,
    },
  }),

  commonTextWidget({
    textContent: '<span class="normal-line-height">Which country are you entering the EU through?</span>',
    cssConfig: {
      fontSize: 24,
      isTextBold: true,
      textFont: 'var(--font-family)',
    },
    gridPosition: {
      y: 3,
      x: 2,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 5,
      cols: 11,
    },
  }),

  new EUCountryWidgetBuilder()
    .fieldName('entering_country')
    .gridPosition({ x: 0, y: 9, rows: 4, cols: 15 } as GridPosition)
    .isRemovable(false)
    .build(),

  new FormSubmissionButtonWidgetBuilder()
    .configDisplayName('Button: Continue')
    .attributes({
      widgetProperties: {
        textContent: 'Continue',
        zoneToNavigate: APP_ZONES.REVENIR_ZONE,
        urlToNavigate: 'leaving-the-eu',
        buttonActionType: ButtonActionType.TRIP_COUNTRY_CONTINUE,
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .gridPosition({
      x: 0,
      y: 26,
      rows: 2,
    } as GridPosition)
    .cssConfig({
      disabledBgColor: 'var(--palette-color-3)',
      disabledTextColor: 'var(--palette-color-6)',
    })
    .build(),
];
