import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonActionType,
  ButtonSizeOptions,
  ButtonTypes,
  CommonPageConfig,
  DefaultToggleOption,
  GridPosition,
  NotificationAlertTypeEnum,
  NotificationChannelEnum,
  PaymentProvider,
  ProfilePagesTags,
  RelevantBaasTags,
  WorkflowPageConfig,
  WorkflowWidgetConfig,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import { buildOtpVerificationPageWidgetsConfig } from '../../../page-builder-utils/widget-builder-utils/otp-verification-page';
import { PageConfig } from '../../../zones/mobile/default-zone-page-setups';
import { formatPageName } from '../../pages/utils';
import {
  AddressWidgetBuilder,
  BackButtonWidgetBuilder,
  BirthdayWidgetBuilder,
  EmailWidgetBuilder,
  FormSubmissionButtonWidgetBuilder,
  MultiIconQuickLinkWidgetBuilder,
  NameWidgetBuilder,
  PhoneWidgetBuilder,
  TextWidgetBuilder,
} from '../../widgets/example-builder';
import { multiIconWidgetCssConfig } from '../../widgets/profile/profile.type';
import { defaultPageConfigurations } from '../../widgets/workflow-ui-constants.v2.type';
import { authenticationWidgets } from './widget-sections/authentication';
import { bankStatementsAccountSelectFileTypeWidgets } from './widget-sections/bank-statements/bank-statements-account-select-file-type';
import { bankStatementsCustomDateRangeWidgets } from './widget-sections/bank-statements/bank-statements-custom-date-range';
import { bankStatementsPresetDownloadsWidgets } from './widget-sections/bank-statements/bank-statements-preset-downloads';
import { faqsWidgets, faqscreatemoneyfin, faqsnotification } from './widget-sections/faqs';
import { notificationBalanceWidgets } from './widget-sections/notification/notification-balance';
import {
  notificationBeneficiaryEditWidgets,
  notificationBeneficiaryRemoveWidgets,
} from './widget-sections/notification/notification-beneficiary';
import { notificationBeneficiaryAddedWidgets } from './widget-sections/notification/notification-beneficiary-added';
import { notificationCentreWidgets } from './widget-sections/notification/notification-centre';
import { notificationDepositArrivedWidgets } from './widget-sections/notification/notification-deposit-arrived';
import { notificationExchangeFailedWidgets } from './widget-sections/notification/notification-exchange-failed';
import { notificationExchangeSuccessWidgets } from './widget-sections/notification/notification-exchange-success';
import { notificationInPushWidgets } from './widget-sections/notification/notification-settings-in-push.widgets';
import { notificationTransactionFailedWidgets } from './widget-sections/notification/notification-transaction-failed';
import { notificationTransactionSuccessWidgets } from './widget-sections/notification/notification-transaction-success';
import { passwordChangePassWidgets, passwordChangeWidgets } from './widget-sections/password';
import { privacyPolicy } from './widget-sections/privacy-policy';
import { authenticationConfirmationPageWidgets } from './widget-sections/profile/authentication-confirmation-page';
import { profileLandingPageWidgets } from './widget-sections/profile/profile-landing-page';
import { userProfileWidgets } from './widget-sections/profile/user-profile-summary';
import {
  getBankAccountsWidgets,
  getFAQWidgets,
  getTextWidget,
  getTextWidgetConfig,
  sortAndPushWidgetsDownIfNeededOnMobile,
} from './widget-sections/utils/common-utils';
import {
  getNotificationWidgetConfig,
  getSwitchNavigatorWidgetConfigNotificationsPage,
} from './widget-sections/utils/notification-utils';

export function profileInitialPages(relevantBaasTags: RelevantBaasTags[]): PageConfig[] {
  function cloneObject(object: any) {
    return JSON.parse(JSON.stringify(object));
  }

  const additionalNavigationWidgets: WorkflowWidgetConfig[] = [];
  const addtionalPages: PageConfig[] = [];
  // if currencyCloud and If being used add regulations quick link
  if (
    relevantBaasTags.includes('if-currencycloud') &&
    relevantBaasTags.includes(PaymentProvider.INTEGRATED_FINANCE)
  ) {
    const regsQuickLink = new MultiIconQuickLinkWidgetBuilder()
      .icons({
        left: 'invoice-icon',
        right: 'arrow-right',
      })
      .template('<p class="multi-icon-header">Regulations</p>')
      .zone(APP_ZONES.PROFILE)
      .navigateToPageUrl('regulations-currencycloud')
      .role()
      .navigationOption('use-as-navigation')
      .configDisplayName('Multi Icon Quick Link: Regulations')
      .cssConfig({
        textAlignment: 'space-between',
        color: 'var(--typography-color-0)',
        borderRadiusTopLeft: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusBottomRight: 5,
        borderColor: 'var(--border-color-1)',
        borderWidth: 1,
        backGroundColor: 'var(--palette-color-6)',
        textFont: 'var(--font-family)',
        fontSize: 16,
      })
      .gridPosition({ y: 22 } as GridPosition)
      .isRemovable(false)
      .setGlobalStylingClass(true, `${ButtonTypes.MENU} ${ButtonSizeOptions.FULL_WIDTH}`)
      .build();
    additionalNavigationWidgets.push(cloneObject(regsQuickLink));
    addtionalPages.push(regulationsCurrencyCloudPage);
  }

  // We are modifying the landing page in place need to make sure we clone before the editing occurs
  const clonedProfileLandingPage = cloneObject(profileLandingPage);

  additionalNavigationWidgets.forEach((widget) => {
    clonedProfileLandingPage.widgets.push(widget);
  });

  clonedProfileLandingPage.widgets = sortAndPushWidgetsDownIfNeededOnMobile(clonedProfileLandingPage.widgets);

  const pages: PageConfig[] = [
    clonedProfileLandingPage,
    profileSummaryPage,
    editProfilePage,
    ...profileSettingsPage,
    ...EmailPhoneConfirmationPages,
  ];

  addtionalPages.forEach((page) => {
    pages.push(page);
  });

  return pages;
}

const regulationsCurrencyCloudPage: PageConfig = {
  name: 'regulations-currencycloud',
  title: 'Regulations',
  url: 'regulations-currencycloud',
  isRemovable: false,
  widgets: [
    new TextWidgetBuilder()
      .isTextEditable(false)
      .text(
        `
<div class="w-100">
  <h2>Regulations</h2>

  </br>
  <p>EEA END CUSTOMERS</p>
  <p>For clients based in the European Economic Area, payment services for {{ appData.legalEntityName }} are provided by CurrencyCloud B.V.. Registered in the Netherlands No. 72186178. Registered Office: Nieuwezijds Voorburgwal 296 - 298, Mindspace Nieuwezijds Office 001 Amsterdam. CurrencyCloud B.V. is authorised by the DNB under the Wet op het financieel toezicht to carry out the business of an electronic-money institution (Relation Number: R142701).</p>

  </br>
  <p>UNITED STATES END CUSTOMERS</p>
  <p>For clients based in the United States, payment services for {{ appData.legalEntityName }} are provided by The Currency Cloud Inc. which operates in partnership with Community Federal Savings Bank (CFSB) to facilitate payments in all 50 states in the US. CFSB is registered with the Federal Deposit Insurance Corporation (FDIC Certificate #57129). The Currency Cloud Inc is registered with FinCEN and authorised in 39 states to transmit money (MSB Registration Number: 31000206794359). Registered Office: 104 5th Avenue, 20th Floor, New York , NY 10011.</p>

  </br>
  <p>UK AND ROW END CUSTOMERS</p>
  <p>For clients based in the United Kingdom and rest of the world bar US and EU, payment services for {{ appData.legalEntityName }} are provided by The Currency Cloud Limited. Registered in England and Wales No. 06323311. Registered Office: Stewardship Building 1st Floor, 12 Steward Street London E1 6FQ. The Currency Cloud Limited is authorised by the Financial Conduct Authority under the Electronic Money Regulations 2011 for the issuing of electronic money (FRN: 900199).</p>
</div>`,
      )
      .configDisplayName('Mandatory Regulations')
      .gridPosition({ y: 0, rows: 45 } as GridPosition)
      .cssConfig({
        color: 'var(--typography-color-0)',
        textFont: 'var(--font-family)',
        display: 'flex',
        verticalAlignment: 'start',
        textAlignment: 'left',
      })
      .isRemovable(false)
      .build(),

    new BackButtonWidgetBuilder()
      .defaultBackButton()
      .gridPosition({ y: 45, rows: 2, cols: 15 } as GridPosition)
      .isRemovable(false)
      .setGlobalStylingClass(true, `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`)
      .build(),
  ],
  tags: [ProfilePagesTags.CURRENCYCLOUD_REGULATIONS],
};

const profileWidgetPageWidgets = [
  new NameWidgetBuilder()
    .configDisplayName('Name Widget')
    .cssConfig({
      isTextBold: true,
      fontSize: '14',
    })
    .gridPosition({ y: 2, rows: 8 } as GridPosition)
    .isRemovable(false)
    .build(),
  new BirthdayWidgetBuilder()
    .configDisplayName('Date of Birth')
    .cssConfig({
      isTextBold: true,
      fontSize: '14',
    })
    .gridPosition({ y: 10, rows: 4 } as GridPosition)
    .isRemovable(false)
    .build(),
  new AddressWidgetBuilder()
    .configDisplayName('Address')
    .cssConfig({
      isTextBold: true,
      fontSize: '14',
    })
    .gridPosition({
      y: 14,
      rows: 29,
    } as GridPosition)
    .build(),
  new PhoneWidgetBuilder()
    .configDisplayName('Phone')
    .cssConfig({
      isTextBold: true,
      fontSize: '14',
    })
    .gridPosition({ y: 44, rows: 4 } as GridPosition)
    .isRemovable(false)
    .build(),

  new EmailWidgetBuilder()
    .configDisplayName('Email')
    .cssConfig({
      isTextBold: true,
      fontSize: '14',
    })
    .gridPosition({ y: 48, rows: 4 } as GridPosition)
    .isRemovable(false)
    .build(),

  new FormSubmissionButtonWidgetBuilder()
    .configDisplayName('Button: Save Changes')
    .attributes({
      widgetProperties: {
        textContent: 'Save Changes',
        buttonActionType: ButtonActionType.SUBMIT_PROFILE_CHANGES_IN_PROFILE_ZONE,
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .cssConfig({
      marginTop: 0,
      verticalAlignment: 'center',
    })
    .isRemovable(false)
    .gridPosition({ y: 53, rows: 2 } as GridPosition)
    .setGlobalStylingClass(true, `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`)
    .build(),
];

const profileLandingPage = {
  name: 'profile-landing-page',
  title: 'Profile landing page',
  url: 'profile',
  widgets: profileLandingPageWidgets,
  tags: [ProfilePagesTags.DEFAULT_PAGE],
};

const profileSummaryPage: PageConfig = {
  name: 'User-Profile-Summary',
  title: 'User Profile Summary',
  widgets: userProfileWidgets,
  url: 'user-profile-summary',
  tags: [ProfilePagesTags.USER_PROFILE],
};
const editProfilePage: PageConfig = {
  name: 'Edit-User-Profile',
  title: 'Edit User Profile',
  widgets: profileWidgetPageWidgets,
  url: 'edit-user-profile',
  tags: [ProfilePagesTags.USER_PROFILE],
};
export const profileSettingsPage: PageConfig[] = [
  {
    name: 'settings',
    title: 'Settings',
    widgets: [
      new MultiIconQuickLinkWidgetBuilder()
        .icons({
          left: 'bell-notification',
          right: 'arrow-right',
        })
        .template('<p class="multi-icon-header">Notification Settings</p>')
        .zone(APP_ZONES.PROFILE)
        .role()
        .navigateToPageUrl('notification-settings-in-app')
        .navigationOption('use-as-navigation')
        .configDisplayName('Multi Icon Quick Link: Notification Settings')
        .cssConfig(multiIconWidgetCssConfig)
        .isRemovable(false)
        .gridPosition({ y: 2 } as GridPosition)
        .setGlobalStylingClass(true, `${ButtonTypes.MENU} ${ButtonSizeOptions.FULL_WIDTH}`)
        .build(),
      new MultiIconQuickLinkWidgetBuilder()
        .icons({
          left: 'change-password',
          right: 'arrow-right',
        })
        .template('<p class="multi-icon-header">Change Password</p>')
        .zone(APP_ZONES.PROFILE)
        .role()
        .navigateToPageUrl('change-password')
        .navigationOption('use-as-navigation')
        .configDisplayName('Multi Icon Quick Link: Change Password')
        .cssConfig(multiIconWidgetCssConfig)
        .isRemovable(false)
        .gridPosition({ y: 2 } as GridPosition)
        .setGlobalStylingClass(true, `${ButtonTypes.MENU} ${ButtonSizeOptions.FULL_WIDTH}`)
        .build(),
      new MultiIconQuickLinkWidgetBuilder()
        .icons({
          left: 'face-id',
          right: 'arrow-right',
        })
        .template('<p class="multi-icon-header">Authentication</p>')
        .zone(APP_ZONES.PROFILE)
        .role()
        .navigateToPageUrl('authentication')
        .navigationOption('use-as-navigation')
        .configDisplayName('Multi Icon Quick Link: Authentication')
        .cssConfig(multiIconWidgetCssConfig)
        .isRemovable(false)
        .gridPosition({ y: 6 } as GridPosition)
        .setGlobalStylingClass(true, `${ButtonTypes.MENU} ${ButtonSizeOptions.FULL_WIDTH}`)
        .build(),
    ],
    url: 'settings',
    tags: [ProfilePagesTags.SETTINGS],
  },
  {
    name: 'notification-settings-in-app',
    title: 'Notifications Settings - In-app',
    widgets: [
      getSwitchNavigatorWidgetConfigNotificationsPage(),
      getNotificationWidgetConfig(
        NotificationAlertTypeEnum.DAILY_BALANCE,
        NotificationChannelEnum.PUSH,
        DefaultToggleOption.ON,
      ),
      getTextWidget(),
      getNotificationWidgetConfig(NotificationAlertTypeEnum.TRANSACTION_ALERTS, NotificationChannelEnum.PUSH),
      getTextWidgetConfig(),
      getNotificationWidgetConfig(
        NotificationAlertTypeEnum.ADD_BENEFICIARY_ALERTS,
        NotificationChannelEnum.PUSH,
      ),
      getNotificationWidgetConfig(
        NotificationAlertTypeEnum.PAYMENTS_REQUEST_ALERTS,
        NotificationChannelEnum.PUSH,
      ),
    ],
    tags: [ProfilePagesTags.SETTINGS],
  },
  {
    name: 'notification-settings-in-sms',
    title: 'Notifications Settings - SMS',
    widgets: [
      getSwitchNavigatorWidgetConfigNotificationsPage(),
      getNotificationWidgetConfig('dailyBalance', NotificationChannelEnum.SMS),
      getTextWidget(),
      getNotificationWidgetConfig('transactionAlerts', NotificationChannelEnum.SMS),
      getTextWidgetConfig(),
      getNotificationWidgetConfig('addBeneficiaryAlerts', NotificationChannelEnum.SMS),
    ],
    tags: [ProfilePagesTags.SETTINGS],
  },
  {
    name: 'notification-settings-in-email',
    title: 'Notifications Settings - Email',
    widgets: [
      getSwitchNavigatorWidgetConfigNotificationsPage(),
      getNotificationWidgetConfig('dailyBalance', NotificationChannelEnum.EMAIL),
      getTextWidget(),
      getNotificationWidgetConfig('transactionAlerts', NotificationChannelEnum.EMAIL),
      getTextWidgetConfig(),
      getNotificationWidgetConfig('addBeneficiaryAlerts', NotificationChannelEnum.EMAIL),
    ],
    tags: [ProfilePagesTags.SETTINGS],
  },
  {
    name: 'notification-settings-in-push',
    title: 'Notifications Settings - Push',
    widgets: notificationInPushWidgets,
    tags: [ProfilePagesTags.SETTINGS],
    pageConfiguration: {
      url: 'notification-settings-in-push',
      pageType: 'dynamicGrid',
      isRemovable: false,
      mainComponentPageName: 'ParentContainerComponent',
      isBodyScrollingEnabled: true,
      ...defaultPageConfigurations,
    },
  },
  {
    name: 'authentication',
    title: 'Authentication',
    widgets: authenticationWidgets,
    tags: [ProfilePagesTags.SETTINGS],
    pageConfiguration: {
      ...defaultPageConfigurations,
      isUsingHtmlStructure: true,
    } as CommonPageConfig,
  },
  {
    name: 'enable-authentication-confirmation',
    title: 'Enable Authentication Confirmation',
    widgets: authenticationConfirmationPageWidgets('enable'),
    tags: [ProfilePagesTags.SETTINGS],
    pageConfiguration: {
      ...defaultPageConfigurations,
      isUsingHtmlStructure: true,
    } as CommonPageConfig,
  },
  {
    name: 'disable-authentication-confirmation',
    title: 'Disable Authentication Confirmation',
    widgets: authenticationConfirmationPageWidgets('disable'),
    tags: [ProfilePagesTags.SETTINGS],
    pageConfiguration: {
      ...defaultPageConfigurations,
      isUsingHtmlStructure: true,
    } as CommonPageConfig,
  },
];
const EmailPhoneConfirmationPages: PageConfig[] = [
  {
    name: 'email-confirmation',
    url: 'email-confirmation',
    title: 'Email Confirmation',
    widgets: buildOtpVerificationPageWidgetsConfig('email', 'profile'),
    tags: [ProfilePagesTags.USER_PROFILE],
  },
  {
    name: 'phone-confirmation',
    url: 'phone-confirmation',
    title: 'Phone Confirmation',
    widgets: buildOtpVerificationPageWidgetsConfig('phone', 'profile'),
    tags: [ProfilePagesTags.USER_PROFILE],
  },
];

export function buildProfileDefaultPages(
  tags: string[],
  relevantBaasTags: RelevantBaasTags[],
  type?: string,
): PageConfig[] {
  // Finds assoicated pages for each tag and then deduplicates the resulting array to only get unique pages
  let profilePagesArray = profileInitialPages(relevantBaasTags);
  if (type === 'migration') {
    const otherDefaultPages = buildProfileZoneAdditionalPages(tags);
    profilePagesArray = profilePagesArray.concat(otherDefaultPages);
  }
  return [
    ...new Set(
      tags?.flatMap((tag) => {
        return profilePagesArray?.filter((page) => {
          return page.tags.includes(tag);
        });
      }),
    ),
  ];
}

export function buildProfileZoneAdditionalPages(tags: string[]): {
  name: string;
  title: string;
  url?: string;
  widgets?: WorkflowWidgetConfig[];
  tags: string[];
  pageConfiguration: CommonPageConfig;
}[] {
  // Finds assoicated pages for each tag and then deduplicates the resulting array to only get unique pages
  const additionalProfileZonePages = buildProfileAdditionalPagesConfig();
  return [
    ...new Set(
      tags.flatMap((tag) => {
        return additionalProfileZonePages.filter((page) => {
          return page.tags.includes(tag);
        });
      }),
    ),
  ];
}

export function buildProfileAdditionalPagesConfig(): {
  name: string;
  title: string;
  url?: string;
  widgets?: WorkflowWidgetConfig[];
  tags: string[];
  pageConfiguration: CommonPageConfig;
}[] {
  const additionalProfilePages: PageConfig[] = [
    {
      name: 'notification-centre',
      title: 'Notification Centre',
      widgets: notificationCentreWidgets,
      tags: [ProfilePagesTags.NOTIFICATIONS_CENTER],
    },
    {
      name: 'notification-transaction-success',
      title: 'Notification (Transaction Success)',
      widgets: notificationTransactionSuccessWidgets,
      tags: [ProfilePagesTags.NOTIFICATIONS_CENTER],
    },
    {
      name: 'notification-transaction-failed',
      title: 'Notification (Transaction Failed)',
      widgets: notificationTransactionFailedWidgets,
      tags: [ProfilePagesTags.NOTIFICATIONS_CENTER],
    },
    {
      name: 'notification-balance',
      title: 'Notification (Balance)',
      widgets: notificationBalanceWidgets,
      tags: [ProfilePagesTags.NOTIFICATIONS_CENTER],
    },
    {
      name: 'notification-exchange-success',
      title: 'Notification (Exchange Success)',
      widgets: notificationExchangeSuccessWidgets,
      tags: [ProfilePagesTags.NOTIFICATIONS_CENTER],
    },
    {
      name: 'notification-exchange-failed',
      title: 'Notification (Exchange Failed)',
      widgets: notificationExchangeFailedWidgets,
      tags: [ProfilePagesTags.NOTIFICATIONS_CENTER],
    },
    {
      name: 'notification-deposit-arrived',
      title: 'Notification (Deposit Arrived)',
      widgets: notificationDepositArrivedWidgets,
      tags: [ProfilePagesTags.NOTIFICATIONS_CENTER],
    },
    {
      name: 'notification-beneficiary-added',
      title: 'Notification (Beneficiary Added)',
      widgets: notificationBeneficiaryAddedWidgets,
      tags: [ProfilePagesTags.NOTIFICATIONS_CENTER],
    },
    {
      name: 'notification-beneficiary-edited',
      title: 'Notification (Beneficiary Edited)',
      widgets: notificationBeneficiaryEditWidgets,
      tags: [ProfilePagesTags.NOTIFICATIONS_CENTER],
    },
    {
      name: 'notification-beneficiary-removed',
      title: 'Notification (Beneficiary Removed)',
      widgets: notificationBeneficiaryRemoveWidgets,
      tags: [ProfilePagesTags.NOTIFICATIONS_CENTER],
    },
    {
      name: 'privacy-policy',
      title: 'Privacy Policy',
      widgets: privacyPolicy,
      url: 'privacy-policy',
      tags: [ProfilePagesTags.PRIVACY_POLICY],
    },
    {
      name: 'pass-change',
      title: 'Change Password',
      widgets: passwordChangeWidgets,
      url: 'change-password',
      tags: [ProfilePagesTags.PASSWORD],
    },
    {
      name: 'pass-change-pass',
      title: 'Change Password Success',
      widgets: passwordChangePassWidgets,
      url: 'change-password-success',
      tags: [ProfilePagesTags.PASSWORD],
    },
    {
      name: 'faqs-landing',
      title: 'FAQs: Landing',
      widgets: faqsWidgets,
      url: 'faqs-landing',
      tags: [ProfilePagesTags.FAQS],
    },
    {
      name: 'faqs-create-account',
      title: 'FAQs: How do I create an account',
      widgets: faqscreatemoneyfin,
      url: 'faqs-how-do-i-create-an-account',
      tags: [ProfilePagesTags.FAQS],
    },
    {
      name: 'faqs-notifications',
      title: 'FAQs: Notification Settings',
      widgets: faqsnotification,
      url: 'faqs-notification-settings',
      tags: [ProfilePagesTags.FAQS],
    },
    {
      name: 'bank-statements-account-file-type',
      title: 'Bank Statements - Account & File Type',
      widgets: bankStatementsAccountSelectFileTypeWidgets,
      tags: [ProfilePagesTags.BANK_STATEMENTS],
    },
    {
      name: 'bank-statements-preset-downloads',
      title: 'Bank Statements Preset Downloads',
      widgets: bankStatementsPresetDownloadsWidgets,
      tags: [ProfilePagesTags.BANK_STATEMENTS],
    },
    {
      name: 'bank-statements-custom-date-range',
      title: 'Bank Statements Custom Date Range',
      widgets: bankStatementsCustomDateRangeWidgets,
      tags: [ProfilePagesTags.BANK_STATEMENTS],
    },
  ];

  const pagesArray: {
    name: string;
    title: string;
    url?: string;
    widgets?: WorkflowWidgetConfig[];
    tags: string[];
    pageConfiguration: CommonPageConfig;
  }[] = [];
  additionalProfilePages.forEach((page) => {
    pagesArray.push({
      name: formatPageName(page.title),
      title: page.title,
      url: formatPageName(page.title),
      widgets: page.widgets,
      tags: page.tags,
      pageConfiguration: defaultPageConfigurations as CommonPageConfig,
    });
  });
  return pagesArray;
}

export function onCreateBankStatementPages(pages: any) {
  if (pages[0].name === 'profile-landing-page') {
    const widgetsToAdd = getBankAccountsWidgets(getNewWidgetY(pages[0].widgets));

    // Check if the widgets already exist on the page
    const existingWidgetNames = pages[0].widgets.map(
      (widget: WorkflowWidgetConfig) => widget.widgetConfiguration.widgetConfigDisplayName,
    );
    const widgetsNotAdded = widgetsToAdd.filter(
      (widget) => !existingWidgetNames.includes(widget.widgetConfiguration.widgetConfigDisplayName),
    );

    // Push only the widgets that do not already exist
    pages[0].widgets.push(...widgetsNotAdded);
  }
}
function getNewWidgetY(widgets: WorkflowWidgetConfig[]) {
  const lastWidgetGrid = widgets[widgets.length - 1].gridPosition;
  return lastWidgetGrid ? lastWidgetGrid.y + lastWidgetGrid.rows : 0;
}
export function addFaqPages(pages: WorkflowPageConfig[]): WorkflowPageConfig[] {
  if (pages[0].name === 'profile-landing-page') {
    const widgetsToAdd = getFAQWidgets(getNewWidgetY(pages[0].widgets));

    // Check if the widgets already exist on the page
    const existingWidgetNames = pages[0].widgets.map(
      (widget: WorkflowWidgetConfig) => widget.widgetConfiguration.widgetConfigDisplayName,
    );
    const widgetsNotAdded = widgetsToAdd.filter(
      (widget) => !existingWidgetNames.includes(widget.widgetConfiguration.widgetConfigDisplayName),
    );

    // Push only the widgets that do not already exist
    pages[0].widgets.push(...widgetsNotAdded);
  }
  return pages;
}

export function removeFaqPages(pages: WorkflowPageConfig[]): WorkflowPageConfig[] {
  if (pages[0].name === 'profile-landing-page') {
    const newWidgets = pages[0].widgets.filter(
      (item: any) => item.widgetConfiguration.attributes.pageUrl !== 'faqs-landing',
    );

    pages[0].widgets = newWidgets;
  }
  return pages;
}

export function removeBankStatementPages(pages: WorkflowPageConfig[]): WorkflowPageConfig[] {
  if (pages[0].name === 'profile-landing-page') {
    const newWidgets = pages[0].widgets.filter(
      (item: any) => item.widgetConfiguration.attributes.pageUrl !== 'bank-statements-account-selection',
    );

    pages[0].widgets = newWidgets;
  }
  return pages;
}
