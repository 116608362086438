export const selectedAccountTemplate = `<div class="list-item">
<div class="row mx-0 flag-and-label-row">
  <div class="flag-wrap d-flex">{{{ currencyToFlag currency '44px' }}}</div>
  <div class="text-wrap">
    <h4 class="m-0">{{ customerName }}</h4>
    <span>{{ currencyFullName }} | {{ currency }}</span>
  </div>
</div>
<div class="amount-wrap">

  <h4>{{ currencyPipe accountBalance currencyCode=currency }}</h4>
</div>
</div>`;
