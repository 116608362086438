import { NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
  effect,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { ConfigService } from '@app/finxone-web-frontend/app/lib/services/config-service/config-service.service';
import { InputLabelOptions, TypographyKeys } from '@finxone-platform/shared/sys-config-types';
import { HlmInputDirective } from '@spartan-ng/ui-input-helm';
import { InputValidationFactory, ValidationConfig } from '../finxone-input/input-validations-factory';
import { SearchPipe } from '../pipes/searchPipe.pipe';

/**
 * FinxoneSelectDropdownComponent is a customizable dropdown component designed for use within Angular applications. It extends the InputValidationFactory and implements ControlValueAccessor, making it suitable for use with Angular forms. This component supports both single and multiple selections, custom templates for options and error messages, and integrates with form controls.
 * Documentation for this component could be found here:
 * https://dev.azure.com/finxone/Finxone%20Platform/_wiki/wikis/Finxone-Platform.wiki/357/Finxone-select-dropdown-component-usage-guide
 */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'finxone-select-dropdown',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FinxoneSelectDropdownComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div (clickOutside)="isOpen = false" class="custom-select-wrapper">
      <!-- Select Box -->
      <span
        *ngIf="placeholder && labelType !== labelTypeOptions.PLACEHOLDER"
        [class.labelActive]="isOpen"
        [class.floatingLabelWithValue]="selectedOption?.length && labelType === labelTypeOptions.FLOATING"
        [class.floatingLabel]="labelType === labelTypeOptions.FLOATING"
        class="labelText labelIdle labelConfig"
        (click)="handleLabelClick()"
      >
        {{ placeholder }}
      </span>
      <div
        hlmInput
        class="custom-select-box d-flex "
        [class.focus]="isOpen"
        [class.disabled]="isDisabled"
        (click)="toggleDropdown()"
        [class]="textStylingClass + ' ' + borderRadiusClass"
      >
        <ng-container *ngIf="!customSelectedOptionTemplate; else customSelectedOption">
          <span class="placeholder">
            @if (labelType === labelTypeOptions.PLACEHOLDER) {
            {{ selectedOption || placeholder }}
            } @else {
            {{ selectedOption }}
            }
          </span>
        </ng-container>

        <ng-template
          #customSelectedOption
          [ngTemplateOutlet]="customSelectedOptionTemplate"
          [ngTemplateOutletContext]="{ $implicit: selectedOption }"
        ></ng-template>
        <span class="arrow">
          <mat-icon svgIcon="chevron-up"></mat-icon>
        </span>
      </div>

      <!-- Dropdown -->
      @if (isOpen) {
      <div #dropdown class="dropdown" [ngClass]="borderRadiusClass">
        <!-- Search Input -->
        @if (searchEnabled) {
        <div class="search-container">
          <input
            type="text"
            class="inputField"
            [placeholder]="'Search...'"
            [value]="searchQuery"
            (input)="onSearchInputChange($event)"
          />
        </div>
        }

        <ng-container *ngIf="this.options?.length; else noOptionsTemplate">
          @if(!this.searchQuery.length) {
          <ng-container
            *ngTemplateOutlet="defaultOptionsListTemplate; context: { $implicit: options }"
          ></ng-container>
          } @else {
          <ng-container
            *ngTemplateOutlet="searchedOptionsListTemplate; context: { $implicit: options }"
          ></ng-container>
          }
        </ng-container>

        <ng-template #noOptionsTemplate>
          <div class="no-options">No options available</div>
        </ng-template>
      </div>
      }

      <!-- Error Template -->
      <div
        *ngIf="control?.invalid && (control?.dirty || control?.touched)"
        class="error-container"
        role="alert"
      >
        <ng-container *ngFor="let validator of validationConfig">
          <small *ngIf="control?.hasError(validator.type)" class="typog-xs text-error">
            {{ getErrorMessageForField(validator) }}
          </small>
        </ng-container>

        <!-- Custom Error Template -->
        <ng-container
          *ngIf="customErrorTemplate"
          [ngTemplateOutlet]="customErrorTemplate"
          [ngTemplateOutletContext]="{
            $implicit: {
              control: control,
              errors: control.errors
            }
          }"
        ></ng-container>
      </div>

      <ng-template #noOptionsTemplate>
        <div class="no-options">No options available</div>
      </ng-template>

      <ng-template #defaultOptionsListTemplate let-options>
        <div class="list-with-scroll" [ngClass]="borderRadiusClass">
          <div
            *ngFor="let option of this.options"
            class="dropdown-option"
            (click)="this.selectOption(option)"
            [ngClass]="textStylingClass"
          >
            <input
              *ngIf="this.multiple"
              type="checkbox"
              [checked]="isSelected(option)"
              (click)="$event.stopPropagation()"
            />
            <ng-container *ngIf="!customOptionTemplate; else customOption">{{
              option[optionLabelKey]
            }}</ng-container>
            <ng-template
              #customOption
              [ngTemplateOutlet]="customOptionTemplate"
              [ngTemplateOutletContext]="{ $implicit: option }"
            ></ng-template>
          </div>
        </div>
      </ng-template>

      <ng-template #searchedOptionsListTemplate let-options let-searchQuery="searchQuery">
        <div class="list-with-scroll" [ngClass]="borderRadiusClass">
          @for (option of searchedOptions; track option) {
          <div class="dropdown-option" (click)="this.selectOption(option)" [ngClass]="textStylingClass">
            <input
              *ngIf="this.multiple"
              type="checkbox"
              [checked]="isSelected(option)"
              (click)="$event.stopPropagation()"
            />
            <ng-container *ngIf="!customOptionTemplate; else customOption">{{
              option[optionLabelKey]
            }}</ng-container>
            <ng-template
              #customOption
              [ngTemplateOutlet]="customOptionTemplate"
              [ngTemplateOutletContext]="{ $implicit: option }"
            ></ng-template>
          </div>
          } @empty {
          <div class="p-3 body1 textOnSurfaceMuted">No results matching your query</div>
          }
        </div>
      </ng-template>
    </div>
  `,
  styles: `
    .custom-select-wrapper {
      position: relative;
      .custom-select-box {
        cursor: pointer;

        .placeholder {
          flex: 1;
          line-height: 2.2rem;
        }

        .arrow {
          font-size: inherit;
          float: right;
          position: relative;
          rotate: 180deg;
          display: flex;
          transition: ease all 0.3s;
        }
        &.focus {
          .arrow {
            rotate: 0deg;
          }
        }
      }

      /*
  if you change anything in dropdown class then you do need to change same thing into below path to work with append to body.
  File: apps/finxone-web-frontend/src/styles.scss
  */
      .custom-select-box.inputField ~ .dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background: var(--inputFieldIdleBackgroundColor);
        border-color: var(--inputFieldIdleBorderColor);
        border-top-width: var(--inputFieldBorderTopWidth);
        border-bottom-width: var(--inputFieldBorderBottomWidth);
        border-left-width: var(--inputFieldBorderLeftWidth);
        border-right-width: var(--inputFieldBorderRightWidth);
        z-index: 9;

        & .list-with-scroll {
          max-height: 200px;
          overflow-y: auto;
        }
        .dropdown-option {
          padding: 10px;
          display: flex;
          align-items: inherit;
          cursor: pointer;
          border: 1px solid transparent;

          &:hover {
            color: var(--inputFieldActiveTextColor);
          }

          input[type='checkbox'] {
            margin-right: 10px;
          }
        }
      }

      .no-options {
        text-align: center;
        padding: 10px;
        color: var(--color-on-surface-muted);
      }
    }
    .search-container {
      padding: 8px;
      border-bottom: 1px solid var(--color-border-color);
    }
  `,
  standalone: true,
  imports: [NgIf, NgFor, NgTemplateOutlet, NgClass, MatIcon, HlmInputDirective, SearchPipe],
})
export class FinxoneSelectDropdownComponent
  extends InputValidationFactory
  implements ControlValueAccessor, OnDestroy
{
  @ViewChild('dropdown', { static: false }) dropdownElement!: ElementRef; // Dropdown reference
  @Input() options: any[] = []; // List of options
  @Input() id = ''; // id for dropdown
  @Input() name = ''; // name for dropdown
  @Input() optionLabelKey = 'label'; // Key for displaying label
  @Input() optionValueKey = 'value'; // Key for storing value
  @Input() multiple = false; // Enable multiple selection
  @Input() placeholder = 'Select an option'; // Placeholder
  @Input() selectedOption = '';
  @Input() customOptionTemplate!: TemplateRef<any>; // Custom option template
  @Input() customSelectedOptionTemplate!: TemplateRef<any>; // Custom selected option template
  @Input() customErrorTemplate!: TemplateRef<any>; // Error template
  @Input() appendToBody = false;
  @Input() searchEnabled = false;
  public searchQuery = '';

  // Validation Configuration
  @Input() validationConfig: ValidationConfig[] = [];

  @Output() selectionChange = new EventEmitter<string>();

  public selectedValues: any[] = []; // For multiple selections
  public isOpen = false; // Dropdown visibility toggle
  public touched = false;
  public labelType: InputLabelOptions;
  public labelTypeOptions = InputLabelOptions;
  public textStylingClass: TypographyKeys;
  private cssVars: Record<string, string> = {};

  private innerValue: any = null;
  public isDisabled = false;
  public borderRadiusClass = '';
  public searchedOptions: any[];

  // Getter and setter for ControlValueAccessor
  get value(): any {
    return this.innerValue;
  }
  set value(val: any) {
    if (val !== this.innerValue) {
      this.innerValue = val;
      this.onChange(val);
      this.selectionChange.emit(val);
    }
  }

  public readonly control = new FormControl();

  // ControlValueAccessor methods
  // eslint-disable-next-line @typescript-eslint/ban-types
  onChange: Function = () => {};
  // eslint-disable-next-line @typescript-eslint/ban-types
  onTouched: Function = () => {};

  private dropdownClone!: HTMLElement | null;

  constructor(
    private readonly cd: ChangeDetectorRef,
    private readonly configService: ConfigService,
    private renderer: Renderer2,
    private elRef: ElementRef,
  ) {
    super();
    effect(() => {
      const globalCssVariables = this.configService.globalCssVariables();
      if (globalCssVariables) {
        this.cssVars = globalCssVariables;
        //get the label type and text style class from root styles
        this.labelType = this.getCssVariableValue('--inputFieldLabelType') as InputLabelOptions;
        this.textStylingClass = this.getCssVariableValue('--inputFieldTextStylingClass') as TypographyKeys;
        this.borderRadiusClass = this.getCssVariableValue('--inputFieldBorderRadiusClass');
        this.cd.detectChanges();
      }
    });
  }

  private initDropdownElement() {
    if (this.appendToBody) {
      this.appendDropdownToBody();
    } else {
      console.error('Dropdown element is not defined');
    }
  }

  private appendDropdownToBody(): void {
    this.dropdownClone = this.dropdownElement.nativeElement;

    // Append the actual dropdown element to body
    document.body.appendChild(this.dropdownClone as never);

    const parentClass = 'finxone-select-dropdown';
    this.renderer.addClass(this.dropdownClone, parentClass);
    const inputEl = this.dropdownClone?.querySelector('input');
    inputEl?.classList.add('inputField');
    // Apply styles and positioning
    const rect = this.elRef.nativeElement.getBoundingClientRect();
    this.renderer.setStyle(this.dropdownClone, 'position', 'absolute');
    this.renderer.setStyle(this.dropdownClone, 'top', `${rect.bottom}px`);
    this.renderer.setStyle(this.dropdownClone, 'left', `${rect.left}px`);
    this.renderer.setStyle(this.dropdownClone, 'z-index', '1000');
    this.renderer.setStyle(this.dropdownClone, 'width', `${rect.width}px`);

    // Trigger change detection to reflect changes
    this.cd.detectChanges();
  }

  public writeValue(value: any): void {
    this.innerValue = value;
    this.selectedValues = Array.isArray(value) ? value : [value];
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public toggleDropdown(): void {
    if (!this.isDisabled) {
      this.isOpen = !this.isOpen;
      if (!this.isOpen) {
        this.searchQuery = ''; // Reset search when closing dropdown
      }
    }
    // Use requestAnimationFrame to manage DOM updates efficiently
    requestAnimationFrame(() => {
      if (this.isOpen) {
        this.initDropdownElement(); // Initialize the dropdown element when it is open
      } else {
        this.cleanUpElement(); // Remove and clean up the dropdown element when it is closed
      }
      this.cd.detectChanges(); // Trigger Angular's change detection to ensure the UI reflects changes
    });
    this.cd.detectChanges(); // Trigger Angular's change detection to ensure the UI reflects changes
  }

  public selectOption(option: any): void {
    if (this.multiple) {
      if (this.selectedValues.includes(option[this.optionValueKey])) {
        this.selectedValues = this.selectedValues.filter((val) => val !== option[this.optionValueKey]);
      } else {
        this.selectedValues.push(option[this.optionValueKey]);
      }
      this.value = this.selectedValues;
      this.selectedOption = this.getSelectedValuesLabels();
    } else {
      this.value = option[this.optionValueKey];
      this.isOpen = false;
      this.cleanUpElement();
      this.selectedOption = this.getLabelByValue(this.value);
    }
    this.selectionChange.emit(this.value);
    this.touched = true;
    this.onTouched();
  }

  public isSelected(option: any): boolean {
    return this.multiple
      ? this.selectedValues.includes(option[this.optionValueKey])
      : this.value === option[this.optionValueKey];
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    isDisabled ? this.control.disable() : this.control.enable();
  }

  public getSelectedValuesLabels(): string {
    return this.selectedValues.map((val) => this.getLabelByValue(val)).join(', ');
  }

  public getLabelByValue(val: string): string {
    const value = this.options?.find((option) => option[this.optionValueKey] === val)?.[this.optionLabelKey];
    return value ?? '';
  }

  public getErrorMessageForField(validator: ValidationConfig): string {
    return this.getErrorMessage(validator);
  }

  private getCssVariableValue(variableName: string): string {
    return this.cssVars[variableName];
  }

  public handleLabelClick(): void {
    if (this.labelType === this.labelTypeOptions.FLOATING) {
      this.isOpen = true;
    }
  }

  private cleanUpElement() {
    if (this.dropdownClone) {
      this.renderer.removeChild(document.body, this.dropdownClone);
      this.dropdownClone = null;
    }
  }

  ngOnDestroy(): void {
    // Cleanup cloned dropdown if it exists
    this.cleanUpElement();
  }

  /**
   * Handles the search input change event by updating the search query
   * and filtering the available options based on the query.
   * Filters options by checking if either the label or value contains the search query.
   * The search is case-insensitive and works with partial matches.
   *
   * @param event - The input event from the search field.
   */
  onSearchInputChange(event: Event) {
    const target = event.target as HTMLInputElement;
    this.searchQuery = target.value;

    if (!this.options?.length) {
      this.searchedOptions = [];
      return;
    }

    const query = this.searchQuery.toLowerCase().trim();
    this.searchedOptions = this.options.filter((option) => {
      const label = String(option[this.optionLabelKey] || '').toLowerCase();
      const value = String(option[this.optionValueKey] || '').toLowerCase();
      return label.includes(query) || value.includes(query);
    });

    this.cd.markForCheck();
  }
}
