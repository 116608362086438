import { notificationBeneficiaryAddedTemplate } from '@finxone-platform/shared/default-templates';
import {
  BaseWidgetProperties,
  ButtonSizeOptions,
  ButtonTypes,
  GridPosition,
} from '@finxone-platform/shared/sys-config-types';
import { BackButtonWidgetBuilder, TextWidgetBuilder } from '../../../../widgets/example-builder';
import {
  commonNotificationHeaderTextWidget,
  commonNotificationImageWidget,
} from '../utils/notification-utils';

export const notificationBeneficiaryAddedWidgets = [
  commonNotificationImageWidget({
    configDisplayName: 'Image Widget: Users Plus Icon',
    attributes: {
      widgetProperties: {
        textContent: '',
        imageAssetUrl: 'assets/images/users-plus.svg',
      } as BaseWidgetProperties,
    },
    gridPosition: { y: 0, rows: 2 } as GridPosition,
  }),

  commonNotificationHeaderTextWidget({
    text: '<div class="w-100" ><p class="m-0">Beneficiary Added</p></div>',
    configDisplayName: 'Notification Header',
    css_config: {
      color: 'var(--palette-color-0)',
      textAlignment: 'center',
      justifyContent: 'center',
      fontSize: '20',
      isTextBold: true,
    },
    gridPosition: { y: 2, rows: 2 } as GridPosition,
  }),

  new TextWidgetBuilder()
    .text(
      '<div class="w-100"><p>A new beneficiary has been added. Please review the details for the newly added beneficiary.</p></div>',
    )
    .configDisplayName('Text Widget:Info')
    .gridPosition({ y: 4, rows: 3 } as GridPosition)
    .cssConfig({
      color: 'var(--palette-color-1)',
      textAlignment: 'center',
      justifyContent: 'center',
      fontSize: '14',
    })
    .isRemovable(false)
    .build(),

  new TextWidgetBuilder()
    .text(notificationBeneficiaryAddedTemplate)
    .configDisplayName('Beneficiary Info')
    .gridPosition({ y: 7, rows: 18 } as GridPosition)
    .cssConfig({
      backGroundColor: 'var(--palette-color-5)',
      paddingLeft: '20',
      paddingRight: '20',
      borderRadiusTopLeft: '5',
      borderRadiusTopRight: '5',
      borderRadiusBottomLeft: '5',
      borderRadiusBottomRight: '5',
      display: 'flex',
      verticalAlignment: 'start',
      textAlignment: 'left',
      fontSize: 16,
    })
    .isRemovable(false)
    .build(),

  new BackButtonWidgetBuilder()
    .defaultBackButton()
    .cssConfig({
      color: 'var(--palette-color-3)',
      backGroundColor: 'var(--palette-color-6)',
      borderColor: 'var(--palette-color-3)',
      borderWidth: '1',
      textAlignment: 'center',
      justifyContent: 'center',
    })
    .text('Back')
    .configDisplayName('Button: Back Navigation')
    .gridPosition({ y: 30, rows: 2, cols: 15 } as GridPosition)
    .isRemovable(true)
    .setGlobalStylingClass(true, `${ButtonTypes.SECONDARY} ${ButtonSizeOptions.FULL_WIDTH}`)
    .build(),
];
