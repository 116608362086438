import {
  APP_ZONES,
  RelevantBaasTags,
  WorkflowPageConfig,
  WorkflowUserType,
} from '@finxone-platform/shared/sys-config-types';
import { buildDefaultVerificationPages } from '../../defaults/pages/verification.pages';
import { commonPageComponentsForDesktopAndTablet } from '../desktop/default-zone-page-setups';
import { buildDynamicZoneConfig } from '../mobile/default-zone-page-setups';

export const zoneToTabletPageComponentDefaults = new Map<
  string,
  ({
    userType,
    relevantBaasTags,
  }: {
    userType?: WorkflowUserType[];
    relevantBaasTags: RelevantBaasTags[];
  }) => WorkflowPageConfig[]
>([
  ...commonPageComponentsForDesktopAndTablet,
  [
    APP_ZONES.VERIFICATION,
    ({ userType }) => {
      return buildDynamicZoneConfig(
        buildDefaultVerificationPages({
          userType: userType as string[],
          deviceType: 'tablet',
        }),
      );
    },
  ],
]);
