import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonSizeOptions,
  ButtonTypes,
  WorkflowWidgetConfig,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import {
  BackButtonWidgetBuilder,
  QuickLinkWidgetBuilder,
  SectionWidgetBuilder,
  SpacerWidgetBuilder,
  TextWidgetBuilder,
  TwoColumnWidgetBuilder,
} from '../../../../widgets';
import { getVerificationOrgEditIncorporationDateWidget } from '../../../../widgets/verification-organisation/verification-organisation.type';
import { createTextWidget, infoText } from './common-widgets';

const headerText = '<h6>Edit Incorporation Date</h6>';
const leftPanelInfoText = `<div>
    <div class="d-flex align-items-center">
        <div class="primaryColor d-flex">{{{ svgIcon 'circle-with-fill' '24px' '24px'}}}</div>
        <div class="ml-2 textOnSurface subtitle1">Company Information</div>
    </div>

    <div class="ml-7">
        <div class="textOnSurfaceMuted mt-2 is-left labelText">Previously as:</div>
        <div class="textOnSurface is-left body1">{{ datePipe organisationData.incorporationDate format='dd-MM-yyyy' }}
        </div>
    </div>

    <div class="d-flex align-items-center mt-7">
        <div class="d-flex primaryColor">{{{ svgIcon 'circle-without-fill' '24px' '24px'}}}</div>
        <div class="ml-2 textOnSurfaceMuted subtitle1">Incorporation Date</div>
    </div>
    
    <div class="d-flex align-items-center mt-7">
        <div class="d-flex border-color">{{{ svgIcon 'circle-without-fill' '24px' '24px'}}}</div>
        <div class="ml-2 textOnSurfaceMuted  subtitle1">Address</div>
    </div>
</div>
`;

export function mobileEditIncorporationDateWidgets(): WorkflowWidgetConfig[] {
  return [
    createTextWidget(
      'Text Widget: Header Text',
      headerText,
      {
        textAlignment: 'left',
        justifyContent: 'center',
        fontSize: '24',
        lineHeight: '32',
        textFont: 'var(--font-family)',
        isTextBold: true,
        marginTop: 35,
      },
      'h6',
    ),

    createTextWidget(
      'Text Widget: Information',
      infoText,
      {
        textAlignment: 'left',
        justifyContent: 'center',
        fontSize: '18',
        color: 'var(--color-on-surface-muted)',
        marginTop: 10,
        marginBottom: 10,
        lineHeight: '24',
        textFont: 'var(--font-family)',
      },
      'subtitle1',
    ),

    new SpacerWidgetBuilder().setHeight({ height: 10, unit: 'px' }).build(),

    getVerificationOrgEditIncorporationDateWidget(),

    new SpacerWidgetBuilder().setHeight({ height: 350, unit: 'px' }).build(),

    new QuickLinkWidgetBuilder()
      .zone(APP_ZONES.ORGANISATION_VERIFICATION)
      .navigateToPageUrl('edit-information-company-address')
      .navigationOption('use-as-navigation')
      .role()
      .configDisplayName('Quick Link')
      .text('Next')
      .isRemovable(false)
      .setGlobalStylingClass(true, `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`)
      .build(),

    new SpacerWidgetBuilder().setHeight({ height: 20, unit: 'px' }).build(),

    new BackButtonWidgetBuilder()
      .defaultBackButton()
      .isRemovable(false)
      .setGlobalStylingClass(true, `${ButtonTypes.SECONDARY} ${ButtonSizeOptions.FULL_WIDTH}`)
      .build(),
  ];
}

export function desktopEditIncorporationDateWidgets(): WorkflowWidgetConfig[] {
  return [
    new TwoColumnWidgetBuilder()
      .attributes({
        widgetProperties: {
          textContent: '',
          selectedNoOfColumn: 2,
          selectedColumnRange: [30, 70],
          selectedSplitType: 'custom',
          columnConfigs: [
            {
              outerCssConfig: `height:100vh; background: var(--color-surface);`,
              containerName: 'Column: 1',
            },
            {
              outerCssConfig: '',
              containerName: 'Column: 2',
            },
          ],
        } as unknown as BaseWidgetProperties,
        outerCss: `height: 100vh;`,
      } as unknown as WorkflowWidgetConfigurationAttributes)
      .cssConfig({
        columnGap: 100,
      })
      .childComponents([
        [
          new SectionWidgetBuilder()
            .attributes({
              outerCss: `height:100vh; background: var(--color-surface); position: relative;     align-content: center;`,
            } as unknown as WorkflowWidgetConfigurationAttributes)
            .childComponents([
              new TextWidgetBuilder()
                .text(leftPanelInfoText)
                .configDisplayName('Edit Information')
                .cssConfig({
                  textAlignment: 'center',
                  justifyContent: 'center',
                  fontSize: '18',
                  lineHeight: '32',
                  textFont: 'var(--font-family)',
                })
                .isRemovable(false)
                .build(),
            ])
            .build(),
        ],
        [
          new SectionWidgetBuilder()
            .attributes({
              outerCss: `position: relative; width: 70%; height: 100vh; align-content: center;`,
            } as unknown as WorkflowWidgetConfigurationAttributes)
            .childComponents([
              createTextWidget(
                'Text Widget: Header Text',
                headerText,
                {
                  textAlignment: 'left',
                  justifyContent: 'center',
                  fontSize: '24',
                  lineHeight: '32',
                  textFont: 'var(--font-family)',
                  isTextBold: true,
                  marginTop: 35,
                },
                'h6',
              ),

              createTextWidget(
                'Text Widget: Information',
                infoText,
                {
                  textAlignment: 'left',
                  justifyContent: 'center',
                  fontSize: '18',
                  color: 'var(--color-on-surface-muted)',
                  marginTop: 10,
                  marginBottom: 10,
                  lineHeight: '24',
                  textFont: 'var(--font-family)',
                },
                'subtitle1',
              ),

              new SpacerWidgetBuilder().setHeight({ height: 10, unit: 'px' }).build(),

              getVerificationOrgEditIncorporationDateWidget(),

              new SpacerWidgetBuilder().setHeight({ height: 40, unit: 'px' }).build(),

              new TwoColumnWidgetBuilder()
                .attributes({
                  widgetProperties: {
                    textContent: '',
                    selectedNoOfColumn: 2,
                    selectedColumnRange: [50, 50],
                    selectedSplitType: 'custom',
                  } as unknown as BaseWidgetProperties,
                } as unknown as WorkflowWidgetConfigurationAttributes)
                .cssConfig({
                  columnGap: 36,
                  columnGapUnit: '%',
                })
                .childComponents([
                  [
                    new BackButtonWidgetBuilder()
                      .defaultBackButton()
                      .isRemovable(false)
                      .setGlobalStylingClass(true, `${ButtonTypes.SECONDARY} ${ButtonSizeOptions.FULL_WIDTH}`)
                      .build(),
                  ],
                  [
                    new QuickLinkWidgetBuilder()
                      .zone(APP_ZONES.ORGANISATION_VERIFICATION)
                      .navigateToPageUrl('edit-information-company-address')
                      .navigationOption('use-as-navigation')
                      .role()
                      .configDisplayName('Quick Link')
                      .text('Next')
                      .isRemovable(false)
                      .setGlobalStylingClass(true, `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`)
                      .build(),
                  ],
                ])
                .build(),
            ])
            .build(),
        ],
      ])
      .build(),
  ];
}
