import { revenirTransactionHistoryItemTemplate } from '@finxone-platform/shared/default-templates';
import {
  APP_ZONES,
  BaseWidgetProperties,
  GridPosition,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import {
  BackButtonWidgetBuilder,
  RevenirTransactionWidgetBuilder,
  TextWidgetBuilder,
} from '../../../widgets/example-builder';
export const transactionListWidgets = [
  new BackButtonWidgetBuilder()
    .cssConfig({
      color: 'var(--palette-color-0)',
      backGroundColor: 'var(--palette-color-6)',
      borderWidth: 0,
      marginLeft: 0,
      marginright: 0,
      marginTop: 0,
      marginBottom: 0,
    })
    .gridPosition({
      rows: 1,
      cols: 2,
      y: 0,
      x: 0,
    } as GridPosition)
    .isRemovable(false)
    .build(),
  new TextWidgetBuilder()
    .text('<h3 class="normal-line-height">Transaction List</h3>')
    .configDisplayName('Text Widget: Transaction List')
    .gridPosition({ y: 1, rows: 2 } as GridPosition)
    .cssConfig({
      color: 'var(--typography-color-0)',
      textFont: 'var(--font-family)',
      display: 'flex',
      verticalAlignment: 'center',
      textAlignment: 'center',
      isTextBold: true,
      fontSize: 23,
      justifyContent: 'center',
      paddingLeft: 20,
      paddingRight: 20,
    })
    .build(),
  new RevenirTransactionWidgetBuilder()
    .isRemovable(false)
    .gridPosition({
      x: 0,
      y: 3,
      rows: 20,
      cols: 15,
    } as GridPosition)
    .cssConfig({
      disabledBgColor: 'var(--palette-color-3)',
      disabledTextColor: 'var(--palette-color-6)',
      fontSize: 14,
      textFont: 'var(--font-family)',
    })
    .attributes({
      widgetProperties: {
        textContent: ``,
      } as BaseWidgetProperties,
      titleTemplate: 'Transaction List',
      transactionItemConfig: {
        showAllTransaction: false,
        isTitleSwitchShown: true,
        transactionHistoryItemTemplate: revenirTransactionHistoryItemTemplate,
        zoneToNavigate: APP_ZONES.REVENIR_ZONE,
        urlToNavigate: 'trips-home-page',
      },
    } as unknown as WorkflowWidgetConfigurationAttributes)
    .build(),
];
