import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { APP_ZONES } from '@finxone-platform/shared/sys-config-types';
import { KeycloakService } from 'keycloak-angular';
import { AccountService } from '../../services/account-service/account-service.service';
import { DeviceTypeService } from '../../services/device-type/device-type.service';
import { formatNameAsUrl } from '../../utils/zone-url.utils';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  constructor(
    protected keycloakService: KeycloakService,
    private _router: Router,
    private accountService: AccountService,
    private deviceTypeService: DeviceTypeService,
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const flutterWebView = (window as any).flutter_inappwebview;

    if (localStorage.getItem('refreshToken') && localStorage.getItem('token')) {
      this.accountService.setRoleInfo(localStorage.getItem('token') as string);
      this._router.navigateByUrl('/feature-redirect');
    } else {
      if (this.deviceTypeService.currentdeviceType == 'desktop') {
        this.keycloakService.login({
          redirectUri: window.location.origin,
        });
      } else {
        if (flutterWebView) {
          flutterWebView
            .callHandler('getRememberMeUsername', [])
            .then((username: null | string) => {
              if (username) {
                this.keycloakService.login({
                  redirectUri: window.location.origin + '/feature-redirect',
                  loginHint: username,
                });
              } else {
                this.mobileRedirect();
              }
            })
            .catch(() => {
              console.error('Failed to fetch username from mobile app');
              this.mobileRedirect();
            });
        } else {
          this.mobileRedirect();
        }
      }
    }
  }

  private mobileRedirect() {
    if (localStorage.getItem('firstTime') == null) {
      localStorage.setItem('firstTime', 'true');
      this._router.navigate(['zones', formatNameAsUrl(APP_ZONES.SIGNED_OUT_FIRST_INTRODUCTION)]);
      return;
    }
    this._router.navigate(['zones', formatNameAsUrl(APP_ZONES.SIGNED_OUT_INTRODUCTION)]);
  }

  login(): void {
    this.keycloakService.login({
      redirectUri: window.location.origin + '/feature-redirect',
    });
  }
}
