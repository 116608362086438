export const clientDetailsTemplate = `
<div class="row mx-0 w-100">
  <div class="col-2-md col-12 p-1 border-right-secondary">
    <div class="text-label">Client Name</div>
    <div class="text-value">
      <b>{{#if selectedClientDetails.name}}{{ selectedClientDetails.name }}{{else}} -- {{/if}}</b>
    </div>
  </div>
  <div class="col-2-md col-12 p-1">
    <div class="text-label">Number of accounts linked</div>
    <div class="text-value">
      <b>{{#if (stringPropertySet selectedClientDetails.accountsLinked)}}{{ selectedClientDetails.accountsLinked }}{{else}} -- {{/if}}</b>
    </div>
  </div>
  <div class="col-2-md col-12 p-1">
    <div class="text-label">Client type</div>
    <div class="text-value">
      {{#if (eq selectedClientDetails.clientType "personal" null) }}
        Individual {{else}} Business
      {{/if}}
    </div>
  </div>
  <div class="col-2-md col-12 p-1">
    <div class="text-label">Group assigned</div>
    <div class="text-value">
      <b>{{#if (stringPropertySet selectedClientDetails.feeGroupName)}}{{ selectedClientDetails.feeGroupName }}{{else}} -- {{/if}}</b>
    </div>
  </div>
  <div class="col-2-md col-12 p-1">
    <div class="text-label">Address</div>
    <div class="text-value">
      <b>
      {{#if selectedClientDetails.attributes.address}}
        {{ selectedClientDetails.address1 }} {{ selectedClientDetails.address2 }} {{ selectedClientDetails.address3 }} {{ selectedClientDetails.address4 }}, {{ selectedClientDetails.city }}, {{ selectedClientDetails.state }}, {{ selectedClientDetails.country }}, {{ selectedClientDetails.postalCode }}
      {{else}} -- {{/if}}</b>
    </div>
  </div>
  {{#if selectedClientDetails.attributes.phoneNumber}}
  <div class="col-2-md col-12 p-1">
    <div class="text-label">Phone number</div>
    <div class="text-value">
      <b>{{selectedClientDetails.attributes.phoneNumber}}{</b>
    </div>
  </div>
  {{/if}}
  {{#if (eq selectedClientDetails.attributes.clientType 'corporate') }}
  <div class="col-2-md col-12 p-1">
    <div class="text-label">Identification ID</div>
    <div class="text-value">
      <b>{{selectedClientDetails.attributes.identificationNumber}}</b>
    </div>
  </div>
  {{/if}}
</div>
`;
