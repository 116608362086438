import {
  BaseWidgetProperties,
  Constants,
  GridPosition,
  WidgetNames,
  WidgetTitles,
  WorkflowWidgetConfig,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import { ImageWidgetBuilder, TextWidgetBuilder } from '../../../../widgets/example-builder';

export const notificationCentreWidgets = [
  new ImageWidgetBuilder()
    .configDisplayName('Image Widget: Notification Icon')
    .cssConfig({
      selectedobjectfit: 'contain',
    })
    .gridPosition({ y: 0, x: 2, rows: 2, cols: 2 } as GridPosition)
    .attributes({
      widgetProperties: {
        textContent: '',
        imageAssetUrl: 'assets/icon-library/bell-icon.svg',
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .build(),
  new TextWidgetBuilder()
    .text('<div class="w-100"><p class="m-0">Notification Centre</p></div>')
    .configDisplayName('Notification Centre Header')
    .gridPosition({ y: 0, x: 4, rows: 2, cols: 10 } as GridPosition)
    .cssConfig({
      color: 'var(--palette-color-0)',
      textAlignment: 'left',
      justifyContent: 'center',
      fontSize: '20',
      textFont: 'var(--font-family)',
      isTextBold: 'bold',
    })
    .isRemovable(false)
    .build(),
  {
    widgetTypeConfig: 'new',
    uniqueId: WidgetNames.NOTIFICATION_LIST_WIDGET,
    name: WidgetNames.NOTIFICATION_LIST_WIDGET,
    widgetConfiguration: {
      isRemovable: false,
      widgetDisplayName: '',
      widgetConfigDisplayName: WidgetTitles.NOTIFICATION_LIST_WIDGET,
      attributes: {
        widgetProperties: {
          textContent:
            '<div class="text-value" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{notificationTitle}}</div>\n' +
            '<div class="text-label" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{notificationDescription}}</div>\n' +
            '<div class="text-label" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">Notification received at {{notificationTime}} on {{notificationDate}}.</div>',
        },
        elementsConfig: [],
        pageSize: 10,
        pagingEnabled: true,
        css_config: {
          ...Constants.defaultBaseCssConfig,
          marginBottom: 16,
          paddingTop: 10,
          paddingLeft: 10,
          paddingRight: 10,
          paddingBottom: 10,
          borderRadiusTopLeft: 5,
          borderRadiusBottomLeft: 5,
          borderRadiusTopRight: 5,
          borderRadiusBottomRight: 5,
          borderWidth: 1,
          borderColor: 'var(--border-color-1)',
          alertColor: 'var(--alerts-color-1)',
        },
      },
    },
    gridPosition: {
      x: 0,
      y: 3,
      rows: 28,
      cols: 15,
      dragEnabled: true,
      resizeEnabled: true,
    },
    widgetIconPath: '',
  } as WorkflowWidgetConfig,
];
