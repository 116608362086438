import { finxoneKeycloakEnGBMessages } from '@app/translations/locale-variables/keycloak-locales/en-gb/finxone-messages';
import { CredentialType } from '@finxone-platform/shared/sys-config-types';

//TODO: Will need to expland on this to include all the different confirmation types and possibly the confirmation action, ie login with app pin will be different to confirming with app pin
export function getConfirmationLocaleVariant(localeContent: any, confirmation: CredentialType) {
  switch (confirmation) {
    case CredentialType.SMS:
      return {
        title: localeContent?.smsAuthTitle ?? finxoneKeycloakEnGBMessages.content['smsAuthTitle'],
        subtitle: localeContent?.smsAuthLabel ?? finxoneKeycloakEnGBMessages.content['smsAuthLabel'],
        resendFrontText:
          localeContent?.resendFrontText ?? finxoneKeycloakEnGBMessages.content['resendFrontText'],
        resendFrontTimerText:
          localeContent?.resendFrontTimerText ?? finxoneKeycloakEnGBMessages.content['resendFrontTimerText'],
        resendBackText:
          localeContent?.resendBackText ?? finxoneKeycloakEnGBMessages.content['resendBackText'],
        resendLinkText:
          localeContent?.resendLinkText ?? finxoneKeycloakEnGBMessages.content['resendLinkText'],
        doBack: localeContent?.doBack ?? finxoneKeycloakEnGBMessages.content['doBack'],
        doTryAnotherWay:
          localeContent?.doTryAnotherWay ?? finxoneKeycloakEnGBMessages.content['doTryAnotherWay'],
        codeExpired:
          localeContent?.smsAuthCodeExpired ?? finxoneKeycloakEnGBMessages.content['smsAuthCodeExpired'],
        codeInvalid:
          localeContent?.smsAuthCodeInvalid ?? finxoneKeycloakEnGBMessages.content['smsAuthCodeInvalid'],
      };
    case CredentialType.EMAIL:
      return {
        title:
          localeContent?.emailOtpAuthCodeTitle ??
          finxoneKeycloakEnGBMessages.content['emailOtpAuthCodeTitle'],
        subtitle:
          localeContent?.emailOtpAuthLabel ?? finxoneKeycloakEnGBMessages.content['emailOtpAuthLabel'],
        resendFrontText:
          localeContent?.resendFrontText ?? finxoneKeycloakEnGBMessages.content['resendFrontText'],
        resendFrontTimerText:
          localeContent?.resendFrontTimerText ?? finxoneKeycloakEnGBMessages.content['resendFrontTimerText'],
        resendBackText:
          localeContent?.resendBackText ?? finxoneKeycloakEnGBMessages.content['resendBackText'],
        resendLinkText:
          localeContent?.resendLinkText ?? finxoneKeycloakEnGBMessages.content['resendLinkText'],
        doBack: localeContent?.doBack ?? finxoneKeycloakEnGBMessages.content['doBack'],
        doTryAnotherWay:
          localeContent?.doTryAnotherWay ?? finxoneKeycloakEnGBMessages.content['doTryAnotherWay'],
        codeExpired:
          localeContent?.emailAuthCodeExpired ?? finxoneKeycloakEnGBMessages.content['emailAuthCodeExpired'],
        codeInvalid:
          localeContent?.emailAuthCodeInvalid ?? finxoneKeycloakEnGBMessages.content['emailAuthCodeInvalid'],
      };
    default:
      return {
        title: localeContent?.loginEnterPinTitle ?? finxoneKeycloakEnGBMessages.content['loginEnterPinTitle'],
        subtitle:
          localeContent?.loginEnterPinSubTitle ??
          finxoneKeycloakEnGBMessages.content['loginEnterPinSubTitle'],
        doSubmit: localeContent?.doSubmit ?? finxoneKeycloakEnGBMessages.content['doSubmit'],
        doBack: localeContent?.doBack ?? finxoneKeycloakEnGBMessages.content['doBack'],
        forgotPassword:
          localeContent?.loginEnterPinForgotText ??
          finxoneKeycloakEnGBMessages.content['loginEnterPinForgotText'],
      };
  }
}
