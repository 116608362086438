import { notificationDepositArrivalInfoTemplate } from '@finxone-platform/shared/default-templates';
import {
  BaseWidgetProperties,
  ButtonSizeOptions,
  ButtonTypes,
  DocumentMonthsUpdate,
  DocumentType,
  DownloadDocumentType,
  GridPosition,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import {
  BackButtonWidgetBuilder,
  DownloadDocumentWidgetBuilder,
  ImageWidgetBuilder,
  TextWidgetBuilder,
} from '../../../../widgets/example-builder';
import { defaultPdfCssConfig } from '../utils/common-utils';

export const notificationDepositArrivedWidgets = [
  new ImageWidgetBuilder()
    .configDisplayName('Image Widget: Money Icon')
    .cssConfig({
      selectedobjectfit: 'contain',
    })
    .gridPosition({ y: 0, rows: 2 } as GridPosition)
    .attributes({
      widgetProperties: {
        textContent: '',
        imageAssetUrl: 'assets/images/money.svg',
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .build(),

  new TextWidgetBuilder()
    .text('<div class="w-100" ><p class="m-0">Money Received</p></div>')
    .configDisplayName('Notification Header')
    .gridPosition({ y: 2, rows: 2 } as GridPosition)
    .cssConfig({
      color: 'var(--palette-color-0)',
      textAlignment: 'center',
      justifyContent: 'center',
      fontSize: '20',
      isTextBold: true,
    })
    .isRemovable(false)
    .build(),

  new TextWidgetBuilder()
    .text(
      '<div class="w-100"><p>A new deposit has been added to your <span> {{organisationData.name}} </span> account.</p></div>',
    )
    .configDisplayName('Text Widget:Info')
    .gridPosition({ y: 4, rows: 3 } as GridPosition)
    .cssConfig({
      color: 'var(--palette-color-1)',
      textAlignment: 'center',
      justifyContent: 'center',
      fontSize: '14',
    })
    .isRemovable(false)
    .build(),

  new TextWidgetBuilder()
    .text(notificationDepositArrivalInfoTemplate)
    .configDisplayName('Deposit Arrived Info')
    .gridPosition({ y: 7, rows: 17 } as GridPosition)
    .cssConfig({
      backGroundColor: 'var(--palette-color-5)',
      paddingLeft: '20',
      paddingRight: '20',
      borderRadiusTopLeft: '5',
      borderRadiusTopRight: '5',
      borderRadiusBottomLeft: '5',
      borderRadiusBottomRight: '5',
      display: 'flex',
      verticalAlignment: 'start',
      textAlignment: 'left',
      fontSize: 16,
    })
    .isRemovable(false)
    .build(),

  new DownloadDocumentWidgetBuilder()
    .attributes({
      documentType: DownloadDocumentType.PROOF_OF_PAYMENT,
      timeInterval: DocumentMonthsUpdate.NONE,
      downloadDocumentAs: DocumentType.PDF,
      textInputHeader: 'Download',
      elementsConfig: [],
      widgetProperties: {
        textContent: 'Download Document',
      },
      css_config: {
        ...defaultPdfCssConfig,
        borderWidth: 0,
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
      },
    })
    .gridPosition({ y: 27, rows: 2 } as GridPosition)
    .configDisplayName('Download Document')
    .isRemovable(false)
    .build(),

  new BackButtonWidgetBuilder()
    .defaultBackButton()
    .cssConfig({
      color: 'var(--palette-color-3)',
      backGroundColor: 'var(--palette-color-6)',
      borderColor: 'var(--palette-color-3)',
      borderWidth: '1',
      textAlignment: 'center',
      justifyContent: 'center',
    })
    .text('Close')
    .configDisplayName('Button: Back Navigation')
    .gridPosition({ y: 30, rows: 2, cols: 15 } as GridPosition)
    .isRemovable(true)
    .setGlobalStylingClass(true, `${ButtonTypes.SECONDARY} ${ButtonSizeOptions.FULL_WIDTH}`)
    .build(),
];
