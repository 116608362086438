export const notificationTransactionAccountInfoTemplate = `<div class="w-100 h-100">
<div class="is-vertical-align">
    <div class="ben-label">Full Name</div>
    <div class="ben-value font-weight-bold">{{notification.selectedNotification.receiver.name}}</div>
</div>
<hr class="my-4 mx-1">
<div class="is-space-between">
    <div class="is-vertical-align">
        <div class="is-vertical-align align-left">
            <div class="ben-label">Sort code</div>
            <div class="ben-value">{{notification.selectedNotification.receiver.sortCode}}</div>
        </div>
    </div>
    <div class="is-vertical-align text-right">
        <div class="ben-label">Amount</div>
        <div class="ben-value"> {{ currencyPipe notification.selectedNotification.amount currencyCode=notification.selectedNotification.currency display="symbol-narrow" }}</div>
    </div>
</div>
<div class="is-space-between my-4">
    <div class="is-vertical-align align-left">
        <div class="ben-label">Account Number</div>
        <div class="ben-value">{{notification.selectedNotification.receiver.accountNumber}}</div>
    </div>
    <div class="is-vertical-align text-right">
        <div class="ben-label">Date of transaction</div>
        <div class="ben-value">{{datePipe notification.selectedNotification.dateOfTransaction format='d MMM y'}}</div>
    </div>
</div>
<div class="is-space-between my-4">
    <div class="is-vertical-align">
        <div class="is-vertical-align align-left">
            <div class="ben-label">Time</div>
            <div class="ben-value">{{datePipe notification.selectedNotification.dateOfTransaction format='h:mm a'}}</div>
        </div>
    </div>
</div>
</div>`;
