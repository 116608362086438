import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { ResetFormDataAction } from '../../../actions/form-submission.action';
import { redirectToPage } from '../cta-button-actions.utils';

/**
 * Resets the form state for assigning a persona and redirects to a specified page.
 *
 * This function utilizes the provided `Injector` to access the `Store` and `Router` services.
 * It dispatches a `ResetFormDataAction` to reset the form data state and then calls
 * `redirectToPage` to navigate to a new page based on the given `widgetProperties`.
 *
 * @param widgetProperties - The properties of the widget, including navigation details.
 * @param injector - The Angular `Injector` used to retrieve necessary services.
 */
export function resetAssignPersonaFormStateAndRedirect(
  widgetProperties: BaseWidgetProperties,
  injector: Injector,
) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  store.dispatch(new ResetFormDataAction());
  redirectToPage(router, widgetProperties, injector);
}
