export const clientDetailsTemplateRemoveClientPage = `
    <div class="w-100">
        <div class="col-12 pb-2 text-center">
            <div class="text-label">Client Name</div>
            <div class="text-value">
                <b>{{#if selectedClientDetails.name}}{{ selectedClientDetails.name }}{{else}} -- {{/if}}</b>
            </div>
        </div>
        <hr />
        <div class="row mx-0 w-100">
            <div class="col-2-md col-12 p-1">
                <div class="text-label">Client type</div>
                <div class="text-value">
                {{#if (eq selectedClientDetails.clientType "personal" null) }}
                    Individual {{else}} Business
                {{/if}}
                </div>
            </div>
            <div class="col-3-md col-12 p-1">
                <div class="text-label">Client Since:</div>
                <div class="text-value">
                <b>{{#if (stringPropertySet selectedClientDetails.clientSince)}}{{ datePipe selectedClientDetails.clientSince format="d MMM y" }}{{else}} -- {{/if}}</b>
                </div>
            </div>
            <div class="col-3-md col-12 p-1">
                <div class="text-label">Number of accounts linked</div>
                <div class="text-value">
                <b>{{#if (stringPropertySet selectedClientDetails.accountsLinked)}}{{ selectedClientDetails.accountsLinked }}{{else}} -- {{/if}}</b>
                </div>
            </div>
            <div class="col-4-md col-12 p-1">
                <div class="text-label">Address</div>
                <div class="text-value">
                <b>
                {{#if selectedClientDetails.attributes.address}}
                    {{ selectedClientDetails.address1 }} {{ selectedClientDetails.address2 }} {{ selectedClientDetails.address3 }} {{ selectedClientDetails.address4 }}, {{ selectedClientDetails.city }}, {{ selectedClientDetails.state }}, {{ selectedClientDetails.country }}, {{ selectedClientDetails.postalCode }}
                {{else}} -- {{/if}}</b>
                </div>
            </div>
        </div>
    </div>
`;
