import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonActionType,
  GridPosition,
  ListingTypeEnum,
  WorkflowWidgetConfig,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import {
  BackButtonWidgetBuilder,
  FeeClientListingWidgetBuilder,
  FormSubmissionButtonWidgetBuilder,
} from '../../../defaults/widgets/example-builder';
import { inputSettings } from '../../../defaults/widgets/payments/payments.type';
import { defaultTableCheckBoxCssConfig } from '../../../defaults/widgets/workflow-ui-constants.v2.type';
import {
  generateTextWidget,
  getQuickLinkWidget,
} from '../../../defaults/zones/payments/common-widgets-utils';
import { generateSwitchNavigatorConfig } from './client-level-fee-page.utils';

export const tableColumns = [
  {
    label: 'Fee Name',
    value: `feeName`,
    handleBarKey: '<span class="text-capitalize">{{feeName}}</span>',
    selected: true,
  },
  {
    label: 'Currency',
    value: `currency`,
    handleBarKey: '{{feeCurrency}}',
    selected: true,
  },
  {
    label: 'Amount/Percentage',
    value: `amountPercentage`,
    handleBarKey: `{{ feeChargeValue feeCharge unitChargeType feeCurrency }}`,
    selected: true,
  },
  {
    label: 'Unit Charge',
    value: `unitCharge`,
    handleBarKey: '{{ valueToTitleChange unitChargeType}}',
    selected: true,
  },
  {
    label: 'Frequency',
    value: `frequency`,
    handleBarKey: '<span class="text-capitalize">{{frequencyType}}</span>',
    selected: true,
  },
  {
    label: 'Effective Date',
    value: `effectiveDate`,
    handleBarKey: `{{ datePipe effectiveDate  format='d MMM y'}}`,
    selected: true,
  },
  {
    label: 'Fee Charged',
    value: `feeCharged`,
    handleBarKey:
      '<span class="text-capitalize">{{currencyPipe feeChargedAmount currencyCode=feeCurrency display="symbol-narrow"}}</span>',
    selected: true,
  },
  {
    label: 'Status',
    value: `status`,
    handleBarKey: `<span class="text-capitalize">{{ status }}</span>`,
    selected: true,
  },
];
function commonHeaderWidget() {
  const commonHeaderWidget = [
    generateTextWidget({
      text: `{{ selectedClientDetails.name }} Fee History - Fee Management`,
      position: { x: 1, y: 0, rows: 1, cols: 28 } as GridPosition,
      displayName: 'Header Text',
      styles: {
        fontSize: 24,
        isTextBold: true,
        verticalAlignment: 'start',
        textAlignment: 'left',
        noAlignment: true,
      },
      isRemovable: true,
    }),

    generateTextWidget({
      text: `The list consists of all the fees charged against the client's account till date.`,
      position: { x: 1, y: 1, rows: 1, cols: 31 } as GridPosition,
      displayName: 'Information Content',
      styles: {
        verticalAlignment: 'start',
        textAlignment: 'left',
        fontSize: 20,
        textFont: 'var(--font-family)',
        color: 'var(--typography-color-1)',
      },
      isRemovable: true,
    }),

    new BackButtonWidgetBuilder()
      .defaultBackButton()
      .configDisplayName('Back Navigation Button')
      .text('Back')
      .cssConfig({
        color: 'var(--palette-color-3)',
        backGroundColor: 'var(--palette-color-6)',
        borderWidth: 1,
        borderColor: 'var(--palette-color-3)',
        fontSize: 14,
        textAlign: 'center',
        verticalAlignment: 'center',
      })
      .gridPosition({
        y: 0,
        x: 32,
        rows: 1,
        cols: 4,
        resizeEnabled: true,
        dragEnabled: true,
      })
      .build(),

    getQuickLinkWidget({
      text: 'All Fee History',
      position: {
        x: 37,
        y: 0,
        rows: 1,
        cols: 5,
      } as GridPosition,
      pageUrl: 'charge-history-fee',
      displayName: 'Quick Link: View Fee History',
      styles: {
        borderWidth: 1,
        color: 'var(--palette-color-3)',
        fontSize: '16',
        backGroundColor: 'var(--palette-color-6)',
        textAlignment: 'center',
        justifyContent: 'center',
        borderColor: 'var(--palette-color-3)',
        borderStyle: 'solid',
        borderRadiusTopLeft: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusBottomRight: 5,
        disabledTextColor: 'var(--palette-color-6)',
        disabledBgColor: 'var(--palette-color-3)',
      },
      zone: APP_ZONES.FEE_MANAGEMENT,
      isRemovable: true,
    }),
    new FormSubmissionButtonWidgetBuilder()
      .configDisplayName('Button: Revert')
      .attributes({
        widgetProperties: {
          textContent: 'Revert',
          zoneToNavigate: APP_ZONES.FEE_MANAGEMENT,
          urlToNavigate: 'clients-fee-history',
          buttonActionType: ButtonActionType.REVERT_FEE,
        } as BaseWidgetProperties,
      } as WorkflowWidgetConfigurationAttributes)
      .cssConfig({
        marginTop: 0,
        disabledTextColor: 'var(--palette-color-6)',
        disabledBgColor: 'var(--palette-color-3)',
      })
      .gridPosition({
        x: 43,
        y: 0,
        rows: 1,
        cols: 4,
      } as GridPosition)
      .build(),
  ];
  return commonHeaderWidget;
}

export function generateClientLevelFeeHistoryPageWidgets(): WorkflowWidgetConfig[] {
  return [
    ...commonHeaderWidget(),
    generateTextWidget({
      text: `
      <div>
        <div class="text-label">Client Name</div>
        <div class="text-value">
          <b>{{#if selectedClientDetails.name}}{{ selectedClientDetails.name }}{{else}} -- {{/if}}</b>
        </div>
        </div>
      `,
      position: { x: 1, y: 5, rows: 2, cols: 8 } as GridPosition,
      displayName: 'Information Content',
      styles: {
        verticalAlignment: 'center',
        paddingLeft: 9,
        paddingRight: 9,
        borderWidth: 1,
        borderColor: 'var(--border-color-1)',
        borderRadiusTopLeft: 6,
        borderRadiusTopRight: 6,
        borderRadiusBottomRight: 6,
        borderRadiusBottomLeft: 6,
        textAlignment: 'left',
        fontSize: '16',
        lineHeight: '24',
        textFont: 'var(--font-family)',
        color: 'var(--typography-color-1)',
      },
      isRemovable: true,
    }),
    generateTextWidget({
      text: `
      <div>
        <div class="text-label">Number of accounts</div>
        <div class="text-value">
          <b>{{#if (stringPropertySet selectedClientDetails.accountsLinked)}}{{ selectedClientDetails.accountsLinked }}{{else}} -- {{/if}}</b>
        </div>
        </div>
      `,
      position: { x: 10, y: 5, rows: 2, cols: 8 } as GridPosition,
      displayName: 'Information Content',
      styles: {
        verticalAlignment: 'center',
        paddingLeft: 9,
        paddingRight: 9,
        borderWidth: 1,
        borderColor: 'var(--border-color-1)',
        borderRadiusTopLeft: 6,
        borderRadiusTopRight: 6,
        borderRadiusBottomRight: 6,
        borderRadiusBottomLeft: 6,
        textAlignment: 'left',
        fontSize: '16',
        lineHeight: '24',
        textFont: 'var(--font-family)',
        color: 'var(--typography-color-1)',
      },
      isRemovable: true,
    }),
    generateTextWidget({
      text: `
      <div>
        <div class="text-label">Group assigned</div>
        <div class="text-value">
          <b>{{#if (stringPropertySet selectedClientDetails.feeGroupName)}}{{ selectedClientDetails.feeGroupName }}{{else}} -- {{/if}}</b>
        </div>
        </div>
      `,
      position: { x: 19, y: 5, rows: 2, cols: 8 } as GridPosition,
      displayName: 'Information Content',
      styles: {
        verticalAlignment: 'center',
        paddingLeft: 9,
        paddingRight: 9,
        borderWidth: 1,
        borderColor: 'var(--border-color-1)',
        borderRadiusTopLeft: 6,
        borderRadiusTopRight: 6,
        borderRadiusBottomRight: 6,
        borderRadiusBottomLeft: 6,
        textAlignment: 'left',
        fontSize: '16',
        lineHeight: '24',
        textFont: 'var(--font-family)',
        color: 'var(--typography-color-1)',
      },
      isRemovable: true,
    }),
    generateTextWidget({
      text: `
      <div>
        <div class="text-label">Client type</div>
        <div class="text-value">
          {{#if (eq selectedClientDetails.clientType "personal" null) }}
            Individual {{else}} Business
          {{/if}}
        </div>
        </div>
      `,
      position: { x: 28, y: 5, rows: 2, cols: 8 } as GridPosition,
      displayName: 'Information Content',
      styles: {
        verticalAlignment: 'center',
        paddingLeft: 9,
        paddingRight: 9,
        borderWidth: 1,
        borderColor: 'var(--border-color-1)',
        borderRadiusTopLeft: 6,
        borderRadiusTopRight: 6,
        borderRadiusBottomRight: 6,
        borderRadiusBottomLeft: 6,
        textAlignment: 'left',
        fontSize: '16',
        lineHeight: '24',
        textFont: 'var(--font-family)',
        color: 'var(--typography-color-1)',
      },
      isRemovable: true,
    }),
    generateSwitchNavigatorConfig(),
    new FeeClientListingWidgetBuilder()
      .text('Listing Widget')
      .configDisplayName(`Listing Widget`)
      .gridPosition({ x: 1, y: 8, rows: 17, cols: 46 } as GridPosition)
      .cssConfig({
        color: 'var(--palette-color-1)',
        textAlignment: 'left',
        justifyContent: 'left',
        fontSize: '16',
        borderWidth: 1,
        borderColor: 'var(--palette-color-2)',
        backGroundColor: 'var(--palette-color-6)',
        borderStyle: 'solid',
        borderRadiusUnit: 'px',
      })
      .attributes({
        widgetProperties: {
          icon: 'search',
          textContent: ``,
          showEntity: false,
          memuOptions: [
            { label: 'Revert', value: 'revert-fee' },
            { label: 'Retry', value: 'retry' },
            { label: 'View Reason', value: 'view-reason' },
          ],
          listingType: ListingTypeEnum.CLIENT_FEE_CHARGED_HISTORY,
        } as BaseWidgetProperties,
        tableCheckboxSettings: {
          enableCheckbox: true,
          inputFieldSettings: { ...defaultTableCheckBoxCssConfig },
        },
        columns: tableColumns,
      } as unknown as WorkflowWidgetConfigurationAttributes)
      .inputSetting({
        ...inputSettings,
        css_config: {
          ...inputSettings.css_config,
          inputWidth: 120,
        },
        label: 'Search',
        isIconAllowed: true,
      })
      .isRemovable(false)
      .build(),
    generateTextWidget({
      text: 'Note: The fee cannot be reverted if the charged date exceeds a time period of 7 days.',
      position: { x: 0, y: 26, rows: 1, cols: 32 } as GridPosition,
      displayName: 'Information Content',
      styles: {
        verticalAlignment: 'start',
        textAlignment: 'left',
        fontSize: '16',
        paddingLeft: 33,
        paddingTop: 5,
        lineHeight: '24',
        textFont: 'var(--font-family)',
        color: 'var(--typography-color-0)',
      },
      isRemovable: true,
    }),
  ];
}
