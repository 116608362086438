import {
  ClassProvider,
  ConstructorProvider,
  ExistingProvider,
  FactoryProvider,
  Injector,
  StaticClassProvider,
  TypeProvider,
  ValueProvider,
} from '@angular/core';
import {
  APP_ZONES,
  ContainerType,
  NewUiZoneWidgetConfig,
  ProfileStateModel,
  ProviderClass,
  SupportedDevice,
  UiPageConfig,
  UiZoneWidgetConfig,
  UserInfoType,
} from '@finxone-platform/shared/sys-config-types';
import Handlebars from 'handlebars';

import jwt_decode from 'jwt-decode';

import { ActivatedRoute, ParamMap } from '@angular/router';

import { IconCacheService } from '@finxone-platform/shared/services';
import { Store } from '@ngxs/store';
import { combineLatest, map, Observable, take } from 'rxjs';
import { GetOrganisation } from '../../lib/actions/organisation.action';
import { SetActiveOrgId } from '../../lib/actions/user-profile.action';
import { ConfigService } from '../../lib/services/config-service/config-service.service';
import { CountriesCurrenciesService } from '../../lib/services/countries-currencies-service/countries-currencies.service';
import { isAuthenticatedRoute } from '../../lib/utils/auth-checks.utils';
import {
  setOverFlowAutoOnBodyTag,
  setOverFlowHiddenOnBodyTag,
} from '../../lib/utils/scrolling-utils/scrolling.utils';
import { buildHandleBarModel } from '../../lib/utils/template/model-builder.utils';
import { formatNameAsUrl } from '../../lib/utils/zone-url.utils';
import { PageConfigurationProvider } from './page-configuration.provider';
import { ZonePageUrlProvider } from './zone-url-provider';

export function createInjector(
  widget: UiZoneWidgetConfig,
  page: UiPageConfig,
  injector: Injector,
  configService: ConfigService,
  zonePageUrlProvider: ZonePageUrlProvider,
  user: UserInfoType,
  userProfile?: ProfileStateModel,
  // Try remove as this is used for page states which we don't really use anymore
  widgetSection?: {
    widgetName: string;
    widgetIndex?: number;
    isSectionPopup?: boolean;
  },
  extraProviders?: (
    | ValueProvider
    | ExistingProvider
    | StaticClassProvider
    | ConstructorProvider
    | FactoryProvider
    | TypeProvider
    | ClassProvider
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | any
  )[],
): Injector {
  if (!page.pageConfiguration || Object.keys(page.pageConfiguration).length === 0) {
    console.error('[DynamicZoneHolder] Page Configuration is empty at Injection.');
  }

  const compAttri = widget.attributes;
  const updatedCompAttri = widgetSection
    ? {
        widgetName: widgetSection.widgetName,
        widgetIndex: widgetSection.widgetIndex,
        isSectionPopup: widgetSection.isSectionPopup,
        ...compAttri,
      }
    : {
        ...compAttri,
      };

  return Injector.create({
    providers: [
      {
        provide: ProviderClass,
        useValue: {
          attri: updatedCompAttri,
          uniqueId: widget?.uniqueId,
          widgetLayout: widget,
          widgetConfig: widget,
          id: user ? user.id : '',
          userProfileData: user ? user : {},
          userProfile: userProfile ?? {},
        },
      },
      {
        provide: ZonePageUrlProvider,
        useValue: zonePageUrlProvider,
      },
      {
        provide: ConfigService,
        useValue: configService,
      },
      {
        provide: PageConfigurationProvider,
        useValue: page.pageConfiguration,
      },
    ].concat(extraProviders ?? []),
    parent: injector,
  });
}

export function createChildWidgetInjector(
  widget: NewUiZoneWidgetConfig,
  injector: Injector,
  dataIn: ProviderClass<object>,
  extraProviders?: (
    | ValueProvider
    | ExistingProvider
    | StaticClassProvider
    | ConstructorProvider
    | FactoryProvider
    | TypeProvider
    | ClassProvider
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | any
  )[],
) {
  return Injector.create({
    providers: [
      {
        provide: ProviderClass,
        useValue: {
          attri: widget.attributes,
          uniqueId: widget?.uniqueId,
          widgetLayout: widget,
          widgetConfig: widget,
          id: dataIn.id,
          userProfileData: dataIn.userProfileData,
          userProfile: dataIn.userProfile,
        },
      },
    ].concat(extraProviders ?? []),
    parent: injector,
  });
}

export function resetStylesOnWebApp() {
  const element = document.getElementById('web-app');
  if (element) {
    element.style.removeProperty('background');
    element.style.removeProperty('backgroundSize');
    element.style.removeProperty('opacity');
    element.style.removeProperty('background');
    element.style.backgroundColor = 'var(--color-background)';

    const cssOverride = window.document.getElementById('page-css-override');
    if (cssOverride) {
      cssOverride.parentNode?.removeChild(cssOverride);
    }
  }
}

export function getRouteParams(route: ActivatedRoute): Observable<{
  zoneUrlInput: string;
  pageUrlInput: string;
  roleInput: string;
  subscriptionInput: string;
  idInput: string;
}> {
  return route.paramMap.pipe(
    map((params: ParamMap) => {
      const pageOrModal = (params.get('modal-page') ? params.get('modal-page') : params.get('page')) ?? '';
      if (params.get('zone') === null || pageOrModal === null) {
        throw Error('No zone or page specified via path or input');
      } else {
        return {
          zoneUrlInput: params.get('zone') ?? '',
          pageUrlInput: pageOrModal ?? '',
          roleInput: params.get('role') ?? '',
          subscriptionInput: params.get('subscription') ?? '',
          idInput: params.get('id') ?? '',
        };
      }
    }),
  );
}

export function fetchProfileAndSetOrgInfoBasedOnZone(
  profile$: Observable<ProfileStateModel>,
  store: Store,
  zone: string,
) {
  return profile$.pipe(
    take(2),
    map((profileData) => {
      let count = 0;
      if (profileData?.activeOrganisationId && isAuthenticatedRoute(window.location.pathname)) {
        store.dispatch(new SetActiveOrgId(profileDataOrgOrLocalOrg(profileData)));
      }
      if (zone === formatNameAsUrl(APP_ZONES.ORGANISATION_VERIFICATION)) {
        const organisationId = profileData?.activeOrganisationId ?? '';
        if (organisationId && count === 0) {
          store.dispatch(new GetOrganisation(profileDataOrgOrLocalOrg(profileData)));
          count++;
        }
      }

      return profileData ? profileData : undefined;
    }),
  );
}

function profileDataOrgOrLocalOrg(profileData: ProfileStateModel) {
  const localActiveOrganisationId = localStorage.getItem('activeOrganisationId');

  // Should load last selected org if a valid choice exists in local storage
  // This should be set in the user profile state when settinging active org
  // if set in locla then
  if (localActiveOrganisationId) {
    // check a valid choice

    const allowedOrgIds = profileData.orgList?.map((org) => org.id) ?? [];

    if (allowedOrgIds.includes(localActiveOrganisationId)) {
      return localActiveOrganisationId;
    }
  }

  // If payabl retireve current org based on current_company_cif
  // need an adapter layer for this in the future
  if (window.location.hostname.includes('payabl')) {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken: {
        current_company_cif: string;
        org_details: {
          [orgId: string]: {
            name: string;
            attributes: {
              externalClientId: string[];
            };
          };
        };
      } = jwt_decode(token);

      for (const org of Object.entries(decodedToken?.org_details ?? {})) {
        if (org[1].attributes['externalClientId'][0] === decodedToken?.['current_company_cif']) {
          return org[0];
        }
      }
    }
  }

  return profileData?.activeOrganisationId ?? '';
}

const originalWidgetLayouts = new WeakMap<object, UiZoneWidgetConfig[]>();

export function hideWidgetsIfNeededWithCallback(
  widgetLayout: UiZoneWidgetConfig[],
  store: Store,
  countriesCurrenciesService: CountriesCurrenciesService,
  iconCacheService: IconCacheService,
  callback: () => void,
) {
  // Get or store original layout
  if (!originalWidgetLayouts.has(widgetLayout)) {
    originalWidgetLayouts.set(widgetLayout, JSON.parse(JSON.stringify(widgetLayout)));
  }
  const originalLayout = originalWidgetLayouts.get(widgetLayout)!;

  // Find all widgets that have a hide condition
  const widgetsToHide = getWidgetsToHide(originalLayout);

  if (!widgetsToHide.length) {
    callback();
    return;
  }

  combineLatest(
    widgetsToHide.map((w) =>
      evaluateCondition(
        w.attributes.hideCondition as string,
        store,
        countriesCurrenciesService,
        iconCacheService,
      ),
    ),
  ).subscribe((results) => {
    const hiddenWidgetIds = new Set<string | number>();

    widgetsToHide.forEach((w, index) => {
      try {
        const result = results[index];
        const booleanResult = /true/.test(result);

        if (booleanResult) {
          console.log(`hiding widget: ${w.uniqueId} with condition: ${w.attributes.hideCondition}`);
          hiddenWidgetIds.add(w.uniqueId);
        }
      } catch (error) {
        console.error(`Failed to evaluate hide condition on widget: ${w.uniqueId} with error: ${error}`);
      }
    });

    // Recursively filter out hidden widgets
    const filteredLayout = filterHiddenWidgets(originalLayout, hiddenWidgetIds);

    widgetLayout.length = 0;
    widgetLayout.push(...filteredLayout);

    callback();
  });
}

// Function to recursively find widgets that have a hide condition
export function getWidgetsToHide(widgets: UiZoneWidgetConfig[]): UiZoneWidgetConfig[] {
  return widgets.reduce((hiddenWidgets: UiZoneWidgetConfig[], widget: NewUiZoneWidgetConfig) => {
    if (widget.attributes?.hideCondition) {
      hiddenWidgets.push(widget); // Add widget to the list if it has a hide condition
    }
    // Recursively check child components for hide conditions
    if (widget.childComponents?.length) {
      const childWidgets = widget.childComponents.flatMap((childArray) =>
        Array.isArray(childArray) ? getWidgetsToHide(childArray) : getWidgetsToHide([childArray]),
      );
      hiddenWidgets.push(...childWidgets);
    }
    return hiddenWidgets;
  }, []);
}

// Function to recursively filter out widgets that should be hidden
export function filterHiddenWidgets(
  widgets: UiZoneWidgetConfig[],
  hiddenWidgetIds: Set<string | number>,
): UiZoneWidgetConfig[] {
  return widgets
    .map((widget: NewUiZoneWidgetConfig) => {
      // If the widget's uniqueId is in the hiddenWidgetIds set, return null (remove it)
      if (hiddenWidgetIds.has(widget.uniqueId)) {
        return null;
      }
      // Recursively filter child components
      const newChildComponents = widget?.childComponents
        ?.map((childArray) => {
          return Array.isArray(childArray)
            ? filterHiddenWidgets(childArray, hiddenWidgetIds)
            : filterHiddenWidgets([childArray], hiddenWidgetIds)[0];
        })
        .filter(Boolean); // Remove any null or undefined child components

      // Return the updated widget with the filtered child components
      return { ...widget, childComponents: newChildComponents };
    })
    .filter(Boolean) as UiZoneWidgetConfig[]; // Remove null values from the final list
}

export function isBodyScrollingEnabled(device: SupportedDevice, pageConfig: UiPageConfig): void {
  const isDesktop = device === 'desktop';
  const isBodyScrollingEnabled = pageConfig.pageConfiguration?.isBodyScrollingEnabled;

  if ((isDesktop && isBodyScrollingEnabled === false) || (!isDesktop && isBodyScrollingEnabled !== true)) {
    setOverFlowHiddenOnBodyTag();
  } else {
    setOverFlowAutoOnBodyTag();
  }
}

export function applyPageConfigStyles(element: HTMLElement, pageConfig: UiPageConfig, isModalPage = false) {
  element.style.cssText = '';
  if (
    pageConfig?.pageConfiguration?.isAdvancedCssActive &&
    pageConfig?.pageConfiguration?.advancedCss?.length
  ) {
    element.style.cssText = pageConfig?.pageConfiguration?.advancedCss;
    return;
  }
  if (!isModalPage && pageConfig?.containerType === ContainerType.PAGE) {
    element.style.backgroundColor =
      pageConfig.pageConfiguration?.backgroundColor ?? 'var(--color-background)';
  }
  if (
    !isModalPage &&
    pageConfig?.containerType !== ContainerType.MODAL &&
    !pageConfig.pageConfiguration?.backgroundImage?.image
  ) {
    const prevImageElement = document.getElementById('backgroundImageWrapper');
    if (prevImageElement) {
      prevImageElement.remove();
    }
  }

  if (
    !isModalPage &&
    pageConfig?.containerType !== ContainerType.MODAL &&
    pageConfig?.pageConfiguration?.backgroundImage?.image
  ) {
    createBackgroundImageWrapperElement(element, pageConfig);
  }

  if (
    pageConfig?.pageConfiguration?.backgroundGradient &&
    pageConfig?.pageConfiguration?.backgroundGradient?.length > 0
  ) {
    element.style.background = pageConfig?.pageConfiguration?.backgroundGradient ?? 'unset';
    element.style.height = pageConfig?.pageConfiguration?.backgroundGradient ? '100vh' : 'unset';
  }
}

export function createBackgroundImageWrapperElement(element: HTMLElement, pageConfig: UiPageConfig) {
  const prevImageElement = document.getElementById('backgroundImageWrapper');
  if (prevImageElement) {
    prevImageElement.remove();
  }
  if (!document.getElementById('backgroundImageWrapper')) {
    const pseudoElement = document.createElement('div');
    pseudoElement.id = 'backgroundImageWrapper';
    pseudoElement.style.content = '""';
    pseudoElement.style.position = 'fixed';
    pseudoElement.style.top = '0';
    pseudoElement.style.left = '0';
    pseudoElement.style.height = '100%';
    pseudoElement.style.width = '100%';
    pseudoElement.style.backgroundImage = pageConfig?.pageConfiguration?.backgroundImage?.image ?? 'unset';
    pseudoElement.style.backgroundColor = 'transparent';
    pseudoElement.style.opacity =
      pageConfig?.pageConfiguration?.backgroundImage?.opacity.toString() ?? 'unset';
    pseudoElement.style.backgroundSize = pageConfig?.pageConfiguration?.backgroundSize ?? 'auto';
    pseudoElement.style.backgroundAttachment = 'fixed';
    pseudoElement.style.backgroundPosition = 'center';
    pseudoElement.style.zIndex = '-1';
    if (pageConfig?.pageConfiguration?.backgroundPosition) {
      pseudoElement.style.backgroundPositionX =
        pageConfig?.pageConfiguration?.backgroundPosition.x ?? 'unset';
      pseudoElement.style.backgroundPositionY =
        pageConfig?.pageConfiguration?.backgroundPosition.y ?? 'unset';
    }
    element.appendChild(pseudoElement);
  }
}

export function buildCssFontRules(fontsConfig: {
  h1: {
    'font-family': string;
    'font-weight': string;
  };
  h2: {
    'font-family': string;
    'font-weight': string;
  };

  h3: {
    'font-family': string;
    'font-weight': string;
  };
  bodyFont: {
    'font-family': string;
    'font-weight': string;
  };
}): string {
  return `
    #web-app h1 { ${
      fontsConfig?.h1['font-weight'] ? `font-weight: ${fontsConfig?.h1['font-weight']}` : ''
    }; ${fontsConfig?.h1['font-family'] ? `font-family: ${fontsConfig?.h1['font-family']}` : ''} }
    #web-app h2 { ${
      fontsConfig?.h2['font-weight'] ? `font-weight: ${fontsConfig?.h2['font-weight']}` : ''
    }; ${fontsConfig?.h2['font-family'] ? `font-family: ${fontsConfig?.h2['font-family']}` : ''} }
    #web-app h3 { ${
      fontsConfig?.h3['font-weight'] ? `font-weight: ${fontsConfig?.h3['font-weight']}` : ''
    }; ${fontsConfig?.h3['font-family'] ? `font-family: ${fontsConfig?.h3['font-family']}` : ''} }
    #web-app body { ${
      fontsConfig?.bodyFont['font-weight'] ? `font-weight: ${fontsConfig?.bodyFont['font-weight']}` : ''
    }; ${fontsConfig?.bodyFont['font-family'] ? `font-family: ${fontsConfig?.bodyFont['font-family']}` : ''} }
  `;
}

export const urlSearchParamsToJson = (searchParams: URLSearchParams): Record<string, string> => {
  const params: Record<string, string> = {};

  // Iterate through all the search parameters
  searchParams?.forEach((value, key) => {
    params[key] = value;
  });

  return params;
};

export function evaluateCondition(
  condition: string,
  store: Store,
  countriesCurrenciesService: CountriesCurrenciesService,
  iconCacheService: IconCacheService,
) {
  const model$ = buildHandleBarModel(store, '', countriesCurrenciesService, iconCacheService);
  return model$
    .pipe(take(1))
    .toPromise()
    .then((model) => {
      const templateFunction = Handlebars.compile(`{{#if (${condition})}}true{{else}}false{{/if}}`);
      return templateFunction(model);
    });
}
