import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { APP_ZONES } from '@finxone-platform/shared/sys-config-types';
import { ConfigService } from '../../../services/config-service/config-service.service';

/**
 * Navigates to the 'confirmation-code' route with query parameters for
 * retrieving secure card details. Utilizes the ConfigService to determine
 * the target URL for redirection after confirmation.
 *
 * @param injector - The Angular Injector used to retrieve services.
 */
export const getSecureCardDetails = (injector: Injector) => {
  const configService = injector.get(ConfigService);
  const router = injector.get(Router);
  const targetUrl = configService.reRouteIfModal(APP_ZONES.CARD_MANAGEMENT_ZONE, 'card-details');

  router.navigate(['confirmation-code'], {
    queryParams: {
      claimType: 'getSensitiveCardDetails',
      confirmationType: 'PIN',
      returnUrl: targetUrl,
    },
  });
};
