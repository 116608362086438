export const userProfileSummaryDetails = `<div class='w-100 h-100'>
<div class='is-vertical-align align-left'>
    <h5 class='text-label'>Full Name</h5>
    <h4 class='font-weight-bold my-1'>{{profile.fullName}}</h4>
</div>
<hr class='my-4 mx-1'>
<div class='is-vertical-align align-left'>
<h5 class='text-label'>Phone Number</h5>
   <h4>{{profile.phoneNumber}}</h4>
   <h5 class='text-label'>Date of Birth</h5>
   <h4>{{datePipe profile.dob format='dd MMM yyyy'}}</h4>
   <h5 class='text-label'>Email Address</h5>
   <h4>{{profile.email}}</h4>
   <h5 class='text-label'>Address</h5>
   <h4>{{addComma profile.flatName}}{{addComma profile.propertyName}}{{profile.propertyNumber}} {{profile.address1}} {{addComma profile.address2}}{{addComma profile.city}}{{addComma profile.county}}{{addComma profile.postalCode}}{{profile.country}}</h4>
   </div>`;
