import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { tap } from 'rxjs';
import { ResetSelectedFee, ResetSelectedFeeGroup } from '../../../actions/fee-management.action';
import { FeeManagementService } from '../../../services/fee-management-service/fee-management.service';
import { FeeManagementState } from '../../../state/fee-management.state';
import { redirectToPage } from '../cta-button-actions.utils';

/**
 * Handles the request to remove a client from a fee group.
 *
 * This function retrieves the necessary services and state using the provided injector.
 * It checks if the selected client details are available, and if so, attempts to remove
 * the client from the fee group using the FeeManagementService. Upon successful removal,
 * it displays a success alert, dispatches an action to reset the selected client, and
 * redirects the user to a specified page. If the removal fails, it displays an error alert.
 *
 * @param widgetProperties - The properties of the widget initiating the request.
 * @param injector - The injector used to retrieve necessary services and state.
 */
export function removeFeeGroupAction(widgetProperties: BaseWidgetProperties, injector: Injector) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  const alertHandlerService = injector.get(AlertHandlerService);
  const feeManagementService = injector.get(FeeManagementService);
  const getFeeManagementState = store.selectSnapshot(FeeManagementState.getFeeManagement);
  const selectedGroup = getFeeManagementState.selectedFeeGroupData;
  if (selectedGroup?.id) {
    feeManagementService
      .removeFeeGroup(selectedGroup.id)
      .pipe(
        tap((response) => {
          if (response) {
            alertHandlerService.showAlertFn('success', 'Fee Group removed successfully');
            store.dispatch(new ResetSelectedFeeGroup());
            redirectToPage(router, widgetProperties, injector);
          } else {
            alertHandlerService.showAlertFn('error', 'An unexpected error occurred, please try again');
          }
        }),
      )
      .subscribe();
  }
}

/**
 * Executes the action to remove a fee based on the selected fee data.
 * Utilizes various services to perform the removal, handle alerts, and redirect upon success.
 *
 * @param widgetProperties - The properties of the widget triggering the action.
 * @param injector - The Angular injector used to retrieve necessary services.
 */
export function removeFeeAction(widgetProperties: BaseWidgetProperties, injector: Injector) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  const alertHandlerService = injector.get(AlertHandlerService);
  const feeManagementService = injector.get(FeeManagementService);
  const getFeeManagementState = store.selectSnapshot(FeeManagementState.getFeeManagement);
  const selectedFeeData = getFeeManagementState.selectedFeeData;
  if (selectedFeeData?.id) {
    feeManagementService
      .removeFee(selectedFeeData?.id)
      .pipe(
        tap((response) => {
          if (response) {
            alertHandlerService.showAlertFn('success', 'Fee removed successfully');
            store.dispatch(new ResetSelectedFee());
            redirectToPage(router, widgetProperties, injector);
          } else {
            alertHandlerService.showAlertFn('error', 'An unexpected error occurred, please try again');
          }
        }),
      )
      .subscribe();
  }
}
