import { CardManagementService } from '@app/finxone-web-frontend/app/lib/services/card-management-service/card-management-service';

/**
 * Shares CVV details using a web view handler.
 *
 * This function retrieves the CVV details from the provided
 * CardManagementService instance and formats them into a text
 * string. If a Flutter web view is available, it invokes the
 * 'shareSheetHandler' with the formatted text to share the
 * CVV details.
 *
 * @param cardManagementService - An instance of CardManagementService
 *                                used to access CVV details.
 */
export function shareCvvDetails(cardManagementService: CardManagementService) {
  const flutterWebView = (window as any).flutter_inappwebview;
  const textContent = `PAN: ${cardManagementService.cvvDetails.pan}\nEXP: ${cardManagementService.cvvDetails.expDate}\nCVV: ${cardManagementService.cvvDetails.cvv2}`;
  if (flutterWebView) {
    flutterWebView.callHandler('shareSheetHandler', [textContent]);
  }
}
