export const reviewBeneficiaryCounters = `<div class="w-100 h-100">
<div class="d-flex w-100 h-100 is-center">
  <div class="col-2">
    <div class="text-label text-left">Total number of payments</div>
    <div class="text-value text-left  font-weight-bold">{{bulkPaymentSummary.totalPayments}}</div>
  </div>

  <div class="col-3">
    <div class="text-label text-left">Number of unique beneficiaries</div>
    <div class="text-value text-left  font-weight-bold">{{bulkPaymentSummary.totalUniqueBeneficiaries}}</div>
  </div>

  <div class="col-2">
    <div class="text-label text-left">Number of error rows</div>
    <div class="text-value text-left  font-weight-bold">{{bulkPaymentSummary.totalErrors}}</div>
  </div>

  <div class="col-2">
    <div class="text-label text-left">Number of duplicate rows</div>
    <div class="text-value text-left font-weight-bold">{{bulkPaymentSummary.totalDuplicatePayments}}</div>
  </div>

  
  <div class="col-3">
    <div class="text-label text-left">Number of pending payments</div>
    <div class="text-value text-left font-weight-bold">{{bulkPaymentSummary.totalPendingPayments}}</div>
  </div>
</div>
</div>
`;
export const reviewBeneficiaryValidationCounters = `<div class="w-100 h-100">
<div class="d-flex w-100 h-100 is-center">
  <div class="col-3">
    <div class="text-label text-left">Total number of payments</div>
    <div class="text-value text-left  font-weight-bold">{{bulkPaymentSummary.totalPayments}}</div>
  </div>

  <div class="col-3">
    <div class="text-label text-left">Number of error rows</div>
    <div class="text-value text-left  font-weight-bold">{{bulkPaymentSummary.totalErrors}}</div>
  </div>


  <div class="col-3">
    <div class="text-label text-left">Number of unique beneficiaries</div>
    <div class="text-value text-left  font-weight-bold">{{bulkPaymentSummary.totalUniqueBeneficiaries}}</div>
  </div> 

  <div class="col-3">
    <div class="text-label text-left">Number of pending beneficiaries</div>
    <div class="text-value text-left  font-weight-bold">{{bulkPaymentSummary.totalPendingBeneficiary}}</div>
  </div> 
  
</div>
</div>
`;

export const prePaymentCounters = `<div class="w-100 h-100">
<div class="d-flex w-100 h-100 is-center">
<div class="col-3">
<div class="text-label text-left">Bulk payment total amount</div>
<div class="text-value text-left  font-weight-bold">{{ currencyPipe bulkPaymentSummary.totalAmount currencyCode=bulkPaymentSummary.currency }}</div>
</div>

<div class="col-2">
<div class="text-label text-left">Total Unique Beneficiaries</div>
<div class="text-value text-left  font-weight-bold">{{bulkPaymentSummary.totalUniqueBeneficiaries}}</div>
</div> 


  <div class="col-2">
    <div class="text-label text-left">Number of payments</div>
    <div class="text-value text-left  font-weight-bold">{{bulkPaymentSummary.totalPayments}}</div>
  </div>

  <div class="col-2">
    <div class="text-label text-left">Payout account name</div>
    <div class="text-value text-left  font-weight-bold">{{customerName}}</div>
  </div>

  <div class="col-3">
    <div class="text-label text-left">Available balance of payout Account</div>
    <div class="text-value text-left  font-weight-bold">{{ currencyPipe availableBalance currencyCode=currency }}</div>
  </div>

</div>
</div>
`;

export const reviewBulkPayments = `<div class="w-100 h-100">
<div class="d-flex w-100 h-100 is-center">
 <div class="col-2">
    <div class="text-label text-left">Payment status</div>
    <div class="text-value text-left  font-weight-bold text-capitalize">{{bulkPaymentSummary.masterStatus}}</div>
  </div>
  <div class="col-2">
    <div class="text-label text-left">Failed Payments</div>
    <div class="text-value text-left  font-weight-bold">{{bulkPaymentSummary.totalFailedPayments}}</div>
  </div>

  <div class="col-2">
    <div class="text-label text-left">Successful Payments</div>
    <div class="text-value text-left  font-weight-bold">{{bulkPaymentSummary.totalSuccessPayments}}</div>
  </div>

  <div class="col-3">
    <div class="text-label text-left">Scheduled Payments</div>
    <div class="text-value text-left  font-weight-bold">{{bulkPaymentSummary.totalSchedulePayments}}</div>
  </div>
  
  <div class="col-3">
    <div class="text-label text-left">Payout Account</div>
    <div class="text-value text-left font-weight-bold">{{bulkPaymentSummary.accountName}}</div>
  </div>
</div>
</div>
`;
