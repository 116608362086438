import { Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormActionTypeEnum, OtpParam } from '@finxone-platform/form-action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import {
  ACCOUNTTYPE,
  APP_ZONES,
  BaseWidgetProperties,
  CheckPayeeMatchStatus,
} from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { catchError, map, take } from 'rxjs';
import { ClearBulkPaymentList, GetBulkPaymentList } from '../../../actions/bulk-payment.action';
import { SetFormActionWithId } from '../../../actions/form-submission.action';
import { AccountService } from '../../../services/account-service/account-service.service';
import { AddBeneficiaryRequest } from '../../../services/account-service/account.type';
import { BackOverrideService } from '../../../services/back-override-service/back-override.service';
import { CopResponseState } from '../../../state/cop-response.state';
import { FormActionState } from '../../../state/form-submision.state';
import { formatNameAsUrl } from '../../zone-url.utils';
import { redirectToPage } from '../cta-button-actions.utils';
import { addEditBeneficiary } from './add-beneficiary';

/**
 * Updates the beneficiary form data based on the current route parameters and state.
 *
 * This function retrieves necessary services and state data using the provided injector.
 * It determines the appropriate form state to update based on the 'flow' query parameter.
 * If the flow is 'bulk-payment', it calls the continue with suggestion API.
 * Otherwise, it updates the form data for adding or editing a beneficiary with the suggested name.
 * If there is an account type mismatch, it swaps the account type.
 * Finally, it dispatches the updated form data to the store and continues the add/edit beneficiary process.
 *
 * @param injector - The injector used to retrieve services and state.
 * @param widgetProperties - The properties of the widget being used.
 */
export function updateBeneficiaryFormData(injector: Injector, widgetProperties: BaseWidgetProperties) {
  const store = injector.get(Store);
  const route = injector.get(ActivatedRoute);
  //get correct bank name from state
  const copState = store.selectSnapshot(CopResponseState.getCopResponseState);
  const suggestedName = copState.name;

  //decide which form state to update
  route.queryParams.pipe(take(1)).subscribe((params) => {
    const beneficiaryFlowParam = params['flow'];
    if (beneficiaryFlowParam === 'bulk-payment') {
      callContinueWithSuggestionAPI(injector);
      return;
    }
    let formData;
    let formActionId;
    if (beneficiaryFlowParam === OtpParam.AddBeneficiary) {
      formData = store.selectSnapshot(
        FormActionState.getFormActionStateWithId(FormActionTypeEnum.ADD_BENEFICIARY),
      )?.formData as AddBeneficiaryRequest;
      formActionId = FormActionTypeEnum.ADD_BENEFICIARY;
      formData.name = suggestedName;
    } else {
      formData = store.selectSnapshot(
        FormActionState.getFormActionStateWithId(FormActionTypeEnum.EDIT_BENEFICIARY),
      )?.formData;
      formActionId = FormActionTypeEnum.EDIT_BENEFICIARY;
      formData.payload.name = suggestedName;
    }
    //in the scenario where account type is wrong, swap account type as well
    if (
      copState.matchStatusCode === CheckPayeeMatchStatus.ACCOUNT_TYPE_MISMATCH_NAME_CLOSE_MATCH ||
      copState.matchStatusCode === CheckPayeeMatchStatus.NAME_MATCHED
    ) {
      formData.accountType =
        formData.accountType === ACCOUNTTYPE.PERSONAL ? ACCOUNTTYPE.BUSINESS : ACCOUNTTYPE.PERSONAL;
    }
    store.dispatch(new SetFormActionWithId({ type: '', formData }, formActionId));
    //continue to add/edit the beneficiary
    addEditBeneficiary(injector, widgetProperties);
  });
}

/**
 * Executes the "continue with suggestion" API call for bulk payments and handles the response.
 *
 * This function retrieves necessary services and data from the provided injector, including
 * the store, account service, alert handler service, router, and back service. It then
 * retrieves form action data related to bulk payments and constructs a mock widget properties
 * object for navigation purposes.
 *
 * The function calls the `bulkPaymentContinueAnywayAndWithSuggestion` method of the
 * `AccountService` with the retrieved master file ID and transaction ID, passing a suggestion
 * flag set to true. Upon successful completion, it dispatches actions to clear and refresh
 * the bulk payment list, updates the navigation URL, and sets a back override. It also
 * displays a success alert.
 *
 * If an error occurs during the API call, the error is returned.
 *
 * @param injector - The injector used to retrieve necessary services and data.
 */
const callContinueWithSuggestionAPI = (injector: Injector) => {
  const store = injector.get(Store);
  const accountService = injector.get(AccountService);
  const alertHandlerService = injector.get(AlertHandlerService);
  const router = injector.get(Router);
  const backService = injector.get(BackOverrideService);
  const formActionData = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.BULK_PAYMENTS),
  );
  const masterFileId = formActionData?.formData.masterFileId;
  const transactionId = formActionData?.formData.transactionId;
  const mockWidgetProperties: BaseWidgetProperties = {
    textContent: '',
    zoneToNavigate: APP_ZONES.PAYMENT,
    urlToNavigate: '',
  };

  // passing suggestion: true as we call the continue with suggestion function here
  accountService
    .bulkPaymentContinueAnywayAndWithSuggestion(masterFileId, transactionId, { suggestion: true })
    .pipe(
      take(1),
      map(() => {
        store.dispatch(new ClearBulkPaymentList());
        store.dispatch(new GetBulkPaymentList(1, 10, masterFileId));
        mockWidgetProperties['urlToNavigate'] = `bulk-payment-beneficiary-review`;
        redirectToPage(router, mockWidgetProperties, injector);
        const nextRoute = `/zones/${formatNameAsUrl(mockWidgetProperties['zoneToNavigate'])}/${
          mockWidgetProperties['urlToNavigate']
        }`;
        backService.setBackOverride('/zones/landing/home', nextRoute, FormActionTypeEnum.ADD_BENEFICIARY);
        alertHandlerService.showAlertFn('success', 'Updated Successfully.');
      }),
      catchError((error) => {
        return error;
      }),
    )
    .subscribe();
};
