<ng-content *ngIf="prefixIcon"></ng-content>
<ng-template #standard>
  <p-dropdown
    [overlayOptions]="getOverlayOptions()"
    [class]="class"
    [options]="options"
    [filter]="filter"
    [formControl]="dropdownStringControl"
    [ngModel]="value"
    [appendTo]="appendto"
    (onChange)="onChange($event)"
    [placeholder]="placeholder"
    [optionLabel]="optionLabel"
    [optionValue]="optionValue"
    [ngStyle]="textColor"
    [attr.disabled]="isDisabled"
    [class.is-disabled]="isDisabled"
    [class.prefix-icon]="prefixIcon"
    (clickOutside)="onBlur()"
    #pdropdown
  >
  </p-dropdown>
</ng-template>
<!-- if value and label are used -->
<p-dropdown
  *ngIf="id && label; else standard"
  [overlayOptions]="getOverlayOptions()"
  [class]="class"
  [options]="options"
  [filter]="filter"
  [formControl]="dropdownStringControl"
  [ngModel]="value"
  [appendTo]="appendto"
  (onChange)="onChange($event)"
  [placeholder]="placeholder"
  optionValue="{{ id }}"
  optionLabel="{{ label }}"
  [ngStyle]="textColor"
  [attr.disabled]="isDisabled"
  [class.is-disabled]="isDisabled"
  [class.prefix-icon]="prefixIcon"
  (clickOutside)="onBlur()"
  #pdropdown
>
</p-dropdown>
