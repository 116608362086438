import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CountdownService {
  private countdownSubject = new BehaviorSubject<string>('00');
  private remainingSeconds: number = 0;
  private intervalSub: any;

  get countdown$(): Observable<string> {
    return this.countdownSubject.asObservable();
  }

  public startCountdown(initialSeconds: number) {
    if (this.intervalSub) {
      clearInterval(this.intervalSub);
    }

    this.remainingSeconds = Math.min(Math.max(initialSeconds, 0), 60); // Ensure within range
    this.updateCountdown();

    this.intervalSub = setInterval(() => {
      if (this.remainingSeconds > 0) {
        this.remainingSeconds--;
        this.updateCountdown();
      } else {
        clearInterval(this.intervalSub);
      }
    }, 1000);
  }

  private updateCountdown() {
    this.countdownSubject.next(this.formatTime(this.remainingSeconds));
  }

  private formatTime(time: number): string {
    return (time < 10 ? '0' : '') + time;
  }
}
