export const checkEmailDesktopConfig = {
  Imagewidget: { x: 17, y: 8, rows: 2, cols: 15 },
  checkEmail: { x: 17, y: 11, rows: 1, cols: 15 },
  Text: { x: 17, y: 12, rows: 2, cols: 15 },
  resendLink: { x: 19, y: 14, rows: 2, cols: 11 },
  changeEmail: { x: 19, y: 16, rows: 2, cols: 11 },
};

export const checkEmailMobileConfig = {
  Imagewidget: { x: 0, y: 8, rows: 2, cols: 15 },
  checkEmail: { x: 0, y: 11, rows: 1, cols: 15 },
  Text: { x: 0, y: 13, rows: 3, cols: 15 },
  resendLink: { x: 0, y: 24, rows: 2, cols: 15 },
  changeEmail: { x: 0, y: 27, rows: 3, cols: 15 },
};
