import { WidgetNames } from '@finxone-platform/shared/sys-config-types';

export const phoneNestedWidgets = {
  countryCodeDropdown: {
    name: WidgetNames.DROPDOWN,
    widgetConfigDisplayName: 'Country Code Dropdown Styling',
    attributes: {
      widgetProperties: {
        textContent: '',
      },
      elementsConfig: [],
      css_config: {
        borderRadiusBottomRight: 0,
        borderRadiusBottomLeft: 6,
        borderRadiusTopRight: 0,
        borderRadiusTopLeft: 6,
        borderWidth: 1,
        borderUnit: 'px',
        borderColor: 'var(--border-color-1)',
        marginUnit: 'px',
        paddingUnit: 'px',
        fontUnit: 'px',
        fontSize: 14,
        borderRadiusUnit: 'px',
        textAlignment: 'center',
        textFont: 'var(--font-family)',
        backGroundColor: 'var(--palette-color-5)',
        color: 'var(--typography-color-0)',
      },
    },
  },
  verifyButton: {
    name: WidgetNames.BUTTON,
    widgetConfigDisplayName: 'Verify Button Styling',
    attributes: {
      widgetProperties: {
        textContent: 'Verify',
      },
      elementsConfig: [],
      css_config: {
        borderRadiusBottomRight: 6,
        borderRadiusBottomLeft: 0,
        borderRadiusTopRight: 6,
        borderRadiusTopLeft: 0,
        borderWidth: 0,
        borderUnit: 'px',
        marginUnit: 'px',
        paddingUnit: 'px',
        borderRadiusUnit: 'px',
        fontSize: 16,
        fontUnit: 'px',
        textAlignment: 'center',
        textFont: 'var(--font-family)',
        backGroundColor: 'var(--palette-color-5)',
        color: 'var(--typography-color-0)',
      },
    },
  },
};
