import { FormActionTypeEnum } from '@finxone-platform/form-action';
import { revenirTransactionItemTemplate } from '@finxone-platform/shared/default-templates';
import {
  APP_ZONES,
  GridPosition,
  SINK_TYPE,
  ValidationRuleTypes,
  WidgetNames,
  WorkflowWidgetConfig,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import {
  CustomQuestionWidgetBuilder,
  RevenirTransactionItemListWidgetBuilder,
} from '../../../../widgets/example-builder';
import {
  commonBackButtonQuickLink,
  commonProgressBarWidget,
  commonTextWidget,
} from '../../../common/common-widgets';

const commonBackBtn = commonBackButtonQuickLink({
  cssConfig: {
    iconSize: 35,
    iconUnit: 'px',
  },
  gridPosition: {
    y: 3,
    x: 0,
    resizeEnabled: true,
    dragEnabled: true,
    rows: 2,
    cols: 2,
  },
});

const customQuestionValidationRules = [
  {
    type: ValidationRuleTypes.REQUIRED,
    value: true,
  },
];

const customQuestionElementsConfig = ({
  label,
  questionUniqueId,
  type,
}: {
  label: string;
  questionUniqueId: string;
  type: string;
}) => {
  return [
    {
      field_name: WidgetNames.CUSTOM_QUESTION_WIDGET,
      label,
      type,
      section: 'questionwidget',
      show: false,
      options: [],
      uniqueId: questionUniqueId,
    },
  ];
};

const customQuestionWidgetProperties = {
  showHeader: false,
  showSubHeader: false,
  formKey: FormActionTypeEnum.ADD_TRIP_REVENIR_ZONE,
};

const customQuestionDefaultInputBorderStyle = {
  inputBorderWidth: '1',
  inputBorderRadiusBottomLeft: '6',
  inputBorderRadiusBottomRight: '6',
  inputBorderRadiusTopLeft: '6',
  inputBorderRadiusTopRight: '6',
  inputBorderRadiusUnit: 'px',
  borderWidth: '0',
  borderStyle: 'none',
  textFont: 'var(--font-family)',
};

export class CommonTripZoneWidgets {
  get commonTripStartEndWidgets(): WorkflowWidgetConfig[] {
    return [
      commonProgressBarWidget({ completionProgressPercentage: '30' }),
      commonBackBtn,
      commonTextWidget({
        textContent: 'Select Date',
        cssConfig: {
          fontSize: 16,
          color: 'var(--typography-color-1)',
          paddingRight: 10,
        },
        gridPosition: {
          x: 0,
          y: 5,
          rows: 2,
          cols: 15,
          resizeEnabled: true,
          dragEnabled: true,
        },
      }),
    ];
  }

  get commonExitPointWidgets(): WorkflowWidgetConfig[] {
    return [commonProgressBarWidget({ completionProgressPercentage: '70' }), commonBackBtn];
  }

  commonAddEditTransaction({ isDisabled }: { isDisabled: boolean }): WorkflowWidgetConfig[] {
    return [
      new CustomQuestionWidgetBuilder()
        .gridPosition({ x: 0, y: 16, rows: 4, cols: 5 } as GridPosition)
        .configDisplayName(`Custom Question Widget: Amount`)
        .cssConfig({ borderWidth: '0', borderStyle: 'none' })
        .attributes({
          customDataQuestionData: {
            data_type: 'string',
            entity: APP_ZONES.REVENIR_ZONE,
            sink: SINK_TYPE.METADATA,
            isUpdateToHasura: false,
          },
          label: `Amount`,
          inputType: 'text',
          questionUniqueId: 'total-amount',
          elementsConfig: customQuestionElementsConfig({
            label: `Amount`,
            questionUniqueId: 'total-amount',
            type: 'text',
          }),
          validationRules: customQuestionValidationRules,
          questionProperties: {
            name: 'total-amount',
            label: 'Amount',
            type: 'text',
            preSelectSubAnswer: 'false',
          },
          css_config: customQuestionDefaultInputBorderStyle,
          widgetProperties: { ...customQuestionWidgetProperties, isDisabled },
        } as unknown as WorkflowWidgetConfigurationAttributes)
        .build(),

      new CustomQuestionWidgetBuilder()
        .gridPosition({ x: 6, y: 16, rows: 4, cols: 5 } as GridPosition)
        .configDisplayName(`Custom Question Widget: VAT`)
        .cssConfig({ borderWidth: '0', borderStyle: 'none' })
        .attributes({
          customDataQuestionData: {
            data_type: 'string',
            entity: APP_ZONES.REVENIR_ZONE,
            sink: SINK_TYPE.METADATA,
            isUpdateToHasura: false,
          },
          label: `VAT`,
          inputType: 'text',
          questionUniqueId: 'total-vat-amount',
          elementsConfig: customQuestionElementsConfig({
            label: `VAT`,
            questionUniqueId: 'total-vat-amount',
            type: 'text',
          }),
          validationRules: customQuestionValidationRules,
          questionProperties: {
            name: 'total-vat-amount',
            label: 'VAT',
            type: 'text',
            preSelectSubAnswer: 'false',
          },
          css_config: customQuestionDefaultInputBorderStyle,
          widgetProperties: { ...customQuestionWidgetProperties, isDisabled },
        } as unknown as WorkflowWidgetConfigurationAttributes)
        .build(),

      new CustomQuestionWidgetBuilder()
        .gridPosition({ x: 0, y: 20, rows: 4, cols: 15 } as GridPosition)
        .configDisplayName(`Custom Question Widget: Retailer`)
        .cssConfig({ borderWidth: '0', borderStyle: 'none' })
        .attributes({
          customDataQuestionData: {
            data_type: 'string',
            entity: APP_ZONES.REVENIR_ZONE,
            sink: SINK_TYPE.METADATA,
            isUpdateToHasura: false,
          },
          label: `Retailer`,
          inputType: 'text',
          questionUniqueId: 'merchant-name',
          elementsConfig: customQuestionElementsConfig({
            label: `Retailer`,
            questionUniqueId: 'merchant-name',
            type: 'text',
          }),
          validationRules: customQuestionValidationRules,
          questionProperties: {
            name: 'merchant-name',
            label: 'Retailer',
            type: 'text',
            preSelectSubAnswer: 'false',
          },
          css_config: customQuestionDefaultInputBorderStyle,
          widgetProperties: { ...customQuestionWidgetProperties, isDisabled },
        } as unknown as WorkflowWidgetConfigurationAttributes)
        .build(),

      new CustomQuestionWidgetBuilder()
        .gridPosition({ x: 0, y: 24, rows: 4, cols: 15 } as GridPosition)
        .configDisplayName(`Custom Question Widget: Date`)
        .cssConfig({ borderWidth: '0', borderStyle: 'none' })
        .attributes({
          customDataQuestionData: {
            data_type: 'string',
            entity: APP_ZONES.REVENIR_ZONE,
            sink: SINK_TYPE.METADATA,
            isUpdateToHasura: false,
          },
          label: `Date`,
          inputType: 'text',
          questionUniqueId: 'transaction-date',
          elementsConfig: customQuestionElementsConfig({
            label: `Date`,
            questionUniqueId: 'transaction-date',
            type: 'text',
          }),
          validationRules: customQuestionValidationRules,
          questionProperties: {
            name: 'transaction-date',
            label: 'Date',
            type: 'text',
            preSelectSubAnswer: 'false',
          },
          css_config: customQuestionDefaultInputBorderStyle,
          widgetProperties: { ...customQuestionWidgetProperties, isDisabled },
        } as unknown as WorkflowWidgetConfigurationAttributes)
        .build(),

      new RevenirTransactionItemListWidgetBuilder()
        .gridPosition({
          x: 0,
          y: 28,
          rows: 12,
          cols: 15,
        } as GridPosition)
        .cssConfig({
          disabledBgColor: 'var(--palette-color-3)',
          disabledTextColor: 'var(--palette-color-6)',
          fontSize: 18,
          textFont: 'Mulish',
        })
        .attributes({
          titleTemplate: 'Items',
          transactionItemConfig: {
            transactionItemTemplate: revenirTransactionItemTemplate,
          },
        } as unknown as WorkflowWidgetConfigurationAttributes)
        .build(),
    ];
  }
}
