<div class="input-container">
  <label
    *ngIf="label && labelType !== labelTypeOptions.PLACEHOLDER"
    [class.labelActive]="isInputOnFocus"
    [class.floatingLabelWithValue]="control?.value?.length && labelType === labelTypeOptions.FLOATING"
    [class.floatingLabel]="labelType === labelTypeOptions.FLOATING"
    class="labelText labelIdle labelConfig"
    [for]="name"
    (click)="handleLabelClick()"
    >{{ label }}</label
  >

  <div #inputElementWrapper class="input-wrapper position-relative">
    <!-- Prefix Content -->
    <ng-content select="[prefix]"></ng-content>
    <!-- Custom Template or Default Input -->
    <ng-container
      *ngIf="customInputTemplate; else defaultInput"
      [ngTemplateOutlet]="customInputTemplate"
      [ngTemplateOutletContext]="{
        $implicit: {
          value: value,
          control: control,
          onInput: onInputChange.bind(this),
          onBlur: onTouched.bind(this),
          onFocus: onTouched.bind(this)
        }
      }"
    ></ng-container>
    <!-- Suffix Content -->
    <ng-content select="[suffix]"></ng-content>
  </div>
  <div
    *ngIf="showErrors && control?.invalid && (control?.dirty || control?.touched)"
    class="error-container"
    role="alert"
  >
    <ng-container *ngFor="let validator of validationConfig">
      <small *ngIf="control?.hasError(validator.type)" class="typog-xs text-error">
        {{ getErrorMessageForField(validator) }}
      </small>
    </ng-container>

    <!-- Custom Error Template -->
    <ng-container
      *ngIf="customErrorTemplate"
      [ngTemplateOutlet]="customErrorTemplate"
      [ngTemplateOutletContext]="{
        $implicit: {
          control: control,
          errors: control?.errors
        }
      }"
    ></ng-container>
  </div>
</div>

<ng-template #defaultInput>
  <input
    hlmInput
    [type]="type"
    [formControl]="control"
    [id]="id"
    [name]="name"
    [value]="value"
    [placeholder]="
      labelType === labelTypeOptions.PLACEHOLDER
        ? label
        : labelType === labelTypeOptions.FLOATING
        ? ''
        : placeholder
    "
    [readonly]="readOnly"
    (input)="onInputChange($event)"
    (blur)="onTouched(); isInputOnFocus = false"
    (focus)="onTouched(); isInputOnFocus = true"
    [class]="textStylingClass + ' ' + borderRadiusClass"
    [ngClass]="{ 'pl-7': hasPrefixContent }"
  />
</ng-template>
