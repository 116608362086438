import {
  notificationBeneficiaryEditTemplate,
  notificationBeneficiaryRemoveTemplate,
} from '@finxone-platform/shared/default-templates';
import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonSizeOptions,
  ButtonTypes,
  GridPosition,
} from '@finxone-platform/shared/sys-config-types';
import { BackButtonWidgetBuilder, TextWidgetBuilder } from '../../../../widgets/example-builder';
import { getQuickLinkWidget } from '../../../payments/common-widgets-utils';
import {
  commonNotificationHeaderTextWidget,
  commonNotificationImageWidget,
} from '../utils/notification-utils';

export const notificationBeneficiaryEditWidgets = [
  commonNotificationImageWidget({
    configDisplayName: 'Image Widget: User Edit Icon',
    attributes: {
      widgetProperties: {
        textContent: '',
        imageAssetUrl: 'assets/icon-library/user-edit.svg',
      } as BaseWidgetProperties,
    },
    gridPosition: { y: 0, rows: 2 } as GridPosition,
  }),

  commonNotificationHeaderTextWidget({
    text: '<div class="w-100" ><p class="m-0">Beneficiary Edited</p></div>',
    configDisplayName: 'Notification Header',
    css_config: {
      color: 'var(--palette-color-0)',
      textAlignment: 'center',
      justifyContent: 'center',
      textFont: 'var(--font-family)',
      fontSize: '20',
      isTextBold: true,
    },
    gridPosition: { y: 2, rows: 2 } as GridPosition,
  }),

  new TextWidgetBuilder()
    .text(
      '<div class="w-100"><p>The beneficiary has been edited. Please review the details for the edited beneficiary.</p></div>',
    )
    .configDisplayName('Text Widget:Info')
    .gridPosition({ y: 4, rows: 3 } as GridPosition)
    .cssConfig({
      color: 'var(--palette-color-1)',
      textAlignment: 'center',
      justifyContent: 'center',
      textFont: 'var(--font-family)',
      fontSize: '14',
    })
    .isRemovable(false)
    .build(),

  new TextWidgetBuilder()
    .text(notificationBeneficiaryEditTemplate)
    .configDisplayName('Beneficiary Info')
    .gridPosition({ y: 7, rows: 22 } as GridPosition)
    .cssConfig({
      backGroundColor: 'var(--palette-color-5)',
      paddingLeft: '20',
      paddingRight: '20',
      borderRadiusTopLeft: '5',
      borderRadiusTopRight: '5',
      borderRadiusBottomLeft: '5',
      borderRadiusBottomRight: '5',
      display: 'flex',
      verticalAlignment: 'start',
      textAlignment: 'left',
      textFont: 'var(--font-family)',
      fontSize: 18,
    })
    .isRemovable(false)
    .build(),

  getQuickLinkWidget({
    text: 'Beneficiary List',
    position: {
      y: 30,
      rows: 2,
      cols: 15,
    } as GridPosition,
    pageUrl: 'beneficiary-list',
    displayName: 'Quick Link: Beneficiary List',
    styles: {
      color: 'var(--palette-color-3)',
      borderWidth: '1',
      borderColor: 'var(--palette-color-3)',
      backGroundColor: 'var(--palette-color-6)',
      fontSize: '14',
      textFont: 'var(--font-family)',
      textAlignment: 'center',
      justifyContent: 'center',
      borderRadiusTopLeft: '5',
      borderRadiusTopRight: '5',
      borderRadiusBottomLeft: '5',
      borderRadiusBottomRight: '5',
    },
    zone: APP_ZONES.BENEFICIARY_ZONE,
    isRemovable: true,
    globalStyling: true,
    globalStylingClass: `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`,
  }),

  new BackButtonWidgetBuilder()
    .defaultBackButton()
    .cssConfig({
      color: 'var(--palette-color-3)',
      backGroundColor: 'var(--palette-color-6)',
      borderColor: 'var(--palette-color-3)',
      borderWidth: '1',
      textAlignment: 'center',
      justifyContent: 'center',
      textFont: 'var(--font-family)',
      fontSize: '14',
    })
    .text('Back')
    .configDisplayName('Button: Back Navigation')
    .gridPosition({ y: 33, rows: 2, cols: 15 } as GridPosition)
    .isRemovable(true)
    .setGlobalStylingClass(true, `${ButtonTypes.SECONDARY} ${ButtonSizeOptions.FULL_WIDTH}`)
    .build(),
];

export const notificationBeneficiaryRemoveWidgets = [
  commonNotificationImageWidget({
    configDisplayName: 'Image Widget: User Remove Icon',
    attributes: {
      widgetProperties: {
        textContent: '',
        imageAssetUrl: 'assets/icon-library/user-remove.svg',
      } as BaseWidgetProperties,
    },
    gridPosition: { y: 0, rows: 2 } as GridPosition,
  }),

  commonNotificationHeaderTextWidget({
    text: '<div class="w-100" ><p class="m-0">Beneficiary Removed</p></div>',
    configDisplayName: 'Notification Header',
    css_config: {
      color: 'var(--palette-color-0)',
      textAlignment: 'center',
      justifyContent: 'center',
      textFont: 'var(--font-family)',
      fontSize: '20',
      isTextBold: true,
    },
    gridPosition: { y: 2, rows: 2 } as GridPosition,
  }),

  new TextWidgetBuilder()
    .text(
      '<div class="w-100"><p>The beneficiary has been removed. Please review the details of the removed beneficiary.</p></div>',
    )
    .configDisplayName('Text Widget:Info')
    .gridPosition({ y: 4, rows: 3 } as GridPosition)
    .cssConfig({
      color: 'var(--palette-color-1)',
      textAlignment: 'center',
      justifyContent: 'center',
      textFont: 'var(--font-family)',
      fontSize: '14',
    })
    .isRemovable(false)
    .build(),

  new TextWidgetBuilder()
    .text(notificationBeneficiaryRemoveTemplate)
    .configDisplayName('Beneficiary Info')
    .gridPosition({ y: 7, rows: 22 } as GridPosition)
    .cssConfig({
      backGroundColor: 'var(--palette-color-5)',
      paddingLeft: '20',
      paddingRight: '20',
      borderRadiusTopLeft: '5',
      borderRadiusTopRight: '5',
      borderRadiusBottomLeft: '5',
      borderRadiusBottomRight: '5',
      display: 'flex',
      verticalAlignment: 'start',
      textAlignment: 'left',
      textFont: 'var(--font-family)',
      fontSize: 18,
    })
    .isRemovable(false)
    .build(),

  getQuickLinkWidget({
    text: 'Beneficiary List',
    position: {
      y: 30,
      rows: 2,
      cols: 15,
    } as GridPosition,
    pageUrl: 'beneficiary-list',
    displayName: 'Quick Link: Beneficiary List',
    styles: {
      color: 'var(--palette-color-3)',
      borderWidth: '1',
      borderColor: 'var(--palette-color-3)',
      backGroundColor: 'var(--palette-color-6)',
      fontSize: '14',
      textFont: 'var(--font-family)',
      textAlignment: 'center',
      justifyContent: 'center',
      borderRadiusTopLeft: '5',
      borderRadiusTopRight: '5',
      borderRadiusBottomLeft: '5',
      borderRadiusBottomRight: '5',
    },
    zone: APP_ZONES.BENEFICIARY_ZONE,
    isRemovable: true,
    globalStyling: true,
    globalStylingClass: `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`,
  }),

  new BackButtonWidgetBuilder()
    .defaultBackButton()
    .cssConfig({
      color: 'var(--palette-color-3)',
      backGroundColor: 'var(--palette-color-6)',
      borderColor: 'var(--palette-color-3)',
      borderWidth: '1',
      textAlignment: 'center',
      justifyContent: 'center',
      textFont: 'var(--font-family)',
      fontSize: '14',
    })
    .text('Back')
    .configDisplayName('Button: Back Navigation')
    .gridPosition({ y: 33, rows: 2, cols: 15 } as GridPosition)
    .isRemovable(true)
    .setGlobalStylingClass(true, `${ButtonTypes.SECONDARY} ${ButtonSizeOptions.FULL_WIDTH}`)
    .build(),
];
