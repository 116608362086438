import { FormActionTypeEnum } from '@finxone-platform/form-action';
import {
  addEmployeeExtraInformationTemplate,
  addEmployeeLoginDetails,
  addEmployeePersonalInformationTemplate,
  addEmployeePositionSummaryTemplate,
} from '@finxone-platform/shared/default-templates';
import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonActionType,
  ENTITY_TYPE,
  GridPosition,
  SINK_TYPE,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import {
  createdSuccessfullySubTitleTextWidget,
  createdSuccessfullyTextWidget,
} from '../../../../page-builder-utils/widget-builder-utils/desktop-page-utils/success-and-error-page.utils';
import {
  AddEmployeeStep1WidgetsBuilder,
  AddEmployeeStep2WidgetsBuilder,
  BackButtonWidgetBuilder,
  EmailWidgetBuilder,
  FormSubmissionButtonWidgetBuilder,
  ProfilePictureDisplayWidgetBuilder,
  ProfilePictureUploadWidgetBuilder,
} from '../../../widgets/example-builder';
import {
  brokerSummaryTextWidget,
  commonLineWidget,
  generateMultiQuickLinkWidget,
} from '../../common/common-widgets';
import {
  backButtonQuickLink,
  headerSubTitle,
  zoneHeaderTitle,
} from '../../organisation-user/organisation-profile/utils';
import {
  boxLineFailedTextWidget,
  boxLineSuccessTextWidget,
  customQuestion,
  discardQuickLinkFailedPage,
  doneQuickLinkSuccessPage,
  failedImageWidget,
  failedSubTitleTextWidget,
  failedTitleTextWidget,
  headerProgressBar,
  headerStepTitle,
  successImageWidget,
  topRightBackButton,
  topRightNextButtonQuickLink,
  tryAgainQuickLinkFailedPage,
} from './utils';

const commonElementsConfig = [
  {
    field_name: 'isShowErrorIcon',
    label: 'Show Error Icon',
    section: 'signup_widget',
    type: 'text',
    show: false,
    options: [],
  },
  {
    field_name: 'isShowSuccessIcon',
    label: 'Show Success Icon',
    section: 'signup_widget',
    type: 'text',
    show: false,
    options: [],
  },
];

const commonEditDetailsTextWidget = ({ gridPosition }: { gridPosition: GridPosition }) =>
  headerSubTitle({
    textContent: 'Please edit the details if there are any changes you see.',
    gridPosition,
    cssConfig: {
      paddingLeft: 5,
      fontSize: 14,
      textAlignment: 'left',
      color: 'var(--typography-color-1)',
    },
  });

const commonEditDetailsMultiIconQuickLinkWidget = ({
  gridPosition,
  displayName,
  navigateToPageUrl,
}: {
  gridPosition: GridPosition;
  displayName: string;
  navigateToPageUrl: string;
}) =>
  generateMultiQuickLinkWidget({
    zoneName: APP_ZONES.USER_MANAGEMENT,
    navigateToPageUrl: navigateToPageUrl,
    displayName: `Multi Quick Link: ${displayName}`,
    textContent: displayName,
    gridPosition: gridPosition,
  });

const commonAddEmployeeWidgets = [
  zoneHeaderTitle({
    cssConfig: {
      verticalAlignment: 'end',
      marginBottom: 5,
      marginLeft: 0,
      paddingLeft: 0,
    },
    textContent: 'Add Employee',
    gridPosition: {
      y: 0,
      x: 0,
      rows: 2,
      cols: 10,
    } as GridPosition,
  }),

  commonLineWidget({
    gridPosition: {
      y: 2,
      x: 0,
      rows: 1,
      cols: 41,
      layerIndex: 1,
    } as GridPosition,
    configDisplayName: 'Assign Position - Header Bottom Line',
    cssConfig: {
      marginLeft: 0,
      marginUnit: 'px',
    },
  }),
];

const commonHeaderWidgetsStep2Step3 = [...commonAddEmployeeWidgets, topRightBackButton];

export const assignPositionWidgets = [
  ...commonAddEmployeeWidgets,

  backButtonQuickLink({
    gridPosition: {
      y: 0,
      x: 35,
      rows: 2,
      cols: 6,
    } as GridPosition,
    cssConfig: {
      marginTop: 35,
      marginBottom: 0,
    },
  }),

  customQuestion({
    gridPosition: {
      y: 3,
      x: 0,
    } as GridPosition,
    label: 'Position',
    questionUniqueId: 'employee position',
    questionType: 'dropdown',
    inputType: 'dropdown',
    entityType: ENTITY_TYPE.EMPLOYEE,
    sink: SINK_TYPE.METADATA,
    data_type: 'stringArray',
    questionOptions: [
      { text: 'Captain', value: 'Captain', suboption: [] },
      { text: 'First Mate', value: 'First Mate', suboption: [] },
      { text: 'Deckhand', value: 'Deckhand', suboption: [] },
      { text: 'Engineer', value: 'Engineer', suboption: [] },
      {
        text: 'Steward/Stewardess',
        value: 'Steward/Stewardess',
        suboption: [],
      },
      { text: 'Chef', value: 'Chef', suboption: [] },
      { text: 'Bartender', value: 'Bartender', suboption: [] },
      {
        text: 'Security Officer',
        value: 'Security Officer',
        suboption: [],
      },
      {
        text: 'Guest Services Representative',
        value: 'Guest Services Representative',
        suboption: [],
      },
      {
        text: 'Maintenance Crew',
        value: 'Maintenance Crew',
        suboption: [],
      },
      {
        text: 'Dive Instructor',
        value: 'Dive Instructor',
        suboption: [],
      },
      {
        text: 'Water Sports Instructor',
        value: 'Water Sports Instructor',
        suboption: [],
      },
      {
        text: 'Entertainment Coordinator',
        value: 'Entertainment Coordinator',
        suboption: [],
      },
      {
        text: 'Medical Officer',
        value: 'Medical Officer',
        suboption: [],
      },
      { text: 'Concierge', value: 'Concierge', suboption: [] },
    ],
  }),

  new EmailWidgetBuilder()
    .configDisplayName(`Email Widget: Employee's Email`)
    .cssConfig({
      borderWidth: '0',
      color: 'var(--typography-color-1)',
      marginLeft: 0,
      marginRight: 0,
    })
    .attributes({
      elementsConfig: [
        {
          field_name: 'verificationViaOTP',
          label: 'Check for Email Validation (OTP)',
          section: 'signup_widget',
          type: 'text',
          show: false,
          options: [],
        },
        ...commonElementsConfig,
      ],
    } as unknown as WorkflowWidgetConfigurationAttributes)
    .isRemovable(true)
    .gridPosition({
      y: 6,
      x: 0,
      rows: 3,
      cols: 13,
    } as GridPosition)
    .build(),

  new FormSubmissionButtonWidgetBuilder()
    .configDisplayName('Button: Invite')
    .attributes({
      widgetProperties: {
        textContent: 'Invite',
        zoneToNavigate: APP_ZONES.USER_MANAGEMENT,
        urlToNavigate: 'add-employee-step-1',
        buttonActionType: ButtonActionType.ADD_EMPLOYEE_INVITE,
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .cssConfig({
      backGroundColor: 'var(--palette-color-3)',
      color: 'var(--palette-color-6)',
      borderRadiusTopLeft: 6,
      borderRadiusBottomLeft: 6,
      borderRadiusTopRight: 6,
      borderRadiusBottomRight: 6,
      fontSize: 14,
      marginTop: 0,
      marginBottom: 0,
      paddingTop: 10,
      paddingBottom: 15,
      paddingLeft: 15,
      paddingRight: 15,
      borderWidth: '1',
      borderColor: 'var(--palette-color-3)',
    })
    .isRemovable(false)
    .gridPosition({ x: 0, y: 9, rows: 1, cols: 6 } as GridPosition)
    .build(),
];

export const addEmployeeStep1Widgets = [
  ...commonAddEmployeeWidgets,

  topRightNextButtonQuickLink({
    textContent: 'Next',
    navigateToPageUrl: 'add-employee-step-2',
    zone: APP_ZONES.USER_MANAGEMENT,
  }),

  headerSubTitle({
    textContent: 'Add Employee - Name and Contact Details',
    gridPosition: {
      layerIndex: 0,
    } as GridPosition,
    cssConfig: {
      marginLeft: 0,
      paddingLeft: 0,
    },
  }),
  headerStepTitle({
    textContent: 'Step 1/3',
    gridPosition: {
      layerIndex: 0,
    } as GridPosition,
  }),

  headerProgressBar({
    completionProgressPercentage: '33',
    cssConfig: {
      marginTop: 30,
      marginLeft: 0,
      marginRight: 15,
      paddingLeft: 0,
    },
    gridPosition: {
      y: 4,
      layerIndex: 1,
    } as GridPosition,
  }),

  headerSubTitle({
    textContent: `Employee with email {{ formSubmissionFormData.email }} does not exist. Please add new employee to grant access and add.`,
    gridPosition: {
      y: 6,
      cols: 30,
    } as GridPosition,
    cssConfig: {
      marginLeft: 0,
      paddingLeft: 0,
      fontSize: 18,
      color: 'var(--typography-color-1)',
    },
  }),

  new AddEmployeeStep1WidgetsBuilder()
    .configDisplayName(`Add Employee Step 1 Widget`)
    .cssConfig({
      borderWidth: '0',
      color: 'var(--typography-color-1)',
    })
    .gridPosition({
      y: 8,
      x: 0,
      rows: 10,
      cols: 13,
    } as GridPosition)
    .build(),
];

export const addEmployeeStep2Widgets = [
  ...commonHeaderWidgetsStep2Step3,

  topRightNextButtonQuickLink({
    textContent: 'Next',
    navigateToPageUrl: 'add-employee-step-3',
    zone: APP_ZONES.USER_MANAGEMENT,
  }),

  headerSubTitle({
    cssConfig: {
      marginLeft: 0,
      paddingLeft: 0,
    },
    gridPosition: {
      layerIndex: 0,
    } as GridPosition,
    textContent: 'Add Employee - Login Details',
  }),
  headerStepTitle({
    gridPosition: {
      layerIndex: 0,
    } as GridPosition,
    textContent: 'Step 2/3',
  }),

  headerProgressBar({
    gridPosition: {
      y: 4,
      layerIndex: 1,
    } as GridPosition,
    cssConfig: {
      marginTop: 30,
      marginLeft: 0,
      marginRight: 0,
      paddingLeft: 0,
    },
    completionProgressPercentage: '66',
  }),

  new AddEmployeeStep2WidgetsBuilder()
    .configDisplayName(`Add Employee Step 2 Widget`)
    .cssConfig({
      borderWidth: '0',
      color: 'var(--typography-color-1)',
    })
    .gridPosition({
      y: 6,
      x: 0,
      rows: 10,
      cols: 13,
    } as GridPosition)
    .build(),
];

export const addEmployeeStep3Widgets = [
  ...commonHeaderWidgetsStep2Step3,

  topRightNextButtonQuickLink({
    zone: APP_ZONES.USER_MANAGEMENT,
    navigateToPageUrl: 'add-employee-summary-details',
    textContent: 'Next',
  }),

  headerSubTitle({
    textContent: 'Add Employee - Extra Information',
    gridPosition: {
      layerIndex: 0,
    } as GridPosition,
    cssConfig: {
      marginLeft: 0,
      paddingLeft: 0,
    },
  }),
  headerStepTitle({
    textContent: 'Step 3/3',
    gridPosition: {
      layerIndex: 0,
    } as GridPosition,
  }),

  headerProgressBar({
    completionProgressPercentage: '100',
    cssConfig: {
      marginTop: 30,
      marginLeft: 0,
      marginRight: 0,
      paddingLeft: 0,
    },
    gridPosition: {
      y: 4,
      layerIndex: 1,
    } as GridPosition,
  }),

  customQuestion({
    questionUniqueId: 'employee about',
    gridPosition: {
      y: 6,
      x: 0,
    } as GridPosition,
    label: 'About',
    inputType: 'text',
    questionType: 'text',
    data_type: 'string',
    sink: SINK_TYPE.METADATA,
    entityType: ENTITY_TYPE.EMPLOYEE,
  }),

  customQuestion({
    gridPosition: {
      y: 9,
      x: 0,
    } as GridPosition,
    label: 'Interests',
    questionUniqueId: 'employee interests',
    questionType: 'text',
    inputType: 'text',
    entityType: ENTITY_TYPE.EMPLOYEE,
    sink: SINK_TYPE.METADATA,
    data_type: 'string',
  }),

  new ProfilePictureUploadWidgetBuilder()
    .attributes({
      profilePictureConfiguration: {
        selectedState: 'add_employee',
        formStateId: FormActionTypeEnum.ADD_EMPLOYEE,
        pictureKey: 'profilePicture',
      },
    } as unknown as WorkflowWidgetConfigurationAttributes)
    .gridPosition({
      y: 13,
      x: 0,
      rows: 6,
      cols: 13,
    } as GridPosition)
    .build(),
];

export const addEmployeeSummaryDetailsWidgets = [
  ...commonAddEmployeeWidgets,

  new BackButtonWidgetBuilder()
    .defaultBackButton()
    .configDisplayName('Back Navigation Button')
    .text('Discard')
    .cssConfig({
      color: 'var(--palette-color-3)',
      backGroundColor: 'var(--palette-color-6)',
      borderWidth: 1,
      borderColor: 'var(--palette-color-3)',
      fontSize: 14,
      paddingLeft: 20,
      paddingRight: 20,
      marginTop: 30,
      marginBottom: 0,
      textAlign: 'center',
      verticalAlignment: 'center',
    })
    .gridPosition({
      y: 0,
      x: 29,
      rows: 2,
      cols: 5,
      resizeEnabled: true,
      dragEnabled: true,
    })
    .build(),

  new FormSubmissionButtonWidgetBuilder()
    .configDisplayName('Button: Confirm & Save')
    .attributes({
      widgetProperties: {
        textContent: 'Confirm & Save',
        zoneToNavigate: APP_ZONES.USER_MANAGEMENT,
        urlToNavigate: 'assign-success',
        buttonActionType: ButtonActionType.ADD_EMPLOYEE_CONFIRM_SAVE,
      } as BaseWidgetProperties,
      profilePictureConfiguration: {
        pictureKey: 'profilePicture',
      },
    } as unknown as WorkflowWidgetConfigurationAttributes)
    .cssConfig({
      backGroundColor: 'var(--palette-color-3)',
      color: 'var(--palette-color-6)',
      borderRadiusTopLeft: 6,
      borderRadiusBottomLeft: 6,
      borderRadiusTopRight: 6,
      borderRadiusBottomRight: 6,
      fontSize: 14,
      marginTop: 30,
      marginBottom: 5,
      paddingTop: 0,
      paddingBottom: 25,
      paddingLeft: 15,
      paddingRight: 15,
      borderWidth: '1',
      borderColor: 'var(--palette-color-3)',
    })
    .isRemovable(false)
    .gridPosition({ y: 0, x: 35, rows: 2, cols: 6 } as GridPosition)
    .build(),

  headerSubTitle({
    textContent: 'Summary Details',
    cssConfig: { color: 'var(--typography-color-1)', paddingLeft: 0 },
  }),

  new ProfilePictureDisplayWidgetBuilder()
    .gridPosition({
      y: 3,
      x: 38,
      rows: 3,
      cols: 3,
    } as GridPosition)
    .attributes({
      profilePictureConfiguration: {
        selectedState: 'add_employee',
        formStateId: FormActionTypeEnum.ADD_EMPLOYEE,
        pictureKey: 'profilePicture',
      },
    } as unknown as WorkflowWidgetConfigurationAttributes)
    .build(),

  commonEditDetailsMultiIconQuickLinkWidget({
    displayName: 'Personal Information',
    navigateToPageUrl: 'add-employee-step-1',
    gridPosition: {
      y: 6,
      x: 0,
      rows: 2,
      cols: 12,
    } as GridPosition,
  }),

  commonEditDetailsTextWidget({
    gridPosition: {
      y: 8,
      x: 0,
      rows: 1,
      cols: 12,
    } as GridPosition,
  }),

  brokerSummaryTextWidget({
    textContent: addEmployeePersonalInformationTemplate,
    gridPosition: {
      y: 10,
      x: 0,
      rows: 9,
      cols: 12,
    } as GridPosition,
    displayName: 'Personal Information',
  }),

  commonEditDetailsMultiIconQuickLinkWidget({
    displayName: 'Login Details',
    navigateToPageUrl: 'add-employee-step-2',
    gridPosition: {
      y: 6,
      x: 13,
      rows: 2,
      cols: 12,
    } as GridPosition,
  }),

  commonEditDetailsTextWidget({
    gridPosition: {
      y: 8,
      x: 13,
      rows: 1,
      cols: 12,
    } as GridPosition,
  }),

  brokerSummaryTextWidget({
    textContent: addEmployeeLoginDetails,
    gridPosition: {
      y: 10,
      x: 13,
      rows: 4,
      cols: 12,
    } as GridPosition,
    displayName: 'Login Details',
  }),

  commonEditDetailsMultiIconQuickLinkWidget({
    displayName: 'Employee Position',
    navigateToPageUrl: 'assign-position',
    gridPosition: {
      y: 15,
      x: 13,
      rows: 2,
      cols: 12,
    } as GridPosition,
  }),

  commonEditDetailsTextWidget({
    gridPosition: {
      y: 17,
      x: 13,
      rows: 1,
      cols: 12,
    } as GridPosition,
  }),

  brokerSummaryTextWidget({
    textContent: addEmployeePositionSummaryTemplate,
    gridPosition: {
      y: 19,
      x: 13,
      rows: 4,
      cols: 12,
    } as GridPosition,
    displayName: 'Employee Position Summary',
  }),

  commonEditDetailsMultiIconQuickLinkWidget({
    displayName: 'Extra Information',
    navigateToPageUrl: 'add-employee-step-3',
    gridPosition: {
      y: 6,
      x: 26,
      rows: 2,
      cols: 15,
    } as GridPosition,
  }),

  commonEditDetailsTextWidget({
    gridPosition: {
      y: 8,
      x: 26,
      rows: 1,
      cols: 15,
    } as GridPosition,
  }),

  brokerSummaryTextWidget({
    textContent: addEmployeeExtraInformationTemplate,
    gridPosition: {
      y: 10,
      x: 26,
      rows: 15,
      cols: 15,
    } as GridPosition,
    displayName: 'Employee Extra Information',
  }),
];

export const addEmployeeSuccessWidgets = [
  ...commonAddEmployeeWidgets,

  successImageWidget({
    gridPosition: {
      y: 7,
      x: 17,
      rows: 4,
      cols: 5,
      layerIndex: 1,
    } as GridPosition,
  }),

  createdSuccessfullyTextWidget({
    textContent: 'Employee added successfully!',
    gridPosition: {
      y: 12,
      x: 14,
      rows: 2,
      cols: 11,
      layerIndex: 1,
    } as GridPosition,
  }),

  createdSuccessfullySubTitleTextWidget({
    textContent: 'New employees will be sent an invite',
    gridPosition: {
      y: 14,
      x: 14,
      rows: 2,
      cols: 11,
      layerIndex: 1,
    } as GridPosition,
  }),

  doneQuickLinkSuccessPage({
    zone: APP_ZONES.USER_MANAGEMENT,
    navigateToPageUrl: 'employee-listing',
  }),

  boxLineSuccessTextWidget({
    gridPosition: {
      y: 5,
      x: 11,
      rows: 15,
      cols: 17,
      layerIndex: 0,
    } as GridPosition,
  }),
];

export const addEmployeeFailedWidgets = [
  ...commonAddEmployeeWidgets,

  failedImageWidget({
    gridPosition: {
      y: 7,
      x: 17,
      rows: 4,
      cols: 5,
      layerIndex: 1,
    } as GridPosition,
  }),

  failedTitleTextWidget({
    textContent: 'Oops! Employee could not be added.',
    gridPosition: {
      y: 11,
      x: 13,
      rows: 2,
      cols: 13,
      layerIndex: 1,
    } as GridPosition,
  }),

  failedSubTitleTextWidget({
    textContent: 'We seem to be having a server problem.',
    gridPosition: {
      y: 13,
      x: 14,
      rows: 2,
      cols: 11,
      layerIndex: 1,
    } as GridPosition,
  }),

  discardQuickLinkFailedPage({
    zone: APP_ZONES.USER_MANAGEMENT,
    navigateToPageUrl: 'employee-listing',
    gridPosition: {
      y: 15,
      x: 15,
      rows: 2,
      cols: 4,
      layerIndex: 1,
    } as GridPosition,
  }),

  tryAgainQuickLinkFailedPage({
    zone: APP_ZONES.USER_MANAGEMENT,
    navigateToPageUrl: 'assign-position',
    gridPosition: {
      y: 15,
      x: 20,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 4,
      layerIndex: 1,
    } as GridPosition,
  }),

  boxLineFailedTextWidget({
    gridPosition: {
      y: 6,
      x: 12,
      rows: 13,
      cols: 15,
      layerIndex: 0,
    } as GridPosition,
  }),
];
