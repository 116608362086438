import { FormActionTypeEnum } from '@finxone-platform/form-action';
import { APP_ZONES, ButtonActionType, GridPosition } from '@finxone-platform/shared/sys-config-types';
import { UploadFileWithPopupWidgetBuilder } from '../../../../widgets/example-builder';
import { commonBackButtonQuickLink, commonCTAButton, commonTextWidget } from '../../../common/common-widgets';

export const tripZoneAddTransactionStep1Widgets = [
  commonBackButtonQuickLink({
    cssConfig: {
      iconSize: 35,
      iconUnit: 'px',
    },
    gridPosition: {
      y: 0,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 2,
      layerIndex: 1,
    },
  }),

  commonCTAButton({
    zoneToNavigate: APP_ZONES.REVENIR_ZONE,
    urlToNavigate: 'add-transaction-step-2',
    textContent: 'Save',
    buttonActionType: ButtonActionType.ADD_TRANSACTION_STEP_1,
    formKey: FormActionTypeEnum.ADD_TRIP_REVENIR_ZONE,
    gridPosition: {
      y: 0,
      x: 13,
      rows: 2,
      cols: 2,
      layerIndex: 1,
    } as GridPosition,
    cssConfig: {
      color: 'var(--typography-color-0)',
      backGroundColor: 'var(--palette-color-6)',
      fontSize: 20,
      marginTop: 0,
      marginBottom: 0,
    },
  }),

  commonTextWidget({
    textContent: 'Add Transaction',
    configDisplayName: 'Add Transaction',
    cssConfig: {
      fontSize: 24,
      isTextBold: true,
    },
    gridPosition: {
      y: 2,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 15,
      layerIndex: 0,
    },
  }),

  new UploadFileWithPopupWidgetBuilder()
    .addCustomWidgetProperties({
      formKey: FormActionTypeEnum.ADD_TRIP_REVENIR_ZONE,
      textContent: `
        <div class="is-vertical-align is-horizontal-align align-items-center">
          <span class="font-size-inherit">
            Add Till Slip
          </span>

          <span class="mt-3">
            {{{ imageFromImageName 'icon-library' 'camera' '40px' }}}
          </span>

        </div
      `,
    })
    .gridPosition({
      y: 6,
      x: 0,
      rows: 11,
      cols: 15,
    } as GridPosition)
    .cssConfig({
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 12,
      paddingRight: 12,
      color: 'var(--typography-color-0)',
      backGroundColor: '#ffffff00',
      borderWidth: 1,
      borderColor: 'var(--border-color-1)',
    })
    .build(),
];
