import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { FormActionState } from '@app/finxone-web-frontend/app/lib/state/form-submision.state';
import { BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { UpdateCurrentAccountId } from '../../../actions/account.action';
import { AccountState } from '../../../state/account.state';
import { redirectToPage } from '../cta-button-actions.utils';

/**
 * Checks the source account for a matching currency with the international beneficiary
 * and redirects accordingly. If a matching currency account exists, updates the current
 * account ID and redirects to the payment page for the international beneficiary. If no
 * matching account is found, redirects to a page indicating the absence of a source
 * currency account.
 *
 * @param widgetProperties - The properties of the widget used for navigation.
 * @param injector - The Angular injector used to retrieve services.
 */
export function checkSourceAccountAndRedirectToPayNewIntlBeneficiary(
  widgetProperties: BaseWidgetProperties,
  injector: Injector,
) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  //get beneficiary from form state
  const beneficiary = store.selectSnapshot(
    FormActionState.getFormActionStateWithId('intlBeneficiaryAdd'),
  )?.formData;
  //check accounts state for matching currency
  const accountsData = store.selectSnapshot(AccountState.getAccounts).accounts;
  const currencyAccount = accountsData.find((v) => v.currency === beneficiary.currency);
  //if exists update current account and redirect to pay intl bene
  if (currencyAccount) {
    store.dispatch(new UpdateCurrentAccountId(currencyAccount.accountId));
    redirectToPage(router, widgetProperties, injector);
  } else {
    const parsedWidgetProperties = JSON.parse(JSON.stringify(widgetProperties));
    parsedWidgetProperties['urlToNavigate'] = `intl-no-source-currency-account`;
    redirectToPage(router, parsedWidgetProperties, injector);
  }
}
