import { TransferMoneyRequest } from '../services/account-service/account.type';

export class CreatePayment {
  static readonly type = '[Payment] CreatePayment';
  constructor(public paymentRequest: TransferMoneyRequest) {}
}

export class InternationalCreatePayment {
  static readonly type = '[Payment] InternationalCreatePayment';
  constructor(public paymentRequest: TransferMoneyRequest) {}
}

export class PaymentSuccess {
  static readonly type = '[Payment] PaymentSuccess';
}

export class PaymentDeclined {
  static readonly type = '[Payment] PaymentDeclined';
}

export class InternationalPaymentSuccess {
  static readonly type = '[Payment] PaymentSuccess';
}

export class InternationalPaymentDeclined {
  static readonly type = '[Payment] InternationalPaymentDeclined';
}

export class CreateBulkPayment {
  static readonly type = '[Payment] CreateBulkPayment';
  constructor(public id: string) {}
}
export class BulkPaymentSuccess {
  static readonly type = '[Payment] BulkPaymentSuccess';
}

export class BulkPaymentDeclined {
  static readonly type = '[Payment] BulkPaymentDeclined';
}

export class BulkPaymentError {
  static readonly type = '[Payment] BulkPaymentError';
}

export class ApproveOrRejectBulkPayment {
  static readonly type = '[Payment] ApproveOrRejectBulkPayment';
  constructor(public id: string, public recordId: string, public status: string) {}
}
