import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { FormActionTypeEnum } from '@finxone-platform/form-action';
import { APP_ZONES, BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { map, take } from 'rxjs';
import { ResetFormDataAction } from '../../../actions/form-submission.action';
import { UserProfileUpdated } from '../../../actions/user-profile.action';
import { FormSubmissionService } from '../../../services/form-submission-service/form-submission-service.service';
import { FormActionState } from '../../../state/form-submision.state';
import { mapFormData } from '../../utils';
import { redirectToPage } from '../cta-button-actions.utils';

/**
 * Submits changes to the user profile in the profile zone.
 *
 * This function retrieves necessary services and state data using the provided injector.
 * It maps the form data and checks if the email and phone fields are verified.
 * If verified, it submits the profile update request and listens for the
 * `UserProfileUpdated` action. Upon dispatch, it redirects to the profile page
 * and resets the form data.
 *
 * @param injector - The Angular Injector used to retrieve services and state.
 */
export function submitProfileZoneUserProfileChanges(
  injector: Injector,
  widgetProperties: BaseWidgetProperties,
) {
  const store = injector.get(Store);
  const actions = injector.get(Actions);
  const router = injector.get(Router);
  const formSubmissionService = injector.get(FormSubmissionService);
  const formStateData = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.EDIT_PROFILE_DETAILS),
  );
  const mappedFormData = mapFormData(formStateData?.formData);
  const verifiableFieldsVerified = mappedFormData['isEmailVerified'] && mappedFormData['isPhoneVerified'];
  if (!widgetProperties['zoneToNavigate']) {
    widgetProperties['zoneToNavigate'] = APP_ZONES.PROFILE;
  }
  const parsedWidgetProperties = JSON.parse(JSON.stringify(widgetProperties));
  if (verifiableFieldsVerified) {
    formSubmissionService.submitProfileUpdateRequest(mappedFormData);
    actions
      .pipe(
        ofActionDispatched(UserProfileUpdated),
        take(1),
        map(() => {
          parsedWidgetProperties['urlToNavigate'] = 'profile';
          redirectToPage(router, widgetProperties, injector);
          store.dispatch(new ResetFormDataAction());
        }),
      )
      .subscribe();
  }
}
