export const notificationBeneficiaryEditTemplate = `<div class="w-100 h-100">
  <div class="d-flex my-4">
    <div class="col-6-xs">
      <div class="text-label">Currency</div>
      <div class=""> {{{notification.selectedNotification.destinationIdentifier.currency}}} </div>
    </div>
    {{#if notification.selectedNotification.address}}
    <div class="col-6-xs">
      <div class="text-label">Country</div>
      <div class=""> {{{ countryFullName notification.selectedNotification.address.country}}} </div>
    </div>
    {{/if}}
  </div>
  <hr class='my-1 mx-1'>
   <div class="d-flex">
    <div class="col-12">
      <div class="text-label">Type</div>
      <div class="text-capitalize"> {{{notification.selectedNotification.accountType}}} </div>
    </div>
  </div>
  <div class="d-flex">
    <div class="col-12">
      <div class="text-label">Full name</div>
      <div class=""> {{{notification.selectedNotification.name}}} </div>
    </div>
  </div>
  {{#if notification.selectedNotification.destinationIdentifier.accountNumber}}
   <div class="d-flex">
     <div class="col-12">
       <div class="text-label">Account number</div>
       <div class=""> {{{notification.selectedNotification.destinationIdentifier.accountNumber}}} </div>
     </div>
    </div>
  {{/if}}
  {{#if notification.selectedNotification.destinationIdentifier.aba}}
  <div class="d-flex">
    <div class="col-12">
      <div class="text-label">ABA routing number</div>
      <div class=""> {{{notification.selectedNotification.destinationIdentifier.aba}}} </div>
    </div>
  </div>
  {{/if}}
  {{#if notification.selectedNotification.destinationIdentifier.bic}}
  <div class="d-flex">
    <div class="col-12">
      <div class="text-label">SWIFT/BIC Code</div>
      <div class=""> {{{notification.selectedNotification.destinationIdentifier.bic}}} </div>
    </div>
  </div>
  {{/if}}
  {{#if notification.selectedNotification.destinationIdentifier.bankName}}
  <div class="d-flex">
    <div class="col-12">
      <div class="text-label">Bank Name</div>
      <div class=""> {{{notification.selectedNotification.destinationIdentifier.bankName}}} </div>
    </div>
  </div>
  {{/if}}
  <hr class='my-1 mx-1'>
  <div class="d-flex">
    <div class="col-12">
      <div class="text-label">Edited on:</div>
      {{#if notification.selectedNotification.updatedDate}}
      <div class=""> {{datePipe notification.selectedNotification.updatedDate format="d MMM y"}} at {{datePipe notification.selectedNotification.updatedDate format='h:mm a'}} </div>
      {{/if}}
    </div>
  </div>
</div>`;

export const notificationBeneficiaryRemoveTemplate = `<div class="w-100 h-100">
  <div class="d-flex my-4">
    <div class="col-6-xs">
      <div class="text-label">Currency</div>
      <div class=""> {{{notification.selectedNotification.destinationIdentifier.currency}}} </div>
    </div>
    {{#if notification.selectedNotification.address}}
    <div class="col-6-xs">
      <div class="text-label">Country</div>
      <div class=""> {{{countryFullName notification.selectedNotification.address.country}}} </div>
    </div>
    {{/if}}
  </div>
  <hr class='my-1 mx-1'>
   <div class="d-flex">
    <div class="col-12">
      <div class="text-label">Type</div>
      <div class="text-capitalize"> {{{notification.selectedNotification.accountType}}} </div>
    </div>
  </div>
  <div class="d-flex">
    <div class="col-12">
      <div class="text-label">Full name</div>
      <div class=""> {{{notification.selectedNotification.name}}} </div>
    </div>
  </div>
  {{#if notification.selectedNotification.destinationIdentifier.accountNumber}}
   <div class="d-flex">
     <div class="col-12">
       <div class="text-label">Account number</div>
       <div class=""> {{{notification.selectedNotification.destinationIdentifier.accountNumber}}} </div>
     </div>
    </div>
  {{/if}}
  {{#if notification.selectedNotification.destinationIdentifier.aba}}
  <div class="d-flex">
    <div class="col-12">
      <div class="text-label">ABA routing number</div>
      <div class=""> {{{notification.selectedNotification.destinationIdentifier.aba}}} </div>
    </div>
  </div>
  {{/if}}
  {{#if notification.selectedNotification.destinationIdentifier.bic}}
  <div class="d-flex">
    <div class="col-12">
      <div class="text-label">SWIFT/BIC Code</div>
      <div class=""> {{{notification.selectedNotification.destinationIdentifier.bic}}} </div>
    </div>
  </div>
  {{/if}}
  {{#if notification.selectedNotification.destinationIdentifier.bankName}}
  <div class="d-flex">
    <div class="col-12">
      <div class="text-label">Bank Name</div>
      <div class=""> {{{notification.selectedNotification.destinationIdentifier.bankName}}} </div>
    </div>
  </div>
  {{/if}}
  <hr class='my-1 mx-1'>
  <div class="d-flex">
    <div class="col-12">
      <div class="text-label">Removed on:</div>
      {{#if notification.selectedNotification.deletedDate}}
      <div class=""> {{datePipe notification.selectedNotification.deletedDate format="d MMM y"}} at {{datePipe notification.selectedNotification.deletedDate format='h:mm a'}}</div>
      {{/if}}
    </div>
  </div>
</div>`;
