import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import {
  InternationBeneficiaryFormStateModel,
  mapInternationBeneficiaryFormStateModelToAddBeneficiaryRequest,
} from '@app/finxone-web-frontend/app/Modules/shared/widgets/international-beneficiary-credentials/international-beneficiary-credentials.component';
import { FormActionTypeEnum, OtpParam } from '@finxone-platform/form-action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { APP_ZONES, BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { AccountService } from '../../../services/account-service/account-service.service';
import { GetBeneficiaryResponse } from '../../../services/account-service/account.type';
import { CtaButtonSignalService } from '../../../services/cta-button-signal-service/cta-button-signal.service';
import { FormSubmissionService } from '../../../services/form-submission-service/form-submission-service.service';
import { FormActionState } from '../../../state/form-submision.state';
import { ProjectSettingsState } from '../../../state/project-settings.state';
import { RoleState } from '../../../state/role.state';
import { redirectToPage } from '../cta-button-actions.utils';

/**
 * Adds an international beneficiary using the provided widget properties and injector.
 * Retrieves necessary services and state data to perform the operation.
 * On successful addition, signals the CTA button, submits the form, and redirects to a specified page.
 * Displays an error alert if the operation fails.
 *
 * @param widgetProperties - The properties of the widget initiating the action.
 * @param injector - The injector used to retrieve necessary services.
 */
export function addInternationalBeneficiary(widgetProperties: BaseWidgetProperties, injector: Injector) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  const alertHandlerService = injector.get(AlertHandlerService);
  const accountService = injector.get(AccountService);
  const formSubmissionService = injector.get(FormSubmissionService);
  const ctaButtonSignalService = injector.get(CtaButtonSignalService);
  const CTAButtonKey = widgetProperties['buttonActionType'] as string;

  const formActionStateData = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.INTL_BENEFICIARY_ADD),
  );
  const formData = formActionStateData?.formData as InternationBeneficiaryFormStateModel;
  accountService
    .addBeneficiary(mapInternationBeneficiaryFormStateModelToAddBeneficiaryRequest(formData))
    .subscribe({
      next: (response: GetBeneficiaryResponse) => {
        ctaButtonSignalService.setSignal({
          [CTAButtonKey]: true,
        });
        formSubmissionService.submitIndividualForm(
          {
            bid: response.id,
          },
          '',
        );
        redirectToPage(router, widgetProperties, injector);
      },
      error: (error: any) => {
        ctaButtonSignalService.setSignal({
          [CTAButtonKey]: false,
        });
      },
    });
}

/**
 * Handles the process of calling the international beneficiary API based on project settings and user role.
 *
 * This function checks if SMS authentication is enabled in the project settings. If enabled, it further checks
 * if the current role is excluded from SMS authentication for adding a beneficiary. Depending on these checks,
 * it either proceeds with adding the international beneficiary directly or redirects to an OTP authentication page.
 *
 * @param widgetProperties - The properties of the widget, including navigation details.
 * @param injector - The Angular injector used to retrieve necessary services.
 */
export function callIntlBeneficiaryAPI(widgetProperties: BaseWidgetProperties, injector: Injector) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  const projectSettingsData = store.selectSnapshot(ProjectSettingsState.getProjectSettings);
  const roleData = store.selectSnapshot(RoleState.getRole);

  if (projectSettingsData?.smsAuthenticationEnabled) {
    if (projectSettingsData.smsAuthenticationExclusion?.['addBeneficiary']?.includes(roleData?.role)) {
      // previous scenario
      addInternationalBeneficiary(widgetProperties, injector);
    } else {
      const parsedWidgetProperties = JSON.parse(JSON.stringify(widgetProperties));
      // OTP page redirection
      parsedWidgetProperties['zoneToNavigate'] = APP_ZONES.PAYMENT;
      parsedWidgetProperties['urlToNavigate'] = `authentication-sms?otp=${OtpParam.AddIntlBeneficiary}`;
      redirectToPage(router, parsedWidgetProperties, injector);
    }
  } else {
    // previous scenario
    addInternationalBeneficiary(widgetProperties, injector);
  }
}
