import { Injector } from '@angular/core';
import { ButtonActionType, UiZoneWidgetAttributeConfig } from '@finxone-platform/shared/sys-config-types';
import { addEmployeeMethod, inviteEmployeeMethod } from '../add-employee/add-employee-action.utils';
import { functionCtaType } from '../cta-button-actions.utils';
import { assignPersonaUser } from '../manage-users-zone/manage-users-actions.utils';
import { addUserSendInvite } from '../user-management-zone-actions/add-user-send-invite.utils';
import { resetAssignPersonaFormStateAndRedirect } from '../user-management-zone-actions/assign-persona-flow-actions.utils';
import { mangeUseredit } from '../user-management-zone-actions/manage-user-edit.utils';

export const userManagementActions: Map<ButtonActionType, functionCtaType> = new Map([
  [
    ButtonActionType.ASSIGN_PERSONA_FORM_STATE_CLEAR_AND_REDIRECT,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      resetAssignPersonaFormStateAndRedirect(attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.ADD_EMPLOYEE_CONFIRM_SAVE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      addEmployeeMethod(attri.widgetProperties, injector, attri);
    },
  ],

  [
    ButtonActionType.ADD_EMPLOYEE_INVITE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      inviteEmployeeMethod(attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.ASSIGN_PERSONA,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      assignPersonaUser(attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.ADD_USER_SEND_INVITE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      addUserSendInvite(attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.MANAGE_USER_EDIT_AND_SAVE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      mangeUseredit(attri.widgetProperties, injector);
    },
  ],
]);
