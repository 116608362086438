export const passResetDesktopConfig = {
  passChangeHeader: { x: 17, y: 10, rows: 1, cols: 15 },
  passChangedTxt: { x: 17, y: 11, rows: 2, cols: 15 },
  imageWidget: { x: 17, y: 8, rows: 2, cols: 15 },
  proceedToLogin: { x: 17, y: 14, rows: 2, cols: 15 },
};

export const passResetMobileConfig = {
  passChangeHeader: { x: 0, y: 10, rows: 3, cols: 15 },
  passChangedTxt: { x: 0, y: 14, rows: 3, cols: 15 },
  imageWidget: { x: 0, y: 6, rows: 4, cols: 15 },
  proceedToLogin: { x: 0, y: 24, rows: 3, cols: 15 },
};
