import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonActionType,
  GridPosition,
  WorkflowWidgetConfig,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import { addBackButton } from '../../../page-builder-utils/widget-builder-utils/common-widget-utils';
import {
  DesktopBulkPaymentListingWidgetBuilder,
  FormSubmissionButtonWidgetBuilder,
  ImageWidgetBuilder,
  QuickLinkWidgetBuilder,
  TextWidgetBuilder,
} from '../../widgets/example-builder';
import { inputSettings } from '../../widgets/payments/payments.type';

export function generateTextWidget(override: {
  text: string;
  position: GridPosition;
  displayName: string;
  styles: any;
  isRemovable: boolean;
}): WorkflowWidgetConfig {
  return new TextWidgetBuilder()
    .text(override?.text)
    .gridPosition(override?.position)
    .configDisplayName(override?.displayName)
    .cssConfig(override?.styles)
    .isRemovable(override?.isRemovable)
    .build();
}

export function generateImageWidget(override: {
  position: GridPosition;
  displayName: string;
  styles: any;
  atributes: any;
  isRemovable: boolean;
}): WorkflowWidgetConfig {
  return new ImageWidgetBuilder()
    .gridPosition(override?.position)
    .configDisplayName(override?.displayName)
    .cssConfig(override?.styles)
    .isRemovable(override?.isRemovable)
    .attributes(override?.atributes)
    .build();
}

export function getQuickLinkWidget(override: {
  text: string;
  position: GridPosition;
  pageUrl: string;
  displayName: string;
  styles: any;
  zone: string;
  isRemovable?: boolean;
  icon?: string;
  globalStyling?: boolean;
  globalStylingClass?: string;
}): WorkflowWidgetConfig {
  return new QuickLinkWidgetBuilder()
    .zone(override?.zone)
    .navigateToPageUrl(override?.pageUrl)
    .navigationOption('use-as-navigation')
    .role()
    .text(override?.text)
    .configDisplayName(override?.displayName)
    .cssConfig(override?.styles)
    .gridPosition(override?.position)
    .isRemovable(override?.isRemovable || false)
    .setWidgetProperties({
      textContent: override?.text,
      icon: override?.icon ?? '',
    })
    .setGlobalStylingClass(override?.globalStyling, override?.globalStylingClass)
    .build();
}

export function bulkPaymentFooter(y: number) {
  return [
    getQuickLinkWidget({
      text: 'Privacy Policy',
      position: { x: 1, y: y, rows: 1, cols: 5 } as GridPosition,
      pageUrl: 'privacy-policy',
      displayName: 'Quick Link: Privacy Policy',
      styles: {
        justifyContent: 'center',
        textAlignment: 'center',
        fontSize: '16',
        lineHeight: '24',
        textFont: 'var(--font-family)',
        color: 'var(--typography-color-1)',
      },
      zone: APP_ZONES.PROFILE,
      isRemovable: true,
    }),

    getQuickLinkWidget({
      text: 'FAQs',
      position: { x: 6, y: y, rows: 1, cols: 3 } as GridPosition,
      pageUrl: 'faqs-landing',
      displayName: 'Quick Link: FAQs',
      styles: {
        justifyContent: 'center',
        textAlignment: 'center',
        fontSize: '16',
        lineHeight: '24',
        textFont: 'var(--font-family)',
        color: 'var(--typography-color-1)',
      },
      zone: APP_ZONES.PROFILE,
      isRemovable: true,
    }),
  ];
}

export function generateBulkPaymentListing(override: {
  text: string;
  position: GridPosition;
  displayName: string;
  styles: any;
  isRemovable: boolean;
  columns: any;
}): WorkflowWidgetConfig {
  return new DesktopBulkPaymentListingWidgetBuilder()
    .text(override?.text)
    .configDisplayName(override?.displayName)
    .gridPosition(override?.position)
    .cssConfig(override?.styles)
    .isRemovable(override?.isRemovable)
    .attributes({
      widgetProperties: {
        icon: 'search',
        textContent: ``,
        showEntity: false,
        entity: 'bulk_payment',
      } as BaseWidgetProperties,
      tableGridSetting: {
        showGridLines: false,
        gridLinesColor: 'var(--border-color-1)',
      },
      rowSettings: {
        rowBgColor: 'var(--palette-color-6)',
        showErrorDuplicationRowBgColor: false,
      },
      columns: override?.columns,
      viewListOption: 'none',
    } as unknown as WorkflowWidgetConfigurationAttributes)
    .inputSetting({
      ...inputSettings,
      css_config: {
        ...inputSettings.css_config,
        inputWidth: 110,
      },
      label: 'Search',
      isIconAllowed: true,
    })
    .build();
}

export const backCTAForBeneficiary = addBackButton(
  {
    x: 30,
    y: 1,
    rows: 1,
    cols: 5,
    resizeEnabled: true,
    dragEnabled: true,
  },
  {
    color: 'var(--palette-color-3)',
    backGroundColor: 'var(--palette-color-6)',
    borderWidth: 1,
    borderColor: 'var(--palette-color-3)',
    fontSize: 14,
    textAlignment: 'center',
    verticalAlignment: 'center',
  },
);

export const refreshBeneficiaryButton = new FormSubmissionButtonWidgetBuilder()
  .configDisplayName('CTA Button:Refresh')
  .cssConfig({
    backGroundColor: 'var(--palette-color-6)',
    color: 'var(--palette-color-3)',
    borderRadiusTopLeft: 6,
    borderRadiusBottomLeft: 6,
    borderRadiusTopRight: 6,
    borderRadiusBottomRight: 6,
    fontSize: 14,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    borderWidth: '1',
    borderColor: 'var(--palette-color-3)',
  })
  .attributes({
    widgetProperties: {
      textContent: 'Refresh',
      buttonActionType: ButtonActionType.REFRESH_BULK_PAYMENT_LIST,
      zoneToNavigate: '',
      urlToNavigate: '',
    } as BaseWidgetProperties,
  } as WorkflowWidgetConfigurationAttributes)
  .gridPosition({ x: 37, y: 5, rows: 1, cols: 6 } as GridPosition)
  .build();

export function getHeaderWidget(headerText: string, subHeaderText: string) {
  return [
    generateTextWidget({
      text: headerText,
      position: { x: 0, y: 1, rows: 1, cols: 25 } as GridPosition,
      displayName: 'Text Widget: Header',
      styles: {
        fontSize: 24,
        isTextBold: true,
        textAlignment: 'left',
      },
      isRemovable: true,
    }),
    generateTextWidget({
      text: subHeaderText,
      position: { x: 0, y: 2, rows: 1, cols: 20 } as GridPosition,
      displayName: 'Text Widget:  Sub header',
      styles: {
        fontSize: 20,
        color: 'var(--palette-color-1)',
        textAlignment: 'left',
      },
      isRemovable: true,
    }),
  ];
}
