import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { FormActionTypeEnum } from '@finxone-platform/form-action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { BaseWidgetProperties, ViewUserAssignPersona } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { tap } from 'rxjs';
import { MetadataService } from '../../../services/metadata-service/metadata-service.service';
import { FormActionState } from '../../../state/form-submision.state';
import { redirectToPage } from '../cta-button-actions.utils';

export function mangeUseredit(widgetProperties: BaseWidgetProperties, injector: Injector) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  const alertHandlerService = injector.get(AlertHandlerService);
  const metadataService = injector.get(MetadataService);
  const formData = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.MANAGE_USER_EDIT),
  )?.formData;
  const parsedWidgetProperties = JSON.parse(JSON.stringify(widgetProperties));
  if (!formData) {
    alertHandlerService.showAlertFn(
      'error',
      'Form data not available, please make sure all steps are completed',
    );
    return;
  }

  const payload: ViewUserAssignPersona = convertFormDataInPayload(formData);

  if (payload.roleId == '') {
    alertHandlerService.showAlertFn('error', 'Please select persona');
    return;
  }
  metadataService
    .viewUserAssignPersona(payload)
    .pipe(
      tap((response) => {
        if (response) {
          alertHandlerService.showAlertFn('success', 'User updated successfully');
          parsedWidgetProperties['urlToNavigate'] = 'select-user';
          redirectToPage(router, widgetProperties, injector);
        } else {
          alertHandlerService.showAlertFn('error', 'An unexpected error occured, please try again');
        }
      }),
    )
    .subscribe();
}

function convertFormDataInPayload(formData: Record<string, any>): ViewUserAssignPersona {
  return {
    id: formData?.['id'],
    roleId: formData?.['assign_persona'],
    additionalRoles: formData?.['assign_role_permission'] ?? [],
  };
}
