import {
  BaseWidgetProperties,
  ButtonSizeOptions,
  ButtonTypes,
  DownloadDocumentType,
  GridPosition,
} from '@finxone-platform/shared/sys-config-types';
import { BackButtonWidgetBuilderCssConfig } from '../../../../widgets';
import { BackButtonWidgetBuilder } from '../../../../widgets/example-builder';
import {
  commonNotificationDescriptionTextWidget,
  commonNotificationHeaderTextWidget,
  commonNotificationImageWidget,
  commonTextWidgetConversionDetail,
  downloadDocumentWidget,
} from '../utils/notification-utils';

export const notificationExchangeSuccessWidgets = [
  commonNotificationImageWidget({
    configDisplayName: 'Image Widget: Success Icon',
    attributes: {
      widgetProperties: {
        textContent: '',
        imageAssetUrl: 'assets/images/form-completed-icon.svg',
      } as BaseWidgetProperties,
    },
    gridPosition: { y: 0, rows: 2 } as GridPosition,
  }),
  commonNotificationHeaderTextWidget({
    text: '<div class="w-100"><p class="m-0">Conversion Successful</p></div>',
    configDisplayName: 'Exchange Successful Header',
    gridPosition: { y: 2, rows: 3 } as GridPosition,
  }),
  commonNotificationDescriptionTextWidget({
    text: '<div class="w-100"><p>Reference Number: {{notification.selectedNotification.transactionNumber}}</p><p>Your transaction has been completed successfully. If you have any questions or concerns, please feel free to contact our customer support.</p></div>',
    configDisplayName: 'Description Text',
    gridPosition: { y: 5, rows: 6 } as GridPosition,
  }),
  ...commonTextWidgetConversionDetail,
  downloadDocumentWidget(DownloadDocumentType.PROOF_OF_EXCHANGE, {
    gridPosition: { y: 42, rows: 2 },
  }),
  new BackButtonWidgetBuilder()
    .defaultBackButton()
    .cssConfig(BackButtonWidgetBuilderCssConfig)
    .gridPosition({ y: 45, rows: 2, cols: 15 } as GridPosition)
    .isRemovable(true)
    .setGlobalStylingClass(true, `${ButtonTypes.SECONDARY} ${ButtonSizeOptions.FULL_WIDTH}`)
    .build(),
];
