import { NotificationModel } from '@app/finxone-web-frontend/app/lib/services/account-service/account.type';
import { PaymentRequestSummary } from '../state/notifications.state';

export class GetNotification {
  static readonly type = '[Notification] GetNotification';
}
export class UpdateNotifications {
  static readonly type = '[Notifications] UpdateNotifications';
  constructor(public notificationList: NotificationModel[]) {}
}
export class SetNotificationLoadingStatus {
  static readonly type = '[Notifications] SetNotificationLoadingStatus';
  constructor(public isLoading: boolean) {}
}
export class SetPaymentRequestSummary {
  static readonly type = '[Payment] SetPaymentRequestSummary';
  constructor(public paymentRequestSummary: PaymentRequestSummary) {}
}

export class SetPaymentRequestSummaryApprovedorRejectedDate {
  static readonly type = '[Payment] SetPaymentRequestSummaryApprovedorRejectedDate';
  constructor(public date: string) {}
}

export class SetSelectedNotification {
  static readonly type = '[SelectedNotification] SetSelectedNotification';
  constructor(public selectedNotification: any) {}
}
export class ClearNotificationsCache {
  static readonly type = '[Payment] ClearNotificationsCache';
}
