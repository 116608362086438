export const currencyAccountListItemTemplate = `<div class="list-item">
<div class="row mx-0 flag-and-label-row">
  <div class="flag-wrap d-flex">{{{ currencyToFlag currencyCode '44px' }}}</div>
  <div class="text-wrap">
    <h4 class="m-0">{{ accountCurrency }} {{ defaultMarker }}</h4>
    <span>{{ currencyName }} | {{ accountCurrency }}</span>
  </div>
</div>
<div class="amount-wrap">

  <h4>{{ currencyPipe totalBalance currencyCode=accountCurrency }}</h4>
</div>
</div>`;

export const accountListingForSelectAccountTemplate = `
<div class="border p-3">
  <div class="is-space-between">
      <div class="is-vertical-align text-left">
          <div class="font-weight-bold typog-lg">{{ accountCurrency }}</div>
      </div>
      <div class="is-vertical-align text-right">
          <div class="is-right font-weight-semi typog-sm"><span class="text-capitalize pr-2">{{ countryName }}</span> {{{ currencyToFlag currencyCode '44px' }}}</div>
      </div>
  </div>
  <hr class="dashed-line my-5">
  <div class="is-space-between">
      <div class="is-vertical-align text-left">
        {{#if (stringPropertySet accountNumber)}}
        <div class="typog-xs label-small">Acc no.</div>
        <div class="typog-sm">{{ accountNumber }}</div>
        {{/if}}
        {{#if (stringPropertySet iban)}}
        <div class="typog-xs label-small">Iban</div>
        <div class="typog-sm">{{ iban }}</div>
        {{/if}}
      </div>

      <div class="is-vertical-align text-left">
        {{#if (stringPropertySet sortCode)}}
        <div class="typog-xs label-small">Sort Code</div>
        <div class="typog-sm">{{ sortCode }}</div>
        {{/if}}
      </div>
      <div class="is-vertical-align text-right">
        <div class="typog-xs label-small">Balance</div>
        <div class="typog-sm font-weight-bold">{{ currencyPipe indicativeBalance currencyCode=accountCurrency }}</div>
      </div>
  </div>
</div>
`;

export const bulkPaymentAccountListingTemplate = `
<div class="border p-3">
    <div class="is-space-between">
        <div class="col-6-xs">
            <div class="font-weight-bold typog-lg">{{ accountCurrency }}</div>
        </div>
        <div class="is-vertical-align text-right">
            <div class="is-right font-weight-semi typog-sm">
                <span class="text-capitalize font-weight-bold pr-2">{{ countryName }}</span>
                {{{ currencyToFlag currencyCode '44px' }}}
            </div>
        </div>
    </div>
    <hr class="dashed-line my-3">
    <div class="is-space-between">
        <div class="d-flex">
            <div class="col-6-xs">
                {{#if (stringPropertySet accountNumber)}}
                <div class="typog-xs label-small">Acc no.</div>
                <div>{{ accountNumber }}</div>
                {{/if}}
            </div>
            <div class="col-6-xs ml-8">
                {{#if (stringPropertySet sortCode)}}
                <div class="typog-xs label-small">Sort Code</div>
                <div>{{ sortCode }}</div>
                {{/if}}
            </div>
        </div>
        <div class="col-8-xs">
            <div class="typog-xs label-small">Available Balance</div>
            <div class="font-weight-bold">{{ currencyPipe indicativeBalance currencyCode=accountCurrency }}</div>
        </div>
    </div>
</div>
`;
