import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { FormActionTypeEnum, OtpParam } from '@finxone-platform/form-action';
import {
  accountNotSupported,
  accountNumberIncorrect,
  accountSwitched,
  invalidSecondaryIdentifier,
  noMatch,
  optedOut,
  sortCodeNotSupported,
} from '@finxone-platform/shared/default-templates';
import {
  APP_ZONES,
  BaseWidgetProperties,
  CheckPayeeMatchStatus,
  UiZoneWidgetAttributeConfig,
} from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { map, take } from 'rxjs';
import {
  SetCopResponseStateMatchStatusCode,
  SetCopResponseStateName,
  SetCopResponseStateReason,
} from '../../../actions/cop-response.action';
import { AccountService } from '../../../services/account-service/account-service.service';
import { CheckPayeeResponse, Payee } from '../../../services/account-service/account.type';
import { CtaButtonSignalService } from '../../../services/cta-button-signal-service/cta-button-signal.service';
import { AccountState, PaymentGatewayConfig } from '../../../state/account.state';
import { FormActionState } from '../../../state/form-submision.state';
import { redirectToPage } from '../cta-button-actions.utils';

/**
 * Updates the beneficiary details by performing a check on the payee's information.
 * If the selected account's currency is GBP, it initiates a CoP (Confirmation of Payee) check.
 * Depending on the CoP check result, it navigates to different pages for matched, close match,
 * or no match scenarios. If the payee check is not enabled, it directly navigates to the match page.
 *
 * @param injector - The Angular Injector used to retrieve services and state.
 */
export function updateBeneficiaryDetail(injector: Injector) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  const formActionStateData = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.EDIT_BENEFICIARY),
  );
  const formValues = formActionStateData?.formData;
  const copCheckpayload = {
    accountNumber: formValues.payload.destinationIdentifier?.accountNumber,
    accountType: formValues.payload?.accountType,
    name: formValues.payload?.name,
    sortCode: formValues.payload.destinationIdentifier?.sortCode,
    srd: formValues.payload?.srd ?? '',
  };
  const mockWidgetProperties: BaseWidgetProperties = {
    textContent: '',
    zoneToNavigate: APP_ZONES.PAYMENT,
    urlToNavigate: `review-information-match?flow=${OtpParam.EditBeneficiary}`,
  };
  const selectedAccount = store.selectSnapshot(AccountState.getCurrentAccount);
  if (selectedAccount?.currency === 'GBP') {
    copCheck(injector, OtpParam.EditBeneficiary, copCheckpayload);
  } else {
    redirectToPage(router, mockWidgetProperties, injector);
  }
}

/**
 * Updates the beneficiary details and performs a check on the payee if enabled.
 *
 * @param injector - The Angular Injector used to retrieve services.
 * @param param - The parameter for the CoP check.
 * @param copCheckpayload - The payload for the payee check.
 *
 * The function retrieves the current form action state for editing a beneficiary,
 * constructs a payload for the payee check, and determines the navigation path
 * based on the matchStatusCode and redirects to a specific page.
 */
export function copCheck(injector: Injector, param: string, copCheckpayload: Payee) {
  const router = injector.get(Router);
  const store = injector.get(Store);
  const accountService = injector.get(AccountService);
  const mockWidgetProperties: BaseWidgetProperties = {
    textContent: '',
    zoneToNavigate: APP_ZONES.PAYMENT,
    urlToNavigate: '',
  };
  let payeeEnabled = false;
  store
    .selectOnce(AccountState.getPaymentGatewayConfig)
    .pipe(
      map((paymentGatewayConfig: PaymentGatewayConfig) => {
        payeeEnabled = paymentGatewayConfig?.checkPayeeEnabled;
      }),
    )
    .subscribe();
  if (payeeEnabled) {
    accountService
      .checkPayee({ ...copCheckpayload } as Payee, param)
      .pipe(take(1))
      .subscribe({
        next: (checkPayeeResponse: CheckPayeeResponse) => {
          store.dispatch(new SetCopResponseStateName(checkPayeeResponse.name));
          store.dispatch(new SetCopResponseStateMatchStatusCode(checkPayeeResponse.matchStatusCode));
          if (checkPayeeResponse.matchStatusCode == CheckPayeeMatchStatus.MATCHED) {
            mockWidgetProperties['urlToNavigate'] = `review-information-match?flow=${param}`;
            redirectToPage(router, mockWidgetProperties, injector);
          } else if (
            checkPayeeResponse.matchStatusCode == CheckPayeeMatchStatus.CLOSE_MATCH ||
            checkPayeeResponse.matchStatusCode == CheckPayeeMatchStatus.NAME_MATCHED ||
            checkPayeeResponse.matchStatusCode == CheckPayeeMatchStatus.ACCOUNT_TYPE_MISMATCH_NAME_CLOSE_MATCH
          ) {
            mockWidgetProperties['urlToNavigate'] = `review-information-close-match-cop?flow=${param}`;
            redirectToPage(router, mockWidgetProperties, injector);
          } else {
            if (checkPayeeResponse.matchStatusCode == CheckPayeeMatchStatus.INVALID_SECONDARY_IDENTIFIER) {
              mockWidgetProperties[
                'urlToNavigate'
              ] = `review-information-secondary-identifier-required-cop?flow=${param}`;
              redirectToPage(router, mockWidgetProperties, injector);
            } else {
              setNoMatchReasonFromResponse(checkPayeeResponse.matchStatusCode, store);
              mockWidgetProperties['urlToNavigate'] = `review-information-no-match-cop?flow=${param}`;
              redirectToPage(router, mockWidgetProperties, injector);
            }
          }
        },
        error: (error) => {
          console.error(error);
        },
      });
  } else {
    // don't check the payee
    mockWidgetProperties['urlToNavigate'] = `review-information-match?flow=${param}`;
    redirectToPage(router, mockWidgetProperties, injector);
  }
}

function setNoMatchReasonFromResponse(response: string, store: Store) {
  let noMatchReason: string;
  switch (response) {
    case CheckPayeeMatchStatus.ACCOUNT_SWITCHED:
      noMatchReason = accountSwitched;
      break;
    case CheckPayeeMatchStatus.ACCOUNT_NOT_SUPPORTED:
      noMatchReason = accountNotSupported;
      break;
    case CheckPayeeMatchStatus.OPTED_OUT:
      noMatchReason = optedOut;
      break;
    case CheckPayeeMatchStatus.INVALID_SECONDARY_IDENTIFIER:
      noMatchReason = invalidSecondaryIdentifier;
      break;
    case CheckPayeeMatchStatus.SORT_CODE_NOT_SUPPORTED:
      noMatchReason = sortCodeNotSupported;
      break;
    case CheckPayeeMatchStatus.ACCOUNT_NUMBER_INCORRECT:
      noMatchReason = accountNumberIncorrect;
      break;
    default:
      noMatchReason = noMatch;
      break;
  }
  store.dispatch(new SetCopResponseStateReason(noMatchReason));
}

export function editbeneficiaryButtonState(
  store: Store,
  ctaButtonSignalService: CtaButtonSignalService,
  attri: UiZoneWidgetAttributeConfig,
) {
  const data = store.select(FormActionState.getFormActionStateWithId(FormActionTypeEnum.EDIT_BENEFICIARY));

  return data.subscribe({
    next: (res) => {
      const key = attri?.['buttonActionType'] as string;
      if (res?.formData?.payload?.isBeneficiaryFormValid) {
        ctaButtonSignalService.setSignal({
          [key]: res?.formData?.payload?.isBeneficiaryFormValid ? true : false,
        });
      }
    },
  });
}
