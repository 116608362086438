import {
  APP_ZONES,
  BaseWidgetProperties,
  GridPosition,
  WorkflowWidgetConfig,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import {
  BackButtonWidgetBuilder,
  LineWidgetBuilder,
  ProgressBarBuilder,
  QuickLinkWidgetBuilder,
  TextWidgetBuilder,
} from '../../widgets/example-builder';

export function generateTextWidget(override: {
  text: string;
  position: GridPosition;
  displayName: string;
  styles: any;
  isRemovable: boolean;
}): WorkflowWidgetConfig {
  return new TextWidgetBuilder()
    .text(override?.text)
    .gridPosition(override?.position)
    .configDisplayName(override?.displayName)
    .cssConfig(override?.styles)
    .isRemovable(override?.isRemovable)
    .build();
}

export function generateHeaderTextWidget() {
  return generateTextWidget({
    text: 'Give Access - Add New User',
    position: {
      y: 0,
      x: 0,
      rows: 2,
      cols: 10,
    } as GridPosition,
    displayName: 'Text Widget:Add New User',
    styles: {
      color: 'var(--typography-color-0)',
      fontSize: 20,
      isTextBold: true,
      textAlignment: 'left',
      marginTop: 12,
      paddingTop: 8,
    },
    isRemovable: true,
  });
}

export function generateSubHeaderRightTextWidget(text: string) {
  return generateTextWidget({
    text: text,
    position: {
      y: 3,
      x: 0,
      rows: 2,
      cols: 15,
    } as GridPosition,
    displayName: 'Text Widget: ' + text,
    styles: {
      color: 'var(--typography-color-0)',
      fontSize: 20,
      isTextBold: true,
      textAlignment: 'left',
    },
    isRemovable: true,
  });
}

export function generateSubHeaderLeftTextWidget(text: string) {
  return generateTextWidget({
    text: text,
    position: {
      y: 3,
      x: 35,
      rows: 2,
      cols: 6,
    } as GridPosition,
    styles: {
      color: 'var(--typography-color-0)',
      fontSize: 20,
      paddingLeft: 40,
      textAlignment: 'right',
    },
    displayName: 'Text Widget: ' + text,
    isRemovable: true,
  });
}

export function generateCommonProgressBarWidget(percentage: string) {
  return generateProgressBarWidget({
    completionProgressPercentage: percentage,
    cssConfig: {
      marginTop: 30,
    },
    position: {
      y: 4,
      rows: 2,
      layerIndex: 1,
    } as GridPosition,
  });
}

export const generateProgressBarWidget = ({
  completionProgressPercentage,
  cssConfig,
  position,
}: {
  completionProgressPercentage: string;
  cssConfig?: any;
  position?: GridPosition;
}) =>
  new ProgressBarBuilder()
    .setWidgetProperties({
      textContent: '',
      completionProgressPercentage,
      progressBarActiveColor: 'var(--palette-color-3)',
      progressBarColor: 'var(--palette-color-4)',
    })
    .configDisplayName('Progress Bar Widget')
    .gridPosition({
      y: 5,
      x: 0,
      rows: 2,
      cols: 42,
      ...position,
    } as GridPosition)
    .cssConfig({
      color: 'var(--typography-color-0)',
      textAlignment: 'center',
      justifyContent: 'center',
      fontSize: 24,
      paddingRight: 40,
      backGroundColor: 'transparent',
      ...cssConfig,
    })
    .isRemovable(true)
    .build();

export const lineWidgetBuilderConfig = new LineWidgetBuilder()
  .configDisplayName('Line Widget: Header Bottom Line')
  .gridPosition({
    y: 2,
    x: 0,
    rows: 1,
    cols: 41,
    layerIndex: 1,
  } as GridPosition)
  .attributes({
    widgetProperties: {
      color: 'var(--border-color-1)',
      'border-bottom': 'var(--border-color-1)',
      lineThickness: 1,
      lineWidth: '100',
      textContent: '',
      show: true,
      contentAlignment: 'center',
      paddingLeft: 2,
    } as BaseWidgetProperties,
    css_config: {
      marginTop: 15,
    },
  } as WorkflowWidgetConfigurationAttributes)
  .build();

export function getBackButtonWidget(
  override: { position: GridPosition; styles: any },
  isGlobalStyleApply = false,
  stylingClass?: string,
): WorkflowWidgetConfig {
  return new BackButtonWidgetBuilder()
    .defaultBackButton()
    .gridPosition(override?.position)
    .cssConfig(override?.styles)
    .isRemovable(true)
    .setGlobalStylingClass(isGlobalStyleApply, stylingClass)
    .build();
}

export function getQuickLinkWidget(override: {
  text: string;
  position: GridPosition;
  pageUrl: string;
  displayName: string;
}): WorkflowWidgetConfig {
  return new QuickLinkWidgetBuilder()
    .zone(APP_ZONES.USER_MANAGEMENT)
    .navigateToPageUrl(override?.pageUrl)
    .navigationOption('use-as-navigation')
    .role()
    .text(override?.text)
    .configDisplayName(override?.displayName)
    .cssConfig({
      color: 'var(--palette-color-6)',
      backGroundColor: 'var(--palette-color-3)',
      borderWidth: 1,
      borderColor: 'var(--palette-color-3)',
      borderRadiusTopLeft: 5,
      borderRadiusBottomLeft: 5,
      borderRadiusTopRight: 5,
      borderRadiusBottomRight: 5,
      fontSize: 16,
    })
    .gridPosition(override?.position)
    .isRemovable(false)
    .build();
}
