import {
  selectAccountFileTypeBottomDescriptionTemplate,
  selectedAccountDetailsForDownloadStatementTemplate,
} from '@finxone-platform/shared/default-templates';
import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonActionType,
  ButtonSizeOptions,
  ButtonTypes,
  GridPosition,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import {
  AccountContextDropdownWidgetBuilder,
  BackButtonWidgetBuilder,
  FormSubmissionButtonWidgetBuilder,
  LineWidgetBuilder,
} from '../../../../widgets/example-builder';
import { createTextWidget } from '../utils/common-utils';

const commonCTAButtonStyle = {
  borderWidth: '1',
  borderColor: 'var(--palette-color-3)',
  backGroundColor: 'var(--palette-color-3)',
  color: 'var(--palette-color-6)',
  fontSize: 14,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 0,
  paddingRight: 0,
  marginTop: 0,
  marginBottom: 0,
  borderRadiusTopLeft: 6,
  borderRadiusBottomLeft: 6,
  borderRadiusTopRight: 6,
  borderRadiusBottomRight: 6,
};

export const bankStatementsAccountSelectFileTypeWidgets = [
  createTextWidget({
    text: '<h1>Select Account & File Type</h1>',
    configDisplayName: 'Text Widget: Title',
    gridPosition: { y: 1, rows: 2 },
    css_config: {
      color: 'var(--typography-color-0)',
      textAlignment: 'left',
      fontSize: 12,
      isTextBold: true,
    },
  }),

  createTextWidget({
    text: '<h2>Choose the account and your preferred file type.</h2>',
    configDisplayName: 'Text Widget: Subtitle',
    gridPosition: { y: 3, rows: 2 },
    css_config: {
      color: 'var(--typography-color-1)',
      textAlignment: 'left',
      fontSize: 10,
    },
  }),

  new AccountContextDropdownWidgetBuilder().build(),

  createTextWidget({
    text: selectedAccountDetailsForDownloadStatementTemplate,
    configDisplayName: 'Text Widget: Selected Account Details',
    gridPosition: { y: 10, rows: 8 },
    css_config: {
      color: 'var(--typography-color-0)',
      borderColor: 'var(--border-color-1)',
      borderWidth: '1',
      borderRadiusTopLeft: 6,
      borderRadiusTopRight: 6,
      borderRadiusBottomLeft: 6,
      borderRadiusBottomRight: 6,
      textAlignment: 'left',
      fontSize: 10,
    },
  }),

  createTextWidget({
    text: 'Download Bank Statement as CSV',
    configDisplayName: 'Text Widget: Download CSV title',
    gridPosition: { y: 19, rows: 1 },
    css_config: {
      color: 'var(--typography-color-0)',
      textAlignment: 'left',
      fontSize: 18,
    },
  }),

  new FormSubmissionButtonWidgetBuilder()
    .configDisplayName('Button: Download as CSV')
    .attributes({
      widgetProperties: {
        lefticon: 'download',
        textContent: 'Download as CSV',
        zoneToNavigate: APP_ZONES.PROFILE,
        urlToNavigate: 'bank-statements-preset-downloads',
        buttonActionType: ButtonActionType.DOWNLOAD_CSV_BANK_STATEMENT,
      } as BaseWidgetProperties,
    } as unknown as WorkflowWidgetConfigurationAttributes)
    .cssConfig(commonCTAButtonStyle)
    .isRemovable(false)
    .gridPosition({ y: 21, rows: 2, cols: 15 } as GridPosition)
    .setGlobalStylingClass(true, `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`)
    .build(),

  new LineWidgetBuilder()
    .configDisplayName('Line Widget')
    .gridPosition({ y: 23, rows: 2, cols: 15 } as GridPosition)
    .attributes({
      widgetProperties: {
        color: 'var(--border-color-1)',
        'border-bottom': 'var(--border-color-1)',
        lineThickness: 1,
        lineWidth: '100',
        textContent: '',
        show: true,
        contentAlignment: 'center',
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .build(),

  createTextWidget({
    text: 'Download Bank Statement as PDF',
    css_config: {
      color: 'var(--typography-color-0)',
      textAlignment: 'left',
      fontSize: 18,
    },
    gridPosition: { y: 25, rows: 1 },
    configDisplayName: 'Text Widget: Download PDF title',
  }),

  new FormSubmissionButtonWidgetBuilder()
    .configDisplayName('Button: Download as PDF')
    .attributes({
      widgetProperties: {
        textContent: 'Download as PDF',
        lefticon: 'download',
        buttonActionType: ButtonActionType.DOWNLOAD_PDF_BANK_STATEMENT,
        zoneToNavigate: APP_ZONES.PROFILE,
        urlToNavigate: 'bank-statements-preset-downloads',
      } as BaseWidgetProperties,
    } as unknown as WorkflowWidgetConfigurationAttributes)
    .cssConfig(commonCTAButtonStyle)
    .isRemovable(false)
    .gridPosition({ y: 27, rows: 2, cols: 15 } as GridPosition)
    .setGlobalStylingClass(true, `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`)
    .build(),

  createTextWidget({
    text: selectAccountFileTypeBottomDescriptionTemplate,
    configDisplayName: 'Text Widget: Bottom description',
    gridPosition: { y: 30, rows: 3 },
    css_config: {
      color: 'var(--typography-color-1)',
      textAlignment: 'center',
      fontSize: 14,
    },
  }),

  new BackButtonWidgetBuilder()
    .defaultBackButton()
    .cssConfig({
      color: 'var(--palette-color-3)',
      backGroundColor: 'var(--palette-color-6)',
      borderColor: 'var(--palette-color-3)',
      borderWidth: '1',
    })
    .configDisplayName('Button: Back Navigation')
    .gridPosition({ y: 34, rows: 2, cols: 15 } as GridPosition)
    .isRemovable(false)
    .setGlobalStylingClass(true, `${ButtonTypes.SECONDARY} ${ButtonSizeOptions.FULL_WIDTH}`)
    .build(),
];
