import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { BaseWidgetProperties, ListingTypeEnum } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { tap } from 'rxjs';
import {
  GetClientFeeChargeHistoryListing,
  GetFeeChargeHistoryListing,
  ResetSelectedFeeHistoryItem,
} from '../../../actions/fee-management.action';
import { FeeManagementService } from '../../../services/fee-management-service/fee-management.service';
import { FeeManagementState } from '../../../state/fee-management.state';
import { redirectToPage } from '../cta-button-actions.utils';

/**
 * Retries a failed fee transaction for the selected fee item.
 *
 * @param widgetProperties - The properties of the widget, including navigation and listing type.
 * @param injector - The Angular injector used to retrieve necessary services.
 *
 * This function retrieves the selected fee item from the store and attempts to retry the failed fee
 * transaction using the FeeManagementService. Upon success, it resets the selected fee history item
 * and dispatches actions to update the fee charge history listings based on the listing type.
 * If a navigation zone is specified, it redirects to the appropriate page. If no fee item is selected,
 * an error alert is displayed.
 */
export function retryFailedFee(widgetProperties: BaseWidgetProperties, injector: Injector) {
  const store = injector.get(Store);
  const _router = injector.get(Router);
  const feeManagementService = injector.get(FeeManagementService);
  const alertHandlerService = injector.get(AlertHandlerService);
  const selectedFeeItem = store.selectSnapshot(FeeManagementState.getFeeManagement)?.selectedFeeHistoryItem;
  const feeManagementState = store.selectSnapshot(FeeManagementState.getFeeManagement);

  if (selectedFeeItem) {
    feeManagementService
      .retryFailedFee(selectedFeeItem.id)
      .pipe(
        tap(() => {
          store.dispatch(new ResetSelectedFeeHistoryItem());
          if (widgetProperties?.['listingType'] === ListingTypeEnum.CLIENT_FEE_CHARGED_HISTORY) {
            const pageNumber = feeManagementState?.clientFeeChargeHistoryListing?.page;
            store.dispatch(
              new GetClientFeeChargeHistoryListing(
                pageNumber,
                100,
                feeManagementState?.selectedClientDetails?.id ?? '',
              ),
            );
          }
          if (widgetProperties?.['listingType'] === ListingTypeEnum.FEE_CHARGE_HISTORY_LISTING) {
            const pageNumber = feeManagementState?.feeChargeHistoryListing?.page;
            store.dispatch(new GetFeeChargeHistoryListing(pageNumber, 100));
          }
          if (widgetProperties?.['zoneToNavigate']) redirectToPage(_router, widgetProperties, injector);
        }),
      )
      .subscribe();
  } else {
    alertHandlerService.showAlertFn('error', 'Selected fee details not available', 'Failed to retry');
  }
}
