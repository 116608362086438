import {
  APP_ZONES,
  ButtonSizeOptions,
  ButtonTypes,
  DocumentMonthsUpdate,
  DocumentType,
  DownloadDocumentType,
  GridPosition,
} from '@finxone-platform/shared/sys-config-types';

import { DownloadDocumentWidgetBuilder } from '../../../../widgets/example-builder';
import {
  createBackButtonWidget,
  createQuickLinkWidget,
  createTextWidget,
  defaultPdfCssConfig,
} from '../utils/common-utils';

export const bankStatementsPresetDownloadsWidgets = [
  createTextWidget({
    text: '<h1>EXPORT</h1>',
    configDisplayName: 'Text Widget: EXPORT',
    gridPosition: { y: 1, rows: 2 },
    css_config: {
      color: 'var(--typography-color-1)',
      textAlignment: 'left',
      fontSize: '7',
      isTextBold: true,
    },
  }),

  new DownloadDocumentWidgetBuilder()
    .attributes({
      documentType: DownloadDocumentType.BANK_STATEMENT,
      timeInterval: DocumentMonthsUpdate.LAST_MONTH,
      downloadDocumentAs: DocumentType.CONTEXT,
      textInputHeader: 'Last Month',
      textInputSubHeader: `
      {{ datePipe profileBankStatementFormState.lastMonthStartDate format='dd MMM yyyy' }} -
      {{ datePipe profileBankStatementFormState.lastMonthEndDate format='dd MMM yyyy' }}
      `,
      elementsConfig: [],
      widgetProperties: {
        textContent: 'Download',
      },
      css_config: { ...defaultPdfCssConfig },
    })
    .gridPosition({ y: 3, rows: 4 } as GridPosition)
    .configDisplayName('Download Document: Last Month')
    .isRemovable(false)
    .build(),

  new DownloadDocumentWidgetBuilder()
    .attributes({
      documentType: DownloadDocumentType.BANK_STATEMENT,
      timeInterval: DocumentMonthsUpdate.PREVIOUS_3_MONTHS,
      downloadDocumentAs: DocumentType.CONTEXT,
      textInputHeader: `Previous 3 months`,
      textInputSubHeader: `
      {{ datePipe profileBankStatementFormState.lastThreeMonthsStartDate format='dd MMM yyyy' }} -
      {{ datePipe profileBankStatementFormState.lastThreeMonthsEndDate format='dd MMM yyyy' }}
      `,
      elementsConfig: [],
      widgetProperties: {
        textContent: 'Download',
      },
      css_config: { ...defaultPdfCssConfig },
    })
    .gridPosition({ y: 7, rows: 4 } as GridPosition)
    .configDisplayName('Download Document: Previous 3 Months')
    .isRemovable(false)
    .build(),

  new DownloadDocumentWidgetBuilder()
    .attributes({
      documentType: DownloadDocumentType.BANK_STATEMENT,
      timeInterval: DocumentMonthsUpdate.PREVIOUS_6_MONTHS,
      downloadDocumentAs: DocumentType.CONTEXT,
      textInputHeader: `Previous 6 months`,
      textInputSubHeader: `
      {{ datePipe profileBankStatementFormState.lastSixMonthsStartDate format='dd MMM yyyy' }} -
      {{ datePipe profileBankStatementFormState.lastSixMonthsEndDate format='dd MMM yyyy' }}
      `,
      elementsConfig: [],
      widgetProperties: {
        textContent: 'Download',
      },
      css_config: { ...defaultPdfCssConfig },
    })
    .gridPosition({ y: 11, rows: 4 } as GridPosition)
    .configDisplayName('Download Document: Previous 6 Months')
    .isRemovable(false)
    .build(),

  createQuickLinkWidget({
    zone: APP_ZONES.PROFILE,
    navigateToPageUrl: 'bank-statements-custom-date-range',
    navigationOption: 'use-as-navigation',
    text: 'Custom Date Range',
    css_config: {
      color: 'var(--palette-color-3)',
      backGroundColor: 'transparent',
      fontSize: '16',
      textAlignment: 'right',
      isTextUnderline: true,
    },
    gridPosition: { y: 16, rows: 2 },
    configDisplayName: 'Quick Link: Custom Date Range',
    globalStyling: true,
    globalStylingClass: `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`,
  }),
  createBackButtonWidget({ y: 24, rows: 2, cols: 15 } as GridPosition),
];
