export const feeTemplate = `
<div class="w-100 h-100">
    <div class="text-center">
        <div class="ben-label pb-2">Fee Name</div>
        <div class='font-weight-bold event-value text-capitalize typog-lg'>{{feeManagement.addEditFeeData.name}}</div>
    </div>
    <hr class="my-4 mx-1">
    <div class="d-flex">
        <div class="pr-2 col-2">
            <div class="ben-label pb-2">Currency</div>
            <div class="font-weight-normal">{{feeManagement.addEditFeeData.currency}}</div>
        </div>
        <div class="pr-2 col-2">
            <div class="ben-label pb-2">Frequency</div>
            <div class="font-weight-normal text-capitalize">{{feeManagement.addEditFeeData.frequencyType}}</div>
        </div>
        <div class="pr-2 col-2">
            <div class="ben-label pb-2">Amount / Percentage</div>
            <div class="font-weight-normal">{{ feeChargeValue feeManagement.addEditFeeData.unitChargeInfo.feeCharge feeManagement.addEditFeeData.unitChargeType feeManagement.addEditFeeData.currency }}</div>
        </div>
        <div class="pr-2 col-2">
            <div class="ben-label pb-2">Unit Charge</div>
            <div class="font-weight-normal text-capitalize">{{feeManagement.addEditFeeData.unitChargeType}}</div>
        </div>
        <div class="pr-2 col-2">
            <div class="ben-label pb-2">Effective from: </div>
            <div class="font-weight-normal">{{ datePipe feeManagement.addEditFeeData.frequencyInfo.startDate  format='d MMM y'}}</div>
        </div>
        <div class="col-2">
            <div class="ben-label pb-2">Status</div>
            <div class="font-weight-normal text-capitalize">{{feeManagement.addEditFeeData.status}}</div>
        </div>
    </div>
</div>`;
