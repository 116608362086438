export const transactionSummaryTemplate = `
<div class="w-100 h-100 text-left ml-4 mr-4">
  <div class="content is-space-between f-wrap mt-8">

    <div class="mb-5 ml-2">
      <div class="text-label typo-color-0 typog-xs font-weight-bold"> Amount </div>
      <div class="text-value typo-color-1 typog-md">
         {{ currencyPipe revenirFormState.transactionDetails.total_amount currencyCode=revenirFormState.transactionDetails.currency display='symbol-narrow' }}
      </div>
    </div>

    <div class="mb-5 mr-2">
      <div class="text-label typo-color-0 typog-xs font-weight-bold "> VAT </div>
      <div class="text-value typo-color-1 typog-md">
         {{ currencyPipe revenirFormState.transactionDetails.total_vat_amount currencyCode=revenirFormState.transactionDetails.currency display='symbol-narrow' }}
      </div>
    </div>

  </div>

   <div class="col-5 mb-5">
      <div class="text-label typo-color-0 typog-xs font-weight-bold"> Retailer </div>
      <div class="text-value typo-color-1 typog-md">
         {{#if revenirFormState.transactionDetails.vendor }}

            {{ revenirFormState.transactionDetails.vendor }}

            {{else}} {{ revenirFormState.transactionDetails.merchant_name }}

          {{/if}}
      </div>
    </div>

    <div class="col-5 mb-5">
      <div class="text-label typo-color-0 typog-xs font-weight-bold">Date</div>
      <div class="text-value typo-color-1 typog-md">
         {{ datePipe revenirFormState.transactionDetails.transaction_date format='d/MM/y' }}
      </div>
    </div>

    <div class="col-5 mb-5">
      <div class="text-label typo-color-0 typog-xs font-weight-bold"> Items </div>
    </div>

    <div style="height:130px" class="scrollable">
    {{#each revenirFormState.transactionItems}}
      <div class="col-10 mb-5">

          <div class="text-label typo-color-0 typog-xs mb-1">
              {{ category }} {{ item_description }}
          </div>
          <div class="text-label typo-color-0 typog-xs mb-1">
               {{ currencyPipe total currencyCode=currency display='symbol-narrow' }}
          </div>

          <div class="typo-color-1 typog-xs">
            VAT Reclaim
          </div>
          <div class="typo-color-1 typog-xs">
               {{ currencyPipe vat_amount currencyCode=currency display='symbol-narrow' }}
          </div>

      </div>
    {{/each}}
    </div>

</div>
`;
