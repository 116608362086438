import {
  APP_ZONES,
  BaseWidgetProperties,
  GridPosition,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import { ImageWidgetBuilder } from '../../../../widgets';
import { commonQuickLink, commonTextWidget } from '../../../common/common-widgets';

export const confirmArchiveSuccessWidgets = [
  commonTextWidget({
    textContent: `Success!`,
    configDisplayName: 'Header: Success',
    cssConfig: {
      textFont: 'var(--font-family)',
      color: 'var(--typography-color-0)',
      textAlignment: 'center',
      fontSize: 24,
      isTextBold: true,
    },
    gridPosition: {
      y: 3,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 15,
    },
  }),

  new ImageWidgetBuilder()
    .configDisplayName('Success Icon')
    .cssConfig({
      selectedobjectfit: 'contain',
      textAlignment: 'center',
    })
    .attributes({
      widgetProperties: {
        imageAssetUrl: 'assets/images/tick-success.svg',
        textContent: '',
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .gridPosition({
      y: 6,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 5,
      cols: 15,
    } as GridPosition)
    .build(),

  commonTextWidget({
    textContent: `If you have successfully scanned your barcode at the tax reclaim kiosk you have successfully submitted your reclaim. We’ll notify you when you should expect your refund.`,
    configDisplayName: 'Success info: info text',
    cssConfig: {
      textFont: 'var(--font-family)',
      color: 'var(--typography-color-0)',
      textAlignment: 'center',
      fontSize: 16,
      marginTop: 0,
      marginRight: 10,
      marginBottom: 0,
      marginLeft: 10,
    },
    gridPosition: {
      y: 13,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 6,
      cols: 15,
    },
  }),

  commonTextWidget({
    textContent: `You can find this trip in the Archive tab in the menu.`,
    configDisplayName: 'Success sub-info: sub-info text',
    cssConfig: {
      textFont: 'var(--font-family)',
      color: 'var(--typography-color-0)',
      textAlignment: 'center',
      fontSize: 11,
    },
    gridPosition: {
      y: 19,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 15,
    },
  }),

  commonQuickLink({
    text: 'Continue',
    configDisplayName: 'Navigate: Continue',
    zone: APP_ZONES.REVENIR_ZONE,
    navigateToPageUrl: 'trips-home-page',
    cssConfig: {
      color: 'var(--palette-color-6)',
      borderColor: 'var(--palette-color-3)',
      fontSize: 16,
      marginTop: 0,
      marginRight: 15,
      marginBottom: 0,
      marginLeft: 15,
      borderRadiusTopLeft: 8,
      borderRadiusTopRight: 8,
      borderRadiusBottomLeft: 8,
      borderRadiusBottomRight: 8,
    },
    gridPosition: { y: 27, x: 0, rows: 2, cols: 15 } as GridPosition,
  }),
];
