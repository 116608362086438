import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonSizeOptions,
  ButtonTypes,
  KycLevel,
  WorkflowWidgetConfig,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import {
  BackButtonWidgetBuilder,
  ImageWidgetBuilder,
  MandatoryCTAButtonBuilder,
  SectionWidgetBuilder,
  SpacerWidgetBuilder,
  TextWidgetBuilder,
} from '../../../../widgets';

export function mobileInformationConfirmationWidgets(): WorkflowWidgetConfig[] {
  return [
    new ImageWidgetBuilder()
      .configDisplayName('Image Widget: Search Icon')
      .cssConfig({
        selectedobjectfit: 'contain',
      })
      .attributes({
        widgetProperties: {
          textContent: '',
          imageAssetUrl: 'assets/images/search.svg',
        } as BaseWidgetProperties,
        outerCss: `height: 80px; position: relative; right: 35%;`,
      } as WorkflowWidgetConfigurationAttributes)
      .build(),

    new TextWidgetBuilder()
      .text('<h4 class="w-100 "> Let\'s confirm your details. </h4>')
      .configDisplayName('Company Name - Information')
      .cssConfig({
        textAlignment: 'left',
        justifyContent: 'left',
        fontSize: '24',
        lineHeight: '32',
        textFont: 'var(--font-family)',
      })
      .isRemovable(false)
      .build(),

    new TextWidgetBuilder()
      .text(
        '<p class="w-100 "> By proceeding I consent to MoneyFin accessing the company details to verify the identity. </p>',
      )
      .configDisplayName('Verify content')
      .cssConfig({
        justifyContent: 'left',
        textAlignment: 'left',
        fontSize: '16',
        lineHeight: '24',
        textFont: 'var(--font-family)',
        color: 'var(--color-on-surface-muted)',
        marginTop: 20,
      })
      .isRemovable(false)
      .build(),

    new SpacerWidgetBuilder().setHeight({ height: 280, unit: 'px' }).build(),

    new ImageWidgetBuilder()
      .configDisplayName('Image Widget: Search Icon')
      .cssConfig({
        selectedobjectfit: 'contain',
      })
      .attributes({
        widgetProperties: {
          textContent: '',
          imageAssetUrl: 'assets/images/sumsub-logo.svg',
        } as BaseWidgetProperties,
        outerCss: 'height:100px;',
      } as WorkflowWidgetConfigurationAttributes)
      .build(),

    new SpacerWidgetBuilder().setHeight({ height: 20, unit: 'px' }).build(),

    new MandatoryCTAButtonBuilder()
      .configDisplayName('Button: Verify Details')
      .attributes({
        widgetProperties: {
          textContent: 'Verify Details',
          zoneToNavigate: APP_ZONES.ORGANISATION_VERIFICATION,
          urlToNavigate: 'sumsub-workflow?type=' + KycLevel.BUSINESS,
        } as BaseWidgetProperties,
      } as WorkflowWidgetConfigurationAttributes)
      .cssConfig({
        borderRadiusTopLeft: 6,
        borderRadiusBottomLeft: 6,
        borderRadiusTopRight: 6,
        borderRadiusBottomRight: 6,
      })
      .setGlobalStylingClass(true, `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`)
      .build(),

    new SpacerWidgetBuilder().setHeight({ height: 20, unit: 'px' }).build(),

    new BackButtonWidgetBuilder()
      .defaultBackButton()
      .cssConfig({
        marginTop: 14,
        marginBottom: 14,
      })
      .isRemovable(false)
      .setGlobalStylingClass(true, `${ButtonTypes.SECONDARY} ${ButtonSizeOptions.FULL_WIDTH}`)
      .build(),
  ];
}
export function desktopInformationConfirmationWidgets(): WorkflowWidgetConfig[] {
  return [
    new SectionWidgetBuilder()
      .attributes({
        outerCss: `left: 30%; position: relative; top: 100px; width: 45%; height:700px;`,
      } as unknown as WorkflowWidgetConfigurationAttributes)
      .childComponents([
        new ImageWidgetBuilder()
          .configDisplayName('Image Widget: Search Icon')
          .cssConfig({
            selectedobjectfit: 'contain',
          })
          .attributes({
            widgetProperties: {
              textContent: '',
              imageAssetUrl: 'assets/images/search.svg',
            } as BaseWidgetProperties,
            outerCss: `height: 80px; position: relative; right: 40%;`,
          } as WorkflowWidgetConfigurationAttributes)
          .build(),

        new TextWidgetBuilder()
          .text('<h4 class="w-100 "> Let\'s confirm your details. </h4>')
          .configDisplayName('Company Name - Information')
          .cssConfig({
            textAlignment: 'left',
            justifyContent: 'left',
            fontSize: '24',
            lineHeight: '32',
            textFont: 'var(--font-family)',
          })
          .isRemovable(false)
          .build(),

        new TextWidgetBuilder()
          .text(
            '<p class="w-100 "> By proceeding I consent to MoneyFin accessing the company details to verify the identity. </p>',
          )
          .configDisplayName('Verify content')
          .cssConfig({
            justifyContent: 'left',
            textAlignment: 'left',
            fontSize: '16',
            lineHeight: '24',
            textFont: 'var(--font-family)',
            color: 'var(--color-on-surface-muted)',
            marginTop: 20,
          })
          .isRemovable(false)
          .build(),

        new SpacerWidgetBuilder().setHeight({ height: 280, unit: 'px' }).build(),

        new ImageWidgetBuilder()
          .configDisplayName('Image Widget: Search Icon')
          .cssConfig({
            selectedobjectfit: 'contain',
          })
          .attributes({
            widgetProperties: {
              textContent: '',
              imageAssetUrl: 'assets/images/sumsub-logo.svg',
            } as BaseWidgetProperties,
            outerCss: 'height:100px;',
          } as WorkflowWidgetConfigurationAttributes)
          .build(),

        new SpacerWidgetBuilder().setHeight({ height: 20, unit: 'px' }).build(),

        new MandatoryCTAButtonBuilder()
          .configDisplayName('Button: Verify Details')
          .attributes({
            widgetProperties: {
              textContent: 'Verify Details',
              zoneToNavigate: APP_ZONES.ORGANISATION_VERIFICATION,
              urlToNavigate: 'sumsub-workflow?type=' + KycLevel.BUSINESS,
            } as BaseWidgetProperties,
          } as WorkflowWidgetConfigurationAttributes)
          .cssConfig({
            borderRadiusTopLeft: 6,
            borderRadiusBottomLeft: 6,
            borderRadiusTopRight: 6,
            borderRadiusBottomRight: 6,
          })
          .setGlobalStylingClass(true, `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`)
          .build(),

        new SpacerWidgetBuilder().setHeight({ height: 20, unit: 'px' }).build(),

        new BackButtonWidgetBuilder()
          .defaultBackButton()
          .cssConfig({
            marginTop: 14,
            marginBottom: 14,
          })
          .isRemovable(false)
          .setGlobalStylingClass(true, `${ButtonTypes.SECONDARY} ${ButtonSizeOptions.FULL_WIDTH}`)
          .build(),
      ])
      .build(),
  ];
}
