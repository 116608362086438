import { GridPosition, WorkflowWidgetConfig } from '@finxone-platform/shared/sys-config-types';
import {
  addCardMultiIconQL,
  addFundsIconQuicklink,
  availableBalanceTextWidget,
  cancelCardMultiIconQL,
  cardBalanceTextWidget,
  cardDetailsQuicklink,
  cardDetailsTextWidget,
  cardLimitQuicklink,
  cardLimitTextWidget,
  cardsWidget,
  createShowPINCTAButton,
  editCardNameMultiIconQL,
  freezeCardToggleWidget,
  replaceCardMultiIconQL,
  transactionIconQuicklink,
  transactionsTextWidget,
} from '../../../widgets/card-management-v2/card-management-v2.widgets';
import { AddToGooglePayWidgetBuilder } from '../../../widgets/example-builder';
import { getBackButtonWidget } from '../../manage-users-zone/common-utils';

export const landingPageWidgets: WorkflowWidgetConfig[] = [
  cardBalanceTextWidget,
  availableBalanceTextWidget,
  addFundsIconQuicklink,
  cardsWidget,
  new AddToGooglePayWidgetBuilder()
    .cssConfig({
      marginUnit: 'px',
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
      marginBottom: 0,
      borderRadius: 6,
      width: 342,
      height: 44,
    })
    .gridPosition({
      x: 0,
      y: 11,
      rows: 2,
      cols: 15,
    } as GridPosition)
    .build(),
  transactionIconQuicklink,
  transactionsTextWidget,
  cardDetailsQuicklink,
  cardDetailsTextWidget,
  cardLimitQuicklink,
  cardLimitTextWidget,
  freezeCardToggleWidget,
  createShowPINCTAButton,
  addCardMultiIconQL,
  cancelCardMultiIconQL,
  editCardNameMultiIconQL,
  replaceCardMultiIconQL,
  getBackButtonWidget({
    position: { y: 36, x: 0, rows: 2, cols: 15 } as GridPosition,
    styles: {
      fontSize: 14,
      textAlignment: 'center',
      backGroundColor: 'var(--palette-color-white)',
      borderWidth: 1,
      borderColor: 'var(--palette-color-black)',
    },
  }),
];
