import {
  BaseWidgetProperties,
  GridPosition,
  SINK_TYPE,
  ValidationRuleTypes,
  WidgetNames,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import {
  CustomQuestionWidgetBuilder,
  ImageWidgetBuilder,
  ProgressBarBuilder,
  QuickLinkWidgetBuilder,
  TextWidgetBuilder,
} from '../../../widgets';
import { customDataQuestionData } from '../../organisation-user/onboarding-organisation/onboarding-organisation';
import { backButtonQuickLink } from '../../organisation-user/organisation-profile/utils';

export const topRightBackButton = backButtonQuickLink({
  gridPosition: {
    y: 0,
    x: 28,
    rows: 2,
    cols: 6,
  } as GridPosition,
  cssConfig: {
    marginTop: 30,
    marginBottom: 5,
  },
});

export const nextButtonQuickLink = ({
  textContent,
  gridPosition,
  navigateToPageUrl,
  cssConfig,
  zone,
}: {
  textContent: string;
  gridPosition: GridPosition;
  navigateToPageUrl: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cssConfig?: any;
  zone: string;
}) =>
  new QuickLinkWidgetBuilder()
    .zone(zone)
    .navigateToPageUrl(navigateToPageUrl)
    .navigationOption('use-as-navigation')
    .role()
    .text(`${textContent}`)
    .configDisplayName(`Quick Link: ${textContent}`)
    .cssConfig({
      marginTop: 15,
      marginBottom: 15,
      color: 'var(--typography-color-0)',
      backGroundColor: 'var(--palette-color-3)',
      borderRadiusTopLeft: 5,
      borderRadiusBottomLeft: 5,
      borderRadiusTopRight: 5,
      borderRadiusBottomRight: 5,
      fontSize: 16,
      ...cssConfig,
    })
    .gridPosition({ ...gridPosition, resizeEnabled: true, dragEnabled: true })
    .build();

export const topRightNextButtonQuickLink = ({
  textContent,
  navigateToPageUrl,
  zone,
}: {
  textContent: string;
  navigateToPageUrl: string;
  zone: string;
}) =>
  nextButtonQuickLink({
    navigateToPageUrl,
    textContent: textContent,
    gridPosition: {
      y: 0,
      x: 35,
      rows: 2,
      cols: 6,
    } as GridPosition,
    zone,
    cssConfig: {
      marginTop: 30,
      marginBottom: 5,
    },
  });

export const customQuestionField = ({
  questionUniqueId,
  label,
  type,
  questionOptions,
}: {
  questionUniqueId: string;
  label: string;
  type: string;
  questionOptions?: {
    text: string;
    value: string;
    suboption: { text: string; value: string }[];
  }[];
}) => {
  return {
    name: questionUniqueId,
    label: label,
    type: type,
    options:
      questionOptions ??
      ([] as {
        text: string;
        value: string;
        suboption: { text: string; value: string }[];
      }[]),
    preSelectSubAnswer: 'false',
  };
};

export const elementsConfig = ({
  label,
  questionUniqueId,
  type,
}: {
  label: string;
  questionUniqueId: string;
  type: string;
}) => {
  return [
    {
      field_name: WidgetNames.CUSTOM_QUESTION_WIDGET,
      label,
      type,
      section: 'questionwidget',
      show: false,
      options: [],
      uniqueId: questionUniqueId,
    },
  ];
};

export const validationRules = ({ mandatoryValidation }: { mandatoryValidation: boolean }) => {
  return [
    {
      type: ValidationRuleTypes.REQUIRED,
      value: mandatoryValidation ? 'true' : 'false',
    },
  ];
};

export const customQuestion = ({
  gridPosition,
  label,
  inputType = 'text',
  questionUniqueId,
  entityType,
  questionType = 'text',
  sink,
  data_type,
  questionOptions,
}: {
  gridPosition: GridPosition;
  label: string;
  inputType?: string;
  questionUniqueId: string;
  entityType: string;
  questionType?: string;
  sink?: SINK_TYPE;
  data_type?: string;
  questionOptions?: {
    text: string;
    value: string;
    suboption: { text: string; value: string }[];
  }[];
}) => {
  return new CustomQuestionWidgetBuilder()
    .gridPosition({ ...gridPosition, rows: 3, cols: 13 })
    .configDisplayName(`Custom Question Widget: ${label}`)
    .cssConfig({ borderWidth: '0' })
    .attributes({
      customDataQuestionData: customDataQuestionData({
        entityType,
        sink,
        data_type,
      }),
      label: `${label}`,
      inputType,
      questionUniqueId,
      elementsConfig: elementsConfig({
        label: `${label}`,
        questionUniqueId,
        type: questionType,
      }),
      validationRules: validationRules({ mandatoryValidation: false }),
      questionProperties: customQuestionField({
        label: `${label}`,
        questionUniqueId,
        type: questionType,
        questionOptions,
      }),
      widgetProperties: {
        showHeader: false,
        showSubHeader: false,
      },
    } as unknown as WorkflowWidgetConfigurationAttributes)
    .build();
};

export const headerStepTitle = ({
  textContent,
  cssConfig,
  gridPosition,
}: {
  textContent: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cssConfig?: any;
  gridPosition?: GridPosition;
}) =>
  new TextWidgetBuilder()
    .text(textContent)
    .gridPosition({
      y: 3,
      x: 35,
      rows: 2,
      cols: 6,
      ...gridPosition,
    } as GridPosition)
    .configDisplayName(`Text Widget: ${textContent}`)
    .cssConfig({
      color: 'var(--typography-color-0)',
      fontSize: 20,
      paddingLeft: 40,
      textAlignment: 'right',
      ...cssConfig,
    })
    .build();

export const headerProgressBar = ({
  completionProgressPercentage,
  cssConfig,
  gridPosition,
}: {
  completionProgressPercentage: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cssConfig?: any;
  gridPosition?: GridPosition;
}) =>
  new ProgressBarBuilder()
    .setWidgetProperties({
      textContent: '',
      completionProgressPercentage,
      progressBarActiveColor: 'var(--palette-color-3)',
      progressBarColor: 'var(--palette-color-4)',
    })
    .configDisplayName('Progress Bar Widget')
    .gridPosition({
      y: 5,
      x: 0,
      rows: 2,
      cols: 42,
      ...gridPosition,
    } as GridPosition)
    .cssConfig({
      color: 'var(--typography-color-0)',
      textAlignment: 'center',
      justifyContent: 'center',
      fontSize: 24,
      paddingLeft: 40,
      paddingRight: 40,
      backGroundColor: 'transparent',
      ...cssConfig,
    })
    .isRemovable(true)
    .build();

export const boxLineTextWidget = ({ gridPosition, height }: { gridPosition: GridPosition; height: string }) =>
  new TextWidgetBuilder()
    .text(
      ` <div
        style="
          border: 0.5px solid rgba(206, 212, 218, 1);
          border-radius: 5px;
          width: 100%;
          height: ${height};
        "
      ></div> `,
    )
    .gridPosition({
      layerIndex: 0,
      ...gridPosition,
    } as GridPosition)
    .configDisplayName('Text Widget: Box Line')
    .cssConfig({
      color: 'var(--typography-color-1)',
      fontSize: 20,
      textAlignment: 'center',
    })
    .build();
export const boxLineSuccessTextWidget = ({ gridPosition }: { gridPosition?: GridPosition }) =>
  boxLineTextWidget({
    gridPosition: {
      x: 12,
      y: 4,
      rows: 16,
      cols: 15,
      ...gridPosition,
    } as GridPosition,
    height: '100%',
  });

export const doneQuickLinkSuccessPage = ({
  zone,
  navigateToPageUrl,
}: {
  zone: string;
  navigateToPageUrl: string;
}) =>
  new QuickLinkWidgetBuilder()
    .zone(zone)
    .navigateToPageUrl(navigateToPageUrl)
    .navigationOption('use-as-navigation')
    .role()
    .text(`Done`)
    .configDisplayName(`Quick Link: Done`)
    .cssConfig({
      marginTop: 15,
      marginBottom: 15,
      color: 'var(--typography-color-0)',
      backGroundColor: 'var(--palette-color-3)',
      borderRadiusTopLeft: 5,
      borderRadiusBottomLeft: 5,
      borderRadiusTopRight: 5,
      borderRadiusBottomRight: 5,
      fontSize: 16,
    })
    .gridPosition({
      y: 16,
      x: 14,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 11,
      layerIndex: 1,
    })
    .build();

export const successImageWidget = ({ gridPosition }: { gridPosition?: GridPosition }) =>
  new ImageWidgetBuilder()
    .configDisplayName('Image Widget: Success Icon')
    .cssConfig({
      selectedobjectfit: 'contain',
    })
    .gridPosition({
      y: 5,
      x: 17,
      rows: 4,
      cols: 5,
      layerIndex: 1,
      ...gridPosition,
    } as GridPosition)
    .attributes({
      widgetProperties: {
        textContent: '',
        imageAssetUrl: 'assets/images/tick-success.svg',
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .build();

export const failedImageWidget = ({ gridPosition }: { gridPosition?: GridPosition }) =>
  new ImageWidgetBuilder()
    .configDisplayName('Image Widget: Failed Icon')
    .cssConfig({
      selectedobjectfit: 'contain',
    })
    .gridPosition({
      x: 17,
      y: 5,
      rows: 4,
      cols: 5,
      layerIndex: 1,
      ...gridPosition,
    } as GridPosition)
    .attributes({
      widgetProperties: {
        textContent: '',
        imageAssetUrl: 'assets/images/payment-decline.svg',
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .build();

export const failedTitleTextWidget = ({
  textContent,
  gridPosition,
}: {
  textContent: string;
  gridPosition?: GridPosition;
}) =>
  new TextWidgetBuilder()
    .text(textContent)
    .gridPosition({
      x: 14,
      y: 9,
      rows: 2,
      cols: 11,
      layerIndex: 1,
      ...gridPosition,
    } as GridPosition)
    .configDisplayName(`Text Widget: ${textContent}`)
    .cssConfig({
      color: 'var(--typography-color-0)',
      fontSize: 24,
      isTextBold: true,
      textAlignment: 'center',
    })
    .build();

export const failedSubTitleTextWidget = ({
  textContent,
  gridPosition,
}: {
  textContent: string;
  gridPosition?: GridPosition;
}) =>
  new TextWidgetBuilder()
    .text(textContent)
    .gridPosition({
      x: 14,
      y: 11,
      rows: 2,
      cols: 11,
      layerIndex: 1,
      ...gridPosition,
    } as GridPosition)
    .configDisplayName(`Text Widget: ${textContent}`)
    .cssConfig({
      color: 'var(--typography-color-1)',
      fontSize: 20,
      textAlignment: 'center',
      verticalAlignment: 'start',
    })
    .build();

export const discardQuickLinkFailedPage = ({
  zone,
  navigateToPageUrl,
  gridPosition,
}: {
  zone: string;
  navigateToPageUrl: string;
  gridPosition?: GridPosition;
}) =>
  new QuickLinkWidgetBuilder()
    .zone(zone)
    .navigateToPageUrl(navigateToPageUrl)
    .navigationOption('use-as-navigation')
    .role()
    .text(`Discard`)
    .configDisplayName(`Quick Link: Discard`)
    .cssConfig({
      marginTop: 15,
      marginBottom: 15,
      color: 'var(--palette-color-3)',
      backGroundColor: 'var(--palette-color-6)',
      borderWidth: 1,
      borderColor: 'var(--palette-color-3)',
      borderRadiusTopLeft: 5,
      borderRadiusBottomLeft: 5,
      borderRadiusTopRight: 5,
      borderRadiusBottomRight: 5,
      fontSize: 16,
    })
    .gridPosition({
      y: 13,
      x: 15,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 4,
      layerIndex: 1,
      ...gridPosition,
    })
    .build();

export const tryAgainQuickLinkFailedPage = ({
  zone,
  navigateToPageUrl,
  gridPosition,
}: {
  zone: string;
  navigateToPageUrl: string;
  gridPosition?: GridPosition;
}) =>
  new QuickLinkWidgetBuilder()
    .zone(zone)
    .navigateToPageUrl(navigateToPageUrl)
    .navigationOption('use-as-navigation')
    .role()
    .text(`Try again`)
    .configDisplayName(`Quick Link: Try again`)
    .cssConfig({
      marginTop: 15,
      marginBottom: 15,
      color: 'var(--typography-color-0)',
      backGroundColor: 'var(--palette-color-3)',
      borderRadiusTopLeft: 5,
      borderRadiusBottomLeft: 5,
      borderRadiusTopRight: 5,
      borderRadiusBottomRight: 5,
      fontSize: 16,
    })
    .gridPosition({
      y: 13,
      x: 20,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 4,
      layerIndex: 1,
      ...gridPosition,
    })
    .build();

export const boxLineFailedTextWidget = ({ gridPosition }: { gridPosition?: GridPosition }) =>
  boxLineTextWidget({
    gridPosition: {
      y: 4,
      x: 12,
      rows: 13,
      cols: 15,
      ...gridPosition,
    } as GridPosition,
    height: '100%',
  });
