<ng-container *ngIf="localeContent$ | async as locale">
  <div
    id="web-app"
    style="background: var(--color-background); height: 100vh; overflow-y: auto"
    [style.padding-top]="topPadding"
    [style.padding-bottom]="bottomPadding"
  >
    <div class="card-pf">
      <header style="margin-bottom: 20px">
        <div>
          <div *ngIf="companyLogo">
            <div
              [style]="
                isMobile
                  ? 'display: flex; justify-content: center; padding-top: 3rem'
                  : 'display: flex; justify-content: center'
              "
              [style.padding-top]="isMobile ? '3rem' : '5rem'"
            >
              <div class="Container" [innerHTML]="companyLogo | safeHtml"></div>
            </div>
          </div>
          <div *ngIf="localeValues.title && localeValues.title.length > 0">
            <div>
              <div>
                <div style="display: flex; justify-content: space-evenly">
                  <div style="align-self: center">
                    <h1>{{ localeValues.title }}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <!-- Error Display -->
      <div *ngIf="error" class="error-message">
        {{ error }}
      </div>

      <!-- Desktop PIN Boxes -->
      <ng-container *ngIf="!isMobile || isConfirmationTypeOTP()">
        <div *ngIf="localeValues">
          <finxone-web-frontend-pin-boxes
            [(pin)]="pin"
            [error]="error"
            (pinBoxes)="checkPinComplete()"
            [confirmationType]="confirmation"
            [localeValues]="localeValues"
          ></finxone-web-frontend-pin-boxes>
        </div>
      </ng-container>

      <!-- Mobile PIN Pad -->
      <ng-container *ngIf="isMobile && !isConfirmationTypeOTP()">
        <finxone-web-frontend-pin-pad
          [(pin)]="pin"
          [error]="error"
          [localeSubtitle]="localeValues.subtitle"
          (pinPad)="checkPinComplete()"
          (biometricAuth)="onBiometricChallenge($event)"
        ></finxone-web-frontend-pin-pad>
      </ng-container>

      <div style="display: none" class="user-info">
        <span class="alt-flow" (click)="onAltFlowClick($event)">{{ locale.doTryAnotherWay }}</span>
      </div>

      <div
        class=""
        style="
          width: 100%;
          display: flex;
          position: fixed;
          bottom: 2rem;
          left: 16px;
          right: 16px;
          margin-top: 3rem;
          margin-left: 16px;
          margin-right: 16px;
        "
      >
        <input
          style="position: fixed; bottom: 2rem; left: 16px; right: 16px"
          class="bottom-back button buttonText secondaryButton full-width"
          type="button"
          value="Back"
          (click)="onBackClick($event)"
        />
      </div>
    </div>
  </div>
</ng-container>
