import { Router } from '@angular/router';
import { createLoggedInModalPageUrl } from '@app/finxone-web-frontend/app/Modules/zones/modal-utils';
import { HttpErrorCode } from '@app/translations';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { AlertDetails, AlertStatusFlag, AlertViewType } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { ProjectAlertsState } from '../../state/project-alerts.state';
import { formatNameAsUrl } from '../zone-url.utils';

export function findAndShowAlertFromCode(
  store: Store,
  router: Router,
  alertHandlerService: AlertHandlerService,
  alertCodes: string[],
) {
  const alerts = store.selectSnapshot(ProjectAlertsState.getSystemAlerts) ?? {};

  // Filter and collect active alerts matching the given alert codes
  const activeAlertsToDisplay = Object.entries(alerts)
    .filter(
      ([alertCode, alertDetails]) =>
        alertCodes.includes(alertCode) && alertDetails?.status === AlertStatusFlag.ACTIVE,
    )
    .slice(0, 3) // Limit to first 3 alerts
    .map(([, alertDetails]) => alertDetails as AlertDetails);

  // Handle each alert appropriately
  activeAlertsToDisplay?.forEach((alert) => {
    if (alert?.viewType === AlertViewType.TOASTER) {
      showAlertFromCode(alertHandlerService, alert);
    } else {
      redirectToAlertUrl(router, alert);
    }
  });
}

/**
 * Redirects the user to a specified alert URL based on the alert details.
 *
 * @param router - The Angular Router instance for navigation.
 * @param alertDetails - The details of the alert, including view type and redirect paths.
 */
const redirectToAlertUrl = (router: Router, alertDetails: AlertDetails) => {
  try {
    // Extract zone and page information from the alert's viewRedirect property.
    const zoneUrl = alertDetails.viewRedirect?.zone ?? '';
    const pageUrl = alertDetails.viewRedirect?.page ?? '';

    // Check if the alert view type is a PAGE, which requires direct navigation.
    if (alertDetails.viewType === AlertViewType.PAGE) {
      // Navigate to the specified page URL within the zone.
      router.navigateByUrl(`/zones/${formatNameAsUrl(zoneUrl)}/${pageUrl}`);
    } else {
      // For other view types (e.g., MODAL), construct a modal navigation URL.
      const modalNavigationUrl = createLoggedInModalPageUrl({
        zoneUrl: formatNameAsUrl(zoneUrl), // Format zone URL to ensure compatibility.
        pageUrl: pageUrl, // Use the extracted page URL.
      });

      // Navigate to the constructed modal URL.
      router.navigateByUrl(modalNavigationUrl);
    }
  } catch (error) {
    // Log any errors encountered during the redirection process.
    console.error('redirectAlertUrl:', error);
  }
};

export function showAlertFromCode(alertHandlerService: AlertHandlerService, alertData: AlertDetails) {
  alertHandlerService.showAlertFn(alertData.type.toLowerCase(), alertData.description, alertData.title);
}

export function handleHttpErrorAlertCodes(
  alertHandlerService: AlertHandlerService,
  store: Store,
  router: Router,
  statusCode: number,
  errorMessage: string,
) {
  const errorMap: { [key: number]: HttpErrorCode } = {
    400: HttpErrorCode.STATUS_400,
    401: HttpErrorCode.STATUS_401,
    402: HttpErrorCode.STATUS_402,
    403: HttpErrorCode.STATUS_403,
    404: HttpErrorCode.STATUS_404,
    405: HttpErrorCode.STATUS_405,
    500: HttpErrorCode.STATUS_500,
    501: HttpErrorCode.STATUS_501,
    502: HttpErrorCode.STATUS_502,
    503: HttpErrorCode.STATUS_503,
    504: HttpErrorCode.STATUS_504,
    505: HttpErrorCode.STATUS_505,
  };

  const errorCode =
    errorMap[statusCode] ??
    (statusCode >= 400 && statusCode < 500
      ? HttpErrorCode.STATUS_4XX_CATCH_ALL_ERROR
      : HttpErrorCode.STATUS_5XX_CATCH_ALL_ERROR);

  if (errorCode) {
    findAndShowAlertFromCode(store, router, alertHandlerService, [errorCode]);
  } else {
    alertHandlerService.showAlertFn('error', errorMessage);
  }
}
