export const reviewInformationOrganisation = `<div class="w-100 h-100">
  <div class="d-flex justify-content-between mb-3">
    <div class="labelText textOnSurfaceMuted">Company name</div>
    <div class="body2 text-right textOnSurface text-capitalize">{{ organisationData.name }}</div>
  </div>
  <div class="d-flex justify-content-between mb-3">
    <div class="labelText textOnSurfaceMuted">Registration number</div>
    <div class="body1 text-right textOnSurface">{{ organisationData.identificationNumber }}</div>
  </div>
  <div class="d-flex justify-content-between mb-3">
    <div class="labelText textOnSurfaceMuted">Incorporation date</div>
    <div class="body1 text-right textOnSurface">{{ datePipe organisationData.incorporationDate format='dd-MM-yyyy' }}</div>
  </div>

  <hr/>

  {{#if organisationData.address}}
    {{#if organisationData.address.address1}}
      <div class="d-flex justify-content-between mb-3 mt-3">
        <div class="labelText textOnSurfaceMuted">Street</div>
        <div class="body1 text-right textOnSurface text-capitalize">{{ organisationData.address.address1 }}{{#if organisationData.address.address2}}, {{ organisationData.address.address2 }}{{/if}}{{#if organisationData.address.address3}}, {{ organisationData.address.address3 }}{{/if}}{{#if organisationData.address.address4}}, {{ organisationData.address.address4 }}{{/if}}</div>
      </div>
    {{/if}}
    
    {{#if organisationData.address.city}}
      <div class="d-flex justify-content-between mb-3">
        <div class="labelText textOnSurfaceMuted">City</div>
        <div class="body1 text-right textOnSurface text-capitalize">{{ organisationData.address.city }}</div>
      </div>
    {{/if}}
    
    {{#if organisationData.address.state}}
      <div class="d-flex justify-content-between mb-3">
        <div class="labelText textOnSurfaceMuted">County</div>
        <div class="body1 text-right textOnSurface text-capitalize">{{ organisationData.address.state }}</div>
      </div>
    {{/if}}
    
    {{#if organisationData.address.country}}
      <div class="d-flex justify-content-between mb-3">
        <div class="labelText textOnSurfaceMuted">Country</div>
        <div class="body1 text-right textOnSurface text-capitalize">{{ countryFullName organisationData.address.country }}</div>
      </div>
    {{/if}}
    
    {{#if organisationData.address.postalCode}}
      <div class="d-flex justify-content-between ">
        <div class="labelText textOnSurfaceMuted">Post code</div>
        <div class="body1 text-right textOnSurface text-capitalize">{{ organisationData.address.postalCode }}</div>
      </div>
    {{/if}}
  {{/if}}
</div>`;
