import { GridPosition } from '@finxone-platform/shared/sys-config-types';
import { generateManageUserStep03WidgetsConfig } from '../../../widgets/admin-roles/user-managment.widgets';
import {
  generateCommonProgressBarWidget,
  generateHeaderTextWidget,
  generateSubHeaderLeftTextWidget,
  generateSubHeaderRightTextWidget,
  getBackButtonWidget,
  getQuickLinkWidget,
  lineWidgetBuilderConfig,
} from '../common-utils';

export const addUsersStepThreeWidgets = [
  generateHeaderTextWidget(),

  lineWidgetBuilderConfig,

  generateSubHeaderRightTextWidget('Address'),

  generateSubHeaderLeftTextWidget('Step 3/3'),

  generateCommonProgressBarWidget('95'),

  generateManageUserStep03WidgetsConfig(),

  getBackButtonWidget({
    position: { y: 19, x: 0, rows: 1, cols: 3 } as GridPosition,
    styles: {
      fontSize: 16,
      textAlignment: 'center',
      color: 'var(--palette-color-3)',
      backGroundColor: 'var(--palette-color-6)',
      borderWidth: 1,
      borderColor: 'var(--palette-color-3)',
    },
  }),
  getQuickLinkWidget({
    text: 'Review Details',
    position: { y: 19, x: 4, rows: 1, cols: 5 } as GridPosition,
    pageUrl: 'add-user-review',
    displayName: 'Quick Link: Review Details',
  }),
];
