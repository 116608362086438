import { GridPosition } from '@finxone-platform/shared/sys-config-types';

import { addBackButton } from '../../../../page-builder-utils/widget-builder-utils/common-widget-utils';

import { BulkPaymentsOtpVerificationWidgetBuilder } from '../../../widgets/otp-builder';
import { bulkPaymentFooter, generateTextWidget, getHeaderWidget } from '../common-widgets-utils';

export const bulkPaymentAuthWidgets = [...header(), ...bulkPaymentFooter(25)];
function header() {
  return [
    ...getHeaderWidget('<h3>Authentication</h3>', '<p>Authenticate yourself to initiate the payment.</p>'),
    ...addBackButton(
      {
        x: 42,
        y: 1,
        rows: 1,
        cols: 4,
      } as GridPosition,
      {
        marginTop: 0,
        marginBottom: 0,
        backGroundColor: 'transparent',
        borderColor: 'var(--border-color-0)',
        borderWidth: 1,
        color: 'var(--palette-color-3)',
      },
    ),
    generateTextWidget({
      text: '<h3>Check your SMS!</h3>',
      position: { x: 0, y: 4, rows: 1, cols: 20 } as GridPosition,
      displayName: 'Text Widget:Auth Message',
      styles: {
        fontSize: 24,
        isTextBold: true,
        textAlignment: 'left',
      },
      isRemovable: true,
    }),
    generateTextWidget({
      text: '<p>We have sent you a 6 digit code at: {{ profile.phoneNumber }}</p>',
      position: { x: 0, y: 5, rows: 1, cols: 20 } as GridPosition,
      displayName: 'Text Widget:  Sub header',
      styles: {
        fontSize: 20,
        color: 'var(--palette-color-1)',
        textAlignment: 'left',
      },
      isRemovable: true,
    }),
    new BulkPaymentsOtpVerificationWidgetBuilder()
      .setWidgetContext('phone', 'bulk-payments')
      .gridPosition({
        x: 0,
        y: 7,
        rows: 6,
        cols: 19,
      } as GridPosition)
      .build(),
  ];
}
