import { CurrencyPipe, DatePipe } from '@angular/common';
import { IconCacheService } from '@finxone-platform/shared/services';
import Handlebars from 'handlebars';
import moment from 'moment';
import { Country } from '../../../dtos/countries.dto';
import { CountriesCurrenciesService } from '../../../services/countries-currencies-service/countries-currencies.service';
import { addComma, addDashesToSortCode, nthLetter, truncate, truncateFromStart } from '../string.utils';
import { registerComparisonHelpers } from './comparison.helper';
import { countryName } from './country-full-name';
import { countryCodeToCircleFlag, currencyToCircleFlag } from './currency-to-flag.utils';
import { iconNameToSvg } from './handlebar-icons.utils';
import { imageFromImageName } from './image.utils';
import { isAfter, isAfterAndBefore } from './is-after.helper';
import { displayFeeCharge, valueToTitleChange } from './title.utils';

export function registerHandlerbarHelpers(
  datePipe: DatePipe,
  currencyPipe: CurrencyPipe,
  countriesCurrenciesService: CountriesCurrenciesService,
  iconCacheService: IconCacheService,
) {
  let countriesData: Country[] = [];
  countriesCurrenciesService.getCountries().subscribe((data) => {
    countriesData = data;
  });

  registerComparisonHelpers();

  Handlebars.registerHelper('isAfter', isAfter);
  Handlebars.registerHelper('isAfterAndBefore', isAfterAndBefore);
  Handlebars.registerHelper('truncate', truncate);
  Handlebars.registerHelper('truncateFromEnd', truncate);
  Handlebars.registerHelper('truncateFromStart', truncateFromStart);
  Handlebars.registerHelper('nthLetter', nthLetter);
  Handlebars.registerHelper('currencyToFlag', currencyToCircleFlag);
  Handlebars.registerHelper('countryCodeToFlag', countryCodeToCircleFlag);

  Handlebars.registerHelper('countryFullName', function (countryCode: string) {
    return countryName(countryCode, countriesData);
  });

  Handlebars.registerHelper('imageFromImageName', imageFromImageName);
  Handlebars.registerHelper('addComma', addComma);
  Handlebars.registerHelper('valueToTitleChange', valueToTitleChange);

  Handlebars.registerHelper('feeChargeValue', function (feeCharge, unitChargeType, currency) {
    return displayFeeCharge(feeCharge, unitChargeType, currency, currencyPipe);
  });

  Handlebars.registerHelper('currencyPipe', function (value: number | string, options: any) {
    // Handle null, undefined, and empty string cases
    if (value === null || value === undefined || value === '') {
      return null;
    }

    if (value?.toString()?.includes('skeleton_')) {
      return value.toString();
    }

    let numberValue: number;
    if (typeof value === 'number') {
      numberValue = value;
    } else {
      // Replace commas and convert the input to a number
      const sanitizedValue = (value as string).replace(/,/g, '');
      numberValue = parseFloat(sanitizedValue);
    }

    if (isNaN(numberValue)) {
      return null;
    }
    return currencyPipe
      .transform(
        numberValue,
        options.hash.currencyCode,
        options.hash.display,
        options.hash.digitsInfo,
        options.hash.locale,
      )
      ?.replace(/([^\d.,])(\d)/, '$1 $2');
  });

  Handlebars.registerHelper('formatMask', function format(value, pattern) {
    let i = 0;
    const v = value?.toString();
    if (value) {
      return pattern.replace(/#/g, () => v[i++]);
    }

    return '-';
  });

  Handlebars.registerHelper('addDashesToSortCode', addDashesToSortCode);

  Handlebars.registerHelper('datePipe', function (value: string | number | Date, options: any) {
    if (value?.toString()?.includes('skeleton_')) {
      return value.toString();
    }
    return datePipe.transform(value, options.hash.format, options.hash.timezone, options.hash.locale);
  });

  Handlebars.registerHelper('for', function (from, to, incr, block) {
    let accum = '';
    for (let i = from; i < to; i += incr) accum += block.fn(i);
    return accum;
  });

  /**
   * For Extracting time and date from a give string e.g: 02/05/2024 06:08
   * It returns date value formatted like 02 May 2024 (as per e.g.)
   * It returns time value in 12Hr format
   */
  Handlebars.registerHelper('splitDateTime', (dateTimeStr, type) => {
    const parts = dateTimeStr.split(' ');
    const dateStr = parts[0];
    const time = parts[1];

    // Create a date object from the date string
    const [day, month, year] = dateStr.split('/').map((num: string) => parseInt(num, 10));
    const date = new Date(year, month - 1, day);

    if (type === 'time') {
      // Split the time into hours and minutes
      // eslint-disable-next-line prefer-const
      let [hours, minutes] = time.split(':').map((num: string) => parseInt(num, 10));
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      return `${hours}:${minutes < 10 ? '0' + minutes : minutes} ${ampm}`;
    } else {
      // Format the date as '28 Nov 2023'
      const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      return `${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()}`;
    }
  });

  Handlebars.registerHelper('capitaliseFirstLetter', (input) => {
    if (!input) return;
    return input.charAt(0).toUpperCase() + input.slice(1);
  });

  Handlebars.registerHelper('removeUnderscoreAndCapitalise', (value: string) => {
    return value
      ?.split('_') // Split the string by underscores
      ?.map((word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
      ?.join(' '); // Join the words with a space
  });

  // {{{ svgIcon 'bell' }}}
  Handlebars.registerHelper('svgIcon', function (iconName: string, width?: string, height?: string) {
    return iconNameToSvg(iconName, width, height, iconCacheService);
  });

  /**
   * Registers a Handlebars helper named 'relativeDateFormat' to format dates
   * based on a specified grouping ('day', 'week', 'month', 'year') and format string.
   *
   * @param {string | Date} date - The date to be formatted.
   * @param {'day' | 'week' | 'month' | 'year'} grouping - The grouping category for formatting.
   * @param {string} format - The desired date format string.
   * @returns {string} - The formatted date string or an empty string if the date is invalid.
   *
   * The function uses predefined date formats for each grouping and provides
   * special handling for week formats by calculating the start and end of the week.
   * If the specified format does not exist for the given grouping, a default format is used.
   */
  Handlebars.registerHelper(
    'relativeDateFormat',
    function (
      date: string | Date,
      grouping: 'day' | 'week' | 'month' | 'year',
      format: string,
      isRelativeDate = false,
    ) {
      const inputDate = moment(date);
      const now = moment();

      if (!inputDate.isValid()) {
        return '';
      }

      // Check if the format exists for the given grouping
      if (!DATE_FORMATS[grouping]?.[format]) {
        return inputDate.format('DD MMM YYYY'); // Default format
      }

      if (isRelativeDate) {
        // Handle relative date formats
        if (grouping === 'day') {
          if (inputDate.isSame(now, 'day')) {
            return 'Today';
          }
          if (inputDate.isSame(now.clone().subtract(1, 'day'), 'day')) {
            return 'Yesterday';
          }
        } else if (grouping === 'week') {
          if (inputDate.isSame(now, 'week')) {
            return 'This Week';
          }
          if (inputDate.isSame(now.clone().subtract(1, 'week'), 'week')) {
            return 'Last Week';
          }
        } else if (grouping === 'month') {
          if (inputDate.isSame(now, 'month')) {
            return 'This Month';
          }
          if (inputDate.isSame(now.clone().subtract(1, 'month'), 'month')) {
            return 'Last Month';
          }
        }
      }

      // Special handling for week formats
      if (grouping === 'week') {
        const weekStart = inputDate.clone().startOf('week');
        const weekEnd = inputDate.clone().endOf('week');

        const formatMap: Record<string, string> = {
          'Week of DD MMM YYYY': `Week of ${weekStart.format('DD MMM YYYY')}`,
          'DD MMM - DD MMM YYYY': `${weekStart.format('DD MMM')} - ${weekEnd.format('DD MMM YYYY')}`,
          'MMM DD - DD, YYYY': `${weekStart.format('MMM DD')} - ${weekEnd.format('DD, YYYY')}`,
          'Week of DD MMMM YYYY': `Week of ${weekStart.format('DD MMMM YYYY')}`,
          'DD MMMM - DD MMMM YYYY': `${weekStart.format('DD MMMM')} - ${weekEnd.format('DD MMMM YYYY')}`,
          'MMMM DD - DD, YYYY': `${weekStart.format('MMMM DD')} - ${weekEnd.format('DD, YYYY')}`,
        };

        return formatMap[format] || formatMap['Week of DD MMM YYYY'];
      }

      // For day, month, and year groupings, use the format directly
      return inputDate.format(DATE_FORMATS[grouping][format]);
    },
  );
}

const DATE_FORMATS: Record<string, Record<string, string>> = {
  day: {
    'DD MMM YYYY': 'DD MMM YYYY',
    'DD MMM': 'DD MMM',
    'DD MMMM YYYY': 'DD MMMM YYYY',
    'DD MMMM': 'DD MMMM',
    'MMM DD, YYYY': 'MMM DD, YYYY',
    'MMMM DD, YYYY': 'MMMM DD, YYYY',
    'DD-MM-YYYY': 'DD-MM-YYYY',
    'MM/DD/YYYY': 'MM/DD/YYYY',
  },
  week: {
    'Week of DD MMM YYYY': 'Week of DD MMM YYYY',
    'DD MMM - DD MMM YYYY': 'DD MMM - DD MMM YYYY',
    'MMM DD - DD, YYYY': 'MMM DD - DD, YYYY',
    'Week of DD MMMM YYYY': 'Week of DD MMMM YYYY',
    'DD MMMM - DD MMMM YYYY': 'DD MMMM - DD MMMM YYYY',
    'MMMM DD - DD, YYYY': 'MMMM DD - DD, YYYY',
  },
  month: {
    'MMMM YYYY': 'MMMM YYYY',
    'MMM YYYY': 'MMM YYYY',
  },
  year: {
    YYYY: 'YYYY',
  },
};
