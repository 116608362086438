import { GridPosition } from '@finxone-platform/shared/sys-config-types';
import { generateManageUserStep01WidgetsConfig } from '../../../widgets/admin-roles/user-managment.widgets';
import {
  generateCommonProgressBarWidget,
  generateHeaderTextWidget,
  generateSubHeaderLeftTextWidget,
  generateSubHeaderRightTextWidget,
  generateTextWidget,
  getBackButtonWidget,
  getQuickLinkWidget,
  lineWidgetBuilderConfig,
} from '../common-utils';

export const addUsersStepOneWidgets = [
  generateHeaderTextWidget(),

  lineWidgetBuilderConfig,

  generateSubHeaderRightTextWidget('Personal Information'),

  generateSubHeaderLeftTextWidget('Step 1/3'),

  generateCommonProgressBarWidget('32'),

  generateTextWidget({
    text: `<div>User with email {{addUserForm.email}} does not exist. Please add new user to grant access.</div>`,
    position: { y: 6, x: 0, rows: 2, cols: 25 } as GridPosition,
    styles: {
      color: 'var(--typography-color-1)',
      fontSize: 14,
      textAlignment: 'left',
    },
    displayName: 'Text widget:Info',
    isRemovable: true,
  }),

  generateManageUserStep01WidgetsConfig(),

  getBackButtonWidget({
    position: { y: 17, x: 0, rows: 1, cols: 3 } as GridPosition,
    styles: {
      fontSize: 16,
      textAlignment: 'center',
      color: 'var(--palette-color-3)',
      backGroundColor: 'var(--palette-color-6)',
      borderWidth: 1,
      borderColor: 'var(--palette-color-3)',
    },
  }),
  getQuickLinkWidget({
    text: 'Next',
    position: { y: 17, x: 4, rows: 1, cols: 4 } as GridPosition,
    pageUrl: 'add-user-step-2',
    displayName: 'Quick Link: Next',
  }),
];
