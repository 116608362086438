import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { OtpParam } from '@finxone-platform/form-action';
import { BaseWidgetProperties, PaymentFlowTypes } from '@finxone-platform/shared/sys-config-types';
import { redirectToPage } from '../cta-button-actions.utils';

/**
 * Redirects the user to a specific page based on the payment flow type extracted from the current URL.
 *
 * This function retrieves the current router instance from the provided injector and parses the URL
 * to determine the payment flow type. Depending on whether the payment flow is SEPA or not, it sets
 * the appropriate URL to navigate to within the widget properties. Finally, it calls the
 * `redirectToPage` function to perform the navigation.
 *
 * @param widgetProperties - The properties of the widget, including navigation details.
 * @param injector - The injector used to retrieve the router instance.
 */
export function skipAddressRedirection(widgetProperties: BaseWidgetProperties, injector: Injector) {
  const router = injector.get(Router);
  const queryParams = new URLSearchParams(router?.url?.split('?')[1] ?? '');
  const paymentFlow = queryParams.get('paymentflow');
  const urlToNavigate =
    paymentFlow === PaymentFlowTypes.SEPA
      ? `review-information-match?flow=${OtpParam.AddBeneficiary}`
      : `intl-review-beneficiary-details`;
  widgetProperties['urlToNavigate'] = urlToNavigate;
  redirectToPage(router, widgetProperties, injector);
}
