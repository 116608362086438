/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { ConfigService } from '../config-service/config-service.service';

@Injectable({
  providedIn: 'root',
})
export class BulkPaymentService {
  private baseUrl = '';
  constructor(private http: HttpClient, private configService: ConfigService) {
    this.configService.getApi('account_service').subscribe((response) => {
      this.baseUrl = response;
    });
  }
  requestBulkPayment(id: string) {
    return this.http.post(`${this.baseUrl}/bulk-payments/${id}/transfer-payments-request`, {}).pipe(
      catchError<any, Observable<boolean>>((_err, _caught) => {
        console.error(`Failed to request payment ${JSON.stringify(id)}: ${JSON.stringify(_err)}`);
        throw _err;
      }),
    );
  }
}
