import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import {
  APP_ZONES,
  BaseWidgetProperties,
  PaymentStatusScreen,
} from '@finxone-platform/shared/sys-config-types';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { map, take } from 'rxjs';
import { PaymentDeclined, PaymentSuccess } from '../../../actions/payment.action';
import { ProjectSettingsState } from '../../../state/project-settings.state';
import { redirectToPage } from '../cta-button-actions.utils';
import { submitInitiatorPayment } from './submit-initiator-payment';

/**
 * Initiates the domestic payment approval process for a widget.
 *
 * This function handles the initiation of a domestic payment approval by
 * dispatching actions and navigating to appropriate screens based on the
 * payment outcome. It checks if SMS authentication is enabled and redirects
 * accordingly. If SMS authentication is not enabled, it submits the payment
 * directly.
 *
 * @param widgetProperties - The properties of the widget initiating the payment.
 * @param injector - The Angular injector used to retrieve services.
 */
export function paymentApproverInitiateDomesticPayment(
  widgetProperties: BaseWidgetProperties,
  injector: Injector,
) {
  const _store = injector.get(Store);
  const _actions = injector.get(Actions);
  const router = injector.get(Router);
  const isSmsAuthEnabled = _store.selectSnapshot(
    ProjectSettingsState.getProjectSettings,
  ).smsAuthenticationEnabled;

  if (!widgetProperties['zoneToNavigate']) {
    widgetProperties['zoneToNavigate'] = APP_ZONES.PAYMENT;
  }
  const parsedWidgetProperties = JSON.parse(JSON.stringify(widgetProperties));

  _actions
    .pipe(
      ofActionDispatched(PaymentSuccess),
      take(1),
      map(() => {
        parsedWidgetProperties['urlToNavigate'] = `${PaymentStatusScreen.PAYMENT_SUCCESS}-initiator`;
        redirectToPage(router, parsedWidgetProperties, injector, true);
      }),
    )
    .subscribe();

  _actions
    .pipe(
      ofActionDispatched(PaymentDeclined),
      take(1),
      map(() => {
        parsedWidgetProperties['urlToNavigate'] = `payment-failed-initiator`;
        redirectToPage(router, parsedWidgetProperties, injector, true);
      }),
    )
    .subscribe();
  if (isSmsAuthEnabled) {
    redirectToPage(router, widgetProperties, injector);
  } else {
    submitInitiatorPayment(injector, parsedWidgetProperties);
  }
}
