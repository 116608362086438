import {
  APP_ZONES,
  BaseWidgetProperties,
  GridPosition,
  ListingTypeEnum,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import {
  BackButtonWidgetBuilder,
  FeeClientListingWidgetBuilder,
  SwitchNavigatorWidgetBuilder,
} from '../../../defaults/widgets/example-builder';
import { inputSettings } from '../../../defaults/widgets/payments/payments.type';
import { switchNavigatorCss } from '../../../defaults/widgets/workflow-ui-constants.v2.type';
import {
  generateTextWidget,
  getQuickLinkWidget,
} from '../../../defaults/zones/payments/common-widgets-utils';

export const feeListingColumns = [
  {
    label: 'Fee name',
    value: `feeName`,
    handleBarKey: '<span class="text-capitalize">{{feeName}}</span>',
    selected: true,
  },
  {
    label: 'Currency',
    value: `currency`,
    handleBarKey: '{{currency}}',
    selected: true,
  },
  {
    label: 'Frequency',
    value: `frequency`,
    handleBarKey: '<span class="text-capitalize">{{feeFrequencySettings.frequencyType}}</span>',
    selected: true,
  },
  {
    label: 'Amount/Percentage',
    value: `amountPercentage`,
    handleBarKey: `{{ feeChargeValue feeCharge unitChargeType currency }}`,
    selected: true,
  },
  {
    label: 'Unit charge',
    value: `unitCharge`,
    handleBarKey: '{{ valueToTitleChange unitChargeType}}',
    selected: true,
  },
  {
    label: 'Group assigned',
    value: `groupAssigned`,
    handleBarKey: '<span class="text-capitalize">{{feeGroupId.name}}</span>',
    selected: true,
  },
  {
    label: 'Effective from:',
    value: `effectiveFrom:`,
    handleBarKey: "{{ datePipe feeFrequencySettings.startDate  format='d MMM y'}}",
    selected: true,
  },
  {
    label: 'Status',
    value: `status`,
    handleBarKey: '<span class="text-capitalize">{{ status}}</span>',
    selected: true,
  },
];

export const clientListingColumns = [
  {
    label: 'Client name',
    value: `clientName`,
    handleBarKey: '<span class="text-capitalize">{{displayName}}</span>',
    selected: true,
  },
  {
    label: 'Number of accounts',
    value: `numberOfAccounts`,
    handleBarKey: '{{numberOfAccounts}}',
    selected: true,
  },
  {
    label: 'Group assigned:',
    value: `groupAssigned`,
    handleBarKey: `{{#if (neq feeGroupName '')}}<span class="text-capitalize">{{feeGroupName}}</span>{{else}} -- {{/if}}`,
    selected: true,
  },
  {
    label: 'Client type',
    value: `clientType`,
    handleBarKey: '<span class="text-capitalize">{{clientType}}</span>',
    selected: true,
  },
  {
    label: 'Address',
    value: `address`,
    handleBarKey: `<span>
    {{#if attributes.address.address1}}{{attributes.address.address1}}, {{/if}}
    {{#if attributes.address.address2}}{{attributes.address.address2}}, {{/if}}
    {{#if attributes.address.address3}}{{attributes.address.address3}}, {{/if}}
    {{#if attributes.address.address4}}{{attributes.address.address4}}, {{/if}}
    {{#if attributes.address.postalCode}}{{attributes.address.postalCode}}, {{/if}}
    {{#if attributes.address.country}}{{attributes.address.country}}, {{/if}}
    {{#if attributes.address.city}}{{attributes.address.city}}, {{/if}}
    {{#if attributes.address.state}}{{attributes.address.state}}{{else}}--{{/if}}
  </span>
  
`,
    selected: true,
  },
  {
    label: 'Client since:',
    value: `clientSince`,
    handleBarKey:
      " {{#if (neq clientSince '')}}{{ datePipe clientSince format='d MMM y'}}{{else}} -- {{/if}}",
    selected: true,
  },
];
function commonHeaderWidget() {
  const commonHeaderWidget = [
    generateTextWidget({
      text: 'Fee/Client Listing - Fee Management',
      position: { x: 1, y: 0, rows: 1, cols: 28 } as GridPosition,
      displayName: 'Header Text',
      styles: {
        fontSize: 24,
        isTextBold: true,
        verticalAlignment: 'start',
        textAlignment: 'left',
        noAlignment: true,
      },
      isRemovable: true,
    }),

    generateTextWidget({
      text: 'You can access the drill-down view for each client to examine the linked accounts in greater detail. Additionally, you can manage fees for each client associated with that specific group.',
      position: { x: 1, y: 1, rows: 2, cols: 31 } as GridPosition,
      displayName: 'Information Content',
      styles: {
        verticalAlignment: 'start',
        textAlignment: 'left',
        fontSize: '16',
        lineHeight: '24',
        textFont: 'var(--font-family)',
        color: 'var(--typography-color-1)',
      },
      isRemovable: true,
    }),

    new BackButtonWidgetBuilder()
      .defaultBackButton()
      .configDisplayName('Back Navigation Button')
      .text('Back')
      .cssConfig({
        color: 'var(--palette-color-3)',
        backGroundColor: 'var(--palette-color-6)',
        borderWidth: 1,
        borderColor: 'var(--palette-color-3)',
        fontSize: 14,
        textAlign: 'center',
        verticalAlignment: 'center',
      })
      .gridPosition({
        y: 0,
        x: 37,
        rows: 1,
        cols: 4,
        resizeEnabled: true,
        dragEnabled: true,
      })
      .build(),

    getQuickLinkWidget({
      text: 'View fee groups',
      position: {
        x: 42,
        y: 0,
        rows: 1,
        cols: 5,
      } as GridPosition,
      pageUrl: 'group-list',
      displayName: 'Quick Link: View fee groups',
      styles: {
        borderWidth: 1,
        color: 'var(--palette-color-6)',
        fontSize: '16',
        backGroundColor: 'var(--palette-color-3)',
        textAlignment: 'center',
        justifyContent: 'center',
        borderColor: 'var(--palette-color-3)',
        borderStyle: 'solid',
        borderRadiusTopLeft: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusBottomRight: 5,
      },
      zone: APP_ZONES.FEE_MANAGEMENT,
      isRemovable: true,
    }),

    new SwitchNavigatorWidgetBuilder()
      .linksData([
        {
          textContent: 'Fee Listing',
          pageUrl: 'management-fee-list',
          zone: APP_ZONES.FEE_MANAGEMENT,
        },
        {
          textContent: 'Client Listing',
          pageUrl: 'management-client-list',
          zone: APP_ZONES.FEE_MANAGEMENT,
        },
      ])
      .navigationOption('use-as-navigation')
      .role()
      .gridPosition({ x: 1, y: 3, rows: 2, cols: 12 } as GridPosition)
      .cssConfig({
        ...switchNavigatorCss,
        backGroundColor: 'var(--palette-color-3)',
        disabledTextColor: 'var(--palette-color-1)',
        fontSize: 14,
        borderColor: 'var(--palette-color-4)',
        paddingLeft: 17,
        paddingRight: 17,
        borderRadiusTopLeft: 6,
        borderRadiusBottomLeft: 6,
        borderRadiusTopRight: 6,
        borderRadiusBottomRight: 6,
      })
      .build(),
  ];
  return commonHeaderWidget;
}

export const feeListingWidget = [
  ...commonHeaderWidget(),

  new FeeClientListingWidgetBuilder()
    .text('Listing Widget')
    .configDisplayName(`Listing Widget`)
    .gridPosition({ x: 1, y: 6, rows: 13, cols: 46 } as GridPosition)
    .cssConfig({
      color: 'var(--palette-color-1)',
      textAlignment: 'left',
      justifyContent: 'left',
      fontSize: '16',
      borderWidth: 1,
      borderColor: 'var(--palette-color-2)',
      backGroundColor: 'var(--palette-color-6)',
      borderStyle: 'solid',
      borderRadiusUnit: 'px',
    })
    .attributes({
      widgetProperties: {
        icon: 'search',
        textContent: ``,
        showEntity: false,
        memuOptions: [
          { label: 'Edit fee details', value: 'edit-fee-details' },
          { label: 'Remove fee', value: 'remove' },
        ],
        listingType: ListingTypeEnum.FEE_LISTING,
      } as BaseWidgetProperties,
      columns: feeListingColumns,
    } as unknown as WorkflowWidgetConfigurationAttributes)
    .inputSetting({
      ...inputSettings,
      css_config: {
        ...inputSettings.css_config,
        inputWidth: 120,
      },
      label: 'Search',
      isIconAllowed: true,
    })
    .isRemovable(false)
    .build(),

  generateTextWidget({
    text: 'Please go to the groups drilldown view to add a new fee structure for a group.',
    position: { x: 0, y: 19, rows: 1, cols: 32 } as GridPosition,
    displayName: 'Information Content',
    styles: {
      verticalAlignment: 'start',
      textAlignment: 'left',
      fontSize: '16',
      paddingLeft: 33,
      paddingTop: 5,
      lineHeight: '24',
      textFont: 'var(--font-family)',
      color: 'var(--typography-color-1)',
    },
    isRemovable: true,
  }),
];

export const clientListingWidget = [
  ...commonHeaderWidget(),

  new FeeClientListingWidgetBuilder()
    .text('Listing Widget')
    .configDisplayName(`Listing Widget`)
    .gridPosition({ x: 1, y: 6, rows: 13, cols: 46 } as GridPosition)
    .cssConfig({
      color: 'var(--palette-color-1)',
      textAlignment: 'left',
      justifyContent: 'left',
      fontSize: '16',
      borderWidth: 1,
      borderColor: 'var(--palette-color-2)',
      backGroundColor: 'var(--palette-color-6)',
      borderStyle: 'solid',
      borderRadiusUnit: 'px',
    })
    .attributes({
      widgetProperties: {
        icon: 'search',
        textContent: ``,
        showEntity: false,
        memuOptions: [
          { label: 'View client details', value: 'view-client-details' },
          { label: 'Remove from assigned group', value: 'remove' },
        ],
        listingType: ListingTypeEnum.CLIENT_LISTING,
      } as BaseWidgetProperties,
      columns: clientListingColumns,
    } as unknown as WorkflowWidgetConfigurationAttributes)
    .inputSetting({
      ...inputSettings,
      css_config: {
        ...inputSettings.css_config,
        inputWidth: 120,
      },
      label: 'Search',
      isIconAllowed: true,
    })
    .isRemovable(false)
    .build(),

  generateTextWidget({
    text: 'Please go to the groups drilldown view to add a new fee structure for a group.',
    position: { x: 0, y: 19, rows: 1, cols: 32 } as GridPosition,
    displayName: 'Information Content',
    styles: {
      verticalAlignment: 'start',
      textAlignment: 'left',
      fontSize: '16',
      paddingLeft: 33,
      paddingTop: 5,
      lineHeight: '24',
      textFont: 'var(--font-family)',
      color: 'var(--typography-color-1)',
    },
    isRemovable: true,
  }),
];
