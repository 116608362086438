import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonSizeOptions,
  ButtonTypes,
  WorkflowWidgetConfig,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import {
  BackButtonWidgetBuilder,
  EditAddressWidgetBuilder,
  MandatoryCTAButtonBuilder,
  SectionWidgetBuilder,
  SpacerWidgetBuilder,
  TextWidgetBuilder,
  TwoColumnWidgetBuilder,
} from '../../../../widgets';
import { createTextWidget, infoText } from './common-widgets';

const headerText = `<h6>Edit Address</h6>`;

const leftPanelInformationText = `<div>
    <div class="d-flex align-items-center">
        <div class="primaryColor d-flex">{{{ svgIcon 'circle-with-fill' '24px' '24px'}}}</div>
        <div class="ml-2 textOnSurface subtitle1">Company Information</div>
    </div>
    
    <div class="d-flex align-items-center mt-7">
        <div class="d-flex primaryColor">{{{ svgIcon 'circle-with-fill' '24px' '24px'}}}</div>
        <div class="ml-2 textOnSurface subtitle1">Incorporation Date</div>
    </div>
    <div class="d-flex align-items-center mt-7">
        <div class="d-flex primaryColor">{{{ svgIcon 'circle-without-fill' '24px' '24px'}}}</div>
        <div class="ml-2 textOnSurfaceMuted  subtitle1">Address</div>
    </div>
</div>
`;

export function mobileEditAddressInformationWidgets(): WorkflowWidgetConfig[] {
  return [
    createTextWidget(
      'Text Widget: Edit Information',
      headerText,
      {
        textAlignment: 'left',
        justifyContent: 'left',
        fontSize: '24',
        lineHeight: '32',
        textFont: 'var(--font-family)',
        isTextBold: true,
        marginTop: 35,
      },
      'h6',
    ),

    createTextWidget(
      'Text Widget:Edit Info Text',
      infoText,
      {
        justifyContent: 'left',
        textAlignment: 'left',
        fontSize: '16',
        lineHeight: '24',
        textFont: 'var(--font-family)',
        color: 'var(--color-on-surface-muted)',
      },
      'subtitle1',
    ),

    new EditAddressWidgetBuilder()
      .configDisplayName('Address Widget')
      .cssConfig({
        paddingLeft: 5,
        paddingRight: 5,
      })
      .build(),

    new SpacerWidgetBuilder().setHeight({ height: 30, unit: 'px' }).build(),

    new MandatoryCTAButtonBuilder()
      .configDisplayName('Button: Save')
      .attributes({
        widgetProperties: {
          textContent: 'Save',
          zoneToNavigate: APP_ZONES.ORGANISATION_VERIFICATION,
          urlToNavigate: 'information-confirmation',
        } as BaseWidgetProperties,
      } as WorkflowWidgetConfigurationAttributes)
      .cssConfig({
        borderRadiusTopLeft: 6,
        borderRadiusBottomLeft: 6,
        borderRadiusTopRight: 6,
        borderRadiusBottomRight: 6,
      })
      .setGlobalStylingClass(true, `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`)
      .build(),

    new SpacerWidgetBuilder().setHeight({ height: 20, unit: 'px' }).build(),

    new BackButtonWidgetBuilder()
      .defaultBackButton()
      .isRemovable(false)
      .setGlobalStylingClass(true, `${ButtonTypes.SECONDARY} ${ButtonSizeOptions.FULL_WIDTH}`)
      .build(),
    new SpacerWidgetBuilder().setHeight({ height: 20, unit: 'px' }).build(),
  ];
}

export function desktopEditAddressInformationWidgets(): WorkflowWidgetConfig[] {
  return [
    new TwoColumnWidgetBuilder()
      .attributes({
        widgetProperties: {
          textContent: '',
          selectedNoOfColumn: 2,
          selectedColumnRange: [30, 70],
          selectedSplitType: 'custom',
          columnConfigs: [
            {
              outerCssConfig: `height:100vh; background: var(--color-surface);`,
              containerName: 'Column: 1',
            },
            {
              outerCssConfig: '',
              containerName: 'Column: 2',
            },
          ],
        } as unknown as BaseWidgetProperties,
        outerCss: `height: 100vh;`,
      } as unknown as WorkflowWidgetConfigurationAttributes)
      .cssConfig({
        columnGap: 100,
      })
      .childComponents([
        [
          new SectionWidgetBuilder()
            .attributes({
              outerCss: `height:100vh; background: var(--color-surface); position: relative;     align-content: center;`,
            } as unknown as WorkflowWidgetConfigurationAttributes)
            .childComponents([
              new TextWidgetBuilder()
                .text(leftPanelInformationText)
                .configDisplayName('Edit Information')
                .cssConfig({
                  textAlignment: 'center',
                  justifyContent: 'center',
                  fontSize: '18',
                  lineHeight: '32',
                  textFont: 'var(--font-family)',
                })
                .isRemovable(false)
                .build(),
            ])
            .build(),
        ],
        [
          new SectionWidgetBuilder()
            .attributes({
              outerCss: `position: relative; top: 50px; width: 65%;`,
            } as unknown as WorkflowWidgetConfigurationAttributes)
            .childComponents([
              createTextWidget(
                'Text Widget: Edit Information',
                headerText,
                {
                  textAlignment: 'left',
                  justifyContent: 'left',
                  fontSize: '24',
                  lineHeight: '32',
                  textFont: 'var(--font-family)',
                  isTextBold: true,
                },
                'h6',
              ),

              createTextWidget(
                'Text Widget:Edit Info Text',
                infoText,
                {
                  justifyContent: 'left',
                  textAlignment: 'left',
                  fontSize: '16',
                  lineHeight: '24',
                  textFont: 'var(--font-family)',
                  color: 'var(--color-on-surface-muted)',
                },
                'subtitle1',
              ),

              new EditAddressWidgetBuilder()
                .configDisplayName('Address Widget')
                .cssConfig({
                  paddingLeft: 5,
                  paddingRight: 5,
                })
                .build(),

              new SpacerWidgetBuilder().setHeight({ height: 40, unit: 'px' }).build(),

              new TwoColumnWidgetBuilder()
                .attributes({
                  widgetProperties: {
                    textContent: '',
                    selectedNoOfColumn: 2,
                    selectedColumnRange: [50, 50],
                    selectedSplitType: 'custom',
                  } as unknown as BaseWidgetProperties,
                } as unknown as WorkflowWidgetConfigurationAttributes)
                .cssConfig({
                  columnGap: 40,
                  columnGapUnit: '%',
                })
                .childComponents([
                  [
                    new BackButtonWidgetBuilder()
                      .defaultBackButton()
                      .isRemovable(false)
                      .setGlobalStylingClass(true, `${ButtonTypes.SECONDARY} ${ButtonSizeOptions.FULL_WIDTH}`)
                      .build(),
                  ],
                  [
                    new MandatoryCTAButtonBuilder()
                      .configDisplayName('Button: Save')
                      .attributes({
                        widgetProperties: {
                          textContent: 'Save',
                          zoneToNavigate: APP_ZONES.ORGANISATION_VERIFICATION,
                          urlToNavigate: 'information-confirmation',
                        } as BaseWidgetProperties,
                      } as WorkflowWidgetConfigurationAttributes)
                      .setGlobalStylingClass(true, `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`)
                      .build(),
                  ],
                ])
                .build(),
            ])
            .build(),
        ],
      ])
      .build(),
  ];
}
