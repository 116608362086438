export const notificationDepositArrivalInfoTemplate = `<div class="w-100 h-100">
  <div class="d-flex is-center mt-4 ">
    <div>
      <div class="text-label is-center">Amount</div>
      <div class="is-center font-weight-bold typog-h6"> {{ currencyPipe notification.selectedNotification.amount currencyCode=notification.selectedNotification.currency display="symbol-narrow" }}</div>
    </div>
  </div>
  <hr class='my-1 mx-1'>
  <div class="d-flex">
    <div class="col-6-xs">
      <div class="text-label">From</div>
      <div class="font-weight-bold"> {{notification.selectedNotification.sender.name}} </div>
    </div>
  </div>
  <div class="d-flex my-4">
      <div class="col-6-xs">
        <div class="text-label">Currency</div>
        <div> {{notification.selectedNotification.currency}} </div>
      </div>
      <div class="col-6-xs">
        <div class="text-label">Account Number</div>
        <div> {{notification.selectedNotification.sender.accountNumber}} </div>
      </div>
  </div>
  <div class="d-flex my-4">
      <div class="col-6-xs">
        <div class="text-label"> Reference Number </div>
        <div>  {{notification.selectedNotification.reference}} </div>
      </div>
      <div class="col-6-xs">
        <div class="text-label"> Sort Code </div>
        <div> {{ formatMask notification.selectedNotification.sender.sortCode "##-##-##" }} </div>
      </div>
  </div>
  <div class="d-flex my-4">
      <div class="col-6-xs">
        <div class="text-label"> As on: </div>
        <div> {{datePipe notification.selectedNotification.dateOfTransaction format='d MMM y'}} </div>
      </div>
      <div class="col-6-xs">
        <div class="text-label"> At </div>
        <div>{{datePipe notification.selectedNotification.dateOfTransaction format='h:mm a'}}</div>
      </div>
  </div>
</div>`;
