import {
  APP_ZONES,
  BaseWidgetProperties,
  CardManagementZonePageTags,
  CommonPageConfig,
  Constants,
  GridPosition,
  Page,
  removeSpaceFromUrl,
  WorkflowWidgetConfig,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import { PageConfig } from '../../../zones/mobile/default-zone-page-setups';
import {
  AddToGooglePayWidgetBuilder,
  BackButtonWidgetBuilder,
  CardsWidgetBuilder,
  FormSubmissionButtonWidgetBuilder,
  ImageWidgetBuilder,
  MultiIconQuickLinkWidgetBuilder,
  QuickLinkWidgetBuilder,
  TextWidgetBuilder,
} from '../../widgets/example-builder';
import { OtpVerificationWidgetBuilder } from '../../widgets/otp-builder';
import { showPinWidgets } from '../../widgets/show-pin.widgets';
import { defaultPageConfigurations } from '../../widgets/workflow-ui-constants.v2.type';
import { commonTextWidget } from '../common/common-widgets';
import { cardTransactionElaboratedViewWidgets } from './widget-configs/card-transaction-elaborated-view-widgets';
import {
  cancelCardConfirmationWidgets,
  cancelCardSuccessWidgets,
  cardDetailsWidgets,
  setCardNameWidgets,
} from './widget-configs/cm-cards-details-page-widgets';
import { landingPageWidgets } from './widget-configs/cm-landing-page-widgets';
import { showCardLimitsPageWidgets } from './widget-configs/show-card-limits-page-widgets';

export function buildCardManagementZoneAdditionalPages(tags: string[]): {
  name: string;
  title: string;
  url?: string;
  widgets?: WorkflowWidgetConfig[];
  tags: string[];
  pageConfiguration: CommonPageConfig;
}[] {
  // Finds assoicated pages for each tag and then deduplicates the resulting array to only get unique pages
  const additionalCardMangementZonePages = buildCardManagementMobilePagesConfig();
  return [
    ...new Set(
      tags.flatMap((tag) => {
        return additionalCardMangementZonePages.filter((page) => {
          return page.tags.includes(tag);
        });
      }),
    ),
  ];
}
export const initialCardManagementPage = [
  {
    name: 'card-application-pending',
    title: 'Card Application Pending',
    tags: [],
    widgets: [
      new ImageWidgetBuilder()
        .configDisplayName('Image Widget: Source Notes Icon')
        .cssConfig({
          selectedobjectfit: 'contain',
        })
        .gridPosition({
          x: 5,
          y: 6,
          rows: 5,
          cols: 5,
        } as GridPosition)
        .attributes({
          widgetProperties: {
            textContent: '',
            imageAssetUrl: 'assets/icon-library/material/source-notes.svg',
          } as BaseWidgetProperties,
        } as WorkflowWidgetConfigurationAttributes)
        .build(),
      commonTextWidget({
        textContent: 'Your card application is currently under review.',
        cssConfig: {
          fontSize: 20,
          isTextBold: true,
        },
        gridPosition: {
          y: 11,
          rows: 3,
          cols: 15,
        } as GridPosition,
      }),
      commonTextWidget({
        textContent: 'We are processing your application. This may take 1-2 business days',
        cssConfig: {
          fontSize: 16,
          color: 'var(--typography-color-1)',
        },
        gridPosition: {
          y: 14,
          rows: 2,
          cols: 15,
        } as GridPosition,
      }),
      commonTextWidget({
        textContent: 'Need Assistance?',
        cssConfig: {
          fontSize: 16,
          isTextBold: true,
        },
        gridPosition: {
          y: 23,
          rows: 1,
          cols: 15,
        } as GridPosition,
      }),
      commonTextWidget({
        textContent: 'Please contact our support team.',
        cssConfig: {
          fontSize: 16,
          color: 'var(--typography-color-1)',
        },
        gridPosition: {
          y: 24,
          rows: 1,
          cols: 15,
        } as GridPosition,
      }),
      new BackButtonWidgetBuilder()
        .defaultBackButton()
        .cssConfig({
          backGroundColor: 'var(--palette-color-5)',
          borderWidth: 1,
          borderColor: 'var(--typography-color-0)',
          borderRadiusTopLeft: 3,
          borderRadiusBottomLeft: 3,
          borderRadiusTopRight: 3,
          borderRadiusBottomRight: 3,
        })
        .gridPosition({ y: 26, rows: 2, cols: 15 } as GridPosition)
        .isRemovable(true)
        .build(),
    ],
    pageConfiguration: {
      url: 'card-application-pending',
      pageHooks: [
        {
          name: 'CardManagementKycCheck',
          enabled: true,
        },
      ],
    } as CommonPageConfig,
  },
];

export function buildCardManagementMobilePagesConfig(): {
  name: string;
  title: string;
  url?: string;
  widgets?: WorkflowWidgetConfig[];
  tags: string[];
  pageConfiguration: CommonPageConfig;
}[] {
  const cardManagementPages: PageConfig[] = [
    {
      name: 'landing-page',
      title: 'Landing Page',
      tags: [CardManagementZonePageTags.PREPAID_VIRTUAL_CARD_TOP_UP],
      widgets: landingPageWidgets,
      pageConfiguration: {
        ...defaultPageConfigurations,
        isRemovable: false,
      } as CommonPageConfig,
    },
    {
      name: 'add-card-select-type',
      title: 'Add Card - Select Type',
      tags: [CardManagementZonePageTags.PREPAID_VIRTUAL_CARD_TOP_UP],
      widgets: [
        new TextWidgetBuilder()
          .configDisplayName('Text Widget: Add Card')
          .text('<b>Add Card - Select Type</b>')
          .cssConfig({
            color: 'var(--typography-color-0)',
            fontSize: 24,
            textAlignment: 'flex-start',
          })
          .gridPosition({
            x: 0,
            y: 0,
            rows: 2,
            cols: 15,
          } as GridPosition)
          .build(),
        new MultiIconQuickLinkWidgetBuilder()
          .icons({
            left: 'mat_phonelink_ring',
            right: 'arrow-right',
          })
          .template('Virtual Prepaid Card')
          .zone(APP_ZONES.CARD_MANAGEMENT_ZONE)
          .role()
          .navigateToPageUrl('validation-add-card')
          .configDisplayName('Multi Icon QL: Virtual Prepaid Card')
          .navigationOption('use-as-navigation')
          .isRemovable(false)
          .cssConfig({
            ...Constants.defaultBaseCssConfig,
            fontSize: 16,
            textFont: 'var(--font-family)',
            textAlignment: 'space-between',
            paddingTop: 10,
            paddingBottom: 10,
            rightIconSize: 24,
            leftIconSize: 32,
            rightIconColor: 'var(--typography-color-1)',
          })
          .gridPosition({ x: 0, y: 3, rows: 3, cols: 15 } as GridPosition)
          .build(),
        new TextWidgetBuilder()
          .configDisplayName('Text Widget: Add Card')
          .text(
            'A digital card for online transactions, offering convenience and security. If you already have a virtual card, you cannot add a new one.',
          )
          .cssConfig({
            color: 'var(--typography-color-1)',
            fontSize: 14,
            textAlignment: 'flex-start',
          })
          .gridPosition({
            x: 0,
            y: 6,
            rows: 3,
            cols: 15,
          } as GridPosition)
          .build(),
        new MultiIconQuickLinkWidgetBuilder()
          .icons({
            left: 'credit-card-2',
            right: 'arrow-right',
          })
          .template('Physical Card')
          .zone(APP_ZONES.CARD_MANAGEMENT_ZONE)
          .role()
          .navigateToPageUrl('')
          .configDisplayName('Multi Icon QL: Physical Card')
          .navigationOption('use-as-navigation')
          .isRemovable(false)
          .cssConfig({
            ...Constants.defaultBaseCssConfig,
            fontSize: 16,
            textFont: 'var(--font-family)',
            textAlignment: 'space-between',
            paddingTop: 10,
            paddingBottom: 10,
            rightIconSize: 24,
            leftIconSize: 32,
            rightIconColor: 'var(--typography-color-1)',
            leftIconColor: 'var(--typography-color-1)',
          })
          .gridPosition({ x: 0, y: 11, rows: 3, cols: 15 } as GridPosition)
          .build(),
        new TextWidgetBuilder()
          .configDisplayName('Text Widget: Physical Card')
          .text(
            'A physical debit card is a tangible card linked to your account or pre-loaded with money, allowing you to make purchases and withdraw cash from ATMs.',
          )
          .cssConfig({
            color: 'var(--typography-color-1)',
            fontSize: 14,
            textAlignment: 'flex-start',
          })
          .gridPosition({
            x: 0,
            y: 14,
            rows: 3,
            cols: 15,
          } as GridPosition)
          .build(),
        new BackButtonWidgetBuilder()
          .defaultBackButton()
          .cssConfig({
            color: 'var(--typography-color-0)',
            borderColor: 'var(--typography-color-0)',
            backGroundColor: 'var(--typography-color-7)',
            borderRadiusUnit: 'px',
            borderRadiusTopLeft: 3,
            borderRadiusBottomLeft: 3,
            borderRadiusTopRight: 3,
            borderRadiusBottomRight: 3,
            borderWidth: 1,
          })
          .gridPosition({ y: 26, rows: 2, cols: 15 } as GridPosition)
          .isRemovable(false)
          .build(),
      ],
    },
    {
      name: 'add-card-validation',
      title: 'Add Card Validation',
      tags: [CardManagementZonePageTags.PREPAID_VIRTUAL_CARD_TOP_UP],
      widgets: [
        new TextWidgetBuilder()
          .configDisplayName('Text Widget: Confirmation')
          .text('<b>Confirmation - Virtual Card</b>')
          .cssConfig({
            color: 'var(--typography-color-0)',
            fontSize: 24,
            textAlignment: 'flex-start',
          })
          .gridPosition({
            x: 0,
            y: 0,
            rows: 2,
            cols: 15,
          } as GridPosition)
          .build(),
        new TextWidgetBuilder()
          .configDisplayName('Text Widget: Confirm Card')
          .text(
            'Please confirm your action to add a virtual card. By confirming, the card will be added and ready for use.',
          )
          .cssConfig({
            color: 'var(--typography-color-1)',
            fontSize: 16,
            textAlignment: 'flex-start',
          })
          .gridPosition({
            x: 0,
            y: 2,
            rows: 3,
            cols: 15,
          } as GridPosition)
          .build(),
        new TextWidgetBuilder()
          .configDisplayName('Text Widget: Card Details')
          .text(
            `<div class="w-100">
<div class="d-flex is-space-between mb-5">
 <div style="color: var(--typography-color-1)">Card Type</div>
 <div>Virtual Card</div>
 </div>
<div class="d-flex is-space-between mb-5">
 <div style="color: var(--typography-color-1)">Cardholder Name</div>
 <div>{{profile.fullName}}</div>
 </div>
<div class="d-flex is-space-between">
 <div style="color: var(--typography-color-1)">Checkout Fee</div>
 <div>£ 0.00</div>
 </div>
</div>`,
          )
          .cssConfig({
            color: 'var(--typography-color-0)',
            fontSize: 16,
            textAlignment: 'flex-start',
          })
          .gridPosition({
            x: 0,
            y: 6,
            rows: 5,
            cols: 15,
          } as GridPosition)
          .build(),
        new TextWidgetBuilder()
          .configDisplayName('Text Widget: Protection text')
          .text(
            `<div class="d-flex mx-3">
<div class="w-100 is-center mr-2">{{{'imageFromImageName' 'icon-library/material' 'mat_encrypted' '44px'}}}</div>
<div>For your protection, your virtual card details are securely encrypted and stored. Remember, never share your card details, including the card number and expiration date, with anyone. If you suspect any unauthorized activity, contact us immediately.</div>
</div>`,
          )
          .cssConfig({
            color: 'var(--typography-color-0)',
            fontSize: 14,
            textAlignment: 'flex-start',
            backGroundColor: 'var(--palette-color-4)',
            borderRadiusTopLeft: 3,
            borderRadiusBottomLeft: 3,
            borderRadiusTopRight: 3,
            borderRadiusBottomRight: 3,
          })
          .gridPosition({
            x: 0,
            y: 14,
            rows: 8,
            cols: 15,
          } as GridPosition)
          .build(),
        new FormSubmissionButtonWidgetBuilder()
          .configDisplayName('CTA Button: Add Card')
          .attributes({
            widgetProperties: {
              textContent: 'Add Card',
              //TODO adding buttonActionType on end user story
              zoneToNavigate: APP_ZONES.CARD_MANAGEMENT_ZONE,
              urlToNavigate: 'validation-add-card-success',
            } as BaseWidgetProperties,
          } as WorkflowWidgetConfigurationAttributes)
          .cssConfig({
            color: 'var(--palette-color-6)',
            backGroundColor: 'var(--typography-color-0)',
            borderRadiusTopLeft: 3,
            borderRadiusBottomLeft: 3,
            borderRadiusTopRight: 3,
            borderRadiusBottomRight: 3,
            fontSize: 14,
          })
          .gridPosition({ y: 23, rows: 2, cols: 15 } as GridPosition)
          .build(),
        new BackButtonWidgetBuilder()
          .defaultBackButton()
          .cssConfig({
            color: 'var(--typography-color-0)',
            borderColor: 'var(--typography-color-0)',
            backGroundColor: 'var(--palette-color-6)',
            borderRadiusUnit: 'px',
            borderRadiusTopLeft: 3,
            borderRadiusBottomLeft: 3,
            borderRadiusTopRight: 3,
            borderRadiusBottomRight: 3,
            borderWidth: 1,
            fontSize: 14,
          })
          .gridPosition({ y: 26, rows: 2, cols: 15 } as GridPosition)
          .isRemovable(false)
          .build(),
      ],
    },
    {
      name: 'validation-add-card-success',
      title: 'Validation - Add Card (Success)',
      tags: [CardManagementZonePageTags.PREPAID_VIRTUAL_CARD_TOP_UP],
      widgets: [
        new ImageWidgetBuilder()
          .configDisplayName('Image Widget: Double Tick')
          .cssConfig({
            selectedobjectfit: 'contain',
          })
          .attributes({
            widgetProperties: {
              textContent: '',
              imageAssetUrl: 'assets/icon-library/material/mat_done_all.svg',
            } as BaseWidgetProperties,
          } as WorkflowWidgetConfigurationAttributes)
          .gridPosition({
            rows: 3,
          } as GridPosition)
          .build(),
        new TextWidgetBuilder()
          .configDisplayName('Text Widget: Card Added')
          .text('<b>You have added a new virtual card!</b>')
          .cssConfig({
            color: 'var(--typography-color-0)',
            fontSize: 20,
            textAlignment: 'center',
          })
          .gridPosition({
            x: 2,
            y: 3,
            rows: 2,
            cols: 11,
          } as GridPosition)
          .build(),
        new TextWidgetBuilder()
          .configDisplayName('Text Widget: You can now start')
          .text('You can now start using your virtual card for online purchases, subscriptions, and more.')
          .cssConfig({
            color: 'var(--typography-color-1)',
            fontSize: 16,
            textAlignment: 'center',
          })
          .gridPosition({
            x: 0,
            y: 6,
            rows: 2,
            cols: 15,
          } as GridPosition)
          .build(),
        new TextWidgetBuilder()
          .configDisplayName('Text Widget: Card Details')
          .text(
            `<div class="d-flex w-100 mx-3 is-space-between">
<div class="is-center mr-2">{{{'imageFromImageName' 'icon-library/material' 'add-card' '32px'}}}</div>
<div><div>{{cards.currentCardDetail.maskedPan}}</div><div class="is-left typog-sm">{{'capitaliseFirstLetter' cards.currentCardDetail.type}} Card</div></div><div class="is-center py-2 px-3 typog-sm" style="color: var(--alerts-color-1); background: #D1FFF0">Card Added!</div>
</div>`,
          )
          .cssConfig({
            color: 'var(--typography-color-0)',
            fontSize: 16,
            textAlignment: 'center',
            borderWidth: 1,
            borderUnit: 'px',
            borderRadiusTopLeft: 3,
            borderRadiusBottomLeft: 3,
            borderRadiusTopRight: 3,
            borderRadiusBottomRight: 3,
            borderColor: 'var(--border-color-1)',
            marginTop: 10,
            marginUnit: 'px',
          })
          .gridPosition({
            x: 0,
            y: 8,
            rows: 3,
            cols: 15,
          } as GridPosition)
          .build(),
        new CardsWidgetBuilder()
          .cssConfig({
            borderWidth: 0,
            backGroundColor: 'var(--border-color-1)',
          })
          .gridPosition({ y: 12 } as GridPosition)
          .build(),
        new AddToGooglePayWidgetBuilder()
          .cssConfig({
            marginUnit: 'px',
            marginTop: 0,
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 0,
            borderRadius: 6,
            width: 342,
            height: 44,
          })
          .gridPosition({
            x: 0,
            y: 20,
            rows: 2,
            cols: 15,
          } as GridPosition)
          .build(),

        new TextWidgetBuilder()
          .configDisplayName('Text Widget: Warning')
          .text(
            `<div class="d-flex mx-3">
              <div class="d-flex align-items-center mr-2" style="width: 25%">{{{'imageFromImageName' 'icon-library/material' 'mat_visibility_off' '32px'}}}</div>
              <div>Remember, never share your card details with anyone. You can freeze or delete your card from your account at any time.</div>
            </div>`,
          )
          .cssConfig({
            color: 'var(--typography-color-0)',
            fontSize: 14,
            textAlignment: 'flex-start',
            backGroundColor: 'var(--palette-color-4)',
            borderRadiusTopLeft: 3,
            borderRadiusBottomLeft: 3,
            borderRadiusTopRight: 3,
            borderRadiusBottomRight: 3,
            marginTop: 10,
            marginBottom: 10,
            marginUnit: 'px',
          })
          .gridPosition({
            x: 0,
            y: 22,
            rows: 4,
            cols: 15,
          } as GridPosition)
          .build(),
        new QuickLinkWidgetBuilder()
          .zone(APP_ZONES.CARD_MANAGEMENT_ZONE)
          .navigateToPageUrl('landing-page')
          .navigationOption('use-as-navigation')
          .role()
          .text('View Card')
          .gridPosition({
            x: 0,
            y: 26,
            rows: 2,
            cols: 15,
          } as GridPosition)
          .cssConfig({
            color: 'var(--palette-color-6)',
            backGroundColor: 'var(--typography-color-0)',
            borderRadiusTopLeft: 3,
            borderRadiusBottomLeft: 3,
            borderRadiusTopRight: 3,
            borderRadiusBottomRight: 3,
            fontSize: 14,
          })
          .build(),
        new QuickLinkWidgetBuilder()
          .zone(APP_ZONES.CARD_MANAGEMENT_ZONE)
          .navigateToPageUrl('landing-page')
          .navigationOption('use-as-navigation')
          .role()
          .text('Home')
          .gridPosition({
            x: 0,
            y: 29,
            rows: 2,
            cols: 15,
          } as GridPosition)
          .cssConfig({
            color: 'var(--typography-color-0)',
            backGroundColor: 'var(--palette-color-6)',
            borderWidth: 1,
            borderUnit: 'px',
            borderColor: 'var(--typography-color-0)',
            borderRadiusTopLeft: 3,
            borderRadiusBottomLeft: 3,
            borderRadiusTopRight: 3,
            borderRadiusBottomRight: 3,
            fontSize: 14,
          })
          .build(),
      ],
    },
    {
      name: 'top-up-input-amount',
      title: 'Top Up - Input Amount',
      tags: [CardManagementZonePageTags.PREPAID_VIRTUAL_CARD_TOP_UP],
      widgets: [],
    },
    {
      name: 'fund-card-confirmation',
      title: 'Fund Card - Confirmation',
      tags: [CardManagementZonePageTags.PREPAID_VIRTUAL_CARD_TOP_UP],
      widgets: [],
    },
    {
      name: 'fund-card-validation-success',
      title: 'Fund Card - Validation (Success)',
      tags: [CardManagementZonePageTags.PREPAID_VIRTUAL_CARD_TOP_UP],
      widgets: [],
    },
    {
      name: 'fund-card-validation-failed',
      title: 'Fund Card - Validation (Failed)',
      tags: [CardManagementZonePageTags.PREPAID_VIRTUAL_CARD_TOP_UP],
      widgets: [],
    },
    {
      name: 'card-transactions',
      title: 'Card - Transactions',
      tags: [CardManagementZonePageTags.PREPAID_VIRTUAL_CARD_TOP_UP],
      widgets: cardTransactionElaboratedViewWidgets,
      pageConfiguration: {
        ...defaultPageConfigurations,
        backgroundColor: 'var(--color-background)',
        isUsingHtmlStructure: true,
      } as CommonPageConfig,
    },
    {
      name: 'card-details',
      title: 'Card - Details',
      tags: [CardManagementZonePageTags.PREPAID_VIRTUAL_CARD_TOP_UP],
      widgets: cardDetailsWidgets,
    },
    {
      name: 'authentication-sms',
      title: 'Authentication - SMS',
      tags: [CardManagementZonePageTags.PREPAID_VIRTUAL_CARD_TOP_UP],
      widgets: [
        new TextWidgetBuilder()
          .text('<b>Enter OTP</b>')
          .cssConfig({
            color: 'var(--typography-color-0)',
            fontSize: 24,
            textAlignment: 'flex-start',
          })
          .gridPosition({
            x: 1,
            y: 2,
            rows: 2,
            cols: 15,
          } as GridPosition)
          .build(),
        new TextWidgetBuilder()
          .text(
            `<div class="w-100"><div>We have sent you a 6 digit code at: </div> <div style="color: black"><b>{{profile.phoneNumber}}</b></div></div>`,
          )
          .cssConfig({
            color: 'var(--typography-color-1)',
            fontSize: 16,
            textAlignment: 'flex-start',
          })
          .gridPosition({
            x: 1,
            y: 6,
            rows: 2,
            cols: 15,
          } as GridPosition)
          .build(),
        new OtpVerificationWidgetBuilder()
          .setWidgetContext('phone', 'cards')
          .gridPosition({
            x: 1,
            y: 9,
            rows: 9,
            cols: 15,
          } as GridPosition)
          .build(),
      ],
    },
    {
      name: 'replace-remove-card',
      title: 'Replace/Remove Card',
      tags: [CardManagementZonePageTags.PREPAID_VIRTUAL_CARD_TOP_UP],
      widgets: [],
    },
    {
      name: 'remove-confirmation',
      title: 'Remove - Confirmation',
      tags: [CardManagementZonePageTags.PREPAID_VIRTUAL_CARD_TOP_UP],
      widgets: [],
    },
    {
      name: 'replace-confirmation',
      title: 'Replace - Confirmation',
      tags: [CardManagementZonePageTags.PREPAID_VIRTUAL_CARD_TOP_UP],
      widgets: [],
    },
    {
      name: 'remove-validation-success',
      title: 'Remove - Validation (Success)',
      tags: [CardManagementZonePageTags.PREPAID_VIRTUAL_CARD_TOP_UP],
      widgets: [],
    },
    {
      name: 'replace-validation-success',
      title: 'Replace - Validation (Success)',
      tags: [CardManagementZonePageTags.PREPAID_VIRTUAL_CARD_TOP_UP],
      widgets: [],
    },
    {
      name: 'cancel-card-confirmation',
      title: 'Cancel Card',
      tags: [CardManagementZonePageTags.PREPAID_VIRTUAL_CARD_TOP_UP],
      widgets: cancelCardConfirmationWidgets,
    },
    {
      name: 'cancel-card-success',
      title: 'Card Cancelled',
      tags: [CardManagementZonePageTags.PREPAID_VIRTUAL_CARD_TOP_UP],
      widgets: cancelCardSuccessWidgets,
    },
    {
      name: 'set-card-name',
      title: 'Set Card Name',
      tags: [CardManagementZonePageTags.PREPAID_VIRTUAL_CARD_TOP_UP],
      widgets: setCardNameWidgets,
    },
    {
      name: Page.SHOW_PIN,
      title: 'Show PIN',
      tags: [CardManagementZonePageTags.PREPAID_VIRTUAL_CARD_TOP_UP],
      widgets: showPinWidgets,
    },
    {
      name: Page.SHOW_CARD_LIMITS,
      title: 'Show Card Limits',
      tags: [CardManagementZonePageTags.PREPAID_VIRTUAL_CARD_TOP_UP],
      widgets: showCardLimitsPageWidgets,
      pageConfiguration: {
        ...defaultPageConfigurations,
        backgroundColor: 'var(--color-background)',
        isUsingHtmlStructure: true,
      } as CommonPageConfig,
    },
  ];
  const pagesArray: {
    name: string;
    title: string;
    url?: string;
    widgets?: WorkflowWidgetConfig[];
    tags: string[];
    pageConfiguration: CommonPageConfig;
  }[] = [];
  cardManagementPages.forEach((page) => {
    pagesArray.push({
      name: removeSpaceFromUrl(page.title),
      title: page.title,
      tags: [...page.tags],
      url: removeSpaceFromUrl(page.title),
      widgets: page.widgets,
      pageConfiguration: page?.pageConfiguration ?? ({} as CommonPageConfig),
    });
  });
  return pagesArray;
}
