export const selectedAccountDetailsForDownloadStatementTemplate = `

<div class="is-vertical-align space-around w-100 h-100 m-3">
    <div class="is-space-between">
      <div class="typog-lg font-weight-bold">
        {{ currency }}
      </div>
      <div class="is-center">
        <div class="typog-sm mr-1">
          {{ country }}
        </div>
        <div class="flag-wrap d-flex">{{{ currencyToFlag currency '30px' }}}</div>
      </div>
    </div>
    <div class="w-100 dashed-border"></div>
    <div>
      <div class="is-left">
        {{#if (neq accountNumber '')}}
        <div>
          <p class="typog-xs typo-color-1">Acc no.</p>
          <p class="typog-sm typo-color-1">
            {{ accountNumber }}
          </p>
        </div>
        {{/if}}
        {{#if (neq sortCode '')}}
        <div class="ml-8">
          <p class="typog-xs typo-color-1">Sort Code</p>
          <p class="typog-sm typo-color-1">{{ sortCode }}</p>
        </div>
        {{/if}}
      </div>
      {{#if (neq iban '')}}
        <div>
          <p class="typog-xs typo-color-1">IBAN Number</p>
          <p class="typog-sm typo-color-1">{{ iban }}</p>
        </div>
      {{/if}}
      {{#if (neq bic '')}}
        <div>
          <p class="typog-xs typo-color-1">Swift BIC</p>
          <p class="typog-sm typo-color-1">{{ bic }}</p>
        </div>
      {{/if}}
      {{#if (neq abaNumber '')}}
        {{#if (eq iban '')}}
          <div>
            <p class="typog-xs typo-color-1">ABA</p>
            <p class="typog-sm typo-color-1">{{ abaNumber }}</p>
          </div>
        {{/if}}
      {{/if}}
      {{#if (neq achNumber '')}}
        {{#if (eq iban '')}}
          <div>
            <p class="typog-xs typo-color-1">ACH</p>
            <p class="typog-sm typo-color-1">{{ achNumber }}</p>
          </div>
        {{/if}}
      {{/if}}
      {{#if (neq bsbCode '')}}
        <div>
          <p class="typog-xs typo-color-1">BSB Code</p>
          <p class="typog-sm typo-color-1">{{ bsbCode }}</p>
        </div>
      {{/if}}
      {{#if (neq ifsc '')}}
        <div>
          <p class="typog-xs typo-color-1">IFSC</p>
          <p class="typog-sm typo-color-1">{{ ifsc }}</p>
        </div>
      {{/if}}
      {{#if (neq rtnCanada '')}}
        <div>
          <p class="typog-xs typo-color-1">RTN Canada</p>
          <p class="typog-sm typo-color-1">{{ rtnCanada }}</p>
        </div>
      {{/if}}
      {{#if (neq clabe '')}}
        <div>
          <p class="typog-xs typo-color-1">Clabe</p>
          <p class="typog-sm typo-color-1">{{ clabe }}</p>
        </div>
      {{/if}}
      {{#if (neq bankCode '')}}
        <div>
          <p class="typog-xs typo-color-1">Bank Code</p>
          <p class="typog-sm typo-color-1">{{ bankCode }}</p>
        </div>
      {{/if}}
      {{#if (neq branchCode '')}}
        <div>
          <p class="typog-xs typo-color-1">Branch Code</p>
          <p class="typog-sm typo-color-1">{{ branchCode }}</p>
        </div>
      {{/if}}
    </div>
  </div>
`;

export const selectAccountFileTypeBottomDescriptionTemplate = `
  Should you have any questions or require further assistance, please do not hesitate to contact us at: example@email.com
`;
