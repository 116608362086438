import {
  APP_ZONES,
  BaseWidgetProperties,
  GridPosition,
  ListingTypeEnum,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import {
  BackButtonWidgetBuilder,
  FeeClientListingWidgetBuilder,
  SwitchNavigatorWidgetBuilder,
} from '../../../defaults/widgets/example-builder';
import { inputSettings } from '../../../defaults/widgets/payments/payments.type';
import { switchNavigatorCss } from '../../../defaults/widgets/workflow-ui-constants.v2.type';
import {
  generateTextWidget,
  getQuickLinkWidget,
} from '../../../defaults/zones/payments/common-widgets-utils';
import { clientListingColumns, feeListingColumns } from './fee-client-listing-page.utils';
function getCommonHeaderWidget() {
  const commonHeaderWidget = [
    generateTextWidget({
      text: '{{feeManagement.selectedFeeGroupData.name}}',
      position: { x: 1, y: 0, rows: 1, cols: 25 } as GridPosition,
      displayName: 'Header Text',
      styles: {
        fontSize: 24,
        isTextBold: true,
        verticalAlignment: 'start',
        textAlignment: 'left',
        noAlignment: true,
      },
      isRemovable: true,
    }),

    generateTextWidget({
      text: '{{feeManagement.selectedFeeGroupData.description}} ',
      position: { x: 1, y: 1, rows: 2, cols: 24 } as GridPosition,
      displayName: 'Information Content',
      styles: {
        verticalAlignment: 'start',
        textAlignment: 'left',
        fontSize: '16',
        paddingTop: 10,
        lineHeight: '24',
        textFont: 'var(--font-family)',
        color: 'var(--typography-color-1)',
      },
      isRemovable: true,
    }),

    new BackButtonWidgetBuilder()
      .defaultBackButton()
      .configDisplayName('Back Navigation Button')
      .text('Back')
      .cssConfig({
        color: 'var(--palette-color-3)',
        backGroundColor: 'var(--palette-color-6)',
        borderWidth: 1,
        borderColor: 'var(--palette-color-3)',
        fontSize: 14,
        paddingLeft: 20,
        paddingRight: 20,

        textAlign: 'center',
        verticalAlignment: 'center',
      })
      .gridPosition({
        y: 0,
        x: 30,
        rows: 1,
        cols: 5,
        resizeEnabled: true,
        dragEnabled: true,
      })
      .build(),

    getQuickLinkWidget({
      text: 'Remove Group',
      position: {
        x: 36,
        y: 0,
        rows: 1,
        cols: 5,
      } as GridPosition,
      pageUrl: 'confirmation-remove-group',
      displayName: 'Quick Link: Remove Group',
      styles: {
        color: 'var(--alerts-color-0)',
        backGroundColor: 'var(--palette-color-6)',
        borderWidth: 1,
        borderColor: 'var(--alerts-color-0)',
        fontSize: '16',
        textAlignment: 'center',
        justifyContent: 'center',
        borderStyle: 'solid',
        borderRadiusTopLeft: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusBottomRight: 5,
      },
      zone: APP_ZONES.FEE_MANAGEMENT,
      isRemovable: true,
    }),

    getQuickLinkWidget({
      text: 'Add/Edit Fee',
      position: {
        x: 42,
        y: 0,
        rows: 1,
        cols: 5,
      } as GridPosition,
      pageUrl: 'group-fee-charge-add-edit-fee',
      displayName: 'Quick Link: Add/Edit Fee',
      styles: {
        borderWidth: 1,
        color: 'var(--palette-color-6)',
        fontSize: '16',
        backGroundColor: 'var(--palette-color-3)',
        textAlignment: 'center',
        justifyContent: 'center',
        borderColor: 'var(--palette-color-3)',
        borderStyle: 'solid',
        borderRadiusTopLeft: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusBottomRight: 5,
      },
      zone: APP_ZONES.FEE_MANAGEMENT,
      isRemovable: true,
    }),

    new SwitchNavigatorWidgetBuilder()
      .linksData([
        {
          textContent: 'Fee Listing',
          pageUrl: 'group-information-fee-list',
          zone: APP_ZONES.FEE_MANAGEMENT,
        },
        {
          textContent: 'Client Listing',
          pageUrl: 'group-information-client-list',
          zone: APP_ZONES.FEE_MANAGEMENT,
        },
      ])
      .navigationOption('use-as-navigation')
      .role()
      .gridPosition({ x: 1, y: 4, rows: 2, cols: 12 } as GridPosition)
      .cssConfig({
        ...switchNavigatorCss,
        backGroundColor: 'var(--palette-color-3)',
        disabledTextColor: 'var(--palette-color-1)',
        fontSize: 14,
        borderColor: 'var(--palette-color-4)',
        paddingLeft: 17,
        paddingRight: 17,
        borderRadiusTopLeft: 6,
        borderRadiusBottomLeft: 6,
        borderRadiusTopRight: 6,
        borderRadiusBottomRight: 6,
      })
      .build(),
  ];
  return commonHeaderWidget;
}

export const groupDrilldownViewFeeListingWidget = [
  ...getCommonHeaderWidget(),

  new FeeClientListingWidgetBuilder()
    .text('Listing Widget')
    .configDisplayName(`Listing Widget`)
    .gridPosition({ x: 1, y: 6, rows: 13, cols: 46 } as GridPosition)
    .cssConfig({
      color: 'var(--palette-color-1)',
      textAlignment: 'left',
      justifyContent: 'left',
      fontSize: '16',
      borderWidth: 1,
      borderColor: 'var(--palette-color-2)',
      backGroundColor: 'var(--palette-color-6)',
      borderStyle: 'solid',
      borderRadiusUnit: 'px',
    })
    .attributes({
      widgetProperties: {
        icon: 'search',
        textContent: ``,
        showEntity: false,
        memuOptions: [
          { label: 'Edit fee details', value: 'edit-fee-details' },
          { label: 'Remove fee', value: 'remove' },
        ],
        listingType: ListingTypeEnum.GROUP_FEE_LISTING,
      } as BaseWidgetProperties,
      columns: feeListingColumns,
    } as unknown as WorkflowWidgetConfigurationAttributes)
    .inputSetting({
      ...inputSettings,
      css_config: {
        ...inputSettings.css_config,
        inputWidth: 120,
      },
      label: 'Search',
      isIconAllowed: true,
    })
    .isRemovable(false)
    .build(),
];

export const groupDrilldownViewClientListingWidget = [
  ...getCommonHeaderWidget(),

  new FeeClientListingWidgetBuilder()
    .text('Listing Widget')
    .configDisplayName(`Listing Widget`)
    .gridPosition({ x: 1, y: 6, rows: 13, cols: 46 } as GridPosition)
    .cssConfig({
      color: 'var(--palette-color-1)',
      textAlignment: 'left',
      justifyContent: 'left',
      fontSize: '16',
      borderWidth: 1,
      borderColor: 'var(--palette-color-2)',
      backGroundColor: 'var(--palette-color-6)',
      borderStyle: 'solid',
      borderRadiusUnit: 'px',
    })
    .attributes({
      widgetProperties: {
        icon: 'search',
        textContent: ``,
        showEntity: false,
        memuOptions: [
          { label: 'View client details', value: 'view-client-details' },
          { label: 'Remove from assigned group', value: 'remove' },
        ],
        listingType: ListingTypeEnum.GROUP_CLIENT_LISTING,
      } as BaseWidgetProperties,
      columns: clientListingColumns,
    } as unknown as WorkflowWidgetConfigurationAttributes)
    .inputSetting({
      ...inputSettings,
      css_config: {
        ...inputSettings.css_config,
        inputWidth: 120,
      },
      label: 'Search',
      isIconAllowed: true,
    })
    .isRemovable(false)
    .build(),
];
