export const manageUserPersonalInformationTemplate = `
<div class="w-100 h-100">
  <div class="content d-flex f-wrap mt-4">

    <div class="col-5 mb-5">
      <div class="text-label text-uppercase typog-xs font-weight-bold"> first name </div>
      <div class="text-value typog-md font-weight-normal"> {{manageUserView.firstName}} </div>
    </div>

    <div class="col-5 ml-8 mb-5">
      <div class="text-label text-uppercase typog-xs font-weight-bold "> middle name </div>
      <div class="text-value typog-md font-weight-normal"> {{manageUserView.middleName}} </div>
    </div>

    <div class="col-10 mb-5">
      <div class="text-label text-uppercase typog-xs font-weight-bold"> last name </div>
      <div class="text-value typog-md font-weight-normal"> {{manageUserView.lastName}} </div>
    </div>

    <div class="col-6 mb-5">
      <div class="text-label text-uppercase typog-xs font-weight-bold">phone number</div>
      <div class="text-value typog-md font-weight-normal"> {{manageUserView.phoneNumber}} </div>
    </div>

  </div>
</div>
`;

export const manageUserLoginDetails = `
<div class="w-100 h-100">
  <div class="content d-flex f-wrap mt-4">

    <div class="col-5">
      <div class="text-label text-uppercase typog-xs font-weight-bold">
        Username
      </div>
      <div class="text-value word-break-all typog-md font-weight-normal">
      {{manageUserView.userName}} 
      </div>
    </div>

    <div class="col-5 ml-8">
      <div class="text-label text-uppercase typog-xs font-weight-bold ">
        Password
      </div>
      <div class="text-value typog-md font-weight-normal">
        *****
      </div>
    </div>

  </div>
</div>
`;

export const manageUsersAddressTemplate = `
<div class="w-100 h-100">
  <div class="content d-flex f-wrap mt-4">
    <div class="col-5 mb-5">
    <div class="text-label text-uppercase typog-xs font-weight-bold"> STREET </div>
    <div class="text-value typog-md font-weight-normal" > {{manageUserView.address1}} {{manageUserView.address2}}</div>
    </div>

    <div class="col-5 ml-8 mb-5">
    <div class="text-label text-uppercase typog-xs font-weight-bold"> Country </div>
    <div class="text-value typog-md font-weight-normal" > {{manageUserView.country}} </div>
    </div>

    <div class="col-5 mb-5">
    <div class="text-label text-uppercase typog-xs font-weight-bold"> CITY </div>
    <div class="text-value typog-md font-weight-normal" > {{manageUserView.city}} </div>
    </div>

    <div class="col-5 ml-8 mb-5">
    <div class="text-label text-uppercase typog-xs font-weight-bold"> ZIP/Postal code </div>
    <div class="text-value typog-md font-weight-normal"> {{manageUserView.postalCode}} </div>
    </div>

    <div class="col-5 mb-5">
    <div class="text-label text-uppercase typog-xs font-weight-bold"> state/region </div>
    <div class="text-value typog-md font-weight-normal" > {{manageUserView.county}} </div>
    </div>

  </div>
</div>
`;
