import { Injector } from '@angular/core';
import { ButtonActionType, UiZoneWidgetAttributeConfig } from '@finxone-platform/shared/sys-config-types';
import { functionCtaType } from '@app/finxone-web-frontend/app/lib/utils/cta-button-actions/cta-button-actions.utils';
import {
  acceptQuote,
  reQuote,
} from '@app/finxone-web-frontend/app/lib/utils/cta-button-actions/exchange-currency/exchange-currency.utils';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { Router } from '@angular/router';

export const exchangeActions: Map<ButtonActionType, functionCtaType> = new Map([
  [
    ButtonActionType.EXCHANGE_RE_QUOTE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      reQuote(injector, attri.widgetProperties);
    },
  ],
  [
    ButtonActionType.EXCHANGE_ACCEPT_QUOTE,
    (injector: Injector) => {
      acceptQuote(injector, injector.get(AlertHandlerService), injector.get(Router));
    },
  ],
]);
