/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  APP_ZONES,
  BaseCssConfig,
  BaseWidgetProperties,
  ButtonActionType,
  GridPosition,
  WorkflowWidgetConfig,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import {
  FormSubmissionButtonWidgetBuilder,
  QuickLinkWidgetBuilder,
  TextWidgetBuilder,
} from '../../../defaults/widgets/example-builder';

export const successPageWidgetsOverride = {
  quickLinkWithIcon: {
    icon: 'tick-icon',
    navigateToPageUrl: '',
    navigationOption: 'use-as-blank',
    css_config: { color: 'var(--alerts-color-1)' },
    gridPosition: {
      y: 7,
      x: 17,
      rows: 2,
      cols: 5,
      layerIndex: 1,
    },
  },
  titleTextWidget: {
    textContent: 'Access Granted',
    gridPosition: {
      y: 9,
      x: 14,
      rows: 1,
      cols: 11,
      layerIndex: 1,
    } as GridPosition,
  },

  subtitleTextWidget: {
    textContent: `{{#if addUserForm.first_name}}
        {{addUserForm.first_name}}
        {{#if addUserForm.middle_name}} {{addUserForm.middle_name}}{{/if}} 
        {{addUserForm.last_name}}
        {{else}}
        {{addUserForm.email}}
      {{/if}}
      is now a 
      {{addUserForm.assign_persona}}`,
    gridPosition: {
      y: 10,
      x: 14,
      rows: 1,
      cols: 11,
      layerIndex: 1,
    } as GridPosition,
  },
  primaryCtaWidget: {
    configDisplayName: 'Assign Persona to Another User',
    textContent: 'Assign Persona to Another User',
    urlToNavigate: 'assign-persona',
    buttonActionType: ButtonActionType.ASSIGN_PERSONA_FORM_STATE_CLEAR_AND_REDIRECT,
    css_config: {
      backGroundColor: 'var(--palette-color-3)',
      color: 'var(--palette-color-6)',
    } as BaseCssConfig,
    gridPosition: {
      y: 11,
      x: 14,
      rows: 1,
      cols: 11,
      layerIndex: 1,
    } as GridPosition,
  },
  cancelCtaWidget: {
    configDisplayName: 'Exit',
    textContent: 'Exit',
    urlToNavigate: 'select-user',
    buttonActionType: ButtonActionType.ASSIGN_PERSONA_FORM_STATE_CLEAR_AND_REDIRECT,
    css_config: {
      backGroundColor: 'var(--palette-color-2)',
      color: 'var(--typography-color-0)',
    } as BaseCssConfig,
    gridPosition: {
      y: 13,
      x: 14,
      rows: 1,
      cols: 11,
      layerIndex: 1,
    } as GridPosition,
  },
};

export const errorPageWidgetsOverride = {
  quickLinkWithIcon: {
    icon: 'exclamation-circle',
    navigateToPageUrl: '',
    navigationOption: 'use-as-blank',
    configDisplayname: 'Error Icon',
    css_config: { color: 'var(--alerts-color-0)' },
    gridPosition: {
      y: 7,
      x: 17,
      rows: 2,
      cols: 5,
      layerIndex: 1,
    },
  },

  titleTextWidget: {
    textContent: 'Error Granting Access',
    gridPosition: {
      y: 9,
      x: 14,
      rows: 1,
      cols: 11,
      layerIndex: 1,
    } as GridPosition,
  },
  subtitleTextWidget: {
    textContent: 'We seem to be having a problem with our server',
    gridPosition: {
      y: 10,
      x: 13,
      rows: 1,
      cols: 13,
      layerIndex: 1,
    } as GridPosition,
  },
  quickLinkWidget: {
    navigateToPageUrl: 'assign-persona',
    navigationOption: 'use-as-navigation',
    textContent: 'Try Again',
    configDisplayName: 'Try Again',
    css_config: {
      borderWidth: 0,
      backGroundColor: 'var(--palette-color-3)',
      color: 'var(--palette-color-6)',
      borderRadiusTopLeft: 6,
      borderRadiusBottomLeft: 6,
      borderRadiusTopRight: 6,
      borderRadiusBottomRight: 6,
      verticalAlignment: 'center',
      fontSize: 16,
    },
    gridPosition: {
      y: 11,
      x: 14,
      rows: 1,
      cols: 11,
      layerIndex: 1,
    },
  },
  cancelCtaWidget: {
    configDisplayName: 'Discard',
    textContent: 'Discard',
    urlToNavigate: 'select-user',
    buttonActionType: ButtonActionType.ASSIGN_PERSONA_FORM_STATE_CLEAR_AND_REDIRECT,
    css_config: {
      backGroundColor: 'var(--palette-color-2)',
      color: 'var(--typography-color-0)',
    } as BaseCssConfig,
    gridPosition: {
      y: 13,
      x: 14,
      rows: 1,
      cols: 11,
      layerIndex: 1,
    } as GridPosition,
  },
};

export function getSuccessOrErrorPageWidgets(type: 'error' | 'success'): WorkflowWidgetConfig[] {
  const pageWidgets =
    type === 'error'
      ? generateErrorPageWidgets(errorPageWidgetsOverride)
      : generateSuccessPageWidgets(successPageWidgetsOverride);
  pageWidgets.push(borderBoxWrapperWidget());
  return pageWidgets;
}

function generateSuccessPageWidgets(override: any): WorkflowWidgetConfig[] {
  return [
    generateQuickLinkWidgetConfig(override.quickLinkWithIcon),
    generateTitleTextWidgetConfig(override.titleTextWidget),
    generateSubTitleTextWidgetConfig(override.subtitleTextWidget),
    generateCtaButtonWidgetConfig(override.primaryCtaWidget),
    generateCtaButtonWidgetConfig(override.cancelCtaWidget),
  ];
}

function generateErrorPageWidgets(override: any): WorkflowWidgetConfig[] {
  return [
    generateQuickLinkWidgetConfig(override.quickLinkWithIcon),
    generateTitleTextWidgetConfig(override.titleTextWidget),
    generateSubTitleTextWidgetConfig(override.subtitleTextWidget),
    generateQuickLinkWidgetConfig(override.quickLinkWidget),
    generateCtaButtonWidgetConfig(override.cancelCtaWidget),
  ];
}

function generateQuickLinkWidgetConfig(override: any): WorkflowWidgetConfig {
  return new QuickLinkWidgetBuilder()
    .icon(override?.icon ?? '')
    .zone(APP_ZONES.USER_MANAGEMENT)
    .navigateToPageUrl(override?.navigateToPageUrl ?? '')
    .navigationOption(override?.navigationOption)
    .role()
    .text(override?.textContent ?? '')
    .configDisplayName(override.configDisplayName)
    .cssConfig({
      borderWidth: 0,
      backGroundColor: 'transparent',
      borderRadiusTopLeft: 5,
      borderRadiusBottomLeft: 5,
      borderRadiusTopRight: 5,
      borderRadiusBottomRight: 5,
      fontSize: 16,
      iconSize: 90,
      iconUnit: 'px',
      ...override?.css_config,
    })
    .gridPosition({
      y: 7,
      x: 17,
      rows: 2,
      cols: 5,
      layerIndex: 1,
      ...override.gridPosition,
    } as GridPosition)
    .build();
}
function generateTitleTextWidgetConfig(override: any): WorkflowWidgetConfig {
  return createdSuccessfullyTextWidget({
    textContent: override.textContent,
    gridPosition: {
      y: 9,
      x: 14,
      rows: 1,
      cols: 11,
      layerIndex: 1,
      ...override.gridPosition,
    } as GridPosition,
  });
}
function generateSubTitleTextWidgetConfig(override: any): WorkflowWidgetConfig {
  return createdSuccessfullySubTitleTextWidget({
    textContent: override.textContent,
    gridPosition: {
      y: 10,
      x: 14,
      rows: 1,
      cols: 11,
      layerIndex: 1,
      ...override.gridPosition,
    } as GridPosition,
  });
}

function generateCtaButtonWidgetConfig(override: {
  configDisplayName: string;
  textContent: string;
  urlToNavigate: string;
  buttonActionType: ButtonActionType;
  css_config: BaseCssConfig;
  gridPosition: GridPosition;
}): WorkflowWidgetConfig {
  return new FormSubmissionButtonWidgetBuilder()
    .configDisplayName(`Button: ${override.configDisplayName}`)
    .attributes({
      widgetProperties: {
        textContent: override.textContent,
        zoneToNavigate: APP_ZONES.USER_MANAGEMENT,
        urlToNavigate: override.urlToNavigate,
        buttonActionType: override.buttonActionType,
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .cssConfig({
      borderWidth: 0,
      borderRadiusTopLeft: 6,
      borderRadiusBottomLeft: 6,
      borderRadiusTopRight: 6,
      borderRadiusBottomRight: 6,
      fontSize: 16,
      verticalAlignment: 'center',
      ...override.css_config,
    })
    .gridPosition(override.gridPosition)
    .build();
}
function borderBoxWrapperWidget(): WorkflowWidgetConfig {
  return boxLineSuccessTextWidget({
    gridPosition: {
      y: 5,
      x: 11,
      rows: 12,
      cols: 17,
      layerIndex: 0,
    } as GridPosition,
  });
}

export const boxLineSuccessTextWidget = ({ gridPosition }: { gridPosition?: GridPosition }) =>
  boxLineTextWidget({
    gridPosition: {
      x: 12,
      y: 4,
      rows: 16,
      cols: 15,
      ...gridPosition,
    } as GridPosition,
    height: '100%',
  });

export const boxLineTextWidget = ({ gridPosition, height }: { gridPosition: GridPosition; height: string }) =>
  new TextWidgetBuilder()
    .text(
      ` <div
  style="
    border: 0.5px solid rgba(206, 212, 218, 1);
    border-radius: 5px;
    width: 100%;
    height: ${height};
  "
></div> `,
    )
    .gridPosition({
      layerIndex: 0,
      ...gridPosition,
    } as GridPosition)
    .configDisplayName('Text Widget: Box Line')
    .cssConfig({
      color: 'var(--typography-color-1)',
      fontSize: 20,
      textAlignment: 'center',
    })
    .build();

export const createdSuccessfullyTextWidget = ({
  textContent,
  gridPosition,
}: {
  textContent: string;
  gridPosition?: GridPosition;
}) =>
  headerSubTitle({
    textContent,
    gridPosition: {
      y: 9,
      x: 14,
      rows: 2,
      cols: 11,
      layerIndex: 1,
      ...gridPosition,
    } as GridPosition,
    cssConfig: {
      color: 'var(--typography-color-0)',
      fontSize: 24,
      isTextBold: true,
      textAlignment: 'center',
      paddingLeft: 0,
    },
  });

export const createdSuccessfullySubTitleTextWidget = ({
  textContent,
  gridPosition,
}: {
  textContent: string;
  gridPosition?: GridPosition;
}) =>
  new TextWidgetBuilder()
    .text(textContent)
    .gridPosition({
      y: 11,
      x: 14,
      rows: 2,
      cols: 11,
      layerIndex: 1,
      ...gridPosition,
    } as GridPosition)
    .configDisplayName(`Text Widget: ${textContent}`)
    .cssConfig({
      color: 'var(--typography-color-1)',
      fontSize: 20,
      textAlignment: 'center',
      verticalAlignment: 'start',
    })
    .build();

export const headerSubTitle = ({
  textContent,
  gridPosition,
  cssConfig,
}: {
  textContent: string;
  gridPosition?: GridPosition;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cssConfig?: any;
}) =>
  new TextWidgetBuilder()
    .text(textContent)
    .gridPosition({
      y: 3,
      x: 0,
      rows: 2,
      cols: 15,
      ...gridPosition,
    } as GridPosition)
    .configDisplayName(`Text Widget: ${textContent}`)
    .cssConfig({
      color: 'var(--typography-color-0)',
      fontSize: 20,
      paddingLeft: 40,
      textAlignment: 'left',
      ...cssConfig,
    })
    .build();
