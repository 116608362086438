import { clientDetailsTemplateRemoveClientPage } from '@finxone-platform/shared/default-templates';
import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonActionType,
  GridPosition,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import {
  FormSubmissionButtonWidgetBuilder,
  ImageWidgetBuilder,
} from '../../../defaults/widgets/example-builder';
import { getBackButtonWidget } from '../../../defaults/zones/manage-users-zone/common-utils';
import { generateTextWidget } from '../../../defaults/zones/payments/common-widgets-utils';

export const confirmationRemovingClientFromListingWidgets = [
  new ImageWidgetBuilder()
    .configDisplayName('Image Widget: Error Icon')
    .cssConfig({
      selectedobjectfit: 'contain',
    })
    .gridPosition({
      y: 3,
      x: 21,
      rows: 2,
      cols: 3,
    } as GridPosition)
    .attributes({
      widgetProperties: {
        textContent: '',
        imageAssetUrl: 'assets/images/payment-decline.svg',
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .build(),
  generateTextWidget({
    text: 'Remove {{ selectedClientDetails.name }} from {{ selectedClientDetails.feeGroupName }} ?',
    position: {
      y: 5,
      x: 11,
      rows: 1,
      cols: 23,
    } as GridPosition,
    displayName: 'Text Widget: Header Text',
    styles: {
      fontSize: 24,
      isTextBold: true,
      verticalAlignment: 'start',
      textAlignment: 'center',
      noAlignment: true,
      color: 'var(--alerts-color-0)',
      textFont: 'var(--font-family)',
      paddingTop: 0,
    },
    isRemovable: true,
  }),

  generateTextWidget({
    text: 'Below are the details for the client that you wish to remove from the group named {{ selectedClientDetails.feeGroupName }}. The default group will be assigned to the client.',
    position: {
      y: 6,
      x: 6,
      rows: 1,
      cols: 36,
    } as GridPosition,
    displayName: 'Text Widget: Information Content',
    styles: {
      verticalAlignment: 'start',
      textAlignment: 'center',
      fontSize: '16',
      paddingTop: 5,
      lineHeight: '24',
      textFont: 'var(--font-family)',
      color: 'var(--typography-color-1)',
    },
    isRemovable: true,
  }),

  generateTextWidget({
    text: clientDetailsTemplateRemoveClientPage,
    position: {
      y: 8,
      x: 13,
      rows: 7,
      cols: 20,
    } as GridPosition,
    styles: {
      backGroundColor: 'var(--palette-color-5)',
      paddingTop: '15',
      paddingBottom: '15',
      paddingLeft: '10',
      paddingRight: '10',
      marginBottom: '0',
      display: 'flex',
      verticalAlignment: 'center',
      textAlignment: 'left',
      borderRadiusTopLeft: '5',
      borderRadiusTopRight: '5',
      borderRadiusBottomLeft: '5',
      borderRadiusBottomRight: '5',
      textFont: 'var(--font-family)',
    },
    displayName: 'Text Widget: Client Information',
    isRemovable: false,
  }),

  getBackButtonWidget({
    position: {
      y: 16,
      x: 18,
      rows: 1,
      cols: 4,
    } as GridPosition,
    styles: {
      color: 'var(--palette-color-3)',
      backGroundColor: 'var(--palette-color-6)',
      borderWidth: 1,
      borderColor: 'var(--palette-color-3)',
      fontSize: 14,
      textAlign: 'center',
      verticalAlignment: 'center',
    },
  }),

  new FormSubmissionButtonWidgetBuilder()
    .configDisplayName('Button: Remove group')
    .attributes({
      widgetProperties: {
        textContent: 'Remove Client',
        zoneToNavigate: APP_ZONES.FEE_MANAGEMENT,
        urlToNavigate: 'management-client-list',
        buttonActionType: ButtonActionType.REMOVE_CLIENT_FROM_FEE_GROUP,
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .cssConfig({
      marginTop: 0,
      color: 'var(--alerts-color-0)',
      borderColor: 'var(--alerts-color-0)',
      backGroundColor: 'var(--palette-color-6)',
      borderWidth: 1,
    })
    .gridPosition({
      y: 16,
      x: 23,
      rows: 1,
      cols: 5,
    } as GridPosition)
    .build(),
];
