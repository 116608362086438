import {
  Constants,
  DummyRole,
  NavigationOptionEnum,
  WidgetNames,
} from '@finxone-platform/shared/sys-config-types';

export const conditionalNestedWidgets = {
  ifCondition: {
    name: WidgetNames.CONDITIONAL_WIDGET_IF_ELSE_CONFIG,
    widgetConfigDisplayName: 'Success condition',
    isRemovable: true,
    attributes: {
      widgetProperties: {
        textContent: '<h4>If Conditional Widget</h4><span>Sub header</span>',
        righticon: 'arrow-right',
        lefticon: 'playlist-icon',
        isTextEditable: true,
      },
      elementsConfig: [],
      css_config: {
        ...Constants.defaultUiNestedButtonCssConfig,
        backGroundColor: 'var(--palette-color-6)',
        color: 'var(--typography-color-0)',
        borderWidth: 1,
        borderColor: 'var(--border-color-1)',
        borderStyle: 'solid',
        verticalAlignment: 'center',
        textAlignment: 'left',
        textFont: 'Metropolis',
        paddingLeft: 10,
        paddingRight: 10,
        shadowBlur: 0,
        shadowSpread: 0,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
      },
      role: DummyRole.EMPTY_ROLE,
      navigationOption: NavigationOptionEnum.USE_AS_BLANK,
      zone: '',
      pageUrl: '',
    },
  },
  elseCondition: {
    name: WidgetNames.CONDITIONAL_WIDGET_IF_ELSE_CONFIG,
    widgetConfigDisplayName: 'Failure condition',
    isRemovable: true,
    attributes: {
      widgetProperties: {
        righticon: 'arrow-right',
        textContent: '<h4>Else Conditional Widget</h4><span>Sub header</span>',
        lefticon: 'playlist-icon',
        isTextEditable: true,
      },
      elementsConfig: [],
      zone: '',
      pageUrl: '',
      navigationOption: NavigationOptionEnum.USE_AS_BLANK,
      role: DummyRole.EMPTY_ROLE,
      css_config: {
        ...Constants.defaultUiNestedButtonCssConfig,
        paddingLeft: 10,
        paddingRight: 10,
        shadowBlur: 0,
        shadowSpread: 0,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        backGroundColor: 'var(--palette-color-6)',
        color: 'var(--typography-color-0)',
        borderWidth: 1,
        borderColor: 'var(--border-color-1)',
        borderStyle: 'solid',
        verticalAlignment: 'center',
        textAlignment: 'left',
        textFont: 'Metropolis',
      },
    },
  },
};
