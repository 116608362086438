import {
  APP_ZONES,
  BaseWidgetProperties,
  GridPosition,
  WorkflowWidgetConfig,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import { ImageWidgetBuilder } from '../../../defaults/widgets';
import { getBackButtonWidget } from '../../../defaults/zones/manage-users-zone';
import { generateTextWidget, getQuickLinkWidget } from '../../../defaults/zones/payments';

export function generateFeeManagementNotAvailablePageWidgets(): WorkflowWidgetConfig[] {
  const widgets = [
    new ImageWidgetBuilder()
      .configDisplayName('Image Widget: Info Icon')
      .cssConfig({
        selectedobjectfit: 'contain',
      })
      .gridPosition({ y: 6, x: 22, rows: 2, cols: 3 } as GridPosition)
      .attributes({
        widgetProperties: {
          textContent: '',
          imageAssetUrl: 'assets/images/info.svg',
        } as BaseWidgetProperties,
      } as WorkflowWidgetConfigurationAttributes)
      .build(),

    generateTextWidget({
      text: 'Fee Management: Feature Unavailable',
      displayName: 'Page Heading',
      isRemovable: false,
      position: {
        x: 10,
        y: 9,
        rows: 1,
        cols: 28,
      } as GridPosition,
      styles: {
        fontSize: 24,
        color: 'var(--typography-color-0)',
        isTextBold: true,
        textFont: 'var(--font-family)',
        textAlignment: 'center',
      },
    }),

    generateTextWidget({
      text: `<p>This feature is not accessible at this time and will not be available for use. We appreciate your understanding. If you have any questions or need assistance, please reach out to our support team.</p>`,
      displayName: 'Information',
      isRemovable: false,
      position: {
        x: 10,
        y: 10,
        rows: 3,
        cols: 28,
      } as GridPosition,
      styles: {
        fontSize: 20,
        textAlignment: 'center',
        verticalAlignment: 'center',
        lineHeight: '24',
        textFont: 'var(--font-family)',
        color: 'var(--typography-color-1)',
      },
    }),

    getBackButtonWidget({
      position: {
        y: 13,
        x: 18,
        rows: 1,
        cols: 5,
      } as GridPosition,
      styles: {
        color: 'var(--palette-color-3)',
        backGroundColor: 'var(--palette-color-6)',
        borderWidth: 1,
        borderColor: 'var(--palette-color-3)',
        textFont: 'var(--font-family)',
        fontSize: 14,
        textAlign: 'center',
        verticalAlignment: 'center',
      },
    }),

    getQuickLinkWidget({
      text: 'Home',
      position: {
        x: 24,
        y: 13,
        rows: 1,
        cols: 5,
      } as GridPosition,
      pageUrl: 'home',
      displayName: 'Quick Link: Home',
      styles: {
        borderWidth: 1,
        color: 'var(--palette-color-6)',
        fontSize: 14,
        backGroundColor: 'var(--palette-color-3)',
        textAlignment: 'center',
        justifyContent: 'center',
        borderColor: 'var(--palette-color-3)',
        textFont: 'var(--font-family)',
        borderStyle: 'solid',
        borderRadiusTopLeft: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusBottomRight: 5,
      },
      zone: APP_ZONES.LANDING,
      isRemovable: false,
    }),
  ];
  return widgets;
}
