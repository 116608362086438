import { transactionSummaryTemplate } from '@finxone-platform/shared/default-templates';
import { commonBackButtonQuickLink, commonTextWidget } from '../../../common/common-widgets';

export const tripZoneTransactionSummaryWidgets = [
  commonBackButtonQuickLink({
    cssConfig: {
      iconSize: 35,
      iconUnit: 'px',
    },
    gridPosition: {
      y: 0,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 2,
    },
  }),
  commonTextWidget({
    textContent: 'Review transaction details',
    configDisplayName: 'Review transaction details',
    cssConfig: {
      textAlignment: 'center',
      fontSize: 24,
      isTextBold: true,
      textFont: 'var(--font-family)',
    },
    gridPosition: {
      y: 1,
      x: 2,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 3,
      cols: 11,
    },
  }),

  commonTextWidget({
    textContent: 'Transaction Summary',
    configDisplayName: 'Transaction Summary',
    cssConfig: {
      fontSize: 24,
      color: 'var(--typography-color-1)',
      textFont: 'var(--font-family)',
    },
    gridPosition: {
      y: 4,
      x: 0,
      rows: 2,
      cols: 15,
      resizeEnabled: true,
      dragEnabled: true,
    },
  }),

  commonTextWidget({
    textContent: transactionSummaryTemplate,
    configDisplayName: 'Transaction Summary Template',
    cssConfig: {
      fontSize: 24,
      borderWidth: 1,
      borderColor: 'var(--border-color-1)',
      borderRadiusTopLeft: 8,
      borderRadiusBottomLeft: 8,
      borderRadiusTopRight: 8,
      borderRadiusBottomRight: 8,
      textFont: 'var(--font-family)',
    },
    gridPosition: {
      y: 7,
      x: 0,
      rows: 19,
      cols: 15,
      resizeEnabled: true,
      dragEnabled: true,
    },
  }),
];
