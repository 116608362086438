import { revenirTransactionHistoryItemTemplate } from '@finxone-platform/shared/default-templates';
import {
  BaseWidgetProperties,
  GridPosition,
  NavigationOptionEnum,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import {
  QuickLinkWidgetBuilder,
  RevenirTransactionWidgetBuilder,
  RevenirTripReclaimStatusWidgetBuilder,
} from '../../../widgets';
import { commonBackButtonQuickLink, commonTextWidget } from '../../common';

export const revenirArchivedTransactionsWidgets = [
  commonBackButtonQuickLink({
    cssConfig: {
      iconSize: 35,
      iconUnit: 'px',
    },
    gridPosition: {
      y: 0,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 2,
      layerIndex: 1,
    },
  }),

  new QuickLinkWidgetBuilder()
    .icon('archive')
    .navigationOption(NavigationOptionEnum.USE_AS_BLANK)
    .role()
    .text('')
    .configDisplayName(`Quick Link: Archive`)
    .cssConfig({
      marginTop: 15,
      marginBottom: 15,
      color: 'var(--palette-color-3)',
      backGroundColor: 'var(--palette-color-6)',
      borderRadiusTopLeft: 5,
      borderRadiusBottomLeft: 5,
      borderRadiusTopRight: 5,
      borderRadiusBottomRight: 5,
      fontSize: 16,
    })
    .gridPosition({
      y: 0,
      x: 13,
      rows: 2,
      cols: 2,
    } as GridPosition)
    .build(),

  commonTextWidget({
    textContent: `{{ revenirFormState.selectedTripDetails.countryName }}`,
    configDisplayName: 'Title',
    cssConfig: {
      fontSize: 24,
      isTextBold: true,
      textFont: 'var(--font-family)',
    },
    gridPosition: {
      y: 2,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 15,
      layerIndex: 0,
    },
  }),

  new RevenirTripReclaimStatusWidgetBuilder()
    .gridPosition({
      x: 0,
      y: 5,
      rows: 3,
      cols: 15,
    } as GridPosition)
    .build(),

  new RevenirTransactionWidgetBuilder()
    .isRemovable(false)
    .gridPosition({
      x: 0,
      y: 9,
      rows: 19,
      cols: 15,
    } as GridPosition)
    .cssConfig({
      fontSize: 14,
    })
    .attributes({
      widgetProperties: {
        textContent: ``,
      } as BaseWidgetProperties,
      titleTemplate: '',
      transactionItemConfig: {
        showAllTransaction: true,
        isTitleSwitchShown: false,
        transactionHistoryItemTemplate: revenirTransactionHistoryItemTemplate,
      },
    } as unknown as WorkflowWidgetConfigurationAttributes)
    .build(),
];
