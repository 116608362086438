export const notificationBeneficiaryAddedTemplate = `<div class="w-100 h-100">
  <div class="d-flex my-4">
    <div class="col-6-xs">
      <div class="text-label">Currency</div>
      <div class="text-value"> {{notification.selectedNotification.destinationIdentifier.currency}} </div>
    </div>
    {{#if notification.selectedNotification.address}}
    <div class="col-6-xs">
      <div class="text-label">Country</div>
      <div class="text-value"> {{notification.selectedNotification.address.country}} </div>
    </div>
    {{/if}}
  </div>
  <hr class='my-1 mx-1'>
  <div class="d-flex">
    <div class="col-12">
      <div class="text-label">Full name</div>
      <div class="text-value font-weight-bold"> {{notification.selectedNotification.name}} </div>
    </div>
  </div>
  {{#if notification.selectedNotification.destinationIdentifier.accountNumber}}
  <div class="d-flex">
    <div class="col-6-xs">
      <div class="text-label">Account number</div>
      <div class="text-value"> {{notification.selectedNotification.destinationIdentifier.accountNumber}} </div>
    </div>
  </div>
  {{/if}}
  {{#if notification.selectedNotification.destinationIdentifier.iban}}
  <div class="d-flex">
    <div class="col-6-xs">
      <div class="text-label">IBAN</div>
      <div class="text-value"> {{notification.selectedNotification.destinationIdentifier.iban}} </div>
    </div>
  </div>
  {{/if}}
  {{#if notification.selectedNotification.destinationIdentifier.aba}}
  <div class="d-flex">
    <div class="col-6-xs">
      <div class="text-label">ABA routing number</div>
      <div class="text-value"> {{notification.selectedNotification.destinationIdentifier.aba}} </div>
    </div>
  </div>
  {{/if}}
  {{#if notification.selectedNotification.destinationIdentifier.rtnCanada}}
  <div class="d-flex">
    <div class="col-6-xs">
      <div class="text-label">Canada Routing Number</div>
      <div class="text-value"> {{notification.selectedNotification.destinationIdentifier.rtnCanada}} </div>
    </div>
  </div>
  {{/if}}
  {{#if notification.selectedNotification.destinationIdentifier.sortCode}}
  <div class="d-flex">
    <div class="col-6-xs">
      <div class="text-label">Sort Code</div>
      <div class="text-value"> {{formatMask notification.selectedNotification.destinationIdentifier.sortCode "##-##-##"}} </div>
    </div>
  </div>
  {{/if}}
  {{#if notification.selectedNotification.destinationIdentifier.bsbCode}}
  <div class="d-flex">
    <div class="col-6-xs">
      <div class="text-label">BSB Code</div>
      <div class="text-value"> {{notification.selectedNotification.destinationIdentifier.bsbCode}} </div>
    </div>
  </div>
  {{/if}}
  {{#if notification.selectedNotification.destinationIdentifier.ifsc}}
  <div class="d-flex">
    <div class="col-6-xs">
      <div class="text-label">IFSC Code</div>
      <div class="text-value"> {{notification.selectedNotification.destinationIdentifier.ifsc}} </div>
    </div>
  </div>
  {{/if}}
  {{#if notification.selectedNotification.destinationIdentifier.cnaps}}
  <div class="d-flex">
    <div class="col-6-xs">
      <div class="text-label">CNAPS Bank Code</div>
      <div class="text-value"> {{notification.selectedNotification.destinationIdentifier.cnaps}} </div>
    </div>
  </div>
  {{/if}}
  {{#if notification.selectedNotification.destinationIdentifier.clabe}}
  <div class="d-flex">
    <div class="col-6-xs">
      <div class="text-label">CLABE</div>
      <div class="text-value"> {{notification.selectedNotification.destinationIdentifier.clabe}} </div>
    </div>
  </div>
  {{/if}}
  {{#if notification.selectedNotification.destinationIdentifier.bankCode}}
  <div class="d-flex">
    <div class="col-6-xs">
      <div class="text-label">Bank Code</div>
      <div class="text-value"> {{notification.selectedNotification.destinationIdentifier.bankCode}} </div>
    </div>
  </div>
  {{/if}}
  {{#if notification.selectedNotification.destinationIdentifier.branchCode}}
  <div class="d-flex">
    <div class="col-6-xs">
      <div class="text-label">Branch Code</div>
      <div class="text-value"> {{notification.selectedNotification.destinationIdentifier.branchCode}} </div>
    </div>
  </div>
  {{/if}}
  {{#if notification.selectedNotification.destinationIdentifier.bankName}}
  <div class="d-flex">
    <div class="col-12">
      <div class="text-label">Bank Name</div>
      <div class="text-value"> {{notification.selectedNotification.destinationIdentifier.bankName}} </div>
    </div>
  </div>
  {{/if}}
  {{#if notification.selectedNotification.destinationIdentifier.bic}}
  <div class="d-flex">
    <div class="col-6-xs">
      <div class="text-label">SWIFT/BIC Code</div>
      <div class="text-value"> {{notification.selectedNotification.destinationIdentifier.bic}} </div>
    </div>
  </div>
  {{/if}}
  <hr class='my-1 mx-1'>
  <div class="d-flex">
    <div class="col-12">
      <div class="text-label">Added on:</div>
      <div class="text-value"> {{datePipe notification.selectedNotification.createdDate format="d MMM y h:mm a"}} </div>
    </div>
  </div>
</div>`;
