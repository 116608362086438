import { Constants, GridPosition } from '@finxone-platform/shared/sys-config-types';
import { getBackButtonWidget } from '../zones/manage-users-zone';
import { cardsWidget } from './card-management-v2/card-management-v2.widgets';
import { CardPINWidgetBuilder, TextWidgetBuilder } from './example-builder';

const title = new TextWidgetBuilder()
  .text(
    `
    <h6>
      Your ePIN
    </h6>
    `,
  )
  .configDisplayName('Text: Show Pin Title')
  .cssConfig({
    ...Constants.defaultBaseCssConfig,
    color: 'var(--color-on-surface)',
  })
  .gridPosition({ x: 0, y: 0, cols: 15 } as GridPosition)
  .build();

const subTitle = new TextWidgetBuilder()
  .text(
    `
    <span class="subtitle1">
    Your ePIN is confidential. Memorise it and avoid storing it electronically or writing it down to keep your account secure.
    </span>
    `,
  )
  .configDisplayName('Text: Show Pin Subtitle')
  .cssConfig({
    ...Constants.defaultBaseCssConfig,
    color: 'var(--color-on-surface-muted)',
  })
  .gridPosition({ x: 0, y: 0, cols: 15, rows: 4 } as GridPosition)
  .build();

const cardPINWidget = new CardPINWidgetBuilder()
  .cssConfig({
    textAlignment: 'center',
    fontSize: 24,
    isTextBold: true,
    verticalAlignment: 'center',
  })
  .gridPosition({ x: 0, y: 10, cols: 15, rows: 3 } as GridPosition)
  .build();

export const showPinWidgets = [
  title,
  subTitle,
  cardsWidget,
  cardPINWidget,
  getBackButtonWidget({
    position: { y: 27, x: 0, rows: 2, cols: 15 } as GridPosition,
    styles: {
      fontSize: 14,
      textAlignment: 'center',
      backGroundColor: 'var(--color-background)',
      borderWidth: 1,
      borderColor: 'var(--color-border-color)',
    },
  }),
];
