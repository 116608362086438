import {
  BaseWidgetProperties,
  ExchangeQuotePayload,
  FieldAttribute,
  UiZoneWidgetAttributeConfig,
} from '@finxone-platform/shared/sys-config-types';
import {
  ResetFormDataAction,
  SetFormActionWithId,
} from '@app/finxone-web-frontend/app/lib/actions/form-submission.action';
import { SetPageContext } from '@app/finxone-web-frontend/app/lib/actions/page-context.action';
import { FormActionTypeEnum } from '@finxone-platform/form-action';
import { FormActionState } from '@app/finxone-web-frontend/app/lib/state/form-submision.state';
import { Store } from '@ngxs/store';
import { Injector } from '@angular/core';
import { ExchangeService } from '@app/finxone-web-frontend/app/lib/services/exchange-service/exchange-service.service';
import { AccountState } from '@app/finxone-web-frontend/app/lib/state/account.state';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { Router } from '@angular/router';
import { CountdownService } from '@app/finxone-web-frontend/app/lib/services/countdown-service/countdown-service.service';

export function reQuote(injector: Injector, widgetProperties: BaseWidgetProperties) {
  const store = injector.get(Store);
  const formStateData = store.selectSnapshot(FormActionState.getFormActionState);
  const exchangeService = injector.get(ExchangeService);
  const countdownService = injector.get(CountdownService);
  const quoteExpiration = widgetProperties['field_attributes']?.find(
    (field: FieldAttribute) => field.name === 'quoteExpiration',
  ).value;

  if (
    formStateData.response?.formData &&
    formStateData.response.formData['buyAccountId'] &&
    formStateData.response.formData['sellAccountId'] &&
    formStateData.response.formData['amount']
  ) {
    const exchangeQuotePayload: ExchangeQuotePayload = formStateData.response
      .formData as ExchangeQuotePayload;
    const lastQuoteId = exchangeService.lastQuoteResponseData$.getValue()?.quoteId;
    if (exchangeQuotePayload && lastQuoteId) {
      exchangeService.postRefreshQuote(lastQuoteId, exchangeQuotePayload).subscribe(() => {
        countdownService.startCountdown(quoteExpiration);
      });
    }
  }
}

export function acceptQuote(injector: Injector, alertHandlerService: AlertHandlerService, router: Router) {
  const store = injector.get(Store);
  const formStateData = store.selectSnapshot(FormActionState.getFormActionState);
  const exchangeService = injector.get(ExchangeService);
  const exchangeQuotePayload: ExchangeQuotePayload = formStateData.response?.formData as ExchangeQuotePayload;
  const lastQuoteId = exchangeService.lastQuoteResponseData$.getValue()?.quoteId;
  const accountsData = store.selectSnapshot(AccountState.getAccounts);

  if (lastQuoteId) {
    const sellAccountId = exchangeQuotePayload.sellAccountId;
    const account = accountsData.accounts.find((account) => account.accountId === sellAccountId);
    if (!account) {
      console.error('Account with sellAccountId not found');
      return;
    }

    const accountBalance = Number(account.balance);
    const lastExchangeQuoteResponseData = exchangeService.lastQuoteResponseData$.getValue();
    const isBalanceAvailable = lastExchangeQuoteResponseData?.debitAmount
      ? accountBalance >= lastExchangeQuoteResponseData?.debitAmount
      : false;
    if (!isBalanceAvailable) {
      alertHandlerService.showAlertFn('error', 'Enter a lower amount to continue.', 'Insufficient balance.');
      return;
    }

    if (lastExchangeQuoteResponseData?.quoteId) {
      exchangeService.postAcceptQuote(lastExchangeQuoteResponseData?.quoteId).subscribe(
        (result) => {
          store.dispatch(new ResetFormDataAction());
          store.dispatch(
            new SetPageContext({
              pageContextData: { conversionRef: result.transactionNumber },
            }),
          );
          store.dispatch(
            new SetFormActionWithId(
              {
                type: '',
                formData: result,
              },
              FormActionTypeEnum.EXCHANGE_QUOTE_SUMMARY,
            ),
          );
          router.navigate(['/zones/payments/conversion-successful']);
        },
        (error) => {
          console.error(error);
          store.dispatch(
            new SetFormActionWithId(
              {
                type: '',
                formData: exchangeQuotePayload,
              },
              FormActionTypeEnum.EXCHANGE_QUOTE_SUMMARY,
            ),
          );
          router.navigate(['/zones/payments/conversion-failed']);
        },
      );
    }
  }
}
