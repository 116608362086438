export const notificationDailyBalanceTemplate = `<div class="w-100 h-100">
<div class='is-vertical-align is-center'>
    <div class="ben-label">Total Balance</div>
    <div class="mt-2 mb-2 typog-h5 font-weight-bold">{{ currencyPipe notification.selectedNotification.amount currencyCode=notification.selectedNotification.currency display="symbol-narrow" }}</div>
</div>
<hr class="my-4 mx-1">
<div class="is-space-between">
    <div class='is-vertical-align'>
        <div class='is-vertical-align align-left'>
            <div class="ben-label">Full Name</div>
            <div class="ben-value">{{profile.fullName}}</div>
        </div>
    </div>
    <div class='is-vertical-align text-right'>
        <div class="ben-label" >As on:</div>
        <div class="ben-value">{{datePipe notification.selectedNotification.createdAt format='d MMM y'}}</div>
    </div>
</div>
<div class="is-space-between my-4">
    <div class='is-vertical-align'>
        <div class='is-vertical-align align-left'>
            <div class="ben-label"></div>
            <div class="ben-value"></div>
        </div>
    </div>
    <div class='is-vertical-align text-right'>
        <div class="ben-label" >At</div>
        <div class="ben-value">{{datePipe notification.selectedNotification.createdAt format='h:mm a'}}</div>
    </div>
</div>
</div>`;
