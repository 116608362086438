export enum HttpErrorCode {
  //400
  STATUS_400 = 'status_400',
  STATUS_401 = 'status_401',
  STATUS_402 = 'status_402',
  STATUS_403 = 'status_403',
  STATUS_404 = 'status_404',
  STATUS_405 = 'status_405',
  STATUS_4XX_CATCH_ALL_ERROR = 'status_4xx-catch-all_error',

  //500
  STATUS_500 = 'status_500',
  STATUS_501 = 'status_501',
  STATUS_502 = 'status_502',
  STATUS_503 = 'status_503',
  STATUS_504 = 'status_504',
  STATUS_505 = 'status_505',
  STATUS_5XX_CATCH_ALL_ERROR = 'status_5xx-catch-all_error',
}
