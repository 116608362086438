import {
  BaseWidgetProperties,
  EntityType,
  GridPosition,
  WorkflowWidgetConfig,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import {
  BackButtonWidgetBuilder,
  DesktopPaymentRequestPendingListingWidgetBuilder,
  SectionWidgetBuilder,
  TabWidgetBuilder,
  TextWidgetBuilder,
  TwoColumnWidgetBuilder,
} from '../../../widgets/example-builder';
import { BackButtonWidgetBuilderCssConfig, inputSettings } from '../../../widgets/payments/payments.type';
import { defaultTableCheckBoxCssConfig } from '../../../widgets/workflow-ui-constants.v2.type';

export const paymentRequestorPendingListingColumns = [
  {
    label: 'Requester',
    value: 'requester',
    handleBarKey: '{{requester.name}}',
    selected: true,
  },
  {
    label: 'Organisation',
    value: 'orgName',
    handleBarKey: '{{orgName}}',
    selected: true,
  },
  {
    label: 'Source Account',
    value: 'sourceAccount',
    handleBarKey: '{{account.name}}',
    selected: true,
  },
  {
    label: 'Payment Ref.',
    value: 'paymentRef',
    handleBarKey: '{{#if paymentReference}}{{paymentReference}}{{else}} -- {{/if}}',
    selected: true,
  },
  {
    label: 'Payment Type',
    value: 'paymentType',
    handleBarKey: '<span id="td-data" class="text-capitalize">{{paymentType}}</span>',
    selected: true,
  },
  {
    label: 'Beneficiary',
    value: `beneficiary`,
    handleBarKey: '<span id="td-data" class="text-capitalize">{{beneficiary.name}}</span>',
    selected: true,
  },
  {
    label: 'Amount',
    value: `amount`,
    handleBarKey: "{{ currencyPipe amount currencyCode=currency display='symbol-narrow' }}",
    selected: true,
  },
];

export const paymentRequestorApprovalListingColumns = [
  {
    label: 'Requester',
    value: 'requester',
    handleBarKey: '{{requester.name}}',
    selected: true,
  },
  {
    label: 'Organisation',
    value: 'orgName',
    handleBarKey: '{{orgName}}',
    selected: true,
  },
  {
    label: 'Source Account',
    value: 'sourceAccount',
    handleBarKey: '{{account.name}}',
    selected: true,
  },
  {
    label: 'Payment Ref.',
    value: 'paymentRef',
    handleBarKey: '{{#if paymentReference}}{{paymentReference}}{{else}} -- {{/if}}',
    selected: true,
  },
  {
    label: 'Payment Type',
    value: 'paymentType ',
    handleBarKey: '<span id="td-data" class="text-capitalize">{{paymentType}}</span>',
    selected: true,
  },
  {
    label: 'Beneficiary',
    value: `beneficiary`,
    handleBarKey: '<span id="td-data" class="text-capitalize">{{beneficiary.name}}</span>',
    selected: true,
  },
  {
    label: 'Amount',
    value: `amount`,
    handleBarKey: "{{ currencyPipe amount currencyCode=currency display='symbol-narrow' }}",
    selected: true,
  },
  {
    label: ' Approval Date',
    value: `approvalDate`,
    handleBarKey: `{{datePipe approvedOrRejectedDate  format='d MMM y'}}`,
    selected: true,
  },
];

export function paymentRequestorPendingPageWidgets(): WorkflowWidgetConfig[] {
  const widgets = [
    new TwoColumnWidgetBuilder()
      .attributes({
        outerCss: `width:83%; top: 8rem; position: relative;`,
        widgetProperties: {
          columnOneOuterCss: `left:55px; position: relative;`,
          columnOnePercent: 85,
        } as unknown as BaseWidgetProperties,
      } as unknown as WorkflowWidgetConfigurationAttributes)
      .childComponents([
        [
          new TextWidgetBuilder()
            .text('<h3>Payment Requests</h3>')
            .configDisplayName('Text Widget: Payment Requests')
            .cssConfig({
              fontSize: 24,
              isTextBold: true,
              textAlignment: 'left',
            })
            .build(),
        ],
        [
          new BackButtonWidgetBuilder()
            .defaultBackButton()
            .cssConfig({
              ...BackButtonWidgetBuilderCssConfig,
              color: 'var(--palette-color-6)',
              backGroundColor: 'var(--palette-color-3)',
              borderWidth: '1',
              borderColor: 'var(--palette-color-3)',
            })
            .isRemovable(true)
            .build(),
        ],
      ])
      .build(),

    new TabWidgetBuilder()
      .attributes({
        outerCss: `width: 80%; left:50px; position:relative; top:12rem;`,
        widgetProperties: {
          tabConfigs: [
            {
              title: 'Pending',
              outerCssConfig: 'top:60px; position: relative;',
            },
            {
              title: 'Approved',
              outerCssConfig: 'top:60px; position: relative;',
            },
          ],
        } as unknown as BaseWidgetProperties,
      } as unknown as WorkflowWidgetConfigurationAttributes)
      .cssConfig({
        elementStyling: {
          tab: {
            default: `height:45px; border-bottom: 1px solid var(--palette-color-3); 
            background:var(--palette-color-6); font-size: 16px; font-weight: 700;`,
            hover: '',
            selected: {
              css: ' background:var(--palette-color-3); color:var(--palette-color-6);',
              selector: '[data-state="active"]',
            },
            disabled: '',
          },
        },
      })
      .gridPosition({ x: 1, y: 5, rows: 2, cols: 42 } as GridPosition)
      .childComponents([
        [
          new SectionWidgetBuilder()
            .attributes({
              outerCss: 'height: 450px;',
            } as unknown as WorkflowWidgetConfigurationAttributes)
            .childComponents([
              new DesktopPaymentRequestPendingListingWidgetBuilder()
                .configDisplayName(`Payment Request Pending Listing Widget`)
                .gridPosition({ x: 1, y: 4, rows: 13, cols: 42 } as GridPosition)
                .attributes({
                  widgetProperties: {
                    icon: 'search',
                    textContent: '',
                    showEntity: true,
                    entityInformation: {
                      entity: EntityType.PaymentRequest,
                    },
                  } as BaseWidgetProperties,
                  columns: paymentRequestorPendingListingColumns,
                  viewListOption: 'pending',
                } as unknown as WorkflowWidgetConfigurationAttributes)
                .inputSetting({
                  ...inputSettings,
                  css_config: {
                    ...inputSettings.css_config,
                    inputWidth: 110,
                  },
                  label: 'Search',
                  isIconAllowed: true,
                })
                .isRemovable(false)
                .build(),
            ])
            .build(),
        ],
        [
          new SectionWidgetBuilder()
            .attributes({
              outerCss: 'height: 450px;',
            } as unknown as WorkflowWidgetConfigurationAttributes)
            .childComponents([
              new DesktopPaymentRequestPendingListingWidgetBuilder()
                .configDisplayName(`Payment Request Approved Listing Widget`)
                .gridPosition({ x: 1, y: 4, rows: 13, cols: 42 } as GridPosition)
                .attributes({
                  widgetProperties: {
                    icon: 'search',
                    textContent: '',
                    showEntity: true,
                    entityInformation: {
                      entity: EntityType.PaymentRequest,
                    },
                  } as BaseWidgetProperties,
                  tableCheckboxSettings: {
                    enableCheckbox: false,
                    inputFieldSettings: { ...defaultTableCheckBoxCssConfig },
                  },
                  columns: paymentRequestorApprovalListingColumns,
                  viewListOption: 'approved',
                } as unknown as WorkflowWidgetConfigurationAttributes)
                .inputSetting({
                  ...inputSettings,
                  css_config: {
                    ...inputSettings.css_config,
                    inputWidth: 110,
                  },
                  label: 'Search',
                  isIconAllowed: true,
                })
                .isRemovable(false)
                .build(),
            ])
            .build(),
        ],
      ])
      .build(),
  ];

  return widgets;
}
