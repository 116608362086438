import { FormActionTypeEnum } from '@finxone-platform/form-action';
import {
  APP_ZONES,
  ButtonActionType,
  Constants,
  WidgetNames,
} from '@finxone-platform/shared/sys-config-types';
import { CalendarWidgetBuilder } from '../../../../widgets';
import { commonCTAButton, commonTextWidget } from '../../../common/common-widgets';
import { CommonTripZoneWidgets } from './common-widgets';

export const tripZoneTripStartWidgets = [
  ...new CommonTripZoneWidgets().commonTripStartEndWidgets,

  commonTextWidget({
    textContent:
      '{{#if tripZoneFormState.startDate}}{{#if tripZoneFormState.endDate}} Trip End {{else}} Trip End {{/if}} {{else}} Trip Start {{/if}}',
    cssConfig: {
      textAlignment: 'left',
      fontSize: 24,
      isTextBold: true,
      textFont: 'var(--font-family)',
    },
    gridPosition: {
      y: 3,
      x: 5,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 10,
    },
  }),
  new CalendarWidgetBuilder()
    .nestedWidgets({
      resetButton: {
        name: WidgetNames.BUTTON,
        widgetConfigDisplayName: 'Reset changes button',
        attributes: {
          widgetProperties: {
            textContent: 'Reset changes',
          },
          elementsConfig: [],
          css_config: {
            ...Constants.defaultUiNestedButtonCssConfig,
            paddingBottom: 15,
            paddingTop: 15,
            color: 'var(--palette-color-3)',
            backGroundColor: 'var(--palette-color-6)',
            isTextUnderline: true,
          },
        },
      },
    })
    .addCustomWidgetProperties({
      selectionMode: 'range',
      widgetType: 'new',
      formKey: FormActionTypeEnum.ADD_TRIP_REVENIR_ZONE,
    })
    .gridPosition({
      y: 7,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 20,
      cols: 15,
    })
    .build(),

  commonCTAButton({
    zoneToNavigate: APP_ZONES.REVENIR_ZONE,
    urlToNavigate: 'entering-the-eu',
    textContent: 'Continue',
    buttonActionType: ButtonActionType.TRIP_START_CONTINUE,
    formKey: FormActionTypeEnum.ADD_TRIP_REVENIR_ZONE,
  }),
];
