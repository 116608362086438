import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { FormActionTypeEnum } from '@finxone-platform/form-action';
import { APP_ZONES, BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { map, take } from 'rxjs';
import { ResetFormDataAction } from '../../../actions/form-submission.action';
import { UserProfileUpdated } from '../../../actions/user-profile.action';
import { FormSubmissionService } from '../../../services/form-submission-service/form-submission-service.service';
import { FormActionState } from '../../../state/form-submision.state';
import { mapFormData } from '../../utils';
import { redirectToPage } from '../cta-button-actions.utils';

/**
 * Submits changes to the user's verification profile and handles the navigation
 * and state reset upon successful update. This function retrieves necessary
 * services and state using the provided injector, maps form data to the required
 * format, and dispatches a profile update request. Upon successful profile update,
 * it redirects the user to the verification zone and resets the form data state.
 *
 * @param injector - The Angular Injector used to retrieve services and state.
 */
export function submitVerificationProfileChanges(injector: Injector) {
  const store = injector.get(Store);
  const actions = injector.get(Actions);
  const router = injector.get(Router);
  const formSubmissionService = injector.get(FormSubmissionService);
  const formStateData = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.KYC_DETAILS),
  );
  const widgetProperties: BaseWidgetProperties = {
    textContent: '',
    zoneToNavigate: APP_ZONES.VERIFICATION,
    urlToNavigate: `information-confirmation`,
  };
  const keyMapping: { [key: string]: string } = {
    address1: 'line_1',
    address2: 'line_2',
    address3: 'line_3',
    address4: 'line_4',
    addressCity: 'city',
    addressPostCode: 'post_code',
    addressState: 'county',
    addressCountry: 'country',
  };
  const mappedFormData = mapFormData(formStateData?.formData, keyMapping);
  formSubmissionService.submitProfileUpdateRequest(mappedFormData);
  actions
    .pipe(
      ofActionDispatched(UserProfileUpdated),
      take(1),
      map(() => {
        redirectToPage(router, widgetProperties, injector);
        store.dispatch(new ResetFormDataAction());
      }),
    )
    .subscribe();
}
