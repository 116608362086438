import { UUID } from '@finxone-platform/shared/base-types';
import {
  BaseWidgetProperties,
  ButtonSizeOptions,
  ButtonTypes,
  DummyRole,
  GridPosition,
  InputFieldSettings,
  NestedWorkflowWidgetConfiguration,
  NewWorkflowWidgetConfig,
  WORKFLOW_POPUPS,
  WorkflowWidgetConfig,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';

export interface IWidgetBuilder {
  gridPosition(gridPosition: GridPosition): IWidgetBuilder;
  childComponents(childComponents: NewWorkflowWidgetConfig[] | NewWorkflowWidgetConfig[][]): IWidgetBuilder;
  attributes(attributes: WorkflowWidgetConfigurationAttributes): IWidgetBuilder;
  name(name: string): IWidgetBuilder;
  popupType(popupType: WORKFLOW_POPUPS | null): IWidgetBuilder;
  isRemovable(isRemovable: boolean): IWidgetBuilder;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cssConfig(cssConfig: any): IWidgetBuilder;
  configDisplayName(name: string): IWidgetBuilder;
  text(text: string): IWidgetBuilder;
  navigationOption(type: string): IWidgetBuilder;
  navigateToPageUrl(url: string): IWidgetBuilder;
  nestedWidgets(nestedWidgets: { [uniqueId: string]: NestedWorkflowWidgetConfiguration }): IWidgetBuilder;
  role(): IWidgetBuilder;
  setWidgetProperties(widgetProperties: BaseWidgetProperties): IWidgetBuilder;
  addCustomWidgetProperties(widgetProperties: { [key: string]: string | boolean | number }): IWidgetBuilder;
  inputSetting(setting: InputFieldSettings): IWidgetBuilder;
  hideCondition(hideCondition: string): IWidgetBuilder;
  build(): WorkflowWidgetConfig;
  setGlobalStylingClass(isGlobalStyleApply?: boolean, stylingClass?: string): IWidgetBuilder;
}

export const initWidgetBuilder = (widgetConst: WorkflowWidgetConfig) => {
  const widget = JSON.parse(JSON.stringify(widgetConst)) as WorkflowWidgetConfig;
  // generate a new uniqueId if needed
  if (widget.name !== widget.uniqueId) widget.uniqueId = new UUID().toString();
  return widget;
};

export class BaseWidgetBuilder {
  public widget: WorkflowWidgetConfig;

  gridPosition(gridPosition: GridPosition): IWidgetBuilder {
    this.widget.gridPosition = {
      ...this.widget.gridPosition,
      ...gridPosition,
    };
    return this;
  }

  childComponents(childComponents: NewWorkflowWidgetConfig[] | NewWorkflowWidgetConfig[][]): IWidgetBuilder {
    this.widget.childComponents = [...(childComponents as NewWorkflowWidgetConfig[])];
    return this;
  }

  attributes(attributes: WorkflowWidgetConfigurationAttributes): IWidgetBuilder {
    this.widget.widgetConfiguration.attributes = {
      ...this.widget.widgetConfiguration.attributes,
      ...attributes,
    };
    return this;
  }

  nestedWidgets(nestedWidgets: { [uniqueId: string]: NestedWorkflowWidgetConfiguration }): IWidgetBuilder {
    this.widget.widgetConfiguration.nestedWidgets = {
      ...this.widget.widgetConfiguration.nestedWidgets,
      ...nestedWidgets,
    };
    return this;
  }

  name(name: string): IWidgetBuilder {
    this.widget.name = name;
    return this;
  }
  popupType(popupType: WORKFLOW_POPUPS | null): IWidgetBuilder {
    this.widget.popupType = popupType;
    return this;
  }

  isRemovable(isRemovable: boolean): IWidgetBuilder {
    this.widget.widgetConfiguration.isRemovable = isRemovable;
    return this;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cssConfig(cssConfig: any): IWidgetBuilder {
    this.widget.widgetConfiguration.attributes.css_config = {
      ...this.widget.widgetConfiguration.attributes.css_config,
      ...cssConfig,
    };
    return this;
  }
  configDisplayName(name: string): IWidgetBuilder {
    this.widget.widgetConfiguration.widgetConfigDisplayName = name;
    return this;
  }
  text(text: string): IWidgetBuilder {
    this.widget.widgetConfiguration.attributes.widgetProperties.textContent = text;
    return this;
  }

  navigationOption(type: string): IWidgetBuilder {
    this.widget.widgetConfiguration.attributes['navigationOption'] = type;
    return this;
  }
  // dummy role, to set role active config activeRole in role-navigation-configuration component
  role(): IWidgetBuilder {
    this.widget.widgetConfiguration.attributes['role'] = DummyRole.EMPTY_ROLE;
    return this;
  }
  navigateToPageUrl(url: string): IWidgetBuilder {
    this.widget.widgetConfiguration.attributes['pageUrl'] = url;
    return this;
  }

  inputSetting(setting: InputFieldSettings): IWidgetBuilder {
    this.widget.widgetConfiguration.attributes.widgetProperties.inputFieldSettings = setting;
    return this;
  }

  setWidgetProperties(widgetProperties: BaseWidgetProperties): this {
    this.widget.widgetConfiguration.attributes.widgetProperties = widgetProperties;
    return this;
  }

  addCustomWidgetProperties(widgetProperties: { [key: string]: string | boolean | number | unknown }): this {
    this.widget.widgetConfiguration.attributes.widgetProperties = {
      ...this.widget.widgetConfiguration.attributes.widgetProperties,
      ...widgetProperties,
    };
    return this;
  }

  hideCondition(hideCondition: string): this {
    this.widget.widgetConfiguration.attributes.hideCondition = hideCondition;
    return this;
  }

  build(): WorkflowWidgetConfig {
    return this.widget;
  }

  setGlobalStylingClass(
    isGlobalStyleApply = false,
    stylingClass = `${ButtonTypes.MENU} ${ButtonSizeOptions.FULL_WIDTH}`,
  ): IWidgetBuilder {
    if (isGlobalStyleApply) {
      const widgetConfig = this.widget?.widgetConfiguration?.attributes;
      if (!widgetConfig) {
        return this;
      }
      widgetConfig.widgetProperties = widgetConfig.widgetProperties || {};
      widgetConfig.widgetProperties['globalStyling'] = isGlobalStyleApply;
      widgetConfig.widgetProperties['globalStylingClass'] = stylingClass;
      widgetConfig.css_config = {};
    }
    return this;
  }
}
