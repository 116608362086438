import {
  APP_ZONES,
  BaseWidgetProperties,
  GridPosition,
  ListingTypeEnum,
  WorkflowWidgetConfig,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import {
  BackButtonWidgetBuilder,
  FeeClientListingWidgetBuilder,
} from '../../../defaults/widgets/example-builder';
import { inputSettings } from '../../../defaults/widgets/payments/payments.type';
import {
  generateTextWidget,
  getQuickLinkWidget,
} from '../../../defaults/zones/payments/common-widgets-utils';

export const tableColumns = [
  {
    label: 'Fee Name',
    value: `feeName`,
    handleBarKey: '<span class="text-capitalize">{{feeName}}</span>',
    selected: true,
  },
  {
    label: 'Client Name',
    value: `clientName`,
    handleBarKey: '{{clientName}}',
    selected: true,
  },
  {
    label: 'Amount/Percentage',
    value: `amountPercentage`,
    handleBarKey: `{{ feeChargeValue feeCharge unitChargeType feeCurrency }}`,
    selected: true,
  },
  {
    label: 'Unit Charge',
    value: `unitCharge`,
    handleBarKey: '{{ valueToTitleChange unitChargeType}}',
    selected: true,
  },
  {
    label: 'Frequency',
    value: `frequency`,
    handleBarKey: '<span class="text-capitalize">{{frequencyType}}</span>',
    selected: true,
  },
  {
    label: 'Group Assigned',
    value: `groupAssigned`,
    handleBarKey: '<span class="text-capitalize">{{feeGroupName}}</span>',
    selected: true,
  },
  {
    label: 'Fee Charged',
    value: `feeCharged`,
    handleBarKey:
      '<span class="text-capitalize">{{currencyPipe feeChargedAmount currencyCode=feeCurrency display="symbol-narrow"}}</span>',
    selected: true,
  },
  {
    label: 'Effective Date',
    value: `effectiveDate`,
    handleBarKey: `{{ datePipe effectiveDate  format='d MMM y'}}`,
    selected: true,
  },
  {
    label: 'Status',
    value: `status`,
    handleBarKey: `<span class="text-capitalize">{{ status }}</span>`,
    selected: true,
  },
];

function commonHeaderWidget() {
  const commonHeaderWidget = [
    generateTextWidget({
      text: 'Fee History - Landing Page',
      position: { x: 1, y: 0, rows: 1, cols: 28 } as GridPosition,
      displayName: 'Header Text',
      styles: {
        fontSize: 24,
        isTextBold: true,
        verticalAlignment: 'start',
        textAlignment: 'left',
        noAlignment: true,
      },
      isRemovable: true,
    }),

    generateTextWidget({
      text: `The list consists of all the fees charged up to this date. It does not include the fees charged against a client's accounts. Go to the client fee page to take a look.`,
      position: { x: 1, y: 1, rows: 2, cols: 36 } as GridPosition,
      displayName: 'Information Content',
      styles: {
        verticalAlignment: 'start',
        textAlignment: 'left',
        fontSize: 20,
        lineHeight: '24',
        textFont: 'var(--font-family)',
        color: 'var(--typography-color-1)',
      },
      isRemovable: true,
    }),

    new BackButtonWidgetBuilder()
      .defaultBackButton()
      .configDisplayName('Back Navigation Button')
      .text('Back')
      .cssConfig({
        color: 'var(--palette-color-3)',
        backGroundColor: 'var(--palette-color-6)',
        borderWidth: 1,
        borderColor: 'var(--palette-color-3)',
        fontSize: 14,
        textAlign: 'center',
        verticalAlignment: 'center',
      })
      .gridPosition({
        y: 0,
        x: 37,
        rows: 1,
        cols: 4,
        resizeEnabled: true,
        dragEnabled: true,
      })
      .build(),

    getQuickLinkWidget({
      text: 'Fee management',
      position: {
        x: 42,
        y: 0,
        rows: 1,
        cols: 5,
      } as GridPosition,
      pageUrl: 'management-fee-list',
      displayName: 'Quick Link: Fee management',
      styles: {
        borderWidth: 1,
        color: 'var(--palette-color-6)',
        fontSize: '16',
        backGroundColor: 'var(--palette-color-3)',
        textAlignment: 'center',
        justifyContent: 'center',
        borderColor: 'var(--palette-color-3)',
        borderStyle: 'solid',
        borderRadiusTopLeft: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusBottomRight: 5,
      },
      zone: APP_ZONES.FEE_MANAGEMENT,
      isRemovable: true,
    }),
  ];
  return commonHeaderWidget;
}

export function generateFeeChargeHistoryPageWidgets(): WorkflowWidgetConfig[] {
  return [
    ...commonHeaderWidget(),

    new FeeClientListingWidgetBuilder()
      .text('Listing Widget')
      .configDisplayName(`Listing Widget`)
      .gridPosition({ x: 1, y: 3, rows: 21, cols: 46 } as GridPosition)
      .cssConfig({
        color: 'var(--palette-color-1)',
        textAlignment: 'left',
        justifyContent: 'left',
        fontSize: '16',
        borderWidth: 1,
        borderColor: 'var(--palette-color-2)',
        backGroundColor: 'var(--palette-color-6)',
        borderStyle: 'solid',
        borderRadiusUnit: 'px',
      })
      .attributes({
        widgetProperties: {
          icon: 'search',
          textContent: ``,
          showEntity: false,
          memuOptions: [
            { label: 'Revert', value: 'revert-fee' },
            { label: 'Retry', value: 'retry' },
            { label: 'View Reason', value: 'view-reason' },
          ],
          listingType: ListingTypeEnum.FEE_CHARGE_HISTORY_LISTING,
        } as BaseWidgetProperties,
        columns: tableColumns,
      } as unknown as WorkflowWidgetConfigurationAttributes)
      .inputSetting({
        ...inputSettings,
        css_config: {
          ...inputSettings.css_config,
          inputWidth: 120,
        },
        label: 'Search',
        isIconAllowed: true,
      })
      .isRemovable(false)
      .build(),

    generateTextWidget({
      text: 'Note: Please go to the groups drilldown view to add a new fee structure for a group.',
      position: { x: 0, y: 25, rows: 1, cols: 32 } as GridPosition,
      displayName: 'Information Content',
      styles: {
        verticalAlignment: 'start',
        textAlignment: 'left',
        fontSize: '16',
        paddingLeft: 33,
        paddingTop: 5,
        lineHeight: '24',
        textFont: 'var(--font-family)',
        color: 'var(--typography-color-1)',
      },
      isRemovable: true,
    }),
  ];
}
