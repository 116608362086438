import { Router } from '@angular/router';
import { APP_ZONES, BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';

import { Injector } from '@angular/core';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { FormActionState } from '../../../state/form-submision.state';
import { ProjectSettingsState } from '../../../state/project-settings.state';
import { redirectToPage } from '../cta-button-actions.utils';
import { approvePaymentRequest } from './submit-payment-request-action';

/**
 * Handles the approval process for a payment request by navigating to the appropriate page
 * based on the current form state and SMS authentication settings.
 *
 * @param widgetProperties - The properties of the widget, including navigation details.
 * @param injector - The injector used to retrieve necessary services and state.
 * @throws Error if the form state data is not found.
 */
export function paymentRequestApprove(widgetProperties: BaseWidgetProperties, injector: Injector) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  const alertHandlerService = injector.get(AlertHandlerService);
  const formState = store.selectSnapshot(FormActionState.getFormActionState);
  const isSmsAuthEnabled = store.selectSnapshot(
    ProjectSettingsState.getProjectSettings,
  ).smsAuthenticationEnabled;
  if (!widgetProperties['zoneToNavigate']) {
    widgetProperties['zoneToNavigate'] = APP_ZONES.PAYMENT;
  }
  const parsedWidgetProperties = JSON.parse(JSON.stringify(widgetProperties));
  if (formState?.response?.formData) {
    if (isSmsAuthEnabled) {
      parsedWidgetProperties['urlToNavigate'] = 'payment-authentication-approver';
      redirectToPage(router, parsedWidgetProperties, injector, true);
    } else {
      approvePaymentRequest(injector, parsedWidgetProperties);
    }
  } else {
    alertHandlerService.showAlertFn('error', 'FormState data not found.');
    throw Error('FormState data not found.');
  }
}
