/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  accountListingForSelectAccountTemplate,
  accountSwitchDropdownTemplate,
  accountSwitchWidgetVisualTemplate,
  currencyAccountListItemTemplate,
  exchangeTransactionHistoryItemTemplate,
  multiIconQuickLinkTemplate,
  quickLinkTemplate,
  titleTemplate,
  transactionHistoryItemTemplate,
} from '@finxone-platform/shared/default-templates';

import { TransactionHistoryTopRightOption } from '@app/finxone-web-frontend/app/lib/utils/widget.utils';
import { FormActionTypeEnum } from '@finxone-platform/form-action';
import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonSizeOptions,
  ButtonTypes,
  ConfirmationPreferenceTypes,
  Constants,
  CurrencyExchangeTransactionType,
  DefaultToggleOption,
  DummyRole,
  ENTITY_TYPE,
  EntityType,
  FieldName,
  GridPosition,
  InputCssConfig,
  ModalConfiguration,
  NestedWorkflowWidgetConfiguration,
  Page,
  TransactionType,
  WORKFLOW_POPUPS,
  WidgetFields,
  WidgetNames,
  WidgetTitles,
  WorkflowWidgetConfig,
  WorkflowWidgetConfigurationAttributes,
  commonPageConfigValue,
  cryptoRandomUUID,
} from '@finxone-platform/shared/sys-config-types';

import { UUID } from '@finxone-platform/shared/base-types';
import { OnboardingNextBackWidgetBuilder } from './example-builder';
import { cardsNestedWidgets } from './nested-widgets/cards/cards';
import { conditionalNestedWidgets } from './nested-widgets/conditional/conditional';
import {
  otpVerificationInternationalNestedWidgets,
  otpVerificationNestedWidgets,
} from './nested-widgets/otp/otp';
import { txHistoryNestedWidgets } from './nested-widgets/transaction-history/transaction-history';
import { getZoneSpecificCSSConfig } from './onboarding/onboarding.type';
import { defaultInputCssConfig } from './payments/payments.type';

export const beneficiaryCarouselWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: Math.floor(Math.random() * (1000000000 + 1)),
  name: WidgetNames.BENEFICIARY_CAROUSEL,
  widgetConfiguration: {
    widgetDisplayName: WidgetTitles.BENEFICIARY_CAROUSEL,
    widgetConfigDisplayName: 'Beneficiary Carousel',
    attributes: {
      widgetProperties: { textContent: '' },
      elementsConfig: [],
      css_config: Constants.defaultBeneficiaryCarouselBaseCssConfig,
    },
    isRemovable: true,
    isWidgetPage: true,
  },
  gridPosition: {
    x: 0,
    y: 0,
    minItemCols: 15,
    minItemRows: 6,
    rows: 6,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const defaultTableHeaderSettingCssConfig = {
  tableHeaderBackgroundColor: 'var(--palette-color-5)',
  tableHeaderTextColor: 'var(--typography-color-0)',
  tableHeaderFontSize: 16,
  tableHeaderFontUnit: 'px',
  tableHeaderFontFamily: 'Mulish',
};

export const defaultTableCheckBoxCssConfig = {
  css_config: {
    backgroundColor: 'var(--palette-color-3)',
    borderColor: 'var(--palette-color-3)',
    inactiveBorderColor: 'var(--palette-color-3)',
    inactiveBackgroundColor: 'var(--palette-color-6)',
    inputTextColor: 'var(--palette-color-6)',
    borderRadiusTopLeft: 5,
    borderRadiusTopRight: 5,
    borderRadiusBottomLeft: 5,
    borderRadiusBottomRight: 5,
    borderStyleUnit: 'px',
    borderWidth: 2,
    iconSize: 24,
    iconSizeUnit: 'px',
  },
};

export const getAddAndSelectTripWidget = (): WorkflowWidgetConfig => {
  const addAndSelectTripWidget: WorkflowWidgetConfig = {
    widgetTypeConfig: 'new',
    uniqueId: WidgetNames.TRIP_ADD_SELECT_WIDGET,
    name: WidgetNames.TRIP_ADD_SELECT_WIDGET,
    widgetConfiguration: {
      isRemovable: false,
      widgetDisplayName: '',
      widgetConfigDisplayName: WidgetTitles.TRIP_ADD_SELECT_WIDGET,
      attributes: {
        css_config: {
          ...Constants.defaultBaseCssConfig,
          borderRadiusTopLeft: 8,
          borderRadiusBottomLeft: 8,
          borderRadiusTopRight: 8,
          borderRadiusBottomRight: 8,
          borderWidth: 1,
          borderColor: 'var(--border-color-1)',
        },
        widgetProperties: {
          textContent: '',
          tripButton: 'Add Trip',
          tripLabel: 'Select Trip',
          tripRoute: 'zones/revenir/trip-start',
          icon: 'archive',
          iconColor: 'var(--typography-color-0)',
        },
        elementsConfig: [],
      },
    },
    gridPosition: {
      x: 0,
      y: 4,
      rows: 7,
      cols: 15,
      dragEnabled: true,
      resizeEnabled: true,
    },
    widgetIconPath: '',
  };
  return addAndSelectTripWidget;
};

export const getTripInformationExitWidget = (): WorkflowWidgetConfig => {
  const tripInformationExitWidget: WorkflowWidgetConfig = {
    widgetTypeConfig: 'new',
    uniqueId: WidgetNames.TRIP_INFORMATION_EXIT_WIDGET,
    name: WidgetNames.TRIP_INFORMATION_EXIT_WIDGET,
    widgetConfiguration: {
      isRemovable: false,
      widgetDisplayName: '',
      widgetConfigDisplayName: WidgetTitles.TRIP_INFORMATION_WIDGET,
      attributes: {
        css_config: {
          ...Constants.defaultBaseCssConfig,
          width: 100,
          borderWidth: 1,
          borderRadiusTopLeft: 5,
          borderRadiusBottomLeft: 5,
          borderRadiusTopRight: 5,
          borderRadiusBottomRight: 5,
          paddingTop: 3,
          paddingLeft: 3,
          paddingRight: 3,
          paddingBottom: 3,
          borderColor: 'var(--border-color-1)',
          dimensionUnit: '%',
          marginBottom: 50,
        },
        widgetProperties: {
          textContent: '',
          approxTime: 'Approximate time*',
          modeOfTravel: 'Mode of travel*',
          departureTerminal: 'Departure Terminal/Station*',
          formKey: FormActionTypeEnum.ADD_TRIP_REVENIR_ZONE,
        },
        elementsConfig: [],
      },
    },
    gridPosition: {
      x: 0,
      y: 7,
      rows: 20,
      cols: 15,
      dragEnabled: true,
      resizeEnabled: true,
    },
    widgetIconPath: '',
  };
  return tripInformationExitWidget;
};

export function cardManagementZoneWidget(
  widgetName: WidgetNames,
  gridPosition: GridPosition,
  displayName = '',
  elementsConfig: WidgetFields[] = [],
): WorkflowWidgetConfig {
  return {
    widgetTypeConfig: 'new',
    uniqueId: widgetName,
    name: widgetName,
    widgetConfiguration: {
      widgetDisplayName: '',
      widgetConfigDisplayName: displayName,
      isRemovable: false,
      attributes: {
        widgetProperties: { textContent: '' },
        elementsConfig: elementsConfig,
        css_config: {
          ...Constants.defaultBaseCssConfig,
          marginTop: 15,
          marginBottom: 15,
        },
      },
    },
    gridPosition: gridPosition,
    widgetIconPath: '',
  };
}

export const cardManagementWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: WidgetNames.CARD_MANAGEMENT,
  name: WidgetNames.CARD_MANAGEMENT,
  widgetConfiguration: {
    widgetDisplayName: WidgetTitles.CARD_MANAGEMENT,
    widgetConfigDisplayName: FieldName.CARD_MANAGEMENT_TITLE,
    isRemovable: true,
    attributes: {
      widgetProperties: {
        textContent: '',
      },
      elementsConfig: [],
      css_config: {
        show: true,
        contentAlignment: 'center',
        ...Constants.defaultBaseCssConfig,
        color: 'var(--color-0)',
        textAlignment: 'center',
        borderColor: '#ffffff00',
        borderRadiusBottomRight: 50,
        borderRadiusBottomLeft: 50,
        borderRadiusTopRight: 50,
        borderRadiusTopLeft: 50,
        borderRadiusUnit: 'px',
        borderStyle: 'solid',
        borderWidth: 0,
      },
    },
  },
  gridPosition: {
    x: 0,
    y: 0,
    rows: 6,
    cols: 15,
    minItemCols: 15,
    minItemRows: 6,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
  roleHook: 'card-management-hook',
} as WorkflowWidgetConfig;

export const monthlyUsageWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: WidgetNames.ACCOUNT_MONTHLY_IN_AND_OUT_WIDGET,
  name: WidgetNames.ACCOUNT_MONTHLY_IN_AND_OUT_WIDGET,
  widgetConfiguration: {
    widgetDisplayName: 'Income/Expenditure',
    widgetConfigDisplayName: WidgetTitles.ACCOUNT_MONTHLY_IN_AND_OUT_WIDGET,
    attributes: {
      widgetProperties: { textContent: '' },
      elementsConfig: [
        {
          field_name: 'showMonthlyIncome',
          label: 'Monthly Income',
          type: 'text',
          show: true,
          options: [],
        },
        {
          field_name: 'showMonthlyExpenditure',
          label: 'Monthly Expenditure',
          type: 'text',
          show: true,
          options: [],
        },
      ],
      css_config: Constants.defaultBaseCssConfig,
    },
    isRemovable: true,
  },
  gridPosition: {
    x: 0,
    y: 0,
    minItemCols: 15,
    minItemRows: 4,
    rows: 4,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const paymentButtonWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: WidgetNames.PAYMENT_BUTTON,
  name: WidgetNames.PAYMENT_BUTTON,
  widgetConfiguration: {
    widgetDisplayName: 'Payments Button',
    widgetConfigDisplayName: WidgetTitles.PAYMENT_BUTTON,
    isRemovable: true,
    attributes: {
      elementsConfig: [],
      css_config: {
        ...Constants.defaultBaseCssConfig,
        backGroundColor: 'var(--color-0)',
        color: 'var(--color-6)',
        textAlignment: 'center',
        verticalAlignment: 'center',
      },
      widgetProperties: {
        textContent: 'Payments Button',
      },
    },
    isWidgetPage: true,
  },
  gridPosition: {
    x: 0,
    y: 0,
    minItemCols: 15,
    minItemRows: 2,
    rows: 2,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const baseTransactionHistoryWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: new UUID().toString(),
  name: WidgetNames.RECENT_TRANSACTION_LIST_WIDGET,
  widgetConfiguration: {
    widgetDisplayName: 'Transaction History',
    widgetConfigDisplayName: WidgetTitles.RECENT_TRANSACTION_LIST_WIDGET,
    nestedWidgets: txHistoryNestedWidgets,
    isRemovable: true,
    attributes: {
      widgetProperties: { textContent: '' },
      elementsConfig: [],
      css_config: Constants.defaultBaseCssConfig,
      // Widget specific below
      titleTemplate: titleTemplate,
      pageSize: 5,
      pagingEnabled: true,
      defaultTransactionType: TransactionType.ALL,
      topRightConfiguration: {
        option: TransactionHistoryTopRightOption.TRANSACTION_TYPE_SELECTOR,
        transactionTypeSelectorOptions: [
          TransactionType.ALL,
          TransactionType.INCOMING,
          TransactionType.OUTGOING,
        ],
        quickLinkOptions: {
          // Should be rendered using the handlebars templates
          textContent: quickLinkTemplate, // so can change change font weight etc or style as a button
          zone: APP_ZONES.LANDING,
          pageUrl: '',
        },
      },
      transactionItemConfig: {
        imageEnabled: true,
        transactionHistoryItemTemplate: transactionHistoryItemTemplate,
        creditBackgroundImage:
          'PHN2ZyB2aWV3Qm94PSIwIDAgNTYgNTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZml0PSIiIGhlaWdodD0iMTAwJSIgICAgIHdpZHRoPSIxMDAlIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCBtZWV0IiBmb2N1c2FibGU9ImZhbHNlIj4gICAgIDxyZWN0IHdpZHRoPSI1NiIgaGVpZ2h0PSI1NiIgcng9IjIwIiBmaWxsPSIjRTNFOEYzIj48L3JlY3Q+ICAgICA8cGF0aCBkPSJNMjMgMzNMMzMgMjNNMzMgMjNIMjNNMzMgMjNWMzMiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiAgICAgICAgIHN0cm9rZS1saW5lam9pbj0icm91bmQiPjwvcGF0aD4gPC9zdmc+',
        debitBackgroundImage:
          'PHN2ZyB2aWV3Qm94PSIwIDAgNTYgNTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZml0PSIiIGhlaWdodD0iMTAwJSIgICAgIHdpZHRoPSIxMDAlIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCBtZWV0IiBmb2N1c2FibGU9ImZhbHNlIj4gICAgIDxyZWN0IHdpZHRoPSI1NiIgaGVpZ2h0PSI1NiIgcng9IjIwIiB0cmFuc2Zvcm09Im1hdHJpeCgtMSAwIDAgMSA1NiAwKSIgZmlsbD0iI0ZFQ0RDQSI+PC9yZWN0PiAgICAgPHBhdGggZD0iTTMzIDIzTDIzIDMzTTIzIDMzVjIzTTIzIDMzSDMzIiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgICAgICAgICBzdHJva2UtbGluZWpvaW49InJvdW5kIj48L3BhdGg+IDwvc3ZnPg==', // maybe only svg???
        imageCssProps: {
          marginTop: 0,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
          marginUnit: 'px',
        },
      },
    },
    isWidgetPage: true,
  },
  gridPosition: {
    x: 0,
    y: 0,
    minItemCols: 15,
    minItemRows: 4,
    resizeEnabled: true,
    dragEnabled: true,
    cols: 15,
    rows: 14,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;
export const transactionHistoryWidgetConfig = {
  ...baseTransactionHistoryWidgetConfig,
};

export const exchangetransactionHistoryWidgetConfig: WorkflowWidgetConfig = {
  ...baseTransactionHistoryWidgetConfig,
  widgetConfiguration: {
    ...baseTransactionHistoryWidgetConfig.widgetConfiguration,
    attributes: {
      widgetProperties: { textContent: '' },
      elementsConfig: [],
      css_config: Constants.defaultBaseCssConfig,
      titleTemplate: titleTemplate,
      pageSize: 5,
      pagingEnabled: true,
      defaultTransactionType: CurrencyExchangeTransactionType.CURRENCYINCOMING,
      topRightConfiguration: {
        option: TransactionHistoryTopRightOption.TRANSACTION_TYPE_SELECTOR,
        transactionTypeSelectorOptions: [
          CurrencyExchangeTransactionType.CURRENCYINCOMING,
          CurrencyExchangeTransactionType.CURRENCYOUTGOING,
        ],
        quickLinkOptions: {
          textContent: quickLinkTemplate,
          zone: APP_ZONES.LANDING,
          pageUrl: '',
        },
      },
      transactionItemConfig: {
        imageEnabled: true,
        transactionHistoryItemTemplate: exchangeTransactionHistoryItemTemplate,
        creditBackgroundImage:
          'PHN2ZyB2aWV3Qm94PSIwIDAgNTYgNTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZml0PSIiIGhlaWdodD0iMTAwJSIgICAgIHdpZHRoPSIxMDAlIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCBtZWV0IiBmb2N1c2FibGU9ImZhbHNlIj4gICAgIDxyZWN0IHdpZHRoPSI1NiIgaGVpZ2h0PSI1NiIgcng9IjIwIiBmaWxsPSIjRTNFOEYzIj48L3JlY3Q+ICAgICA8cGF0aCBkPSJNMjMgMzNMMzMgMjNNMzMgMjNIMjNNMzMgMjNWMzMiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiAgICAgICAgIHN0cm9rZS1saW5lam9pbj0icm91bmQiPjwvcGF0aD4gPC9zdmc+',
        debitBackgroundImage:
          'PHN2ZyB2aWV3Qm94PSIwIDAgNTYgNTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZml0PSIiIGhlaWdodD0iMTAwJSIgICAgIHdpZHRoPSIxMDAlIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCBtZWV0IiBmb2N1c2FibGU9ImZhbHNlIj4gICAgIDxyZWN0IHdpZHRoPSI1NiIgaGVpZ2h0PSI1NiIgcng9IjIwIiB0cmFuc2Zvcm09Im1hdHJpeCgtMSAwIDAgMSA1NiAwKSIgZmlsbD0iI0ZFQ0RDQSI+PC9yZWN0PiAgICAgPHBhdGggZD0iTTMzIDIzTDIzIDMzTTIzIDMzVjIzTTIzIDMzSDMzIiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgICAgICAgICBzdHJva2UtbGluZWpvaW49InJvdW5kIj48L3BhdGg+IDwvc3ZnPg==', // maybe only svg???
        imageCssProps: {
          marginTop: 0,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
          marginUnit: 'px',
        },
      },
    },
  },
};

export const simpleBalanceWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: WidgetNames.SIMPLE_BALANCE_WIDGET,
  name: WidgetNames.SIMPLE_BALANCE_WIDGET,
  widgetConfiguration: {
    widgetDisplayName: 'Simple Balance Widget',
    widgetConfigDisplayName: WidgetTitles.SIMPLE_BALANCE_WIDGET,
    attributes: {
      widgetProperties: { textContent: '' },
      elementsConfig: [],
      css_config: Constants.defaultSimpleBalanceBaseCssConfig,
    },
    isRemovable: true,
  },
  gridPosition: {
    x: 0,
    y: 0,
    cols: 15,
    rows: 10,
    minItemRows: 10,
    dragEnabled: true,
    resizeEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const quickLinkWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: Math.floor(Math.random() * (1000000000 + 1)),
  name: WidgetNames.QUICK_LINKS_WIDGET,
  widgetConfiguration: {
    widgetDisplayName: 'Quick Link',
    widgetConfigDisplayName: WidgetTitles.QUICK_LINKS_WIDGET,
    attributes: {
      elementsConfig: [],
      name: Page.QUICK_LINKS_WIDGET,
      type: 'links',
      slug: 'quick_links',
      label: 'Quick Links',
      css_config: {
        ...Constants.defaultBaseCssConfig,
        textAlignment: 'center',
        verticalAlignment: 'center',
        textFont: 'Mulish',
        fontSize: 20,
        shadowBlur: 0,
        shadowSpread: 0,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
      },
      widgetProperties: {
        textContent: 'Quick Links',
      },
      pageIndex: 0,
      zone: '',
    },
    isRemovable: true,
  },
  gridPosition: {
    x: 0,
    y: 0,
    minItemCols: 1,
    minItemRows: 1,
    rows: 4,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const resendLinkWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: Math.floor(Math.random() * (1000000000 + 1)),
  name: WidgetNames.RESEND_WIDGET,
  widgetConfiguration: {
    widgetDisplayName: 'Resend Link',
    widgetConfigDisplayName: WidgetTitles.RESEND_WIDGET,
    attributes: {
      elementsConfig: [],
      name: Page.RESEND_WIDGET,
      type: 'links',
      slug: 'resend_links',
      label: 'Resend Links',
      css_config: {
        ...Constants.defaultBaseCssConfig,
        textAlignment: 'center',
        verticalAlignment: 'center',
        textFont: 'Mulish',
        fontSize: 20,
        marginTop: 0,
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 0,
        shadowBlur: 0,
        shadowSpread: 0,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
      },
      widgetProperties: {
        textContent: 'Resend Links',
        globalStyling: true,
        globalStylingClass: `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`,
      },
      pageIndex: 0,
      zone: '',
    },
    isRemovable: true,
  },
  gridPosition: {
    x: 0,
    y: 0,
    minItemCols: 1,
    minItemRows: 1,
    rows: 4,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const switchNavigatorQuickLinkWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: Math.floor(Math.random() * (1000000000 + 1)),
  name: WidgetNames.SWITCH_NAVIGATOR_QUICK_LINK,
  widgetConfiguration: {
    widgetDisplayName: 'Switch Navigator Quick Link',
    widgetConfigDisplayName: WidgetTitles.SWITCH_NAVIGATOR_QUICK_LINK,
    attributes: {
      elementsConfig: [],
      name: Page.SWITCH_NAVIGATOR_QUICK_LINK,
      type: 'links',
      slug: 'switch_navigator_quick_links',
      label: 'Switch Navigator Quick Links',
      role: '',
      css_config: {
        ...Constants.defaultBaseCssConfig,
        textAlignment: 'center',
        verticalAlignment: 'center',
        textFont: 'Mulish',
        fontSize: 15,
        shadowBlur: 0,
        shadowSpread: 0,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        borderWidth: 1,
        borderRadiusUnit: 'px',
        borderRadiusTopLeft: 10,
        borderRadiusBottomLeft: 10,
        borderRadiusTopRight: 10,
        borderRadiusBottomRight: 10,
        borderColor: '#384F80',
        paddingUnit: 'px',
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 10,
        color: '#fff',
        backGroundColor: '#384F80',
        disabledBgColor: '#fff',
        disabledTextColor: '#384F80',
      },
      widgetProperties: {
        textContent: '',
      },
      linksList: [
        {
          textContent: 'Switch',
          pageIndex: 0,
          zone: '',
        },
        {
          textContent: 'Switch',
          pageIndex: 1,
          zone: '',
        },
      ],
    },
    isRemovable: true,
  },
  gridPosition: {
    x: 0,
    y: 5,
    minItemCols: 1,
    minItemRows: 1,
    rows: 3,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const chartWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: WidgetNames.BURNDOWN_CHART,
  name: WidgetNames.BURNDOWN_CHART,
  widgetConfiguration: {
    widgetConfigDisplayName: 'Spending',
    widgetDisplayName: WidgetTitles.BURNDOWN_CHART,
    isRemovable: true,
    attributes: {
      widgetProperties: { textContent: '' },
      elementsConfig: [],
      css_config: Constants.defaultBaseCssConfig,
    },
    isWidgetPage: true,
  },
  gridPosition: {
    x: 0,
    y: 0,
    minItemCols: 1,
    minItemRows: 1,
    rows: 10,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const progressBarWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: cryptoRandomUUID(),
  name: WidgetNames.PROGRESSBAR_WIDGET,
  widgetConfiguration: {
    widgetDisplayName: WidgetTitles.PROGRESSBAR_WIDGET,
    widgetConfigDisplayName: WidgetTitles.PROGRESSBAR_WIDGET,
    isRemovable: true,
    attributes: {
      widgetProperties: {
        textContent: WidgetTitles.PROGRESSBAR_WIDGET,
        completionProgressPercentage: '5',
        progressBarActiveColor: 'var(--palette-color-0)',
        progressBarColor: 'var(--palette-color-6)',
      },
      elementsConfig: [
        {
          field_name: WidgetNames.PROGRESSBAR_WIDGET,
          label: 'Image.png',
          type: 'file',
          section: WidgetNames.PROGRESSBAR_WIDGET,
          show: true,
          options: [],
        },
      ],
      css_config: {
        ...Constants.defaultBaseCssConfig,
        color: 'var(--typography-color-0)',
        backGroundColor: 'var(--palette-color-3)',
        textAlignment: 'center',
        verticalAlignment: 'end',
        fontSize: 25,
        isTextBold: true,
      },
    },
  },
  gridPosition: {
    x: 0,
    y: 0,
    minItemCols: 5,
    minItemRows: 4,
    rows: 8,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
  popupType: WORKFLOW_POPUPS.IMAGE_WIDGET,
} as WorkflowWidgetConfig;

export const multiIconQuickLinkWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: Math.floor(Math.random() * (1000000000 + 1)),
  name: WidgetNames.MULTI_ICON_QUICK_LINK_WIDGET,
  widgetConfiguration: {
    widgetDisplayName: WidgetTitles.MULTI_ICON_QUICK_LINK_WIDGET,
    widgetConfigDisplayName: 'Multi Icon',
    attributes: {
      elementsConfig: [],
      name: Page.MULTI_ICON_QUICK_LINK_WIDGET,
      type: 'links',
      slug: 'quick_links_icon',
      label: 'Quick Links Icons',
      css_config: {
        ...Constants.defaultBaseCssConfig,
        textAlignment: 'left',
        verticalAlignment: 'center',
        textFont: 'Metropolis',
        fontSize: 20,
        shadowBlur: 0,
        shadowSpread: 0,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        backGroundColor: '#fff',
        color: '#384F80',
      },
      widgetProperties: {
        textContent: '',
      },
      pageIndex: 0,
      zone: '',
      iconConfig: {
        leftIcon: '',
        rightIcon: '',
      },
      itemConfig: {
        itemTemplate: multiIconQuickLinkTemplate,
      },
    },
    isRemovable: true,
  },
  gridPosition: {
    x: 0,
    y: 1,
    minItemCols: 1,
    minItemRows: 1,
    rows: 3,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

const buttonGridPosition = {
  x: 0,
  y: 0,
  minItemCols: 1,
  minItemRows: 1,
  rows: 4,
  cols: 6,
  resizeEnabled: true,
  dragEnabled: true,
};

const buttonCssConfig = {
  ...Constants.defaultBaseCssConfig,
  textAlignment: 'center',
  verticalAlignment: 'center',
  shadowBlur: 0,
  shadowSpread: 0,
  shadowOffsetX: 0,
  shadowOffsetY: 0,
  iconSize: 24,
};
export const backButtonWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: cryptoRandomUUID(),
  name: WidgetNames.BACK_BUTTON_WIDGET,
  widgetConfiguration: {
    widgetDisplayName: 'Back Navigation Button',
    widgetConfigDisplayName: WidgetTitles.BACK_BUTTON_WIDGET,
    attributes: {
      elementsConfig: [],
      name: WidgetNames.BACK_BUTTON_WIDGET,
      type: 'button',
      slug: 'back_button',
      label: 'Back Navigation Button',
      css_config: buttonCssConfig,
      widgetProperties: {
        textContent: '',
        icon: 'arrow-left',
      },
      pageIndex: 0,
      zone: '',
    },
    isRemovable: true,
  },
  gridPosition: buttonGridPosition,
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const otpVerificationWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: WidgetNames.OTP_VERIFICATION,
  name: WidgetNames.OTP_VERIFICATION,
  widgetConfiguration: {
    widgetDisplayName: WidgetTitles.OTP_VERIFICATION,
    widgetConfigDisplayName: WidgetTitles.OTP_VERIFICATION,
    nestedWidgets: otpVerificationNestedWidgets,
    attributes: {
      elementsConfig: [],
      name: WidgetNames.OTP_VERIFICATION,
      label: WidgetTitles.OTP_VERIFICATION,
      css_config: Constants.defaultBaseCssConfig,
      widgetProperties: {
        textContent: '',
        widgetContext: '',
        inputFieldSettings: {
          css_config: {
            ...defaultInputCssConfig,
            inputHeight: 39,
            inputWidth: 20,
          },
          label: '',
        },
      },
      pageIndex: 0,
      zone: '',
    },
    isRemovable: true,
  },
  gridPosition: {
    x: 0,
    y: 1,
    minItemCols: 1,
    minItemRows: 1,
    rows: 3,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const otpVerificationInternationalConfig = {
  widgetTypeConfig: 'new',
  uniqueId: WidgetNames.OTP_VERIFICATION_INTERNATIONAL_PAYMENTS,
  name: WidgetNames.OTP_VERIFICATION_INTERNATIONAL_PAYMENTS,
  widgetConfiguration: {
    widgetDisplayName: WidgetTitles.OTP_VERIFICATION_INTERNATIONAL_PAYMENTS,
    widgetConfigDisplayName: WidgetTitles.OTP_VERIFICATION_INTERNATIONAL_PAYMENTS,
    nestedWidgets: otpVerificationInternationalNestedWidgets,
    attributes: {
      elementsConfig: [],
      name: WidgetNames.OTP_VERIFICATION_INTERNATIONAL_PAYMENTS,
      label: WidgetTitles.OTP_VERIFICATION_INTERNATIONAL_PAYMENTS,
      css_config: Constants.defaultBaseCssConfig,
      widgetProperties: {
        textContent: '',
        widgetContext: '',
        inputFieldSettings: {
          css_config: defaultInputCssConfig,
          label: '',
        },
      },
      pageIndex: 0,
      zone: '',
    },
    isRemovable: true,
  },
  gridPosition: {
    x: 0,
    y: 1,
    minItemCols: 1,
    minItemRows: 1,
    rows: 3,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const forwardButtonWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: WidgetNames.FORWARD_BUTTON_WIDGET,
  name: WidgetNames.FORWARD_BUTTON_WIDGET,
  widgetConfiguration: {
    widgetDisplayName: 'Forward Navigation Button',
    widgetConfigDisplayName: WidgetTitles.FORWARD_BUTTON_WIDGET,
    attributes: {
      elementsConfig: [],
      name: WidgetNames.FORWARD_BUTTON_WIDGET,
      type: 'button',
      slug: 'forward_button',
      label: 'Forward Navigation Button',
      css_config: buttonCssConfig,
      widgetProperties: {
        textContent: '',
        icon: 'arrow-right',
      },
      pageIndex: 0,
      zone: '',
    },
    isRemovable: true,
  },
  gridPosition: buttonGridPosition,
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const lottieAnimationWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: WidgetNames.LOTTIE_ANIMATION,
  name: WidgetNames.LOTTIE_ANIMATION,
  widgetConfiguration: {
    widgetDisplayName: WidgetTitles.LOTTIE_ANIMATION,
    widgetConfigDisplayName: WidgetTitles.LOTTIE_ANIMATION,
    attributes: {
      elementsConfig: [],
      name: WidgetNames.LOTTIE_ANIMATION,
      type: 'animation',
      slug: 'lottie_animation',
      label: WidgetTitles.LOTTIE_ANIMATION,
      css_config: Constants.defaultBaseCssConfig,
      widgetProperties: {
        textContent: '',
        animationSource: 'Asset URL',
        assetType: 'url',
        assetUrl: 'https://lottie.host/96595b53-e70f-4fc0-9a64-c70a761afa1c/anknM6TrkU.json',
        animationType: 'loop',
      },
      pageIndex: 0,
      zone: '',
    },
    isRemovable: true,
  },
  gridPosition: {
    x: 0,
    y: 0,
    minItemCols: 1,
    minItemRows: 1,
    rows: 6,
    cols: 6,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const notificationWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: Math.floor(Math.random() * (1000000000 + 1)),
  name: WidgetNames.NOTIFICATION_WIDGET,
  widgetConfiguration: {
    widgetDisplayName: WidgetTitles.NOTIFICATION_WIDGET,
    widgetConfigDisplayName: WidgetTitles.NOTIFICATION_WIDGET,
    attributes: {
      elementsConfig: [],
      name: WidgetNames.NOTIFICATION_WIDGET,
      label: WidgetTitles.NOTIFICATION_WIDGET,
      css_config: {
        ...Constants.defaultBaseCssConfig,
        fontSize: 16,
        textFont: 'Mulish',
        textAlignment: 'space-between',
        borderRadiusTopLeft: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusBottomRight: 5,
        borderWidth: 1,
        borderColor: 'var(--border-color-1)',
        borderStyle: 'solid',
      },
      widgetProperties: {
        textContent: '<p class="m-0">Label here</p>',
        icon: '',
        notificationSettings: {
          channel: '',
          isActive: false,
          category: '',
          defaultToggle: DefaultToggleOption.OFF,
        },
      },
    },
    isRemovable: true,
  },
  gridPosition: {
    x: 0,
    y: 0,
    minItemCols: 1,
    minItemRows: 1,
    rows: 3,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const currencyAccountsListingWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: WidgetNames.CURRENCY_ACCOUNTS_LISTING_WIDGET,
  name: WidgetNames.CURRENCY_ACCOUNTS_LISTING_WIDGET,
  widgetConfiguration: {
    widgetDisplayName: WidgetTitles.CURRENCY_ACCOUNTS_LISTING_WIDGET,
    widgetConfigDisplayName: WidgetTitles.CURRENCY_ACCOUNTS_LISTING_WIDGET,
    attributes: {
      elementsConfig: [],
      name: WidgetNames.CURRENCY_ACCOUNTS_LISTING_WIDGET,
      label: WidgetTitles.CURRENCY_ACCOUNTS_LISTING_WIDGET,
      css_config: {
        ...Constants.defaultBaseCssConfig,
        fontSize: 16,
        textFont: 'Mulish',
        textAlignment: 'space-between',
        borderRadiusTopLeft: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusBottomRight: 5,
        borderWidth: 0,
        borderColor: 'var(--border-color-1)',
        borderStyle: 'solid',
      },
      widgetProperties: {
        textContent: currencyAccountListItemTemplate,
        icon: 'search',
        inputSettings: {
          isAllowed: true,
          label: 'Search for Accounts',
          isIconAllowed: true,
          css_config: {
            iconSize: 24,
            iconSizeUnit: 'px',
          },
        },
      },
      contextSwitch: true,
      pagingEnabled: true,
      pageSize: '10',
    },
    isRemovable: true,
  },
  gridPosition: {
    x: 0,
    y: 0,
    minItemCols: 1,
    minItemRows: 1,
    rows: 15,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export function generateFeeClientAccountsListingWidgetConfig(): WorkflowWidgetConfig {
  const widgetConfig = JSON.parse(JSON.stringify(currencyAccountsListingWidgetConfig));
  widgetConfig.name = WidgetNames.FEE_CLIENT_ACCOUNT_LISTING_WIDGET;
  widgetConfig.uniqueId = WidgetNames.FEE_CLIENT_ACCOUNT_LISTING_WIDGET;
  widgetConfig.widgetConfiguration.attributes = {
    ...widgetConfig.widgetConfiguration.attributes,
    navigationOption: 'use-as-navigation',
    role: DummyRole.EMPTY_ROLE,
    zone: APP_ZONES.FEE_MANAGEMENT,
    pageUrl: 'client-fee-charge-add-edit-fee',
  };
  delete widgetConfig.widgetConfiguration.attributes.contextSwitch;
  return widgetConfig;
}

export const addEditFeeWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: WidgetNames.ADD_EDIT_FEE,
  name: WidgetNames.ADD_EDIT_FEE,
  widgetConfiguration: {
    widgetDisplayName: WidgetTitles.ADD_EDIT_FEE,
    widgetConfigDisplayName: WidgetTitles.ADD_EDIT_FEE,
    attributes: {
      elementsConfig: [],
      name: WidgetNames.ADD_EDIT_FEE,
      label: WidgetTitles.ADD_EDIT_FEE,
      css_config: {
        ...Constants.defaultBaseCssConfig,
        fontSize: 16,
        textFont: 'Mulish',
        textAlignment: 'space-between',
        borderRadiusTopLeft: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusBottomRight: 5,
        borderWidth: 0,
        borderColor: 'var(--border-color-1)',
        borderStyle: 'solid',
      },
      widgetProperties: {
        textContent: '',
        icon: 'search',
        inputFieldSettings: {
          isAllowed: false,
          label: 'Search for Accounts',
          isIconAllowed: false,
          css_config: {
            backgroundColor: 'var(--palette-color-6)',
            inactiveBorderColor: 'var(--border-color-2)',
            inactiveBackgroundColor: 'var(--palette-color-6)',
            borderRadiusTopLeft: 5,
            borderRadiusTopRight: 5,
            borderRadiusBottomLeft: 5,
            borderRadiusBottomRight: 5,
            borderWidth: 1,
            borderColor: 'var(--border-color-1)',
            inputTextColor: 'var(--typography-color-0)',
            borderStyleUnit: 'px',
            inputHeight: 44,
            inputWidth: 100,
            iconSize: 24,
            iconSizeUnit: 'px',
          },
          addEditfeeInputsConfiguration: {
            feeNameAndCategory: {
              widgetConfigDisplayName: 'Fee name and Category - Label',
              selectGroupLabel: 'Select group',
              selectGroupDescriptionText: 'Select the group for which you want to change the fee management.',
              selectCurrencyLabel: 'Select currency',
              setFeeNameHeader: 'Set Fee Name',
              feeNameLabel: 'Fee Name',
            },
            unitCharge: {
              widgetConfigDisplayName: 'Unit Charge - Labels',
              setUnitChargeHeader: 'Set Unit Charge',
              setUnitChargeLabel: 'Unit Charge',
              setFeePercentageLabel: 'Set Fee Percentage (%)',
              setMinimumFeeAmountLabel: 'Set Minimum Fee Amount',
              setMaximumFeeAmountLabel: 'Set Maximum Fee Amount',
              setFeeAmountLabel: 'Set Fee Amount',
              unitChargeDescription:
                '(Note: Depending on the unit charge selected, the fields will vary accordingly.)',
            },
            frequencyForExecution: {
              widgetConfigDisplayName: 'Frequency - Labels',
              setFrequencyHeader: 'Set Frequency for execution',
              setFrequencyLabel: 'Frequency',
              effectiveFromLabel: 'Effective from:',
              effectiveFromStartDateLabel: 'Effective from (start date):',
              endDateLabel: 'End date (optional):',
              dayOfTheWeekLabel: 'Day of the week',
              dayOfTheMonthLabel: 'Day of the month',
              startQuarterFromLabel: 'Start quarter from:',
              dayOfTheSelectedMonthLabel: 'Day of the selected month',
              monthOfTheYearLabel: 'Month of the year',
              frequencyDescription:
                '(Note: The fee will be executed on the last day of the month if the selected day exceeds the number of days in that month. Additionally, if the trigger date falls before the effective date, the fee will be charged in the next relevant cycle.)',
            },
          },
        },
      },
    },
    isRemovable: true,
  },
  gridPosition: {
    x: 1,
    y: 3,
    minItemCols: 1,
    minItemRows: 1,
    rows: 24,
    cols: 45,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export function generateFeeConfigurationWidgetConfig(): WorkflowWidgetConfig {
  const widgetConfig = JSON.parse(JSON.stringify(addEditFeeWidgetConfig));
  widgetConfig.name = WidgetNames.FEE_CONFIGURATION_WIDGET;
  widgetConfig.uniqueId = WidgetNames.FEE_CONFIGURATION_WIDGET;
  widgetConfig.widgetConfiguration.widgetConfigDisplayName = 'Fee Configuration';
  const inputSetting = widgetConfig.widgetConfiguration.attributes.widgetProperties.inputFieldSettings;
  delete inputSetting.addEditfeeInputsConfiguration.feeNameAndCategory;
  inputSetting.addEditfeeInputsConfiguration.unitCharge = {
    ...inputSetting.addEditfeeInputsConfiguration.unitCharge,
    setFeeNameHeader: 'Set Fee Name',
    feeNameLabel: 'Fee Name',
  };
  widgetConfig.gridPosition.rows = 18;
  return widgetConfig;
}

export const addFeeGroupWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: WidgetNames.ADD_FEE_GROUP_WIDGET,
  name: WidgetNames.ADD_FEE_GROUP_WIDGET,
  widgetConfiguration: {
    widgetDisplayName: WidgetTitles.ADD_FEE_GROUP_WIDGET,
    widgetConfigDisplayName: WidgetTitles.ADD_FEE_GROUP_WIDGET,
    attributes: {
      elementsConfig: [],
      name: WidgetNames.ADD_FEE_GROUP_WIDGET,
      label: WidgetTitles.ADD_FEE_GROUP_WIDGET,
      css_config: {
        ...Constants.defaultBaseCssConfig,
        fontSize: 16,
        textFont: 'Mulish',
        textAlignment: 'space-between',
        borderRadiusTopLeft: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusBottomRight: 5,
        borderWidth: 0,
        borderColor: 'var(--border-color-1)',
        borderStyle: 'solid',
      },
      widgetProperties: {
        textContent: '',
        icon: 'search',
        inputFieldSettings: {
          isAllowed: false,
          label: 'Search for Accounts',
          isIconAllowed: false,
          css_config: {
            backgroundColor: 'transparent',
            inactiveBorderColor: 'var(--border-color-2)',
            inactiveBackgroundColor: '',
            borderRadiusTopLeft: 5,
            borderRadiusTopRight: 5,
            borderRadiusBottomLeft: 5,
            borderRadiusBottomRight: 5,
            borderWidth: 1,
            borderColor: 'var(--border-color-1)',
            inputTextColor: 'var(--typography-color-0)',
            borderStyleUnit: 'px',
            inputHeight: 44,
            inputWidth: 100,
            iconSize: 24,
            iconSizeUnit: 'px',
          },
          addFeeGroupFields: {
            groupNameLabel: 'Name Group',
            groupDescriptionLabel: 'Add Description',
          },
        },
      },
    },
    isRemovable: true,
  },
  gridPosition: {
    x: 1,
    y: 3,
    minItemCols: 1,
    minItemRows: 1,
    rows: 9,
    cols: 17,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const defaultPageConfigurations = commonPageConfigValue;
export const defaultModalConfiguration = {
  modalSizeMarginUnit: 'px',
  modalSizeWidth: 0,
  modalSizeHeight: 0,
  marginBottom: 0,
  closeExternalClick: false,
  closeAction: true,
  closeIconColour: '',
  closeIconUnit: 'px',
  closeIconSize: 32,
  backDropColor: '#000000',
  borderWidthUnit: 'px',
  borderRadiusUnit: 'px',
  borderRadiusTopLeft: 0,
  borderRadiusTopRight: 0,
  borderRadiusBottomLeft: 0,
  borderRadiusBottomRight: 0,
  shadowColour: '',
  shadowBlur: 0,
  shadowSpread: 0,
  shadowOffsetX: 0,
  shadowOffsetY: 0,
  url: '',
  pageType: 'dynamicGrid',
  isRemovable: true,
  mainComponentPageName: 'PageAsModalComponent',
} as ModalConfiguration;

export const customQuestionWidgetConfig = {
  widgetTypeConfig: 'new',
  name: WidgetNames.CUSTOM_QUESTION_WIDGET,
  uniqueId: cryptoRandomUUID(),
  widgetConfiguration: {
    isVisible: true,
    widgetDisplayName: '',
    widgetConfigDisplayName: 'Question Widget',
    attributes: {
      label: '',
      sub_heading: '',
      questionUniqueId: '',
      elementsConfig: [],
      css_config: {
        ...Constants.defaultBaseCssConfig,
        borderColor: 'var(--color-4)',
        borderRadiusBottomRight: 8,
        borderRadiusBottomLeft: 8,
        borderRadiusTopRight: 8,
        borderRadiusTopLeft: 8,
        borderRadiusUnit: 'px',
        borderStyle: 'solid',
        borderWidth: 1,
      },
      widgetProperties: {
        textContent: '',
      },
    },
    isRemovable: false,
  },
  gridPosition: {
    x: 0,
    y: 0,
    rows: 10,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const switchNavigatorCss = {
  textAlignment: 'center',
  verticalAlignment: 'center',
  textFont: 'var(--font-family)',
  fontSize: 15,
  shadowBlur: 0,
  shadowSpread: 0,
  shadowOffsetX: 0,
  shadowOffsetY: 0,
  borderWidth: 1,
  borderRadiusUnit: 'px',
  borderRadiusTopLeft: 10,
  borderRadiusBottomLeft: 10,
  borderRadiusTopRight: 10,
  borderRadiusBottomRight: 10,
  borderColor: 'var(--border-color-1)',
  paddingUnit: 'px',
  paddingTop: 10,
  paddingLeft: 10,
  paddingRight: 10,
  paddingBottom: 10,
  color: 'var(--palette-color-6)',
  backGroundColor: 'var(--palette-color-3)',
  disabledBgColor: 'var(--palette-color-6)',
  disabledTextColor: 'var(--palette-color-1)',
};

export const questionSummaryWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: Math.floor(Math.random() * (1000000000 + 1)),
  name: WidgetNames.QUESTION_SUMMARY_WIDGET,
  widgetConfiguration: {
    widgetDisplayName: 'Question Summary Widget',
    widgetConfigDisplayName: WidgetTitles.QUESTION_SUMMARY_WIDGET,
    attributes: {
      elementsConfig: [],
      name: WidgetNames.QUESTION_SUMMARY_WIDGET,
      css_config: {
        ...buttonCssConfig,
        color: 'var(--typography-color-0)',
        textAlignment: 'left',
      },
      widgetProperties: {
        textContent: '',
      },
    },
    isRemovable: true,
    isVisible: true,
  },
  gridPosition: {
    x: 0,
    y: 0,
    rows: 15,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
  popupType: WORKFLOW_POPUPS.QUESTION_SUMMARY_WIDGET,
} as WorkflowWidgetConfig;

export const fileUploadWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: Math.floor(Math.random() * (1000000000 + 1)),
  name: WidgetNames.FILE_UPLOAD_WIDGET,
  widgetConfiguration: {
    widgetDisplayName: WidgetTitles.FILE_UPLOAD_WIDGET,
    widgetConfigDisplayName: WidgetTitles.FILE_UPLOAD_WIDGET,
    attributes: {
      elementsConfig: [],
      name: WidgetNames.FILE_UPLOAD_WIDGET,
      uploadType: 'image',
      selectedEntityOption: ENTITY_TYPE.FACILITY,
      css_config: {
        ...buttonCssConfig,
        color: 'var(--palette-color-6)',
        backGroundColor: 'var(--palette-color-3)',
        textAlignment: 'center',
        borderRadiusUnit: 'px',
        borderRadiusTopLeft: 8,
        borderRadiusBottomLeft: 8,
        borderRadiusTopRight: 8,
        borderRadiusBottomRight: 8,
        paddingUnit: 'px',
        paddingTop: 20,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
      },
      widgetProperties: {
        textContent: '',
        icon: 'upload',
      },
      pageIndex: 0,
      zone: '',
      iconConfig: {
        leftIcon: '',
        rightIcon: '',
      },
    },
    isRemovable: true,
  },
  gridPosition: {
    x: 0,
    y: 0,
    minItemCols: 15,
    minItemRows: 20,
    rows: 20,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const profilePictureUploadWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: cryptoRandomUUID(),
  name: WidgetNames.PROFILE_PICTURE_UPLOAD_WIDGET,
  widgetConfiguration: {
    widgetDisplayName: WidgetTitles.PROFILE_PICTURE_UPLOAD_WIDGET,
    widgetConfigDisplayName: WidgetTitles.PROFILE_PICTURE_UPLOAD_WIDGET,
    attributes: {
      elementsConfig: [],
      name: WidgetNames.PROFILE_PICTURE_UPLOAD_WIDGET,
      uploadType: 'image',
      uploadFileLabel: 'Upload Profile Picture',
      uploadCTACustomization: 'Upload',
      uploadPreviewPillCustomization: '{{ filename }}',
      profilePictureConfiguration: {
        selectedState: '',
        formStateId: '',
        pictureKey: '',
      },
      css_config: {
        ...buttonCssConfig,
        borderRadiusUnit: 'px',
        borderRadiusTopLeft: 8,
        borderRadiusBottomLeft: 8,
        borderRadiusTopRight: 8,
        borderRadiusBottomRight: 8,
        paddingUnit: 'px',
        paddingTop: 15,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 15,
        color: 'var(--palette-color-6)',
        backGroundColor: 'var(--palette-color-3)',
        textAlignment: 'center',
      },
      widgetProperties: {
        icon: 'upload',
        textContent: '',
      },
      pageIndex: 0,
      zone: '',
      iconConfig: {
        rightIcon: '',
        leftIcon: '',
      },
    },
    isRemovable: true,
  },
  gridPosition: {
    x: 0,
    y: 0,
    rows: 6,
    cols: 13,
    minItemCols: 15,
    minItemRows: 20,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const verificationOrganisationCompanyNameWidget: WorkflowWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: WidgetNames.VERIFICATION_ORGANISATION_COMPANY_NAME,
  name: WidgetNames.VERIFICATION_ORGANISATION_COMPANY_NAME,
  widgetConfiguration: {
    isRemovable: false,
    widgetDisplayName: 'Company Name',
    widgetConfigDisplayName: WidgetTitles.VERIFICATION_ORGANISATION_COMPANY_NAME,
    attributes: {
      widgetProperties: {
        textContent: '',
        inputLabel: 'Company Name',
      },

      field_attributes: [
        {
          name: 'organisation_companyName',
          decision_config: { required: true },
          label: 'Company Name',
          type: 'text',
          options: [],
          additional_config: {},
        },
      ],
      elementsConfig: [],
      name: WidgetNames.VERIFICATION_ORGANISATION_COMPANY_NAME,
      label: WidgetTitles.VERIFICATION_ORGANISATION_COMPANY_NAME,
      css_config: {
        ...Constants.defaultBaseCssConfig,
      },
    },
  },
  gridPosition: {
    x: 0,
    y: 0,
    minItemCols: 1,
    minItemRows: 1,
    rows: 5,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
};

export const editAddressWidget: WorkflowWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: WidgetNames.EDIT_ADDRESS_WIDGET,
  name: WidgetNames.EDIT_ADDRESS_WIDGET,
  widgetConfiguration: {
    isRemovable: false,
    widgetDisplayName: WidgetTitles.ADDRESS_WIDGET,
    widgetConfigDisplayName: WidgetTitles.ADDRESS_WIDGET,
    attributes: {
      css_config: Constants.defaultBaseCssConfig,
      widgetProperties: {
        textContent: '',
        addressLabel: 'Address',
        cityLabel: 'City',
        stateRegionLabel: 'State/Region',
        countryLabel: 'Country',
        zipPostalLabel: 'ZIP/Postal Code',
        inputFieldSettings: {
          isIconAllowed: false,
          css_config: defaultInputCssConfig,
        },
      },
      elementsConfig: [],
    },
  },
  gridPosition: {
    x: 0,
    y: 4,
    rows: 50,
    cols: 15,
    dragEnabled: true,
    resizeEnabled: true,
  },
  widgetIconPath: 'generic-widget',
};

export const eventListingColumns = [
  {
    label: 'Event Details',
    value: 'name',
    handleBarKey: '{{name}}',
    selected: true,
  },
  {
    label: 'Embark',
    value: 'embarkLocation',
    handleBarKey: '{{embarkLocation}}',
    selected: true,
  },
  {
    label: 'Disembark',
    value: 'disembarkLocation',
    handleBarKey: '{{disembarkLocation}}',
    selected: true,
  },
  {
    label: 'Principal',
    value: 'facility.name',
    handleBarKey: '{{facility.name}}',
    selected: true,
  },
  {
    label: 'Event Start',
    value: `datePipe start format='shortTime'`,
    handleBarKey: "{{ datePipe start format='MMM d yy'  }}",
    selected: true,
  },
  {
    label: 'Event End',
    value: `datePipe  end  format='shortTime'`,
    handleBarKey: "{{ datePipe end format='MMM d yy'  }}",
    selected: true,
  },
];

export const employeeListingColumns = [
  {
    label: 'Name',
    value: 'firstName',
    handleBarKey: '{{firstName }}',
    selected: true,
  },
  {
    label: 'Position',
    value: 'position',
    handleBarKey: '{{position}}',
    selected: true,
  },
  {
    label: 'Events',
    value: 'events',
    handleBarKey: '{{events}}',
    selected: true,
  },
  {
    label: 'Phone Number',
    value: 'phoneNumber',
    handleBarKey: '{{phoneNumber}}',
    selected: true,
  },
  {
    label: 'Email',
    value: `email'`,
    handleBarKey: '{{ email}}',
    selected: true,
  },
];
export const landingAccountsListingWidgetConfig: WorkflowWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: WidgetNames.LANDING_ACCOUNTS_LISTING,
  name: WidgetNames.LANDING_ACCOUNTS_LISTING,
  widgetConfiguration: {
    widgetConfigDisplayName: 'Accounts Listing',
    widgetDisplayName: WidgetTitles.LANDING_ACCOUNTS_LISTING,
    attributes: {
      navigationOption: 'use-as-navigation',
      role: DummyRole.EMPTY_ROLE,
      zone: APP_ZONES.LANDING,
      pageUrl: 'home',
      elementsConfig: [],
      widgetProperties: {
        textContent: accountListingForSelectAccountTemplate,
        inputFieldSettings: {
          isAllowed: true,
          isIconAllowed: true,
          css_config: {
            iconSize: 24,
            iconSizeUnit: 'px',
          } as InputCssConfig,
          label: 'Search for Accounts',
        },
        icon: 'search',
      },
      css_config: Constants.defaultBaseCssConfig,
    },
    isRemovable: true,
  },
  gridPosition: {
    dragEnabled: true,
    resizeEnabled: true,
    x: 0,
    y: 3,
    rows: 25,
    cols: 15,
  },
  widgetIconPath: 'generic-widget',
};

export const accountsContextDropdownWidgetConfig: WorkflowWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: WidgetNames.ACCOUNTS_CONTEXT_DROPDOWN,
  name: WidgetNames.ACCOUNTS_CONTEXT_DROPDOWN,
  widgetConfiguration: {
    widgetConfigDisplayName: 'Account Context Drop-down',
    widgetDisplayName: WidgetTitles.ACCOUNTS_CONTEXT_DROPDOWN,
    attributes: {
      role: DummyRole.EMPTY_ROLE,
      zone: APP_ZONES.LANDING,
      elementsConfig: [],
      textContent: 'Accounts',
      iconConfig: {
        leftIcon: 'change-pin',
        rightIcon: '',
      },
      enableTotalWealth: false,
      showFlag: true,
      enableAccountContextSwitch: true,
      widgetVisualTemplate: accountSwitchWidgetVisualTemplate,
      widgetDropdownTemplate: accountSwitchDropdownTemplate,
      widgetProperties: {
        textContent: '',
        inputFieldSettings: {
          isIconAllowed: false,
          companyNameLabel: '',
          css_config: { ...defaultInputCssConfig, inputHeight: undefined },
        },
      },
      css_config: {
        ...Constants.defaultBaseCssConfig,
        backgroundColor: 'transparent',
        inactiveBorderColor: 'var(--border-color-2)',
        inactiveBackgroundColor: '',
        verticalAlignment: 'center',
        borderColor: 'var(--border-color-1)',
        borderStyle: 'solid',
        borderWidth: 1,
        height: 80,
        borderRadiusBottomRight: 10,
        borderRadiusBottomLeft: 10,
        borderRadiusTopRight: 10,
        borderRadiusTopLeft: 10,
        display: 'flex',
        paddingLeft: 10,
        paddingRight: 10,
        fontSize: 18,
        isTextBold: true,
        justifyContent: 'space-between',
        textAlignment: 'left',
        shadowBlur: 0,
        shadowSpread: 0,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
      },
    },
    isRemovable: true,
  },
  gridPosition: {
    dragEnabled: true,
    resizeEnabled: true,
    x: 0,
    y: 6,
    rows: 3,
    cols: 15,
  },
  widgetIconPath: 'generic-widget',
};

export const desktopManageUserListingWidgetConfig = createDesktopListWidget(
  WidgetNames.DESKTOP_MANAGE_USER_LISTING_WIDGET,
  WidgetTitles.DESKTOP_MANAGE_USER_LISTING_WIDGET,
  'manage-user',
  'User Listing Widget',
  ['05', '10', '20'],
  'p-datatable-lg',
);

export const desktopBulkPaymentListingWidgetConfig = createDesktopListWidget(
  WidgetNames.DESKTOP_BULK_PAYMENT_LISTING_WIDGET,
  WidgetTitles.DESKTOP_BULK_PAYMENT_LISTING_WIDGET,
  'bulk-payment-listing',
  'Bulk Payment Listing Widget',
  ['05', '10', '20'],
  'p-datatable-lg',
);
export const desktopBulkPaymentHistoryListingWidgetConfig = createDesktopListWidget(
  WidgetNames.DESKTOP_BULK_PAYMENT_HISTORY_LISTING_WIDGET,
  WidgetTitles.DESKTOP_BULK_PAYMENT_HISTORY_LISTING_WIDGET,
  'bulk-payment-history',
  'Bulk Payment History Listing Widget',
  ['05', '10', '20'],
  'p-datatable-lg',
);

function createDesktopListWidget(
  zone: string,
  title: string,
  entity: string,
  displayName: string,
  selectedPaginationOption: string[],
  selectTableSize?: string,
) {
  return {
    widgetTypeConfig: 'new',
    uniqueId: cryptoRandomUUID(),
    name: zone,
    widgetConfiguration: {
      widgetDisplayName: displayName,
      widgetConfigDisplayName: title,
      attributes: {
        elementsConfig: [],
        name: zone,
        css_config: { ...buttonCssConfig, color: 'rgba(251, 194, 94, 1)' },
        tableHeaderSetting: {
          css_config: defaultTableHeaderSettingCssConfig,
        },
        widgetProperties: {
          textContent: '',
          zoneToNavigate: '',
          urlToNavigate: '',
          entityInformation: {
            entity: entity,
            dateFromKey: 'start',
            dateToKey: 'end',
          },
        },
        pageIndex: 0,
        zone: '',
        iconConfig: {
          leftIcon: '',
          rightIcon: '',
        },
        rowSettings: {
          rowBgColor: 'var(--palette-color-6)',
          showErrorDuplicationRowBgColor: false,
        },
        selectedListOption: '',
        viewListOption: '',
        tableGridSetting: {
          showGridLines: false,
          gridLinesColor: 'var(--border-color-1)',
        },
        viewPaginationOption: selectedPaginationOption,
        viewTableSize: selectTableSize,
        columns: [],
      },
      isRemovable: true,
    },
    gridPosition: {
      x: 0,
      y: 7,
      minItemCols: 15,
      minItemRows: 30,
      rows: 30,
      cols: 39,
      resizeEnabled: true,
      dragEnabled: true,
    },
    widgetIconPath: 'generic-widget',
  } as WorkflowWidgetConfig;
}

export const addEmployeeStep1WidgetsConfig = {
  widgetTypeConfig: 'new',
  uniqueId: cryptoRandomUUID(),
  name: WidgetNames.ADD_EMPLOYEE_STEP_1_WIDGETS,
  widgetConfiguration: {
    widgetDisplayName: WidgetTitles.ADD_EMPLOYEE_STEP_1_WIDGETS,
    widgetConfigDisplayName: WidgetTitles.ADD_EMPLOYEE_STEP_1_WIDGETS,
    attributes: {
      elementsConfig: [],
      widgetProperties: {
        textContent: '',
        inputFieldSettings: {
          css_config: defaultInputCssConfig,
        },
      },
      label: '',
      sub_heading: '',
      show: true,
      decision_config: {
        required: true,
      },
      field_attributes: [
        //-----------name-widget-------------
        {
          name: 'first_name',
          decision_config: {
            required: true,
          },
          label: 'First Name *',
          type: 'text',
          options: [],
          additional_config: {},
        },
        {
          name: 'middle_name',
          decision_config: { required: false },
          label: 'Middle Name',
          type: 'text',
          options: [],
          additional_config: {},
        },
        {
          name: 'last_name',
          decision_config: { required: true },
          label: 'Last Name *',
          type: 'text',
          options: [],
          additional_config: {},
        },
        //-----------name-widget-------------

        //-----------phone-widget-------------
        {
          name: 'country_code',
          label: 'Country Code',
          type: 'dropdown',
          options: [{ country_codes_list: [] }],
          decision_config: {
            required: true,
          },
        },
        {
          name: 'phone',
          label: 'Phone',
          type: 'number',
          options: [],
          decision_config: {
            required: true,
            pattern: true,
            verifyViaOTP: true,
          },
          additional_config: {},
        },
        //-----------phone-widget-------------
      ],
      css_config: getZoneSpecificCSSConfig(),
    },
    isRemovable: true,
  },
  gridPosition: {
    x: 0,
    y: 7,
    minItemCols: 15,
    minItemRows: 30,
    rows: 30,
    cols: 39,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: '',
} as WorkflowWidgetConfig;

export const userNameWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: cryptoRandomUUID(),
  name: WidgetNames.USER_NAME_WIDGET,
  widgetConfiguration: {
    isVisible: true,
    widgetDisplayName: WidgetTitles.USER_NAME_WIDGET,
    widgetConfigDisplayName: WidgetTitles.USER_NAME_WIDGET,
    isRemovable: false,
    attributes: {
      elementsConfig: [],
      widgetProperties: {
        textContent: '',
        inputFieldSettings: {
          css_config: defaultInputCssConfig,
        },
      },
      label: 'User name',
      sub_heading: 'Enter your User name',
      show: true,
      decision_config: {
        required: true,
      },
      field_attributes: [
        {
          name: 'userName',
          decision_config: {
            required: true,
          },
          label: 'User name',
          type: 'text',
          options: [],
          additional_config: {},
        },
      ],
      css_config: {
        ...Constants.defaultBaseCssConfig,
        textFont: 'var(--font-family)',
      },
    },
  },
  gridPosition: {
    y: 6,
    x: 0,
    rows: 3,
    cols: 13,
    dragEnabled: true,
    resizeEnabled: true,
  },
  widgetIconPath: '',
} as WorkflowWidgetConfig;

export const addEmployeeStep2WidgetsConfig = {
  widgetTypeConfig: 'new',
  uniqueId: cryptoRandomUUID(),
  name: WidgetNames.ADD_EMPLOYEE_STEP_2_WIDGETS,
  widgetConfiguration: {
    widgetDisplayName: WidgetTitles.ADD_EMPLOYEE_STEP_2_WIDGETS,
    widgetConfigDisplayName: WidgetTitles.ADD_EMPLOYEE_STEP_2_WIDGETS,
    attributes: {
      elementsConfig: [],
      widgetProperties: {
        textContent: '',
        inputFieldSettings: {
          css_config: defaultInputCssConfig,
        },
      },
      label: '',
      sub_heading: '',
      show: true,
      decision_config: {
        required: true,
      },
      field_attributes: [
        //-----------user-name-widget-------------
        {
          name: 'userName',
          decision_config: {
            required: true,
          },
          label: 'Username',
          type: 'text',
          options: [],
          additional_config: {},
        },
        //-----------user-name-widget-------------

        //-----------password-widget-------------
        {
          name: 'password',
          decision_config: {
            required: true,
          },
          label: 'Password',
          type: 'password',
          options: [],
          additional_config: {},
        },
        {
          name: 'confirm_password',
          decision_config: {
            required: true,
          },
          label: 'Confirm password',
          type: 'password',
          options: [],
          additional_config: {},
        },
        //-----------password-widget-------------
      ],
      css_config: getZoneSpecificCSSConfig(),
    },
    isRemovable: true,
  },
  gridPosition: {
    x: 0,
    y: 7,
    minItemCols: 15,
    minItemRows: 30,
    rows: 30,
    cols: 39,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: '',
} as WorkflowWidgetConfig;

export const profilePicDisplayWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: WidgetNames.PROFILE_PICTURE_DISPLAY,
  name: WidgetNames.PROFILE_PICTURE_DISPLAY,
  widgetConfiguration: {
    widgetDisplayName: 'Profile Picture Display',
    widgetConfigDisplayName: WidgetTitles.PROFILE_PICTURE_DISPLAY,
    isRemovable: true,
    attributes: {
      elementsConfig: [],
      css_config: {
        ...Constants.defaultBaseCssConfig,
        backGroundColor: 'var(--color-0)',
        color: 'var(--color-6)',
        textAlignment: 'center',
        verticalAlignment: 'center',
        borderRadiusUnit: '%',
        borderRadiusTopLeft: 100,
        borderRadiusBottomLeft: 100,
        borderRadiusTopRight: 100,
        borderRadiusBottomRight: 100,
      },
      widgetProperties: {
        textContent: 'Profile Pic Display',
      },
    },
    isWidgetPage: true,
  },
  gridPosition: {
    x: 0,
    y: 0,
    minItemCols: 15,
    minItemRows: 4,
    rows: 4,
    cols: 5,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const conditionalWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: cryptoRandomUUID(),
  name: WidgetNames.CONDITIONAL_WIDGET,
  widgetConfiguration: {
    widgetDisplayName: WidgetTitles.CONDITIONAL_WIDGET,
    widgetConfigDisplayName: WidgetTitles.CONDITIONAL_WIDGET,
    nestedWidgets: conditionalNestedWidgets,
    attributes: {
      elementsConfig: [],
      name: WidgetNames.CONDITIONAL_WIDGET,
      type: 'links',
      slug: '',
      label: '',
      css_config: {
        ...Constants.defaultBaseCssConfig,
        textAlignment: 'left',
        verticalAlignment: 'center',
        textFont: 'Metropolis',
        fontSize: 20,
        shadowBlur: 0,
        shadowSpread: 0,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        backGroundColor: '#fff',
        color: '#384F80',
      },
      widgetProperties: {
        textContent: '',
      },
    },
    isRemovable: true,
  },
  gridPosition: {
    x: 0,
    y: 1,
    minItemCols: 1,
    minItemRows: 1,
    rows: 3,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const travelEmployeeListingColumns = [
  {
    label: 'Name',
    value: 'firstName',
    handleBarKey: '<b>{{firstName }} {{lastName }}</b>',
    selected: true,
  },
  {
    label: 'Position',
    value: 'position',
    handleBarKey: '{{extraAttributes.position}}',
    selected: true,
  },
  {
    label: 'Events Linked',
    value: 'events',
    handleBarKey: '{{#if eventsLinked.length}} {{eventsLinked.0.name}} +{{eventsLinked.length}} {{/if}}',
    selected: true,
  },
  {
    label: 'Phone Number',
    value: 'phoneNumber',
    handleBarKey: '{{phoneNumber}}',
    selected: true,
  },
  {
    label: 'Email',
    value: `email'`,
    handleBarKey: '{{ email}}',
    selected: true,
  },
];

export const bulkpaymentListing = [
  {
    label: 'Row',
    value: 'row',
    handleBarKey: '<b>{{row }} </b>',
    selected: true,
  },
  {
    label: 'Recipient / Org. Name',
    value: 'name',
    handleBarKey: '{{name}}',
    selected: true,
  },
  {
    label: 'Sort Code',
    value: 'beneficiaryBankAccounts.0.sort_code',
    handleBarKey: '{{beneficiaryBankAccounts.0.sort_code}}',
    selected: true,
  },
  {
    label: 'A/C Number',
    value: 'beneficiaryBankAccounts.0.account_number',
    handleBarKey: '{{beneficiaryBankAccounts.0.account_number}}',
    selected: true,
  },
  {
    label: 'Type',
    value: `type`,
    handleBarKey: '<span class="text-capitalize"> {{type}}</span>',
    selected: true,
  },
  {
    label: 'Amount',
    value: `amount`,
    handleBarKey: '{{ currencyPipe amount currencyCode=currency }} ',
    selected: true,
  },
  {
    label: 'Schedule',
    value: `scheduleType`,
    handleBarKey: '<span class="text-capitalize"> {{ scheduleType}} </span>',
    selected: true,
  },
];

export const backNextButtonWidgetConfig: WorkflowWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: cryptoRandomUUID(),
  name: WidgetNames.ONBOARDING_BACK_NEXT_BUTTON,
  widgetConfiguration: {
    widgetConfigDisplayName: 'Button',
    widgetDisplayName: WidgetTitles.ONBOARDING_BACK_NEXT_BUTTON,
    attributes: {
      elementsConfig: [],
      textContent: 'button',
      widgetProperties: {
        textContent: '',
        icon: '',
      },
      css_config: {
        ...Constants.defaultBaseCssConfig,
        backGroundColor: 'var(--palette-color-3)',
        color: 'var(--palette-color-6)',
        textAlignment: 'center',
        verticalAlignment: 'center',
        paddingTop: 20,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
      },
    },
    isRemovable: true,
  },
  gridPosition: {
    dragEnabled: true,
    resizeEnabled: true,
    x: 0,
    y: 6,
    rows: 3,
    cols: 15,
  },
  widgetIconPath: 'generic-widget',
};

export const euCountryWidgetConfig: WorkflowWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: cryptoRandomUUID(),
  name: WidgetNames.EU_COUNTRY_DROPDOWN,
  widgetConfiguration: {
    widgetConfigDisplayName: 'Country List',
    widgetDisplayName: WidgetTitles.EU_COUNTRY_DROPDOWN,
    attributes: {
      elementsConfig: [],
      textContent: 'Country List',
      widgetProperties: {
        textContent: '',
        icon: '',
      },
      css_config: {
        ...Constants.defaultBaseCssConfig,
      },
    },
    isRemovable: true,
  },
  gridPosition: {
    dragEnabled: true,
    resizeEnabled: true,
    x: 0,
    y: 6,
    rows: 3,
    cols: 15,
  },
  widgetIconPath: 'generic-widget',
};

export function getBackNextButton(override: {
  icon: string;
  gridPosition: GridPosition;
  displayText: string;
  buttonType: 'next' | 'back';
  text?: string;
  shape?: 'rectangle' | 'circle' | 'square';
  styles?: any;
}) {
  return new OnboardingNextBackWidgetBuilder()
    .configDisplayName(override.displayText)
    .attributes({
      widgetProperties: {
        textContent: override.text ?? '',
        icon: override.icon,
        backgroundShape: override.shape ?? 'circle',
      } as BaseWidgetProperties,
      buttonType: override.buttonType,
    } as unknown as WorkflowWidgetConfigurationAttributes)
    .gridPosition(override.gridPosition)
    .cssConfig(override?.styles)
    .build();
}

export const documentPreviewWidgetConfig: WorkflowWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: cryptoRandomUUID(),
  name: WidgetNames.DOCUMENT_PREVIEW,
  widgetConfiguration: {
    widgetConfigDisplayName: 'Document Preview',
    widgetDisplayName: WidgetTitles.DOCUMENT_PREVIEW,
    attributes: {
      elementsConfig: [],
      textContent: 'Document Preview',
      widgetProperties: {
        textContent: '',
        icon: '',
      },
      css_config: {
        ...Constants.defaultBaseCssConfig,
      },
    },
    isRemovable: true,
  },
  gridPosition: {
    dragEnabled: true,
    resizeEnabled: true,
    x: 0,
    y: 6,
    rows: 3,
    cols: 15,
  },
  widgetIconPath: 'generic-widget',
};

export const reviewUploadBoardingPassComponentWidgetConfig: WorkflowWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: cryptoRandomUUID(),
  name: WidgetNames.REVIEW_UPLOAD_BOARDING_PASS,
  widgetConfiguration: {
    widgetConfigDisplayName: 'Document Preview',
    widgetDisplayName: WidgetTitles.DOCUMENT_PREVIEW,
    attributes: {
      elementsConfig: [],
      textContent: 'Document Preview',
      widgetProperties: {
        textContent: '',
        icon: '',
      },
      css_config: {
        ...Constants.defaultBaseCssConfig,
      },
    },
    isRemovable: true,
  },
  gridPosition: {
    dragEnabled: true,
    resizeEnabled: true,
    x: 0,
    y: 6,
    rows: 3,
    cols: 15,
  },
  widgetIconPath: '',
};

export const uploadFileWithPopupWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: cryptoRandomUUID(),
  name: WidgetNames.UPLOAD_FILE_WITH_POPUP_WIDGET,
  widgetConfiguration: {
    widgetDisplayName: WidgetTitles.UPLOAD_FILE_WITH_POPUP_WIDGET,
    widgetConfigDisplayName: WidgetTitles.UPLOAD_FILE_WITH_POPUP_WIDGET,
    attributes: {
      elementsConfig: [],
      name: WidgetNames.UPLOAD_FILE_WITH_POPUP_WIDGET,
      uploadType: 'image',
      css_config: {
        ...buttonCssConfig,
        color: 'var(--palette-color-6)',
        backGroundColor: 'var(--palette-color-3)',
        textAlignment: 'center',
        borderRadiusUnit: 'px',
        borderRadiusTopLeft: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusBottomRight: 5,
        paddingUnit: 'px',
        paddingTop: 20,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        fontSize: 16,
      },
      widgetProperties: {
        textContent: '',
        formKey: '',
      },
      pageIndex: 0,
      zone: '',
    },
    isRemovable: true,
  },
  gridPosition: {
    x: 0,
    y: 0,
    minItemCols: 15,
    minItemRows: 13,
    rows: 13,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
    layerIndex: 1,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

// Function to create a nested widget config
const createNestedWidgetConfig = (
  name: string,
  displayName: string,
  cssConfig: object,
  widgetProperties = {} as BaseWidgetProperties,
) =>
  ({
    name,
    widgetConfigDisplayName: displayName,
    attributes: {
      widgetProperties: {
        ...widgetProperties,
      },
      elementsConfig: [],
      css_config: cssConfig,
    },
  } as NestedWorkflowWidgetConfiguration);

export const revenirTransactionListWidgetConfig: WorkflowWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: cryptoRandomUUID(),
  name: WidgetNames.REVENIR_TRANSACTION_LIST,
  widgetConfiguration: {
    widgetConfigDisplayName: 'Transaction List',
    widgetDisplayName: WidgetTitles.REVENIR_TRANSACTION_LIST,
    nestedWidgets: {
      firstLetterConfig: createNestedWidgetConfig(
        WidgetNames.REVENIR_TRANSACTION_LIST_FIRST_CHARACTER,
        'First Letter Styling',
        {
          ...Constants.defaultUiNestedButtonCssConfig,
          paddingTop: 15,
          paddingLeft: 15,
          paddingRight: 15,
          paddingBottom: 15,
          backGroundColor: 'var(--palette-color-6)',
          color: 'var(--palette-color-3)',
          borderColor: 'var(--palette-color-3)',
          borderWidth: 4,
          borderRadiusTopLeft: 8,
          borderRadiusTopRight: 8,
          borderRadiusBottomLeft: 8,
          borderRadiusBottomRight: 8,
          borderRadiusUnit: 'px',
          fontSize: 26,
          isTextBold: true,
        },
      ),
    },
    attributes: {
      elementsConfig: [],
      textContent: 'Transaction List',
      transactionItemConfig: {
        showAllTransaction: true,
        toggleTitle: 'Show Accepted',
        toggleTitleTextStyle: 'body1',
        toggleActiveBgColour: 'var(--color-primary)',
        toggleInactiveBgColour: 'var(--color-on-primary-muted)',
        transactionHistoryItemTemplate: '',
        zoneToNavigate: '',
        urlToNavigate: '',
      },
      widgetProperties: {
        textContent: '',
        icon: '',
      },
      css_config: {
        ...Constants.defaultBaseCssConfig,
      },
    },
    isRemovable: true,
  },
  gridPosition: {
    dragEnabled: true,
    resizeEnabled: true,
    x: 0,
    y: 6,
    rows: 3,
    cols: 15,
  },
  widgetIconPath: 'generic-widget',
};

export const revenirTransactionItemListWidgetConfig: WorkflowWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: cryptoRandomUUID(),
  name: WidgetNames.REVENIR_TRANSACTION_ITEM_LIST,
  widgetConfiguration: {
    widgetConfigDisplayName: 'Transaction Item List',
    widgetDisplayName: WidgetTitles.REVENIR_TRANSACTION_ITEM_LIST,
    attributes: {
      elementsConfig: [],
      textContent: 'Transaction Item List',
      widgetProperties: {
        textContent: '',
        icon: '',
      },
      css_config: {
        ...Constants.defaultBaseCssConfig,
      },
    },
    isRemovable: true,
  },
  gridPosition: {
    dragEnabled: true,
    resizeEnabled: true,
    x: 0,
    y: 6,
    rows: 3,
    cols: 15,
  },
  widgetIconPath: '',
};

export const cardsWidgetConfig: WorkflowWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: Math.floor(Math.random() * (1000000000 + 1)),
  name: WidgetNames.CARDS_WIDGET,
  widgetConfiguration: {
    isRemovable: true,
    widgetDisplayName: WidgetTitles.CARDS_WIDGET,
    widgetConfigDisplayName: WidgetTitles.CARDS_WIDGET,
    nestedWidgets: cardsNestedWidgets,
    attributes: {
      cardLayoutType: 'individual',
      sortBy: 'date-added-newest-first',
      css_config: { ...Constants.defaultBaseCssConfig, backGroundColor: '' },

      widgetProperties: {
        textContent: 'textContent',
        subHeadingLabel: '',
      },
      elementsConfig: [],
    },
  },
  gridPosition: {
    x: 0,
    y: 0,
    rows: 7,
    cols: 15,
    dragEnabled: true,
    resizeEnabled: true,
  },
  widgetIconPath: 'generic-widget',
};
export const uploadPreviewFileWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: cryptoRandomUUID(),
  name: WidgetNames.DOCUMENT_UPLOAD_PREVIEW,
  widgetConfiguration: {
    widgetDisplayName: WidgetTitles.DOCUMENT_UPLOAD_PREVIEW,
    widgetConfigDisplayName: WidgetTitles.DOCUMENT_UPLOAD_PREVIEW,
    attributes: {
      elementsConfig: [],
      name: WidgetNames.DOCUMENT_UPLOAD_PREVIEW,
      uploadType: 'image',
      css_config: {
        ...buttonCssConfig,
        color: 'var(--palette-color-6)',
        backGroundColor: 'var(--palette-color-3)',
        textAlignment: 'center',
        borderRadiusUnit: 'px',
        borderRadiusTopLeft: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusBottomRight: 5,
        paddingUnit: 'px',
        paddingTop: 20,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        fontSize: 16,
      },
      widgetProperties: {
        textContent: '',
        formKey: '',
      },
      pageIndex: 0,
      zone: '',
    },
    isRemovable: true,
    nestedWidgets: {
      documentPreviewCss: {
        name: WidgetNames.DOCUMENT_PREVIEW,
        widgetConfigDisplayName: 'Document Preview Styling',
        attributes: {
          widgetProperties: {
            textContent: '',
          },
          elementsConfig: [],
          css_config: {
            ...Constants.defaultBaseCssConfig,
          },
        },
      },
    },
  },
  gridPosition: {
    x: 0,
    y: 0,
    minItemCols: 15,
    minItemRows: 13,
    rows: 13,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
    layerIndex: 1,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const revenirTransactionDocumentWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: cryptoRandomUUID(),
  name: WidgetNames.REVENIR_TRANSACTION_DOCUMENT,
  widgetConfiguration: {
    widgetDisplayName: WidgetTitles.REVENIR_TRANSACTION_DOCUMENT,
    widgetConfigDisplayName: WidgetTitles.REVENIR_TRANSACTION_DOCUMENT,
    attributes: {
      name: WidgetNames.REVENIR_TRANSACTION_DOCUMENT,
      elementsConfig: [],
      css_config: {
        ...buttonCssConfig,
        paddingUnit: 'px',
        borderRadiusUnit: 'px',
        borderRadiusTopLeft: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusBottomRight: 5,
      },
      widgetProperties: {
        textContent: '',
      },
    },
    isRemovable: true,
  },
  gridPosition: {
    x: 0,
    y: 0,
    rows: 13,
    cols: 15,
    minItemCols: 15,
    minItemRows: 13,
    resizeEnabled: true,
    dragEnabled: true,
    layerIndex: 1,
  },
  widgetIconPath: '',
} as WorkflowWidgetConfig;

export const cardSensitiveDetailsConfig: WorkflowWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: Math.floor(Math.random() * (1000000000 + 1)),
  name: WidgetNames.CARD_SENSITIVE_DETAILS,
  widgetConfiguration: {
    isRemovable: true,
    widgetDisplayName: WidgetTitles.CARD_SENSITIVE_DETAILS,
    widgetConfigDisplayName: WidgetTitles.CARD_SENSITIVE_DETAILS,
    attributes: {
      css_config: {
        ...Constants.defaultBaseCssConfig,
        backGroundColor: '',
        iconColor: 'var(--typography-color-1)',
        iconSize: 24,
        fontSize: 16,
        fontUnit: 'px',
        iconUnit: 'px',
      },

      widgetProperties: {
        textContent: '',
        subHeadingLabel: '',
        icon: 'mat_copy',
        labelTextColor: 'var(--typography-color-1)',
        detailsTextColor: 'var(--typography-color-0)',
      },
      elementsConfig: [],
    },
  },
  gridPosition: {
    x: 0,
    y: 0,
    rows: 7,
    cols: 15,
    dragEnabled: true,
    resizeEnabled: true,
  },
  widgetIconPath: 'generic-widget',
};

// Reusable CSS configurations
const defaultButtonCssConfig = {
  ...Constants.defaultUiNestedButtonCssConfig,
  paddingTop: 6,
  paddingLeft: 8,
  paddingRight: 8,
  paddingBottom: 6,
  marginTop: 8,
  marginRight: 4,
  marginBottom: 0,
  marginLeft: 0,
  borderRadiusTopLeft: 36,
  borderRadiusBottomLeft: 36,
  borderRadiusTopRight: 36,
  borderRadiusBottomRight: 36,
  color: 'var(--color-background)',
  textFont: 'var(--font-family)',
  fontSize: 16,
};

export const revenirArchivedTripsWidgetConfig: WorkflowWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: cryptoRandomUUID(),
  name: WidgetNames.REVENIR_ARCHIVED_TRIPS,
  widgetConfiguration: {
    widgetConfigDisplayName: 'Archived Trips',
    widgetDisplayName: WidgetTitles.REVENIR_ARCHIVED_TRIPS,
    nestedWidgets: {
      tripCardContent: createNestedWidgetConfig(
        WidgetNames.REVENIR_ARCHIVED_TRIP_CARD_CONTENT,
        'Trip Card Styling',
        {
          ...Constants.defaultUiNestedButtonCssConfig,
          marginBottom: 20,
          paddingTop: 0,
          paddingBottom: 0,
          backGroundColor: '',
          color: 'var(--color-background)',
          borderRadiusTopLeft: 12,
          borderRadiusBottomLeft: 12,
          borderRadiusTopRight: 12,
          borderRadiusBottomRight: 12,
        },
      ),
      countryNameContent: createNestedWidgetConfig(
        WidgetNames.REVENIR_ARCHIVED_TRIPS_COUNTRY_NAME_CONTENT,
        'Country Name Styling',
        {
          ...Constants.defaultUiNestedButtonCssConfig,
          textAlignment: 'start',
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 8,
          paddingRight: 8,
          backGroundColor: 'var(--color-on-background-muted)',
          color: 'var(--color-background)',
          borderRadiusTopLeft: 0,
          borderRadiusBottomLeft: 12,
          borderRadiusTopRight: 0,
          borderRadiusBottomRight: 12,
          fontSize: 19,
        },
        {
          textContent: '',
          textStyleClass: '',
          textStyleClassTwo: '',
        },
      ),
      refundApprovedStatus: createNestedWidgetConfig(
        WidgetNames.REVENIR_ARCHIVED_TRIP_STATUS_CONTENT,
        'Refund Approved Status',
        {
          ...defaultButtonCssConfig,
          backGroundColor: '#00D8BE',
        },
      ),
      refundFailedStatus: createNestedWidgetConfig(
        WidgetNames.REVENIR_ARCHIVED_TRIP_STATUS_CONTENT,
        'Refund Failed Status',
        {
          ...defaultButtonCssConfig,
          backGroundColor: '#D80000',
        },
      ),
      processingStatus: createNestedWidgetConfig(
        WidgetNames.REVENIR_ARCHIVED_TRIP_STATUS_CONTENT,
        'Processing Status',
        {
          ...defaultButtonCssConfig,
          backGroundColor: '#FFAF65',
        },
      ),
      refundPaidStatus: createNestedWidgetConfig(
        WidgetNames.REVENIR_ARCHIVED_TRIP_STATUS_CONTENT,
        'Refund Paid Status',
        {
          ...defaultButtonCssConfig,
          backGroundColor: '#00D8BE',
        },
      ),
    },
    attributes: {
      elementsConfig: [],
      textContent: 'Archived Trips',
      widgetProperties: {
        textContent: '',
        pageSize: 5,
        pagingEnabled: true,
      },
      css_config: {
        ...Constants.defaultBaseCssConfig,
      },
    },
    isRemovable: true,
  },
  gridPosition: {
    dragEnabled: true,
    resizeEnabled: true,
    x: 0,
    y: 6,
    rows: 3,
    cols: 15,
  },
  widgetIconPath: '',
};

const tripReclaimStatusCssConfig = {
  borderRadiusTopLeft: 8,
  borderRadiusBottomLeft: 8,
  borderRadiusTopRight: 8,
  borderRadiusBottomRight: 8,
  paddingTop: 5,
  paddingLeft: 15,
  paddingRight: 15,
  paddingBottom: 5,
  marginBottom: 0,
  backGroundColor: '#CED4DA',
};

export const revenirTripReclaimStatusWidgetConfig: WorkflowWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: cryptoRandomUUID(),
  name: WidgetNames.REVENIR_TRIP_RECLAIM_STATUS_WIDGET,
  widgetConfiguration: {
    widgetConfigDisplayName: 'Trip Reclaim Status',
    widgetDisplayName: WidgetTitles.REVENIR_TRIP_RECLAIM_STATUS_WIDGET,
    nestedWidgets: {
      refundApprovedStatus: createNestedWidgetConfig(
        WidgetNames.REVENIR_TRIP_RECLAIM_STATUS_CONTENT,
        'Refund Approved Status',
        {
          ...defaultButtonCssConfig,
          ...tripReclaimStatusCssConfig,
          iconSize: 25,
          iconColor: '#1ABB5E',
        },
        {
          textContent: 'Your refund has been approved and is in the process of being paid',
          icon: 'check-circle-common',
        },
      ),
      refundFailedStatus: createNestedWidgetConfig(
        WidgetNames.REVENIR_TRIP_RECLAIM_STATUS_CONTENT,
        'Refund Failed Status',
        {
          ...defaultButtonCssConfig,
          ...tripReclaimStatusCssConfig,
          iconSize: 25,
          iconColor: '#FF1414',
        },
        {
          textContent: 'Your refund has failed. Find out why',
          icon: 'alert-octagon',
        },
      ),
      processingStatus: createNestedWidgetConfig(
        WidgetNames.REVENIR_TRIP_RECLAIM_STATUS_CONTENT,
        'Processing Status',
        {
          ...defaultButtonCssConfig,
          ...tripReclaimStatusCssConfig,
          iconSize: 25,
          iconColor: '#FFFFFF',
        },
        {
          textContent: 'Your refund is being processed',
          icon: 'revenir-loading-icon',
        },
      ),
      refundPaidStatus: createNestedWidgetConfig(
        WidgetNames.REVENIR_TRIP_RECLAIM_STATUS_CONTENT,
        'Refund Paid Status',
        {
          ...defaultButtonCssConfig,
          ...tripReclaimStatusCssConfig,
          iconSize: 25,
          iconColor: '#1ABB5E',
        },
        {
          textContent: 'Your refund has been Paid',
          icon: 'check-circle-common',
        },
      ),
    },
    attributes: {
      elementsConfig: [],
      textContent: '',
      widgetProperties: {
        textContent: '',
      },
      css_config: {
        ...Constants.defaultBaseCssConfig,
      },
    },
    isRemovable: false,
  },
  gridPosition: {
    dragEnabled: true,
    resizeEnabled: true,
    x: 0,
    y: 6,
    rows: 3,
    cols: 15,
  },
  widgetIconPath: '',
};

export const revenirBarcodeImageViewerWidgetConfig: WorkflowWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: WidgetNames.REVENIR_BARCODE_IMAGE_VIEWER_COMPONENT,
  name: WidgetNames.REVENIR_BARCODE_IMAGE_VIEWER_COMPONENT,
  widgetConfiguration: {
    widgetConfigDisplayName: 'Barcode image viewer',
    widgetDisplayName: WidgetTitles.REVENIR_BARCODE_IMAGE_VIEWER_COMPONENT,
    attributes: {
      elementsConfig: [],
      textContent: ``,
      widgetProperties: {
        textContent: `{{revenirFormState.selectedTripDetails.countryName}} <br> VAT Reclaim Amount: {{ currencyPipe revenirFormState.selectedTripDetails.total_reclaim currencyCode=EUR display='symbol-narrow'}}`,
        icon: '',
      },
      css_config: {
        ...Constants.defaultBaseCssConfig,
      },
    },
    isRemovable: true,
  },
  gridPosition: {
    dragEnabled: true,
    resizeEnabled: true,
    x: 0,
    y: 9,
    rows: 7,
    cols: 15,
  },
  widgetIconPath: '',
};

export const uploadBoardingPassWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: cryptoRandomUUID(),
  name: WidgetNames.UPLOAD_BOARDING_PASS_WIDGET,
  widgetConfiguration: {
    widgetDisplayName: WidgetTitles.UPLOAD_BOARDING_PASS_WIDGET,
    widgetConfigDisplayName: WidgetTitles.UPLOAD_BOARDING_PASS_WIDGET,
    attributes: {
      elementsConfig: [],
      name: WidgetNames.UPLOAD_BOARDING_PASS_WIDGET,
      uploadType: 'image',
      css_config: {
        ...buttonCssConfig,
        color: 'var(--palette-color-6)',
        backGroundColor: 'var(--palette-color-3)',
        textAlignment: 'center',
        borderRadiusUnit: 'px',
        borderRadiusTopLeft: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusBottomRight: 5,
        paddingUnit: 'px',
        paddingTop: 20,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        fontSize: 16,
      },
      widgetProperties: {
        textContent: '',
        formKey: '',
      },
      pageIndex: 0,
      zone: '',
    },
    isRemovable: true,
  },
  gridPosition: {
    x: 0,
    y: 0,
    minItemCols: 15,
    minItemRows: 13,
    rows: 13,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
    layerIndex: 1,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const uploadPassportWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: cryptoRandomUUID(),
  name: WidgetNames.UPLOAD_PASSPORT_WIDGET,
  widgetConfiguration: {
    widgetDisplayName: WidgetTitles.UPLOAD_PASSPORT_WIDGET,
    widgetConfigDisplayName: WidgetTitles.UPLOAD_PASSPORT_WIDGET,
    attributes: {
      elementsConfig: [],
      name: WidgetNames.UPLOAD_PASSPORT_WIDGET,
      uploadType: 'image',
      css_config: {
        ...buttonCssConfig,
        color: 'var(--palette-color-6)',
        backGroundColor: 'var(--palette-color-3)',
        textAlignment: 'center',
        borderRadiusUnit: 'px',
        borderRadiusTopLeft: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusBottomRight: 5,
        paddingUnit: 'px',
        paddingTop: 20,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        fontSize: 16,
      },
      widgetProperties: {
        textContent: '',
        formKey: '',
      },
      pageIndex: 0,
      zone: '',
    },
    isRemovable: true,
  },
  gridPosition: {
    x: 0,
    y: 0,
    minItemCols: 15,
    minItemRows: 13,
    rows: 13,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
    layerIndex: 1,
  },
  widgetIconPath: '',
} as WorkflowWidgetConfig;

export const desktopPaymentRequestPendingListingWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: cryptoRandomUUID(),
  name: WidgetNames.DESKTOP_PAYMENT_REQUEST_PENDING_APPROVED_LISTING_WIDGET,
  widgetConfiguration: {
    widgetDisplayName: WidgetNames.DESKTOP_PAYMENT_REQUEST_PENDING_APPROVED_LISTING_WIDGET,
    widgetConfigDisplayName: WidgetTitles.DESKTOP_PAYMENT_REQUEST_PENDING_APPROVED_LISTING_WIDGET,
    nestedWidgets: {
      actionDropDown: {
        name: WidgetNames.DROPDOWN,
        widgetConfigDisplayName: 'Approval Selection Dropdown',
        isRemovable: true,
        attributes: {
          widgetProperties: {
            textContent: '',
          },
          elementsConfig: [],
          css_config: {
            ...Constants.defaultUiNestedButtonCssConfig,
            backGroundColor: 'var(--palette-color-6)',
            color: 'var(--typography-color-0)',
            borderWidth: 1,
            borderColor: 'var(--border-color-1)',
            marginLeft: 10,
            paddingTop: 3,
            paddingBottom: 3,
            textAlignment: 'left',
            verticalAlignment: 'center',
          },
        },
      },
      actionConfirmButton: {
        name: WidgetNames.BUTTON,
        widgetConfigDisplayName: 'Confirm Button',
        isRemovable: true,
        attributes: {
          widgetProperties: {
            textContent: 'Confirm',
          },
          elementsConfig: [],
          css_config: {
            ...Constants.defaultUiNestedButtonCssConfig,
            verticalAlignment: 'center',
            borderRadiusTopLeft: 15,
            borderRadiusBottomLeft: 15,
            borderRadiusTopRight: 15,
            borderRadiusBottomRight: 15,
            paddingRight: 20,
            paddingLeft: 20,
          },
        },
      },
    },
    attributes: {
      elementsConfig: [],
      name: WidgetNames.DESKTOP_PAYMENT_REQUEST_PENDING_APPROVED_LISTING_WIDGET,
      css_config: {
        ...Constants.defaultBaseCssConfig,
        textAlignment: 'center',
        verticalAlignment: 'center',
        color: 'var(--palette-color-1)',
        justifyContent: 'left',
        fontSize: '16',
        borderWidth: 1,
        borderColor: 'var(--palette-color-2)',
        backGroundColor: 'var(--palette-color-6)',
        borderStyle: 'solid',
        borderRadiusUnit: 'px',
        borderRadiusTopLeft: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusBottomRight: 5,
        shadowBlur: 0,
        shadowSpread: 0,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        iconSize: 24,
      },
      widgetProperties: {
        icon: 'search',
        textContent: '',
        showEntity: true,
        entityInformation: {
          entity: EntityType.PaymentRequest,
        },
      },
      pageSize: 10,
      selectedListOption: 'None',
      viewListOption: 'none',
      tableHeaderSetting: {
        css_config: defaultTableHeaderSettingCssConfig,
      },
      rowSettings: {
        rowBgColor: 'var(--palette-color-6)',
        showErrorDuplicationRowBgColor: false,
      },
      tableGridSetting: {
        showGridLines: false,
        gridLinesColor: 'var(--border-color-1)',
      },
      tableCheckboxSettings: {
        enableCheckbox: true,
        inputFieldSettings: { ...defaultTableCheckBoxCssConfig },
      },
      viewTableSize: 'p-datatable-lg',
      viewPaginationOption: ['05', '10', '20'],
      columns: [],
    },
    isRemovable: true,
  },
  gridPosition: {
    x: 1,
    y: 7,
    rows: 13,
    cols: 39,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: '',
} as WorkflowWidgetConfig;

export const secondaryIdentifierWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: cryptoRandomUUID(),
  name: WidgetNames.SECONDARY_IDENTIFIER,
  widgetConfiguration: {
    widgetDisplayName: WidgetTitles.SECONDARY_IDENTIFIER,
    widgetConfigDisplayName: WidgetTitles.SECONDARY_IDENTIFIER,
    nestedWidgets: {
      continueButton: {
        name: WidgetNames.BUTTON,
        widgetConfigDisplayName: 'Continue Button',
        attributes: {
          widgetProperties: {
            textContent: 'Continue',
            globalStyling: true,
            globalStylingClass: `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`,
          },
          elementsConfig: [],
          css_config: {
            ...Constants.defaultBaseCssConfig,
          },
        },
      },
    },
    attributes: {
      elementsConfig: [],
      name: WidgetNames.SECONDARY_IDENTIFIER,
      css_config: {},
      widgetProperties: {
        textContent: '',
        formKey: '',
      },
      pageIndex: 0,
      zone: '',
    },
    isRemovable: true,
  },
  gridPosition: {
    x: 0,
    y: 0,
    minItemCols: 1,
    minItemRows: 5,
    rows: 2,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
    layerIndex: 0,
  },
  widgetIconPath: '',
} as WorkflowWidgetConfig;

export const cardPinWidgetConfig: WorkflowWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: cryptoRandomUUID(),
  name: WidgetNames.CARD_PIN,
  widgetConfiguration: {
    isRemovable: true,
    widgetDisplayName: WidgetTitles.CARD_PIN,
    widgetConfigDisplayName: WidgetTitles.CARD_PIN,
    attributes: {
      css_config: { ...Constants.defaultBaseCssConfig },
      widgetProperties: {
        textContent: 'textContent',
      },
      elementsConfig: [],
    },
  },
  gridPosition: {
    x: 0,
    y: 0,
    rows: 7,
    cols: 15,
    dragEnabled: true,
    resizeEnabled: true,
  },
  widgetIconPath: '',
};

export const paymentRailSelectorWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: cryptoRandomUUID(),
  name: WidgetNames.PAYMENT_RAIL_SELECTOR_WIDGET,
  widgetConfiguration: {
    widgetDisplayName: WidgetTitles.PAYMENT_RAIL_SELECTOR_WIDGET,
    widgetConfigDisplayName: WidgetTitles.PAYMENT_RAIL_SELECTOR_WIDGET,
    attributes: {
      elementsConfig: [],
      name: WidgetNames.PAYMENT_RAIL_SELECTOR_WIDGET,
      css_config: {},
      widgetProperties: {
        textContent: '',
        inputLabel: 'Payment Rail',
      },
    },
    isRemovable: true,
  },
  gridPosition: {
    x: 0,
    y: 18,
    minItemCols: 1,
    minItemRows: 5,
    rows: 3,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
    layerIndex: 0,
  },
  widgetIconPath: '',
} as WorkflowWidgetConfig;

export const defaultConfirmationPreferences: ConfirmationPreferenceTypes[] = [
  ConfirmationPreferenceTypes.APP_PIN,
  ConfirmationPreferenceTypes.BIOMETRICS,
  ConfirmationPreferenceTypes.TOTP,
  ConfirmationPreferenceTypes.SMS_OTP,
  ConfirmationPreferenceTypes.EMAIL_OTP,
];
