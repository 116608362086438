import {
  APP_ZONES,
  BaseWidgetProperties,
  GridPosition,
  ListingTypeEnum,
  WorkflowWidgetConfig,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import { switchNavigatorCss } from '../../../defaults/widgets';
import {
  BackButtonWidgetBuilder,
  FeeClientListingWidgetBuilder,
  SwitchNavigatorWidgetBuilder,
} from '../../../defaults/widgets/example-builder';
import { inputSettings } from '../../../defaults/widgets/payments/payments.type';
import {
  generateTextWidget,
  getQuickLinkWidget,
} from '../../../defaults/zones/payments/common-widgets-utils';

export const feeListingColumns = [
  {
    label: 'Fee name',
    value: `feeName`,
    handleBarKey: '<span class="text-capitalize">{{feeName}}</span>',
    selected: true,
  },
  {
    label: 'Currency',
    value: `currency`,
    handleBarKey: '{{currency}}',
    selected: true,
  },
  {
    label: 'Amount/Percentage',
    value: `amountPercentage`,
    handleBarKey: `{{ feeChargeValue feeCharge unitChargeType currency }}`,
    selected: true,
  },
  {
    label: 'Unit charge',
    value: `unitCharge`,
    handleBarKey: '{{ valueToTitleChange unitChargeType}}',
    selected: true,
  },
  {
    label: 'Frequency',
    value: `frequency`,
    handleBarKey: '<span class="text-capitalize">{{feeFrequencySettings.frequencyType}}</span>',
    selected: true,
  },
  {
    label: 'Effective from',
    value: `effectiveFrom`,
    handleBarKey: "{{ datePipe feeFrequencySettings.startDate  format='d MMM y'}}",
    selected: true,
  },
  {
    label: 'Status',
    value: `status`,
    handleBarKey: '<span class="text-capitalize">{{ status}}</span>',
    selected: true,
  },
];
function commonHeaderWidget() {
  const commonHeaderWidget = [
    generateTextWidget({
      text: `{{ selectedClientDetails.name }} Fee Listing - Fee Management`,
      position: { x: 1, y: 0, rows: 1, cols: 28 } as GridPosition,
      displayName: 'Header Text',
      styles: {
        fontSize: 24,
        isTextBold: true,
        verticalAlignment: 'start',
        textAlignment: 'left',
        noAlignment: true,
      },
      isRemovable: true,
    }),

    generateTextWidget({
      text: `The list consists of all the fees charged to the clients' accounts. This is a client-based view of the fees charged.`,
      position: { x: 1, y: 1, rows: 1, cols: 31 } as GridPosition,
      displayName: 'Information Content',
      styles: {
        verticalAlignment: 'start',
        textAlignment: 'left',
        fontSize: 20,
        textFont: 'var(--font-family)',
        color: 'var(--typography-color-1)',
      },
      isRemovable: true,
    }),

    new BackButtonWidgetBuilder()
      .defaultBackButton()
      .configDisplayName('Back Navigation Button')
      .text('Back')
      .cssConfig({
        color: 'var(--palette-color-3)',
        backGroundColor: 'var(--palette-color-6)',
        borderWidth: 1,
        borderColor: 'var(--palette-color-3)',
        fontSize: 14,
        textAlign: 'center',
        verticalAlignment: 'center',
      })
      .gridPosition({
        y: 0,
        x: 37,
        rows: 1,
        cols: 4,
        resizeEnabled: true,
        dragEnabled: true,
      })
      .build(),

    getQuickLinkWidget({
      text: 'All Fee History',
      position: {
        x: 42,
        y: 0,
        rows: 1,
        cols: 5,
      } as GridPosition,
      pageUrl: 'charge-history-fee',
      displayName: 'Quick Link: View fee history',
      styles: {
        borderWidth: 1,
        color: 'var(--palette-color-3)',
        fontSize: '16',
        backGroundColor: 'var(--palette-color-6)',
        textAlignment: 'center',
        justifyContent: 'center',
        borderColor: 'var(--palette-color-3)',
        borderStyle: 'solid',
        borderRadiusTopLeft: 5,
        borderRadiusBottomLeft: 5,
        borderRadiusTopRight: 5,
        borderRadiusBottomRight: 5,
      },
      zone: APP_ZONES.FEE_MANAGEMENT,
      isRemovable: true,
    }),
  ];
  return commonHeaderWidget;
}

export function generateClientLevelFeeListPageWidgets(): WorkflowWidgetConfig[] {
  return [
    ...commonHeaderWidget(),
    generateTextWidget({
      text: `
      <div>
        <div class="text-label">Client Name</div>
        <div class="text-value">
          <b>{{#if selectedClientDetails.name}}{{ selectedClientDetails.name }}{{else}} -- {{/if}}</b>
        </div>
        </div>
      `,
      position: { x: 1, y: 5, rows: 2, cols: 8 } as GridPosition,
      displayName: 'Information Content',
      styles: {
        verticalAlignment: 'center',
        paddingLeft: 9,
        paddingRight: 9,
        borderWidth: 1,
        borderColor: 'var(--border-color-1)',
        borderRadiusTopLeft: 6,
        borderRadiusTopRight: 6,
        borderRadiusBottomRight: 6,
        borderRadiusBottomLeft: 6,
        textAlignment: 'left',
        fontSize: '16',
        lineHeight: '24',
        textFont: 'var(--font-family)',
        color: 'var(--typography-color-1)',
      },
      isRemovable: true,
    }),
    generateTextWidget({
      text: `
      <div>
        <div class="text-label">Number of accounts</div>
        <div class="text-value">
          <b>{{#if (stringPropertySet selectedClientDetails.accountsLinked)}}{{ selectedClientDetails.accountsLinked }}{{else}} -- {{/if}}</b>
        </div>
        </div>
      `,
      position: { x: 10, y: 5, rows: 2, cols: 8 } as GridPosition,
      displayName: 'Information Content',
      styles: {
        verticalAlignment: 'center',
        paddingLeft: 9,
        paddingRight: 9,
        borderWidth: 1,
        borderColor: 'var(--border-color-1)',
        borderRadiusTopLeft: 6,
        borderRadiusTopRight: 6,
        borderRadiusBottomRight: 6,
        borderRadiusBottomLeft: 6,
        textAlignment: 'left',
        fontSize: '16',
        lineHeight: '24',
        textFont: 'var(--font-family)',
        color: 'var(--typography-color-1)',
      },
      isRemovable: true,
    }),
    generateTextWidget({
      text: `
      <div>
        <div class="text-label">Group assigned</div>
        <div class="text-value">
          <b>{{#if (stringPropertySet selectedClientDetails.feeGroupName)}}{{ selectedClientDetails.feeGroupName }}{{else}} -- {{/if}}</b>
        </div>
        </div>
      `,
      position: { x: 19, y: 5, rows: 2, cols: 8 } as GridPosition,
      displayName: 'Information Content',
      styles: {
        verticalAlignment: 'center',
        paddingLeft: 9,
        paddingRight: 9,
        borderWidth: 1,
        borderColor: 'var(--border-color-1)',
        borderRadiusTopLeft: 6,
        borderRadiusTopRight: 6,
        borderRadiusBottomRight: 6,
        borderRadiusBottomLeft: 6,
        textAlignment: 'left',
        fontSize: '16',
        lineHeight: '24',
        textFont: 'var(--font-family)',
        color: 'var(--typography-color-1)',
      },
      isRemovable: true,
    }),
    generateTextWidget({
      text: `
      <div>
        <div class="text-label">Client type</div>
        <div class="text-value">
          {{#if (eq selectedClientDetails.clientType "personal" null) }}
            Individual {{else}} Business
          {{/if}}
        </div>
        </div>
      `,
      position: { x: 28, y: 5, rows: 2, cols: 8 } as GridPosition,
      displayName: 'Information Content',
      styles: {
        verticalAlignment: 'center',
        paddingLeft: 9,
        paddingRight: 9,
        borderWidth: 1,
        borderColor: 'var(--border-color-1)',
        borderRadiusTopLeft: 6,
        borderRadiusTopRight: 6,
        borderRadiusBottomRight: 6,
        borderRadiusBottomLeft: 6,
        textAlignment: 'left',
        fontSize: '16',
        lineHeight: '24',
        textFont: 'var(--font-family)',
        color: 'var(--typography-color-1)',
      },
      isRemovable: true,
    }),
    generateSwitchNavigatorConfig(),
    new FeeClientListingWidgetBuilder()
      .text('Listing Widget')
      .configDisplayName(`Listing Widget`)
      .gridPosition({ x: 1, y: 8, rows: 17, cols: 46 } as GridPosition)
      .cssConfig({
        color: 'var(--palette-color-1)',
        textAlignment: 'left',
        justifyContent: 'left',
        fontSize: '16',
        borderWidth: 1,
        borderColor: 'var(--palette-color-2)',
        backGroundColor: 'var(--palette-color-6)',
        borderStyle: 'solid',
        borderRadiusUnit: 'px',
      })
      .attributes({
        widgetProperties: {
          icon: 'search',
          textContent: ``,
          showEntity: false,
          memuOptions: [
            { label: 'Edit fee details', value: 'edit-fee-details' },
            { label: 'Remove fee', value: 'remove' },
          ],
          listingType: ListingTypeEnum.CLIENT_FEE_LISTING,
        } as BaseWidgetProperties,
        columns: feeListingColumns,
      } as unknown as WorkflowWidgetConfigurationAttributes)
      .inputSetting({
        ...inputSettings,
        css_config: {
          ...inputSettings.css_config,
          inputWidth: 120,
        },
        label: 'Search',
        isIconAllowed: true,
      })
      .isRemovable(false)
      .build(),

    generateTextWidget({
      text: 'Please navigate to the group-based view to view or add fees based on a group, or to see the client listings by group.',
      position: { x: 0, y: 26, rows: 1, cols: 32 } as GridPosition,
      displayName: 'Information Content',
      styles: {
        verticalAlignment: 'start',
        textAlignment: 'left',
        fontSize: '16',
        paddingLeft: 33,
        paddingTop: 5,
        lineHeight: '24',
        textFont: 'var(--font-family)',
        color: 'var(--typography-color-0)',
      },
      isRemovable: true,
    }),
  ];
}

export function generateSwitchNavigatorConfig(): WorkflowWidgetConfig {
  return new SwitchNavigatorWidgetBuilder()
    .linksData([
      {
        textContent: 'Fee List',
        pageUrl: 'clients-fee-list',
        zone: APP_ZONES.FEE_MANAGEMENT,
      },
      {
        textContent: 'Fee History',
        pageUrl: 'clients-fee-history',
        zone: APP_ZONES.FEE_MANAGEMENT,
      },
    ])
    .navigationOption('use-as-navigation')
    .role()
    .gridPosition({ x: 1, y: 3, rows: 2, cols: 12 } as GridPosition)
    .cssConfig({
      ...switchNavigatorCss,
      backGroundColor: 'var(--palette-color-3)',
      disabledTextColor: 'var(--palette-color-1)',
      fontSize: 14,
      borderColor: 'var(--palette-color-4)',
      paddingLeft: 17,
      paddingRight: 17,
      borderRadiusTopLeft: 6,
      borderRadiusBottomLeft: 6,
      borderRadiusTopRight: 6,
      borderRadiusBottomRight: 6,
    })
    .build();
}
