/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  BaseWidgetProperties,
  Constants,
  GridPosition,
  WidgetNames,
  WorkflowWidgetConfig,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import {
  BackButtonWidgetBuilder,
  ImageWidgetBuilder,
  QuickLinkWidgetBuilder,
  TextWidgetBuilder,
} from '../../defaults/widgets/example-builder';
const defaultCss = {
  color: 'var(--palette-color-3)',
  backGroundColor: 'var(--palette-color-6)',
  borderWidth: 1,
  borderColor: 'var(--palette-color-3)',
  fontSize: 14,
  paddingLeft: 20,
  paddingRight: 20,
  marginTop: 14,
  marginBottom: 14,
  textAlign: 'center',
  verticalAlignment: 'center',
};
export function addBackButton(gridPosition: GridPosition, css?: any): WorkflowWidgetConfig[] {
  return [
    new BackButtonWidgetBuilder()
      .defaultBackButton()
      .configDisplayName('Back Navigation Button')
      .cssConfig(css ?? defaultCss)
      .gridPosition(gridPosition)
      .build(),
  ];
}

export function generateImageWidgetConfig(override: {
  pageType: string;
  gridPosition: any;
  imageName: string;
}): WorkflowWidgetConfig {
  return new ImageWidgetBuilder()
    .configDisplayName(`Image Widget: ${override.pageType} Icon`)
    .cssConfig({
      selectedobjectfit: 'contain',
    })
    .gridPosition({
      y: 5,
      x: 17,
      rows: 4,
      cols: 5,
      ...override.gridPosition,
    } as GridPosition)
    .attributes({
      widgetProperties: {
        textContent: '',
        imageAssetUrl: `assets/images/${override.imageName}.svg`,
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .build();
}

export function generateTextWidgetConfig(override: {
  gridPosition: any;
  cssConfig: any;
  textContent: string;
  configDisplayName?: string;
}): WorkflowWidgetConfig {
  return new TextWidgetBuilder()
    .text(override.textContent)
    .gridPosition({
      y: 3,
      x: 0,
      rows: 2,
      cols: 15,
      ...override.gridPosition,
    } as GridPosition)
    .configDisplayName(
      `Text Widget: ${override?.configDisplayName ? override?.configDisplayName : override.textContent}`,
    )
    .cssConfig({
      ...override.cssConfig,
    })
    .build();
}

export function generateQuickLinkWidgetConfig(override: {
  gridPosition: any;
  cssConfig: any;
  textContent: string;
  navigateToPageUrl: string;
  zone: string;
}): WorkflowWidgetConfig {
  return new QuickLinkWidgetBuilder()
    .zone(override.zone)
    .navigateToPageUrl(override.navigateToPageUrl)
    .navigationOption('use-as-navigation')
    .role()
    .text(override.textContent)
    .configDisplayName(`Quick Link: ${override.textContent}`)
    .cssConfig({
      borderRadiusTopLeft: 5,
      borderRadiusBottomLeft: 5,
      borderRadiusTopRight: 5,
      borderRadiusBottomRight: 5,
      fontSize: 16,
      ...override.cssConfig,
    })
    .gridPosition({
      y: 16,
      x: 14,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 11,
      ...override.gridPosition,
    })
    .build();
}
export function getOtpVerificationWidgetConfig(
  config: WorkflowWidgetConfig,
  widgetName: WidgetNames,
): WorkflowWidgetConfig {
  config.name = widgetName;
  config.uniqueId = widgetName;
  config.widgetConfiguration.attributes['name'] = widgetName;
  config.widgetConfiguration.nestedWidgets = {
    ...config.widgetConfiguration.nestedWidgets,
    resendButton: {
      name: WidgetNames.BUTTON,
      widgetConfigDisplayName: 'Resend Button Styling',
      attributes: {
        widgetProperties: {
          textContent: 'Resend',
        },
        elementsConfig: [],
        css_config: {
          ...Constants.defaultUiNestedButtonCssConfig,
          backGroundColor: 'transparent',
          color: 'var(--palette-color-3)',
          textAlignment: 'center',
          isTextBold: true,
          isTextUnderline: true,
        },
      },
    },
  };
  return config;
}

export function removeSpacesAndCharactersFromPageName(pageName: string): string {
  if (pageName?.length)
    return pageName
      .replace(/[^a-zA-Z0-9\s]/g, '')
      .replace(/\s+/g, '-')
      .toLowerCase();
  else return '';
}
