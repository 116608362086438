export const paymentApproverPaymentSummaryTemplate = `<div class="w-100 h-100">
<div class="d-flex w-100">
  <div class="col-12">
      <div class="text-label">Amount</div>
      <div class="text-value">
      <b class="typog-h5"> {{ currencyPipe baseFormStateData.amount currencyCode=baseFormStateData.currency }} </b>
      </div>
  </div>
</div>
<hr class="my-4">
<div class="d-flex w-100">
    <div class="col-4">
      <div class="text-label">Payee</div>
      <div class="text-value"><b>{{ selectedBeneficiary.name }}</b></div>
    </div>

    <div class="col-4">
      <div class="text-label">Sort Code</div>
      <div class="text-value">{{ formatMask selectedBeneficiary.destinationIdentifier.sortCode '##-##-##' }}</div>
    </div>

    <div class="col-4">
      <div class="text-label">Account Number</div>
      <div class="text-value">{{ selectedBeneficiary.destinationIdentifier.accountNumber }}</div>
    </div>
</div>
<hr class="my-4">
<div class="d-flex w-100">
    <div class="col-4">
      <div class="text-label">From</div>
      <div class="text-value"><b>{{ customerName }}</b></div>
    </div>

    <div class="col-4">
      <div class="text-label">Sort Code</div>
      <div class="text-value">{{ sortCode}}</div>
    </div>

    <div class="col-4">
      <div class="text-label">Account Number</div>
      <div class="text-value">{{ accountNumber }}</div>
    </div>
</div>
<hr class="my-4">
<div class="d-flex w-100">
    <div class="col-4">
      <div class="text-label">Payment Reference</div>
      <div class="text-value">{{ baseFormStateData.paymentReference }}</div>
    </div>

    <div class="col-8">
      <div class="text-label">Payment Reason</div>
      <div class="text-value">{{ baseFormStateData.transferReasonIdText }}</div>
    </div>
</div>`;
