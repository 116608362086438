import {
  CanvasSubscription,
  ContainerType,
  DefaultConfig,
  PageTemplateConfig,
  Role,
  SupportedDevice,
  SystemPageTemplateConfig,
  SystemZoneDetail,
  UiPageConfig,
  UiPagePageConfiguration,
  UiZoneConfig,
  UiZoneSubscriptionsConfig,
  WorkflowPageConfig,
  WorkflowZoneDevicesConfig,
  defaultZoneConfiguration,
  is2dArray,
  isNewWorkflowWidgetConfig,
  isUiZoneConfig,
} from '@finxone-platform/shared/sys-config-types';
import { mapDefaultWidgetFunction, widgetToMappingFunctionMap } from './default-widget.utils';

export const removeSpaceFromRole = (role: string) => {
  try {
    return role.toLowerCase().replace(/[^A-Z0-9]+/gi, '-');
  } catch (error) {
    return '';
  }
};

export function formatUrl(url: string): string {
  if (url?.length)
    return url
      .replace(/[^a-zA-Z0-9\s]/g, '')
      .replace(/\s+/g, '-')
      .toLowerCase();
  else return '';
}

type individualZoneMappingFunction = (
  device: SupportedDevice,
  subscription: string,
  zone: string,
  role: Role,
) => UiZoneConfig;

// Needs updating once more than individual is being done
export function zoneMapping(
  zone: string,
  zoneTitle: string,
  roles: Role[],
  mappingFunction: individualZoneMappingFunction,
): SystemZoneDetail {
  let zoneDetail: any = {};

  zoneDetail = {
    title: zoneTitle,
    roles: {},
  };
  zoneDetail.roles = {};

  // for every role
  roles.forEach((role: Role) => {
    const roleSlug = role.slug;
    zoneDetail.roles[roleSlug] = mapZoneSubscriptionsAndDevice(zone, role, mappingFunction);
  });

  return zoneDetail as SystemZoneDetail;
}

export function mapZoneSubscriptionsAndDevice(
  zone: string,
  role: Role,
  mappingFunction: individualZoneMappingFunction,
): CanvasSubscription | UiZoneSubscriptionsConfig | undefined {
  const subscriptions =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    role.zones[zone]?.canvases !== undefined
      ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        Object.keys(role.zones[zone]!.canvases.subscription!)
      : undefined;
  if (subscriptions === undefined) return undefined;

  const canvasViews = subscriptions.map((subscription) => {
    // go through mobile and desktop canvas' and map
    const canvasDeviceViews = ['mobile', 'tablet', 'desktop'].map((device) => {
      const config = mappingFunction(device as SupportedDevice, subscription, zone, role);

      if (isUiZoneConfig(config)) {
        return {
          [device]: config,
        };
      } else {
        throw new Error(`Unable to detect workflow config type when mapping zone: ${zone}, role ${role}`);
      }
    });

    const config = {} as DefaultConfig;
    canvasDeviceViews.forEach((view) => {
      const view1 = view as unknown as UiZoneConfig;
      Object.assign(config, view1);
    });

    return {
      [subscription]: config,
    };
  });

  const subscriptionCanvases = {} as CanvasSubscription | UiZoneSubscriptionsConfig;
  canvasViews.forEach((canvas) => {
    Object.assign(subscriptionCanvases, canvas);
  });

  return subscriptionCanvases;
}

export function mapWorkflowZoneDevicesConfig(config: PageTemplateConfig): SystemPageTemplateConfig {
  const output = {
    mobile: {} as any,
    tablet: {} as any,
    desktop: {} as any,
  } as {
    [key in SupportedDevice]: UiPageConfig;
  };
  Object.keys(config).forEach((device) => {
    if (device === 'mobile' || device === 'tablet' || device === 'desktop') {
      const pageConfig = config[device];
      output[device] = mapPages([pageConfig])[0];
    }
  });
  return output;
}

export function mapZone(
  device: SupportedDevice,
  subscription: string,
  zone: string,
  role: Role,
): UiZoneConfig {
  const zoneDevicesConfig = role.zones[zone]?.canvases.subscription[
    subscription
  ] as WorkflowZoneDevicesConfig;
  if (zoneDevicesConfig) {
    const zoneConfig = zoneDevicesConfig[device];
    return {
      zoneConfiguration: zoneConfig?.zoneConfiguration
        ? zoneConfig.zoneConfiguration
        : defaultZoneConfiguration,
      pages: zoneConfig?.pages ? mapPages(zoneConfig.pages) : [],
    };
  } else {
    return {
      zoneConfiguration: defaultZoneConfiguration,
      pages: [],
    };
  }
}

export function mapChildComponents(childComponents: any[][] | any[]): any[][] | any[] {
  if (is2dArray(childComponents)) {
    const list = childComponents as any[][];
    return list?.map((widgets) => {
      return widgets.map((widget) => {
        const mappingFunction = widgetToMappingFunctionMap.get(widget.name) ?? mapDefaultWidgetFunction;
        return mappingFunction(widget);
      });
    });
  }
  const list = childComponents as any[];
  return list?.map((widget) => {
    const mappingFunction = widgetToMappingFunctionMap.get(widget.name) ?? mapDefaultWidgetFunction;
    return mappingFunction(widget);
  });
}

function mapPages(workflowPages: WorkflowPageConfig[]): UiPageConfig[] {
  return workflowPages.map((workflowPage) => {
    return {
      url: workflowPage.pageConfiguration.url,
      mainComponentName: workflowPage.pageConfiguration.mainComponentPageName,
      title: workflowPage.title,
      widgetLayouts: workflowPage.widgets.map((widget) => {
        const mappingFunction = widgetToMappingFunctionMap.get(widget.name) ?? mapDefaultWidgetFunction;
        return {
          ...mappingFunction(widget),
          childComponents:
            isNewWorkflowWidgetConfig(widget) && widget.childComponents
              ? mapChildComponents(widget.childComponents)
              : undefined,
        };
      }),
      pageType: workflowPage.pageConfiguration.pageType,
      pageTypeConfig: 'new',
      containerType: workflowPage?.containerType ?? ContainerType.PAGE,
      pageConfiguration: {
        gridsterMargin: workflowPage.pageConfiguration.gridsterMargin ?? {
          left: 1,
          right: 1,
          top: 2,
          unit: 'em',
        },
        isUsingHtmlStructure: workflowPage.pageConfiguration.isUsingHtmlStructure ?? false,
        showHeader: workflowPage.pageConfiguration.showHeader ?? true,
        labelsAsPlaceholder: workflowPage.pageConfiguration.labelsAsPlaceholder ?? false,
        isCustomGridActive: workflowPage.pageConfiguration.isCustomGridActive ?? false,
        isBodyScrollingEnabled: workflowPage.pageConfiguration.isBodyScrollingEnabled ?? undefined,
        backgroundColor: workflowPage.pageConfiguration.backgroundColor ?? undefined,
        backgroundImage: workflowPage.pageConfiguration?.backgroundImage,
        backgroundPosition: workflowPage.pageConfiguration?.backgroundPosition,
        backgroundSize: workflowPage.pageConfiguration?.backgroundSize,
        backgroundGradient: workflowPage.pageConfiguration?.backgroundGradient,
        fontsConfig: workflowPage.pageConfiguration?.fontsConfig,
        autoRedirectConfig: workflowPage.pageConfiguration?.autoRedirectConfig,
        pageHooks: workflowPage.pageConfiguration?.pageHooks,
        pageMargin: workflowPage.pageConfiguration?.pageMargin,
        url: workflowPage?.pageConfiguration?.url ?? workflowPage?.name,
        borderConfig: workflowPage.pageConfiguration?.borderConfig,
        modalConfiguration: workflowPage?.pageConfiguration?.modalConfiguration ?? {},
        hideNavigationMenu: workflowPage.pageConfiguration?.hideNavigationMenu ?? false,
        isAdvancedCssActive: workflowPage.pageConfiguration?.isAdvancedCssActive ?? false,
        advancedCss: workflowPage.pageConfiguration?.advancedCss ?? '',
      } as UiPagePageConfiguration,
    };
  });
}
