export const addEmployeePersonalInformationTemplate = `
<div class="w-100 h-100">
  <div class="content d-flex f-wrap mt-4">

    <div class="col-5 mb-5">
      <div class="text-label text-uppercase typog-xs font-weight-bold"> first name </div>
      <div class="text-value typog-md font-weight-normal">
        {{ addEmployeeFormState.first_name }}
      </div>
    </div>

    <div class="col-5 ml-8 mb-5">
      <div class="text-label text-uppercase typog-xs font-weight-bold "> middle name </div>
      <div class="text-value typog-md font-weight-normal">
        {{#if addEmployeeFormState.middle_name}} {{ addEmployeeFormState.middle_name }}  {{else}} -- {{/if}}
      </div>
    </div>

    <div class="col-10 mb-5">
      <div class="text-label text-uppercase typog-xs font-weight-bold"> last name </div>
      <div class="text-value typog-md font-weight-normal">
        {{ addEmployeeFormState.last_name }}
      </div>
    </div>

    <div class="col-6 mb-5">
      <div class="text-label text-uppercase typog-xs font-weight-bold">phone number</div>
      <div class="text-value typog-md font-weight-normal">
        {{ addEmployeeFormState.country_code }} {{ addEmployeeFormState.phone }}
      </div>
    </div>

  </div>
</div>
`;

export const addEmployeeLoginDetails = `
<div class="w-100 h-100">
  <div class="content d-flex f-wrap mt-4">

    <div class="col-5">
      <div class="text-label text-uppercase typog-xs font-weight-bold">
        Username
      </div>
      <div class="text-value typog-md font-weight-normal">
        {{ addEmployeeFormState.userName }}
      </div>
    </div>

    <div class="col-5 ml-8">
      <div class="text-label text-uppercase typog-xs font-weight-bold ">
        Password
      </div>
      <div class="text-value typog-md font-weight-normal">
        {{ addEmployeeFormState.password }}
      </div>
    </div>

  </div>
</div>
`;

export const addEmployeePositionSummaryTemplate = `
<div class="w-100 h-100">
  <div class="content d-flex f-wrap mt-4">

    <div>
      <div class="text-label text-uppercase typog-xs font-weight-bold">
        Position assigned
      </div>

      <div class="text-value typog-md font-weight-normal">
        {{ customQuestionFormData.[employee-position] }}
      </div>
    </div>

  </div>
</div>
`;

export const addEmployeeExtraInformationTemplate = `
<div class="w-100 h-100">
  <div class="content d-flex f-wrap mt-4">

    <div>

      <div class="text-label text-uppercase typog-xs font-weight-bold">
        About
      </div>

      <div class="text-value typog-md font-weight-normal mb-4">
        {{ customQuestionFormData.[employee-about] }}
      </div>

      <div class="text-label text-uppercase typog-xs font-weight-bold">
        Interests
      </div>

      <div class="text-value typog-md font-weight-normal">
        {{ customQuestionFormData.[employee-interests] }}
      </div>

    </div>

  </div>

</div>
`;
