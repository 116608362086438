import {
  ButtonSizeOptions,
  ButtonTypes,
  Constants,
  WidgetNames,
} from '@finxone-platform/shared/sys-config-types';

export const downloadDocNestedWidgets = {
  downloadButton: {
    name: WidgetNames.BUTTON,
    widgetConfigDisplayName: 'Download Button Styling',
    attributes: {
      widgetProperties: {
        textContent: 'Download',
        globalStylingClass: `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`,
        globalStyling: true,
      },
      elementsConfig: [],
      css_config: {
        ...Constants.defaultUiNestedButtonCssConfig,
        paddingBottom: 15,
        paddingTop: 15,
      },
    },
  },
};

export const downloadRangeNestedWidgets = {
  resetButton: {
    name: WidgetNames.BUTTON,
    widgetConfigDisplayName: 'Reset changes button',
    attributes: {
      widgetProperties: {
        textContent: 'Reset changes',
      },
      elementsConfig: [],
      css_config: {
        ...Constants.defaultUiNestedButtonCssConfig,
        paddingBottom: 15,
        paddingTop: 15,
        color: 'var(--palette-color-3)',
        backGroundColor: 'var(--palette-color-6)',
        isTextUnderline: true,
      },
    },
  },
};

export const enterEmailNestedWidgets = {
  reviewDetailsButton: {
    widgetConfigDisplayName: 'Send Link',
    name: WidgetNames.BUTTON,
    attributes: {
      widgetProperties: {
        textContent: 'Send Link',
      },
      elementsConfig: [],
      css_config: {
        ...Constants.defaultUiNestedButtonCssConfig,
        color: 'var(--palette-color-6)',
        marginUnit: 'px',
        marginBottom: 25,
        paddingTop: 15,
        paddingBottom: 15,
        backGroundColor: 'var(--palette-color-3)',
      },
    },
  },
};
