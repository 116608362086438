import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { FormActionTypeEnum } from '@finxone-platform/form-action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { BaseWidgetProperties, UserPayload } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { tap } from 'rxjs';
import { MetadataService } from '../../../services/metadata-service/metadata-service.service';
import { FormActionState } from '../../../state/form-submision.state';
import { ProfileState } from '../../../state/user-profile.state';
import { redirectToPage } from '../cta-button-actions.utils';

export function addUserSendInvite(widgetProperties: BaseWidgetProperties, injector: Injector) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  const alertHandlerService = injector.get(AlertHandlerService);
  const metadataService = injector.get(MetadataService);
  const formData = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.MANAGE_USER),
  )?.formData;
  const activeUserOrgId = store.selectSnapshot(ProfileState.getProfile)?.activeOrganisationId;
  if (!formData) {
    alertHandlerService.showAlertFn(
      'error',
      'Form data not available, please make sure all steps are completed',
    );
    return;
  }
  const payload: UserPayload = convertFormDataInPayload(formData, activeUserOrgId ?? '');
  metadataService
    .addUser(payload)
    .pipe(
      tap((response) => {
        if (response) {
          alertHandlerService.showAlertFn('success', 'Invite sent successfully');
          widgetProperties['urlToNavigate'] = 'access-granted';
          redirectToPage(router, widgetProperties, injector);
        } else {
          alertHandlerService.showAlertFn('error', 'An unexpected error occured, please try again');
          widgetProperties['urlToNavigate'] = 'access-error';
          redirectToPage(router, widgetProperties, injector);
        }
      }),
    )
    .subscribe();
}

function convertFormDataInPayload(formData: Record<string, any>, activeUserOrgId: string): UserPayload {
  const phoneNumber = formData?.['country_code'] + formData?.['phone'];
  return {
    email: formData?.['email'],
    emailVerified: false,
    phoneNumber: phoneNumber,
    phoneVerified: false,
    country: formData?.['addressCountry'],
    address1: formData?.['addressStreet'],
    address2: formData?.['address2'],
    flatName: formData?.['flatName'],
    propertyName: formData?.['propertyName'],
    propertyNumber: formData?.['propertyNumber'],
    city: formData?.['addressCity'],
    county: formData?.['addressState'],
    postalCode: formData?.['addressPostCode'],
    firstName: formData?.['first_name'],
    middleName: formData?.['middle_name'],
    lastName: formData?.['last_name'],
    userName: formData?.['userName'],
    roleId: formData?.['assign_persona'],
    password: formData?.['password'],
    organisationId: activeUserOrgId,
    additionalRoles: formData?.['assign_role_permission'] ?? [],
    extraAttributes: {},
  };
}
