import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { MetadataService } from '../../../services/metadata-service/metadata-service.service';
import { OrganisationState } from '../../../state/organisation.state';
import { redirectToPage } from '../cta-button-actions.utils';

/**
 * Retrieves organisation data from the store and updates the organisation details.
 * If the organisation data is available, it constructs the form data and calls
 * the update method to update the organisation and redirect to a specified page.
 * Logs an error if the organisation data is not available.
 *
 * @param widgetProperties - Properties of the widget used for redirection.
 * @param injector - Injector to retrieve necessary services like Store, Router, and MetadataService.
 */
export function getOrganisationDataFromStoreAndCallUpdateMethod(
  widgetProperties: BaseWidgetProperties,
  injector: Injector,
) {
  const store = injector.get(Store);
  const organisationData = store.selectSnapshot(OrganisationState.getOrganisation);
  let orgId = '';
  let formData = {};
  if (organisationData.name) {
    orgId = organisationData.id;
    formData = {
      organisation_companyName: organisationData.name,
      addressCountry: organisationData.address.country,
      addressStreet: organisationData.address.street,
      addressCity: organisationData.address.city,
      addressState: organisationData.address.state,
      addressPostCode: organisationData.address.postalCode,
      identificationNumber: organisationData.identificationNumber,
      incorporationDate: organisationData.incorporationDate,
    };
    upateOrganisationAndRedirect(injector, orgId, formData, widgetProperties);
  } else {
    console.error('Organisation data is not avaiable');
  }
}
/**
 * @param injector - Injector to retrieve necessary services - Router, MetadataService.
 * @param orgId: Organisation Id from the Organisation State
 * @param formData: Organisation data from the Organisation State
 * @param widgetProperties - Properties of the widget used for redirection.
 * Submit the organisation data in API so that the org kyb status changes in the BE
 * Redirect the user to information confirmation screen after API call is done
 */
export function upateOrganisationAndRedirect(
  injector: Injector,
  orgId: string,
  formData: Record<string, string>,
  widgetProperties: BaseWidgetProperties,
) {
  const router = injector.get(Router);
  const metadataService = injector.get(MetadataService);
  if (orgId) {
    metadataService.updateOrganisation(orgId, formData).subscribe((response) => {
      if (response) redirectToPage(router, widgetProperties, injector);
    });
  }
}
