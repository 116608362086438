import { APP_ZONES, GridPosition } from '@finxone-platform/shared/sys-config-types';
import { multiIconWidgetCssConfig } from '../../../../widgets/profile/profile.type';
import { MultiIconQuickLinkWidgetBuilder } from '../../../../widgets/example-builder';

export const profileSettingPageWidgets = [
  new MultiIconQuickLinkWidgetBuilder()
    .icons({
      left: 'bell-notification',
      right: 'arrow-right',
    })
    .template('<p class="multi-icon-header">Notification Settings</p>')
    .zone(APP_ZONES.PROFILE)
    .role()
    .navigateToPageUrl('notification-settings-in-app')
    .navigationOption('use-as-navigation')
    .configDisplayName('Multi Icon Quick Link: Notification Settings')
    .cssConfig(multiIconWidgetCssConfig)
    .isRemovable(false)
    .gridPosition({ y: 2 } as GridPosition)
    .build(),
  new MultiIconQuickLinkWidgetBuilder()
    .icons({
      left: 'face-id',
      right: 'arrow-right',
    })
    .template('<p class="multi-icon-header">Authentication</p>')
    .zone(APP_ZONES.PROFILE)
    .role()
    .navigateToPageUrl('authentication')
    .navigationOption('use-as-navigation')
    .configDisplayName('Multi Icon Quick Link: Authentication')
    .cssConfig(multiIconWidgetCssConfig)
    .isRemovable(false)
    .gridPosition({ y: 6 } as GridPosition)
    .build(),
];
