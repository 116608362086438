import { removeFeeTemplate, removeGroupNameTemplate } from '@finxone-platform/shared/default-templates';
import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonActionType,
  GridPosition,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import {
  FormSubmissionButtonWidgetBuilder,
  ImageWidgetBuilder,
} from '../../../defaults/widgets/example-builder';
import { getBackButtonWidget } from '../../../defaults/zones/manage-users-zone/common-utils';
import { generateTextWidget } from '../../../defaults/zones/payments/common-widgets-utils';

export const confirmationRemovingGroupWidgets = [
  new ImageWidgetBuilder()
    .configDisplayName('Image Widget: Error Icon')
    .cssConfig({
      selectedobjectfit: 'contain',
    })
    .gridPosition({ y: 1, x: 21, rows: 2, cols: 3 } as GridPosition)
    .attributes({
      widgetProperties: {
        textContent: '',
        imageAssetUrl: 'assets/images/payment-decline.svg',
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .build(),
  generateTextWidget({
    text: 'Remove {{feeManagement.selectedFeeGroupData.name}} ?',
    position: { x: 11, y: 3, rows: 2, cols: 23 } as GridPosition,
    displayName: 'Header Text',
    styles: {
      fontSize: 24,
      isTextBold: true,
      verticalAlignment: 'start',
      textAlignment: 'center',
      noAlignment: true,
      color: 'var(--alerts-color-0)',
      textFont: 'var(--font-family)',
      paddingTop: 28,
    },
    isRemovable: true,
  }),

  generateTextWidget({
    text: 'Removing the group will assign the clients linked to this group to the default group. All the clients assigned to this group will be reassigned to the default group. Are you sure you want to remove the group?',
    position: { x: 9, y: 5, rows: 2, cols: 28 } as GridPosition,
    displayName: 'Information Content',
    styles: {
      verticalAlignment: 'start',
      textAlignment: 'center',
      fontSize: '16',
      paddingTop: 5,
      lineHeight: '24',
      textFont: 'var(--font-family)',
      color: 'var(--typography-color-1)',
    },
    isRemovable: true,
  }),

  generateTextWidget({
    text: removeGroupNameTemplate,
    position: { x: 13, y: 7, rows: 9, cols: 19 } as GridPosition,
    styles: {
      backGroundColor: 'var(--palette-color-5)',
      paddingTop: '30',
      paddingBottom: '25',
      paddingLeft: '25',
      paddingRight: '25',
      marginBottom: '20',
      display: 'flex',
      verticalAlignment: 'start',
      textAlignment: 'left',
      borderRadiusTopLeft: '5',
      borderRadiusTopRight: '5',
      borderRadiusBottomLeft: '5',
      borderRadiusBottomRight: '5',
      textFont: 'var(--font-family)',
    },
    displayName: 'Group Information',
    isRemovable: false,
  }),

  getBackButtonWidget({
    position: {
      y: 16,
      x: 17,
      rows: 1,
      cols: 5,
    } as GridPosition,
    styles: {
      color: 'var(--palette-color-3)',
      backGroundColor: 'var(--palette-color-6)',
      borderWidth: 1,
      borderColor: 'var(--palette-color-3)',
      fontSize: 14,
      textAlign: 'center',
      verticalAlignment: 'center',
    },
  }),

  new FormSubmissionButtonWidgetBuilder()
    .configDisplayName('Button: Remove group')
    .attributes({
      widgetProperties: {
        textContent: 'Remove group',
        zoneToNavigate: APP_ZONES.FEE_MANAGEMENT,
        urlToNavigate: 'group-list',
        buttonActionType: ButtonActionType.REMOVE_FEE_GROUP,
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .cssConfig({
      marginTop: 0,
      color: 'var(--alerts-color-0)',
      borderColor: 'var(--alerts-color-0)',
      backGroundColor: 'var(--palette-color-6)',
      borderWidth: 1,
    })
    .gridPosition({
      x: 24,
      y: 16,
      rows: 1,
      cols: 5,
    } as GridPosition)
    .build(),
];

export const confirmationRemoveFeeWidgets = [
  new ImageWidgetBuilder()
    .configDisplayName('Image Widget: Error Icon')
    .cssConfig({
      selectedobjectfit: 'contain',
    })
    .gridPosition({ y: 1, x: 21, rows: 2, cols: 3 } as GridPosition)
    .attributes({
      widgetProperties: {
        textContent: '',
        imageAssetUrl: 'assets/images/payment-decline.svg',
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .build(),

  generateTextWidget({
    text: 'Remove {{feeManagement.selectedFeeData.feeName}} ?',
    position: { x: 11, y: 3, rows: 2, cols: 23 } as GridPosition,
    displayName: 'Header Text',
    styles: {
      fontSize: 24,
      isTextBold: true,
      verticalAlignment: 'start',
      textAlignment: 'center',
      noAlignment: true,
      color: 'var(--alerts-color-0)',
      textFont: 'var(--font-family)',
      paddingTop: 28,
    },
    isRemovable: true,
  }),

  generateTextWidget({
    text: 'Below are the details for the fee that you wish to remove. Please confirm.',
    position: { x: 9, y: 5, rows: 2, cols: 28 } as GridPosition,
    displayName: 'Information Content',
    styles: {
      verticalAlignment: 'start',
      textAlignment: 'center',
      fontSize: '16',
      paddingTop: 5,
      lineHeight: '24',
      textFont: 'var(--font-family)',
      color: 'var(--typography-color-1)',
    },
    isRemovable: true,
  }),

  generateTextWidget({
    text: removeFeeTemplate,
    position: { x: 6, y: 7, rows: 6, cols: 33 } as GridPosition,
    styles: {
      backGroundColor: 'var(--palette-color-5)',
      paddingTop: '30',
      paddingLeft: '30',
      paddingRight: '30',
      display: 'flex',
      verticalAlignment: 'start',
      textAlignment: 'left',
      borderRadiusTopLeft: '5',
      borderRadiusTopRight: '5',
      borderRadiusBottomLeft: '5',
      borderRadiusBottomRight: '5',
      textFont: 'var(--font-family)',
    },
    displayName: 'Remove Fee Template',
    isRemovable: false,
  }),

  getBackButtonWidget({
    position: {
      y: 14,
      x: 16,
      rows: 1,
      cols: 5,
    } as GridPosition,
    styles: {
      color: 'var(--palette-color-3)',
      backGroundColor: 'var(--palette-color-6)',
      borderWidth: 1,
      borderColor: 'var(--palette-color-3)',
      fontSize: 14,
      textAlign: 'center',
      verticalAlignment: 'center',
    },
  }),

  new FormSubmissionButtonWidgetBuilder()
    .configDisplayName('Button: Remove Fee')
    .attributes({
      widgetProperties: {
        textContent: 'Remove Fee',
        zoneToNavigate: APP_ZONES.FEE_MANAGEMENT,
        urlToNavigate: 'management-fee-list',
        buttonActionType: ButtonActionType.REMOVE_FEE,
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .cssConfig({
      marginTop: 0,
      color: 'var(--alerts-color-0)',
      borderColor: 'var(--alerts-color-0)',
      backGroundColor: 'var(--palette-color-6)',
      borderWidth: 1,
    })
    .gridPosition({
      x: 23,
      y: 14,
      rows: 1,
      cols: 5,
    } as GridPosition)
    .build(),
];
