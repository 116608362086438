import {
  Constants,
  WidgetNames,
  WorkflowWidgetConfig,
  cryptoRandomUUID,
} from '@finxone-platform/shared/sys-config-types';

export const cardNameWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: cryptoRandomUUID(),
  name: WidgetNames.CARD_NAME_WIDGET,
  widgetConfiguration: {
    widgetDisplayName: 'Set Card Name',
    widgetConfigDisplayName: 'Set Card Name',
    attributes: {
      workflowFormConfig: {
        type: 'self',
        formPath: 'cards.cardName',
      },
      elementsConfig: [],
      css_config: {
        ...Constants.defaultBaseCssConfig,
      },
      widgetProperties: {
        errorContent: 'This is mandatory',
        textContent: 'Card Name',
      },
      pageIndex: 0,
      zone: '',
    },
    isRemovable: true,
  },
  gridPosition: {
    x: 0,
    y: 0,
    minItemCols: 1,
    minItemRows: 1,
    rows: 4,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;
