import { Constants, WidgetNames } from '@finxone-platform/shared/sys-config-types';

export const otpVerificationNestedWidgets = {
  verifyButton: {
    name: WidgetNames.BUTTON,
    widgetConfigDisplayName: 'Verify Button Styling',
    attributes: {
      widgetProperties: {
        textContent: 'Verify',
      },
      elementsConfig: [],
      css_config: {
        ...Constants.defaultUiNestedButtonCssConfig,
        textAlignment: 'center',
      },
    },
  },
  resendButton: {
    name: WidgetNames.BUTTON,
    widgetConfigDisplayName: 'Resend Button Styling',
    attributes: {
      widgetProperties: {
        textContent: 'Resend',
      },
      elementsConfig: [],
      css_config: {
        ...Constants.defaultUiNestedButtonCssConfig,
        backGroundColor: 'transparent',
        color: 'var(--palette-color-3)',
        textAlignment: 'center',
        isTextBold: true,
        isTextUnderline: true,
      },
    },
  },
  timerContent: {
    name: WidgetNames.TIMER_BUTTON,
    widgetConfigDisplayName: 'Timer Content Styling',
    attributes: {
      widgetProperties: {
        textContent: '',
      },
      elementsConfig: [],
      css_config: {
        ...Constants.defaultUiNestedButtonCssConfig,
        backGroundColor: 'transparent',
        color: 'var(--palette-color-3)',
        textAlignment: 'center',
        isTextBold: true,
        isTextUnderline: false,
        borderWidth: 0,
        borderStyle: 'solid',
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },
};

export const otpVerificationInternationalNestedWidgets = {
  verifyButton: {
    name: WidgetNames.BUTTON,
    widgetConfigDisplayName: 'Verify Button Styling',
    attributes: {
      widgetProperties: {
        textContent: 'Verify',
      },
      elementsConfig: [],
      css_config: {
        ...Constants.defaultUiNestedButtonCssConfig,
        textAlignment: 'center',
      },
    },
  },
};
