import { Constants } from '@finxone-platform/shared/sys-config-types';
import { BackButtonWidgetBuilder, SpacerWidgetBuilder, TextWidgetBuilder } from '../../../widgets';

const title = new TextWidgetBuilder()
  .setTextStylingClass('')
  .text(`<h6>Card Limits</h6>`)
  .configDisplayName('Text: Show Card Limits Title')
  .cssConfig({
    ...Constants.defaultBaseCssConfig,
    color: 'var(--color-on-surface)',
  })
  .build();

const cardDetails = new TextWidgetBuilder()
  .text(
    `
    <div class="w-100">
      <div class="row flex-nowrap w-100 mb-3">
        <div class="col labelText textOnSurfaceMuted">Card Number</div>
        <div class="col body1 text-right textOnSurface">{{cards.currentCardDetail.maskedPan}}</div>
      </div>
      <div class="row flex-nowrap w-100">
        <div class="col labelText textOnSurfaceMuted">Available Balance</div>
        <div class="col body1 text-right textOnSurface">
          {{currencyPipe cards.currentCardDetail.availableBalance currencyCode=cards.currentCardLimits.withdrawalLimits.amount.currency display="symbol-narrow"}}
        </div>
      </div>
    </div>
    `,
  )
  .configDisplayName('Text: Card Details')
  .cssConfig({
    ...Constants.defaultBaseCssConfig,
    backGroundColor: 'var(--color-surface)',
    borderRadiusTopLeft: 8,
    borderRadiusBottomLeft: 8,
    borderRadiusTopRight: 8,
    borderRadiusBottomRight: 8,
    paddingTop: 24,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 24,
  })
  .build();

const monthlyLimit = new TextWidgetBuilder()
  .text(
    `
    <div class="w-100">
      <div class="row flex-nowrap w-100 mb-3">
        <div class="col-7 labelText textOnSurface font-weight-bold">Total Monthly Limit</div>
        <div class="col-5 body1 text-right textOnSurface">
          {{currencyPipe cards.currentCardLimits.transactionLimits.amount.value currencyCode=cards.currentCardLimits.transactionLimits.amount.currency display="symbol-narrow"}}
        </div>
      </div>
      <div class="row flex-nowrap w-100 mb-3">
        <div class="col labelText textOnSurfaceMuted">Spent</div>
        <div class="col body1 text-right textOnSurface">
          {{currencyPipe cards.currentCardLimits.transactionLimits.amount.spendValue currencyCode=cards.currentCardLimits.transactionLimits.amount.currency display="symbol-narrow"}}
        </div>
      </div>
      <div class="row flex-nowrap w-100">
        <div class="col labelText textOnSurfaceMuted">Available To Spend</div>
        <div class="col body2 text-right font-weight-bold textOnSurface">
          {{currencyPipe cards.currentCardLimits.transactionLimits.amount.availableValue currencyCode=cards.currentCardLimits.transactionLimits.amount.currency display="symbol-narrow"}}
        </div>
      </div>
    </div>
    `,
  )
  .configDisplayName('Text: Monthly Transaction Limits')
  .cssConfig({
    ...Constants.defaultBaseCssConfig,
    backGroundColor: 'var(--color-surface)',
    borderRadiusTopLeft: 8,
    borderRadiusBottomLeft: 8,
    borderRadiusTopRight: 8,
    borderRadiusBottomRight: 8,
    paddingTop: 24,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 24,
  })
  .build();

const withdrawalLimit = new TextWidgetBuilder()
  .text(
    `
    <div class="w-100">
      <div class="row flex-nowrap w-100 mb-3">
        <div class="col-8 labelText textOnSurface font-weight-bold">ATM Withdrawal Limit</div>
        <div class="col-4 body1 text-right textOnSurface">
          {{currencyPipe cards.currentCardLimits.withdrawalLimits.amount.value currencyCode=cards.currentCardLimits.withdrawalLimits.amount.currency display="symbol-narrow"}}
        </div>
      </div>
      <div class="row flex-nowrap w-100 mb-3">
        <div class="col labelText textOnSurfaceMuted">Spent</div>
        <div class="col body1 text-right textOnSurface">
          {{currencyPipe cards.currentCardLimits.withdrawalLimits.amount.spendValue currencyCode=cards.currentCardLimits.withdrawalLimits.amount.currency display="symbol-narrow"}}
        </div>
      </div>
      <div class="row flex-nowrap w-100">
        <div class="col labelText textOnSurfaceMuted">Available To Spend</div>
        {{#if (neq cards.currentCardLimits.withdrawalLimits.amount.availableValue '0.00')}}
        <div class="col body2 text-right font-weight-bold textOnSurface">
          {{currencyPipe cards.currentCardLimits.withdrawalLimits.amount.availableValue currencyCode=cards.currentCardLimits.withdrawalLimits.amount.currency display="symbol-narrow"}}
        </div>
        {{/if}}
        {{#if (eq cards.currentCardLimits.withdrawalLimits.amount.availableValue '0.00')}}
        <div class="col body2 text-right text-error font-weight-bold textOnSurface">
          {{currencyPipe cards.currentCardLimits.withdrawalLimits.amount.availableValue currencyCode=cards.currentCardLimits.withdrawalLimits.amount.currency display="symbol-narrow"}}
        </div>
        {{/if}}
      </div>
    </div>
    `,
  )
  .configDisplayName('Text: Withdrawal Limits')
  .cssConfig({
    ...Constants.defaultBaseCssConfig,
    backGroundColor: 'var(--color-surface)',
    borderRadiusTopLeft: 8,
    borderRadiusBottomLeft: 8,
    borderRadiusTopRight: 8,
    borderRadiusBottomRight: 8,
    paddingTop: 24,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 24,
  })
  .build();

export const showCardLimitsPageWidgets = [
  new SpacerWidgetBuilder().setHeight({ height: 30, unit: 'px' }).build(),
  title,
  new SpacerWidgetBuilder().setHeight({ height: 30, unit: 'px' }).build(),
  cardDetails,
  new SpacerWidgetBuilder().setHeight({ height: 30, unit: 'px' }).build(),
  monthlyLimit,
  new SpacerWidgetBuilder().setHeight({ height: 30, unit: 'px' }).build(),
  withdrawalLimit,
  new SpacerWidgetBuilder().setHeight({ height: 70, unit: 'px' }).build(),
  new BackButtonWidgetBuilder()
    .icon('')
    .configDisplayName('Back Button')
    .text('Back')
    .cssConfig({
      fontSize: 16,
      textAlignment: 'center',
      backGroundColor: 'var(--color-surface)',
      borderWidth: 1,
      borderColor: 'var(--color-primary)',
      color: 'var(--color-primary)',
      borderRadiusTopLeft: 6,
      borderRadiusBottomLeft: 6,
      borderRadiusTopRight: 6,
      borderRadiusBottomRight: 6,
    })
    .setGlobalStylingClass(true, 'secondaryButton full-width')
    .build(),
];
