import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import {
  APP_ZONES,
  BaseWidgetProperties,
  InternationalPaymentStatusScreen,
} from '@finxone-platform/shared/sys-config-types';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { map, take } from 'rxjs';
import {
  InternationalCreatePayment,
  InternationalPaymentDeclined,
  InternationalPaymentSuccess,
} from '../../../actions/payment.action';
import { TransferMoneyRequest } from '../../../services/account-service/account.type';
import { AccountState } from '../../../state/account.state';
import { FormActionState } from '../../../state/form-submision.state';
import { ProjectSettingsState } from '../../../state/project-settings.state';
import { redirectToPage } from '../cta-button-actions.utils';

/**
 * Submits an international payment based on the SMS authentication setting.
 *
 * This function checks if SMS authentication is enabled in the project settings.
 * If enabled, it redirects to a page using the provided router and widget properties.
 * Otherwise, it proceeds to submit the international payment using the provided
 * widget properties and injector.
 *
 * @param widgetProperties - The properties of the widget initiating the payment.
 * @param injector - The injector used to retrieve necessary services and states.
 */
export function submitInternationalPayment(widgetProperties: BaseWidgetProperties, injector: Injector) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  const isSmsAuthEnabled = store.selectSnapshot(
    ProjectSettingsState.getProjectSettings,
  ).smsAuthenticationEnabled;
  if (isSmsAuthEnabled) {
    redirectToPage(router, widgetProperties, injector);
  } else {
    onSubmitInternationalPayment(widgetProperties, injector);
  }
}

/**
 * Handles the submission of an international payment by dispatching the payment action
 * and redirecting to the appropriate status page based on the payment result.
 *
 * @param widgetProperties - The properties of the widget initiating the payment.
 * @param injector - The Angular injector used to retrieve necessary services.
 *
 * The function retrieves the current form state and active account ID, then dispatches
 * an international payment creation action. It listens for payment success or decline
 * actions to redirect the user to the corresponding status page. If form data is not
 * available, it displays an error alert.
 */
export function onSubmitInternationalPayment(widgetProperties: BaseWidgetProperties, injector: Injector) {
  const store = injector.get(Store);
  const actions = injector.get(Actions);
  const router = injector.get(Router);
  const alertHandlerService = injector.get(AlertHandlerService);
  let formStateData = store.selectSnapshot(FormActionState.getFormActionState)?.response?.formData;
  const activeAccountId = store.selectSnapshot(AccountState.getCurrentAccountId);
  if (!widgetProperties['zoneToNavigate']) {
    widgetProperties['zoneToNavigate'] = APP_ZONES.PAYMENT;
  }
  const parsedWidgetProperties = JSON.parse(JSON.stringify(widgetProperties));
  formStateData = {
    ...formStateData,
    review: false,
    sourceAccountId: activeAccountId,
  };
  if (formStateData) {
    actions
      .pipe(
        ofActionDispatched(InternationalPaymentSuccess),
        take(1),
        map(() => {
          parsedWidgetProperties['urlToNavigate'] = InternationalPaymentStatusScreen.PAYMENT_SUCCESS;
          redirectToPage(router, parsedWidgetProperties, injector, true);
        }),
      )
      .subscribe();

    actions
      .pipe(
        ofActionDispatched(InternationalPaymentDeclined),
        take(1),
        map(() => {
          parsedWidgetProperties['urlToNavigate'] = InternationalPaymentStatusScreen.PAYMENT_DECLINED;
          redirectToPage(router, parsedWidgetProperties, injector, true);
        }),
      )
      .subscribe();

    store.dispatch(new InternationalCreatePayment(filterObjectByType(formStateData)));
    parsedWidgetProperties['urlToNavigate'] = InternationalPaymentStatusScreen.PAYMENT_PROCESSING;
    redirectToPage(router, parsedWidgetProperties, injector, true);
  } else {
    alertHandlerService.showAlertFn('error', 'Payment request data not available, please try again.');
  }
}

export function filterObjectByType(formStateData: any): TransferMoneyRequest {
  let result: TransferMoneyRequest = {
    sourceAccountId: '',
    transferReasonId: '',
    bid: '',
    amount: 0,
    paymentReference: '',
    review: false,
    paymentRailOption: undefined,
  };

  for (const key in formStateData) {
    if (key in result) {
      result = {
        ...result,
        [key]: formStateData[key],
      };
    }
  }

  return result;
}
