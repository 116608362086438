import {
  beneficiaryDetailsTemplate,
  copCloseMatchDetails,
  copCloseMatchReturnedBankName,
  copEnteredDetails,
  copNoMatchDetails,
  currencyAccountTemplate,
  paymentInsufficientFundAccountInfoTemplate,
  paymentIntlReviewBeneficiaryDetailsTemplate,
  paymentProcessingTotalEnteredAmountBackgroundStyle,
  paymentProcessingWaitTextTemplate,
  paymentReviewAccountInfoTemplate,
  paymentSuccessSummaryTemplate,
  paymentSummaryBeneficiaryDetailsTemplate,
  selectedAccountCustomerNameTemplate,
  selectedAccountItemTampleteWhiteBgColorStyle,
  selectedAccountItemTemplate,
  selectedAccountItemTemplateGreyBgColorStyle,
  totalEnteredAmountBackgroundStyle,
  totalEnteredAmountTemplate,
  transactionInfoTemplate,
} from '@finxone-platform/shared/default-templates';
import { CustomValidators, SEPAPaymentRailTypeEnum } from '@finxone-platform/shared/enums';
import {
  APP_ZONES,
  ActiveDevice,
  BaseWidgetProperties,
  ButtonActionType,
  CommonPageConfig,
  Constants,
  GridPosition,
  InputCssConfig,
  PaymentFlowTypes,
  PaymentProvider,
  SupportedDevice,
  WidgetNames,
  WidgetTitles,
  WorkflowPageConfig,
  WorkflowWidgetConfig,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import { generatePaymentInitiatorPages } from '../../../page-builder-utils/payment-approver-flow-pages/payment-approver-flow-pages.utils';
import { generatePaymentRequestApprovedAndRejectedStatusPages } from '../../../page-builder-utils/payment-request-status-pages/payment-request-approved-rejected-pages.utils';
import { generatePaymentApproverPages } from '../../../page-builder-utils/widget-builder-utils/desktop-page-utils/payment-approver-page.utils';
import { generatePaymentPendingApprovalPages } from '../../../page-builder-utils/widget-builder-utils/desktop-page-utils/payment-pending-approval-page-utils';
import { generatePaymentRequestorPages } from '../../../page-builder-utils/widget-builder-utils/desktop-page-utils/payment-requestor-page.utils';
import {
  buildOtpVerificationPageInternationalConfig,
  buildOtpVerificationPageWidgetsConfig,
} from '../../../page-builder-utils/widget-builder-utils/otp-verification-page';
import {
  CustomWorkflowWidgetConfig,
  currencyInputFieldWidgetConfig,
  getProgressBarWidgetConfig,
} from '../../widgets/display-widgets.type';
import {
  AddCurrencyAccountWidgetBuilder,
  BackButtonWidgetBuilder,
  CurrencyAccountsListingWidgetBuilder,
  CurrencyInputFieldWidgetBuilder,
  ExchangeCurrencyWidgetBuilder,
  FormSubmissionButtonWidgetBuilder,
  ImageWidgetBuilder,
  InternationalCurrencyCountryWidgetBuilder,
  LottieAnimationWidgetBuilder,
  MandatoryCTAButtonBuilder,
  MultiIconQuickLinkWidgetBuilder,
  PaymentRailSelectorWidgetBuilder,
  PaymentReasonWidgetBuilder,
  PaymentRefConfigBuilder,
  ProgressBarBuilder,
  QuickLinkWidgetBuilder,
  SecondaryIdentifierWidgetBuilder,
  SwitchNavigatorWidgetBuilder,
  TextWidgetBuilder,
} from '../../widgets/example-builder';
import {
  BackButtonWidgetBuilderCssConfig,
  beneficiarySelectionWidgetConfig,
  exchangeQuoteSummaryConfig,
  exchangeQuoteWidgetConfig,
  getAddIntlBeneficiaryCredentialsWidget,
  getIntlBeneficiaryAddressWidget,
  inputSettings,
  intlBeneficiaryAdded,
  quoteExpirationAttributes,
} from '../../widgets/payments/payments.type';
import { defaultPageConfigurations, switchNavigatorCss } from '../../widgets/workflow-ui-constants.v2.type';
import { transactionElaboratedViewWidgets } from '../profile/widget-sections/payment/transaction-elaborated-view-widget';
import { addBeneficiaryIntlPageWidgets } from './add-beneficiary-intl-widgets/add-beneficiary-intl-widgets';
import { buildBulkPaymentsPagesConfig } from './bulk-payments-page-setup';
import { addBeneficiaryPageWidgets } from './domestic-sepa-payments-widgets/add-beneficiary-widgets';
import {
  getReviewBeneficiaryWidgetsForDesktop,
  getReviewBeneficiaryWidgetsForMobile,
} from './domestic-sepa-payments-widgets/review-information-widgets';

const multiQuickLinkCssConfig = {
  backGroundColor: 'var(--palette-color-6)',
  color: 'var(--typography-color-0)',
  textAlignment: 'space-between',
  borderWidth: 1,
  borderRadiusTopLeft: 5,
  borderRadiusBottomLeft: 5,
  borderRadiusTopRight: 5,
  borderRadiusBottomRight: 5,
  paddingRight: '20',
  borderColor: 'var(--border-color-1)',
  fontSize: 10,
  textFont: 'Mulish',
};

const paymentRailSelectorWidget = new PaymentRailSelectorWidgetBuilder()
  .addCustomWidgetProperties({
    toggleRailSelector: true,
    defaultPaymentRail: SEPAPaymentRailTypeEnum.SEPA_CREDIT_TRANSFER,
    selectedVisiblePaymentRailList: [
      {
        id: 1,
        value: SEPAPaymentRailTypeEnum.SEPA_CREDIT_TRANSFER,
      },
    ],
  })
  .build();

function formatPageName(pageName: string): string {
  if (pageName?.length)
    return pageName
      .replace(/[^a-zA-Z0-9\s]/g, '')
      .replace(/\s+/g, '-')
      .toLowerCase();
  else return '';
}

export function buildPaymentsPages(tags: string[]): {
  name: string;
  title: string;
  url?: string;
  widgets?: WorkflowWidgetConfig[];
  tags: string[];
}[] {
  // Finds assoicated pages for each tag and then deduplicates the resulting array to only get unique pages
  return [
    ...new Set(
      tags.flatMap((tag) => {
        return associatedPaymentPages.filter((page) => {
          return page.tags.includes(tag);
        });
      }),
    ),
  ];
}
export function onCreatePaymentType(pages: WorkflowPageConfig[], paymentModekey: string[]) {
  if (pages[0]?.name === 'payment-type') {
    let widgets = [];
    widgets = getPaymentType(paymentModekey);
    pages[0].widgets = [];
    pages[0].widgets.push(...widgets);
  }
}
export function buildPaymentsAdditionalPages(
  paymentProvider: string,
  tags: string[],
  activeDevice?: SupportedDevice,
): {
  name: string;
  title: string;
  url?: string;
  widgets?: WorkflowWidgetConfig[];
  tags: string[];
  pageConfiguration: CommonPageConfig;
}[] {
  // Finds assoicated pages for each tag and then deduplicates the resulting array to only get unique pages
  const additionalWorkflowPaymentPages = [];

  if (tags.length >= 1) {
    if (tags.includes(PaymentFlowTypes.BULK_PAYMENTS)) {
      additionalWorkflowPaymentPages.push(...buildBulkPaymentsPagesConfig());
    }
    if (tags.includes(PaymentFlowTypes.APPROVER)) {
      additionalWorkflowPaymentPages.push(
        ...generatePaymentApproverPages(),
        ...generatePaymentRequestApprovedAndRejectedStatusPages(),
        ...generatePaymentRequestorPages(),
        ...generatePaymentPendingApprovalPages(),
      );
    }
    if (tags.includes(PaymentFlowTypes.INITIATOR)) {
      additionalWorkflowPaymentPages.push(...generatePaymentInitiatorPages());
    }
    if (tags.includes(PaymentFlowTypes.REQUESTOR)) {
      additionalWorkflowPaymentPages.push(...generatePaymentRequestorPages());
      additionalWorkflowPaymentPages.push(...generatePaymentRequestApprovedAndRejectedStatusPages());
      additionalWorkflowPaymentPages.push(...generatePaymentPendingApprovalPages());
    }
    if (tags.includes(PaymentFlowTypes.SWIFT) || tags.includes(PaymentFlowTypes.SEPA)) {
      getInternationalAndSepaPaymentPages(tags, additionalWorkflowPaymentPages, activeDevice);
    }
    if (tags.includes(PaymentFlowTypes.UK_FASTER_PAYMENTS) || tags.includes(PaymentFlowTypes.SEPA)) {
      //filter COP pages on UK Bank Transfers based on if COP is enabled
      getUkAndSepaPaymentsPages(tags, additionalWorkflowPaymentPages, activeDevice);
    }
    if (tags.includes(PaymentFlowTypes.CURRENCY_ACCOUNT)) {
      additionalWorkflowPaymentPages.push(...buildCurrencyAccountPagesConfig());
    }
    if (tags.includes(PaymentFlowTypes.COMMON_UK_FASTER_PAYMENTS_SWIFT)) {
      additionalWorkflowPaymentPages.push(...buildCommonPaymentPagesConfig());
    }
  }
  //filter the pages which are available with the current payment provider
  const pagesIncludedWithProvider = additionalWorkflowPaymentPages.filter((page) => {
    return page.tags.includes(paymentProvider);
  });
  return [
    ...new Set(
      tags.flatMap((tag) => {
        return pagesIncludedWithProvider.filter((page) => {
          return page.tags.includes(tag);
        });
      }),
    ),
  ];
}

function getInternationalAndSepaPaymentPages(
  tags: string[],
  additionalWorkflowPaymentPages: any,
  activeDevice?: SupportedDevice,
) {
  const paymentTypeSwift = tags.includes(PaymentFlowTypes.SWIFT) ? PaymentFlowTypes.SWIFT : '';
  const paymentTypeSepa = tags.includes(PaymentFlowTypes.SEPA) ? PaymentFlowTypes.SEPA : '';

  if (paymentTypeSwift && paymentTypeSepa) {
    // If both SWIFT and SEPA are present, pass both
    additionalWorkflowPaymentPages.push(
      ...buildInternationalPaymentsPagesConfig(paymentTypeSwift, paymentTypeSepa, activeDevice),
    );
  } else if (paymentTypeSwift) {
    // If only SWIFT is present
    additionalWorkflowPaymentPages.push(
      ...buildInternationalPaymentsPagesConfig(paymentTypeSwift, '', activeDevice),
    );
  } else if (paymentTypeSepa) {
    // If only SEPA is present
    additionalWorkflowPaymentPages.push(
      ...buildInternationalPaymentsPagesConfig('', paymentTypeSepa, activeDevice),
    );
  }
}

function getUkAndSepaPaymentsPages(
  tags: string[],
  additionalWorkflowPaymentPages: any,
  activeDevice?: SupportedDevice,
) {
  const paymentTypeUkPayments = tags.includes(PaymentFlowTypes.UK_FASTER_PAYMENTS)
    ? PaymentFlowTypes.UK_FASTER_PAYMENTS
    : '';
  const paymentTypeSepa = tags.includes(PaymentFlowTypes.SEPA) ? PaymentFlowTypes.SEPA : '';
  if (activeDevice === ActiveDevice.DESKTOP) {
    if (paymentTypeUkPayments && paymentTypeSepa) {
      // Both UK_FASTER_PAYMENTS and SEPA are available: add pages for both payment types on desktop
      additionalWorkflowPaymentPages.push(
        ...buildPaymentsAdditionalDesktopPagesConfig(paymentTypeUkPayments, paymentTypeSepa),
      );
    } else if (paymentTypeUkPayments) {
      // Only UK_FASTER_PAYMENTS is available: add desktop pages for UK payments
      additionalWorkflowPaymentPages.push(
        ...buildPaymentsAdditionalDesktopPagesConfig(paymentTypeUkPayments, ''),
      );
    } else if (paymentTypeSepa) {
      // Only SEPA is available: add desktop pages for SEPA payments
      additionalWorkflowPaymentPages.push(...buildPaymentsAdditionalDesktopPagesConfig('', paymentTypeSepa));
    }
  } else {
    if (paymentTypeUkPayments && paymentTypeSepa) {
      // Both UK_FASTER_PAYMENTS and SEPA are available: add pages for both payment types on non-desktop devices
      additionalWorkflowPaymentPages.push(
        ...buildPaymentsAdditionalPagesConfig(paymentTypeUkPayments, paymentTypeSepa),
      );
    } else if (paymentTypeUkPayments) {
      // Only UK_FASTER_PAYMENTS is available: add pages for UK payments on non-desktop devices
      additionalWorkflowPaymentPages.push(...buildPaymentsAdditionalPagesConfig(paymentTypeUkPayments, ''));
    } else if (paymentTypeSepa) {
      // Only SEPA is available: add pages for SEPA payments on non-desktop devices
      additionalWorkflowPaymentPages.push(...buildPaymentsAdditionalPagesConfig('', paymentTypeSepa));
    }
  }
}

export function buildInternationalPaymentsPagesConfig(
  paymentTypeSwift: string,
  paymentTypeSepa: string,
  activeDevice?: SupportedDevice,
): {
  name: string;
  title: string;
  url?: string;
  widgets?: WorkflowWidgetConfig[];
  tags: string[];
  pageConfiguration: CommonPageConfig;
}[] {
  const internationalTransferPaymentPages: {
    title: string;
    widgets: WorkflowWidgetConfig[];
    tags: string[];
    pageConfiguration?: CommonPageConfig;
  }[] = [
    {
      title: 'Beneficiary Type (Int.)',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeSwift,
      ],
      widgets: [
        getProgressBarWidgetConfig(paymentTypeProgressBar),

        createMultiIconQuickLinkWidget(
          'Pay New Beneficiary',
          'Send money to a new beneficiary',
          'intl-select-country',
          'user-plus-jamboo',
          'arrow-right-jamboo',
          { y: 5 },
        ),

        createMultiIconQuickLinkWidget(
          'Pay Existing Beneficiary',
          'Send money to an existing beneficiary',
          'intl-select-beneficiary',
          'user',
          'arrow-right-jamboo',
          { y: 9 },
        ),

        getQuickLinkWidgetAsSecondaryButton({
          pageUrl: 'payment-type',
          gridPosition: { y: 24, rows: 3 } as GridPosition,
        }),
      ],
    },
    {
      title: 'Intl. Select Beneficiary',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeSwift,
      ],
      widgets: [
        new ProgressBarBuilder()
          .setWidgetProperties({
            textContent: WidgetTitles.PROGRESSBAR_WIDGET,
            completionProgressPercentage: '5',
            progressBarActiveColor: 'var(--palette-color-3)',
            progressBarColor: 'var(--palette-color-4)',
          })
          .text('<h1 class="w-100 mt-3">Select Beneficiary</h1>')
          .configDisplayName('Progress Bar Widget: Select Beneficiary')
          .gridPosition({ y: 0 } as GridPosition)
          .cssConfig({
            isTextBold: true,
            color: 'var(--typography-color-0)',
            textAlignment: 'center',
            justifyContent: 'center',
            fontSize: 12,
            backGroundColor: 'transparent',
          })
          .isRemovable(true)
          .build(),
        getBeneficiaryListConfig({
          textContent: `
          <div class="is-vertical-align">
            <h4>{{ name }}</h4>
            <div class="text-wrap">
              <span>{{ currencyName }} | {{ currencyCode }}</span>
            </div>
          </div>`,
          widgetContext: {
            pageType: PaymentFlowTypes.SWIFT,
          },
          gridPosition: {
            y: 4,
            row: 13,
          },
        }),
        getAddBeneficiaryQuickLink({
          pageUrl: 'intl-select-country',
          gridPosition: { y: 22, rows: 3 },
        }),
        new BackButtonWidgetBuilder()
          .defaultBackButton()
          .cssConfig(BackButtonWidgetBuilderCssConfig)
          .gridPosition({ y: 25, rows: 3, cols: 15 } as GridPosition)
          .isRemovable(true)
          .build(),
      ],
    },
    {
      title: 'Intl. Select Country',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeSwift,
      ],
      widgets: [
        new ProgressBarBuilder()
          .setWidgetProperties({
            textContent: WidgetTitles.PROGRESSBAR_WIDGET,
            completionProgressPercentage: '35',
            progressBarActiveColor: 'var(--palette-color-3)',
            progressBarColor: 'var(--palette-color-4)',
          })
          .text('<div class="w-100 mt-3" style="font-weight: 700"><p>Select Country</p></div>')
          .configDisplayName('Progress Bar Widget: Select Country')
          .gridPosition({ y: 0 } as GridPosition)
          .cssConfig({
            color: 'var(--typography-color-0)',
            textAlignment: 'center',
            justifyContent: 'center',
            fontSize: 24,
            backGroundColor: 'transparent',
          })
          .isRemovable(true)
          .build(),

        new InternationalCurrencyCountryWidgetBuilder()
          .configDisplayName('Intl Select country')
          .inputSetting({
            ...inputSettings,
            isIconAllowed: false,
            currencyLabel: 'Currency',
            countryLabel: 'Country',
          })
          .gridPosition({ y: 4 } as GridPosition)
          .build(),
        new BackButtonWidgetBuilder()
          .defaultBackButton()
          .cssConfig(BackButtonWidgetBuilderCssConfig)
          .gridPosition({ y: 15, rows: 3, cols: 15 } as GridPosition)
          .isRemovable(true)
          .build(),
      ],
    },
    {
      title: 'Intl. No Source Currency Account',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeSwift,
      ],
      widgets: [
        ...commonImageWidgetErrorIcon,
        new TextWidgetBuilder()
          .text('<h1>No {{selectedBeneficiary.destinationIdentifier.currency}} Account</h1>')
          .configDisplayName('Text Widget: No Currency Account')
          .cssConfig({
            color: 'var(--alerts-color-0)',
            isTextBold: true,
            fontSize: 12,
            textAlignment: 'center',
            verticalAlignment: 'center',
          })
          .gridPosition({ y: 5, rows: 2 } as GridPosition)
          .isRemovable(false)
          .build(),
        new TextWidgetBuilder()
          .text(
            '<p>Due to our current service provider limitations, we cannot perform inter currency international payments.</p>',
          )
          .configDisplayName('Text Widget: Description Para One')
          .cssConfig({
            color: 'var(--typography-color-1)',
            fontSize: 14,
            paddingLeft: 20,
            paddingRight: 20,
            textAlign: 'center',
            verticalAlignment: 'center',
          })
          .gridPosition({ y: 7, rows: 4 } as GridPosition)
          .build(),
        new TextWidgetBuilder()
          .text(
            '<p>If you still wish to send this beneficiary a payment, you must open a (({{selectedBeneficiary.destinationIdentifier.currency}})) account and perform an internal exchange first.</p>',
          )
          .configDisplayName('Text Widget: Description Para Two')
          .cssConfig({
            color: 'var(--typography-color-1)',
            fontSize: 14,
            paddingLeft: 20,
            paddingRight: 20,
            textAlign: 'center',
            verticalAlignment: 'center',
          })
          .gridPosition({ y: 11, rows: 4 } as GridPosition)
          .build(),
        getQuickLinkWidgetAsSecondaryButton({
          text: 'Create Account',
          pageUrl: 'currency-accounts-landing',
          gridPosition: { y: 23, rows: 3 } as GridPosition,
          cssConfig: {
            color: 'var(--palette-color-6)',
            backGroundColor: 'var(--palette-color-3)',
            borderWidth: '1',
            borderColor: 'var(--palette-color-3)',
            fontSize: '14',
            borderRadiusTopLeft: '6',
            borderRadiusTopRight: '6',
            borderRadiusBottomLeft: '6',
            borderRadiusBottomRight: '6',
            marginBottom: '12',
            marginTop: '12',
            paddingTop: '11',
            paddingBottom: '11',
            paddingRight: '11',
            paddingLeft: '11',
            textAlignment: 'center',
            justifyContent: 'center',
            textFont: 'Mulish',
          },
        }),
        getQuickLinkWidgetAsSecondaryButton({
          text: 'Cancel',
          pageUrl: 'intl-select-beneficiary',
          gridPosition: { y: 26, rows: 3 } as GridPosition,
        }),
      ],
    },
    {
      title: 'Add Beneficiary Intl.',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeSwift,
      ],
      widgets: addBeneficiaryIntlPageWidgets(activeDevice),
      pageConfiguration: {
        ...defaultPageConfigurations,
        isUsingHtmlStructure: true,
      } as CommonPageConfig,
    },
    {
      title: 'Intl. Beneficiary Bank Details (Personal)',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeSwift,
      ],
      widgets: getIntlBeneficiaryDetailsWidgets('personal'),
    },
    {
      title: 'Intl. Beneficiary Bank Details (Business)',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeSwift,
      ],
      widgets: getIntlBeneficiaryDetailsWidgets('business'),
    },
    {
      title: 'Beneficiary Address',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeSwift,
        paymentTypeSepa,
      ],
      widgets: [
        new ProgressBarBuilder()
          .setWidgetProperties({
            textContent: WidgetTitles.PROGRESSBAR_WIDGET,
            progressBarColor: 'var(--palette-color-4)',
            progressBarActiveColor: 'var(--palette-color-3)',
            completionProgressPercentage: '50',
          })
          .text('<div class="w-100 mt-3"><p>Beneficiary Address</p></div>')
          .configDisplayName('Progress Bar Widget: Beneficiary Address')
          .gridPosition({ y: 0, rows: 4 } as GridPosition)
          .cssConfig({
            fontSize: 24,
            justifyContent: 'center',
            textAlignment: 'center',
            color: 'var(--typography-color-0)',
            backGroundColor: 'transparent',
          })
          .isRemovable(true)
          .build(),
        getIntlBeneficiaryAddressWidget(),

        new FormSubmissionButtonWidgetBuilder()
          .configDisplayName('CTA Button: Skip')
          .attributes({
            widgetProperties: {
              textContent: 'Skip',
              buttonActionType: ButtonActionType.SKIP_BENEFICIARY_ADDRESS,
              zoneToNavigate: APP_ZONES.PAYMENT,
              urlToNavigate: 'intl-review-beneficiary-details',
            } as BaseWidgetProperties,
          } as WorkflowWidgetConfigurationAttributes)
          .cssConfig({
            textAlignment: 'center',
            backGroundColor: 'var(--color-background)',
            borderWidth: 1,
            borderColor: 'var(--color-primary)',
            color: 'var(--color-primary)',
          })
          .gridPosition({ y: 38, rows: 2 } as GridPosition)
          .build(),

        new BackButtonWidgetBuilder()
          .defaultBackButton()
          .cssConfig(BackButtonWidgetBuilderCssConfig)
          .gridPosition({ y: 40, rows: 3, cols: 15 } as GridPosition)
          .isRemovable(true)
          .build(),
      ],
      pageConfiguration: {
        isBodyScrollingEnabled: true,
      } as CommonPageConfig,
    },
    {
      title: 'Intl. Review Beneficiary Details',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeSwift,
      ],
      widgets: [
        new TextWidgetBuilder()
          .text(`<h1>Review Beneficiary Details</h1>`)
          .configDisplayName('Text / HTML')
          .cssConfig({
            isTextBold: true,
            fontSize: 12,
            textAlignment: 'center',
          })
          .gridPosition({ rows: 2, y: 0 } as GridPosition)
          .isRemovable(false)
          .build(),

        new TextWidgetBuilder()
          .isTextEditable(false)
          .text(paymentIntlReviewBeneficiaryDetailsTemplate)
          .configDisplayName('Text / HTML')
          .cssConfig({
            fontSize: 18,
            backGroundColor: 'var(--palette-color-5)',
            textAlignment: 'left',
          })
          .gridPosition({ rows: 22, y: 3 } as GridPosition)
          .isRemovable(false)
          .build(),
        new FormSubmissionButtonWidgetBuilder()
          .configDisplayName('CTA Button: Confirm & Add Beneficiary')
          .attributes({
            widgetProperties: {
              textContent: 'Confirm & Add Beneficiary',
              buttonActionType: ButtonActionType.ADD_INTERNATIONAL_BENEFICIARY,
              zoneToNavigate: APP_ZONES.PAYMENT,
              urlToNavigate: 'intl-beneficiary-added',
            } as BaseWidgetProperties,
          } as WorkflowWidgetConfigurationAttributes)
          .cssConfig({
            marginTop: 10,
          })
          .gridPosition({ y: 25 } as GridPosition)
          .build(),
        new BackButtonWidgetBuilder()
          .defaultBackButton()
          .cssConfig(BackButtonWidgetBuilderCssConfig)
          .gridPosition({ y: 28, rows: 3, cols: 15 } as GridPosition)
          .isRemovable(true)
          .build(),
      ],
    },
    {
      title: 'Intl. Beneficiary Added',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeSwift,
      ],
      widgets: [
        ...commonImageWidgetSuccessIcon,

        new TextWidgetBuilder()
          .text('<h1>New Beneficiary Added</h1>')
          .configDisplayName('Text Widget: New Beneficiary Added')
          .cssConfig({
            isTextBold: true,
            fontSize: 12,
            verticalAlignment: 'start',
          })
          .build(),

        intlBeneficiaryAdded,
        new FormSubmissionButtonWidgetBuilder()
          .configDisplayName('CTA Button: Proceed to Pay Beneficiary')
          .attributes({
            widgetProperties: {
              textContent: 'Proceed to Pay Beneficiary',
              buttonActionType: ButtonActionType.PAY_NEW_INTL_BENEFICIARY,
              zoneToNavigate: APP_ZONES.PAYMENT,
              urlToNavigate: 'intl-pay-beneficiary',
            } as BaseWidgetProperties,
          } as WorkflowWidgetConfigurationAttributes)
          .gridPosition({ y: 23, rows: 2 } as GridPosition)
          .build(),
      ],
    },
    {
      title: 'Intl. Pay Beneficiary',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeSwift,
      ],
      widgets: [
        new ProgressBarBuilder()
          .text('<h1 class="w-100 mt-3">Pay Beneficiary</h1>')
          .configDisplayName('Progress Bar Widget: Pay Beneficiary')
          .gridPosition({ rows: 3, y: 0 } as GridPosition)
          .cssConfig({
            isTextBold: true,
            color: 'var(--typography-color-0)',
            textAlignment: 'center',
            justifyContent: 'center',
            fontSize: 12,
            backGroundColor: 'transparent',
          })
          .isRemovable(true)
          .build(),

        new TextWidgetBuilder()
          .text(
            `<div class="d-flex align-items-center gap-2">{{{ currencyToFlag selectedBeneficiary.destinationIdentifier.currency "24px" }}} {{ selectedBeneficiary.name }}</div>`,
          )
          .configDisplayName('Text / HTML')
          .cssConfig({
            isTextBold: false,
            fontSize: 18,
            textAlignment: 'center',
          })
          .gridPosition({ rows: 2, y: 3 } as GridPosition)
          .isRemovable(false)
          .build(),

        new CurrencyInputFieldWidgetBuilder()
          .cssConfig({
            textAlignment: 'center',
            horizontalAlignment: 'center',
          })
          .gridPosition({ y: 7 } as GridPosition)
          .isRemovable(false)
          .build(),

        new PaymentRefConfigBuilder()
          .cssConfig({
            marginTop: 0,
            marginLeft: 0,
            marginRight: 0,
            borderRadiusTopLeft: 5,
            borderRadiusBottomLeft: 5,
            borderRadiusTopRight: 5,
            borderRadiusBottomRight: 5,
            borderColor: 'var(--border-color-1)',
            borderWidth: 1,
            backGroundColor: 'var(--palette-color-6)',
            textAlignment: 'left',
            horizontalAlignment: 'start',
            color: 'var(--typography-color-1)',
          })
          .gridPosition({ rows: 3, y: 11 } as GridPosition)
          .isRemovable(false)
          .addCustomWidgetProperties({
            customValidator: CustomValidators.PAYMENT_REF_MAX_LENGTH_LOCAL,
          })
          .build(),

        new PaymentReasonWidgetBuilder().isRemovable(false).build(),

        new TextWidgetBuilder()
          .text(currencyAccountTemplate)
          .configDisplayName('Text / HTML')
          .cssConfig({
            isTextBold: false,
            fontSize: 18,
            textAlignment: 'left',
          })
          .gridPosition({ rows: 3, y: 18 } as GridPosition)
          .isRemovable(false)
          .build(),

        new MandatoryCTAButtonBuilder()
          .configDisplayName('Button: Review Payment Details')
          .attributes({
            widgetProperties: {
              textContent: 'Review Payment Details',
              zoneToNavigate: APP_ZONES.PAYMENT,
              urlToNavigate: 'intl-review-payment-details',
            } as BaseWidgetProperties,
          } as WorkflowWidgetConfigurationAttributes)
          .cssConfig({
            marginTop: 0,
            paddingTop: 15,
            paddingBottom: 15,
          })
          .gridPosition({ y: 22, rows: 2 } as GridPosition)
          .build(),

        new BackButtonWidgetBuilder()
          .defaultBackButton()
          .cssConfig(BackButtonWidgetBuilderCssConfig)
          .gridPosition({ y: 25, rows: 2, cols: 15 } as GridPosition)
          .cssConfig({
            marginTop: 0,
            marginBottom: 0,
          })
          .isRemovable(true)
          .build(),
      ],
    },
    {
      title: 'Intl. Review Payment Details',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeSwift,
      ],
      widgets: [
        new TextWidgetBuilder()
          .text('<h1>Review Payment Details</h1>')
          .configDisplayName('Text Widget: Review Payment Details')
          .cssConfig({
            isTextBold: true,
            fontSize: 12,
            verticalAlignment: 'start',
          })
          .gridPosition({ y: 0, rows: 2 } as GridPosition)
          .build(),

        new TextWidgetBuilder()
          .text(paymentReviewAccountInfoTemplate)
          .configDisplayName('Payment Review Information')
          .gridPosition({ y: 2, rows: 15 } as GridPosition)
          .cssConfig({
            backGroundColor: 'var(--palette-color-5)',
            marginTop: '10',
            paddingTop: '25',
            paddingBottom: '25',
            paddingLeft: '20',
            paddingRight: '20',
            borderRadiusTopLeft: '5',
            borderRadiusTopRight: '5',
            borderRadiusBottomLeft: '5',
            borderRadiusBottomRight: '5',
            display: 'flex',
            verticalAlignment: 'start',
            textAlignment: 'left',
          })
          .isRemovable(false)
          .build(),

        new FormSubmissionButtonWidgetBuilder()
          .configDisplayName('Button: Confirm & Pay')
          .gridPosition({ y: 19, rows: 2 } as GridPosition)
          .cssConfig({
            paddingTop: 15,
            paddingBottom: 15,
            paddingLeft: 11,
            paddingRight: 11,
          })
          .attributes({
            widgetProperties: {
              textContent: `Confirm & Pay`,
              zoneToNavigate: APP_ZONES.PAYMENT,
              urlToNavigate: 'intl-authentication-sms',
              buttonActionType: ButtonActionType.INIT_INTL_PAYMENT,
            } as BaseWidgetProperties,
          } as WorkflowWidgetConfigurationAttributes)
          .build(),
        new BackButtonWidgetBuilder()
          .defaultBackButton()
          .cssConfig(BackButtonWidgetBuilderCssConfig)
          .gridPosition({ y: 21, rows: 3, cols: 15 } as GridPosition)
          .isRemovable(true)
          .build(),
      ],
    },
    {
      title: 'Intl. Authentication (SMS)',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeSwift,
      ],
      widgets: [
        new TextWidgetBuilder()
          .text('Authentication')
          .configDisplayName('Text Widget: Authentication header')
          .cssConfig({
            color: 'var(--typography-color-0)',
            fontSize: 24,
            display: 'flex',
            isTextBold: true,
            textAlignment: 'left',
          })
          .gridPosition({ rows: 2, y: 2, x: 0 } as GridPosition)
          .isRemovable(false)
          .build(),
        new TextWidgetBuilder()
          .text('Authenticate yourself to initiate the payment')
          .configDisplayName('Text Widget: Subheader')
          .cssConfig({
            color: 'var(--typography-color-1)',
            fontSize: 16,
            display: 'flex',
            textAlignment: 'left',
          })
          .gridPosition({ rows: 1, y: 4, x: 0 } as GridPosition)
          .isRemovable(false)
          .build(),
        ...buildOtpVerificationPageInternationalConfig('phone', 'payment'),
      ],
    },
    {
      title: 'Intl. Insufficient Funds',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeSwift,
      ],
      widgets: [
        ...commonImageWidgetErrorIcon,
        new TextWidgetBuilder()
          .text('<h1>Insufficient Funds</h1>')
          .configDisplayName('Text Widget: Insufficient Funds')
          .cssConfig({
            color: 'var(--alerts-color-0)',
            isTextBold: true,
            fontSize: 12,
            paddingLeft: 50,
            paddingRight: 50,
            verticalAlignment: 'start',
          })
          .gridPosition({ y: 5, rows: 2 } as GridPosition)
          .build(),

        new TextWidgetBuilder()
          .text(
            '<p>Due to added fees, you have insufficient funds to complete this payment. Please add money to your account, or select an account with sufficient funds.</p>',
          )
          .configDisplayName('Text Widget: Description')
          .cssConfig({
            color: 'var(--typography-color-1)',
            fontSize: 14,
            paddingLeft: 20,
            paddingRight: 20,
            textAlign: 'center',
            verticalAlignment: 'center',
          })
          .gridPosition({ y: 7, rows: 4 } as GridPosition)
          .build(),

        new TextWidgetBuilder()
          .text(paymentInsufficientFundAccountInfoTemplate)
          .configDisplayName('Payment Information')
          .gridPosition({ y: 11, rows: 11 } as GridPosition)
          .cssConfig({
            backGroundColor: 'var(--palette-color-5)',
            marginTop: '10',
            paddingTop: '25',
            paddingBottom: '25',
            paddingLeft: '20',
            paddingRight: '20',
            borderRadiusTopLeft: '5',
            borderRadiusTopRight: '5',
            borderRadiusBottomLeft: '5',
            borderRadiusBottomRight: '5',
            display: 'flex',
            verticalAlignment: 'start',
            textAlignment: 'left',
          })
          .isRemovable(false)
          .build(),
        new MandatoryCTAButtonBuilder()
          .configDisplayName('Button: Add Money')
          .gridPosition({ y: 23, rows: 2 } as GridPosition)
          .cssConfig({
            paddingTop: 15,
            paddingBottom: 15,
            paddingLeft: 11,
            paddingRight: 11,
          })
          .attributes({
            widgetProperties: {
              textContent: `Add Money`,
              zoneToNavigate: APP_ZONES.PAYMENT,
              urlToNavigate: 'intl-payment-processing',
            } as BaseWidgetProperties,
          } as WorkflowWidgetConfigurationAttributes)
          .build(),
        getQuickLinkWidgetAsSecondaryButton({
          text: 'Cancel',
          pageUrl: 'intl-pay-beneficiary',
          gridPosition: { y: 25, rows: 3 } as GridPosition,
        }),
      ],
    },
    {
      title: 'Intl. Payment Processing',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeSwift,
      ],
      widgets: [
        getLottieAnimationPaymentProcessingPage(),
        getTextWidgetPaymentProcessingPage(),

        new TextWidgetBuilder()
          .text(
            totalEnteredAmountTemplate(paymentProcessingTotalEnteredAmountBackgroundStyle, 'international'),
          )
          .configDisplayName('Text Widget: Entered Amount')
          .gridPosition({ rows: 5, y: 13 } as GridPosition)
          .isRemovable(false)
          .build(),
      ],
    },
    {
      title: 'Intl. Payment Success',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeSwift,
      ],
      widgets: [
        ...commonImageWidgetSuccessIcon,
        getTextWidgetForPaymentStatusPages({
          text: 'Transaction Successful',
          configDisplayName: 'Text Widget: Transaction Successful',
          css_config: {
            fontSize: 24,
            paddingLeft: 15,
            paddingRight: 15,
            color: 'var(--typography-color-0)',
          },
          gridPosition: { y: 5, rows: 2 },
        }),
        new TextWidgetBuilder()
          .text(transactionInfoTemplate)
          .configDisplayName('Text Widget: Information')
          .gridPosition({ rows: 6, y: 7 } as GridPosition)
          .cssConfig({
            color: 'var(--typography-color-1)',
          })
          .isRemovable(false)
          .build(),

        new TextWidgetBuilder()
          .text(paymentSuccessSummaryTemplate)
          .configDisplayName('Text Widget: Payment Summary')
          .gridPosition({ rows: 6, y: 13 } as GridPosition)
          .cssConfig({
            color: 'var(--typography-color-1)',
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 20,
            paddingRight: 20,
            backGroundColor: 'var(--palette-color-4)',
            textAlignment: 'left',
            borderRadiusTopLeft: '5',
            borderRadiusTopRight: '5',
            borderRadiusBottomLeft: '5',
            borderRadiusBottomRight: '5',
          })
          .isRemovable(false)
          .build(),
        ...commonCurrencyCloudImage,
        getAddBeneficiaryQuickLink({
          pageUrl: 'intl-select-beneficiary',
          icon: '',
          text: 'Make Another Payment',
          configDisplayName: 'Make Another Payment',
          gridPosition: {
            rows: 2,
            y: 25,
          },
        }),

        getQuickLinkWidgetAsSecondaryButton({
          text: 'Exit',
          pageUrl: 'payment-type',
          gridPosition: { y: 27, rows: 3 } as GridPosition,
        }),
      ],
    },
    {
      title: 'Intl. Payment Failed',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeSwift,
      ],
      widgets: [
        ...commonImageWidgetErrorIcon,
        getTextWidgetForPaymentStatusPages({
          text: 'Transaction Failed',
          configDisplayName: 'Text Widget: Transaction Failed',
          css_config: {
            paddingLeft: 50,
            paddingRight: 50,
            color: 'var(--alerts-color-0)',
          },
          gridPosition: { y: 5, rows: 2 },
        }),
        new TextWidgetBuilder()
          .text(
            '<div>If you have any questions or concerns, please feel free to contact customer support.</div>',
          )
          .configDisplayName('Text Widget: Information')
          .gridPosition({ rows: 5, y: 7 } as GridPosition)
          .cssConfig({
            color: 'var(--typography-color-1)',
          })
          .isRemovable(false)
          .build(),
        ...commonCurrencyCloudImage,
        getAddBeneficiaryQuickLink({
          pageUrl: 'intl-select-beneficiary',
          icon: '',
          text: 'Try Again',
          configDisplayName: 'Try Again',
          gridPosition: {
            rows: 2,
            y: 25,
          },
        }),

        getQuickLinkWidgetAsSecondaryButton({
          text: 'Exit',
          pageUrl: 'payment-type',
          gridPosition: { y: 27, rows: 3 } as GridPosition,
        }),
      ],
    },
  ];
  const pagesArray: {
    name: string;
    title: string;
    url?: string;
    widgets?: WorkflowWidgetConfig[];
    tags: string[];
    pageConfiguration: CommonPageConfig;
  }[] = [];
  internationalTransferPaymentPages.forEach((page) => {
    pagesArray.push({
      name: formatPageName(page.title),
      title: page.title,
      tags: [...page.tags],
      url: formatPageName(page.title),
      widgets: page.widgets,
      pageConfiguration: page?.pageConfiguration ?? ({} as CommonPageConfig),
    });
  });
  return pagesArray;
}

function createMultiIconQuickLinkWidget(
  header: string,
  subheader: string,
  pageUrl: string,
  leftIcon: string,
  rightIcon: string,
  gridPosition: any,
) {
  return new MultiIconQuickLinkWidgetBuilder()
    .icons({
      left: leftIcon,
      right: rightIcon,
    })
    .template(
      `<p class="multi-icon-header">${header}</p><span class="multi-icon-subheader">${subheader}</span>`,
    )
    .zone(APP_ZONES.PAYMENT)
    .role()
    .navigateToPageUrl(pageUrl)
    .configDisplayName(`Multi Icon: ${header}`)
    .navigationOption('use-as-navigation')
    .isRemovable(false)
    .cssConfig(multiQuickLinkCssConfig)
    .gridPosition(gridPosition)
    .build();
}

export function buildPaymentsAdditionalPagesConfig(
  paymentTypeUk: string,
  paymentTypeSepa: string,
): {
  name: string;
  title: string;
  url?: string;
  widgets?: WorkflowWidgetConfig[];
  tags: string[];
  pageConfiguration: CommonPageConfig;
}[] {
  const beneficiaryTransferPaymentPages: {
    title: string;
    widgets: WorkflowWidgetConfig[];
    tags: string[];
    pageConfiguration?: CommonPageConfig;
  }[] = [
    {
      title: 'Beneficiary Type (Domestic)',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeUk,
      ],
      widgets: [
        getProgressBarWidgetConfig(paymentTypeProgressBar),

        createMultiIconQuickLinkWidget(
          'Pay Someone New',
          'Pay money to a new beneficiary',
          'add-beneficiary',
          'user-plus-jamboo',
          'arrow-right-jamboo',
          { y: 5 },
        ),

        createMultiIconQuickLinkWidget(
          'Pay Existing Beneficiary',
          'Pay money to an existing beneficiary',
          'select-beneficiary',
          'user',
          'arrow-right-jamboo',
          { y: 9 },
        ),
        new BackButtonWidgetBuilder()
          .defaultBackButton()
          .cssConfig(BackButtonWidgetBuilderCssConfig)
          .gridPosition({ y: 24, rows: 3, cols: 15 } as GridPosition)
          .isRemovable(true)
          .build(),
      ],
    },
    {
      title: 'Add Beneficiary',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeUk,
        paymentTypeSepa,
      ],
      widgets: addBeneficiaryPageWidgets(),
      pageConfiguration: {
        ...defaultPageConfigurations,
        isUsingHtmlStructure: true,
      } as CommonPageConfig,
    },
    {
      title: 'Review Information Match',
      tags: [
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.MODULR,
        PaymentProvider.RAILSR,
        paymentTypeUk,
        paymentTypeSepa,
      ],
      widgets: getReviewBeneficiaryWidgetsForMobile(),
      pageConfiguration: {
        ...defaultPageConfigurations,
        backgroundColor: 'var(--color-background)',
        isUsingHtmlStructure: true,
        isBodyScrollingEnabled: true,
      } as CommonPageConfig,
    },
    {
      title: 'Review Information Match (COP)',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeUk,
      ],
      widgets: [
        new TextWidgetBuilder()
          .text(`<div>{{{ imageFromImageName 'images' 'check-circle-common' '75px'}}}</div>`)
          .configDisplayName('Text Widget: Warning Icon')
          .cssConfig({
            fontSize: 24,
          })
          .gridPosition({ x: 6, y: 1, cols: 3, rows: 3 } as GridPosition)
          .build(),
        new TextWidgetBuilder()
          .text(`<b>Account details are valid</b>`)
          .configDisplayName(`Text Widget: Account details don't match`)
          .cssConfig({
            color: 'var(--typography-color-0)',
            textAlignment: 'center',
            fontSize: 20,
          })
          .gridPosition({ x: 0, y: 4, cols: 15, rows: 1 } as GridPosition)
          .build(),
        new TextWidgetBuilder()
          .text(`The account details you gave us are the same as the details in the banking records.`)
          .configDisplayName(`Text Widget: Account details sub text`)
          .cssConfig({
            color: 'var(--typography-color-0)',
            textAlignment: 'center',
            fontSize: 16,
          })
          .gridPosition({ x: 0, y: 5, cols: 15, rows: 4 } as GridPosition)
          .build(),

        new TextWidgetBuilder()
          .text(copEnteredDetails)
          .configDisplayName('Beneficiary Information')
          .gridPosition({ y: 7, rows: 8 } as GridPosition)
          .cssConfig({
            color: 'var(--typography-color-0)',
            textAlignment: 'center',
            fontSize: 16,
            borderUnit: 'px',
            borderWidth: 1,
            borderRadiusTopLeft: '6',
            borderRadiusTopRight: '6',
            borderRadiusBottomLeft: '6',
            borderRadiusBottomRight: '6',
            paddingTop: '19',
            paddingBottom: '19',
            paddingRight: '20',
            paddingLeft: '20',
            borderColor: 'var(--border-color-2)',
          })
          .isRemovable(false)
          .build(),
        new FormSubmissionButtonWidgetBuilder()
          .configDisplayName('CTA Button: Continue anyway')
          .attributes({
            widgetProperties: {
              textContent: 'Save Recipient',
              buttonActionType: ButtonActionType.ADD_BENEFICIARY,
              zoneToNavigate: APP_ZONES.PAYMENT,
              urlToNavigate: '',
            } as BaseWidgetProperties,
          } as WorkflowWidgetConfigurationAttributes)
          .gridPosition({ y: 18, rows: 2 } as GridPosition)
          .build(),
        new BackButtonWidgetBuilder()
          .defaultBackButton()
          .text(`Back`)
          .cssConfig({
            ...BackButtonWidgetBuilderCssConfig,
            marginBottom: '0',
            marginTop: '0',
            paddingTop: '0',
            paddingBottom: '0',
            paddingRight: '0',
            paddingLeft: '0',
            fontSize: '16',
          })
          .gridPosition({ y: 21, rows: 2, cols: 15 } as GridPosition)
          .isRemovable(false)
          .build(),
      ],
    },
    {
      title: 'Review Information Close Match (COP)',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeUk,
      ],
      widgets: [
        new TextWidgetBuilder()
          .text(`<div>{{{ imageFromImageName 'icon-library/material' 'mat_warning_circle' '57px'}}}</div>`)
          .configDisplayName('Text Widget: Warning Icon')
          .cssConfig({
            fontSize: 24,
          })
          .gridPosition({ x: 6, y: 0, cols: 3, rows: 3 } as GridPosition)
          .build(),
        new TextWidgetBuilder()
          .text(`<b>Account details don't match the account</b>`)
          .configDisplayName(`Text Widget: Account details don't match`)
          .cssConfig({
            color: 'var(--typography-color-0)',
            textAlignment: 'center',
            fontSize: 20,
          })
          .gridPosition({ x: 0, y: 3, cols: 15, rows: 2 } as GridPosition)
          .build(),
        new TextWidgetBuilder()
          .text(`The account details you gave us aren't the same as the details in the banking records.`)
          .configDisplayName(`Text Widget: Account details sub text`)
          .cssConfig({
            color: 'var(--typography-color-0)',
            textAlignment: 'center',
            fontSize: 16,
          })
          .gridPosition({ x: 0, y: 5, cols: 15, rows: 3 } as GridPosition)
          .build(),
        new TextWidgetBuilder()
          .text(copCloseMatchDetails)
          .configDisplayName(`Text Widget: Entered Account Details`)
          .cssConfig({
            color: 'var(--typography-color-0)',
            textAlignment: 'center',
            fontSize: 16,
            borderUnit: 'px',
            borderWidth: 1,
            borderRadiusTopLeft: '6',
            borderRadiusTopRight: '6',
            borderRadiusBottomLeft: '6',
            borderRadiusBottomRight: '6',
            paddingTop: '19',
            paddingBottom: '19',
            paddingRight: '20',
            paddingLeft: '20',
            borderColor: 'var(--border-color-2)',
          })
          .gridPosition({ x: 0, y: 8, cols: 15, rows: 12 } as GridPosition)
          .build(),
        new TextWidgetBuilder()
          .text(copCloseMatchReturnedBankName)
          .configDisplayName(`Text Widget: Their bank told us`)
          .cssConfig({
            color: 'var(--typography-color-0)',
            textAlignment: 'center',
            fontSize: 16,
            borderUnit: 'px',
            borderWidth: 2,
            borderRadiusTopLeft: '6',
            borderRadiusTopRight: '6',
            borderRadiusBottomLeft: '6',
            borderRadiusBottomRight: '6',
            paddingTop: '19',
            paddingBottom: '19',
            paddingRight: '20',
            paddingLeft: '20',
            borderColor: 'var(--alerts-color-0)',
          })
          .gridPosition({ x: 0, y: 21, cols: 15, rows: 4 } as GridPosition)
          .build(),
        new FormSubmissionButtonWidgetBuilder()
          .configDisplayName('CTA Button: Continue anyway')
          .attributes({
            widgetProperties: {
              textContent: 'Continue anyway',
              buttonActionType: ButtonActionType.ADD_BENEFICIARY,
              zoneToNavigate: APP_ZONES.PAYMENT,
              urlToNavigate: '',
            } as BaseWidgetProperties,
          } as WorkflowWidgetConfigurationAttributes)
          .gridPosition({ y: 26, rows: 2 } as GridPosition)
          .build(),
        new BackButtonWidgetBuilder()
          .defaultBackButton()
          .text(
            `Edit {{#if (eq beneficiaryDetails.accountType "personal") }}Account Holder {{else}}Corporate {{/if}}Name`,
          )
          .cssConfig({
            ...BackButtonWidgetBuilderCssConfig,
            marginBottom: '13',
            marginTop: '12',
            paddingTop: '0',
            paddingBottom: '0',
            paddingRight: '0',
            paddingLeft: '0',
            fontSize: '16',
          })
          .gridPosition({ y: 28, rows: 3, cols: 15 } as GridPosition)
          .isRemovable(false)
          .build(),
        new FormSubmissionButtonWidgetBuilder()
          .configDisplayName('CTA Button: Use Suggested Name')
          .attributes({
            widgetProperties: {
              textContent: `Continue with suggestion`,
              buttonActionType: ButtonActionType.USE_SUGGESTED_NAME_COP,
              zoneToNavigate: APP_ZONES.PAYMENT,
              urlToNavigate: '',
            } as BaseWidgetProperties,
          } as WorkflowWidgetConfigurationAttributes)
          .gridPosition({ y: 31, rows: 2 } as GridPosition)
          .build(),
      ],
      pageConfiguration: {
        isBodyScrollingEnabled: true,
      } as CommonPageConfig,
    },
    {
      title: 'Review Information No Match (COP)',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeUk,
      ],
      widgets: [
        new TextWidgetBuilder()
          .text(`<div>{{{ imageFromImageName 'images' 'times-circle' '75px'}}}</div>`)
          .configDisplayName('Text Widget: Warning Icon')
          .cssConfig({
            fontSize: 24,
          })
          .gridPosition({ x: 6, y: 1, cols: 3, rows: 3 } as GridPosition)
          .build(),
        new TextWidgetBuilder()
          .text(`<b>Account details don't match the account</b>`)
          .configDisplayName(`Text Widget: Account details don't match`)
          .cssConfig({
            color: 'var(--typography-color-0)',
            textAlignment: 'center',
            fontSize: 20,
          })
          .gridPosition({ x: 0, y: 4, cols: 15, rows: 2 } as GridPosition)
          .build(),
        new TextWidgetBuilder()
          .text(`{{ copResponse.reason }}`)
          .configDisplayName(`Text Widget: Account details sub text`)
          .cssConfig({
            color: 'var(--typography-color-0)',
            textAlignment: 'center',
            fontSize: 16,
          })
          .gridPosition({ x: 0, y: 6, cols: 15, rows: 4 } as GridPosition)
          .build(),
        new TextWidgetBuilder()
          .text(copNoMatchDetails)
          .configDisplayName(`Text Widget: Entered Account Details`)
          .cssConfig({
            color: 'var(--typography-color-0)',
            textAlignment: 'center',
            fontSize: 16,
            borderUnit: 'px',
            borderWidth: 1,
            borderRadiusTopLeft: '6',
            borderRadiusTopRight: '6',
            borderRadiusBottomLeft: '6',
            borderRadiusBottomRight: '6',
            paddingTop: '19',
            paddingBottom: '19',
            paddingRight: '20',
            paddingLeft: '20',
            borderColor: 'var(--alerts-color-error)',
          })
          .gridPosition({ y: 10, rows: 9 } as GridPosition)
          .build(),
        new FormSubmissionButtonWidgetBuilder()
          .configDisplayName('CTA Button: Continue anyway')
          .attributes({
            widgetProperties: {
              textContent: 'Continue anyway',
              buttonActionType: ButtonActionType.ADD_BENEFICIARY,
              zoneToNavigate: APP_ZONES.PAYMENT,
              urlToNavigate: '',
            } as BaseWidgetProperties,
          } as WorkflowWidgetConfigurationAttributes)
          .gridPosition({ y: 20, rows: 2 } as GridPosition)
          .build(),
        new BackButtonWidgetBuilder()
          .defaultBackButton()
          .text('Back')
          .cssConfig({
            ...BackButtonWidgetBuilderCssConfig,
            marginBottom: '0',
            marginTop: '0',
            paddingTop: '0',
            paddingBottom: '0',
            paddingRight: '0',
            paddingLeft: '0',
            fontSize: '16',
          })
          .gridPosition({ y: 23, rows: 2, cols: 15 } as GridPosition)
          .isRemovable(false)
          .build(),
      ],
    },
    {
      title: 'Review Information Secondary Identifier Required (COP)',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeUk,
      ],
      widgets: [
        new TextWidgetBuilder()
          .text(`<div>{{{ imageFromImageName 'icon-library/material' 'mat_warning_circle' '57px'}}}</div>`)
          .configDisplayName('Text Widget: Warning Icon')
          .cssConfig({
            fontSize: 24,
          })
          .gridPosition({ x: 6, y: 0, cols: 3, rows: 3 } as GridPosition)
          .build(),
        new TextWidgetBuilder()
          .text(`<b>Secondary Identifier Required</b>`)
          .configDisplayName(`Text Widget: Secondary Identifier Required`)
          .cssConfig({
            color: 'var(--typography-color-0)',
            textAlignment: 'center',
            fontSize: 20,
          })
          .gridPosition({ x: 0, y: 3, cols: 15, rows: 2 } as GridPosition)
          .build(),
        new TextWidgetBuilder()
          .text(`The bank requires a secondary identifier for this account.`)
          .configDisplayName(`Text Widget: Secondary Identifier sub text`)
          .cssConfig({
            color: 'var(--typography-color-0)',
            textAlignment: 'center',
            fontSize: 16,
          })
          .gridPosition({ x: 0, y: 5, cols: 15, rows: 3 } as GridPosition)
          .build(),
        new SecondaryIdentifierWidgetBuilder()
          .configDisplayName(`Secondary Identifier Widget`)
          .gridPosition({ x: 0, y: 8, cols: 15, rows: 7 } as GridPosition)
          .build(),
        new BackButtonWidgetBuilder()
          .defaultBackButton()
          .text('Back')
          .cssConfig({
            ...BackButtonWidgetBuilderCssConfig,
            marginBottom: '13',
            marginTop: '12',
            paddingTop: '0',
            paddingBottom: '0',
            paddingRight: '0',
            paddingLeft: '0',
            fontSize: '16',
          })
          .gridPosition({ y: 15, rows: 3, cols: 15 } as GridPosition)
          .isRemovable(false)
          .build(),
      ],
    },
    {
      title: 'Beneficiary Success',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeUk,
        paymentTypeSepa,
      ],
      widgets: [
        ...commonImageWidgetSuccessIcon,

        new TextWidgetBuilder()
          .text('A new beneficiary has been added!')
          .configDisplayName('Text / HTML')
          .cssConfig({
            isTextBold: true,
            fontSize: 24,
            paddingLeft: 50,
            paddingRight: 50,
            verticalAlignment: 'start',
          })
          .gridPosition({ y: 5, rows: 3 } as GridPosition)
          .isRemovable(false)
          .build(),

        ...commonSuccessDeclineBeneficiaryInfoWidget,
        ...commonSuccessDeclineBeneficiaryQuickLink,
      ],
    },
    {
      title: 'Beneficiary Error',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeUk,
      ],
      widgets: [
        ...commonImageWidgetErrorIcon,

        new TextWidgetBuilder()
          .text('There was a problem adding a beneficiary!')
          .configDisplayName('Text Widget: Beneficiary Error')
          .cssConfig({
            isTextBold: true,
            fontSize: 24,
            paddingLeft: 50,
            paddingRight: 50,
            verticalAlignment: 'start',
          })
          .gridPosition({ y: 5, rows: 4 } as GridPosition)
          .build(),

        ...commonSuccessDeclineBeneficiaryInfoWidget,
        ...commonSuccessDeclineBeneficiaryQuickLink,
      ],
    },
    {
      title: 'Select Beneficiary',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeUk,
        paymentTypeSepa,
      ],
      widgets: [
        new TextWidgetBuilder()
          .text('<div class="w-100" style="font-weight: 700"><p>Select Beneficiary</p></div>')
          .configDisplayName('Text Widget: Select Beneficiary')
          .gridPosition({ y: 0, rows: 2 } as GridPosition)
          .cssConfig({
            color: 'var(--palette-color-3)',
            textAlignment: 'center',
            justifyContent: 'center',
            fontSize: 24,
          })
          .isRemovable(false)
          .build(),
        beneficiarySelectionWidgetConfig,

        getAddBeneficiaryQuickLink({
          pageUrl: 'add-beneficiary',
          gridPosition: {
            rows: 3,
            y: 20,
          },
        }),
      ],
    },
    {
      title: 'Payment Initiation Error',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeUk,
      ],
      widgets: [
        new ImageWidgetBuilder()
          .configDisplayName('Image Widget: Error Icon')
          .cssConfig({
            selectedobjectfit: 'contain',
          })
          .gridPosition({ y: 4, rows: 5 } as GridPosition)
          .attributes({
            widgetProperties: {
              textContent: '',
              imageAssetUrl: 'assets/images/payment-decline.svg',
            } as BaseWidgetProperties,
          } as WorkflowWidgetConfigurationAttributes)
          .build(),

        getTextWidgetForPaymentStatusPages({
          text: 'Payment Initiation Error',
          configDisplayName: 'Text Widget: Payment Initiation Error',
          css_config: {
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 10,
          },
          gridPosition: { y: 9, rows: 2 },
        }),

        new TextWidgetBuilder()
          .text(
            '<p class="w-100"> The recipient\'s account details are not linked to a UK-based bank account. To proceed with your payment, please select a recipient with an account that can accept GBP (British Pounds). Ensure the beneficiary\'s account details are correct and include a UK bank sort code and account number. </p>',
          )
          .configDisplayName('Information content')
          .gridPosition({ y: 11, rows: 9 } as GridPosition)
          .cssConfig({
            justifyContent: 'center',
            textAlignment: 'center',
            fontSize: '16',
            lineHeight: '24',
            textFont: 'var(--font-family)',
            color: 'var(--typography-color-1)',
          })
          .isRemovable(false)
          .build(),

        new QuickLinkWidgetBuilder()
          .zone(APP_ZONES.PAYMENT)
          .navigateToPageUrl('payment-type')
          .navigationOption('use-as-navigation')
          .role()
          .configDisplayName('Quick Link: Payments')
          .text('Payments')
          .gridPosition({ y: 25, rows: 2 } as GridPosition)
          .cssConfig({
            backGroundColor: 'var(--palette-color-3)',
            color: '#FFFFFF',
            borderRadiusTopLeft: 5,
            borderRadiusBottomLeft: 5,
            borderRadiusTopRight: 5,
            borderRadiusBottomRight: 5,
            fontSize: 16,
            borderWidth: 1,
            borderColor: 'var(--palette-color-3)',
          })
          .build(),
        new BackButtonWidgetBuilder()
          .defaultBackButton()
          .cssConfig(BackButtonWidgetBuilderCssConfig)
          .gridPosition({ y: 27, rows: 3, cols: 15 } as GridPosition)
          .isRemovable(false)
          .build(),
      ],
    },
    {
      title: 'Initiate a payment',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeUk,
        paymentTypeSepa,
      ],
      widgets: [
        new TextWidgetBuilder()
          .text(selectedAccountCustomerNameTemplate)
          .configDisplayName('Text Widget: Account Name')
          .cssConfig({
            fontSize: 26,
            isTextBold: true,
            verticalAlignment: 'start',
            paddingTop: 20,
          })
          .gridPosition({ rows: 3 } as GridPosition)
          .build(),

        currencyInputFieldWidgetConfig,

        new PaymentRefConfigBuilder()
          .addCustomWidgetProperties({
            customValidator: CustomValidators.PAYMENT_REF_MAX_LENGTH_LOCAL,
          })
          .build(),

        new PaymentReasonWidgetBuilder().isRemovable(false).build(),

        paymentRailSelectorWidget,

        new TextWidgetBuilder()
          .text(selectedAccountItemTemplate(selectedAccountItemTampleteWhiteBgColorStyle))
          .configDisplayName('Text Widget: Account Selected')
          .cssConfig({
            paddingBottom: 15,
            borderRadiusTopLeft: 5,
            borderRadiusBottomLeft: 5,
            borderRadiusTopRight: 5,
            borderRadiusBottomRight: 5,
            noAlignment: true,
          })
          .gridPosition({ y: 23, rows: 4 } as GridPosition)
          .build(),

        new FormSubmissionButtonWidgetBuilder()
          .configDisplayName('CTA Button: Next')
          .attributes({
            widgetProperties: {
              textContent: 'Next',
              buttonActionType: ButtonActionType.PAYMENT_REQUEST,
              zoneToNavigate: APP_ZONES.PAYMENT,
              urlToNavigate: 'payment-summary',
            } as BaseWidgetProperties,
          } as WorkflowWidgetConfigurationAttributes)
          .gridPosition({ y: 27, rows: 2 } as GridPosition)
          .build(),
      ],
    },
    {
      title: 'Payment Summary',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeUk,
        paymentTypeSepa,
      ],
      widgets: [
        new ProgressBarBuilder()
          .text('')
          .attributes({
            widgetProperties: {
              progressBarActiveColor: 'var(--palette-color-3)',
              progressBarColor: 'var(--border-color-1)',
              completionProgressPercentage: '85',
              textContent: '',
            } as BaseWidgetProperties,
          } as WorkflowWidgetConfigurationAttributes)
          .gridPosition({ rows: 3 } as GridPosition)
          .cssConfig({
            backGroundColor: '#ffffff00',
          })
          .isRemovable(true)
          .build(),
        new TextWidgetBuilder()
          .text('Pay Beneficiary')
          .configDisplayName('Text Widget: Pay Beneficiary')
          .cssConfig({
            fontSize: 26,
            isTextBold: true,
            verticalAlignment: 'start',
            noAlignment: true,
          })
          .gridPosition({ y: 3, rows: 3 } as GridPosition)
          .build(),

        new TextWidgetBuilder()
          .text(selectedAccountItemTemplate(selectedAccountItemTemplateGreyBgColorStyle))
          .configDisplayName('Text Widget: Account Selected')
          .cssConfig({
            paddingBottom: 15,
            borderRadiusTopLeft: 5,
            borderRadiusBottomLeft: 5,
            borderRadiusTopRight: 5,
            borderRadiusBottomRight: 5,
            noAlignment: true,
          })
          .gridPosition({ y: 6, rows: 4 } as GridPosition)
          .build(),

        new TextWidgetBuilder()
          .text(totalEnteredAmountTemplate(totalEnteredAmountBackgroundStyle))
          .configDisplayName('Text Widget: Entered Amount')
          .cssConfig({
            marginTop: 1,
            noAlignment: false,
          })
          .gridPosition({ y: 10, rows: 5 } as GridPosition)
          .build(),

        new TextWidgetBuilder()
          .text(paymentSummaryBeneficiaryDetailsTemplate('selectedBeneficiary'))
          .configDisplayName('Text Widget: Beneficiary Details')
          .cssConfig({
            backGroundColor: 'var(--palette-color-5)',
            paddingTop: '25',
            paddingBottom: '25',
            paddingLeft: '20',
            paddingRight: '20',
            borderRadiusTopLeft: '5',
            borderRadiusTopRight: '5',
            borderRadiusBottomLeft: '5',
            borderRadiusBottomRight: '5',
            display: 'flex',
            verticalAlignment: 'start',
            textAlignment: 'left',
          })
          .gridPosition({ y: 15, rows: 12 } as GridPosition)
          .build(),

        new FormSubmissionButtonWidgetBuilder()
          .attributes({
            widgetProperties: {
              textContent: 'Confirm and Pay',
              zoneToNavigate: APP_ZONES.PAYMENT,
              urlToNavigate: 'authentication-sms',
              buttonActionType: ButtonActionType.CHECK_SMS_AUTH_STATUS_AND_REDIRECT,
            } as BaseWidgetProperties,
          } as WorkflowWidgetConfigurationAttributes)
          .cssConfig({
            backGroundColor: 'var(--palette-color-3)',
            borderRadiusTopLeft: 5,
            borderRadiusBottomLeft: 5,
            borderRadiusTopRight: 5,
            borderRadiusBottomRight: 5,
            borderWidth: 1,
            color: 'var(--palette-color-6)',
            fontSize: 16,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 0,
            marginBottom: 0,
          })
          .gridPosition({ y: 29, rows: 2 } as GridPosition)
          .isRemovable(false)
          .build(),
        new BackButtonWidgetBuilder()
          .defaultBackButton()
          .cssConfig(BackButtonWidgetBuilderCssConfig)
          .gridPosition({ y: 31, rows: 3, cols: 15 } as GridPosition)
          .isRemovable(true)
          .build(),
      ],
      pageConfiguration: {
        ...defaultPageConfigurations,
        isBodyScrollingEnabled: true,
      } as CommonPageConfig,
    },
    {
      title: 'Authentication (SMS)',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeUk,
        paymentTypeSepa,
      ],
      widgets: [
        new TextWidgetBuilder()
          .text('Authentication')
          .configDisplayName('Text Widget: Authentication header')
          .cssConfig({
            color: 'var(--typography-color-0)',
            fontSize: 24,
            display: 'flex',
            isTextBold: true,
            textAlignment: 'left',
          })
          .gridPosition({ rows: 2, y: 2, x: 0 } as GridPosition)
          .isRemovable(false)
          .build(),
        new TextWidgetBuilder()
          .text('Authenticate yourself to initiate the payment')
          .configDisplayName('Text Widget: Subheader')
          .cssConfig({
            color: 'var(--typography-color-1)',
            fontSize: 16,
            display: 'flex',
            textAlignment: 'left',
          })
          .gridPosition({ rows: 1, y: 4, x: 0 } as GridPosition)
          .isRemovable(false)
          .build(),
        ...buildOtpVerificationPageWidgetsConfig('phone', 'payment'),
      ],
    },
    {
      title: 'Payment Processing',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeUk,
        paymentTypeSepa,
      ],
      widgets: [
        getLottieAnimationPaymentProcessingPage(),
        getTextWidgetPaymentProcessingPage(),
        new TextWidgetBuilder()
          .text(totalEnteredAmountTemplate(paymentProcessingTotalEnteredAmountBackgroundStyle))
          .configDisplayName('Text Widget: Entered Amount')
          .gridPosition({ rows: 5, y: 13 } as GridPosition)
          .isRemovable(false)
          .build(),
      ],
    },
    {
      title: 'Payment Success',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeUk,
        paymentTypeSepa,
      ],
      widgets: [
        ...commonImageWidgetSuccessIcon,
        getTextWidgetForPaymentStatusPages({
          text: 'Your Payment has been made!',
          configDisplayName: 'Text Widget: Payment Succeeded',
          gridPosition: { y: 5, rows: 2 },
        }),

        ...paymentCommonEnteredAmountWidget,

        new QuickLinkWidgetBuilder()
          .zone(APP_ZONES.PAYMENT)
          .navigateToPageUrl('select-beneficiary')
          .navigationOption('use-as-navigation')
          .role()
          .text('Make another Payment')
          .configDisplayName('Quick Link : Make another Payment')
          .cssConfig({
            backGroundColor: 'var(--palette-color-3)',
            color: 'var(--palette-color-6)',
            borderRadiusTopLeft: 5,
            borderRadiusBottomLeft: 5,
            borderRadiusTopRight: 5,
            borderRadiusBottomRight: 5,
            fontSize: 16,
            marginBottom: 25,
          })
          .gridPosition({ y: 12, rows: 3 } as GridPosition)
          .build(),

        ...paymentCommonHomePageQuickLinkWidget,
      ],
    },
    {
      title: 'Payment Declined',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeUk,
        paymentTypeSepa,
      ],
      widgets: [
        ...commonImageWidgetErrorIcon,
        getTextWidgetForPaymentStatusPages({
          text: 'Your Payment Failed!',
          configDisplayName: 'Text Widget: Payment Failed',
          css_config: {
            paddingLeft: 50,
            paddingRight: 50,
          },
          gridPosition: { y: 5, rows: 2 },
        }),

        ...paymentCommonEnteredAmountWidget,

        new QuickLinkWidgetBuilder()
          .zone(APP_ZONES.PAYMENT)
          .navigateToPageUrl('select-beneficiary')
          .navigationOption('use-as-navigation')
          .role()
          .configDisplayName('Quick Link: Retry Payment')
          .text('Retry Payment')
          .gridPosition({ y: 12, rows: 3 } as GridPosition)
          .cssConfig({
            backGroundColor: 'var(--palette-color-3)',
            color: 'var(--palette-color-6)',
            borderRadiusTopLeft: 5,
            borderRadiusBottomLeft: 5,
            borderRadiusTopRight: 5,
            borderRadiusBottomRight: 5,
            fontSize: 16,
            marginBottom: 25,
          })
          .build(),

        ...paymentCommonHomePageQuickLinkWidget,
      ],
    },
  ];

  const pagesArray: {
    name: string;
    title: string;
    url?: string;
    widgets?: WorkflowWidgetConfig[];
    tags: string[];
    pageConfiguration: CommonPageConfig;
  }[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  beneficiaryTransferPaymentPages.forEach((page) => {
    pagesArray.push({
      name: formatPageName(page.title),
      title: page.title,
      tags: [...page.tags],
      url: formatPageName(page.title),
      widgets: page.widgets,
      pageConfiguration: page?.pageConfiguration ?? ({} as CommonPageConfig),
    });
  });

  return pagesArray;
}

export function buildCurrencyAccountPagesConfig(): {
  name: string;
  title: string;
  url?: string;
  widgets?: WorkflowWidgetConfig[];
  tags: string[];
  pageConfiguration: CommonPageConfig;
}[] {
  const currencyAccountPaymentPages: {
    title: string;
    widgets: WorkflowWidgetConfig[];
    tags: string[];
    pageConfiguration?: CommonPageConfig;
  }[] = [
    {
      title: 'Currency Accounts Landing',
      tags: [PaymentProvider.MODULR, PaymentProvider.INTEGRATED_FINANCE, PaymentProvider.RAILSR],
      widgets: [
        new TextWidgetBuilder()
          .text('Currency Accounts')
          .configDisplayName('Text Widget: Currency Accounts')
          .cssConfig({
            fontSize: 24,
            isTextBold: true,
            verticalAlignment: 'start',
            textAlignment: 'left',
            noAlignment: true,
            textFont: 'Mulish',
          })
          .gridPosition({ rows: 2, y: 0 } as GridPosition)
          .isRemovable(false)
          .build(),
        new CurrencyAccountsListingWidgetBuilder()
          .inputSetting({
            ...inputSettings,
            isIconAllowed: true,
            css_config: {
              iconSize: 24,
              iconSizeUnit: 'px',
            } as InputCssConfig,
          })
          .gridPosition({ y: 2 } as GridPosition)
          .isRemovable(false)
          .build(),
        new QuickLinkWidgetBuilder()
          .icon('add-card-icon')
          .zone(APP_ZONES.PAYMENT)
          .navigateToPageUrl('add-currency-account')
          .navigationOption('use-as-navigation')
          .role()
          .text('Add Account')
          .cssConfig({
            color: 'var(--typography-color-0)',
            borderWidth: '1',
            borderColor: 'var(--border-color-1)',
            fontSize: '16',
            backGroundColor: 'var(--palette-color-6)',
            borderRadiusTopLeft: '5',
            borderRadiusTopRight: '5',
            borderRadiusBottomLeft: '5',
            borderRadiusBottomRight: '5',
            paddingLeft: 15,
            textAlignment: 'left',
            justifyContent: 'start',
            iconSize: '37',
            iconUnit: 'px',
          })
          .gridPosition({ y: 18, rows: 3 } as GridPosition)
          .configDisplayName('Quick Link: Add Account')
          .isRemovable(false)
          .build(),
      ],
    },
    {
      title: 'Add Currency Account',
      tags: [PaymentProvider.MODULR, PaymentProvider.INTEGRATED_FINANCE, PaymentProvider.RAILSR],
      widgets: [
        new TextWidgetBuilder()
          .text('Select Currency')
          .configDisplayName('Text Widget: Select Currency')
          .cssConfig({
            fontSize: 24,
            isTextBold: true,
            verticalAlignment: 'start',
            textAlignment: 'left',
            noAlignment: true,
            textFont: 'Mulish',
          })
          .gridPosition({ rows: 2, y: 0 } as GridPosition)
          .isRemovable(false)
          .build(),
        new AddCurrencyAccountWidgetBuilder()
          .allowedCurrencies([]) // keeping empty when the widget is initialised.
          .setZone('')
          .navigationOption('use-as-blank')
          .navigateToPageUrl('')
          .role()
          .inputSetting({
            ...inputSettings,
            css_config: {
              iconSize: 24,
              iconSizeUnit: 'px',
            } as InputCssConfig,
            label: 'Search for Currencies',
            isIconAllowed: true,
          })
          .gridPosition({ y: 2, rows: 26 } as GridPosition)
          .isRemovable(false)
          .build(),
      ],
    },
    getExchangeCurrencyScreenConfig('Buy', 'Buy'),
    getExchangeCurrencyScreenConfig('Sell', 'Sell'),
    {
      title: 'Exchange Quote',
      tags: [PaymentProvider.INTEGRATED_FINANCE, PaymentProvider.RAILSR],
      pageConfiguration: {
        ...defaultPageConfigurations,
        backgroundColor: 'var(--color-background)',
        isUsingHtmlStructure: true,
      } as CommonPageConfig,
      widgets: [
        new TextWidgetBuilder()
          .text('Exchange Quote')
          .configDisplayName('Text Widget: Exchange Quote')
          .cssConfig({
            isTextBold: true,
            fontSize: 24,
            textAlignment: 'left',
          })
          .gridPosition({ rows: 2, y: 0 } as GridPosition)
          .build(),

        exchangeQuoteWidgetConfig,

        new FormSubmissionButtonWidgetBuilder()
          .attributes({
            widgetProperties: {
              textContent: 'Requote',
              buttonActionType: ButtonActionType.EXCHANGE_RE_QUOTE,
              field_attributes: [quoteExpirationAttributes],
            } as BaseWidgetProperties,
          } as WorkflowWidgetConfigurationAttributes)
          .cssConfig({
            paddingTop: 10,
            paddingRight: 10,
            paddingLeft: 10,
            paddingBottom: 10,
            marginBottom: 10,
          })
          .build(),

        new FormSubmissionButtonWidgetBuilder()
          .attributes({
            widgetProperties: {
              textContent: 'Accept Quote',
              buttonActionType: ButtonActionType.EXCHANGE_ACCEPT_QUOTE,
            } as BaseWidgetProperties,
          } as WorkflowWidgetConfigurationAttributes)
          .cssConfig({
            paddingTop: 10,
            paddingRight: 10,
            paddingLeft: 10,
            paddingBottom: 10,
          })
          .build(),

        new BackButtonWidgetBuilder()
          .defaultBackButton()
          .cssConfig(BackButtonWidgetBuilderCssConfig)
          .gridPosition({ y: 30, rows: 3, cols: 15 } as GridPosition)
          .isRemovable(true)
          .build(),
      ],
    },
    {
      title: 'Conversion Successful',
      tags: [PaymentProvider.INTEGRATED_FINANCE, PaymentProvider.RAILSR],
      pageConfiguration: {
        ...defaultPageConfigurations,
        isUsingHtmlStructure: true,
      } as CommonPageConfig,
      widgets: [
        ...commonImageWidgetSuccessIcon,
        new TextWidgetBuilder()
          .setWidgetProperties({
            textContent: 'Conversion Successful',
            textStyleClass: 'h1',
          } as BaseWidgetProperties)
          .configDisplayName('Text / HTML')
          .cssConfig({
            isTextBold: true,
            fontSize: 24,
            paddingLeft: 10,
            paddingRight: 10,
            verticalAlignment: 'start',
          })
          .gridPosition({ y: 5, rows: 2 } as GridPosition)
          .isRemovable(false)
          .build(),

        ...conversionSuccessFailRefNumber,

        new TextWidgetBuilder()
          .text(
            `<p>Your transaction has been completed successfully. If you have any questions or concerns, please feel free to contact our customer support.</p>`,
          )
          .configDisplayName('Text / HTML')
          .cssConfig({
            verticalAlignment: 'start',
          })
          .gridPosition({ y: 9, rows: 5 } as GridPosition)
          .isRemovable(false)
          .build(),

        exchangeQuoteSummaryConfig,
        ...conversionSuccessFailPaymentsQuicklink,
      ],
    },
    {
      title: 'Conversion Failed',
      tags: [PaymentProvider.INTEGRATED_FINANCE, PaymentProvider.RAILSR],
      pageConfiguration: {
        ...defaultPageConfigurations,
        isUsingHtmlStructure: true,
      } as CommonPageConfig,
      widgets: [
        ...commonImageWidgetErrorIcon,

        new TextWidgetBuilder()
          .setWidgetProperties({
            textContent: 'Conversion Failed',
            textStyleClass: 'h1',
          } as BaseWidgetProperties)
          .configDisplayName('Text / HTML')
          .cssConfig({
            isTextBold: true,
            fontSize: 24,
            paddingLeft: 50,
            paddingRight: 50,
            verticalAlignment: 'start',
          })
          .gridPosition({ y: 7, rows: 2 } as GridPosition)
          .isRemovable(false)
          .build(),

        new TextWidgetBuilder()
          .text(
            `<p>Your transaction has been failed. If you have any questions or concerns, please feel free to contact our customer support.</p>`,
          )
          .configDisplayName('Text / HTML')
          .cssConfig({
            verticalAlignment: 'start',
          })
          .gridPosition({ y: 9, rows: 5 } as GridPosition)
          .isRemovable(false)
          .build(),

        exchangeQuoteSummaryConfig,
        ...conversionSuccessFailPaymentsQuicklink,
      ],
    },
  ];
  const pagesArray: {
    name: string;
    title: string;
    url?: string;
    widgets?: WorkflowWidgetConfig[];
    tags: string[];
    pageConfiguration: CommonPageConfig;
  }[] = [];

  currencyAccountPaymentPages.forEach((page) => {
    pagesArray.push({
      name: formatPageName(page.title),
      title: page.title,
      tags: [...page.tags, PaymentFlowTypes.CURRENCY_ACCOUNT],
      url: formatPageName(page.title),
      widgets: page.widgets,
      pageConfiguration: page?.pageConfiguration ?? ({} as CommonPageConfig),
    });
  });
  return pagesArray;
}

const addIntlBeneficiaryWidgetConfig: CustomWorkflowWidgetConfig = {
  widgetConfiguration: {
    widgetDisplayName: WidgetTitles.PROGRESSBAR_WIDGET,
    widgetConfigDisplayName: WidgetTitles.PROGRESSBAR_WIDGET,
    isRemovable: true,
    attributes: {
      widgetProperties: {
        textContent: 'Beneficiary Bank Details',
        completionProgressPercentage: '60',
        progressBarActiveColor: 'var(--palette-color-3)',
        progressBarColor: 'var(--palette-color-4)',
      },
      elementsConfig: [
        {
          field_name: WidgetNames.PROGRESSBAR_WIDGET,
          label: 'Image.png',
          type: 'file',
          section: WidgetNames.PROGRESSBAR_WIDGET,
          show: true,
          options: [],
        },
      ],
      css_config: {
        ...Constants.defaultBaseCssConfig,
        color: 'var(--typography-color-0)',
        textAlignment: 'center',
        verticalAlignment: 'end',
        fontSize: 25,
        isTextBold: true,
      },
    },
  },
  gridPosition: {
    x: 0,
    y: 0,
    minItemCols: 1,
    minItemRows: 1,
    rows: 3,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
  },
};
const conversionSuccessFailPaymentsQuicklink = [
  new QuickLinkWidgetBuilder()
    .zone(APP_ZONES.PAYMENT)
    .navigateToPageUrl('payment-type')
    .navigationOption('use-as-navigation')
    .role()
    .configDisplayName('Quick Link: Payments')
    .text('Payments')
    .gridPosition({ y: 27, rows: 2 } as GridPosition)
    .cssConfig({
      backGroundColor: 'var(--palette-color-3)',
      color: '#FFFFFF',
      borderRadiusTopLeft: 5,
      borderRadiusBottomLeft: 5,
      borderRadiusTopRight: 5,
      borderRadiusBottomRight: 5,
      fontSize: 16,
      borderWidth: 1,
      borderColor: 'var(--palette-color-3)',
      paddingTop: 10,
      paddingRight: 10,
      paddingLeft: 10,
      paddingBottom: 10,
      marginTop: 10,
    })
    .build(),
];

const conversionSuccessFailRefNumber = [
  new TextWidgetBuilder()
    .text(`<p>Reference Number: {{pageContext.conversionRef}}</p>`)
    .configDisplayName('Text / HTML')
    .cssConfig({
      verticalAlignment: 'start',
    })
    .gridPosition({ y: 7, rows: 2 } as GridPosition)
    .isRemovable(false)
    .build(),
];

const paymentTypeProgressBar: CustomWorkflowWidgetConfig = {
  widgetConfiguration: {
    widgetDisplayName: WidgetTitles.PROGRESSBAR_WIDGET,
    widgetConfigDisplayName: WidgetTitles.PROGRESSBAR_WIDGET,
    isRemovable: true,
    attributes: {
      widgetProperties: {
        textContent: '<span class="pt-3"> Beneficiary Type </span> ',
        completionProgressPercentage: '20',
        progressBarActiveColor: 'var(--palette-color-3)',
        progressBarColor: 'var(--palette-color-4)',
      },
      elementsConfig: [
        {
          field_name: WidgetNames.PROGRESSBAR_WIDGET,
          label: 'Image.png',
          type: 'file',
          section: WidgetNames.PROGRESSBAR_WIDGET,
          show: true,
          options: [],
        },
      ],
      css_config: {
        ...Constants.defaultBaseCssConfig,
        color: 'var(--typography-color-0)',
        textAlignment: 'center',
        verticalAlignment: 'end',
        fontSize: 24,
        textFont: 'Mulish',
        isTextBold: true,
      },
    },
  },
};
const associatedPaymentPages: {
  name: string;
  title: string;
  url?: string;
  widgets?: WorkflowWidgetConfig[];
  tags: string[];
}[] = [
  {
    name: 'payment-type',
    title: 'Payment Type',
    url: 'payment-type',
    widgets: [
      new TextWidgetBuilder()
        .text('<h2 class="w-100 font-weight-bold"> Payment Type </h2>')
        .configDisplayName('Payment Type')
        .gridPosition({ y: 0 } as GridPosition)
        .cssConfig({
          textAlignment: 'center',
          justifyContent: 'center',
          fontSize: '24',
        })
        .isRemovable(false)
        .build(),
    ],
    tags: [PaymentFlowTypes.UK_FASTER_PAYMENTS, PaymentFlowTypes.SEPA, PaymentFlowTypes.SWIFT],
  },
];

function getPaymentType(tags: string[]): WorkflowWidgetConfig[] {
  const widgets: WorkflowWidgetConfig[] = [
    new TextWidgetBuilder()
      .text('<h2 class="w-100 font-weight-bold"> Payment Type </h2>')
      .configDisplayName('Payment Type')
      .gridPosition({ y: 0 } as GridPosition)
      .cssConfig({
        textAlignment: 'center',
        justifyContent: 'center',
        fontSize: '24',
        textFont: 'Mulish',
      })
      .isRemovable(false)
      .build(),
  ];
  tags.forEach((tag) => {
    switch (tag) {
      case PaymentFlowTypes.UK_FASTER_PAYMENTS:
        widgets.push(
          new MultiIconQuickLinkWidgetBuilder()
            .icons({
              left: 'bank',
              right: 'arrow-right-jamboo',
            })
            .template(
              '<p class="multi-icon-header"> UK faster payments</p><span class="multi-icon-subheader">Pay money domestically</span>',
            )
            .zone(APP_ZONES.PAYMENT)
            .role()
            .navigateToPageUrl('beneficiary-type-domestic')
            .configDisplayName('Multi Icon: Make Bank Payment')
            .attributes({
              hideCondition: "neq currency 'GBP'",
            } as WorkflowWidgetConfigurationAttributes)
            .navigationOption('use-as-navigation')
            .cssConfig({ ...multiQuickLinkCssConfig, textAlignment: 'left' })
            .isRemovable(false)
            .gridPosition({ y: 4 } as GridPosition)
            .build(),
        );
        break;

      case PaymentFlowTypes.CURRENCY_ACCOUNT:
        widgets.push(
          new MultiIconQuickLinkWidgetBuilder()
            .icons({
              left: 'currency-account',
              right: 'arrow-right-jamboo',
            })
            .template(
              '<p class="multi-icon-header">Currency Accounts</p><span class="multi-icon-subheader">Manage your accounts</span>',
            )
            .zone(APP_ZONES.PAYMENT)
            .role()
            .navigateToPageUrl('currency-accounts-landing')
            .configDisplayName('Multi Icon: Currency Accounts')
            .navigationOption('use-as-navigation')
            .isRemovable(false)
            .cssConfig({ ...multiQuickLinkCssConfig, textAlignment: 'left' })
            .gridPosition({ y: 12 } as GridPosition)
            .build(),

          new MultiIconQuickLinkWidgetBuilder()
            .icons({
              left: 'exchange-currency',
              right: 'arrow-right-jamboo',
            })
            .template(
              '<p class="multi-icon-header" >Exchange Currency</p><span class="multi-icon-subheader" >Exchange currencies within accounts</span>',
            )
            .zone(APP_ZONES.PAYMENT)
            .role()
            .navigateToPageUrl('exchange-currency-buy')
            .configDisplayName('Multi Icon: Exchange Currency')
            .navigationOption('use-as-navigation')
            .isRemovable(false)
            .cssConfig({ ...multiQuickLinkCssConfig, textAlignment: 'left' })
            .gridPosition({ y: 16 } as GridPosition)
            .build(),
        );
        break;

      case PaymentFlowTypes.SWIFT:
        widgets.push(
          new MultiIconQuickLinkWidgetBuilder()
            .icons({
              left: 'international',
              right: 'arrow-right-jamboo',
            })
            .template(
              '<p class="multi-icon-header" >International Transfer</p><span class="multi-icon-subheader" >Pay money internationally</span>',
            )
            .zone(APP_ZONES.PAYMENT)
            .role()
            .navigateToPageUrl('beneficiary-type-int')
            .configDisplayName('Multi Icon: International Transfer')
            .navigationOption('use-as-navigation')
            .isRemovable(false)
            .cssConfig({ ...multiQuickLinkCssConfig, textAlignment: 'left' })
            .gridPosition({ y: 8 } as GridPosition)
            .build(),
        );
        break;

      case PaymentFlowTypes.BULK_PAYMENTS:
        widgets.push(
          new MultiIconQuickLinkWidgetBuilder()
            .icons({
              left: 'users',
              right: 'arrow-right-jamboo',
            })
            .template(
              '<p class="multi-icon-header" >Bulk Pay</p><span class="multi-icon-subheader" >Pay to multiple accounts at once</span>',
            )
            .zone(APP_ZONES.PAYMENT)
            .role()
            .navigateToPageUrl('bulk-payment-landing')
            .configDisplayName('Multi Icon: Bulk Payment')
            .navigationOption('use-as-navigation')
            .isRemovable(false)
            .cssConfig({ ...multiQuickLinkCssConfig, textAlignment: 'left' })
            .gridPosition({ y: 20 } as GridPosition)
            .build(),
        );
        break;
      case PaymentFlowTypes.SEPA:
        widgets.push(
          new FormSubmissionButtonWidgetBuilder()
            .configDisplayName('CTA Button: SEPA Payment')
            .attributes({
              widgetProperties: {
                textContent:
                  '<p class="body1 textOnSurface">SEPA</p><spanclass="labelText textOnSurfaceMuted">Eurozone bank transfers.</span>',
                buttonActionType: ButtonActionType.SEPA_TRANSFER_PAYMENT_FLOW,
                zoneToNavigate: APP_ZONES.PAYMENT,
                urlToNavigate: `select-beneficiary`,
                lefticon: 'euro-icon',
                righticon: 'arrow-right-jamboo',
              } as BaseWidgetProperties,
              hideCondition: "neq currency 'EUR'",
            } as WorkflowWidgetConfigurationAttributes)
            .cssConfig({
              ...multiQuickLinkCssConfig,
              righticonSize: 24,
              lefticonSize: 32,
              textAlignment: 'left',
            })
            .gridPosition({ y: 24, rows: 3 } as GridPosition)
            .build(),
        );
        break;
    }
  });
  return widgets;
}

function getSwitchNavigatorIntlBeneficiaryWidgetConfig(): WorkflowWidgetConfig {
  const widgetConfig = new SwitchNavigatorWidgetBuilder()
    .linksData([
      {
        textContent: 'Personal',
        pageUrl: 'intl-beneficiary-bank-details-personal',
        zone: APP_ZONES.PAYMENT,
      },
      {
        textContent: 'Business',
        pageUrl: 'intl-beneficiary-bank-details-business',
        zone: APP_ZONES.PAYMENT,
      },
    ])
    .navigationOption('use-as-navigation')
    .role()
    .cssConfig(switchNavigatorCss)
    .build();

  return widgetConfig;
}

function getSwitchNavigatorWidgetConfigForCurrency(): WorkflowWidgetConfig {
  const widgetConfig = new SwitchNavigatorWidgetBuilder()
    .linksData([
      {
        textContent: 'Buy',
        pageUrl: 'exchange-currency-buy',
        zone: APP_ZONES.PAYMENT,
      },
      {
        textContent: 'Sell',
        pageUrl: 'exchange-currency-sell',
        zone: APP_ZONES.PAYMENT,
      },
    ])
    .navigationOption('use-as-navigation')
    .role()
    .gridPosition({ y: 5, rows: 2 } as GridPosition)
    .cssConfig({
      ...switchNavigatorCss,
      borderColor: 'var(--border-color-1)',
      backGroundColor: 'var(--palette-color-3)',
      disabledTextColor: 'var(--typography-color-1)',
      paddingTop: 8,
      paddingBottom: 8,
    })
    .build();

  return widgetConfig;
}

const paymentCommonEnteredAmountWidget = [
  new TextWidgetBuilder()
    .text(totalEnteredAmountTemplate(''))
    .configDisplayName('Text Widget: Entered Amount')
    .gridPosition({ y: 7, rows: 5 } as GridPosition)
    .build(),
];

const commonSuccessDeclineBeneficiaryInfoWidget = [
  new TextWidgetBuilder()
    .text(beneficiaryDetailsTemplate)
    .configDisplayName('Beneficiary Information')
    .gridPosition({ y: 9, rows: 12 } as GridPosition)
    .cssConfig({
      backGroundColor: '#FFFFFF',
      paddingTop: '25',
      paddingBottom: '25',
      paddingLeft: '20',
      paddingRight: '20',
      borderRadiusTopLeft: '5',
      borderRadiusTopRight: '5',
      borderRadiusBottomLeft: '5',
      borderRadiusBottomRight: '5',
      display: 'flex',
      verticalAlignment: 'start',
      textAlignment: 'left',
    })
    .isRemovable(false)
    .build(),
];

const commonSuccessDeclineBeneficiaryQuickLink = [
  new QuickLinkWidgetBuilder()
    .zone(APP_ZONES.PAYMENT)
    .navigateToPageUrl('select-beneficiary')
    .navigationOption('use-as-navigation')
    .role()
    .text('Go to Beneficiary List')
    .configDisplayName('Quick Link: Go to Beneficiary List')
    .cssConfig({
      backGroundColor: 'var(--palette-color-3)',
      color: 'var(--palette-color-6)',
      borderRadiusTopLeft: 5,
      borderRadiusBottomLeft: 5,
      borderRadiusTopRight: 5,
      borderRadiusBottomRight: 5,
      fontSize: 16,
      marginBottom: 25,
    })
    .gridPosition({ y: 21, rows: 3 } as GridPosition)
    .build(),
];

const commonImageWidgetErrorIcon = [
  new ImageWidgetBuilder()
    .configDisplayName('Image Widget: Error Icon')
    .cssConfig({
      selectedobjectfit: 'contain',
    })
    .gridPosition({ rows: 5 } as GridPosition)
    .attributes({
      widgetProperties: {
        textContent: '',
        imageAssetUrl: 'assets/images/payment-decline.svg',
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .build(),
];

const commonImageWidgetSuccessIcon = [
  new ImageWidgetBuilder()
    .configDisplayName('Image Widget: Success Icon')
    .cssConfig({
      selectedobjectfit: 'contain',
    })
    .attributes({
      widgetProperties: {
        imageAssetUrl: 'assets/images/tick-success.svg',
        textContent: '',
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .gridPosition({ rows: 5 } as GridPosition)
    .build(),
];

const commonCurrencyCloudImage = [
  new ImageWidgetBuilder()
    .configDisplayName('Image Widget: Currency Cloud')
    .cssConfig({
      selectedobjectfit: 'contain',
    })
    .attributes({
      widgetProperties: {
        imageAssetUrl: 'assets/images/widget-icons/payment-zone/currency-cloud.png',
        textContent: '',
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .gridPosition({ rows: 4, cols: 11, y: 20, x: 2 } as GridPosition)
    .build(),
];

const paymentCommonHomePageQuickLinkWidget = [
  new QuickLinkWidgetBuilder()
    .zone(APP_ZONES.LANDING)
    .navigateToPageUrl('home')
    .navigationOption('use-as-navigation')
    .role()
    .text('Home')
    .configDisplayName('Quick Link: Home')
    .cssConfig({
      borderWidth: 1,
      borderColor: 'var(--border-color-3)',
      color: 'var(--palette-color-3)',
      borderRadiusTopLeft: 5,
      borderRadiusBottomLeft: 5,
      borderRadiusTopRight: 5,
      borderRadiusBottomRight: 5,
      fontSize: 16,
    })
    .gridPosition({ y: 15, rows: 2 } as GridPosition)
    .build(),
];

function getTextWidgetForExchangeCurrencyScreens(configData: { text: string }): WorkflowWidgetConfig {
  const css_config_exchange = {
    textFont: 'Mulish',
    fontSize: 24,
    isTextBold: true,
    textAlignment: 'left',
  };
  const css_config_buy_sell = {
    textFont: 'Mulish',
    fontSize: 18,
    textAlignment: 'left',
    color: 'var(--typography-color-1)',
  };
  const gridOptionsExchange = { y: 1, rows: 2 } as GridPosition;
  const gridOptionsBuySell = { y: 3, rows: 1 } as GridPosition;
  return new TextWidgetBuilder()
    .text(configData.text)
    .configDisplayName('Text Widget: ' + configData.text)
    .cssConfig(configData.text.includes('Exchange') ? css_config_exchange : css_config_buy_sell)
    .gridPosition(configData.text.includes('Exchange') ? gridOptionsExchange : gridOptionsBuySell)
    .build();
}

function getQuickLinkWidgetAsSecondaryButton(overRideConfig: any = {}): WorkflowWidgetConfig {
  return new QuickLinkWidgetBuilder()
    .zone(APP_ZONES.PAYMENT)
    .navigateToPageUrl(overRideConfig?.pageUrl ?? '')
    .navigationOption('use-as-navigation')
    .role()
    .text(overRideConfig?.text ?? 'Back')
    .cssConfig(
      overRideConfig.cssConfig
        ? overRideConfig.cssConfig
        : {
            color: 'var(--palette-color-3)',
            borderWidth: '1',
            borderColor: 'var(--palette-color-3)',
            fontSize: '14',
            borderRadiusTopLeft: '6',
            borderRadiusTopRight: '6',
            borderRadiusBottomLeft: '6',
            borderRadiusBottomRight: '6',
            marginBottom: '12',
            marginTop: '12',
            paddingTop: '11',
            paddingBottom: '11',
            paddingRight: '11',
            paddingLeft: '11',
            textAlignment: 'center',
            justifyContent: 'center',
            textFont: 'var(--font-family)',
          },
    )
    .gridPosition(overRideConfig?.gridPosition ?? ({ y: 21, rows: 2 } as GridPosition))
    .configDisplayName(`Quick Link: ${overRideConfig?.text ? overRideConfig?.text : 'Back'}`)
    .isRemovable(false)
    .build();
}

export function getExchangeCurrencyWidget(
  context: 'Buy' | 'Sell',
  gridPosition: GridPosition,
): WorkflowWidgetConfig {
  return new ExchangeCurrencyWidgetBuilder()
    .context(context)
    .configDisplayName('Exchange Currency: Sell')
    .inputSetting({
      ...inputSettings,
      isIconAllowed: false,
      showFlags: true,
      buyAccountLabel: 'Buy:',
      sellAccountLabel: 'Sell:',
      amountInputLabel: 'Amount:',
    })
    .nestedWidgets({
      quoteButton: {
        name: WidgetNames.BUTTON,
        widgetConfigDisplayName: 'Quote Button Styling',
        attributes: {
          widgetProperties: {
            textContent: 'Quote',
          },
          elementsConfig: [],
          css_config: {
            ...Constants.defaultUiNestedButtonCssConfig,
            paddingTop: 10,
            paddingLeft: 10,
            paddingRight: 10,
            paddingBottom: 10,
            marginBottom: 0,
          },
        },
      },
    })
    .gridPosition(gridPosition)
    .build();
}
export function getBeneficiaryListConfig(override: any = {}): WorkflowWidgetConfig {
  return {
    ...beneficiarySelectionWidgetConfig,
    widgetConfiguration: {
      ...beneficiarySelectionWidgetConfig.widgetConfiguration,
      attributes: {
        ...beneficiarySelectionWidgetConfig.widgetConfiguration.attributes,
        widgetProperties: {
          textContent: '',
          showFlag: true,
          icon: 'search',
          inputFieldSettings: {
            isIconAllowed: true,
            label: 'Search',
            isAllowed: true,
            css_config: {
              iconSize: 24,
              iconSizeUnit: 'px',
            } as InputCssConfig,
          },
          widgetContext: override.widgetContext,
        },
        css_config: {
          ...beneficiarySelectionWidgetConfig.widgetConfiguration.attributes.css_config,
          paddingTop: 12,
          paddingLeft: 10,
          paddingRight: 10,
          paddingBottom: 12,
        },
        itemConfig: {
          itemTemplate: override
            ? override.textContent
            : beneficiarySelectionWidgetConfig.widgetConfiguration.attributes['itemConfig'].itemTemplate,
        },
      },
      isRemovable: false,
    },
    gridPosition: override?.gridPosition
      ? ({
          ...beneficiarySelectionWidgetConfig.gridPosition,
          ...override.gridPosition,
        } as GridPosition)
      : beneficiarySelectionWidgetConfig.gridPosition,
  };
}
function getAddBeneficiaryQuickLink(overRideConfig?: any) {
  return new QuickLinkWidgetBuilder()
    .icon(overRideConfig?.icon !== undefined ? overRideConfig.icon : 'plus-circle')
    .zone(APP_ZONES.PAYMENT)
    .navigateToPageUrl(overRideConfig ? overRideConfig.pageUrl : '')
    .text(overRideConfig?.text ? overRideConfig.text : 'Add New Beneficiary')
    .navigationOption('use-as-navigation')
    .role()
    .cssConfig({
      backGroundColor: 'var(--palette-color-3)',
      color: '#fff',
      paddingTop: 20,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 20,
      borderRadiusTopLeft: 5,
      borderRadiusBottomLeft: 5,
      borderRadiusTopRight: 5,
      borderRadiusBottomRight: 5,
      borderWidth: 1,
      borderColor: 'var(--palette-color-3)',
      fontSize: 16,
    })
    .configDisplayName(
      `Quick Link: ${
        overRideConfig?.configDisplayName ? overRideConfig.configDisplayName : 'Add Beneficiary'
      }`,
    )
    .isRemovable(false)
    .gridPosition(
      overRideConfig?.gridPosition
        ? (overRideConfig.gridPosition as GridPosition)
        : ({ rows: 3 } as GridPosition),
    )
    .build();
}
function getLottieAnimationPaymentProcessingPage(): WorkflowWidgetConfig {
  return new LottieAnimationWidgetBuilder()
    .setWidgetProperties({
      animationSource: 'Asset URL',
      assetUrl: 'assets/JSON/loader-animation.json',
      animationType: 'Loop',
      animationContainerType: 'Default',
      textContent: '',
    })
    .gridPosition({ x: 4, y: 2 } as GridPosition)
    .isRemovable(true)
    .build();
}
function getTextWidgetPaymentProcessingPage(): WorkflowWidgetConfig {
  return new TextWidgetBuilder()
    .text(paymentProcessingWaitTextTemplate)
    .configDisplayName('Text Widget: Processing')
    .cssConfig({
      isTextBold: true,
      fontSize: 24,
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 0,
      verticalAlignment: 'end',
    })
    .gridPosition({ rows: 2, y: 8 } as GridPosition)
    .isRemovable(false)
    .build();
}
export function getTextWidgetForPaymentStatusPages(override: any = {}): WorkflowWidgetConfig {
  return new TextWidgetBuilder()
    .text(override?.text)
    .configDisplayName(override?.configDisplayName)
    .cssConfig({
      isTextBold: true,
      fontSize: 24,
      paddingLeft: 35,
      paddingRight: 35,
      verticalAlignment: 'start',
      ...override?.css_config,
    })
    .gridPosition(
      override?.gridPosition ? (override.gridPosition as GridPosition) : ({ rows: 2 } as GridPosition),
    )
    .build();
}

function getExchangeCurrencyScreenConfig(
  title: string,
  actionType: 'Buy' | 'Sell',
): {
  title: string;
  widgets: WorkflowWidgetConfig[];
  tags: string[];
} {
  return {
    title: `Exchange Currency - ${title}`,
    tags: [PaymentProvider.INTEGRATED_FINANCE, PaymentProvider.RAILSR],
    widgets: [
      getTextWidgetForExchangeCurrencyScreens({
        text: 'Exchange Currency',
      }),
      getTextWidgetForExchangeCurrencyScreens({
        text: `Select fixed side currency:`,
      }),
      getSwitchNavigatorWidgetConfigForCurrency(),
      getExchangeCurrencyWidget(actionType, { y: 7, rows: 15 } as GridPosition),
      new BackButtonWidgetBuilder()
        .defaultBackButton()
        .cssConfig(BackButtonWidgetBuilderCssConfig)
        .gridPosition({ y: 22, rows: 3, cols: 15 } as GridPosition)
        .isRemovable(true)
        .build(),
    ],
  };
}

function getIntlBeneficiaryDetailsWidgets(type: 'personal' | 'business') {
  return [
    getProgressBarWidgetConfig(addIntlBeneficiaryWidgetConfig),
    getSwitchNavigatorIntlBeneficiaryWidgetConfig(),
    getAddIntlBeneficiaryCredentialsWidget(type),
    new BackButtonWidgetBuilder()
      .defaultBackButton()
      .configDisplayName('Back Navigation Button')
      .cssConfig(BackButtonWidgetBuilderCssConfig)
      .gridPosition({ y: 19, rows: 3, cols: 15 } as GridPosition)
      .isRemovable(true)
      .build(),
  ];
}

export function buildPaymentsAdditionalDesktopPagesConfig(
  paymentTypeUk: string,
  paymentTypeSepa: string,
): {
  name: string;
  title: string;
  url?: string;
  widgets?: WorkflowWidgetConfig[];
  tags: string[];
  pageConfiguration: CommonPageConfig;
}[] {
  const beneficiaryTransferPaymentPages: {
    title: string;
    widgets: WorkflowWidgetConfig[];
    tags: string[];
    pageConfiguration?: CommonPageConfig;
  }[] = [
    {
      title: 'Beneficiary Type (Domestic)',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeUk,
      ],
      widgets: [
        getProgressBarWidgetConfig(paymentTypeProgressBar),

        createMultiIconQuickLinkWidget(
          'Pay Someone New',
          'Pay money to a new beneficiary',
          'add-beneficiary',
          'user-plus-jamboo',
          'arrow-right-jamboo',
          { y: 5 },
        ),

        createMultiIconQuickLinkWidget(
          'Pay Existing Beneficiary',
          'Pay money to an existing beneficiary',
          'select-beneficiary',
          'user',
          'arrow-right-jamboo',
          { y: 9 },
        ),
        new BackButtonWidgetBuilder()
          .defaultBackButton()
          .cssConfig(BackButtonWidgetBuilderCssConfig)
          .gridPosition({ y: 24, rows: 3, cols: 15 } as GridPosition)
          .isRemovable(true)
          .build(),
      ],
    },
    {
      title: 'Add Beneficiary',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeUk,
        paymentTypeSepa,
      ],
      widgets: addBeneficiaryPageWidgets(),
      pageConfiguration: {
        ...defaultPageConfigurations,
        isUsingHtmlStructure: true,
      } as CommonPageConfig,
    },
    {
      title: 'Review Information Match',
      tags: [
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.MODULR,
        PaymentProvider.RAILSR,
        paymentTypeUk,
        paymentTypeSepa,
      ],
      widgets: getReviewBeneficiaryWidgetsForDesktop(),
      pageConfiguration: {
        ...defaultPageConfigurations,
        backgroundColor: 'var(--color-background)',
        isUsingHtmlStructure: true,
      } as CommonPageConfig,
    },
    {
      title: 'Review Information Match (COP)',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeUk,
      ],
      widgets: [
        new TextWidgetBuilder()
          .text('<b>UK Bank Transfer</b>')
          .configDisplayName('Text Widget: UK Bank Transfer')
          .cssConfig({
            color: 'var(--typography-color-0)',
            textAlignment: 'left',
            fontSize: 24,
          })
          .gridPosition({ x: 2, y: 0, cols: 15, rows: 2, layerIndex: 1 } as GridPosition)
          .build(),
        new TextWidgetBuilder()
          .text('')
          .configDisplayName('Text Widget: Details Background')
          .cssConfig({
            backGroundColor: 'var(--palette-color-6)',
            borderWidth: 1,
            borderUnit: 'px',
            borderRadiusTopLeft: '6',
            borderRadiusTopRight: '6',
            borderRadiusBottomLeft: '6',
            borderRadiusBottomRight: '6',
            borderColor: 'var(--border-color-1)',
            marginBottom: '20',
            marginUnit: 'px',
          })
          .gridPosition({ x: 14, y: 4, cols: 18, rows: 14, layerIndex: 0 } as GridPosition)
          .build(),
        new TextWidgetBuilder()
          .text(`<div>{{{ imageFromImageName 'images' 'check-circle-common' '75px'}}}</div>`)
          .configDisplayName('Text Widget: Success Icon')
          .cssConfig({
            fontSize: 24,
          })
          .gridPosition({ x: 21, y: 4, cols: 4, rows: 3, layerIndex: 1 } as GridPosition)
          .build(),
        new TextWidgetBuilder()
          .text(`<b>Account details are valid</b>`)
          .configDisplayName(`Text Widget: Account details match`)
          .cssConfig({
            color: 'var(--typography-color-0)',
            textAlignment: 'center',
            fontSize: 20,
          })
          .gridPosition({ x: 15, y: 7, cols: 16, rows: 1, layerIndex: 1 } as GridPosition)
          .build(),
        new TextWidgetBuilder()
          .text(`The account details you gave us are the same as the details in the banking records.`)
          .configDisplayName(`Text Widget: Account details sub text`)
          .cssConfig({
            color: 'var(--typography-color-0)',
            textAlignment: 'center',
            fontSize: 16,
          })
          .gridPosition({ x: 15, y: 8, cols: 16, rows: 3, layerIndex: 1 } as GridPosition)
          .build(),

        new TextWidgetBuilder()
          .text(copEnteredDetails)
          .configDisplayName('Beneficiary Information')
          .gridPosition({ x: 15, y: 11, cols: 16, rows: 6, layerIndex: 1 } as GridPosition)
          .cssConfig({
            color: 'var(--typography-color-0)',
            textAlignment: 'center',
            fontSize: 16,
            borderUnit: 'px',
            borderWidth: 1,
            borderRadiusTopLeft: '6',
            borderRadiusTopRight: '6',
            borderRadiusBottomLeft: '6',
            borderRadiusBottomRight: '6',
            paddingTop: '19',
            paddingBottom: '19',
            paddingRight: '20',
            paddingLeft: '20',
            borderColor: 'var(--alerts-color-success)',
          })
          .isRemovable(false)
          .build(),
        new FormSubmissionButtonWidgetBuilder()
          .configDisplayName('CTA Button: Continue anyway')
          .attributes({
            widgetProperties: {
              textContent: 'Save Recipient',
              buttonActionType: ButtonActionType.ADD_BENEFICIARY,
              zoneToNavigate: APP_ZONES.PAYMENT,
              urlToNavigate: '',
            } as BaseWidgetProperties,
          } as WorkflowWidgetConfigurationAttributes)
          .gridPosition({ x: 24, y: 18, rows: 1, cols: 8, layerIndex: 1 } as GridPosition)
          .build(),
        new BackButtonWidgetBuilder()
          .defaultBackButton()
          .text(`Back`)
          .cssConfig({
            ...BackButtonWidgetBuilderCssConfig,
            marginBottom: '0',
            marginTop: '0',
            paddingTop: '0',
            paddingBottom: '0',
            paddingRight: '0',
            paddingLeft: '0',
          })
          .gridPosition({ x: 14, y: 18, rows: 1, cols: 8, layerIndex: 1 } as GridPosition)
          .isRemovable(false)
          .build(),
      ],
    },
    {
      title: 'Review Information Close Match (COP)',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeUk,
      ],
      widgets: [
        new TextWidgetBuilder()
          .text('<b>UK Bank Transfer</b>')
          .configDisplayName('Text Widget: UK Bank Transfer')
          .cssConfig({
            color: 'var(--typography-color-0)',
            textAlignment: 'left',
            fontSize: 24,
          })
          .gridPosition({ x: 2, y: 0, cols: 15, rows: 2, layerIndex: 1 } as GridPosition)
          .build(),
        new TextWidgetBuilder()
          .text('')
          .configDisplayName('Text Widget: Details Background')
          .cssConfig({
            backGroundColor: 'var(--palette-color-6)',
            borderWidth: 1,
            borderUnit: 'px',
            borderRadiusTopLeft: '6',
            borderRadiusTopRight: '6',
            borderRadiusBottomLeft: '6',
            borderRadiusBottomRight: '6',
            borderColor: 'var(--border-color-1)',
            marginBottom: '20',
            marginUnit: 'px',
          })
          .gridPosition({ x: 14, y: 2, cols: 18, rows: 18, layerIndex: 0 } as GridPosition)
          .build(),
        new TextWidgetBuilder()
          .text(`<div>{{{ imageFromImageName 'icon-library/material' 'mat_warning_circle' '57px'}}}</div>`)
          .configDisplayName('Text Widget: Warning Icon')
          .cssConfig({
            fontSize: 24,
          })
          .gridPosition({ x: 21, y: 2, cols: 4, rows: 3, layerIndex: 1 } as GridPosition)
          .build(),
        new TextWidgetBuilder()
          .text(`<b>Account details don't match the account</b>`)
          .configDisplayName(`Text Widget: Account details don't match`)
          .cssConfig({
            color: 'var(--typography-color-0)',
            textAlignment: 'center',
            fontSize: 20,
          })
          .gridPosition({ x: 15, y: 5, cols: 16, rows: 1, layerIndex: 1 } as GridPosition)
          .build(),
        new TextWidgetBuilder()
          .text(`The account details you gave us aren't the same as the details in the banking records.`)
          .configDisplayName(`Text Widget: Account details sub text`)
          .cssConfig({
            color: 'var(--typography-color-0)',
            textAlignment: 'center',
            fontSize: 16,
          })
          .gridPosition({ x: 15, y: 6, cols: 16, rows: 3, layerIndex: 1 } as GridPosition)
          .build(),

        new TextWidgetBuilder()
          .text(copCloseMatchDetails)
          .configDisplayName(`Text Widget: Entered Account Details`)
          .cssConfig({
            color: 'var(--typography-color-0)',
            textAlignment: 'center',
            fontSize: 16,
            borderUnit: 'px',
            borderWidth: 1,
            borderRadiusTopLeft: '6',
            borderRadiusTopRight: '6',
            borderRadiusBottomLeft: '6',
            borderRadiusBottomRight: '6',
            paddingTop: '19',
            paddingBottom: '19',
            paddingRight: '20',
            paddingLeft: '20',
            borderColor: 'var(--border-color-2)',
          })
          .gridPosition({ x: 15, y: 9, cols: 16, rows: 6, layerIndex: 1 } as GridPosition)
          .isRemovable(false)
          .build(),
        new TextWidgetBuilder()
          .text(copCloseMatchReturnedBankName)
          .configDisplayName(`Text Widget: Their bank told us`)
          .cssConfig({
            color: 'var(--typography-color-0)',
            textAlignment: 'center',
            fontSize: 16,
            borderUnit: 'px',
            borderWidth: 2,
            borderRadiusTopLeft: '6',
            borderRadiusTopRight: '6',
            borderRadiusBottomLeft: '6',
            borderRadiusBottomRight: '6',
            paddingTop: '19',
            paddingBottom: '19',
            paddingRight: '20',
            paddingLeft: '20',
            borderColor: 'var(--alerts-color-0)',
          })
          .gridPosition({ x: 15, y: 16, cols: 16, rows: 3, layerIndex: 1 } as GridPosition)
          .build(),
        new FormSubmissionButtonWidgetBuilder()
          .configDisplayName('CTA Button: Continue anyway')
          .attributes({
            widgetProperties: {
              textContent: 'Continue anyway',
              buttonActionType: ButtonActionType.ADD_BENEFICIARY,
              zoneToNavigate: APP_ZONES.PAYMENT,
              urlToNavigate: '',
            } as BaseWidgetProperties,
          } as WorkflowWidgetConfigurationAttributes)
          .gridPosition({ x: 24, y: 20, rows: 1, cols: 8, layerIndex: 1 } as GridPosition)
          .build(),
        new BackButtonWidgetBuilder()
          .defaultBackButton()
          .text(
            `Edit {{#if (eq beneficiaryDetails.accountType "personal") }}Account Holder {{else}}Corporate {{/if}}Name`,
          )
          .cssConfig({
            ...BackButtonWidgetBuilderCssConfig,
            marginBottom: '0',
            marginTop: '0',
            paddingTop: '0',
            paddingBottom: '0',
            paddingRight: '0',
            paddingLeft: '0',
          })
          .gridPosition({ x: 14, y: 20, rows: 1, cols: 8, layerIndex: 1 } as GridPosition)
          .isRemovable(false)
          .build(),
        new FormSubmissionButtonWidgetBuilder()
          .configDisplayName('CTA Button: Use Suggested Name')
          .attributes({
            widgetProperties: {
              textContent: `Continue with suggestion`,
              buttonActionType: ButtonActionType.USE_SUGGESTED_NAME_COP,
              zoneToNavigate: APP_ZONES.PAYMENT,
              urlToNavigate: '',
            } as BaseWidgetProperties,
            hideCondition: `eq copResponse.matchStatusCode 'name_matched'`,
          } as WorkflowWidgetConfigurationAttributes)
          .gridPosition({ x: 14, y: 22, rows: 1, cols: 18, layerIndex: 1 } as GridPosition)
          .build(),
      ],
    },
    {
      title: 'Review Information No Match (COP)',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeUk,
      ],
      widgets: [
        new TextWidgetBuilder()
          .text('<b>UK Bank Transfer</b>')
          .configDisplayName('Text Widget: UK Bank Transfer')
          .cssConfig({
            color: 'var(--typography-color-0)',
            textAlignment: 'left',
            fontSize: 24,
          })
          .gridPosition({ x: 2, y: 0, cols: 15, rows: 2, layerIndex: 1 } as GridPosition)
          .build(),
        new TextWidgetBuilder()
          .text('')
          .configDisplayName('Text Widget: Details Background')
          .cssConfig({
            backGroundColor: 'var(--palette-color-6)',
            borderWidth: 1,
            borderUnit: 'px',
            borderRadiusTopLeft: '6',
            borderRadiusTopRight: '6',
            borderRadiusBottomLeft: '6',
            borderRadiusBottomRight: '6',
            borderColor: 'var(--border-color-1)',
            marginBottom: '20',
            marginUnit: 'px',
          })
          .gridPosition({ x: 14, y: 4, cols: 18, rows: 14, layerIndex: 0 } as GridPosition)
          .build(),
        new TextWidgetBuilder()
          .text(`<div>{{{ imageFromImageName 'images' 'times-circle' '75px'}}}</div>`)
          .configDisplayName('Text Widget: Warning Icon')
          .cssConfig({
            fontSize: 24,
          })
          .gridPosition({ x: 21, y: 4, cols: 4, rows: 3, layerIndex: 1 } as GridPosition)
          .build(),
        new TextWidgetBuilder()
          .text(`<b>Account details don't match the account</b>`)
          .configDisplayName(`Text Widget: Account details don't match`)
          .cssConfig({
            color: 'var(--typography-color-0)',
            textAlignment: 'center',
            fontSize: 20,
          })
          .gridPosition({ x: 15, y: 7, cols: 16, rows: 1, layerIndex: 1 } as GridPosition)
          .build(),
        new TextWidgetBuilder()
          .text(`{{ copResponse.reason }}`)
          .configDisplayName(`Text Widget: Account details sub text`)
          .cssConfig({
            color: 'var(--typography-color-0)',
            textAlignment: 'center',
            fontSize: 16,
          })
          .gridPosition({ x: 15, y: 8, cols: 16, rows: 3, layerIndex: 1 } as GridPosition)
          .build(),

        new TextWidgetBuilder()
          .text(copNoMatchDetails)
          .configDisplayName(`Text Widget: Entered Account Details`)
          .cssConfig({
            color: 'var(--typography-color-0)',
            textAlignment: 'center',
            fontSize: 16,
            borderUnit: 'px',
            borderWidth: 1,
            borderRadiusTopLeft: '6',
            borderRadiusTopRight: '6',
            borderRadiusBottomLeft: '6',
            borderRadiusBottomRight: '6',
            paddingTop: '19',
            paddingBottom: '19',
            paddingRight: '20',
            paddingLeft: '20',
            borderColor: 'var(--alerts-color-error)',
          })
          .isRemovable(false)
          .gridPosition({ x: 15, y: 11, cols: 16, rows: 6, layerIndex: 1 } as GridPosition)
          .build(),
        new FormSubmissionButtonWidgetBuilder()
          .configDisplayName('CTA Button: Continue anyway')
          .attributes({
            widgetProperties: {
              textContent: 'Save Recipient',
              buttonActionType: ButtonActionType.ADD_BENEFICIARY,
              zoneToNavigate: APP_ZONES.PAYMENT,
              urlToNavigate: '',
            } as BaseWidgetProperties,
          } as WorkflowWidgetConfigurationAttributes)
          .gridPosition({ x: 24, y: 18, rows: 1, cols: 8, layerIndex: 1 } as GridPosition)
          .build(),
        new BackButtonWidgetBuilder()
          .defaultBackButton()
          .text(`Back`)
          .cssConfig({
            ...BackButtonWidgetBuilderCssConfig,
            marginBottom: '0',
            marginTop: '0',
            paddingTop: '0',
            paddingBottom: '0',
            paddingRight: '0',
            paddingLeft: '0',
          })
          .gridPosition({ x: 14, y: 18, rows: 1, cols: 8, layerIndex: 1 } as GridPosition)
          .isRemovable(false)
          .build(),
      ],
    },
    {
      title: 'Review Information Secondary Identifier Required (COP)',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeUk,
      ],
      widgets: [
        new TextWidgetBuilder()
          .text('<b>UK Bank Transfer</b>')
          .configDisplayName('Text Widget: UK Bank Transfer')
          .cssConfig({
            color: 'var(--typography-color-0)',
            textAlignment: 'left',
            fontSize: 24,
          })
          .gridPosition({ x: 2, y: 0, cols: 15, rows: 2, layerIndex: 1 } as GridPosition)
          .build(),
        new TextWidgetBuilder()
          .text('')
          .configDisplayName('Text Widget: Details Background')
          .cssConfig({
            backGroundColor: 'var(--palette-color-6)',
            borderWidth: 1,
            borderUnit: 'px',
            borderRadiusTopLeft: '6',
            borderRadiusTopRight: '6',
            borderRadiusBottomLeft: '6',
            borderRadiusBottomRight: '6',
            borderColor: 'var(--border-color-1)',
            marginBottom: '20',
            marginUnit: 'px',
          })
          .gridPosition({ x: 14, y: 4, cols: 18, rows: 14, layerIndex: 0 } as GridPosition)
          .build(),
        new TextWidgetBuilder()
          .text(`<div>{{{ imageFromImageName 'icon-library/material' 'mat_warning_circle' '57px'}}}</div>`)
          .configDisplayName('Text Widget: Warning Icon')
          .cssConfig({
            fontSize: 24,
          })
          .gridPosition({ x: 21, y: 4, cols: 4, rows: 3, layerIndex: 1 } as GridPosition)
          .build(),
        new TextWidgetBuilder()
          .text(`<b>Secondary Identifier Required</b>`)
          .configDisplayName(`Text Widget: Secondary Identifier Required`)
          .cssConfig({
            color: 'var(--typography-color-0)',
            textAlignment: 'center',
            fontSize: 20,
          })
          .gridPosition({ x: 15, y: 7, cols: 16, rows: 1, layerIndex: 1 } as GridPosition)
          .build(),
        new TextWidgetBuilder()
          .text(`The bank requires a secondary identifier for this account.`)
          .configDisplayName(`Text Widget: Secondary Identifier sub text`)
          .cssConfig({
            color: 'var(--typography-color-0)',
            textAlignment: 'center',
            fontSize: 16,
          })
          .gridPosition({ x: 15, y: 8, cols: 16, rows: 3, layerIndex: 1 } as GridPosition)
          .build(),
        new SecondaryIdentifierWidgetBuilder()
          .configDisplayName(`Secondary Identifier Widget`)
          .gridPosition({ x: 15, y: 11, cols: 16, rows: 4, layerIndex: 1 } as GridPosition)
          .build(),
        new BackButtonWidgetBuilder()
          .defaultBackButton()
          .text('Back')
          .cssConfig({
            ...BackButtonWidgetBuilderCssConfig,
            marginBottom: '0',
            marginTop: '0',
            paddingTop: '0',
            paddingBottom: '0',
            paddingRight: '0',
            paddingLeft: '0',
            fontSize: '16',
          })
          .gridPosition({ x: 15, y: 15, rows: 1, cols: 16, layerIndex: 1 } as GridPosition)
          .isRemovable(false)
          .build(),
      ],
    },
    {
      title: 'Beneficiary Success',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeUk,
        paymentTypeSepa,
      ],
      widgets: [
        ...commonImageWidgetSuccessIcon,

        new TextWidgetBuilder()
          .text('A new beneficiary has been added!')
          .configDisplayName('Text / HTML')
          .cssConfig({
            isTextBold: true,
            fontSize: 24,
            paddingLeft: 50,
            paddingRight: 50,
            verticalAlignment: 'start',
          })
          .gridPosition({ y: 5, rows: 3 } as GridPosition)
          .isRemovable(false)
          .build(),

        ...commonSuccessDeclineBeneficiaryInfoWidget,
        ...commonSuccessDeclineBeneficiaryQuickLink,
      ],
    },
    {
      title: 'Beneficiary Error',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeUk,
      ],
      widgets: [
        ...commonImageWidgetErrorIcon,

        new TextWidgetBuilder()
          .text('There was a problem adding a beneficiary!')
          .configDisplayName('Text Widget: Beneficiary Error')
          .cssConfig({
            isTextBold: true,
            fontSize: 24,
            paddingLeft: 50,
            paddingRight: 50,
            verticalAlignment: 'start',
          })
          .gridPosition({ y: 5, rows: 4 } as GridPosition)
          .build(),

        ...commonSuccessDeclineBeneficiaryInfoWidget,
        ...commonSuccessDeclineBeneficiaryQuickLink,
      ],
    },
    {
      title: 'Select Beneficiary',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeUk,
        paymentTypeSepa,
      ],
      widgets: [
        new TextWidgetBuilder()
          .text('<div class="w-100" style="font-weight: 700"><p>Select Beneficiary</p></div>')
          .configDisplayName('Text Widget: Select Beneficiary')
          .gridPosition({ y: 0, rows: 2 } as GridPosition)
          .cssConfig({
            color: 'var(--palette-color-3)',
            textAlignment: 'center',
            justifyContent: 'center',
            fontSize: 24,
          })
          .isRemovable(false)
          .build(),
        beneficiarySelectionWidgetConfig,
        getAddBeneficiaryQuickLink({
          pageUrl: 'add-beneficiary',
          gridPosition: {
            rows: 3,
            y: 20,
          },
        }),
      ],
    },
    {
      title: 'Payment Initiation Error',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeUk,
      ],
      widgets: [
        new ImageWidgetBuilder()
          .configDisplayName('Image Widget: Error Icon')
          .cssConfig({
            selectedobjectfit: 'contain',
          })
          .gridPosition({ y: 4, rows: 5 } as GridPosition)
          .attributes({
            widgetProperties: {
              textContent: '',
              imageAssetUrl: 'assets/images/payment-decline.svg',
            } as BaseWidgetProperties,
          } as WorkflowWidgetConfigurationAttributes)
          .build(),

        getTextWidgetForPaymentStatusPages({
          text: 'Payment Initiation Error',
          configDisplayName: 'Text Widget: Payment Initiation Error',
          css_config: {
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 10,
          },
          gridPosition: { y: 9, rows: 2 },
        }),

        new TextWidgetBuilder()
          .text(
            '<p class="w-100"> The recipient\'s account details are not linked to a UK-based bank account. To proceed with your payment, please select a recipient with an account that can accept GBP (British Pounds). Ensure the beneficiary\'s account details are correct and include a UK bank sort code and account number. </p>',
          )
          .configDisplayName('Information content')
          .gridPosition({ y: 11, rows: 9 } as GridPosition)
          .cssConfig({
            justifyContent: 'center',
            textAlignment: 'center',
            fontSize: '16',
            lineHeight: '24',
            textFont: 'var(--font-family)',
            color: 'var(--typography-color-1)',
          })
          .isRemovable(false)
          .build(),

        new QuickLinkWidgetBuilder()
          .zone(APP_ZONES.PAYMENT)
          .navigateToPageUrl('payment-type')
          .navigationOption('use-as-navigation')
          .role()
          .configDisplayName('Quick Link: Payments')
          .text('Payments')
          .gridPosition({ y: 25, rows: 2 } as GridPosition)
          .cssConfig({
            backGroundColor: 'var(--palette-color-3)',
            color: '#FFFFFF',
            borderRadiusTopLeft: 5,
            borderRadiusBottomLeft: 5,
            borderRadiusTopRight: 5,
            borderRadiusBottomRight: 5,
            fontSize: 16,
            borderWidth: 1,
            borderColor: 'var(--palette-color-3)',
          })
          .build(),
        new BackButtonWidgetBuilder()
          .defaultBackButton()
          .cssConfig(BackButtonWidgetBuilderCssConfig)
          .gridPosition({ y: 27, rows: 3, cols: 15 } as GridPosition)
          .isRemovable(false)
          .build(),
      ],
    },
    {
      title: 'Initiate a payment',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeUk,
        paymentTypeSepa,
      ],
      widgets: [
        new TextWidgetBuilder()
          .text(selectedAccountCustomerNameTemplate)
          .configDisplayName('Text Widget: Account Name')
          .cssConfig({
            fontSize: 26,
            isTextBold: true,
            verticalAlignment: 'start',
            paddingTop: 20,
          })
          .gridPosition({ rows: 3 } as GridPosition)
          .build(),

        currencyInputFieldWidgetConfig,

        new PaymentRefConfigBuilder()
          .addCustomWidgetProperties({
            customValidator: CustomValidators.PAYMENT_REF_MAX_LENGTH_LOCAL,
          })
          .build(),

        new PaymentReasonWidgetBuilder().isRemovable(false).build(),

        paymentRailSelectorWidget,

        new TextWidgetBuilder()
          .text(selectedAccountItemTemplate(selectedAccountItemTampleteWhiteBgColorStyle))
          .configDisplayName('Text Widget: Account Selected')
          .cssConfig({
            paddingBottom: 15,
            borderRadiusTopLeft: 5,
            borderRadiusBottomLeft: 5,
            borderRadiusTopRight: 5,
            borderRadiusBottomRight: 5,
            noAlignment: true,
          })
          .gridPosition({ y: 23, rows: 4 } as GridPosition)
          .build(),

        new FormSubmissionButtonWidgetBuilder()
          .configDisplayName('CTA Button: Next')
          .attributes({
            widgetProperties: {
              textContent: 'Next',
              buttonActionType: ButtonActionType.PAYMENT_REQUEST,
              zoneToNavigate: APP_ZONES.PAYMENT,
              urlToNavigate: 'payment-summary',
            } as BaseWidgetProperties,
          } as WorkflowWidgetConfigurationAttributes)
          .gridPosition({ y: 27, rows: 2 } as GridPosition)
          .build(),
      ],
    },
    {
      title: 'Payment Summary',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeUk,
        paymentTypeSepa,
      ],
      widgets: [
        new ProgressBarBuilder()
          .text('')
          .attributes({
            widgetProperties: {
              progressBarActiveColor: 'var(--palette-color-3)',
              progressBarColor: 'var(--border-color-1)',
              completionProgressPercentage: '85',
              textContent: '',
            } as BaseWidgetProperties,
          } as WorkflowWidgetConfigurationAttributes)
          .gridPosition({ rows: 3 } as GridPosition)
          .cssConfig({
            backGroundColor: '#ffffff00',
          })
          .isRemovable(true)
          .build(),
        new TextWidgetBuilder()
          .text('Pay Beneficiary')
          .configDisplayName('Text Widget: Pay Beneficiary')
          .cssConfig({
            fontSize: 26,
            isTextBold: true,
            verticalAlignment: 'start',
            noAlignment: true,
          })
          .gridPosition({ y: 3, rows: 3 } as GridPosition)
          .build(),

        new TextWidgetBuilder()
          .text(selectedAccountItemTemplate(selectedAccountItemTemplateGreyBgColorStyle))
          .configDisplayName('Text Widget: Account Selected')
          .cssConfig({
            paddingBottom: 15,
            borderRadiusTopLeft: 5,
            borderRadiusBottomLeft: 5,
            borderRadiusTopRight: 5,
            borderRadiusBottomRight: 5,
            noAlignment: true,
          })
          .gridPosition({ y: 6, rows: 4 } as GridPosition)
          .build(),

        new TextWidgetBuilder()
          .text(totalEnteredAmountTemplate(totalEnteredAmountBackgroundStyle))
          .configDisplayName('Text Widget: Entered Amount')
          .cssConfig({
            marginTop: 1,
            noAlignment: false,
          })
          .gridPosition({ y: 10, rows: 5 } as GridPosition)
          .build(),

        new TextWidgetBuilder()
          .text(paymentSummaryBeneficiaryDetailsTemplate('selectedBeneficiary'))
          .configDisplayName('Text Widget: Beneficiary Details')
          .cssConfig({
            backGroundColor: 'var(--palette-color-5)',
            paddingTop: '25',
            paddingBottom: '25',
            paddingLeft: '20',
            paddingRight: '20',
            borderRadiusTopLeft: '5',
            borderRadiusTopRight: '5',
            borderRadiusBottomLeft: '5',
            borderRadiusBottomRight: '5',
            display: 'flex',
            verticalAlignment: 'start',
            textAlignment: 'left',
          })
          .gridPosition({ y: 15, rows: 12 } as GridPosition)
          .build(),

        new FormSubmissionButtonWidgetBuilder()
          .attributes({
            widgetProperties: {
              textContent: 'Confirm and Pay',
              zoneToNavigate: APP_ZONES.PAYMENT,
              urlToNavigate: 'authentication-sms',
              buttonActionType: ButtonActionType.CHECK_SMS_AUTH_STATUS_AND_REDIRECT,
            } as BaseWidgetProperties,
          } as WorkflowWidgetConfigurationAttributes)
          .cssConfig({
            backGroundColor: 'var(--palette-color-3)',
            borderRadiusTopLeft: 5,
            borderRadiusBottomLeft: 5,
            borderRadiusTopRight: 5,
            borderRadiusBottomRight: 5,
            borderWidth: 1,
            color: 'var(--palette-color-6)',
            fontSize: 16,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 0,
            marginBottom: 0,
          })
          .gridPosition({ y: 29, rows: 2 } as GridPosition)
          .isRemovable(false)
          .build(),
        new BackButtonWidgetBuilder()
          .defaultBackButton()
          .cssConfig(BackButtonWidgetBuilderCssConfig)
          .gridPosition({ y: 31, rows: 3, cols: 15 } as GridPosition)
          .isRemovable(true)
          .build(),
      ],
      pageConfiguration: {
        ...defaultPageConfigurations,
        isBodyScrollingEnabled: true,
      } as CommonPageConfig,
    },
    {
      title: 'Authentication (SMS)',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeUk,
        paymentTypeSepa,
      ],
      widgets: [
        new TextWidgetBuilder()
          .text('Authentication')
          .configDisplayName('Text Widget: Authentication header')
          .cssConfig({
            color: 'var(--typography-color-0)',
            fontSize: 24,
            display: 'flex',
            isTextBold: true,
            textAlignment: 'left',
          })
          .gridPosition({ rows: 2, y: 2, x: 0 } as GridPosition)
          .isRemovable(false)
          .build(),
        new TextWidgetBuilder()
          .text('Authenticate yourself to initiate the payment')
          .configDisplayName('Text Widget: Subheader')
          .cssConfig({
            color: 'var(--typography-color-1)',
            fontSize: 16,
            display: 'flex',
            textAlignment: 'left',
          })
          .gridPosition({ rows: 1, y: 4, x: 0 } as GridPosition)
          .isRemovable(false)
          .build(),
        ...buildOtpVerificationPageWidgetsConfig('phone', 'payment'),
      ],
    },
    {
      title: 'Payment Processing',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeUk,
        paymentTypeSepa,
      ],
      widgets: [
        getLottieAnimationPaymentProcessingPage(),
        getTextWidgetPaymentProcessingPage(),
        new TextWidgetBuilder()
          .text(totalEnteredAmountTemplate(paymentProcessingTotalEnteredAmountBackgroundStyle))
          .configDisplayName('Text Widget: Entered Amount')
          .gridPosition({ rows: 5, y: 13 } as GridPosition)
          .isRemovable(false)
          .build(),
      ],
    },
    {
      title: 'Payment Success',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeUk,
        paymentTypeSepa,
      ],
      widgets: [
        ...commonImageWidgetSuccessIcon,
        getTextWidgetForPaymentStatusPages({
          text: 'Your Payment has been made!',
          configDisplayName: 'Text Widget: Payment Succeeded',
          gridPosition: { y: 5, rows: 2 },
        }),

        ...paymentCommonEnteredAmountWidget,

        new QuickLinkWidgetBuilder()
          .zone(APP_ZONES.PAYMENT)
          .navigateToPageUrl('select-beneficiary')
          .navigationOption('use-as-navigation')
          .role()
          .text('Make another Payment')
          .configDisplayName('Quick Link : Make another Payment')
          .cssConfig({
            backGroundColor: 'var(--palette-color-3)',
            color: 'var(--palette-color-6)',
            borderRadiusTopLeft: 5,
            borderRadiusBottomLeft: 5,
            borderRadiusTopRight: 5,
            borderRadiusBottomRight: 5,
            fontSize: 16,
            marginBottom: 25,
          })
          .gridPosition({ y: 12, rows: 3 } as GridPosition)
          .build(),

        ...paymentCommonHomePageQuickLinkWidget,
      ],
    },
    {
      title: 'Payment Declined',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentProvider.RAILSR,
        paymentTypeUk,
        paymentTypeSepa,
      ],
      widgets: [
        ...commonImageWidgetErrorIcon,
        getTextWidgetForPaymentStatusPages({
          text: 'Your Payment Failed!',
          configDisplayName: 'Text Widget: Payment Failed',
          css_config: {
            paddingLeft: 50,
            paddingRight: 50,
          },
          gridPosition: { y: 5, rows: 2 },
        }),

        ...paymentCommonEnteredAmountWidget,

        new QuickLinkWidgetBuilder()
          .zone(APP_ZONES.PAYMENT)
          .navigateToPageUrl('select-beneficiary')
          .navigationOption('use-as-navigation')
          .role()
          .configDisplayName('Quick Link: Retry Payment')
          .text('Retry Payment')
          .gridPosition({ y: 12, rows: 3 } as GridPosition)
          .cssConfig({
            backGroundColor: 'var(--palette-color-3)',
            color: 'var(--palette-color-6)',
            borderRadiusTopLeft: 5,
            borderRadiusBottomLeft: 5,
            borderRadiusTopRight: 5,
            borderRadiusBottomRight: 5,
            fontSize: 16,
            marginBottom: 25,
          })
          .build(),

        ...paymentCommonHomePageQuickLinkWidget,
      ],
    },
  ];

  const pagesArray: {
    name: string;
    title: string;
    url?: string;
    widgets?: WorkflowWidgetConfig[];
    tags: string[];
    pageConfiguration: CommonPageConfig;
  }[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  beneficiaryTransferPaymentPages.forEach((page) => {
    pagesArray.push({
      name: formatPageName(page.title),
      title: page.title,
      tags: [...page.tags],
      url: formatPageName(page.title),
      widgets: page.widgets,
      pageConfiguration: page?.pageConfiguration ?? ({} as CommonPageConfig),
    });
  });
  return pagesArray;
}

export function buildCommonPaymentPagesConfig(): {
  name: string;
  title: string;
  url?: string;
  widgets?: WorkflowWidgetConfig[];
  tags: string[];
  pageConfiguration: CommonPageConfig;
}[] {
  const commonPaymentPages: {
    name: string;
    title: string;
    widgets: WorkflowWidgetConfig[];
    tags: string[];
    pageConfiguration?: CommonPageConfig;
  }[] = [
    {
      title: 'Transaction Elaborated View',
      name: 'transaction-elaborated-view',
      tags: [
        PaymentProvider.MODULR,
        PaymentProvider.INTEGRATED_FINANCE,
        PaymentFlowTypes.COMMON_UK_FASTER_PAYMENTS_SWIFT,
      ],
      widgets: transactionElaboratedViewWidgets(),
      pageConfiguration: {
        ...defaultPageConfigurations,
        backgroundColor: 'var(--color-background)',
        isUsingHtmlStructure: true,
      } as CommonPageConfig,
    },
  ];

  const pagesArray: {
    name: string;
    title: string;
    url?: string;
    widgets?: WorkflowWidgetConfig[];
    tags: string[];
    pageConfiguration: CommonPageConfig;
  }[] = [];

  commonPaymentPages.forEach((page) => {
    pagesArray.push({
      name: page.name,
      title: page.title,
      tags: [...page.tags],
      url: page.name,
      widgets: page.widgets,
      pageConfiguration: page?.pageConfiguration ?? ({} as CommonPageConfig),
    });
  });
  return pagesArray;
}
