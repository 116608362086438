import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileErrorCodes } from '@app/translations';
import { FormActionTypeEnum } from '@finxone-platform/form-action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { ClearFormDataActionWithId } from '../../../actions/form-submission.action';
import { FormActionState } from '../../../state/form-submision.state';
import { findAndShowAlertFromCode } from '../../alert-code-utils/alert-code.utils';
import { redirectToPage } from '../cta-button-actions.utils';

/**
 * Toggles the biometric authentication setting and updates the application state accordingly.
 *
 * @param injector - The Angular Injector used to retrieve necessary services.
 * @param widgetProperties - Properties of the widget that may influence navigation.
 *
 * This function retrieves the current biometric authentication preference from the store,
 * updates the local storage with the new preference, and communicates the change to a
 * Flutter WebView handler. It also triggers an alert based on the success of enabling or
 * disabling biometric authentication and clears the form data related to the toggle action.
 * Finally, it redirects the user to a specified page based on the widget properties.
 */
export function toggleBiometricsAuthentication(injector: Injector, widgetProperties: BaseWidgetProperties) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  const alertService = injector.get(AlertHandlerService);
  const flutterWebView = (window as any).flutter_inappwebview;
  const biometricsFormValue = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.BIOMETRICS_AUTHENTICATION_TOGGLE),
  )?.formData;
  updateBiometricsFlagInStorage(biometricsFormValue.currentValue);
  flutterWebView?.callHandler('biometricsPreference', biometricsFormValue?.currentValue ?? false);
  const alertCode = biometricsFormValue.currentValue
    ? ProfileErrorCodes.BIOMETRIC_AUTH_ENABLED_SUCCESSFULLY
    : ProfileErrorCodes.BIOMETRIC_AUTH_DISABLED_SUCCESSFULLY;
  findAndShowAlertFromCode(store, router, alertService, [alertCode]);
  store.dispatch(new ClearFormDataActionWithId(FormActionTypeEnum.BIOMETRICS_AUTHENTICATION_TOGGLE));
  redirectToPage(router, widgetProperties, injector);
}

/**
 * Updates the biometric authentication flag in the application's local storage.
 *
 * This function retrieves the current application preferences from local storage,
 * updates the `isBiometricEnabled` flag based on the `isChecked` parameter, and
 * saves the updated preferences back to local storage. If no preferences exist,
 * it initializes the preferences with the biometric flag.
 *
 * @param isChecked - A boolean indicating whether biometric authentication should be enabled.
 */
export function updateBiometricsFlagInStorage(isChecked: boolean) {
  const appPreferences = localStorage.getItem('APP_PREFERENCES');
  if (appPreferences) {
    const parsedPreferences = JSON.parse(appPreferences);
    parsedPreferences.isBiometricEnabled = isChecked;
    localStorage.setItem('APP_PREFERENCES', JSON.stringify(parsedPreferences));
  } else {
    localStorage.setItem(
      'APP_PREFERENCES',
      JSON.stringify({
        isBiometricEnabled: isChecked,
      }),
    );
  }
}
