import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { UploadRevenirTransactionReceipt } from '../../../actions/revenir.action';
import { RevenirState } from '../../../state/revenir.state';
import { redirectToPage } from '../cta-button-actions.utils';

/**
 * Uploads a reviewed transaction document and redirects to a specified page.
 *
 * @param widgetProperties - The properties of the widget, including navigation details.
 * @param injector - The Angular injector used to retrieve services.
 *
 * This function retrieves the current transaction details from the store,
 * dispatches an action to upload the transaction receipt using the transaction ID,
 * and then redirects to a page specified by the widget properties.
 */
export const uploadReviewedTransactionDocument = (
  widgetProperties: BaseWidgetProperties,
  injector: Injector,
) => {
  const store = injector.get(Store);
  const router = injector.get(Router);
  const transactionDetails = store.selectSnapshot(RevenirState.getSelectedRevenirTransactionDetails);

  store.dispatch(new UploadRevenirTransactionReceipt(transactionDetails?.transaction_id));

  redirectToPage(router, widgetProperties, injector, true);
};
