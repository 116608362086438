import { APP_ZONES, ButtonActionType, GridPosition } from '@finxone-platform/shared/sys-config-types';
import {
  BackButtonWidgetBuilder,
  RevenirTransactionDocumentWidgetBuilder,
} from '../../../../widgets/example-builder';
import { commonBackButtonQuickLink, commonCTAButton, commonTextWidget } from '../../../common/common-widgets';

export const reviewPhotoWidgets = [
  commonBackButtonQuickLink({
    cssConfig: {
      iconSize: 35,
      iconUnit: 'px',
    },
    gridPosition: {
      y: 0,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 2,
      layerIndex: 1,
    },
  }),

  commonTextWidget({
    textContent: 'Review Photo',
    configDisplayName: 'Review Photo',
    cssConfig: {
      textAlignment: 'center',
      fontSize: 24,
      isTextBold: true,
      paddingLeft: 40,
      paddingRight: 40,
      marginLeft: 40,
      marginRight: 40,
      textFont: 'var(--font-family)',
    },
    gridPosition: {
      y: 2,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 15,
    },
  }),

  new RevenirTransactionDocumentWidgetBuilder().gridPosition({ y: 5, rows: 15 } as GridPosition).build(),

  new BackButtonWidgetBuilder()
    .configDisplayName('Back Navigation Button')
    .cssConfig({
      backGroundColor: 'var(--palette-color-6)',
      color: 'var(--palette-color-3)',
      borderColor: 'var(--palette-color-3)',
      borderWidth: 1,
      textFont: 'var(--font-family)',
    })
    .setWidgetProperties({
      icon: '',
      textContent: 'Retry',
    })
    .gridPosition({ y: 25, x: 0, rows: 2, cols: 15, resizeEnabled: true, dragEnabled: true })
    .build(),

  commonCTAButton({
    zoneToNavigate: APP_ZONES.REVENIR_ZONE,
    urlToNavigate: 'scanning-receipt',
    textContent: 'Upload',
    configDisplayName: 'Upload',
    buttonActionType: ButtonActionType.REVENIR_UPLOAD_REVIEWED_TRANSACTION_DOCUMENT,
    gridPosition: { y: 28, x: 0, rows: 2, cols: 15 } as GridPosition,
  }),
];
