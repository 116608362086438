import { Injectable } from '@angular/core';
import { ConfirmationRequest, UserProfileType, CredentialTypeMetadata } from './keycloak-custom.type';
import { KeycloakWrapperService } from './keycloak-wrapper.service';

@Injectable({
  providedIn: 'root',
})
export class KeycloakCustomService {
  constructor(private keycloakWrapper: KeycloakWrapperService) {}

  public async sendConfirmation(payload: ConfirmationRequest): Promise<{ access_token: string }> {
    const { claim, factors } = payload;
    return this.keycloakWrapper.useCustomKeycloakService<{ access_token: string }>(
      'POST',
      'confirmation/confirmation',
      {
        data: {
          claim,
          factors,
        },
        isExternalKeycloak: true,
      },
    );
  }

  public async sendChallenge(payload: { type: string }): Promise<{ challenge: string }> {
    const { type } = payload;
    return this.keycloakWrapper.useCustomKeycloakService<{ challenge: string }>(
      'POST',
      'confirmation/confirmation/send-challenge',
      {
        data: {
          type,
        },
        isExternalKeycloak: true,
      },
    );
  }

  public async getUserOrganisationList(id: string): Promise<UserProfileType[]> {
    return await this.keycloakWrapper.useCustomKeycloakService<UserProfileType[]>(
      'GET',
      `/users/${id}/orgs`,
      {
        isExternalKeycloak: true,
      },
    );
  }

  public async getUserCredentialOptions(): Promise<CredentialTypeMetadata[]> {
    return await this.keycloakWrapper.useCustomKeycloakService<CredentialTypeMetadata[]>(
      'GET',
      `account/credentials`,
      {
        isExternalKeycloak: true,
      },
    );
  }
}
