import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { FormActionTypeEnum } from '@finxone-platform/form-action';
import { ButtonActionType, UiZoneWidgetAttributeConfig } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { FormActionState } from '../../../state/form-submision.state';
import { functionCtaType, redirectToPage } from '../cta-button-actions.utils';
import { saveTransactionAction } from '../revenir-zone/add-transaction-utils';
import {
  addRevenirTrip,
  archiveRevenirTrip,
  checkBarcodeScanDoneRevenirTrip,
  confirmScanArchiveRevenirTrip,
  downloadTaxFreeForms,
  passportDetails,
  reviewBoardingPass,
  skipBoardingPass,
} from '../revenir-zone/trip-utils';
import { uploadReviewedTransactionDocument } from '../revenir-zone/upload-reviewed-transaction-document-utils';

export const revenirActions: Map<ButtonActionType, functionCtaType> = new Map([
  [
    ButtonActionType.TRIP_START_CONTINUE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      redirectToPage(injector.get(Router), attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.TRIP_END_CONTINUE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      redirectToPage(injector.get(Router), attri.widgetProperties, injector);
    },
  ],

  [
    ButtonActionType.TRIP_COUNTRY_CONTINUE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      redirectToPage(injector.get(Router), attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.TRIP_COUNTRY_CONTINUE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      redirectToPage(injector.get(Router), attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.TRIP_UPLOAD_PASS,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      redirectToPage(injector.get(Router), attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.TRIP_COUNTRY_CONTINUE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      redirectToPage(injector.get(Router), attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.TRIP_LEAVING_COUNTRY_CONTINUE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      const router = injector.get(Router);
      const data = injector
        .get(Store)
        .select(FormActionState.getFormActionStateWithId(FormActionTypeEnum.ADD_TRIP_REVENIR_ZONE));
      const parsedWidgetProperties = JSON.parse(JSON.stringify(attri.widgetProperties));
      let isButtonClick = true;
      data.subscribe({
        next: (res) => {
          if (isButtonClick) {
            if (res?.formData?.['entering_country'] !== res?.formData?.['leaving_country']) {
              parsedWidgetProperties['urlToNavigate'] = 'multiple-country-notification';
              redirectToPage(router, parsedWidgetProperties, injector);
            } else if (
              res?.formData?.['entering_country'] == 'belgium' ||
              res?.formData?.['leaving_country'] == 'belgium'
            ) {
              parsedWidgetProperties['urlToNavigate'] = 'upload-boarding-pass';
              redirectToPage(router, parsedWidgetProperties, injector);
            } else {
              parsedWidgetProperties['urlToNavigate'] = 'exit-point';
              redirectToPage(router, parsedWidgetProperties, injector);
            }
          }
          isButtonClick = false;
        },
      });
    },
  ],
  [
    ButtonActionType.MULTIPLE_COUNTRY_NOTI,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      const data = injector
        .get(Store)
        .select(FormActionState.getFormActionStateWithId(FormActionTypeEnum.ADD_TRIP_REVENIR_ZONE));
      const parsedWidgetProperties = JSON.parse(JSON.stringify(attri.widgetProperties));
      let isButtonClick = true;
      data.subscribe({
        next: (res) => {
          const router = injector.get(Router);
          if (isButtonClick) {
            if (
              res?.formData?.['entering_country'] == 'belgium' ||
              res?.formData?.['leaving_country'] == 'belgium'
            ) {
              parsedWidgetProperties['urlToNavigate'] = 'upload-boarding-pass';
              redirectToPage(router, parsedWidgetProperties, injector);
            } else {
              parsedWidgetProperties['urlToNavigate'] = 'exit-point';
              redirectToPage(router, parsedWidgetProperties, injector);
            }
            isButtonClick = false;
          }
        },
      });
    },
  ],

  [
    ButtonActionType.TRIP_EXIT_POINT_CONTINUE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      const router = injector.get(Router);
      redirectToPage(router, attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.ADD_TRANSACTION_STEP_1,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      saveTransactionAction(attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.ADD_REVENIR_NEW_TRIP,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      addRevenirTrip(attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.CONFIRM_ARCHIVE_TRIP,
    (injector: Injector) => {
      archiveRevenirTrip(injector.get(Store));
    },
  ],
  [
    ButtonActionType.BARCODE_CONFIRM_CTA,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      redirectToPage(injector.get(Router), attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.BARCODE_CONFIRM_SCAN_ARCHIVE_CTA,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      confirmScanArchiveRevenirTrip(attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.DOWNLOAD_TAX_FREE_FORMS_CTA,
    () => {
      downloadTaxFreeForms();
    },
  ],
  [
    ButtonActionType.REVENIR_UPLOAD_REVIEWED_TRANSACTION_DOCUMENT,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      uploadReviewedTransactionDocument(attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.REVENIR_TRIP_BARCODE_SCAN_CHECK,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      checkBarcodeScanDoneRevenirTrip(attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.REVENIR_TRIP_BARCODE_CONFIRMATION_DECLARATION,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      redirectToPage(injector.get(Router), attri.widgetProperties);
    },
  ],
  [
    ButtonActionType.REVENIR_PREVIEW_BOARDING_PASS,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      reviewBoardingPass(attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.SKIP_BOARDING_PASS_DETAILS,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      skipBoardingPass(attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.SKIP_PASSPORT_DETAILS,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      passportDetails(attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.PASSPORT_DETAILS,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      passportDetails(attri.widgetProperties, injector);
    },
  ],
]);
