import { notificationDailyBalanceTemplate } from '@finxone-platform/shared/default-templates';
import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonSizeOptions,
  ButtonTypes,
  GridPosition,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import { ImageWidgetBuilder, TextWidgetBuilder } from '../../../../widgets/example-builder';
import { createQuickLinkWidget } from '../utils/common-utils';

export const notificationBalanceWidgets = [
  new ImageWidgetBuilder()
    .configDisplayName('Image Widget: Money Icon')
    .cssConfig({
      selectedobjectfit: 'contain',
    })
    .gridPosition({ y: 0, rows: 1 } as GridPosition)
    .attributes({
      widgetProperties: {
        textContent: '',
        imageAssetUrl: 'assets/images/money-bill.svg',
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .build(),
  new TextWidgetBuilder()
    .text('<div class="w-100"><p class="m-0">Daily Balance Update</p></div>')
    .configDisplayName('Daily Balance Update Header')
    .gridPosition({ y: 1, rows: 3 } as GridPosition)
    .cssConfig({
      color: 'var(--palette-color-0)',
      textAlignment: 'center',
      justifyContent: 'center',
      fontSize: '20',
      isTextBold: 'bold',
    })
    .isRemovable(false)
    .build(),

  new TextWidgetBuilder()
    .text(notificationDailyBalanceTemplate)
    .configDisplayName('Balance Information')
    .gridPosition({ y: 4, rows: 12 } as GridPosition)
    .cssConfig({
      backGroundColor: 'var(--palette-color-5)',
      paddingTop: '25',
      paddingBottom: '25',
      paddingLeft: '20',
      paddingRight: '20',
      borderRadiusTopLeft: '5',
      borderRadiusTopRight: '5',
      borderRadiusBottomLeft: '5',
      borderRadiusBottomRight: '5',
      display: 'flex',
      verticalAlignment: 'start',
      textAlignment: 'left',
    })
    .isRemovable(false)
    .build(),

  new TextWidgetBuilder()
    .text(
      '<div class="w-100"><p>For detailed transaction history, visit Charts section of the app.</p></div>',
    )
    .configDisplayName('Description Text')
    .gridPosition({ y: 16, rows: 4 } as GridPosition)
    .cssConfig({
      color: 'var(--palette-color-1)',
      textAlignment: 'center',
      justifyContent: 'center',
      fontSize: '14',
      paddingTop: '50',
      paddingBottom: '1',
      paddingLeft: '1',
      paddingRight: '1',
    })
    .isRemovable(false)
    .build(),

  createQuickLinkWidget({
    zone: APP_ZONES.PROFILE,
    navigateToPageUrl: 'notification-centre',
    navigationOption: 'use-as-navigation',
    text: 'Close',
    css_config: {
      color: 'var(--palette-color-3)',
      borderWidth: '1',
      borderColor: 'var(--palette-color-3)',
      backGroundColor: 'var(--palette-color-6)',
      fontSize: '16',
      borderRadiusTopLeft: '5',
      borderRadiusTopRight: '5',
      borderRadiusBottomLeft: '5',
      borderRadiusBottomRight: '5',
      marginBottom: '12',
      marginTop: '12',
      paddingTop: '11',
      paddingBottom: '11',
      paddingRight: '11',
      paddingleft: '11',
      textAlignment: 'center',
      justifyContent: 'center',
    },
    gridPosition: { y: 20, rows: 2 },
    configDisplayName: 'Quick Link: Close',
    globalStyling: true,
    globalStylingClass: `${ButtonTypes.SECONDARY} ${ButtonSizeOptions.FULL_WIDTH}`,
  }),
];
