import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonActionType,
  GridPosition,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import { iFrameWidgetWidgetBuilder, RevenirBarcodeImageViewerWidgetBuilder } from '../../../../widgets';
import {
  commonBackButtonQuickLink,
  commonCTAButton,
  commonQuickLink,
  commonTextWidget,
} from '../../../common/common-widgets';

export const barcodeWidgets = [
  commonQuickLink({
    text: 'X',
    configDisplayName: 'Navigate: Close',
    zone: APP_ZONES.REVENIR_ZONE,
    navigateToPageUrl: 'trips-home-page',
    cssConfig: {
      textFont: 'var(--font-family)',
      textAlignment: 'center',
      color: 'var(--palette-color-3)',
      backGroundColor: 'var(--palette-color-6)',
      fontSize: 16,
    },
    gridPosition: { y: 0, x: 13, rows: 2, cols: 2 } as GridPosition,
  }),

  commonTextWidget({
    textContent: `Scan Barcode`,
    configDisplayName: 'Header: Scan Barcode',
    cssConfig: {
      textFont: 'var(--font-family)',
      color: 'var(--typography-color-0)',
      textAlignment: 'center',
      fontSize: 24,
      isTextBold: true,
    },
    gridPosition: {
      y: 2,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 15,
    },
  }),

  commonTextWidget({
    textContent: `Finalise your VAT Refund`,
    configDisplayName: 'Sub heading: Finalise your VAT Refund',
    cssConfig: {
      textFont: 'var(--font-family)',
      color: 'var(--typography-color-0)',
      textAlignment: 'center',
      fontSize: 23,
    },
    gridPosition: {
      y: 4,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 15,
    },
  }),

  commonTextWidget({
    textContent: `Claim your VAT refund by scanning
 the barcode at the kiosk!`,
    configDisplayName: 'Barcode info: Claim you vat text',
    cssConfig: {
      textFont: 'var(--font-family)',
      color: 'var(--typography-color-0)',
      textAlignment: 'center',
      fontSize: 16,
      marginTop: 0,
      marginRight: 20,
      marginBottom: 0,
      marginLeft: 20,
    },
    gridPosition: {
      y: 6,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 3,
      cols: 15,
    },
  }),

  new RevenirBarcodeImageViewerWidgetBuilder()
    .cssConfig({
      paddingTop: 10,
      paddingLeft: 10,
      paddingRight: 10,
      paddingBottom: 10,
      textAlignment: 'center',
      fontSize: 16,
      borderRadiusTopLeft: 5,
      borderRadiusTopRight: 5,
      borderRadiusBottomLeft: 5,
      borderRadiusBottomRight: 5,
      textFont: 'var(--font-family)',
      color: 'var(--typography-color-0)',
      borderWidth: 1,
      borderColor: 'var(--palette-color-3)',
    })
    .gridPosition({ y: 10, x: 0, rows: 10, cols: 15 } as GridPosition)
    .build(),

  commonCTAButton({
    zoneToNavigate: APP_ZONES.REVENIR_ZONE,
    urlToNavigate: 'exit-point',
    textContent: 'Confirm scan and archive',
    configDisplayName: 'Trigger Success popup: Confirm scan and archive',
    buttonActionType: ButtonActionType.BARCODE_CONFIRM_SCAN_ARCHIVE_CTA,
    formKey: '',
    gridPosition: { y: 20, x: 0, rows: 2, cols: 15 } as GridPosition,
    cssConfig: {
      fontSize: 16,
      marginTop: 0,
      marginRight: 10,
      marginBottom: 0,
      marginLeft: 10,
      borderRadiusTopLeft: 5.3,
      borderRadiusTopRight: 5.3,
      borderRadiusBottomLeft: 5.3,
      borderRadiusBottomRight: 5.3,
    },
  }),

  commonQuickLink({
    text: 'Find the VAT reclaim Kiosk',
    zone: APP_ZONES.REVENIR_ZONE,
    navigateToPageUrl: 'kiosk-map',
    configDisplayName: 'Navigate: Find the VAT reclaim Kiosk',
    cssConfig: {
      fontSize: 16,
      color: 'var(--palette-color-1)',
      backGroundColor: 'var(--palette-color-6)',
      borderWidth: 1,
      borderColor: 'var(--palette-color-3)',
      marginTop: 0,
      marginRight: 10,
      marginBottom: 0,
      marginLeft: 10,
      borderRadiusTopLeft: 5.3,
      borderRadiusTopRight: 5.3,
      borderRadiusBottomLeft: 5.3,
      borderRadiusBottomRight: 5.3,
      textFont: 'var(--font-family)',
    },
    gridPosition: { y: 23, x: 0, rows: 2, cols: 15 } as GridPosition,
  }),

  commonCTAButton({
    zoneToNavigate: APP_ZONES.REVENIR_ZONE,
    urlToNavigate: 'exit-point',
    textContent: 'Download Tax Free Forms',
    configDisplayName: 'Trigger download: Download Tax Free Forms',
    buttonActionType: ButtonActionType.DOWNLOAD_TAX_FREE_FORMS_CTA,
    formKey: '',
    gridPosition: { y: 26, x: 0, rows: 2, cols: 15 } as GridPosition,
    cssConfig: {
      fontSize: 16,
      color: 'var(--palette-color-1)',
      backGroundColor: 'var(--palette-color-6)',
      isTextUnderline: true,
    },
  }),

  commonTextWidget({
    textContent: `Not sure what to do?`,
    configDisplayName: 'Text: Not sure what to do?',
    cssConfig: {
      textFont: 'var(--font-family)',
      color: 'var(--typography-color-0)',
      textAlignment: 'left',
      fontSize: 24,
      isTextBold: true,
      marginTop: 0,
      marginRight: 10,
      marginBottom: 0,
      marginLeft: 10,
    },
    gridPosition: {
      y: 29,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 15,
    },
  }),

  commonTextWidget({
    textContent: `We will reply as soon as we can.`,
    configDisplayName: 'Text: We will reply as soon as we can',
    cssConfig: {
      textFont: 'var(--font-family)',
      color: 'var(--typography-color-0)',
      textAlignment: 'left',
      fontSize: 16,
      marginTop: 0,
      marginRight: 10,
      marginBottom: 0,
      marginLeft: 10,
    },
    gridPosition: {
      y: 32,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 15,
    },
  }),

  commonQuickLink({
    text: 'Chat to our help team',
    configDisplayName: 'Navigate: Chat to our help team',
    zone: APP_ZONES.REVENIR_ZONE,
    navigateToPageUrl: 'trips-home-page',
    cssConfig: {
      color: 'var(--palette-color-1)',
      backGroundColor: 'var(--palette-color-6)',
      borderWidth: 1,
      borderColor: 'var(--palette-color-3)',
      fontSize: 16,
      marginTop: 0,
      marginRight: 10,
      marginBottom: 0,
      marginLeft: 10,
      borderRadiusTopLeft: 5.3,
      borderRadiusTopRight: 5.3,
      borderRadiusBottomLeft: 5.3,
      borderRadiusBottomRight: 5.3,
      textFont: 'var(--font-family)',
    },
    gridPosition: { y: 35, x: 0, rows: 2, cols: 15 } as GridPosition,
  }),
];

export const kioskMapPageWidgets = [
  commonBackButtonQuickLink({
    cssConfig: {
      iconSize: 35,
      iconUnit: 'px',
    },
    gridPosition: {
      y: 0,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 2,
    },
  }),

  commonTextWidget({
    textContent: 'Kiosk Map',
    configDisplayName: 'Kiosk Map',
    cssConfig: {
      textAlignment: 'center',
      fontSize: 24,
      isTextBold: true,
      paddingLeft: 10,
      paddingRight: 10,
      marginLeft: 10,
      marginRight: 10,
      textFont: 'var(--font-family)',
    },
    gridPosition: {
      y: 2,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 3,
      cols: 15,
    },
  }),

  new iFrameWidgetWidgetBuilder()
    .attributes({
      widgetProperties: {
        iframeUrl: `https://www.google.com/maps/d/embed?mid=1hYHaqpFOnVIY_jbFlDCPwZQFej5c4is&hl=en&ehbc=2E312F`,
        verticalHeight: '72',
        textContent: '',
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .gridPosition({
      y: 6,
      x: 0,
      rows: 26,
      cols: 15,
    } as GridPosition)
    .build(),
];
