import { copNoMatchDetails } from '@finxone-platform/shared/default-templates';
import {
  APP_ZONES,
  CommonPageConfig,
  GridPosition,
  PermissionConfig,
  WorkflowConfig,
  WorkflowPageConfig,
} from '@finxone-platform/shared/sys-config-types';
import {
  BackButtonWidgetBuilder,
  BackButtonWidgetBuilderCssConfig,
  SecondaryIdentifierWidgetBuilder,
  TextWidgetBuilder,
  defaultPageConfigurations,
} from '../../defaults/widgets';

// This migration adds the new Secondary Identifier COP page and updates the COP No Match text content to include the Secondary Identifer field if required

const secondaryIdentifierPage: WorkflowPageConfig = {
  name: 'review-information-secondary-identifier-required-cop',
  title: 'Review Information Secondary Identifier Required (COP)',
  pageConfiguration: {
    ...(defaultPageConfigurations as CommonPageConfig),
    url: 'review-information-secondary-identifier-required-cop',
  },
  widgets: [
    new TextWidgetBuilder()
      .text(`<div>{{{ imageFromImageName 'icon-library/material' 'mat_warning_circle' '57px'}}}</div>`)
      .configDisplayName('Text Widget: Warning Icon')
      .cssConfig({
        fontSize: 24,
      })
      .gridPosition({ x: 6, y: 0, cols: 3, rows: 3 } as GridPosition)
      .build(),
    new TextWidgetBuilder()
      .text(`<b>Secondary Identifier Required</b>`)
      .configDisplayName(`Text Widget: Secondary Identifier Required`)
      .cssConfig({
        color: 'var(--typography-color-0)',
        textAlignment: 'center',
        fontSize: 20,
      })
      .gridPosition({ x: 0, y: 3, cols: 15, rows: 2 } as GridPosition)
      .build(),
    new TextWidgetBuilder()
      .text(`The bank requires a secondary identifier for this account.`)
      .configDisplayName(`Text Widget: Secondary Identifier sub text`)
      .cssConfig({
        color: 'var(--typography-color-0)',
        textAlignment: 'center',
        fontSize: 16,
      })
      .gridPosition({ x: 0, y: 5, cols: 15, rows: 3 } as GridPosition)
      .build(),
    new SecondaryIdentifierWidgetBuilder()
      .configDisplayName(`Secondary Identifier Widget`)
      .gridPosition({ x: 0, y: 8, cols: 15, rows: 7 } as GridPosition)
      .build(),
    new BackButtonWidgetBuilder()
      .defaultBackButton()
      .text('Back')
      .cssConfig({
        ...BackButtonWidgetBuilderCssConfig,
        marginBottom: '13',
        marginTop: '12',
        paddingTop: '0',
        paddingBottom: '0',
        paddingRight: '0',
        paddingLeft: '0',
        fontSize: '16',
      })
      .gridPosition({ y: 15, rows: 3, cols: 15 } as GridPosition)
      .isRemovable(false)
      .build(),
  ],
};

export const getV1_0_140ToV1_0_141 = (workflowConfig: WorkflowConfig) => {
  const oneToOneKeys = Object.keys(workflowConfig);
  const mappingObject = {};
  // Assign mappings to not modify this version upgrade
  oneToOneKeys.forEach((key) => {
    Object.assign(mappingObject, {
      [key]: key,
    });
  });
  return {
    ...mappingObject,
    // add new version number
    version: {
      key: 'version',
      transform: () => {
        return 'v1.0.141';
      },
    },
    permissionSections: {
      key: 'permissionSections',
      transform: (permissionSection: PermissionConfig): PermissionConfig => {
        const { roles, subscriptions } = permissionSection;
        roles.forEach((item) => {
          const zones = item.zones;
          const paymentZone = zones?.[APP_ZONES.PAYMENT];
          if (paymentZone) {
            const mobilePages = paymentZone?.canvases?.subscription?.['default']?.mobile?.pages as
              | WorkflowPageConfig[]
              | undefined;
            const desktopPages = paymentZone?.canvases?.subscription?.['default']?.desktop?.pages as
              | WorkflowPageConfig[]
              | undefined;
            const tabletPages = paymentZone?.canvases?.subscription?.['default']?.tablet?.pages as
              | WorkflowPageConfig[]
              | undefined;
            if (mobilePages?.length) {
              addNewPage(mobilePages);
              updateTextContent(mobilePages);
            }
            if (desktopPages?.length) {
              addNewPage(desktopPages);
              updateTextContent(desktopPages);
            }
            if (tabletPages?.length) {
              addNewPage(tabletPages);
              updateTextContent(tabletPages);
            }
          }
        });
        return <PermissionConfig>{
          zones: permissionSection.zones,
          subscriptions,
          roles,
        };
      },
    },
  };
};

function addNewPage(pagesArray: WorkflowPageConfig[]): WorkflowPageConfig[] {
  const secondaryIdentifierPageIndex = pagesArray.findIndex(
    (page) => page.title === 'Review Information Secondary Identifier Required (COP)',
  );
  const noMatchPageIndex = pagesArray.findIndex((page) => page.title === 'Review Information No Match (COP)');
  //if theres no existing secondary identifer page but there is the domestic pages built, shown by the no match page existing
  if (secondaryIdentifierPageIndex === -1 && noMatchPageIndex !== -1) {
    pagesArray.splice(noMatchPageIndex + 1, 0, secondaryIdentifierPage);
  }
  return pagesArray;
}

function updateTextContent(pagesArray: WorkflowPageConfig[]): WorkflowPageConfig[] {
  const noMatchPageIndex = pagesArray.findIndex((page) => page.title === 'Review Information No Match (COP)');
  if (noMatchPageIndex !== -1) {
    pagesArray[noMatchPageIndex].widgets.forEach((widget) => {
      if (widget.widgetConfiguration.widgetConfigDisplayName === 'Text Widget: Entered Account Details') {
        widget.widgetConfiguration.attributes.widgetProperties.textContent = copNoMatchDetails;
      }
    });
  }
  return pagesArray;
}
