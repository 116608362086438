import { userProfileSummaryDetails } from '@finxone-platform/shared/default-templates';
import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonActionType,
  ButtonSizeOptions,
  ButtonTypes,
  GridPosition,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import {
  FormSubmissionButtonWidgetBuilder,
  ProfilePictureWidgetBuilder,
  TextWidgetBuilder,
} from '../../../../widgets/example-builder';

export const userProfileWidgets = [
  new ProfilePictureWidgetBuilder()
    .gridPosition({ y: 1, rows: 3, cols: 15 } as GridPosition)
    .cssConfig({ textAlignment: 'center' })
    .build(),
  new TextWidgetBuilder()
    .text(userProfileSummaryDetails)
    .configDisplayName('User Summary')
    .gridPosition({ y: 4, rows: 19 } as GridPosition)
    .cssConfig({
      backGroundColor: 'var(--palette-color-6)',
      paddingTop: '25',
      paddingBottom: '25',
      paddingLeft: '20',
      paddingRight: '20',
      borderRadiusTopLeft: '5',
      borderRadiusTopRight: '5',
      borderRadiusBottomLeft: '5',
      borderRadiusBottomRight: '5',
      display: 'flex',
      verticalAlignment: 'start',
      textAlignment: 'left',
      fontSize: '18',
      textFont: 'var(--font-family)',
    })
    .isRemovable(false)
    .build(),
  new TextWidgetBuilder()
    .text('Review and update your personal information.')
    .configDisplayName('Review information')
    .gridPosition({ x: 1, y: 24, rows: 2, cols: 13 } as GridPosition)
    .cssConfig({
      textAlignment: 'center',
      fontSize: '16',
      color: 'var(--palette-color-1)',
      textFont: 'var(--font-family)',
    })
    .isRemovable(false)
    .build(),
  new FormSubmissionButtonWidgetBuilder()
    .configDisplayName('Button: Edit Profile')
    .attributes({
      widgetProperties: {
        textContent: 'Edit',
        buttonActionType: ButtonActionType.CLEAR_FORM_STATE_AND_REDIRECT_TO_EDIT_PROFILE,
        urlToNavigate: 'edit-user-profile',
        zoneToNavigate: APP_ZONES.PROFILE,
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .cssConfig({
      color: 'var(--palette-color-6)',
      borderWidth: '1',
      borderColor: 'var(--palette-color-3)',
      backGroundColor: 'var(--palette-color-3)',
      fontSize: '16',
      borderRadiusTopLeft: '5',
      borderRadiusTopRight: '5',
      borderRadiusBottomLeft: '5',
      borderRadiusBottomRight: '5',
      marginTop: 0,
      marginBottom: '12',
      paddingTop: '11',
      paddingBottom: '11',
      paddingRight: '11',
      paddingleft: '11',
      textAlignment: 'center',
      justifyContent: 'center',
      textFont: 'var(--font-family)',
      verticalAlignment: 'center',
    })
    .isRemovable(false)
    .gridPosition({ y: 26, rows: 2 } as GridPosition)
    .setGlobalStylingClass(true, `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`)
    .build(),
];
