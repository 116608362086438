import { GridPosition } from '@finxone-platform/shared/sys-config-types';
import { LottieAnimationWidgetBuilder } from '../../../../widgets/example-builder';
import { commonTextWidget } from '../../../common/common-widgets';

export const scanningReceiptWidgets = [
  new LottieAnimationWidgetBuilder()
    .setWidgetProperties({
      animationSource: 'Asset URL',
      assetUrl: 'assets/JSON/loader-animation.json',
      animationType: 'Loop',
      animationContainerType: 'Default',
      textContent: '',
    })
    .cssConfig({
      textAlignment: 'center',
      justifyContent: 'center',
      textFont: 'var(--font-family)',
    })
    .gridPosition({ x: 6, y: 11, rows: 4, cols: 3 } as GridPosition)
    .isRemovable(true)
    .build(),
  commonTextWidget({
    textContent: 'Scanning Receipt',
    cssConfig: {
      fontSize: 24,
      isTextBold: true,
      textFont: 'var(--font-family)',
    },
    gridPosition: {
      y: 15,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 15,
      layerIndex: 1,
    },
  }),
];
