export const notificationExchangeAccountInfoTemplate = `<div class="w-100 h-100">
<div class="is-vertical-align">
    <div class="text-label">Source</div>
    <div class="text-value">{{notification.selectedNotification.sender.name}}</div>
    <div class="text-label">{{notification.selectedNotification.sender.accountNumber}}</div>
</div>
<hr class="my-4 mx-1">
<div class="is-vertical-align">
    <div class="text-label">Destination</div>
    <div class="text-value">{{notification.selectedNotification.receiver.name}}</div>
    <div class="text-label">{{notification.selectedNotification.receiver.accountNumber}}</div>
</div>
<hr class="my-4 mx-1">
<div class="is-space-between">
    <div class="is-vertical-align">
        <div class="is-vertical-align align-left">
            <div class="text-label">Sold</div>
            <div class="text-value font-weight-bold">{{ currencyPipe notification.selectedNotification.sellAmount currencyCode=notification.selectedNotification.sellCurrency }}</div>
        </div>
    </div>
</div>
<div class="is-space-between my-4">
    <div class="is-vertical-align align-left">
        <div class="text-label">Bought</div>
        <div class="text-value font-weight-bold">{{ currencyPipe notification.selectedNotification.buyAmount currencyCode=notification.selectedNotification.buyCurrency }}</div>
    </div>
</div>
<div class="is-space-between my-4">
    <div class="is-vertical-align align-left">
        <div class="text-label">Total Fees</div>
        <div class="text-value">{{ currencyPipe notification.selectedNotification.feeAmount currencyCode=notification.selectedNotification.feeCurrency }}</div>
    </div>
    <div class="is-vertical-align text-right">
        <div class="text-label">Exchange Rate</div>
        <div class="text-value">{{ currencyPipe 1 currencyCode=notification.selectedNotification.sellCurrency }} = {{ currencyPipe notification.selectedNotification.exchangeRate currencyCode=notification.selectedNotification.buyCurrency }}</div>
    </div>
</div>
<hr class="my-4 mx-1">
<div class="is-space-between my-4">
    <div class="is-vertical-align">
        <div class="is-vertical-align align-left">
            <div class="text-label">Created At</div>
            <div class="text-value">{{datePipe notification.selectedNotification.originalExchangeDate format="d MMM y"}}</div>
        </div>
    </div>
</div>
<div class="is-space-between my-4">
    <div class="is-vertical-align">
        <div class="is-vertical-align align-left">
            <div class="text-label">Completed At</div>
            <div class="text-value">{{datePipe notification.selectedNotification.exchangeDate format="d MMM y"}}</div>
        </div>
    </div>
</div>
</div>`;
