import { WorkflowPageConfig } from '@finxone-platform/shared/sys-config-types';
import { PageConfig } from '../../../../../zones/mobile/default-zone-page-setups';
import { sumsubWidgetConfig } from '../../../../widgets/verification/verification.type';
import { defaultPageConfigurations } from '../../../../widgets/workflow-ui-constants.v2.type';
import { mobileEditAddressInformationWidgets } from '../widget-sections/edit-company-address-widgets';
import { mobileEditCompanyInformationWidgets } from '../widget-sections/edit-company-information-widgets';
import { mobileEditIncorporationDateWidgets } from '../widget-sections/edit-information-incorporation-date';
import { mobileInformationConfirmationWidgets } from '../widget-sections/information-confirmation-widget';
import { mobileReviewInformationWidgets } from '../widget-sections/review-information-page-widgets';

export function buildMobileVerificationOrgZonePagesConfig(): WorkflowPageConfig[] {
  const zonePages: WorkflowPageConfig[] = [];
  const initialPages: PageConfig[] = [
    {
      name: 'review-information',
      title: 'Review Information',
      tags: [],
      url: 'review-information',
      widgets: mobileReviewInformationWidgets(),
    },
    {
      name: 'edit-information-company-name',
      title: 'Edit Information - Company Name',
      tags: [],
      url: 'edit-information-company-name',
      widgets: mobileEditCompanyInformationWidgets(),
    },
    {
      name: 'edit-information-incorporation-date',
      title: 'Edit Information - Incorporation Date',
      tags: [],
      url: 'edit-information-incorporation-date',
      widgets: mobileEditIncorporationDateWidgets(),
    },
    {
      name: 'edit-information-company-address',
      title: 'Edit Information - Company Address',
      tags: [],
      url: 'edit-information-company-address',
      widgets: mobileEditAddressInformationWidgets(),
    },
    {
      name: 'information-confirmation',
      title: 'Information Confirmation',
      tags: [],
      url: 'information-confirmation',
      widgets: mobileInformationConfirmationWidgets(),
    },
    {
      name: 'sumsub-workflow',
      title: 'Sumsub Workflow',
      tags: [],
      url: 'sumsub-workflow',
      widgets: [sumsubWidgetConfig()],
    },
  ];

  initialPages.forEach((page: PageConfig) => {
    const pageObj: WorkflowPageConfig = {
      name: page.name,
      title: page.title,
      widgets: page.widgets ?? [],
      pageConfiguration: {
        ...defaultPageConfigurations,
        ...page.pageConfiguration,
        isUsingHtmlStructure: true,
        backgroundColor: 'var(--color-background)',
        url: page.url ?? page.name,
        pageType: 'dynamicGrid',
        isRemovable: false,
        mainComponentPageName: 'ParentContainerComponent',
      },
    };
    zonePages.push(pageObj);
  });
  return zonePages;
}
