import {
  APP_ZONES,
  BaseWidgetProperties,
  GridPosition,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import { inputSettings } from '../../../widgets';
import {
  DesktopBulkPaymentHistoryListingWidgetBuilder,
  LineWidgetBuilder,
} from '../../../widgets/example-builder';
import { defaultTableHeaderSettingCssConfig } from '../../../widgets/workflow-ui-constants.v2.type';
import { bulkPaymentFooter, generateTextWidget, getQuickLinkWidget } from '../common-widgets-utils';

export const bulkPaymentListingColumns = [
  {
    label: 'Number of transactions',
    value: 'numberOfTransactions',
    handleBarKey: '{{totalPayments}}',
    selected: true,
  },
  {
    label: 'Date of execution',
    value: 'dataOfExecution',
    handleBarKey: "{{ datePipe executed_at  format='d MMM y'}}",
    selected: true,
  },
  {
    label: 'No. of beneficiaries',
    value: 'noOfBeneficiaries',
    handleBarKey: '{{totalUniqueBeneficiaries}}',
    selected: true,
  },
  {
    label: 'Payout Account',
    value: 'payoutAccount',
    handleBarKey: '{{accountName}}',
    selected: true,
  },
  {
    label: 'Total Amount',
    value: `totalAmount`,
    handleBarKey: "{{ currencyPipe totalAmount currencyCode=accountCurrency display='symbol-narrow' }}",
    selected: true,
  },
  {
    label: 'Status',
    value: `status`,
    handleBarKey: '{{status}}',
    selected: true,
  },
];

export const bulkPaymentReviewListingColumns = [
  {
    label: 'Recipient / Org. Name',
    value: 'name',
    selected: true,
    handleBarKey: '{{name}}',
  },
  {
    label: 'Sort Code',
    value: 'beneficiaryBankAccounts.0.sort_code',
    selected: true,
    handleBarKey: '{{beneficiaryBankAccounts.0.sort_code}}',
  },
  {
    label: 'A/C Number',
    value: 'beneficiaryBankAccounts.0.account_number',
    selected: true,
    handleBarKey: '{{beneficiaryBankAccounts.0.account_number}}',
  },
  {
    label: 'Type',
    value: 'type',
    selected: true,
    handleBarKey: '{{type}}',
  },
  {
    label: 'Amount',
    value: 'amount',
    selected: true,
    handleBarKey: '{{ currencyPipe amount currencyCode=currency }} ',
  },
  {
    label: 'Schedule',
    value: 'scheduleType',
    selected: true,
    handleBarKey: '{{ scheduleType}}',
  },
  {
    label: 'New / Existing',
    value: `removeUnderscoreAndCapitalise beneficiaryExistStatus`,
    selected: true,
    handleBarKey: `{{removeUnderscoreAndCapitalise beneficiaryExistStatus}}`,
  },
  {
    label: 'Status',
    value: `{{#if (eq beneficiaryCoPStatus 'not_applicable')}}
            N/a
    {{else}}
          {{beneficiaryCoPStatus}}
    {{/if}}`,
    handleBarKey: `
    {{#if (eq beneficiaryCoPStatus 'not_applicable')}}
            N/a
    {{else}}
          {{beneficiaryCoPStatus}}
    {{/if}}
      `,
    selected: true,
  },
];

export const bulkPaymentHistoryWidgets = [
  generateTextWidget({
    text: 'Bulk Payment History',
    position: { x: 0, y: 0, rows: 2, cols: 15 } as GridPosition,
    displayName: 'Header Text',
    styles: {
      fontSize: 24,
      isTextBold: true,
      verticalAlignment: 'start',
      textAlignment: 'left',
      noAlignment: true,
      paddingLeft: 33,
      paddingTop: 28,
    },
    isRemovable: true,
  }),

  generateTextWidget({
    text: 'View all bulk payments made by you.',
    position: { x: 0, y: 2, rows: 1, cols: 18 } as GridPosition,
    displayName: 'Information Content',
    styles: {
      verticalAlignment: 'start',
      textAlignment: 'left',
      fontSize: '16',
      paddingLeft: 33,
      paddingTop: 5,
      lineHeight: '24',
      textFont: 'var(--font-family)',
      color: 'var(--typography-color-1)',
    },
    isRemovable: true,
  }),

  // ...addBackButton({ x: 38, y: 1, rows: 2, cols: 5 } as GridPosition),

  getQuickLinkWidget({
    text: 'Back',
    position: { x: 38, y: 1, rows: 1, cols: 5 } as GridPosition,
    pageUrl: 'bulk-payment-landing',
    displayName: 'Quick Link: Back Button',
    styles: {
      color: 'var(--palette-color-3)',
      fontSize: '16',
      backGroundColor: 'transparent',
      textAlignment: 'center',
      justifyContent: 'center',
      borderColor: 'var(--palette-color-3)',
      borderStyle: 'solid',
      borderWidth: 1,
      borderRadiusTopLeft: 6,
      borderRadiusBottomLeft: 6,
      borderRadiusTopRight: 6,
      borderRadiusBottomRight: 6,
    },
    zone: APP_ZONES.PAYMENT,
    isRemovable: true,
  }),

  new DesktopBulkPaymentHistoryListingWidgetBuilder()
    .text('Bulk Payment History Listing Widget')
    .configDisplayName(`Bulk Payment Listing Widget `)
    .gridPosition({ x: 1, y: 4, rows: 13, cols: 42 } as GridPosition)
    .cssConfig({
      color: 'var(--palette-color-1)',
      textAlignment: 'left',
      justifyContent: 'left',
      fontSize: '16',
      borderWidth: 1,
      borderColor: 'var(--palette-color-2)',
      backGroundColor: 'var(--palette-color-6)',
      borderStyle: 'solid',
      borderRadiusUnit: 'px',
      borderRadiusTopLeft: 5,
      borderRadiusBottomLeft: 5,
      borderRadiusTopRight: 5,
      borderRadiusBottomRight: 5,
    })
    .attributes({
      widgetProperties: {
        icon: 'search',
        textContent: ``,
        showEntity: false,
        entityInformation: {
          entity: 'bulk_payment',
        },
        menuOptions: [
          { label: 'Inspect Suggestion', value: 'inspect-suggestion', isIdNotExist: '' },
          { label: 'Continue Anyway', value: 'continue-anyway', isIdNotExist: '' },
          { label: 'Skip', value: 'skip', isIdNotExist: '' },
        ],
      } as BaseWidgetProperties,
      tableGridSetting: {
        showGridLines: false,
        gridLinesColor: 'var(--border-color-1)',
      },
      rowSettings: {
        rowBgColor: 'var(--palette-color-6)',
        showErrorDuplicationRowBgColor: false,
      },
      columns: bulkPaymentListingColumns,
      selectedListOption: 'None',
      viewListOption: 'none',
      tableHeaderSetting: {
        css_config: defaultTableHeaderSettingCssConfig,
      },
    } as unknown as WorkflowWidgetConfigurationAttributes)
    .inputSetting({
      ...inputSettings,
      css_config: {
        ...inputSettings.css_config,
        inputWidth: 110,
      },
      label: 'Search',
      isIconAllowed: true,
    })
    .isRemovable(false)
    .build(),

  new LineWidgetBuilder()
    .configDisplayName('Line Widget')
    .gridPosition({ x: 1, y: 20, rows: 1, cols: 45 } as GridPosition)
    .attributes({
      widgetProperties: {
        color: 'var(--border-color-1)',
        'border-bottom': 'var(--border-color-1)',
        lineThickness: 1,
        lineWidth: '100',
        textContent: '',
        show: true,
        contentAlignment: 'center',
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .build(),

  ...bulkPaymentFooter(21),
];
