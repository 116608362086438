<div class="finxone-menu-content fixed z-[1] rounded-[6px] border-0" #contextMenu>
  <hlm-menu [variant]="menuVariant">
    <hlm-menu-group>
      @for (item of menuItemList; track $index) { @if (!item.hideCondition) { @if (item.children) {
      <button
        hlmMenuItem
        [brnMenuTriggerFor]="submenu"
        class="pt-2 pb-2 border-0 cursor-[_pointer] menu-button justify-content-between"
        [disabled]="!item.isEnabled"
      >
        <span class="{{ item?.itemClass ?? '' }}"> {{ item?.['name'] | titlecase }} </span>
        <hlm-icon size="base" [name]="subMenuIconName"></hlm-icon>
      </button>
      <ng-template #submenu>
        <hlm-sub-menu class="p-0">
          @for (item of item.children; track $index) {
          <button
            hlmMenuItem
            (click)="menuItemClick(item)"
            class="pt-2 pb-2 border-0 cursor-[_pointer] menu-button justify-content-between"
            [disabled]="!item.isEnabled"
          >
            <span class="{{ item?.itemClass ?? '' }}"> {{ item?.['name'] | titlecase }} </span>
          </button>
          }
        </hlm-sub-menu>
      </ng-template>
      } @else {
      <button
        hlmMenuItem
        (click)="menuItemClick(item)"
        class="pt-2 pb-2 border-0 cursor-[_pointer] menu-button"
        [disabled]="!item.isEnabled"
      >
        <span class="{{ item?.itemClass ?? '' }}"> {{ item?.['name'] | titlecase }} </span>
      </button>
      } } }
    </hlm-menu-group>
  </hlm-menu>
</div>
