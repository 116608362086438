import { FormActionTypeEnum } from '@finxone-platform/form-action';
import { APP_ZONES, GridPosition } from '@finxone-platform/shared/sys-config-types';
import { UploadPreviewFileWidgetBuilder } from '../../../../widgets';
import { commonQuickLink, commonTextWidget } from '../../../common/common-widgets';
import { CommonTripZoneWidgets } from './common-widgets';

export const tripZoneEditTransactionStep2Widgets = [
  commonQuickLink({
    cssConfig: {
      color: 'var(--typography-color-0)',
      backGroundColor: 'var(--palette-color-6)',
      fontSize: 20,
      marginTop: 0,
      marginBottom: 0,
      iconSize: 35,
      iconUnit: 'px',
    },
    gridPosition: { y: 0, x: 0, resizeEnabled: true, dragEnabled: true, rows: 2, cols: 2 } as GridPosition,
    text: '',
    icon: 'arrow-left',
    configDisplayName: 'Back',
    zone: APP_ZONES.REVENIR_ZONE,
    navigateToPageUrl: 'trips-home-page',
  }),

  commonQuickLink({
    cssConfig: {
      color: 'var(--typography-color-0)',
      backGroundColor: 'var(--palette-color-6)',
      fontSize: 20,
      marginTop: 0,
      marginBottom: 0,
      textFont: 'var(--font-family)',
    },
    gridPosition: { y: 0, x: 13, rows: 2, cols: 2 } as GridPosition,
    text: 'Save',
    configDisplayName: 'Save',
    zone: APP_ZONES.REVENIR_ZONE,
    navigateToPageUrl: 'transaction-summary',
  }),

  commonTextWidget({
    textContent: 'Edit Transaction',
    configDisplayName: 'Edit Transaction',
    gridPosition: {
      y: 2,
      x: 0,
      resizeEnabled: true,
      dragEnabled: true,
      rows: 2,
      cols: 15,
      layerIndex: 1,
    },
    cssConfig: {
      fontSize: 24,
      isTextBold: true,
      textFont: 'var(--font-family)',
    },
  }),

  new UploadPreviewFileWidgetBuilder()
    .addCustomWidgetProperties({
      urlToNavigate: 'review-photo',
      zoneToNavigate: APP_ZONES.REVENIR_ZONE,
      formKey: FormActionTypeEnum.ADD_TRIP_REVENIR_ZONE,
      textContent: `
      <div class="is-vertical-align is-horizontal-align align-items-center">
        <span class="font-size-inherit">
          Add Till Slip
        </span>

        <span class="mt-3">
          {{{ imageFromImageName 'icon-library' 'camera' '40px' }}}
        </span>

      </div
    `,
    })
    .gridPosition({
      y: 6,
      x: 0,
      rows: 7,
      cols: 15,
    } as GridPosition)
    .cssConfig({
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 12,
      paddingRight: 12,
      color: 'var(--typography-color-0)',
      backGroundColor: '#ffffff00',
      borderWidth: 1,
      borderColor: 'var(--border-color-1)',
    })
    .build(),

  ...new CommonTripZoneWidgets().commonAddEditTransaction({
    isDisabled: true,
  }),
];
