import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonActionType,
  ButtonSizeOptions,
  ButtonTypes,
  ConditionEnum,
  ConditionalWidgetVariableEnum,
  Constants,
  DummyRole,
  GridPosition,
  KycLevel,
  KycStatus,
  NavigationOptionEnum,
  WidgetNames,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import {
  ConditionalWidgetBuilder,
  FormSubmissionButtonWidgetBuilder,
  TextWidgetBuilder,
} from '../example-builder';

export const statusScreenHeaderTextWidget = ({ gridPosition }: { gridPosition?: GridPosition }) =>
  new TextWidgetBuilder()
    .text(`You haven't finished signing up yet! Pick up where you left off.`)
    .gridPosition(
      gridPosition ??
        ({
          y: 1,
          x: 0,
          rows: 2,
          cols: 15,
        } as GridPosition),
    )
    .configDisplayName(`Text Widget: Total event linked to employee`)
    .cssConfig({
      color: 'var(--typography-color-0)',
      borderRadiusTopLeft: 5,
      borderRadiusBottomLeft: 5,
      borderRadiusTopRight: 5,
      borderRadiusBottomRight: 5,
      paddingTop: 20,
      paddingBottom: 20,
      borderColor: 'var(--border-color-1)',
      textAlignment: 'left',
      paddingLeft: '0',
      paddingRight: '0',
      fontSize: 20,
      borderWidth: 0,
    })
    .build();

export const statusScreenCommonCssConfigConditionalWidget = {
  ...Constants.defaultUiNestedButtonCssConfig,
  backGroundColor: 'var(--palette-color-6)',
  color: 'var(--typography-color-0)',
  borderWidth: 1,
  borderColor: 'var(--border-color-1)',
  borderStyle: 'solid',
  verticalAlignment: 'center',
  textAlignment: 'left',
  textFont: 'Metropolis',
  paddingLeft: 10,
  paddingRight: 10,
  shadowBlur: 0,
  shadowSpread: 0,
  shadowOffsetX: 0,
  shadowOffsetY: 0,
  rightIconUnit: 'px',
  righticonSize: 30,
};

export const sumsubVerificationPersonalConditionalWidget = ({
  gridPosition,
}: {
  gridPosition?: GridPosition;
}) =>
  new ConditionalWidgetBuilder()
    .isRemovable(true)
    .configDisplayName('Conditional Widget - Submsub Verification - Personal')
    .nestedWidgets({
      ifCondition: {
        name: WidgetNames.CONDITIONAL_WIDGET_IF_ELSE_CONFIG,
        widgetConfigDisplayName: 'Success condition',
        isRemovable: true,
        attributes: {
          widgetProperties: {
            textContent: '<h4>Sumsub Verification (Personal)</h4><span class="success">Completed</span>',
            righticon: '',
            lefticon: 'playlist-icon',
            isTextEditable: true,
          },
          elementsConfig: [],
          css_config: statusScreenCommonCssConfigConditionalWidget,
          role: DummyRole.EMPTY_ROLE,
          navigationOption: NavigationOptionEnum.USE_AS_BLANK,
          zone: '',
          pageUrl: ``,
        },
      },
      elseCondition: {
        name: WidgetNames.CONDITIONAL_WIDGET_IF_ELSE_CONFIG,
        widgetConfigDisplayName: 'Failure condition',
        isRemovable: true,
        attributes: {
          widgetProperties: {
            textContent: '<h4>Sumsub Verification (Personal)</h4><span class="fail">Pending</span>',
            righticon: 'arrow-right',
            lefticon: 'playlist-icon',
            isTextEditable: true,
          },
          elementsConfig: [],
          css_config: statusScreenCommonCssConfigConditionalWidget,
          role: DummyRole.EMPTY_ROLE,
          navigationOption: NavigationOptionEnum.USE_AS_NAVIGATION,
          zone: APP_ZONES.VERIFICATION,
          pageUrl: `sumsub-workflow?type=${KycLevel.PERSONAL}`,
        },
      },
    })
    .attributes({
      selectedVariable: ConditionalWidgetVariableEnum.KYC_PERSONAL,
      selectedCondition: ConditionEnum.EQUAL,
      selectedValue: KycStatus.SUCCESS,
    } as unknown as WorkflowWidgetConfigurationAttributes)
    .gridPosition(
      gridPosition ??
        ({
          y: 4,
          x: 0,
          rows: 3,
          cols: 15,
        } as GridPosition),
    )
    .build();

export const verificationReviewInformationCTAButtonCssConfig = {
  backGroundColor: 'var(--palette-color-6)',
  color: 'var(--palette-color-3)',
  borderRadiusTopLeft: 6,
  borderRadiusBottomLeft: 6,
  borderRadiusTopRight: 6,
  borderRadiusBottomRight: 6,
  fontSize: 14,
  marginTop: 0,
  marginBottom: 0,
  paddingTop: 10,
  paddingBottom: 15,
  paddingLeft: 15,
  paddingRight: 15,
  borderWidth: '1',
  borderColor: 'var(--palette-color-3)',
};

export const reviewInformationPersonalCTAButton = ({ gridPosition }: { gridPosition: GridPosition }) =>
  new FormSubmissionButtonWidgetBuilder()
    .configDisplayName('Button: Review Information - Personal')
    .attributes({
      widgetProperties: {
        textContent: 'Review Information - Personal',
        zoneToNavigate: APP_ZONES.VERIFICATION,
        urlToNavigate: 'review-information',
        buttonActionType: ButtonActionType.REVIEW_INFORMATION_VERIFICATION_PERSONAL,
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .cssConfig(verificationReviewInformationCTAButtonCssConfig)
    .isRemovable(false)
    .gridPosition(gridPosition)
    .setGlobalStylingClass(true, `${ButtonTypes.PRIMARY} ${ButtonSizeOptions.FULL_WIDTH}`)
    .build();
