import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonSizeOptions,
  ButtonTypes,
  GridPosition,
  OrganisationProfileType,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';
import { PageConfig } from '../../../../../zones/mobile/default-zone-page-setups';
import {
  BackButtonWidgetBuilder,
  LineWidgetBuilder,
  MultiIconQuickLinkWidgetBuilder,
  ProfilePictureWidgetBuilder,
} from '../../../../widgets/example-builder';
import { backButtonQuickLink, zoneHeaderTitle } from '../utils';

const commonLineWidget = ({
  gridPosition,
  configDisplayName,
}: {
  gridPosition: GridPosition;
  configDisplayName: string;
}) =>
  new LineWidgetBuilder()
    .configDisplayName(`Line Widget: ${configDisplayName}`)
    .gridPosition(gridPosition)
    .attributes({
      css_config: {
        marginTop: 15,
      },
      widgetProperties: {
        'border-bottom': 'var(--border-color-1)',
        color: 'var(--border-color-1)',
        contentAlignment: 'center',
        lineWidth: '100',
        lineThickness: 1,
        show: true,
        textContent: '',
      } as BaseWidgetProperties,
    } as WorkflowWidgetConfigurationAttributes)
    .build();

const commonOrgProfileWidgets = ({
  headerContent,
  subHeaderContent,
}: {
  headerContent: string;
  subHeaderContent: string;
}) => [
  zoneHeaderTitle({
    cssConfig: {
      verticalAlignment: 'end',
      marginBottom: 5,
      marginLeft: 20,
      paddingLeft: 0,
    },
    textContent: headerContent,
    gridPosition: {
      y: 0,
      x: 0,
      rows: 2,
      cols: 10,
    } as GridPosition,
  }),
  zoneHeaderTitle({
    textContent: subHeaderContent,
    cssConfig: {
      color: 'var(--typography-color-1)',
      marginTop: 10,
      marginLeft: 20,
      paddingLeft: 0,
    },
    gridPosition: {
      y: 2,
      x: 0,
      rows: 1,
      cols: 15,
    } as GridPosition,
  }),

  commonLineWidget({
    gridPosition: {
      layerIndex: 1,
      x: 0,
      y: 3,
      cols: 40,
      rows: 1,
    } as GridPosition,
    configDisplayName: 'Header Bottom Line',
  }),
];

export const organisationDesktopProfilePages: PageConfig[] = [
  {
    name: 'Profile Landing Page',
    title: 'Profile Landing Page',
    tags: [OrganisationProfileType.ORGANISATION_PROFILE],
    url: 'organisation-profile-landing',
    widgets: [
      ...commonOrgProfileWidgets({
        headerContent: 'Business Profile',
        subHeaderContent: 'Business Profile Details',
      }),
      new ProfilePictureWidgetBuilder()
        .cssConfig({ width: '100', height: '100' })
        .gridPosition({
          y: 5,
          x: 10,
          rows: 3,
          cols: 5,
        } as GridPosition)
        .build(),

      zoneHeaderTitle({
        cssConfig: {
          textAlignment: 'start',
          paddingLeft: 0,
        },
        textContent: `
            {{ organisationData.name }}
                <br>
            {{#if organisationData.identificationNumber}}
               {{ organisationData.identificationNumber}}
            {{else}}
              --
            {{/if}}
          `,
        gridPosition: {
          y: 8,
          x: 10,
          rows: 2,
          cols: 15,
        } as GridPosition,
      }),

      commonLineWidget({
        gridPosition: {
          y: 10,
          x: 10,
          rows: 1,
          cols: 15,
        } as GridPosition,
        configDisplayName: 'Content Top Line',
      }),

      zoneHeaderTitle({
        cssConfig: {
          textAlignment: 'start',
          paddingTop: 30,
          paddingLeft: 20,
          paddingBottom: 20,
          borderRadiusTopLeft: 5,
          borderRadiusBottomLeft: 5,
          borderRadiusTopRight: 5,
          borderRadiusBottomRight: 5,
          backGroundColor: 'rgba(242, 244, 247, 1)',
        },
        textContent: `
          <div class="profile-total-balance-content">
            <p>Total Balance</p>
  
            {{#if availableBalance}}
              <h1>{{ currencyPipe availableBalance currencyCode=currency }}</h1>
            {{else}}
              --
            {{/if}}
  
          </div>
          `,
        gridPosition: {
          y: 11,
          x: 10,
          rows: 3,
          cols: 15,
        } as GridPosition,
      }),

      new MultiIconQuickLinkWidgetBuilder()
        .icons({
          left: 'suitcase',
          right: 'arrow-right',
        })
        .template('Business Profile')
        .zone(APP_ZONES.ORGANISATION_PROFILE)
        .role()
        .navigateToPageUrl('organisation-profile-summary')
        .configDisplayName('Multi Icon: Business Profile')
        .navigationOption('use-as-navigation')
        .isRemovable(true)
        .cssConfig({
          paddingLeft: 20,
          paddingRight: 20,
          borderRadiusTopLeft: 5,
          borderRadiusBottomLeft: 5,
          borderRadiusTopRight: 5,
          borderRadiusBottomRight: 5,
          borderWidth: '1',
          borderColor: 'var(--border-color-1)',
          color: 'var(--typography-color-0)',
        })
        .gridPosition({
          y: 15,
          x: 10,
          rows: 2,
          cols: 15,
        } as GridPosition)
        .setGlobalStylingClass(true, `${ButtonTypes.MENU} ${ButtonSizeOptions.FULL_WIDTH}`)
        .build(),

      new MultiIconQuickLinkWidgetBuilder()
        .icons({
          left: 'question-circle',
          right: 'arrow-right',
        })
        .template('FAQs')
        .zone(APP_ZONES.ORGANISATION_PROFILE)
        .role()
        .navigateToPageUrl('organisation-faq-landing')
        .configDisplayName('Multi Icon: FAQs')
        .navigationOption('use-as-navigation')
        .isRemovable(true)
        .cssConfig({
          color: 'var(--typography-color-0)',
          borderRadiusTopRight: 5,
          borderRadiusBottomRight: 5,
          borderRadiusTopLeft: 5,
          borderRadiusBottomLeft: 5,
          paddingLeft: 20,
          paddingRight: 20,
          borderColor: 'var(--border-color-1)',
          borderWidth: '1',
        })
        .gridPosition({
          y: 18,
          x: 10,
          rows: 2,
          cols: 15,
        } as GridPosition)
        .setGlobalStylingClass(true, `${ButtonTypes.MENU} ${ButtonSizeOptions.FULL_WIDTH}`)
        .build(),

      new BackButtonWidgetBuilder()
        .defaultBackButton()
        .text('Back')
        .configDisplayName('Back Navigation Button')
        .cssConfig({
          color: 'var(--palette-color-3)',
          backGroundColor: 'var(--palette-color-6)',
          borderWidth: 1,
          borderColor: 'var(--palette-color-3)',
          fontSize: 14,
          paddingLeft: 20,
          paddingRight: 20,
          marginTop: 14,
          marginBottom: 14,
          textAlign: 'center',
          verticalAlignment: 'center',
        })
        .gridPosition({
          y: 21,
          x: 10,
          rows: 2,
          cols: 15,
        } as GridPosition)
        .setGlobalStylingClass(true, `${ButtonTypes.SECONDARY} ${ButtonSizeOptions.FULL_WIDTH}`)
        .build(),
    ],
  },
  {
    name: 'Business Profile Summary',
    title: 'Business Profile Summary',
    tags: [OrganisationProfileType.ORGANISATION_PROFILE],
    url: 'organisation-profile-summary',
    widgets: [
      ...commonOrgProfileWidgets({
        headerContent: 'Business Profile Summary',
        subHeaderContent: 'Business Profile Details - All Information Available',
      }),

      new ProfilePictureWidgetBuilder()
        .cssConfig({ width: '100', height: '100', textAlignment: 'center' })
        .attributes({
          widgetProperties: {
            textContent: '',
            backgroundShape: 'circle',
          } as BaseWidgetProperties,
        } as WorkflowWidgetConfigurationAttributes)
        .gridPosition({
          y: 5,
          x: 17,
          rows: 3,
          cols: 6,
        } as GridPosition)
        .build(),

      zoneHeaderTitle({
        cssConfig: {
          textAlignment: 'start',
          paddingTop: 35,
          paddingLeft: 35,
          paddingRight: 35,
          paddingBottom: 35,
          borderRadiusTopLeft: 5,
          borderRadiusBottomLeft: 5,
          borderRadiusTopRight: 5,
          borderRadiusBottomRight: 5,
          backGroundColor: 'rgba(242, 244, 247, 1)',
        },
        textContent: `
          <div class="org-profile-summary">
            <div class="company-name-content mb-6 pb-6">
              <p class="typog-lg font-weight-normal">Company Name</p>
              <h3> <b> {{ organisationData.name }} </b> </h3>
            </div>
  
            <div class="middle-content mb-6 pb-6">
              <div class="identification-number-content mb-8">
                <p class="typog-lg font-weight-normal">Identification Number</p>
              <h3>
                {{#if organisationData.identificationNumber}}
                {{ organisationData.identificationNumber}}
                {{else}}
                   --
                {{/if}}
              </h3>
              </div>
  
              <div class="address-content">
                <p class="typog-lg font-weight-normal">Address</p>
                <h3>
                {{#if profile.address1}}
                  {{ profile.address1 }},
                {{/if}}
  
                {{#if profile.address2}}
                  {{ profile.address2 }},
                {{/if}}
  
                {{#if profile.city}}
                  {{ profile.city }},
                {{/if}}
  
                {{#if profile.county}}
                  {{ profile.county }},
                {{/if}}
  
                {{#if profile.country}}
                  {{ profile.country }}
                {{/if}}
                </h3>
              </div>
            </div>
  
            <div class="bottom-content is-left">
              <div class="account-number mr-8">
                <p class="typog-lg font-weight-normal">Account Number</p>
                <h3>
                {{#if accountNumber}}
                  {{ accountNumber }}
                {{else}}
                    --
                {{/if}}
                </h3>
              </div>
  
              <div class="sort-code">
                <p class="typog-lg font-weight-normal">Sort Code</p>
                <h3>
                {{#if sortCode}}
                {{ sortCode }}
                {{else}}
                  --
                {{/if}}
                </h3>
              </div>
            </div>
          </div>
            `,
        gridPosition: {
          y: 8,
          x: 11,
          rows: 13,
          cols: 18,
        } as GridPosition,
      }),

      zoneHeaderTitle({
        cssConfig: {
          textAlignment: 'center',
          paddingTop: 20,
          paddingBottom: 20,
          color: 'var(--typography-color-1)',
        },
        textContent: `
              <p class="font-weight-normal">
              If you need to update your company information, please contact us. For any other queries, feel free to reach out.
              </p>
            `,
        gridPosition: {
          y: 22,
          x: 11,
          rows: 2,
          cols: 18,
        } as GridPosition,
      }),

      backButtonQuickLink({
        gridPosition: {
          y: 24,
          x: 11,
          rows: 2,
          cols: 18,
        } as GridPosition,
        cssConfig: {
          marginTop: 15,
          marginBottom: 20,
        },
      }),
    ],
  },
  {
    name: 'FAQs Landing',
    title: 'FAQs Landing',
    tags: [OrganisationProfileType.FAQ],
    url: 'organisation-faq-landing',
    widgets: [],
  },
];
