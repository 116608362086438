export const userInfoReviewTemplate = `
<div class="user-info">
    <div class="row mx-0">
        <div class="col-12 mx-0">
            <span class="label">Full Name</span>
            <h4 class="content">{{ profile.fullName }}</h4>
        </div>
        <div class="col-12 mx-0">
            <span class="label">Phone Number</span>
            <h4 class="content">{{ profile.phoneNumber }}</h4>
        </div>
        <div class="col-12 mx-0">
            <span class="label">Email</span>
            <h4 class="content">{{ profile.email }}</h4>
        </div>
        <div class="col-12 mx-0">
            <span class="label">Date of Birth</span>
            <h4 class="content">{{ profile.dob }}</h4>
        </div>
    </div>
    <hr/>
    <div class="row mx-0">
        <div class="col-6-xs mx-0">
            <span class="label">Address 1</span>
            <h4 class="content">{{ profile.address1 }}</h4>
        </div>
        <div class="col-6-xs mx-0">
            <span class="label">Address 2</span>
            <h4 class="content">{{ profile.address2 }}</h4>
        </div>
        <div class="col-6-xs mx-0">
            <span class="label">City</span>
            <h4 class="content">{{ profile.city }}</h4>
        </div>
        <div class="col-6-xs mx-0">
            <span class="label">State/Region</span>
            <h4 class="content">{{ profile.county }}</h4>
        </div>
        <div class="col-6-xs mx-0">
            <span class="label">Country</span>
            <h4 class="content">{{ profile.country }}</h4>
        </div>
        <div class="col-6-xs mx-0">
            <span class="label">ZIP/Postal Code</span>
            <h4 class="content">{{ profile.postalCode }}</h4>
        </div>
    </div>
</div>`;
